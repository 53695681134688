import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSearchData } from "../../redux/searchDataReducer";
import { setMapState } from "../../redux/mapStateReducer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./SearchHome.css"; // Assuming you move styles to a separate file

const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = mapboxtoken;

const SearchHome = () => {
  const geocoderContainerRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [address, setAddress] = useState("");

  useEffect(() => {
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxtoken,
      mapboxgl: mapboxgl,
      language: "zh_TW",
      placeholder: "搜尋地址...",
      minLength: 2,
      autocomplete: true,
    });

    geocoder.on("result", (e) => {
      const coordinates = {
        lat: e.result.geometry.coordinates[1],
        lng: e.result.geometry.coordinates[0],
      };
      setAddress(e.result.place_name);
      dispatch(
        setSearchData({ location: coordinates, address: e.result.place_name })
      );
      dispatch(
        setMapState({
          center: [coordinates.lng, coordinates.lat],
          zoom: 16,
          pitch: 0,
          bearing: 0,
        })
      );
    });

    geocoderContainerRef.current.appendChild(geocoder.onAdd());
    return () => geocoder.onRemove();
  }, [dispatch]);

  const handleSearchClick = () => {
    if (!address) {
      alert("請輸入搜尋地址，再請進搜尋!");
      return;
    }
    navigate("/search_result");
  };

  return (
    <div className="search-home-container">
      <div className="overlay" />
      <div className="content">
        <div className="search-box">
          <h2>安家置產時</h2>
          <h2>如何找到安居的所在</h2>
          <div className="geocoder-container">
            <div ref={geocoderContainerRef} />
            <button onClick={handleSearchClick}>搜尋</button>
          </div>
          <div className="login-container">
            <button>登入</button>
            <button>註冊</button>
            <div>忘記密碼</div>
          </div>
        </div>
        <div className="info-panel">
          <h4>公開的災害潛勢圖</h4>
          <h4>足以讓您做出明智的決策嗎?</h4>
          <h6>為了解答所有的困擾與疑問</h6>
          <h6>我們會聚眾眾多災害防護領域的專家</h6>
          <h6>採用全球最尖端的災害模擬與分析技術</h6>
          <h6>讓您能安心置產</h6>
          <div>
            了解更多 <ArrowForwardIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchHome;
