import React from "react";
import "./FeatureText.scss";
import { motion } from "framer-motion";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import PieChartIcon from "@mui/icons-material/PieChart";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import PeopleIcon from "@mui/icons-material/People";

const bentoVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
};

const FeatureText = () => {
  return (
    <div className="feature-text-container">
      <div className="feature-text-grid">
        <motion.div
          className="feature-item"
          variants={bentoVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="icon-container">
            <DesignServicesIcon fontSize="large" className="icon" />
            <h4 className="feature-title">精準</h4>
          </div>
          <p className="feature-description">
            高精度淹水演算法，數據能以0.5mx0.5m的網格呈現分析結果。(資料密度最高為公開資料的6400倍)
          </p>
          <a
            href="https://upload.cc/i1/2021/06/04/byJnKG.png"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/floodcompare1.png"
              // src="/img/tech-pic3.png"
              alt="Custom Analysis"
              className="feature-image"
            />
          </a>
        </motion.div>
        <motion.div
          className="feature-item"
          variants={bentoVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="icon-container">
            <AutoAwesomeIcon fontSize="large" className="icon" />
            <h4 className="feature-title">反覆驗證</h4>
          </div>
          <p className="feature-description">
            自動萃取多方數據，務求資料來源最新最可靠。
          </p>
          <img
            src="/img/web_slider.png"
            alt="Custom Analysis"
            className="feature-image"
          />
        </motion.div>
        <motion.div
          className="feature-item"
          variants={bentoVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="icon-container">
            <PieChartIcon fontSize="large" className="icon" />
            <h4 className="feature-title">量化</h4>
          </div>
          <p className="feature-description">損失量化與避險建議。</p>
          <img
            src="/img/sumary2.png"
            alt="Custom Analysis"
            className="feature-image"
          />
        </motion.div>
        <motion.div
          className="feature-item"
          variants={bentoVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="icon-container">
            <PeopleIcon fontSize="large" className="icon" />
            <h4 className="feature-title">客製化</h4>
          </div>
          {/* <p className="feature-description">
            客製化建物分析，滿足個人需求，市面上技術大多只能做到低精度的單一點位的分析，本產品的高精度產品則可以點選建物或是手繪分析區域，比起單點分析的準確率更高。
          </p> */}
          <p className="feature-description">
            針對你的房屋位置、建築材質、樓層
          </p>
          <img
            src="/img/select2.png"
            alt="Custom Analysis"
            className="feature-image"
          />
        </motion.div>
      </div>
    </div>
  );
};

export default FeatureText;
