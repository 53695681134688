import React from "react";
import "./Prob.scss";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Prob = ({ report }) => {
  // console.log(report);
  const data = [
    {
      name: "10cm",
      機率: parseFloat(report.p10),
    },
    {
      name: "20cm",
      機率: parseFloat(report.p20),
    },
    {
      name: "30cm",
      機率: parseFloat(report.p30),
    },
    {
      name: "40cm",
      機率: parseFloat(report.p40),
    },
    {
      name: "50cm",
      機率: parseFloat(report.p50),
    },
  ];
  return (
    <>
      <div style={{ width: 600, height: 300 }}>
        <ResponsiveContainer>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 10,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              label={{
                value: "淹水深度(cm)",
                position: "insideBottom",
                offset: -10,
              }}
            />
            <YAxis
              label={{ value: "機率(%)", angle: -90, position: "insideLeft" }}
            />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="機率"
              stroke="#8884d8"
              fill="#8884d8"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className="prob-table">
        <table cellSpacing="5">
          <thead>
            <tr>
              <th>淹水深度(以上)</th>
              <th>發生機率</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>10cm</td>
              <td>{report.p10}%</td>
            </tr>
            <tr>
              <td>20cm</td>
              <td>{report.p20}%</td>
            </tr>
            <tr>
              <td>30cm</td>
              <td>{report.p30}%</td>
            </tr>
            <tr>
              <td>40cm</td>
              <td>{report.p40}%</td>
            </tr>
            <tr>
              <td>50cm</td>
              <td>{report.p50}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Prob;
