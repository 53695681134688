import React from "react";
import "./StormText.scss";
// import "../../app.scss";

const StormText = () => {
  return (
    <div className="container index_container position-relative">
      <div className="title flex-col align-items-center position-relative mt-150">
        {/* <div className="stormtext-h0">SAFEHOUSING</div> */}
        <h3 className="text-center w-100">颱風和暴雨</h3>
        <hr className="m-0" />
      </div>
      <div className="d-flex mt-4">
        <div className="col-auto col-xl-1"></div>
        <h5 className="col-12 col-xl-10 text-md-center">
          颱風和暴雨，是台灣最嚴重的天然災害，造成每年有形損失超過350億元，包括在路邊或地庫的汽機車、工廠機具和家具泡水，還有造成生命傷亡。正如世界銀行報告指出，這些災害正威脅着台灣99%人口。每個人都享有一個安居樂業的家，但是我們所能得到的資訊有限，公開的災害潛勢資料精細度不足，說明不清楚，我們該如何判斷?
        </h5>
        <br />
        {/* <h5>
          每個人都享有一個安居樂業的家，但是我們所能得到的資訊有限，公開的災害潛勢資料精細度不足，說明不清楚，我們該如何判度?
        </h5> */}
      </div>
      <div className="d-flex primary_color mt-sm-3 mt-md-5 mb-3 mb-md-0">
        <div className="col-auto col-xl-1"></div>
        <div className="col-12 col-xl-10">
          <div className="index_sup d-flex flex-column-reverse flex-md-row align-items-end align-items-md-start  justify-content-md-end mb-4 mb-md-0">
            <div className="flex-col align-items-end pe-2 pe-sm-3 pe-md-0 pt-3 pt-sm-4 pt-md-0 me-2 me-sm-3 me-md-0">
              <p className="mb-0">暴雨要來了</p>
              <hr className="w-50 opacity-100 mt-2 mb-2 pc_show" />
              <p className="mb-0">愛車該停在哪一條街？</p>
            </div>
            <div className="hr_space flex-col pc_show">
              <p className="mb-0">&nbsp;</p>
              <hr className="opacity-100 mt-2 mb-2 pc_show" />
            </div>
            <img src="/img/index01.jpg" alt="" style={{ borderRadius: "2%" }} />
          </div>
          <div className="index_sup d-flex flex-column flex-md-row align-items-md-end">
            <img src="/img/index02.jpg" alt="" style={{ borderRadius: "2%" }} />
            <div className="hr_space flex-col pc_show">
              <hr className="opacity-100 mt-2 mb-2" />
              <p className="mb-0">&nbsp;</p>
            </div>
            <div className="flex-col ps-2 ps-sm-3 ps-md-0 pt-3 pt-sm-4 pt-md-0 ms-2 ms-sm-3 ms-md-0">
              <p className="mb-0">根據坊間的評估</p>
              <hr className="w-75 opacity-100 mt-2 mb-2 pc_show" />
              <p className="mb-0">看來停哪都沒差……</p>
            </div>
          </div>
          {/* <div className="d-flex flex-column-reverse flex-md-row align-items-end align-items-md-start  justify-content-md-end">
            <div className="flex-col align-items-end pe-2 pe-sm-3 pe-md-0 pt-3 pt-sm-4 pt-md-0 me-2 me-sm-3 me-md-0">
              <p className="mb-0">暴雨要來了</p>
              <hr className="w-50 opacity-100 mt-2 mb-2 pc_show" />
              <p className="mb-0">愛車該停在哪一條街？</p>
            </div>
            <div className="hr_space flex-col pc_show">
              <p className="mb-0">&nbsp;</p>
              <hr className="opacity-100 mt-2 mb-2 pc_show" />
            </div>
            <img src="/img/index01.jpg" alt="" style={{ borderRadius: "2%" }} />
          </div> */}
        </div>
        {/* <div className="h0 position-absolute pc_show">SAFEHOUSING</div> */}
      </div>
    </div>
  );
};

export default StormText;
