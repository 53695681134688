import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  polygonData:
    '{"geo_data":"{\\"coordinates\\":[[[121.29945967306145,24.99250033387227],[121.29995832874107,24.992867020373453],[121.30024058667277,24.99256429089894],[121.30017943078826,24.992073952843242],[121.29949730743192,24.992167756811014],[121.29945967306145,24.99250033387227]]],\\"type\\":\\"Polygon\\"}"}',
  // {
  //   geo_data: {
  //     coordinates: [
  //       [
  //         [121.29945967306145, 24.99250033387227],
  //         [121.29995832874107, 24.992867020373453],
  //         [121.30024058667277, 24.99256429089894],
  //         [121.30017943078826, 24.992073952843242],
  //         [121.29949730743192, 24.992167756811014],
  //         [121.29945967306145, 24.99250033387227],
  //       ],
  //     ],
  //     type: "Polygon",
  //   },
  // },
};

export const polygonDataSlice = createSlice({
  name: "polygonData",
  initialState,
  reducers: {
    setPolygonData: (state, action) => {
      state.polygonData = action.payload;
    },
    resetPolygonData: (state) => {
      state.polygonData = initialState.polygonData;
    },
  },
});

export const { setPolygonData, resetPolygonData } = polygonDataSlice.actions;

export default polygonDataSlice.reducer;
