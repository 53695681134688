import React, { useEffect, useState, useRef } from "react";
import mapboxgl from "mapbox-gl";
// import "./RiskMapAll.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import DrawPolygonMapSelect from "./DrawPolygonMapSelect";
import ClickPolygonMapSelect from "./ClickPolygonMapSelect";
import RadiusPolygonMapSelect from "./RadiusPolygonMapSelect";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
// import DrawIcon from '@mui/icons-material/Draw';
import ModeIcon from "@mui/icons-material/Mode";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Container } from "@mui/material";
import AnimationIcon from "@mui/icons-material/Animation";
// import RiskMapRadius2 from "../../components/RiskMapAll/RiskMapRadius2";
import { setMapState } from "../../redux/mapStateReducer";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = mapboxtoken;

const Select = () => {
  const dispatch = useDispatch();
  const [pkid, setPkid] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const targetInfo = useSelector((state) => state.targetInfo);
  const { countyCode } = useParams();
  const mapState = useSelector((state) => state.mapState);
  // console.log("mapState", mapState);
  const [initialCoor, setInitialCoor] = useState({
    lng: 121.30128,
    lat: 24.99265,
  });

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const CustomTab = ({ label, icon }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        color: "black",
        fontWeight: "bold",
      }}
    >
      {icon}
      {!isMobile && <span style={{ marginLeft: 8 }}>{label}</span>}
    </div>
  );

  const [tabValue, setTabValue] = useState(0);

  const county_location = {
    9007: ["連江縣", 119.9436, 26.1519],
    10002: ["宜蘭縣", 121.76088, 24.7288],
    10007: ["彰化縣", 120.516, 24.0521],
    10008: ["南投縣", 120.9911, 23.8388],
    10009: ["雲林縣", 120.482, 23.6649],
    10017: ["基隆市", 121.7415, 25.1282],
    63000: ["臺北市", 121.5637, 25.0375],
    65000: ["新北市", 121.4628, 25.0168],
    66000: ["臺中市", 120.6736, 24.1477],
    67000: ["臺南市", 120.2053, 23.0007],
    68000: ["桃園市", 121.30128, 24.99265],
    10005: ["苗栗縣", 120.8214, 24.5602],
    10020: ["嘉義市", 120.45205, 23.47675],
    10010: ["嘉義縣", 120.6244, 23.4346],
    9020: ["金門縣", 118.319, 24.4324],
    64000: ["高雄市", 120.312, 22.6209],
    10014: ["臺東縣", 121.1136, 22.7649],
    10015: ["花蓮縣", 121.6014, 23.991],
    10016: ["澎湖縣", 119.5793, 23.568],
    10018: ["新竹市", 120.9665, 24.805],
    10004: ["新竹縣", 121.0027, 24.8392],
    10013: ["屏東縣", 120.5489, 22.5495],
  };

  useEffect(() => {
    // Get the county location from the county_location object using the countyCode
    const location = county_location[countyCode];
    if (location) {
      const [countyName, lng, lat] = location;
      setInitialCoor({ lng, lat });
      dispatch(
        setMapState({
          center: [lng, lat],
          zoom: 16,
          pitch: 0,
          bearing: 0,
        })
      );
    }
  }, [countyCode]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Container sx={{ marginBottom: "10px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // height: "110vh",
          // padding: "10px",
          position: "relative",
          marginBottom: "10px",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
          <Tabs value={tabValue} onChange={handleTabChange} centered>
            <Tab
              label={<CustomTab label="點選建物" icon={<HomeWorkIcon />} />}
            />
            <Tab label={<CustomTab label="手繪區域" icon={<ModeIcon />} />} />
            <Tab
              label={<CustomTab label="點選半徑" icon={<AnimationIcon />} />}
            />
          </Tabs>

          {
            // Use a switch statement to handle rendering based on tabValue
            (() => {
              switch (tabValue) {
                case 0:
                  return <ClickPolygonMapSelect initialCoor={initialCoor} />;
                case 1:
                  return <DrawPolygonMapSelect initialCoor={initialCoor} />; // Replace SomeOtherComponent with the actual component you want to render for tab value 1
                case 2:
                  return <RadiusPolygonMapSelect initialCoor={initialCoor} />; // Replace AnotherComponent with the actual component you want to render for tab value 2
                default:
                  return null; // This is optional, in case tabValue is outside of the expected range
              }
            })()
          }

          {/* <div style={{ padding: "10px" }}>
            <ul
              className="content-list"
              style={{ listStyleType: "disc", fontWeight: "bold" }}
            >
              <li>1. 請填入分析建物之基本資料</li>
              <li>
                2. 請在地圖上選擇建築物範圍，或切換自行畫圖分析
                (自行畫圖時，請依建築物投射地面的形狀為主，過大的範圍會影響分析結果)
              </li>
            </ul>
            <hr />
            <div className="target-input-container">
              <div
                style={{
                  marginRight: "10px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                <button onClick={() => dispatch(resetTargetInfo())}>
                  重置
                </button>
              </div>
              <div
                style={{
                  marginRight: "10px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                建物資訊輸入
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",

                  flexDirection: "row",
                }}
              >
                <label
                  htmlFor="designPga"
                  style={{
                    marginRight: "5px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  建築抗震設計(g)，或不輸入，系統將採用抗震設計規範值:
                </label>
                <input
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "60px",
                  }}
                  id="designPga"
                  type="number"
                  min="0"
                  step="0.01"
                  value={targetInfo.designPga}
                  onChange={handleDesignPgaChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",

                  flexDirection: "row",
                }}
              >
                <label
                  htmlFor="totalFloorNumber"
                  style={{
                    marginRight: "5px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  建物總樓層數:
                </label>
                <input
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "60px",
                  }}
                  id="totalFloorNumber"
                  type="number"
                  min="1"
                  step="1"
                  value={targetInfo.totalFloorNumber}
                  onChange={handleTotalfloorNumberChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",

                  flexDirection: "row",
                }}
              >
                <label
                  htmlFor="floorNumber"
                  style={{
                    marginRight: "5px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  建物位於幾樓:
                </label>
                <input
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "60px",
                  }}
                  id="floorNumber"
                  type="number"
                  min="1"
                  step="1"
                  value={targetInfo.floorNumber}
                  onChange={handleFloorNumberChange}
                />
              </div>
              <div
                style={{
                  display: "flex",

                  gap: "5px",
                  flexDirection: "row",
                }}
              >
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginRight: "5px",
                  }}
                  htmlFor="building-type"
                >
                  建物種類:
                </label>
                <select
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    maxWidth: "200px", // Adjust the max width as needed
                    overflowX: "auto", // Enable horizontal scrolling if necessary
                  }}
                  id="building-type"
                  value={targetInfo.buildingType}
                  onChange={handleBuildingTypeChange}
                >
                  <option value={1.25}>
                    公共建築(活動中心、博物館、百貨公司、大賣場、電影院、車站等)
                  </option>
                  <option value={1}>住宅</option>
                  <option value={1.5}>
                    政府辦公室、醫院、學校、電廠、水廠等
                  </option>
                  <option value={1.5}>油槽、加油站、化學廠房</option>
                </select>
              </div>
              <div
                style={{
                  display: "flex",

                  gap: "5px",
                  flexDirection: "row",
                }}
              >
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginRight: "5px",
                  }}
                  htmlFor="building-material"
                >
                  建物材質:
                </label>
                <select
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                  id="building-material"
                  value={targetInfo.buildingMaterial || "SRC"}
                  onChange={handleBuildingMaterialChange}
                >
                  <option value="SRC">SRC</option>
                  <option value="RC">RC</option>
                  <option value="磚造">磚造</option>
                  <option value="加強磚造">加強磚造</option>
                  <option value="木材">木材</option>
                </select>
              </div>

              <div
                style={{
                  display: "flex",

                  gap: "5px",
                  flexDirection: "row",
                }}
              >
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginRight: "5px",
                  }}
                  htmlFor="building-time"
                >
                  建造時間(西元年):
                </label>
                <input
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "60px",
                  }}
                  id="building-time"
                  type="number"
                  min="0"
                  step="1"
                  value={targetInfo.buildingTime}
                  onChange={handleBuildingTimeChange}
                />
              </div>

              <div>
                <label
                  htmlFor="maskingHeight"
                  style={{
                    marginRight: "5px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  建物1F地板比人行道高幾公分(一般為10~30公分):
                </label>
                <input
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "60px",
                  }}
                  id="maskingHeight"
                  type="number"
                  min="0" // Set a minimum value of 0 to prevent negative numbers
                  value={targetInfo.maskingHeight}
                  onChange={handleMaskingHeightChange}
                />
              </div>
              <hr />
              <div
                style={{
                  marginRight: "5px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                財產資料
              </div>
              <div>
                <label
                  htmlFor="buildingValue"
                  style={{
                    marginRight: "5px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  建物實體價值(萬元):
                </label>
                <input
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "60px",
                  }}
                  id="buildingValue"
                  type="number"
                  min="0" // Set a minimum value of 0 to prevent negative numbers
                  value={targetInfo.buildingValue}
                  onChange={handleBuildingValueChange}
                />
              </div>
              <div>
                <label
                  htmlFor="businessLoss"
                  style={{
                    marginRight: "5px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  營業損失(萬元):
                </label>
                <input
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "60px",
                  }}
                  id="businessLoss"
                  type="number"
                  min="0" // Set a minimum value of 0 to prevent negative numbers
                  value={targetInfo.businessLoss}
                  onChange={handleBusinessLossChange}
                />
              </div>
              <div>
                <label
                  htmlFor="carValue"
                  style={{
                    marginRight: "5px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  汽機車價值(萬元):
                </label>
                <input
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "60px",
                  }}
                  id="carValue"
                  type="number"
                  min="0" // Set a minimum value of 0 to prevent negative numbers
                  value={targetInfo.carValue}
                  onChange={handleCarValueChange}
                />
              </div>
              <div>
                <label
                  htmlFor="furnitureLoss"
                  style={{
                    marginRight: "5px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  附屬物價值(家具)(萬元):
                </label>
                <input
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "60px",
                  }}
                  id="furnitureLoss"
                  type="number"
                  min="0" // Set a minimum value of 0 to prevent negative numbers
                  value={targetInfo.furnitureLoss}
                  onChange={handleFurnitureLossChange}
                />
              </div>
            </div>
          </div> */}
          <hr />
        </Box>
      </div>
    </Container>
  );
};

export default Select;
