import React from "react";
import {
  Tabs,
  Tab,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const EarthPrepare = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState("panel1");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        各階段整備行動
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            sx={{ fontWeight: "bold" }}
            value={tabValue}
            onChange={handleChange}
            aria-label="flood info tabs"
            centered={!isMobile}
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : false}
          >
            <Tab label="地震前（防災前）" sx={{ fontWeight: "bold" }}/>
            <Tab label="地震時（防災中）" sx={{ fontWeight: "bold" }}/>
            <Tab label="地震後（防災後）" sx={{ fontWeight: "bold" }}/>
          </Tabs>
        </Box>
        {tabValue === 0 && (
          <Box p={3} sx={{ padding: "0px" }}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>我們建議住家風險減輕方案:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  我們建議住家風險減輕方案：
                  老舊建築，可申請結構加強補助（找我們的合作伙伴)
                </Typography>
                <Typography>
                  土壤液化為地震災害的一種，而且一般所造成的損壞影響為老舊建築物，因為當時的建築設計規範並未將土壤液化納入考量。
                  為了顧及老舊建築之安全以及都市防災需求，內政部國土署已制訂都市危險及老舊建築物加速重建條例，針對都市計畫區之危險老舊建築提供耐震初評、詳評以及改建等補助措施。
                </Typography>
                <Typography>換一個新房屋</Typography>
              </AccordionDetails>
            </Accordion>
            {/* Repeat for other items as needed */}
          </Box>
        )}
        {tabValue === 1 && (
          <Box p={3} sx={{ padding: "0px" }}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>災中自救:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>防災包（找我們的合作伙伴）</Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
        {tabValue === 2 && (
          <Box p={3} sx={{ padding: "0px" }}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>災後行動:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  拍照 找里長 主動聯繫保險業務員 住家環境消毒
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default EarthPrepare;
