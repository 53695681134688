import React, { useEffect, useState } from "react";
import RiskMapClipSeaLevel from "../../components/RiskMapAll/RiskMapClipSeaLevel";
// import DepthProbTableBuilding from "../../components/RiskMapAll/DepthProbTableBuilding";
import DepthProbBuilding from "../Prob/DepthProbBuilding";
import DepthProbTableBuilding from "../Prob/DepthProbTableBuilding";

// import { Container } from "@mui/material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import axiosInstance from "../../axios";
import FloodPrepare from "./FloodPrepare";
import FloodSolution from "./FloodSolution";
import FloodRiskChartSealevel from "../FloodRiskChart/FloodRiskChartSealevel";
import SealevelProbTable from "../Prob/SealevelProbTable";
import SealevelProb from "../Prob/SealevelProb";

const SealevelResult = ({ report, productTargetInfo, furnitureLoss }) => {
  const polygonData = useSelector((state) => state.polygonData.polygonData);
  const [liqProb, setLiqProb] = useState({});
  const [loading, setLoading] = useState(false);
  const sealevel = report?.risk_results["sealevel"][2];
  const sealevelCarLoss = report?.risk_results["sealevel"][3];
  const sealevelFurnitureLoss = report?.risk_results["sealevel"][4];
  const sealevelTotalLoss = report?.risk_results["sealevel"][5];
  const sealevel_rise_masked_and_probabilities_all =
    report?.risk_results["sealevel"][7];

  const waterProbData = report?.p0_200_interpolated;

  // console.log("waterProbData", waterProbData);

  // console.log("sealevelTotalLoss", sealevelTotalLoss);
  // console.log(
  //   "sealevel_rise_masked_and_probabilities_all[0][0][10cm]",
  //   sealevel_rise_masked_and_probabilities_all[0][0]["10cm"]
  // );

  const [sealevel15, setSealevel15] = useState(0);
  const [sealevel22, setSealevel22] = useState(0);
  const [sealevel40, setSealevel40] = useState(0);
  const [sealevel47, setSealevel47] = useState(0);

  // Assuming report.product.target_info is a string containing JSON data
  const targetInfoString = report.product.target_info;

  // Parse the string into a JSON object
  const targetInfoJSON = JSON.parse(targetInfoString);

  // Extracting the mask height
  const maskHeight = targetInfoJSON.maskingHeight;

  // useEffect(() => {
  //   const calc_waterdepth = (sealevel_depth, setState) => {
  //     if (sealevel_depth > 0) {
  //       const mask_depth = sealevel_depth - maskHeight / 100;
  //       if (mask_depth > 0) {
  //         setState(mask_depth);
  //       } else {
  //         setState(0);
  //       }
  //     } else {
  //       setState(0);
  //     }
  //   };

  //   // Calculate water depth for each sea level
  //   calc_waterdepth(sealevel[0], setSealevel15);
  //   calc_waterdepth(sealevel[1], setSealevel22);
  //   calc_waterdepth(sealevel[2], setSealevel40);
  //   calc_waterdepth(sealevel[3], setSealevel47);
  // }, [sealevel, maskHeight]);

  if (loading) {
    return <div>資料加載中，請稍後...</div>;
  }

  // if (!liqProb) {
  //   return <div>No liquefaction probability data available.</div>;
  // }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            氣候變遷的淹水風險概況：海平面上升+降雨增強(AR6)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <FloodRiskChartSealevel
              total_loss={report.risk_results["flood"][0]}
            />
          </div>
          <hr />
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            氣候變遷的主要影響為海平面上升與降雨增強，因此淹水深度與發生機率增加。
            依據本系統分析，本建物一樓地板比人行道高
            <span style={{ color: "red" }}>{maskHeight}</span>公分，
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            S1:海平面上升1.5m+AR6降雨情境，本建物一樓每年大於10公分的淹水機率
            {sealevel_rise_masked_and_probabilities_all[0][0]["10cm"]}
            %。您物件位於本建物的
            {targetInfoJSON.floorNumber}樓，家俱家電可能每年有
            {sealevelFurnitureLoss[0].toFixed(0)}
            元的期望損失。考量不同淹水深度與對應的發生機率，此建物的汽機車每年有
            {sealevelCarLoss[0].toFixed(0)}元的期望損失，每年因淹水平均損失為
            <span style={{ color: "red" }}>
              {sealevelTotalLoss[0].toFixed(0)}
            </span>
            元。
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            S2:海平面上升2.2m+AR6降雨情境，本建物一樓每年大於10公分的淹水機率
            {sealevel_rise_masked_and_probabilities_all[1][0]["10cm"]}
            %。您物件位於本建物的
            {targetInfoJSON.floorNumber}樓，家俱家電可能每年有
            {sealevelFurnitureLoss[1].toFixed(0)}
            元的期望損失。考量不同淹水深度與對應的發生機率，此建物的汽機車每年有
            {sealevelCarLoss[1].toFixed(0)}元的期望損失，每年因淹水平均損失為
            <span style={{ color: "red" }}>
              {sealevelTotalLoss[1].toFixed(0)}
            </span>
            。
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {" "}
            S3:海平面上升4.0m+AR6降雨情境，本建物一樓每年大於10公分的淹水機率
            {sealevel_rise_masked_and_probabilities_all[2][0]["10cm"]}
            %。您物件位於本建物的
            {targetInfoJSON.floorNumber}樓，家俱家電可能每年有
            {sealevelFurnitureLoss[2].toFixed(0)}
            元的期望損失。考量不同淹水深度與對應的發生機率，此建物的汽機車每年有
            {sealevelCarLoss[2].toFixed(0)}元的期望損失，每年因淹水平均損失為
            <span style={{ color: "red" }}>
              {sealevelTotalLoss[2].toFixed(0)}
            </span>
            。
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            S4:海平面上升4.7m+AR6降雨情境，本建物一樓每年大於10公分的淹水機率
            {sealevel_rise_masked_and_probabilities_all[3][0]["10cm"]}
            %。您物件位於本建物的
            {targetInfoJSON.floorNumber}樓，家俱家電可能每年有
            {sealevelFurnitureLoss[3].toFixed(0)}
            元的期望損失。考量不同淹水深度與對應的發生機率，此建物的汽機車每年有
            {sealevelCarLoss[3].toFixed(0)}元的期望損失，每年因淹水平均損失為
            <span style={{ color: "red" }}>
              {sealevelTotalLoss[3].toFixed(0)}
            </span>
            。
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            警語：氣候變遷已經在發生，但台灣海平面目前已經上升多少尚未有明確的評估或公布，因此建議於官方公布海平面上升量時，再進行相關防護措施。在海平面尚未上升前，採一般的淹水風險評估結果，並採取對應的防水措施。
          </Typography>
          <div>
            <ul style={{ fontSize: "14px", fontWeight: "bold" }}>
              <li>
                依據IPCC
                AR6之升溫2°C情境，將導致臺灣周邊海域海平面上升0.5公尺，升溫4°C情境，將導致海平面上升1.2公尺。
              </li>
              <li>
                依據氣象局資料，2003-2022年間全年最高高潮位/暴潮位約為1-3.5(公尺)，以此為基準計算海平面上升可能造成的海水位高度，分別計算海水位1.5m、2.2m、4.0m、4.7m的低窪淹水區域
              </li>
            </ul>
          </div>{" "}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            氣候變遷下海平面上升淹水潛勢圖
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RiskMapClipSeaLevel report={report} />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            每年平均淹水損失(建物淹水機率分析)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="flood-prob-table-container">
            {/* <DepthProbTableBuilding waterProbData={waterProbData} /> */}
            <SealevelProbTable report={report} />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            淹水保險方案
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ padding: "15px" }}
            variant="body1"
            component="div"
            gutterBottom
          >
            經淹水分析後，發現本建物每年因淹水平均期望損失為
            <span style={{ fontSize: "16px", color: "red" }}>
              {report.risk_results["flood"][0]}元
            </span>
            大於1000 建議加裝防水閘版、保險 100～1000
            可加裝防水閘版、或斟酌是否購買保險 0~100 恭喜你買到淹水機率低的房屋
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            淹水預防措施
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SealevelProb report={report} productTargetInfo={productTargetInfo} />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            防災準備
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FloodPrepare />
          <FloodSolution />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default SealevelResult;
