import React, { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxCompare from "mapbox-gl-compare";
import * as turf from "@turf/turf";
import "mapbox-gl/dist/mapbox-gl.css";
import "mapbox-gl-compare/dist/mapbox-gl-compare.css";
import "./ReportMapSlider.scss";
import { stringify } from "wkt";
import axios from "axios";
import { toast } from "react-toastify";

const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const gs_username = process.env.REACT_APP_GEOSERVER_BASIC_USERNAME;
const gs_pass = process.env.REACT_APP_GEOSERVER_BASIC_PASSWORD;
mapboxgl.accessToken = mapboxtoken;

const ReportMapSlider = ({ detailOrder }) => {
  const [legendUrl, setLegendUrl] = useState("");

  useEffect(() => {
    // Function to fetch the legend image with authentication
    const fetchLegendImage = async (legendRequestUrl) => {
      try {
        const response = await axios.get(legendRequestUrl, {
          responseType: "blob", // Important: this tells Axios to handle the response as a Blob
          auth: {
            username: gs_username,
            password: gs_pass,
          },
        });

        // Create a local URL for the image blob
        const imageUrl = URL.createObjectURL(response.data);

        // Set the local URL as the image source
        setLegendUrl(imageUrl);
      } catch (error) {
        console.error("Error fetching legend image:", error);
        toast.error("無法加載圖例。");
      }
    };
    if (detailOrder?.wms_layer_name) {
      // Construct the legend URL using the layer name from detailOrder
      const legendRequestUrl = `${geoserver_url}geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&LAYER=${detailOrder.wms_layer_name}`;
      // Call the function to fetch the legend image
      fetchLegendImage(legendRequestUrl);
    }
  }, [detailOrder, geoserver_url, gs_username, gs_pass]);
  // console.log(detailOrder);
  useEffect(() => {
    if (!detailOrder || !detailOrder.calculated_geometry) {
      console.log("No valid geometry data available");
      return; // exit if there is no valid geometry data
    }

    let center;
    try {
      center = turf.center(detailOrder.calculated_geometry).geometry
        .coordinates;
    } catch (error) {
      console.error("Error calculating center:", error);
      return;
    }

    if (!center || center.length !== 2 || center.some(isNaN)) {
      console.error("Invalid center coordinates:", center);
      return;
    }

    // Assuming `wms_layer_name` is available and valid
    // const newLegendUrl = `${geoserver_url}geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&LAYER=${detailOrder?.wms_layer_name}`;
    // setLegendUrl(newLegendUrl);

    // console.log("Center coordinates:", center);

    // Use Turf.js to calculate the center and bbox
    const bbox = turf.bbox(detailOrder?.calculated_geometry);
    const product_bbox = [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ];

    const wms_layer_name = detailOrder?.wms_layer_name;

    const wkt3857 = stringify(detailOrder?.calculated_geometry_3857);

    const beforeMap = new mapboxgl.Map({
      container: "before",
      style: "mapbox://styles/mapbox/streets-v12",
      transformRequest: (url, resourceType) => {
        // console.log(`Making a ${resourceType} request to ${url}`);
        if (
          (resourceType === "Source" || resourceType === "Tile") &&
          // url.indexOf("http://localhost:8080/geoserver/staging/wms") > -1
          url.indexOf(geoserver_url + `geoserver/staging/wms`) > -1
        ) {
          const username = gs_username;
          const password = gs_pass;
          const credentials = btoa(`${username}:${password}`);
          // const credentials = Buffer.from(`${username}:${password}`).toString('base64');
          const headers = {
            // 'my-custom-header': true,
            Authorization: `Basic ${credentials}`,
          };
          // console.log(`Adding headers: ${JSON.stringify(headers)}`);

          return {
            url: url,
            headers: headers,
            credentials: "include", // Include cookies for cross-origin requests
          };
        }
      },
      center: center,
      zoom: 15.5,
      minZoom: 15.5,
      dragPan: false,
    });

    // Fit the map to the bbox extent
    beforeMap.fitBounds(product_bbox, { padding: 20 });
    beforeMap.on("style.load", () => {
      //circle
      beforeMap.addSource("polygon", {
        type: "geojson",
        // data: geojson,
        data: detailOrder?.calculated_geometry,
      });

      beforeMap.addLayer({
        id: "polygon-layer",
        type: "fill",
        source: "polygon",
        paint: {
          "fill-color": "transparent",
          //   "fill-opacity": 0.5,
        },
      });

      beforeMap.addLayer({
        id: "polygon-border-layer",
        type: "line",
        source: "polygon",
        paint: {
          "line-color": "black",
          "line-width": 2,
          "line-dasharray": [2, 2],
        },
      });

      //wms
      beforeMap.addSource("wms-test-source", {
        type: "raster",
        tiles: [
          //   `http://localhost:8080/geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:opendata6h250mm40m&FORMAT=image/png&TILED=true&clip=${wkt3857}`,
          // `http://localhost:8080/geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:opendata6h250mm40m&FORMAT=image/png&TILED=true`,
          geoserver_url +
            `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:opendata6h350mm40m3857&FORMAT=image/png&TILED=true`,
        ],

        tileSize: 256,
      });
      beforeMap.addLayer(
        {
          id: "wms-test-layer",
          type: "raster",
          source: "wms-test-source",
          paint: {},
        }
        // "building"
      );

      beforeMap.setPaintProperty("wms-test-layer", "raster-opacity", 0.7);
    });

    const afterMap = new mapboxgl.Map({
      container: "after",
      //   style: "mapbox://styles/mapbox/streets-v12",
      style: "mapbox://styles/mapbox/dark-v11",
      transformRequest: (url, resourceType) => {
        // console.log(`Making a ${resourceType} request to ${url}`);
        if (
          (resourceType === "Source" || resourceType === "Tile") &&
          // url.indexOf("http://localhost:8080/geoserver/staging/wms") > -1
          url.indexOf(geoserver_url + `geoserver/staging/wms`) > -1
        ) {
          const username = gs_username;
          const password = gs_pass;
          const credentials = btoa(`${username}:${password}`);
          // const credentials = Buffer.from(`${username}:${password}`).toString('base64');
          const headers = {
            // 'my-custom-header': true,
            Authorization: `Basic ${credentials}`,
          };
          // console.log(`Adding headers: ${JSON.stringify(headers)}`);

          return {
            url: url,
            headers: headers,
            credentials: "include", // Include cookies for cross-origin requests
          };
        }
      },
      //   style: "mapbox://styles/mapbox/navigation-night-v1",
      center: center,
      zoom: 15.5,
      minZoom: 15.5,
      dragPan: false,
    });

    // Fit the map to the bbox extent
    afterMap.fitBounds(product_bbox, { padding: 20 });

    afterMap.on("style.load", () => {
      //circle
      afterMap.addSource("polygon", {
        type: "geojson",
        // data: geojson,
        data: detailOrder?.calculated_geometry,
      });

      afterMap.addLayer({
        id: "polygon-layer",
        type: "fill",
        source: "polygon",
        paint: {
          "fill-color": "transparent",
          //   "fill-opacity": 0.5,
        },
      });

      afterMap.addLayer({
        id: "polygon-border-layer",
        type: "line",
        source: "polygon",
        paint: {
          "line-color": "black",
          "line-width": 2,
          "line-dasharray": [2, 2],
        },
      });

      //wms
      afterMap.addSource("wms-test-source", {
        type: "raster",
        tiles: [
          geoserver_url +
            `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=${wms_layer_name}&FORMAT=image/png&TILED=true&clip=${wkt3857}`,
        ],
        tileSize: 256,
      });

      afterMap.addLayer(
        {
          id: "wms-test-layer",
          type: "raster",
          source: "wms-test-source",
          paint: {},
        }
        // "building"
      );

      // afterMap.setPaintProperty("wms-test-layer", "raster-opacity", 0.7);
      afterMap.setPaintProperty("wms-test-layer", "raster-opacity", 0.8);
    });

    // A selector or reference to HTML element
    const container = "#comparison-container";

    // const map = new mapboxgl.Compare(beforeMap, afterMap, container, {
    const map = new MapboxCompare(beforeMap, afterMap, container, {
      // Set this to enable comparing two maps by mouse movement:
      // mousemove: true,
      orientation: "vertical",
    });

    map.on("slideend", (e) => {});

    // setMap(map);

    // Clean up on unmount
    return () => {
      map.remove();
      afterMap.remove();
      beforeMap.remove();
    };
  }, [detailOrder]);

  if (!detailOrder || Object.keys(detailOrder).length === 0) {
    return <div>Loading...</div>; // display loading message until the data is available
  }

  return (
    <div id="comparison-container">
      <div id="before" className="map_compare"></div>

      <div id="after" className="map_compare"></div>

      {/* Add the legend container */}
      <div
        className="map-legend"
        style={{
          position: "absolute",
          bottom: "20px",
          right: "10px",
          border: "2px solid",
          // padding: "2px",
          borderRadius: "5px",
        }}
      >
        {legendUrl && <img src={legendUrl} alt="Legend" />}
      </div>
    </div>
  );
};

export default ReportMapSlider;
