import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SummaryChartFlood from "../../components/Charts/SummaryChartFlood";
import SummaryChartEarthquake from "../../components/Charts/SummaryChartEarthquake";
import SummaryChartLiq from "../../components/Charts/SummaryChartLiq";
import SummaryChartSealevel from "../../components/Charts/SummaryChartSealevel";
import SummaryChartAll from "../../components/Charts/SummaryChartAll";

const Summary = ({ report, productTargetInfo, furnitureLoss }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            分析參數
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            建物材質: {productTargetInfo?.["buildingMaterial"]}/ 建造時間:{" "}
            {productTargetInfo?.["buildingTime"]}年/ 建物價值:{" "}
            {productTargetInfo?.["buildingValue"]} 萬元/ 所在樓層:{" "}
            {productTargetInfo?.["floorNumber"]}樓/ 總樓層:{" "}
            {productTargetInfo?.["totalFloorNumber"]}樓/ 汽機車價值:{" "}
            {productTargetInfo?.["carValue"]}萬元/ 傢俱價值:{" "}
            {productTargetInfo?.["furnitureLoss"]}萬元/ 門檻高度:{" "}
            {productTargetInfo?.["maskingHeight"]}公分
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            淹水風險分析
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            依據本系統分析，本建物一樓地板比人行道高
            {productTargetInfo?.["maskingHeight"]}
            公分，本建物一樓每年
            <span style={{ fontSize: "16px", color: "red" }}>
              大於10公分的淹水機率
              {/* {report.p0_200_interpolated["10cm"]}％ */}
              {report.risk_results["flood"][4]["10cm"]}%
            </span>
            。{furnitureLoss}
            汽車會受到影響。
            <br />
            <br />
            {/* 考量不同淹水深度與對應的發生機率，此建物的汽機車每年有
            <span style={{ fontSize: "16px", color: "red" }}>
              {report.risk_results["flood"][1]}
            </span>
            元的期望損失，
            建議可在汽機車出入口、較低的通風口、人員出入口在大豪雨期間加裝防水閘版，以及購買淹水保險。 */}
            {/* 可能保險費用約落在
            <span style={{ fontSize: "16px", color: "red" }}>
              {(report.risk_results["flood"][1] * 1.2).toFixed(0)}～
              {(report.risk_results["flood"][1] * 1.5).toFixed(0)}
            </span>
            元/年為合理的保險費用。 */}
          </Typography>
          <SummaryChartFlood report={report} />
        </AccordionDetails>
      </Accordion>

      {/* Repeat for each type of risk or data segment */}

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            地震風險分析
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            您的建物依據防震設計規範至少為
            <span style={{ fontSize: "16px", color: "red" }}>
              {productTargetInfo?.["designpga"]}
            </span>
            的防震，亦即小於此震度不會發生全倒，但小於此震度仍會受到大小不等的損傷。
            {/* 依據本系統分析顯示，本建物所在位置，未來50年有10％的機率發生
            {report.risk_results["earthquake"][2][0]}g（中央氣象署定義
            {report.risk_results["earthquake"][3][0]}）與2％的機率發生
            {report.risk_results["earthquake"][2][1]}g （中央氣象署定義
            {report.risk_results["earthquake"][3][1]}
            ）的地震，考量不同震度對建物所造成的損失比率、以及地震發生機率，本建物的年期望損失為
            <span style={{ fontSize: "16px", color: "red" }}>
              {report.risk_results["earthquake"][0]}
            </span>
            元，建議可加保超額地震險、擴大地震險。、輕損地震險。 */}
            {/* 可能保險費用約落在
            <span style={{ fontSize: "16px", color: "red" }}>
              {(report.risk_results["earthquake"][0] * 1.2).toFixed(0)}～
              {(report.risk_results["earthquake"][0] * 1.5).toFixed(0)}
            </span>
            元/年為合理的保險費用。 */}
          </Typography>
          <SummaryChartEarthquake report={report} />
        </AccordionDetails>
      </Accordion>

      {/* More accordions for other risks */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            土壤液化風險
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            依據經濟部地質調查及礦業管理中心資料，本建物所在位置土壤液化潛勢屬{" "}
            {report.risk_results["liguefaction"][0]}潛勢。
            {/* 本建物的基礎為
            <span style={{ fontSize: "16px", color: "red" }}>
           
              {productTargetInfo?.["buildingFoundation"]}
            </span>
            ，建造時間為
            <span style={{ fontSize: "16px", color: "red" }}>
              {productTargetInfo?.["buildingTime"]}
            </span>
            年，可能需要注意土壤液化對建物的影響，建議可進一步找土木技師工會進行專業判斷。
            本建物之土壤液化年期望損失為
            <span style={{ fontSize: "16px", color: "red" }}>
              {report.risk_results["liguefaction"][5][0]}~
              {report.risk_results["liguefaction"][5][1]}元
            </span> */}
          </Typography>
          <SummaryChartLiq report={report} />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            坡地災害
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            依據農業部農村發展及水土保持署資料，本建物所在位置離既有崩坍區、本系統分析土石流潛勢區最短距離為
            {report.risk_results["landslide"][0] / 1000}公里。
            {/* 屬第{report.risk_results["landslide"][1]}級安全區域。 */}
            
            {/* 本建物之坡地災害年期望損失為
            <span style={{ fontSize: "16px", color: "red" }}>
              {report.risk_results["landslide"][2]}元
            </span>{" "} */}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            氣候變遷
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            依據本系統分析顯示，本建物所在位置氣候變遷衝擊下，海平面上升1.5m~4.7m可能造成的淹水期望值為。
            <span style={{ color: "red" }}>
              {report?.risk_results["sealevel"][5][0]}
            </span>{" "}
            -
            <span style={{ color: "red" }}>
              {report?.risk_results["sealevel"][5][3]}
            </span>
            元
          </Typography>
          <SummaryChartSealevel report={report} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            安家建議
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* <Typography>
            依本系統分析結果顯示，本建物可能會有淹水而造成損失的機率，建議除加強住家防水安全外，可以增加保險，藉以保障財產安全。此外，地震風險保額仍有不足的現象，建議可加保超額與擴大地震險。
          </Typography> */}
          <SummaryChartAll report={report} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Summary;
