import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  CardMedia,
  Button,
  Modal,
} from "@mui/material";
import "./Testimonials.css";

// Testimonial data
const testimonials = [
  // {
  //   expert: "水利專家",
  //   image: "https://via.placeholder.com/150",
  //   name: "台灣大學土木系游景雲教授",
  //   text: "感謝安家百科的指導，我們成功避開了地震帶來的風險，確保了家庭的安全。",
  // },
  {
    expert: "土木專家",
    image:
      "https://www.cv.ncu.edu.tw/wp-content/uploads/2020/04/image1-e1589954127422-979x1030.jpg",
    name: "台灣大學土木系鐘志忠教授",
    text: "…..結合巨量資料水文水理模式，精確模擬出高解析度的淹水潛勢圖，解析度約為0.5～公尺，遠優於市面上公開資料的20-40公尺精度。透過進階的統計學模型，系統化計算各地點10-200公分以上淹水風險的機率，並針對每個地點提供專屬報告，幫助用戶深入了解該區域的淹水風險,作為安家選擇或保險投資的決策依據。",
  },
  {
    expert: "水利專家",
    image:
      "https://researchoutput.ncku.edu.tw/files-asset/144588220/9708034.png?w=320&f=webp",
    name: "成功大學水利系王曉雯教授",
    text: `「殘餘風險residual risk」是指透過風險控制降低⾃然或固有風險後仍存在的風險。
            以洪災為例，防洪⼯程措施皆以某⼀設定的保護標準來設計興建，有其極限。以凱米
            颱風帶來的強降雨為例，在愛河流域記錄到單⽇降雨超過50年重現期，但愛河區域
            排⽔僅為可通過10 年重現期洪⽔與達25年重現期洪⽔不溢堤的保護標準。另以⼭坨
            兒颱風來看，⾼雄仁武地區六⼩時最⼤累積降雨⼤約350 mm，也已超過200 年重現
            期。

            今年五⽉國家科學與技術委員會與環境部共同發布「2024國家氣候變遷科學報告」，
            已明確揭⽰我們所⾯對的各種氣候衝擊與災害威脅，已經不是「會不會發⽣」、⽽是
            「多快會發⽣」與「衝擊有多⼤」。因此，建立社會的「風險文化」（Risk
            Culture）、以及⺠眾具備適當的「風險意識」（Risk Perception）有其必要。在適
            當的風險意識下，個⼈才有機會做好相應的準備。

            「安家百科」提供五項⾃然風險（地震、淹⽔、⼟壤液化、坡地災害、氣候變遷）的
            量化分析、以及提供如何避險與轉移風險的建議，透過視覺化風險評估⼯具，使⺠眾
            可互動式理解不同淹⽔深度與機率、以及可能的損失，也有機會理解不同的避險措施
            （如防⽔閘⾨⾼度）可如何降低淹⽔機率與損失。個⼈對災害規模、衝擊、損失有所
            了解，才會知道怎麼⾏動。

            風險不僅是災害發⽣本⾝，也涉及社會、經濟條件的連動關係，例如基礎設施、⼈⼝
            移動、住房需求、特有的社區特性等。在不可抗拒的⼤⾃然因素下，夠過更明確的風
            險資訊，可以進⼀步建立公共知識，建構居家安全防護。`,
  },
];

const TestimonialSlider = () => {
  const [open, setOpen] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);

  const handleOpen = (testimonial) => {
    setSelectedTestimonial(testimonial);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTestimonial(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        overflow: "hidden",
        width: "100%",
        justifyContent: "center",
        padding: "20px 0",
      }}
    >
      <div className="slider">
        <div className="slider-track">
          {testimonials.concat(testimonials).map((testimonial, index) => (
            <Card
              key={index}
              sx={{
                display: "flex",
                minWidth: "300px",
                maxWidth: "600px",
                height: "300px", // Fix card height
                margin: "0 10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                // border: "0.5px grey solid",
                position: "relative",
              }}
            >
              <Grid container spacing={2} sx={{ alignItems: "center" }}>
                <Grid item xs={8}>
                  <CardContent
                    sx={{
                      height: "200px", // Restrict content height
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      {testimonial.expert}推薦
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      「{testimonial.text.substring(0, 100)}...」 -{" "}
                      {testimonial.name}
                    </Typography>
                  </CardContent>
                  <Button
                    onClick={() => handleOpen(testimonial)}
                    sx={{
                      position: "absolute",
                      bottom: "10px",
                      left: "10px",
                    }}
                  >
                    閱讀更多
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <CardMedia
                    component="img"
                    image={testimonial.image}
                    alt={testimonial.name}
                    sx={{
                      width: "100%",
                      height: "150px", // Set a fixed height for the image
                      objectFit: "cover", // Ensure the image covers the area while maintaining aspect ratio
                      borderRadius: "50%",
                      objectPosition: "center",
                    }}
                  />
                </Grid>
              </Grid>
            </Card>
          ))}
        </div>
      </div>

      {/* Modal to display full content */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "600px",
            maxHeight: "80vh", // Limit modal height
            overflowY: "auto", // Enable scrolling for overflow content
            bgcolor: "background.paper",
            // border: "0.5px solid #000",
            boxShadow: 24,
            borderRadius: "5px",
            p: 4,
          }}
        >
          {selectedTestimonial && (
            <>
              <Typography variant="h6" gutterBottom>
                專家推薦 - {selectedTestimonial.name}
              </Typography>
              <Box
              // sx={{
              //   whiteSpace: "pre-wrap", // Preserve newlines and spaces
              //   wordWrap: "break-word", // Break long words if needed
              //   lineHeight: "1.6", // Add line spacing
              // }}
              >
                {selectedTestimonial.text}
              </Box>
              <Button onClick={handleClose} sx={{ mt: 2 }}>
                Close
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default TestimonialSlider;
