// import { Children } from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Footer from "./components/Footer/Footer";
// import Navbar from "./components/Navbar/Navbar";
// import Home from "./pages/Home/Home";
import Home2 from "./pages/Home/Home_dev";
// import Product from "./pages/Product/Product";
// import Products from "./pages/Products/Products";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Activate from "./pages/Activate/Activate";
import NotFound from "./pages/NotFound/NotFound";
// import Slider3 from "./components/Slider/Slider3";
// import MapboxBuy from "./components/Map/MapboxBuy";
// import MapboxSpin from "./components/Map/MapboxSpin";
// import Map from "./components/Map/Map";
// import MapboxSlider from "./components/Map/MapboxSlider";
// import RiskMapAll from "./components/RiskMapAll/RiskMapAll";
// import RiskMap from "./components/RiskMapAll/RiskMap";
// import RiskMapRadius from "./components/RiskMapAll/RiskMapRadius";
// import RiskMapDraw from "./components/RiskMapAll/RiskMapDraw";
// import CityMapDraw from "./components/Map/CityMapDraw";
// import CityMapRadius from "./components/Map/CityMapRadius";
// import CityMapBuilding from "./components/Map/CityMapBuilding";
// import ChooseMap from "./components/Map/ChooseMap";
import Dashboard from "./components/Dashboard/OrderDashboard";
// import MultiRiskDashboard from "./components/MultiRiskDashboard/MultiRiskDashboard";
// import ReportPage from "./components/Dashboard/ReportPage";
// import CartPage from "./components/Cart/CartPage";
// import CartPage from "./components/Cart/CartPageRisk";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./app.scss";
import Payment from "./pages/Payment/Payment2";
import PaymentResult from "./pages/Payment/PaymentResult";
import Profile from "./pages/Profile/Profile";
import About from "./pages/About/About";
import Tech from "./pages/Tech/Tech";
// import Report from "./pages/Report/Report";
import Contact from "./pages/Contact/Contact";
import ServiceTerm from "./pages/ServiceTerm/ServiceTerm";
import PrivacyTerm from "./pages/PrivacyTerm/PrivacyTerm";
import BuyPoint from "./pages/BuyPoint/BuyPoint";
// import ComingSoon from "./pages/ComingSoon/ComingSoon";
// import Select from "./pages/Select/Select";
import Product_intro from "./pages/Product_intro/Product_intro";
// import CityMap from "./components/Map/CityMap";
// import RiskCityMap from "./components/Map/RiskCityMap";
import NavbarAppbar from "./components/Navbar/NavbarAppbar2";
import Qa from "./pages/Qa/Qa";
// import Video from "./pages/Video/Video";
// import WFS from "./components/RiskMapAll/Wfs";
// import RiskHouse from "./components/RiskMapAll/RiskHouse";
// import RiskReport from "./pages/RiskReport/RiskReport";
// import RiskReport2 from "./pages/RiskReport/RiskReport_v2";
// import RiskReport3 from "./pages/RiskReport/RiskReport_v3";
import RiskReport4 from "./pages/RiskReport/RiskReport_v4";
import ProductMap from "./components/Dashboard/ProductMap";
// import MapWithSearch from "./components/Map/MapWithSearch";
// import ChooseType from "./components/RiskMapAll/ChooseType";
// import BuildingRisk from "./components/RiskMapAll/BuildingRisk";
// import MapboxSyncMaps from "./components/RiskMapAll/MapboxSyncMaps";
// import SealevelResult from "./components/RiskMapAll/SealevelResult";
import SearchResult from "./pages/SearchResult/SearchResult";
import SearchResultDraw from "./pages/SearchResult/SearchResultDraw";
// import SearchHomeGoogle from "./components/SearchHome/SearchHomeGoogle";
import Test from "./components/Test/Test";
import TrialReport from "./pages/RiskReport/TrialReport";
import ScrollToTop from "./ScrollToTop";

const Layout = () => {
  return (
    <div className="app">
      {/* <Navbar /> */}
      <NavbarAppbar />
      <ToastContainer autoClose={2000} closeOnClick />
      <ScrollToTop /> {/* Add ScrollToTop here */}
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      // need to remove in prod
      // {
      //   path: "/multi",
      //   element: <MultiRiskDashboard />,
      // },
      {
        path: "/test",
        element: <Test />,
      },
      {
        path: "/trial-report",
        element: <TrialReport />,
      },
      {
        path: "/riskreport/:pkid",
        element: <RiskReport4 />,
      },
      // {
      //   path: "/riskreport_dev/:pkid",
      //   element: <RiskReport4 />,
      // },
      // {
      //   path: "/sync",
      //   element: <MapboxSyncMaps />,
      // },
      {
        path: "/success",
        element: <PaymentResult />,
      },
      {
        path: "/failure",
        element: <PaymentResult />,
      },
      // {
      //   path: "/SearchHomeGoogle",
      //   element: <SearchHomeGoogle />,
      // },
      // {
      //   path: "/riskmapall",
      //   element: <RiskMapAll />,
      // },

      // {
      //   path: "/riskhouse",
      //   element: <RiskHouse />,
      // },
      // {
      //   path: "/riskmap",
      //   element: <RiskMap />,
      // },
      // {
      //   path: "/wfs",
      //   element: <WFS />,
      // },
      // {
      //   path: "/CityMapDraw",
      //   element: <CityMapDraw />,
      // },
      // {
      //   path: "/CityMapBuilding",
      //   element: <CityMapBuilding />,
      // },

      // {
      //   path: "/br",
      //   element: <BuildingRisk />,
      // },
      // {
      //   path: "/ChooseMap",
      //   element: <ChooseMap />,
      // },
      // {
      //   path: "/Choosetype",
      //   element: <ChooseType />,
      // },
      // {
      //   path: "/ChooseMap/:countyCode",
      //   element: <ChooseMap />,
      // },

      // need to remove in prod

      {
        path: "/About",
        element: <About />,
      },
      {
        path: "/productmap",
        element: <ProductMap />,
      },
      // {
      //   path: "/Select",
      //   element: <Select />,
      // },
      // {
      //   path: "/select/:countyCode",
      //   element: <Select />,
      // },
      // {
      //   path: "/mapsearch",
      //   element: <MapWithSearch />,
      // },

      {
        path: "/BuyPoint",
        element: <BuyPoint />,
      },

      // {
      //   path: "/Video",
      //   element: <Video />,
      // },
      // {
      //   path: "/CityMap",
      //   element: <CityMap />,
      // },
      // {
      //   path: "/RiskCityMap",
      //   element: <RiskCityMap />,
      // },

      // {
      //   path: "/city",
      //   // path: "/CityMapRadius",
      //   element: <CityMapRadius />,
      // },

      // {
      //   path: "/MapboxSlider",
      //   element: <MapboxSlider />,
      // },
      // {
      //   path: "/Map",
      //   element: <Map />,
      // },

      // {
      //   path: "/riskmap/:countyCode",
      //   element: <RiskMap />,
      // },
      // {
      //   path: "/riskmapradius/:countyCode",
      //   element: <RiskMapRadius />,
      // },
      // {
      //   path: "/riskmapdraw/:countyCode",
      //   element: <RiskMapDraw />,
      // },
      {
        path: "/search_result",
        element: <SearchResult />,
      },
      {
        path: "/search_result_draw",
        element: <SearchResultDraw />,
      },
      {
        path: "/Product_intro",
        element: <Product_intro />,
      },
      {
        path: "/PrivacyTerm",
        element: <PrivacyTerm />,
      },
      {
        path: "/ServiceTerm",
        element: <ServiceTerm />,
      },
      // {
      //   path: "/Report",
      //   element: <Report />,
      // },

      {
        path: "/Contact",
        element: <Contact />,
      },
      {
        path: "/Tech",
        element: <Tech />,
      },
      // {
      //   path: "/",
      //   element: <Home />,
      // },
      {
        path: "/",
        element: <Home2 />,
      },
      // {
      //   path: "/home_dev",
      //   element: <Home2/>,
      // },
      {
        path: "/payment",
        element: <Payment />,
      },
      {
        path: "/Profile",
        element: <Profile />,
      },
      // {
      //   path: "/cart",
      //   element: <CartPage />,
      // },
      {
        path: "/Dashboard",
        element: <Dashboard />,
      },
      // {
      //   path: "/product/:pkid",
      //   element: <ReportPage />,
      // },
      // {
      //   path: "/riskreport/:pkid",
      //   element: <RiskReport />,
      // },
      // {
      //   path: "/riskreport/:pkid",
      //   // element: <RiskReport2 />,
      //   element: <RiskReport3 />,
      // },

      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/activate/:uid/:token",
        element: <Activate />,
      },
      // {
      //   path: "/comingsoon",
      //   element: <ComingSoon />,
      // },
      {
        path: "/qa",
        element: <Qa />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
