import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import "./PgaProbBuilding.scss";

const PgaProbBuilding = ({ report, polygonData, targetInfo }) => {
  const [designPga, setDesignPga] = useState(0.25);
  // const [hoverPga, setHoverPga] = useState(null); // New state for tracking hover pga
  const [chartData, setChartData] = useState([]);
  const [buildingType, setBuildingType] = useState(1);
  const [buildingTime, setBuildingTime] = useState(1);
  const [buildingMaterial, setBuildingMaterial] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [earthquakeLoss, setEarthquakeLoss] = useState(null);
  // const productTargetInfo = report.product.target_info;
  const productTargetInfo = JSON.parse(report.product.target_info);

  const risk_results_earthquake = report.risk_results["earthquake"];
  const min_fault_dist = report.risk_results["earthquake"][4];
  const earthquake_loss = report.risk_results["earthquake"][0];
  const earthquake_level = report.risk_results["earthquake"][1];
  const pga_all = report.risk_results["earthquake"][2];
  const cwb_level = report.risk_results["earthquake"][3];
  const design_pga_loss_rate = report.risk_results["earthquake"][7];
  const rebuild_price_per_ping = report.risk_results["earthquake"][5];
  const renovationValue = productTargetInfo?.["renovationValue"];
  const pingAll = productTargetInfo?.["pingAll"];
  // const floor_number = targetInfo.floorNumber;
  // const material = targetInfo.buildingMaterial;
  // const building_value = targetInfo.buildingValue;

  // console.log("floor_number", floor_number);
  // console.log("productTargetInfo", productTargetInfo);
  // console.log("report", report);
  // console.log("material", material);
  // console.log("building_value", building_value);
  // console.log("earthquakeLoss", earthquakeLoss);

  const handleBuildingTypeChange = (event) => {
    const newBuildingType = event.target.value;
    setBuildingType(newBuildingType);
  };
  const handleBuildingTimeChange = (event) => {
    const newBuildingTime = event.target.value;
    setBuildingTime(newBuildingTime);
  };
  const handleBuildingMaterialChange = (event) => {
    const newBuildingMaterial = event.target.value;
    setBuildingMaterial(newBuildingMaterial);
  };

  const handleDesignPgaChange = (event) => {
    setDesignPga(parseFloat(event.target.value));
  };

  return (
    <div style={{ fontFamily: "Arial", padding: "0px 20px", marginBottom: "10px" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src="/img/earth_loss_example.png"
          className="responsive-img"
          alt="Earthquake Loss Example"
        />
      </div>
      <div>
        <ul style={{ fontSize: "16px", fontWeight: "bold" }}>
          <li>
            本建物50年內發生全倒的機率小於
            <span style={{ color: "red" }}>
              {(
                (1 - (1 - report.risk_results["earthquake"][2][2]) ** 50) *
                100
              ).toFixed(2)}
              %
            </span>
            ，亦即每年發生機率小於
            <span style={{ color: "red" }}>
              {(report.risk_results["earthquake"][2][2] * 100).toFixed(2)}%/年
            </span>
            。
          </li>
          <li>
            依台灣的地震危害度分析與建築脆弱度分析結果(整合國內外研究)，本建物在0.25g震度下房屋損壞比率約為 4.91%。
          </li>
          <li>
            由建築脆弱度分析可知，不同震度對建築物會對建築物有或大或小的損壞，小至牆壁出現裂痕、大至樑柱結構損壞或半倒全倒。
          </li>

          <li>
            目前您的房屋仍有
            <span style={{ color: "red" }}>
              {(report.risk_results["earthquake"][5] *
                productTargetInfo?.["pingAll"]) /
                10000}
            </span>
            萬元的保險缺額。
          </li>
          <li>
            本系統建議可加購超額地震險、擴大地震險、輕損地震險，不只在全倒或半倒，房屋在發生任何地震損壞時，都能夠得到理賠，藉以保障您的重要資產。
          </li>
        </ul>
      </div>

      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
          margin: "10px",
        }}
      >
        <img style={{ width: "90%" }} src="/img/地震分級.png" alt="地震分級" />
        <p style={{ marginTop: "10px", fontSize: "0.8rem", color: "#666" }}>
          資料來源: 交通部中央氣象署
        </p>
      </div> */}

      <div>
        <ul
          // className="content-list"
          style={{ fontSize: "16px", fontWeight: "bold" }}
        >
          <li>
            PGA, Peak Ground Acceleration, 最大地震動加速度。單位是 g,
            重力加速度=980 公分/秒平方
          </li>
          <li>0.001g 比較敏感的人會感受到震動</li>
          <li>0.02g 會感覺到失去平衡</li>
          <li>
            0.5g
            非常大的震動。房屋如果沒有經過耐震設計的話，若震動時間長，將會產生災害。
          </li>
          <li>中央氣象局震度表，震度五級相當於0.08g-0.25g。</li>
        </ul>
      </div>

      {/* <div>
        <ul
          // className="content-list"
          style={{ fontSize: "16px", fontWeight: "bold" }}
        >
          <li>
            依據本系統分析，考量各種地震來源對此地的震度、發生機率與損壞程度，您的房屋在50年內的損壞比率約為
            <span style={{ color: "red" }}>
              {(report.risk_results["earthquake"][6] * 100).toFixed(2)}
              %，每年平均損壞為
              {((report.risk_results["earthquake"][6] * 100) / 50).toFixed(2)}%
            </span>
          </li>
          <li>
            依據產物保險商業同業公會的住宅類建築造價參考，您的房屋建坪為
            {pingAll}
            （每坪重建成本為{rebuild_price_per_ping}元），裝潢為
            {renovationValue}
            萬元，因此您的建物價值成本(此為重建成本)為
            {(rebuild_price_per_ping * pingAll) / 10000 + renovationValue}萬元。
          </li>
          <li>
            根據上述每年平均損壞比率與建物成本，本建物的每年平均損失(年期望損失)為
            <span style={{ color: "red" }}>
              {report.risk_results["earthquake"][0]}元/年
            </span>
            。
          </li>
        </ul>
      </div> */}
      {/* <div style={{ width: "100%", height: 400 }}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <ResponsiveContainer>
            <AreaChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 10, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#000" />
              <XAxis
                dataKey="x"
                name="Probability"
                label={{
                  value: "地震破壞機率(%)",
                  position: "insideBottom",
                  offset: -10,
                  fill: "#000",
                }}
                stroke="#000"
              />
              <YAxis
                dataKey="y"
                name="Depth"
                label={{
                  value: "地表加速度(PGA)",
                  angle: -90,
                  position: "insideLeft",
                  offset: 10,
                  fill: "#000",
                }}
                stroke="#000"
              />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey="y"
                stroke="#8884d8"
                fill="#8884d8"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div> */}
    </div>
  );
};

export default PgaProbBuilding;
