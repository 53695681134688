import axiosInstance from "../../axios";
import "./PointProb.scss";
import * as turf from "@turf/turf";
// import floodhouse from "./ty3_floodhouse_color_upload.geojson";
import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import { point, circle } from "@turf/turf";
import "mapbox-gl/dist/mapbox-gl.css";
import { useDispatch } from "react-redux";

import { stringify } from "wkt";
import Prob3 from "../Prob/Prob3";
import { Grid } from "@mui/material";

const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const gs_username = process.env.REACT_APP_GEOSERVER_BASIC_USERNAME;
const gs_pass = process.env.REACT_APP_GEOSERVER_BASIC_PASSWORD;
mapboxgl.accessToken = mapboxtoken;

const PointProb = ({ detailOrder, report }) => {
  const [opacity, setOpacity] = useState(0.8);
  const [calculatedGeometry, setCalculatedGeometry] = useState(
    report?.product?.calculated_geometry
  );

  const [house, setHouse] = useState({});
  const [rendered, setRendered] = useState(false); // A state to keep track if legend has been rendered
  const [loading, setLoading] = useState(true);
  const [wmsRoad, setWmsRoad] = useState(null);
  const [fetch_url, setFetch_url] = useState(null);

  const dispatch = useDispatch();
  const [pkid, setPkid] = useState(null);
  const [status, setStatus] = useState("");
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [newProb, setNewProb] = useState({
    p10: report?.p10,
    p20: report?.p20,
    p30: report?.p30,
    p40: report?.p40,
    p50: report?.p50,
  });
  // const [coor, setCoor] = useState({
  //   lng: report?.product?.location?.coordinates[0],
  //   lat: report?.product?.location?.coordinates[1],
  // });
  const [coor, setCoor] = useState({ lng: 0, lat: 0 });
  const mapContainer = useRef(null);
  const productPkid = report?.product?.pkid;
  const wms_layer_name = detailOrder?.wms_layer_name;

  //first time use the origin prob from report, later on use api

  const getNewProb = async (coor) => {
    setStatus("計算中請稍等...");
    try {
      if (!productPkid || !coor.lng || !coor.lat) {
        console.error(
          "Missing required values: report.product.pkid, coor.lng, coor.lat"
        );
        setStatus("計算錯誤，請選擇圓圈中點位");

        return;
      }
      const data = {
        product_pkid: productPkid,
        x: coor.lng,
        y: coor.lat,
      };

      const res = await axiosInstance.post("shop/calc_point_prob/", data);
      // console.log(res);
      const taskId = res.data.task_id; // Save the task id
      if (taskId) {
        // Periodically fetch the task status
        const intervalId = setInterval(async () => {
          const taskRes = await axiosInstance.get(
            `shop/task-status/${taskId}/`
          );
          // setStatus('計算中...');
          if (taskRes.data.status === "SUCCESS") {
            clearInterval(intervalId); // Stop the polling if the task is complete
            setStatus("計算完成");
            const prob = taskRes.data.result[2]; // the result is x, y, exceedance_probability, count_
            // console.log(taskRes.data.result)
            setNewProb({
              p10: prob[0],
              p20: prob[1],
              p30: prob[2],
              p40: prob[3],
              p50: prob[4],
            });
          }
        }, 3000); // Poll every 5 seconds
      } else {
        setStatus(res.data.detail);
      }
    } catch (err) {
      console.log(err);
      setStatus("計算錯誤，請稍後再嘗試!");
    }
  };

  // circle4326
  // console.log(detailOrder);
  // console.log("report:", report);

  // console.log(house);

  // get buildings
  useEffect(() => {
    if (!wms_layer_name) {
      return;
    }

    const fetch_building = (fetch_url) => {
      fetch(fetch_url)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          return response.json(); // Parse the original response as JSON
        })
        .then((data) => {
          setHouse(data);
          setLoading(false);
          // console.log(loading)
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false);
        });
    };

    if (wms_layer_name === "taoyuan_1_v1_350mm6hr-max-water-depth_merge") {
      setFetch_url("/building/ty1_floodhouse_color_uploadv2.geojson");
      setWmsRoad("staging:ty1_350mm6hr_road0");
      fetch_building("/building/ty1_floodhouse_color_uploadv2.geojson");
    } else if (
      wms_layer_name === "taoyuan_2_v11_350mm6hr-max-water-depth_merge"
    ) {
      setFetch_url("/building/ty2_floodhouse_color_upload.geojson");
      setWmsRoad("staging:ty2_350mm6hr_road0");
      fetch_building("/building/ty2_floodhouse_color_upload.geojson");
    } else if (
      wms_layer_name === "taoyuan_3_v5_350mm6hr-max-water-depth_merge"
    ) {
      setFetch_url("/building/ty3_floodhouse_color_upload_diff.geojson");
      setWmsRoad("staging:ty3_350mm6hr_road0");
      fetch_building("/building/ty3_floodhouse_color_upload_diff.geojson");
    } else {
      console.log("no building geo in this area yet");
      console.log(wms_layer_name);
      setFetch_url("/building/ty3_floodhouse_color_upload_diff.geojson");
      setWmsRoad("staging:ty3_350mm6hr_road0");
      fetch_building("/building/ty3_floodhouse_color_upload_diff.geojson");
    }
  }, [wms_layer_name]);

  // console.log(fetch_url);
  // console.log(loading);

  useEffect(() => {
    const getPkid = async () => {
      try {
        const res = await axiosInstance.get("auth/users/me/");
        setPkid(res.data.pkid);
      } catch (err) {}
    };

    getPkid();
  }, []);
  // console.log(house);

  // ini map
  useEffect(() => {
    if (!house || loading || !calculatedGeometry) {
      // Don't proceed if the necessary data is not available
      console.log("Waiting for data...");
      return;
    }

    // Calculate the center and bbox from the calculatedGeometry
    const center = turf.center(calculatedGeometry).geometry.coordinates;
    if (!center[0] || !center[1]) {
      console.error("Invalid center coordinates");
      return;
    }
    const bbox = turf.bbox(calculatedGeometry);
    const product_bbox = [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ];

    // Calculate the center from the calculatedGeometry
    const centerCoordinates =
      turf.center(calculatedGeometry).geometry.coordinates;
    if (!centerCoordinates[0] || !centerCoordinates[1]) {
      console.error("Invalid center coordinates");
      return;
    }

    // Update the coor state with the center coordinates
    setCoor({ lng: centerCoordinates[0], lat: centerCoordinates[1] });

    // wkt3857 for raster clipping
    const wkt3857 = stringify(report?.product?.calculated_geometry_3857);

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/wayne32545/cle6fqgc3000601t6g17e3arw",
      transformRequest: (url, resourceType) => {
        if (
          (resourceType === "Source" || resourceType === "Tile") &&
          // url.indexOf("http://localhost:8080/geoserver/staging/wms") > -1
          url.indexOf(geoserver_url + `geoserver/staging/wms`) > -1
        ) {
          const username = gs_username;
          const password = gs_pass;
          const credentials = btoa(`${username}:${password}`);
          const headers = {
            Authorization: `Basic ${credentials}`,
          };
          return {
            url: url,
            headers: headers,
            credentials: "include", // Include cookies for cross-origin requests
          };
        }
      },
      center: [center[0], center[1]],

      zoom: 15.5,
      minZoom: 15.5,
      dragPan: false,
    });

    map.fitBounds(product_bbox, { padding: 20 });

    // layer control

    class CustomControl {
      constructor() {
        this.currentStyle = "mapbox://styles/mapbox/streets-v12"; // Set the initial style here
        this.currentWmsLayer = null; // Track the current WMS layer
        this.currentStyle2 =
          "mapbox://styles/wayne32545/cle6fqgc3000601t6g17e3arw"; // Set the initial style here
        this.currentWmsLayer2 = null; // Track the current WMS layer
        this.isFirstWMSLayer = true;
      }
      removeCurrentWmsLayer(layerId) {
        if (layerId === this.currentWmsLayer && map.getLayer(layerId)) {
          map.removeLayer(layerId);
          if (
            map.getSource(
              `wms-test-source${layerId.charAt(layerId.length - 1)}`
            )
          ) {
            map.removeSource(
              `wms-test-source${layerId.charAt(layerId.length - 1)}`
            );
          }
          this.currentWmsLayer = null;
        }
      }

      onAdd(map) {
        this.map = map;
        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

        // Style Control
        const styleControl = document.createElement("button");
        styleControl.className =
          "basemap_icon mapboxgl-ctrl-icon mapboxgl-ctrl-style ";
        styleControl.textContent = "衛星底圖";

        styleControl.onclick = () => {
          // Check the current style and switch to the other one
          const newStyle2 =
            this.currentStyle2 ===
            "mapbox://styles/wayne32545/cle6fqgc3000601t6g17e3arw"
              ? "mapbox://styles/mapbox/satellite-v9"
              : "mapbox://styles/wayne32545/cle6fqgc3000601t6g17e3arw";

          this.currentStyle2 = newStyle2;
          map.setStyle(newStyle2);

          // Listen for the 'styledata' event on the map
          map.once("styledata", () => {
            if (!map.getSource("composite")) {
              // Add the 'composite' source and '3d-buildings' layer if they don't exist
              map.addSource("composite", {
                type: "vector",
                url: "mapbox://mapbox.mapbox-streets-v8",
              });
            }
          });
        };

        this.container.appendChild(styleControl);
        // houseControl
        const houseControl = document.createElement("button");
        houseControl.className =
          "basemap_icon mapboxgl-ctrl-icon mapboxgl-ctrl-wms3";
        houseControl.textContent = "淹水房屋";

        const houseButtonClick = () => {
          if (map.getLayer("filteredData")) {
            let currentColor = map.getPaintProperty(
              "filteredData",
              "fill-extrusion-color"
            );

            if (currentColor === "black") {
              map.setPaintProperty("filteredData", "fill-extrusion-color", [
                "get",
                "color",
              ]);
            } else {
              map.setPaintProperty(
                "filteredData",
                "fill-extrusion-color",
                "black"
              );
            }
          }
        };

        houseControl.onclick = houseButtonClick;
        this.container.appendChild(houseControl);
        // WMS3 Control
        const wms3Control = document.createElement("button");
        wms3Control.className =
          "basemap_icon mapboxgl-ctrl-icon mapboxgl-ctrl-wms3";
        wms3Control.textContent = "淹水道路";
        // let isClicked = false; // Tracks whether the button has been clicked

        const handleButtonClick = () => {
          // Toggle between the first and second WMS layer URLs
          const newWMSLayerURL = this.isFirstWMSLayer
            ? geoserver_url +
              `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=${wmsRoad}&FORMAT=image/png&TILED=true&clip=${wkt3857}`
            : geoserver_url +
              `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:${wms_layer_name}&FORMAT=image/png&TILED=true&clip=${wkt3857}`;

          // Remove the existing WMS layer
          map.removeLayer("wms-test-layer");
          map.removeSource("wms-test-source");

          // Add a new WMS layer with the updated URL
          map.addSource("wms-test-source", {
            type: "raster",
            tiles: [newWMSLayerURL],
            tileSize: 256,
          });
          map.addLayer(
            {
              id: "wms-test-layer",
              type: "raster",
              source: "wms-test-source",
              paint: {},
            },
            "filteredData"
            // "room-extrusion"
          );
          // Toggle the state variable
          this.isFirstWMSLayer = !this.isFirstWMSLayer;
        };
        wms3Control.onclick = handleButtonClick;
        this.container.appendChild(wms3Control);

        // Opacity Control
        const opacityControl = document.createElement("label");
        opacityControl.className = "opacity-control";

        const opacityLabel = document.createElement("span");
        opacityLabel.textContent = "房屋透明度: ";
        opacityLabel.className = "opacity-label";

        const opacityRange = document.createElement("input");
        opacityRange.type = "range";
        opacityRange.min = "0";
        opacityRange.max = "1";
        opacityRange.step = "0.1";
        opacityRange.value = this.currentOpacity || "0.8"; // Set initial opacity value
        opacityRange.oninput = (event) => {
          const newOpacity = parseFloat(event.target.value);
          setOpacity(newOpacity);

          this.currentOpacity = newOpacity;
          // Update the opacity of the layers
          map.setPaintProperty(
            "filteredData",
            "fill-extrusion-opacity",
            newOpacity
          );
        };

        opacityControl.appendChild(opacityLabel);
        opacityControl.appendChild(opacityRange);
        this.container.appendChild(opacityControl);

        // Rest of the code...

        return this.container;
      }

      onRemove() {
        this.removeCurrentWmsLayer();
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
      }
    }

    // Then you would add the control like this:
    const control = new CustomControl();
    map.addControl(control, "top-right");
    // layer control

    map.addControl(new mapboxgl.NavigationControl(), "top-right");
    map.addControl(new mapboxgl.FullscreenControl(), "top-right");

    const ProductMarker = new mapboxgl.Marker({
      color: "black",
      // draggable: false,
    })
      .setLngLat([center[0], center[1]])
      .addTo(map);

    const layers = [
      "0.0-0.1",
      "0.1-0.2",
      "0.2-0.3",
      "0.3-0.4",
      "0.4-0.5",
      "> 0.5",
    ];
    const colors = [
      "rgba(255, 255, 255, 0.5)",
      "rgba(240, 255, 255, 1)",
      "rgba(30, 144, 255, 1)",
      "rgba(255, 255, 0, 1)",
      "rgba(255, 140, 0, 1)",
      "rgba(255, 0, 0, 1)",
    ];
    if (!rendered) {
      // create legend
      const legend = document.getElementById("legend");

      layers.forEach((layer, i) => {
        const color = colors[i];
        const item = document.createElement("div");
        const key = document.createElement("span");
        key.className = "legend-key";
        key.style.backgroundColor = color;

        const value = document.createElement("span");
        value.innerHTML = `${layer}`;
        item.appendChild(key);
        item.appendChild(value);
        legend.appendChild(item);
      });
      setRendered(true); // Update the rendered state to true
    }

    map.on("style.load", () => {
      //circle
      map.addSource("polygon", {
        type: "geojson",
        data: detailOrder?.calculated_geometry,
      });
      map.addLayer({
        id: "polygon-layer",
        type: "fill",
        source: "polygon",
        paint: {
          "fill-color": "transparent",
        },
      });
      map.addLayer({
        id: "polygon-border-layer",
        type: "line",
        source: "polygon",
        paint: {
          "line-color": "black",
          "line-width": 2,
          "line-dasharray": [2, 2],
        },
      });

      //wms
      map.addSource("wms-test-source", {
        type: "raster",
        tiles: [
          geoserver_url +
            // `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:taoyuan_1_v1_350mm6hr-max-water-depth_merge&FORMAT=image/png&TILED=true&clip=${wkt3857}`,
            `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:${wms_layer_name}&FORMAT=image/png&TILED=true&clip=${wkt3857}`,
        ],

        tileSize: 256,
      });
      map.addLayer(
        {
          id: "wms-test-layer",
          type: "raster",
          source: "wms-test-source",
          paint: {},
        }
        // "building"
      );

      map.setPaintProperty("wms-test-layer", "raster-opacity", 0.7);

      const originalGeoJson = house;

      // Define your polygon/ filter cirle area
      const polygon = turf.polygon(
        detailOrder?.calculated_geometry?.coordinates
      );

      // Filter GeoJSON data based on the polygon

      const filteredGeoJson = {
        type: "FeatureCollection",
        features: [],
      };

      // Iterate through each feature in the original GeoJSON
      originalGeoJson.features?.forEach((feature) => {
        // Check if the feature is a MultiPolygon
        if (feature.geometry.type === "MultiPolygon") {
          // Iterate through each Polygon in the MultiPolygon
          feature.geometry.coordinates.forEach((singlePolygonCoordinates) => {
            // Create a new single Polygon feature
            const singlePolygonFeature = {
              type: "Feature",
              properties: { ...feature.properties }, // copy original properties
              geometry: {
                type: "Polygon",
                coordinates: singlePolygonCoordinates,
              },
            };

            // Check if the polygon completely contains the single Polygon
            const isContained = turf.booleanContains(
              polygon,
              singlePolygonFeature
            );
            if (isContained) {
              // If the Polygon is within the polygon, add it to the filtered GeoJSON
              filteredGeoJson.features.push(singlePolygonFeature);
            }
          });
        } else {
          // If the feature is not a MultiPolygon, check if it's within the polygon as before
          const isContained = turf.booleanContains(polygon, feature);
          if (isContained) {
            filteredGeoJson.features.push(feature);
          }
        }
      });

      // console.log(wmsRoad);
      // console.log(wms_layer_name);
      // console.log(filteredGeoJson);
      // console.log(fetch_url)

      if (filteredGeoJson.features.length > 0) {
        map.addSource("filteredData", {
          type: "geojson",
          data: filteredGeoJson,
        });
        map.addLayer({
          id: "filteredData",
          type: "fill-extrusion",
          source: "filteredData",
          paint: {
            "fill-extrusion-color": ["get", "color"],
            // "fill-extrusion-color": ["get", "color_demo"],
            "fill-extrusion-height": ["get", "build_h"],
            "fill-extrusion-opacity": opacity,
            // "fill-extrusion-opacity": 0.8,
          },
        });
      } else {
        console.log("No features within the polygon");
        // console.log(filteredGeoJson);
      }
    });

    setMap(map);

    return () => {
      map.remove();
    };
  }, [house, loading, calculatedGeometry]);

  useEffect(() => {
    if (!map) return;

    const handleMapClick = (e) => {
      const { lng, lat } = e.lngLat;
      if (marker) {
        marker.setLngLat([lng, lat]);
        setCoor({ lng, lat });
        map.flyTo({
          center: [lng, lat],
          duration: 1000,
          essential: true,
          zoom: 18,
        });
      } else {
        const newMarker = new mapboxgl.Marker()
          .setLngLat([lng, lat])
          .addTo(map);

        setMarker(newMarker);

        setCoor({ lng, lat });
        map.flyTo({
          center: [lng, lat],
          duration: 1000,
          essential: true,
          zoom: 18,
        });
      }
    };

    map.on("click", handleMapClick);

    return () => {
      map.off("click", handleMapClick);
    };
  }, [map, marker]);

  return (
    <div className="prob-container">
      <Grid item xs={12} md={6}>
        <div className="prob-button">
          <button onClick={() => getNewProb(coor)}>開始計算</button>
          <span>{`經度:${coor?.lng?.toFixed(5)}`}</span>
          <span>{`緯度:${coor?.lat?.toFixed(5)}`}</span>
        </div>
        <span>{status}</span>

        <div className="prob-choose-container">
          {loading ? (
            <div
              style={{
                height: "45vh",
                fontSize: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bolder",
              }}
            >
              Map Loading...
            </div>
          ) : (
            <div
              ref={mapContainer}
              className="prob-mapContainer"
              style={{ height: "45vh" }}
            />
          )}
          <div className="map-overlay" id="legend">
            水深(m)
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="prob-component-container">
          <Prob3 report={report} newProb={newProb} />
        </div>
      </Grid>
    </div>
  );
};

export default PointProb;
