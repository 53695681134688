import React, { useEffect, useState } from "react";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import RiskRadarChart from "../../components/Charts/RiskRadarChart";
import "./RiskReport.scss";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../axios";
import RiskMapClip from "../../components/RiskMapAll/RiskMapClip";
import RiskMapClipSeaLevel from "../../components/RiskMapAll/RiskMapClipSeaLevel";
import RiskMapClipLiq from "../../components/RiskMapAll/RiskMapClipLiq";
import RiskMapClipEarthquake from "../../components/RiskMapAll/RiskMapClipEarthquake";
import RiskMapClipLandSlide from "../../components/RiskMapAll/RiskMapClipLandSlide2";
import PointProb from "../../components/Map/PointProbRisk";
// import PointProb from "../../components/Map/PointProbAll";
import Prob2 from "../../components/Prob/Prob2";
import DepthProb from "../../components/Prob/DepthProb";
import DepthProbTable from "../../components/Prob/DepthProbTable";
import ReportMapSlider from "../../components/Map/ReportMapSlider";
import ReportMapSlider2 from "../../components/Map/ReportMapSlider2";
import html2canvas from "html2canvas";
import axios from "axios";
import { Tabs, Tab } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home"; // Example icon
import InfoIcon from "@mui/icons-material/Info"; // Another example icon
import DiamondIcon from "@mui/icons-material/Diamond";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import FloodIcon from "@mui/icons-material/Flood";
import PublicIcon from "@mui/icons-material/Public";

import HouseboatIcon from "@mui/icons-material/Houseboat";
import LandslideIcon from "@mui/icons-material/Landslide";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import BuildingRisk from "../../components/RiskMapAll/BuildingRisk";
import BuildingRiskAll from "../../components/RiskMapAll/BuildingRiskAll";
import FloodResult from "../../components/RiskMapAll/FloodResult";
import EerthquakeResult from "../../components/RiskMapAll/EerthquakeResult";
import TsunamiIcon from "@mui/icons-material/Tsunami";
import { RiEarthquakeFill } from "react-icons/ri";
import LiqResult from "../../components/RiskMapAll/LiqResult";
import SealevelResult from "../../components/RiskMapAll/SealevelResult";
import BuildingMap from "../../components/RiskMapAll/BuildingMap";
import FloodMapboxSyncMaps from "../../components/RiskMapAll/FloodMapboxSyncMaps";
import Compare from "../../components/RiskMapAll/Compare";
// import PaidReport from "./PaidReport";
import PaidReport from "./PaidReport";
import FreeReport from "./FreeReport";
import Summary from "./Summary";

const SelectVersion = ({ report, productTargetInfo }) => {
  const { pkid } = useParams();
  //   const [report, setReport] = useState({});
  // State to hold the parsed product target info
  // const [productTargetInfo, setProductTargetInfo] = useState(null);
  // console.log(productTargetInfo);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const CustomTab = ({ label, icon }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // Optionally adjust icon size here if it's a component
    const adjustedIcon = React.cloneElement(icon, {
      style: {
        // fontSize: isMobile ? "16px" : "24px",
        fontSize: isMobile ? "24px" : "36px", // Adjust the icon size based on screen size
        color: "black", // Set icon color to black
      },
    });

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontWeight: "bold",
          color: "black", // Set text color to black
        }}
      >
        {adjustedIcon}
        {!isMobile && <span style={{ marginLeft: 8 }}>{label}</span>}
      </div>
    );
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div style={{ width: "100%" }}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        // variant="fullWidth"
        scrollButtons="auto"
        variant="scrollable"
        sx={{
          marginBottom: "24px", // Adjust the value as needed
          "& .MuiTabs-flexContainer": {
            justifyContent: "center", // Center the tabs if using flex layout
          },
        }}
      >
        <Tab
          sx={(theme) => ({
            minWidth: 0,
            marginRight: theme.spacing(1),
            padding: theme.spacing(1),
            [theme.breakpoints.down("sm")]: {
              marginRight: theme.spacing(0.5), // Less space on small screens
            },
          })}
          label={<CustomTab label="安家免費版" icon={<PublicIcon />} />}
        />
        <Tab
          sx={(theme) => ({
            minWidth: 0,
            marginRight: theme.spacing(1),
            padding: theme.spacing(1),
            [theme.breakpoints.down("sm")]: {
              marginRight: theme.spacing(0.5), // Less space on small screens
            },
          })}
          //   label={<CustomTab label="安家付費版" icon={<DiamondIcon />} />}
          label={<CustomTab label="安家付費版" icon={<LockOpenIcon />} />}
        />
      </Tabs>
      {activeTab === 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <FreeReport report={report} />
            {/* <Typography className="section">公開風險地圖</Typography>
            <RiskMapClip report={report} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography className="section">公開風險地圖說明</Typography>
            <Typography
              sx={{ padding: "15px" }}
              variant="body1"
              component="div"
              gutterBottom
            >
              公開淹水圖採用350mm6hr極端降雨(約十年重現期)
            </Typography> */}
          </Grid>
        </Grid>
      )}
      {activeTab === 1 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <PaidReport report={report} productTargetInfo={productTargetInfo} />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default SelectVersion;
