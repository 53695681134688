const floodLevels = [
  {
    level: "10公分淹水",
    impacts: [
      {
        category: "人",
        description:
          "對成人來說，風險較低。但對於小孩和老年人，可能存在滑倒風險。盡量避免讓他們在此深度的積水中行走。",
      },
      {
        category: "房子",
        description:
          "房屋可能出現輕微的損害，如地毯潮濕，修復費用可能在幾百到幾千元。",
      },
      {
        category: "機車",
        description: "風險相對較低，但要注意避免水花進入引擎或排氣管。",
      },
      {
        category: "汽車",
        description: "影響較小，但需要注意避免水花飛濺到引擎。",
      },
    ],
  },
  {
    level: "20公分淹水",
    impacts: [
      {
        category: "人",
        description:
          "對成人來說，風險較低。但對於小孩和老年人，可能存在滑倒風險。盡量避免讓他們在此深度的積水中行走。",
      },
      {
        category: "房子",
        description:
          "房屋可能出現輕微的損害，如地毯潮濕，修復費用可能在幾百到幾千元。",
      },
      {
        category: "機車",
        description: "風險相對較低，但要注意避免水花進入引擎或排氣管。",
      },
      {
        category: "汽車",
        description: "影響較小，但需要注意避免水花飛濺到引擎。",
      },
    ],
  },
  {
    level: "30公分淹水",
    impacts: [
      {
        category: "人",
        description:
          "此深度的積水可能導致所有人都有生命危險，特別是對於無法游泳的人來說。避免涉水是最好的選擇。",
      },
      {
        category: "房子",
        description:
          "如果水位達到這種深度，可能會直接對房屋結構造成損害，並導致內部財產遭受重大損失。修復成本可能會達到數萬元，甚至更高。",
      },
      {
        category: "機車",
        description:
          "此深度的積水可能會導致機車完全熄火，並可能對電路和引擎造成損壞。修理成本可能達到幾千元，或者可能需要更換新車。",
      },
      {
        category: "汽車",
        description:
          "如果積水達到這種深度，汽車可能會無法運作，並可能導致嚴重的機械損壞。修理成本可能在數千到數萬元之間，或者可能需要更換新車。",
      },
    ],
  },
  {
    level: "40公分淹水",
    impacts: [
      {
        category: "人",
        description:
          "此深度的積水會對所有人都有生命危險，特別是對於無法游泳的人來說。在這種情況下，避免涉水是最好的選擇。",
      },
      {
        category: "房子",
        description:
          "此深度的積水會對房屋結構造成重大損害，包括地板、牆壁和電氣系統。修復成本可能達到數萬元，甚至上百萬元。",
      },
      {
        category: "機車",
        description:
          "此深度的積水將導致機車全面熄火，引擎、電路和內部部件可能全部損壞，可能需要全面更換，成本可能達到新車價格。",
      },
      {
        category: "汽車",
        description:
          "此深度的積水會導致引擎進水並可能引發嚴重機械損壞。修復可能需要花費數萬元，或者需要更換新車。",
      },
    ],
  },
  {
    level: "50公分淹水",
    impacts: [
      {
        category: "人",
        description:
          "此深度的積水對所有人都構成巨大生命危險。人們應該絕對避免在此深度的水中嘗試涉水。",
      },
      {
        category: "房子",
        description:
          "此深度的積水會對房屋結構和內部設施造成極大的損害，可能導致房屋不再適合居住。修復成本可能達到上百萬元。",
      },
      {
        category: "機車",
        description: "此深度的積水將使機車全面損壞，需要更換新車。",
      },
      {
        category: "汽車",
        description: "此深度的積水將導致汽車全面損壞，可能需要更換新車。",
      },
    ],
  },

  // add other flood levels here...
];

export default floodLevels;
