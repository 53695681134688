import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Spinner/Spinner";
import Title from "../../components/Title/Title";
import { toast } from "react-toastify";
import { register, reset } from "../../features/auth/authSlice";
import "./register.scss";
import ServiceTerm from "../ServiceTerm/ServiceTerm";
import PrivacyTerm from "../PrivacyTerm/PrivacyTerm2";

const Register = () => {
  const [username, setUsername] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [re_password, setRePassword] = useState("");

  // Modals
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (user) {
      toast.error("目前為登入狀態，切換使用者請先登出!");
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    if (isError) {
      toast.error(message || "請使用有效信箱，或是8位以上的密碼再進行嘗試。");
    }

    if (isSuccess) {
      toast.success("驗證信件已寄到您的信箱，請收信驗證以啟動帳號!");
      navigate("/");
      dispatch(reset());
    }

    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message, navigate, dispatch]);

  const validatePassword = (password) => {
    const minLength = 8;

    if (password.length < minLength) {
      toast.error(`密碼需至少 ${minLength} 個字元長度`);
      return false;
    }

    if (!isNaN(password)) {
      toast.error("密碼不可為全數字，請包含字母或符號");
      return false;
    }

    return true;
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!validatePassword(password)) {
      return;
    }

    if (password !== re_password) {
      toast.error("兩次密碼不相同，請再次確認密碼輸入一致");
      return;
    }

    const userData = {
      username,
      first_name,
      last_name,
      email,
      password,
      re_password,
    };

    dispatch(register(userData));
  };

  return (
    <div className="register-background">
      <Title title="安家百科-帳號註冊" />
      {isLoading && <Spinner />}
      <div className="register-container">
        <div className="card">
          <div className="left">
            <h1>安家百科 帳號註冊</h1>
            <span>
              您已經有帳號了嗎? <Link to="/login">點我登入</Link>
            </span>
            <p style={{ fontSize: "10px" }}>密碼請輸入8位以上的英文+數字</p>
          </div>

          <div className="right">
            <form onSubmit={submitHandler}>
              <input
                type="text"
                placeholder="使用者名稱"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                type="text"
                placeholder="名字"
                value={first_name}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <input
                type="text"
                placeholder="姓氏"
                value={last_name}
                onChange={(e) => setLastName(e.target.value)}
              />
              <input
                type="email"
                placeholder="電子信箱"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="輸入密碼"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                type="password"
                placeholder="再次輸入密碼"
                value={re_password}
                onChange={(e) => setRePassword(e.target.value)}
              />
              <button type="submit">註冊</button>
            </form>

            {/* Add the agreement text here */}
            <div className="agreement-text">
              <p>
                一旦完成，本人即同意安家百科的{" "}
                <span
                  className="re-link"
                  onClick={() => setShowPrivacyModal(true)}
                >
                  《隱私權條款》
                </span>{" "}
                及{" "}
                <span
                  className="re-link"
                  onClick={() => setShowServiceModal(true)}
                >
                  《網站會員條款》
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Privacy Modal */}
      {showPrivacyModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowPrivacyModal(false)}>
              &times;
            </span>
            <PrivacyTerm />
          </div>
        </div>
      )}

      {/* Service Terms Modal */}
      {showServiceModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowServiceModal(false)}>
              &times;
            </span>
            <ServiceTerm />
          </div>
        </div>
      )}
    </div>
  );
};

export default Register;
