import React from "react";
import "./ProductCompare.css";

const ProductCompare = () => {
  return (
    <div className="table-container">
      <table className="disaster-table">
        <thead>
          <tr>
            <th>災害</th>
            <th>資料來源</th>
            <th>考量災害規模情境</th>
            <th>災害衝擊</th>
            <th>發生機率</th>
            <th>特定分析對象</th>
            <th>期望損失 (每年平均損失)</th>
            <th>安家建議</th>
          </tr>
        </thead>
        <tbody>
          {/* 淹水 */}
          <tr>
            <td rowSpan="2">淹水</td>
            <td>公開資料</td>
            <td>少量降雨量情境</td>
            <td>淹水潛勢圖，解析度 40m x 40m</td>
            <td>未提供</td>
            <td>未針對您的房屋分析</td>
            <td>未提供</td>
            <td>未提供</td>
          </tr>
          <tr>
            <td>安家百科</td>
            <td>大量降雨情境</td>
            <td>
              高精度淹水模擬圖，解析度 0.5m x 0.5m (資料密度為公開資料的 6400
              倍)
            </td>
            <td>本網站分析提供</td>
            <td>針對您的房屋分析</td>
            <td>針對您的房屋進行損失期望值分析</td>
            <td>針對您的房屋提出安家建議</td>
          </tr>

          {/* 地震 */}
          <tr>
            <td rowSpan="2">地震</td>
            <td>公開資料</td>
            <td>提供斷層位置</td>
            <td>未提供</td>
            <td>未提供</td>
            <td>未針對您的房屋分析</td>
            <td>未提供</td>
            <td>未提供</td>
          </tr>
          <tr>
            <td>安家百科</td>
            <td>引用斷層位置(公開資料)進行地震危害度分析</td>
            <td>導入台美日三國脆弱度曲線</td>
            <td>本網站分析提供</td>
            <td>針對您的房屋分析</td>
            <td>針對您的房屋進行損失期望值分析</td>
            <td>針對您的房屋提出安家建議</td>
          </tr>

          {/* 土壤液化 */}
          <tr>
            <td rowSpan="2">土壤液化</td>
            <td>公開資料</td>
            <td>土壤液化潛勢分析</td>
            <td>未提供</td>
            <td>未提供</td>
            <td>未針對您的房屋分析</td>
            <td>未提供</td>
            <td>未提供</td>
          </tr>
          <tr>
            <td>安家百科</td>
            <td>引用上述公開資料</td>
            <td>本網站分析提供</td>
            <td>本網站分析提供</td>
            <td>針對您的房屋分析</td>
            <td>針對您的房屋進行損失期望值分析</td>
            <td>針對您的房屋提出安家建議</td>
          </tr>

          {/* 氣候變遷 */}
          <tr>
            <td rowSpan="2">氣候變遷</td>
            <td>公開資料</td>
            <td>尚未公布</td>
            <td>無</td>
            <td>未提供</td>
            <td>未針對您的房屋分析</td>
            <td>未提供</td>
            <td>未提供</td>
          </tr>
          <tr>
            <td>安家百科</td>
            <td>海平面上升雨量增加</td>
            <td>
              高精度淹水模擬圖，解析度 0.5m x 0.5m (資料密度為公開資料的 6400
              倍)
            </td>
            <td>本網站分析提供</td>
            <td>針對您的房屋分析</td>
            <td>針對您的房屋進行損失期望值分析</td>
            <td>針對您的房屋提出安家建議</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProductCompare;
