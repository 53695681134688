import React from 'react';
import './GoldenCircle.css';

const GoldenCircle = () => {
  return (
    <div className="golden-circle-page">
      {/* <div className="circle-container">
        <div className="outer-circle">
          <div className="middle-circle">
            <div className="inner-circle">
              <h2>為什麼 (Why)</h2>
            </div>
            <div className="how-text">
              <h2>如何 (How)</h2>
            </div>
          </div>
          <div className="what-text">
            <h2>做什麼 (What)</h2>
          </div>
        </div>
      </div> */}
      <div className="section-container">
        <section className="section">
          <h2>為什麼 (Why)</h2>
          <p>
            我們相信保護房屋這一生中最重要的資產是至關重要的，因為它關係到您和家人的安全與未來。
          </p>
        </section>
        <section className="section">
          <h2>如何 (How)</h2>
          <p>
            我們透過量化風險和提供專業的避險建議，幫助您做出明智的決策來保護資產。
          </p>
        </section>
        <section className="section">
          <h2>做什麼 (What)</h2>
          <p>
            因此，我們創建了這個網站，提供您最新的風險評估工具和避險策略。
          </p>
          {/* <button className="cta-button">了解更多</button> */}
        </section>
      </div>
    </div>
  );
};

export default GoldenCircle;
