import React from "react";
import { motion, useInView } from "framer-motion";
import CountUp from "react-countup";
import "./SummaryChart.css";

const SummaryChartAll = ({ report }) => {
  const minHeight = 40; // Minimum height for bars
  const minHeight2 = 80; // Minimum height for bars
  const minHeight3 = 120; // Minimum height for bars

  const earlth_loss_yearly = report.risk_results["earthquake"][0].toFixed(0); //本建物的年期望損失為

  const liq_en_loss = report.risk_results.liguefaction[2][1];
  const liq_non_en_loss = report.risk_results.liguefaction[3][1];
  const liq_loss = liq_en_loss + liq_non_en_loss;

  const sealevelTotalLoss_sl15 = report?.risk_results["sealevel"][5][0] / 10000;
  const sealevelTotalLoss_sl22 = report?.risk_results["sealevel"][5][1] / 10000;
  const sealevelTotalLoss_sl40 = report?.risk_results["sealevel"][5][2] / 10000;
  const sealevelTotalLoss_sl47 = report?.risk_results["sealevel"][5][3] / 10000;

  const flood_loss_yearly_total = report.risk_results["flood"][0];

  // For animating numbers when they come into view
  const ref = React.useRef(null);
  const isInView = useInView(ref, { triggerOnce: true, threshold: 0.2 });

  return (
    <div className="flood-loss-container">
      <br />
      <div className="f-title">依據分析結果顯示各類風險之損失</div>
      <div className="bar-container">
        <div className="bar">
          <div
            className="bar-value"
            style={{
              width: "70px",
              height: `${Math.max(
                flood_loss_yearly_total / 300,
                minHeight2
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={flood_loss_yearly_total}
                duration={2.5}
                suffix="元/年"
              />
            ) : (
              `${flood_loss_yearly_total}元/年`
            )}
          </div>
          <div className="bar-label">淹水損失</div>
        </div>

        <div className="bar">
          <div
            className="bar-value"
            style={{
              width: "70px",
              height: `${Math.max(earlth_loss_yearly / 300, minHeight2)}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={earlth_loss_yearly}
                duration={2.5}
                suffix="元/年"
              />
            ) : (
              `${earlth_loss_yearly}元/年`
            )}
          </div>
          <div className="bar-label">地震損失</div>
        </div>
      </div>
      <div className="bar-container">
        <div className="bar">
          <div
            className="bar-value"
            style={{
              width: "70px",
              height: `${Math.max(liq_loss / 300, minHeight2)}px`,
            }}
          >
            {isInView ? (
              <CountUp start={0} end={liq_loss} duration={2.5} suffix="元/年" />
            ) : (
              `${liq_loss}元/年`
            )}
          </div>
          <div className="bar-label">土壤液化損失</div>
        </div>
        <div className="bar">
          <div
            className="bar-value"
            style={{
              width: "110px",
              textAlign: "start",
              height: `${Math.max(
                sealevelTotalLoss_sl15 / 100000,
                minHeight3
              )}px`,
            }}
          >
            {`S1: ${sealevelTotalLoss_sl15}萬元/年`}
            <br />
            {`S2: ${sealevelTotalLoss_sl22}萬元/年`}
            <br />
            {`S3: ${sealevelTotalLoss_sl40}萬元/年`}
            <br />
            {`S4: ${sealevelTotalLoss_sl47}萬元/年`}
          </div>
          <div className="bar-label">氣候變遷損失</div>
        </div>
      </div>
      <div>
        其中，氣候變遷風險乃依照現況水利工程設施進行評估，未來政府應會加強水利工程並減少氣候帶來的淹水風險，建議可針對現有的風險「淹水」、「地震」來防制即可。
        <br />
        <div className="f-title">建議</div>
      </div>
      <div>淹水：加裝防水閘版與購買汽車淹水險</div>
      <div>地震：購買擴大地震險</div>
      <div>
        若想與各領域專家或技師詳談災害避免方法(付費)或更詳細的分析報告(付費)，請聯繫本網站服務中心service@safehousing.info
      </div>
      <br />
    </div>
  );
};

export default SummaryChartAll;
