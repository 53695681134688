import React, { useEffect, useState } from "react";
import "./DepthProbBuilding.scss";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  ReferenceArea,
} from "recharts";
import { Slider } from "antd"; // You can use Ant Design's slider component
import { useSelector, useDispatch } from "react-redux";

const DepthProbBuilding = ({ report, waterProbData }) => {
  const dispatch = useDispatch();

  // the targetInfo should come from report
  const targetInfo = JSON.parse(report?.product.target_info);
  const [data, setData] = useState(waterProbData || []);
  const [selectedDepth, setSelectedDepth] = useState(10); // New state to track selected depth from slider
  const maskwaterdepth10cm_prob = report?.risk_results["flood"][4]["10cm"];
  const [probability, setProbability] = useState(maskwaterdepth10cm_prob); // Track corresponding probability
  const [carLoss, setCarLoss] = useState(0); // New state for car loss

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const depthInMeters = (payload[0].value / 100).toFixed(1);
      let carLossRatio;
      if (depthInMeters >= 0.9) {
        carLossRatio = 100;
      } else if (depthInMeters >= 0.6) {
        carLossRatio = 50;
      } else if (depthInMeters >= 0.4) {
        carLossRatio = 27;
      } else if (depthInMeters >= 0.3) {
        carLossRatio = 14;
      } else {
        carLossRatio = 0; // Default ratio
      }

      const carLoss = `${(targetInfo.carValue * (carLossRatio / 100)).toFixed(
        2
      )} 萬元`;

      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: "2px",
          }}
        >
          <p>{`水深 ${payload[0].payload.淹水深度}cm: 機率 ${payload[0].value}% 汽機車損失 ${carLoss}`}</p>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    if (waterProbData) {
      const newData = Object.entries(waterProbData)
        .map(([depth, probability]) => {
          const depthValue = parseInt(depth.replace("cm", ""));
          const adjustedProbability =
            depthValue <= targetInfo.maskingHeight
              ? 0
              : parseFloat(probability) || 0;

          return {
            淹水深度: depthValue - targetInfo.maskingHeight, // Adjust depth by subtracting masking height
            機率: adjustedProbability,
          };
        })
        .filter((entry) => entry.淹水深度 > 0); // Remove entries that are now less than or equal to 0

      newData.sort((a, b) => a.淹水深度 - b.淹水深度);
      setData(newData);
    }
  }, [waterProbData, targetInfo.maskingHeight]);

  // Handle slider change to adjust selected depth
  const handleSliderChange = (value) => {
    setSelectedDepth(value);

    if (Array.isArray(data) && data.length > 0) {
      const foundData = data.find((d) => d.淹水深度 === value);
      if (foundData) {
        setProbability(foundData.機率);

        // Calculate car loss based on the found data
        const depthInMeters = value / 100;
        let carLossRatio;
        if (depthInMeters >= 0.9) {
          carLossRatio = 100;
        } else if (depthInMeters >= 0.6) {
          carLossRatio = 50;
        } else if (depthInMeters >= 0.4) {
          carLossRatio = 27;
        } else if (depthInMeters >= 0.3) {
          carLossRatio = 14;
        } else {
          carLossRatio = 0; // Default ratio
        }

        const calculatedCarLoss = (
          targetInfo.carValue *
          (carLossRatio / 100)
        ).toFixed(2);
        setCarLoss(calculatedCarLoss); // Set the car loss state
      } else {
        setProbability(null);
        setCarLoss(0); // Reset car loss if no matching depth found
      }
    } else {
      setProbability(null);
      setCarLoss(0); // Reset car loss if data is not available
    }
  };

  return (
    <div className="DepthProb" style={{ fontFamily: "Arial", padding: "0px" }}>
      <div style={{ position: "relative", width: "100%", maxWidth: "900px" }}>
        <div
          className="chart-container"
          style={{
            position: "relative",
            zIndex: 1,
            width: "100%",
            height: "300px",
            alignItems: "flex-end",
            display: "flex",
            maxWidth: "1366px",
          }}
        >
          <ResponsiveContainer width="100%" height="70%">
            <AreaChart
              data={data}
              margin={{ top: 20, right: 30, left: 10, bottom: 20 }}
              style={{ background: "transparent" }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#000" />
              <XAxis
                type="number"
                dataKey="機率"
                domain={[0, 100]}
                label={{
                  value: "機率(%)",
                  position: "insideBottom",
                  offset: -10,
                  fill: "#000",
                }}
                stroke="#000"
              />
              <YAxis
                type="number"
                dataKey="淹水深度"
                domain={[0, 200]}
                label={{
                  value: "水深(cm)",
                  angle: -90,
                  position: "insideLeft",
                  offset: 10,
                  fill: "#000",
                }}
                stroke="#000"
              />

              <Tooltip content={<CustomTooltip />} />

              <Area
                type="monotone"
                dataKey="淹水深度"
                stroke="#8884d8"
                fill="#8884d8"
              />

              <ReferenceLine
                y={selectedDepth + targetInfo.maskingHeight}
                stroke="darkblue"
              />
              <ReferenceArea
                y1={selectedDepth + targetInfo.maskingHeight}
                y2={0}
                fill="darkblue"
                fillOpacity={0.6}
              />
              <ReferenceLine
                y={targetInfo.maskingHeight}
                stroke="#CD7F32"
                label={{
                  value: `1F樓地板比人行道高 ${targetInfo.maskingHeight} 公分`,
                  position: "middle",
                  fill: "black",
                  fontSize: 12,
                  fontWeight: "bold",
                  offset: 10,
                  dy: -10,
                }}
              />
              <ReferenceArea
                y1={targetInfo.maskingHeight}
                y2={0}
                fill="#CD7F32"
                fillOpacity={0.6}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>

        <div style={{ padding: "20px", width: "100%", textAlign: "center" }}>
          <p style={{ fontWeight: "bold", fontSize: "18px" }}>
            請滑動計算淹水深度與機率淹水深度{" "}
          </p>
          <p>
            淹水深度: {selectedDepth} cm, 淹水發生機率: {probability}%
          </p>
          <p>汽機車損失: {carLoss} 萬元</p> {/* Display the car loss */}
          <Slider
            min={data.length > 0 ? Math.min(...data.map((d) => d.淹水深度)) : 0}
            max={
              data.length > 0 ? Math.max(...data.map((d) => d.淹水深度)) : 100
            }
            value={selectedDepth}
            onChange={handleSliderChange}
            tooltip={{ open: false }}
          />
        </div>
      </div>
    </div>
  );
};

export default DepthProbBuilding;
