import React, { useEffect, useState, useRef } from "react";
import mapboxgl from "mapbox-gl";
import CompareIcon from "@mui/icons-material/Compare";
import { GrCompare } from "react-icons/gr";
import WindowIcon from "@mui/icons-material/Window";

// import "./RiskMapAll.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setTargetInfo, resetTargetInfo } from "../../redux/targetInfoReducer";
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import FloodMapboxSyncMaps from "./FloodMapboxSyncMaps";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Container } from "@mui/material";
import ReportMapSlider3 from "../Map/ReportMapSlider3";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = mapboxtoken;

const Compare = ({ report }) => {
  const dispatch = useDispatch();
  const [pkid, setPkid] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const targetInfo = useSelector((state) => state.targetInfo);
  const { countyCode } = useParams();
  const [initialCoor, setInitialCoor] = useState({
    lng: 121.30128,
    lat: 24.99265,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const CustomTab = ({ label, icon }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        color: "black",
        fontWeight: "bold",
      }}
    >
      {icon}
      {!isMobile && <span style={{ marginLeft: 8 }}>{label}</span>}
    </div>
  );

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label={<CustomTab label="雙圖比較" icon={<WindowIcon />} />} />
          <Tab label={<CustomTab label="滑動比較" icon={<CompareIcon />} />} />
        </Tabs>

        {
          // Use a switch statement to handle rendering based on tabValue
          (() => {
            switch (tabValue) {
              case 0:
                return <FloodMapboxSyncMaps report={report} />;

              case 1:
                return (
                  <div className="detail-map">
                    <ReportMapSlider3 detailOrder={report.product} />
                  </div>
                );
              default:
                return null; // This is optional, in case tabValue is outside of the expected range
            }
          })()
        }

        <hr />
      </Box>
    </div>
  );
};

export default Compare;
