import React from "react";
import { useSelector } from "react-redux";
import DepthProbBuilding from "../Prob/DepthProbBuilding";
import DepthProbTableBuilding from "../Prob/DepthProbTableBuilding";
import "./FloodResult.scss";

const FloodResult = ({ report }) => {
  // this is for if ever want to calculate in the client side.
  // const waterProbData = useSelector(
  //   (state) => state.waterProbData.waterProbData
  // );

  const waterProbData = report.p0_200_interpolated;
  // console.log("waterProbData", waterProbData);

  // const targetInfo = useSelector((state) => state.targetInfo);

  // Convert the waterProbData object into an array of elements that React can render
  const waterProbDataElements = Object.entries(waterProbData).map(
    ([depth, value]) => (
      <div key={depth}>
        {depth}: {value}
      </div>
    )
  );

  

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      {/* <div>{waterProbDataElements}</div> */}
      {/* <h2>FloodResult</h2> */}
      <div
        style={{
          height: "50vh",
          // margin: "20px",
          marginBottom: "10px",
          minHeight: "410px",
        }}
      >
        <DepthProbBuilding waterProbData={waterProbData} />
      </div>

      <div
        className="flood-prob-table-container"
        // style={{
        //   // height: "50vh",
        //   // maxWidth: "900px",
        //   margin: "80px 20px",
        //   justifyContent: "center",
        //   alignContent: "center",
        //   display: "flex",
        // }}
      >
        <DepthProbTableBuilding waterProbData={waterProbData} />
      </div>
    </div>
  );
};

export default FloodResult;
