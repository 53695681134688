import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./RiskMapClip.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as turf from "@turf/turf";
import axios from "axios";
import axiosInstance from "../../axios";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
// const gs_username = process.env.REACT_APP_GEOSERVER_BASIC_USERNAME;
// const gs_pass = process.env.REACT_APP_GEOSERVER_BASIC_PASSWORD;
const baseURL = process.env.REACT_APP_API_URL;
mapboxgl.accessToken = mapboxtoken;

const RiskMapClipLandslide = ({ report }) => {
  const { user } = useSelector((state) => state.auth);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [activeLayer, setActiveLayer] = useState("landslidegroup");
  const [layerOpacity, setLayerOpacity] = useState(1); // Default to fully opaque

  const reportLandSlideData = report?.landslide_nearest_lines;
  const [result, setResult] = useState(reportLandSlideData);
  const polyGon = report.product.calculated_geometry;
  const [legendUrl, setLegendUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const polygonData = useSelector((state) => state.polygonData.polygonData);
  const nearestLines = report?.landslide_nearest_lines?.nearest_lines;
  const risk_results_landslide = report.risk_results["landslide"];

  // console.log("reportLandSlideData", reportLandSlideData);
  // console.log("nearestLines", nearestLines);

  const addNearestLineLayer = () => {
    setIsLoading(true);
    // console.log("Attempting to add nearest line layers", nearestLines);

    if (map.current) {
      // console.log("Map is loaded, adding nearest line layers");
      // Remove existing nearest line layers and sources
      nearestLines.forEach((_, index) => {
        const lineId = `nearest-line-${index}`;
        // console.log(`Adding source and layer for ${lineId}`);
        if (map.current.getLayer(lineId)) {
          map.current.removeLayer(lineId);
        }
        if (map.current.getSource(lineId)) {
          map.current.removeSource(lineId);
        }
      });

      // Add new nearest line layers
      nearestLines.forEach((lineGeoJSON, index) => {
        const lineId = `nearest-line-${index}`;

        map.current.addSource(lineId, {
          type: "geojson",
          data: lineGeoJSON,
        });

        map.current.addLayer({
          id: lineId,
          type: "line",
          source: lineId,
          layout: {},
          paint: {
            "line-color": "#1e453e", // Adjust the color as needed
            "line-width": 3,
            "line-dasharray": [3, 3], // Adjust the style as needed
          },
        });

        // Calculate the midpoint of the line
        const start = turf.point(lineGeoJSON.geometry.coordinates[0]);
        const end = turf.point(
          lineGeoJSON.geometry.coordinates[
            lineGeoJSON.geometry.coordinates.length - 1
          ]
        );
        const midpoint = turf.midpoint(start, end);

        // Create a popup at the midpoint with the distance
        const popup = new mapboxgl.Popup({
          offset: 25,
          closeButton: false,
          closeOnClick: false,
          anchor: "bottom",
          // className: 'custom-popup' // Add a class name to the popup
        })
          .setLngLat(midpoint.geometry.coordinates)
          .setHTML(
            `<strong>${lineGeoJSON.properties.layer_name}: ${(
              lineGeoJSON.properties.distance / 1000
            ).toFixed(2)} 公里</strong>`
          )
          .addTo(map.current);
      });

      // Fit the map to the bounds of all lines
      const bounds = new mapboxgl.LngLatBounds();
      nearestLines.forEach((lineGeoJSON) => {
        lineGeoJSON.geometry.coordinates.forEach((coord) => {
          bounds.extend(coord);
        });
      });
      map.current.fitBounds(bounds, { padding: 50 });
      setIsLoading(false);
    } else {
      console.log("Map is not ready, setting up 'load' event listener");
      // Set up a 'load' event listener to wait for the map to be ready
      map.current.once("load", () => addNearestLineLayer());
    }
  };

  // Function to fetch the legend image with authentication
  const fetchLegendImage = async (layerName) => {
    // const url = `${geoserver_url}geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layerName}`;
    const url = `${baseURL}risk/geo/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layerName}`;
    try {
      const response = await axios.get(url, {
        responseType: "blob", // Important: handle the response as a Blob
        // auth: {
        //   username: gs_username,
        //   password: gs_pass,
        // },
      });

      // Create a local URL for the image Blob
      const imageUrl = URL.createObjectURL(response.data);
      setLegendUrl(imageUrl); // Store the Blob URL in the state
    } catch (error) {
      console.error("Error fetching legend image:", error);
      // Handle error, e.g., show a notification
    }
  };

  useEffect(() => {
    // Call this function when you need to load a legend, e.g., when a layer is toggled
    if (activeLayer) {
      fetchLegendImage(activeLayer);
    }
  }, [activeLayer]);

  const layers = [
    {
      id: "debris",
      title: "土石流區域",
      wms:
        // geoserver_url +
        `${baseURL}risk/geo/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:debris&FORMAT=image/png&TILED=true`,
    },
    {
      id: "debrisstream",
      title: "土石流潛勢溪",
      wms:
        // geoserver_url +
        `${baseURL}risk/geo/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:debrisstream&FORMAT=image/png&TILED=true`,
    },
    {
      id: "largelandslide3857",
      title: "大規模崩塌區",
      wms:
        // geoserver_url +
        `${baseURL}risk/geo/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:largelandslide3857&FORMAT=image/png&TILED=true`,
    },
    {
      id: "landslidegroup",
      title: "坡地災害",
      wms:
        // geoserver_url +
        `${baseURL}risk/geo/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:landslidegroup&FORMAT=image/png&TILED=true`,
    },
  ];

  // console.log("polyGon", polyGon);

  const featureCollection = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: polyGon,
      },
    ],
  };

  const calculateBounds = (geometry) => {
    const bounds = new mapboxgl.LngLatBounds();
    geometry.coordinates[0].forEach((coord) => bounds.extend(coord));
    return bounds;
  };

  const changeOpacity = (newOpacity) => {
    setLayerOpacity(newOpacity);
    layers.forEach((layer) => {
      if (map.current.getLayer(layer.id)) {
        map.current.setPaintProperty(layer.id, "raster-opacity", newOpacity);
      }
    });
  };

  const addWMSLayers = () => {
    layers.forEach((layer) => {
      // Only add the source if it doesn't already exist
      if (!map.current.getSource(layer.id)) {
        map.current.addSource(layer.id, {
          type: "raster",
          tiles: [layer.wms],
          tileSize: 256,
        });
      }

      // Only add the layer if it doesn't already exist
      if (!map.current.getLayer(layer.id)) {
        map.current.addLayer({
          id: layer.id,
          type: "raster",
          source: layer.id,
          paint: {
            "raster-opacity": layerOpacity, // use the current opacity
          },
          layout: {
            // Use the ternary operator to set visibility based on the active layer
            visibility: layer.id === activeLayer ? "visible" : "none",
          },
        });
      } else {
        // If the layer already exists, just update its visibility
        map.current.setLayoutProperty(
          layer.id,
          "visibility",
          layer.id === activeLayer ? "visible" : "none"
        );
      }
    });
  };

  const addPolygonLayer = () => {
    if (polyGon && polyGon.coordinates) {
      // Check if polygon source and layer already exist
      if (!map.current.getSource("polygon")) {
        map.current.addSource("polygon", {
          type: "geojson",
          data: featureCollection, // Use featureCollection here
        });
      }

      if (!map.current.getLayer("polygon")) {
        map.current.addLayer({
          id: "polygon",
          type: "line",
          source: "polygon",
          layout: {},
          paint: {
            "line-color": "#000", // black color
            "line-width": 2,
            "line-dasharray": [2, 2], // dotted line pattern
          },
        });
      }
    }
  };

  useEffect(() => {
    if (map.current || !mapContainer.current) return; // Exit if map is already initialized or container is not available

    // if (map.current) return; // Initialize map only once
    // if (!map.current && mapContainer.current) {
    // if (!isLoading && map.current && !map.current.isStyleLoaded()) {
    // for detailorder
    let center;
    try {
      center = turf.center(report.product.calculated_geometry).geometry
        .coordinates;
    } catch (error) {
      console.error("Error calculating center:", error);
      return;
    }

    if (!center || center.length !== 2 || center.some(isNaN)) {
      console.error("Invalid center coordinates:", center);
      return;
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v11",
      // style: "mapbox://styles/mapbox/streets-v11",
      // style: "mapbox://styles/wayne32545/cle6fqgc3000601t6g17e3arw",
      center: center,
      // center: [121.31552, 24.99337],
      zoom: 11,
      minZoom: 9,
      dragPan: false,
      // transformRequest: (url, resourceType) => {
      //   if (
      //     (resourceType === "Source" || resourceType === "Tile") &&
      //     url.indexOf(geoserver_url + `geoserver/staging/wms`) > -1
      //   ) {
      //     const username = gs_username;
      //     const password = gs_pass;
      //     const credentials = btoa(`${username}:${password}`);
      //     const headers = {
      //       Authorization: `Basic ${credentials}`,
      //     };

      //     return {
      //       url: url,
      //       headers: headers,
      //       credentials: "include", // Include cookies for cross-origin requests
      //     };
      //   }
      // },
    });

    if (map.current) {
      map.current.on("load", () => {
        // console.log("Map loaded");
        addWMSLayers(); // Add the WMS layers
        addPolygonLayer(); // Add the polygon layer
        // Fetch and display the nearest lines
        addNearestLineLayer();
      });

      const centerCoordinates = turf.center(polyGon).geometry.coordinates;

      const marker = new mapboxgl.Marker()
        .setLngLat(centerCoordinates)
        .addTo(map.current);
    }

    // }

    // Cleanup function to run when the component unmounts
    // return () => {
    //   if (map.current) {
    //     map.current.remove();
    //   }
    // };
  }, [layerOpacity, polyGon]);

  const toggleLayer = (layerId) => {
    setActiveLayer(layerId);
    layers.forEach((layer) => {
      map.current.setLayoutProperty(
        layer.id,
        "visibility",
        layer.id === layerId ? "visible" : "none"
      );
    });
    const layer = layers.find((l) => l.id === layerId);
  };

  // if (!user) {
  //   // If no user is logged in, display a login message
  //   return (
  //     <div className="login-message">
  //       <p>請先登入</p>
  //       <Link to="/login">登入</Link>{" "}
  //       {/* Replace "/login" with your login route */}
  //     </div>
  //   );
  // }

  return (
    <div
      className="riskmap-container"
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <div>
        <ul style={{ fontSize: "14px", fontWeight: "bold" }}>
          <li>
            離最近土石流潛勢區位置為:
            {(result?.distance_info?.[0]?.distance / 1000).toFixed(2)} 公里
          </li>
          <li>
            離最近大規模崩塌潛勢區位置為:{" "}
            {(result?.distance_info?.[1]?.distance / 1000).toFixed(2)} 公里
          </li>
          <li>
            本資料為依據農業部農村發展及土壤保持分署相關資料進行簡易加值分析，此資料為已發生的坡地災害，
            不代表其他坡地不會發生災害，如想在坡地附近購買房屋，敬請尋找大地專業技師進行評估。
          </li>
          {/* <li>在本系統分級屬{risk_results_landslide[1]}級</li> */}
          {/* <li>
            本建物因坡地災害造成的每年平均損失(年期望損失)為:{" "}
            {risk_results_landslide[2]}元
          </li> */}
        </ul>
      </div>
      <div
        className="map-container"
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: "60vh",
        }}
      >
        {/* Display loading indicator if isLoading is true */}
        {isLoading && (
          <div
            className="loading-indicator"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)", // Center the div
              zIndex: 3, // Ensure it's above the map
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(255, 255, 255, 0.8)", // Optional: Add a semi-transparent background
              padding: "20px",
              borderRadius: "10px",
              border: "2px solid #ccc", // Optional: Style as needed
              fontWeight: "bold",
            }}
          >
            分析中, 請稍後...
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
            {/* Here you can also add a spinner or any other visual indicator */}
          </div>
        )}
        {/* Map container covering the full width and 60vh height */}
        <div
          ref={mapContainer}
          // style={{ width: "100%", height: "60vh", padding: "10px" }}
          style={{ width: "100%", height: "100%", padding: "10px" }}
        />

        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "50%", // Moves the left edge of the div to the center of the container
            transform: "translateX(-50%)", // Shifts the div back to the left by half its width
            zIndex: 3,
            fontWeight: "bold",
          }}
        >
          建物所在位置為安全
        </div>
        {/* <div className="map-container" ref={mapContainer} /> */}
        {/* Add legend display */}
        <div
          className="legend-container"
          style={{
            position: "absolute",
            bottom: "20px",
            right: "10px",
            border: "2px solid",
            // padding: "2px",
            borderRadius: "5px",
          }}
        >
          {legendUrl && <img src={legendUrl} alt="Legend" />}
        </div>
      </div>
    </div>
  );
};

export default RiskMapClipLandslide;
