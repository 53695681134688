import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="top" style={{ backgroundColor: "#4C839B" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            color: "white",
          }}
        >
          <img src="/img/logo-white.svg" alt="Public Data Analysis" />
          <span className="small">讓每個人都能住得安心</span>
        </div>
        {/* <div> */}
        <div className="item">
          <span>
            {" "}
            <Link className="link" to="/about">
              關於我們
            </Link>
          </span>
          <span>
            <Link className="link" to="/contact">
              聯絡我們
            </Link>
          </span>
        </div>

        <div className="item">
          <span>
            <Link className="link" to="/Product_intro">
              商品介紹
            </Link>
          </span>
          <span>
            <Link className="link" to="/tech">
              技術理論
            </Link>
          </span>

          <span>
            <Link className="link" to="/dashboard">
              我的報告
            </Link>
          </span>

          <span>
            <Link className="link" to="/profile">
              我的帳號
            </Link>
          </span>
          {/* <span>
            <Link className="link" to="/register">
              註冊帳號
            </Link>
          </span> */}
        </div>
        <div className="item">
          <span>
            <Link className="link" to="/search_result">
              購買圖資
            </Link>
          </span>
          <span>
            <Link className="link" to="/cart">
              購物車
            </Link>
          </span>
          {/* <span>
            <Link className="link" to="/video">
              影音專區
            </Link>
          </span> */}
          {/* <span>本產品由中小企業處SBIR補助</span> */}
        </div>
        <div className="item">
          <span>
            <Link className="link" to="/qa">
              常見問題
            </Link>
          </span>

          <span>
            <Link className="link" to="/ServiceTerm">
              服務條款
            </Link>
          </span>
          <span>
            <Link className="link" to="/PrivacyTerm">
              隱私政策
            </Link>
          </span>
          {/* <span>
            Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore. Lorem ipsum dolor sit
            amet conse ctetur adipisicing elit, seddo eiusmod tempor incididunt
            ut labore etdolore.
          </span> */}
        </div>
      </div>
      {/* <div className="bottom">
        <div className="left">
          <h5 className="logo primary_color mb-0">
            {" "}
            <strong>安家百科 </strong>
            <span className="small">讓每個人都能住得安心</span>
          </h5>
          <p className="copyright m-0">© Copyright 2023. All Rights Reserved</p>
          <p className="copyright m-0">
            Icon by:
            <a
              href="https://www.freepik.com/icon/building_24736#fromView=resource_detail&position=17"
              target="_blank"
              rel="noopener noreferrer"
            >
              Freepik
            </a>
          </p>
        </div>
        
      </div> */}
    </div>
  );
};

export default Footer;
