import React from 'react';
// import PropTypes from 'prop-types';
import './fonts.css';
import './common.css';

import cn from 'classnames';

import styles from './index.module.scss';

function UntitledPage(props) {
  return (
    <section className={cn(styles.earthquakeInfoSection, props.className, 'untitled-page')}>
      {/* Main content section for earthquake information */}

      <div className={styles.contentWrapper}>
        <div className={styles.infoContainer}>
          <h2 className={styles.mainHeading}>
            政府公開資料
            <br />
            不一定可以解答你的問題!
          </h2>

          <div className={styles.earthquakeDetailsContainer}>
            <div className={styles.earthquakeInfoRow}>
              <div className={styles.primaryInfoColumn}>
                <button className={styles.earthquakeAlertBtn}>
                  {/* TODO */}
                  地震快報
                </button>
                <div className={styles.earthquakeMagnitudeWrapper_box}>
                  <span className={styles.earthquakeMagnitudeWrapper}>
                    <span className={styles.earthquakeMagnitudeWrapper_span0}>
                      台灣東部發生地震
                      <br />
                      芮氏規模{' '}
                    </span>
                    <span className={styles.earthquakeMagnitudeWrapper_span1}>6.3</span>
                  </span>
                </div>
                <p className={styles.earthquakeDetails_box}>
                  <span className={styles.earthquakeDetails}>
                    <span className={styles.earthquakeDetails_span0}>發生時間：</span>
                    <span className={styles.earthquakeDetails_span1}>2024</span>
                    <span className={styles.earthquakeDetails_span2}> 年 </span>
                    <span className={styles.earthquakeDetails_span3}>10</span>
                    <span className={styles.earthquakeDetails_span4}> 月 </span>
                    <span className={styles.earthquakeDetails_span5}>1</span>
                    <span className={styles.earthquakeDetails_span6}> 日 </span>
                    <span className={styles.earthquakeDetails_span7}>
                      14:35
                      <br />
                    </span>
                    <span className={styles.earthquakeDetails_span8}>地震規模：芮氏規模 </span>
                    <span className={styles.earthquakeDetails_span9}>6.3</span>
                    <span className={styles.earthquakeDetails_span10}>
                      <br />
                      震央位置：北緯{' '}
                    </span>
                    <span className={styles.earthquakeDetails_span11}>24.0</span>
                    <span className={styles.earthquakeDetails_span12}> 度，東經 </span>
                    <span className={styles.earthquakeDetails_span13}>121.5</span>
                    <span className={styles.earthquakeDetails_span14}>
                      {' '}
                      度，位於台灣東部近海
                      <br />
                      震源深度：
                    </span>
                    <span className={styles.earthquakeDetails_span15}>20</span>
                    <span className={styles.earthquakeDetails_span16}> 公里</span>
                  </span>
                </p>
              </div>

              <p className={styles.seismicIntensityInfo_box}>
                <span className={styles.seismicIntensityInfo}>
                  <span className={styles.seismicIntensityInfo_span0}>
                    各地震度：
                    <br />
                    宜蘭縣：
                  </span>
                  <span className={styles.seismicIntensityInfo_span1}>5</span>
                  <span className={styles.seismicIntensityInfo_span2}>
                    {' '}
                    級<br />
                    花蓮縣：
                  </span>
                  <span className={styles.seismicIntensityInfo_span3}>4</span>
                  <span className={styles.seismicIntensityInfo_span4}>
                    {' '}
                    級<br />
                    台北市：
                  </span>
                  <span className={styles.seismicIntensityInfo_span5}>3</span>
                  <span className={styles.seismicIntensityInfo_span6}>
                    {' '}
                    級<br />
                    台中市：
                  </span>
                  <span className={styles.seismicIntensityInfo_span7}>2</span>
                  <span className={styles.seismicIntensityInfo_span8}>
                    {' '}
                    級<br />
                    高雄市：
                  </span>
                  <span className={styles.seismicIntensityInfo_span9}>1</span>
                  <span className={styles.seismicIntensityInfo_span10}> 級</span>
                </span>
              </p>
            </div>

            <div className={styles.publicQueriesContainer}>
              <div className={styles.queriesColumn}>
                <button className={styles.publicQueriesBtn}>
                  {/* TODO */}
                  民眾疑問
                </button>
                <h3 className={styles.queriesHeading_box}>
                  <span className={styles.queriesHeading}>
                    <span className={styles.queriesHeading_span0}>我只看懂 </span>
                    <span className={styles.queriesHeading_span1}>6.3</span>
                    <span className={styles.queriesHeading_span2}> 和 </span>
                    <span className={styles.queriesHeading_span3}>5</span>
                    <span className={styles.queriesHeading_span4}>
                      ，這到底是什麼意思？
                      <br />
                      這次地震會對我的房屋有多大影響？
                      <br />
                      我應該如何應對？該轉移風險嗎？
                    </span>
                  </span>
                </h3>
              </div>

              <img
                className={styles.queryIllustration}
                src={'/assets/b0f0b4f62b54ec354c59b06e1c7ee204.svg'}
                alt="alt text"
              />
            </div>
          </div>
        </div>

        <h2 className={styles.comparisonHeading_box}>
          <span className={styles.comparisonHeading}>
            <span className={styles.comparisonHeading_span0}>公開資料 </span>
            <span className={styles.comparisonHeading_span1}>vs.</span>
            <span className={styles.comparisonHeading_span2}> </span>
            <span className={styles.comparisonHeading_span3}>安家百科</span>
          </span>
        </h2>

        <div className={styles.comparisonGroup}>
          {/* Comparison between public data and encyclopedia */}

          <div className={styles.dataComparisonColumn}>
            <div className={styles.disasterScaleContainer}>
              <div className={styles.scaleInfoWrapper}>
                <button className={styles.disasterScaleBtn_box}>
                  {/* TODO */}
                  <span className={styles.disasterScaleBtn}>
                    <span className={styles.disasterScaleBtn_span0}>災害規模 </span>
                    <span className={styles.disasterScaleBtn_span1}>Scale</span>
                  </span>
                </button>
                <p className={styles.scaleDetails_box}>
                  <span className={styles.scaleDetails}>
                    <span className={styles.scaleDetails_span0}>發生時間：</span>
                    <span className={styles.scaleDetails_span1}>2024</span>
                    <span className={styles.scaleDetails_span2}> 年 </span>
                    <span className={styles.scaleDetails_span3}>10</span>
                    <span className={styles.scaleDetails_span4}> 月 </span>
                    <span className={styles.scaleDetails_span5}>1</span>
                    <span className={styles.scaleDetails_span6}> 日 </span>
                    <span className={styles.scaleDetails_span7}>
                      14:35
                      <br />
                    </span>
                    <span className={styles.scaleDetails_span8}>地震規模：芮氏規模 </span>
                    <span className={styles.scaleDetails_span9}>6.3</span>
                    <span className={styles.scaleDetails_span10}>
                      <br />
                      震央位置：北緯{' '}
                    </span>
                    <span className={styles.scaleDetails_span11}>24.0</span>
                    <span className={styles.scaleDetails_span12}> 度，東經 </span>
                    <span className={styles.scaleDetails_span13}>121.5</span>
                    <span className={styles.scaleDetails_span14}>
                      {' '}
                      度<br />
                      震源深度：
                    </span>
                    <span className={styles.scaleDetails_span15}>20</span>
                    <span className={styles.scaleDetails_span16}>
                      {' '}
                      公里
                      <br />
                      災害規模：如降雨量、地震規模等...
                    </span>
                  </span>
                </p>
              </div>
            </div>

            <div className={styles.disasterImpactContainer}>
              <div className={styles.impactInfoWrapper}>
                <button className={styles.disasterImpactBtn_box}>
                  {/* TODO */}
                  <span className={styles.disasterImpactBtn}>
                    <span className={styles.disasterImpactBtn_span0}>災害衝擊 </span>
                    <span className={styles.disasterImpactBtn_span1}>Impact</span>
                  </span>
                </button>
                <p className={styles.impactDetails}>
                  災害可能造成建築物或附屬物的損壞程度
                  <br />
                  不同規模對建築物或附屬物的損壞範圍
                  <br />
                  淹水模擬分析、地震危害程度分析
                </p>
              </div>
            </div>

            <div className={styles.occurrenceProbabilityContainer}>
              <div className={styles.probabilityInfoWrapper}>
                <button className={styles.occurrenceProbabilityBtn_box}>
                  {/* TODO */}
                  <span className={styles.occurrenceProbabilityBtn}>
                    <span className={styles.occurrenceProbabilityBtn_span0}>發生機率 </span>
                    <span className={styles.occurrenceProbabilityBtn_span1}>Probability</span>
                  </span>
                </button>
                <p className={styles.probabilityDetails}>
                  各種損壞程度的發生機率
                  <br />
                  淹水深度 vs 機率、地震脆弱度分析等
                </p>
              </div>
            </div>

            <div className={styles.costContainer}>
              <div className={styles.costInfoWrapper}>
                <button className={styles.costBtn_box}>
                  {/* TODO */}
                  <span className={styles.costBtn}>
                    <span className={styles.costBtn_span0}>成本 </span>
                    <span className={styles.costBtn_span1}>Cost</span>
                  </span>
                </button>
                <p className={styles.costDetails}>
                  建築成本與附屬物價值
                  <br />
                  涉及汽車、家具、建築、裝潢等項目
                </p>
              </div>
            </div>

            <div className={styles.expectedLossContainer}>
              <div className={styles.lossInfoWrapper}>
                <button className={styles.expectedLossBtn_box}>
                  {/* TODO */}
                  <span className={styles.expectedLossBtn}>
                    <span className={styles.expectedLossBtn_span0}>期望損失 </span>
                    <span className={styles.expectedLossBtn_span1}>Expected Loss</span>
                  </span>
                </button>
                <p className={styles.lossDetails}>每年平均損失估算</p>
              </div>
            </div>

            <div className={styles.suggestionsContainer}>
              <div className={styles.suggestionsInfoWrapper}>
                <button className={styles.suggestionsBtn_box}>
                  {/* TODO */}
                  <span className={styles.suggestionsBtn}>
                    <span className={styles.suggestionsBtn_span0}>安家建議 </span>
                    <span className={styles.suggestionsBtn_span1}>Suggestions</span>
                  </span>
                </button>
                <p className={styles.suggestionsDetails}>依據安家百科的個別化專業分析提出建議</p>
              </div>
            </div>
          </div>

          <img
            className={styles.analysisIllustration}
            src={'/assets/17716d15cefc2f181bfdc4424629232a.svg'}
            alt="alt text"
          />
          <h3 className={styles.encyclopediaAnalysisHeading}>
            安家百科的
            <br />
            個別化專業分析
          </h3>
          <h3 className={styles.governmentInfoHeading}>政府資訊</h3>
          <p className={styles.technicalTerms}>專業術語</p>
          <p className={styles.laymansTerms}>大眾能理解的語言</p>
        </div>

        {/* <div className={styles.resolutionComparisonRow}>
          <h3 className={styles.resolutionComparisonHeading_box}>
            <span className={styles.resolutionComparisonHeading}>
              <span className={styles.resolutionComparisonHeading_span0}>
                安家百科的淹水深度模擬解析度
                <br />
                為公開資料的{' '}
              </span>
              <span className={styles.resolutionComparisonHeading_span1}>1600</span>
              <span className={styles.resolutionComparisonHeading_span2}> 倍</span>
            </span>
          </h3>

          <div className={styles.resolutionImagesWrapper}>
            <img
              className={styles.resolutionImage1}
              src={'/assets/e88d2d1ff97fa4e1bc8c53035de9f6f3.png'}
              alt="alt text"
            />
            <img
              className={styles.resolutionImage2}
              src={'/assets/33083ca49ef4d7ef8d3e4aac4517dbad.png'}
              alt="alt text"
            />
          </div>
        </div> */}
      </div>

      <img className={styles.decorativeElement1} src={'/assets/07de50c34f50fd78511c79015ce42f4f.svg'} alt="alt text" />
      <img className={styles.decorativeElement2} src={'/assets/c09758c24835a45b377a7e1077b2fbb1.svg'} alt="alt text" />
    </section>
  );
}

// UntitledPage.propTypes = {
//   className: PropTypes.string
// };

export default UntitledPage;
