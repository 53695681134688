import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL;

// const baseURL = "http://localhost/api/v1/";

const axiosInstance = axios.create({
  baseURL: baseURL,
  // timeout: 5000,
  timeout: 20000, // Timeout of 10 seconds
  headers: {
    Authorization: localStorage.getItem("user")
      ? "JWT " + JSON.parse(localStorage.getItem("user")).access
      : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

// Adding the request interceptor
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("user")
    ? "JWT " + JSON.parse(localStorage.getItem("user")).access
    : null;
  config.headers["Authorization"] = token;

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (typeof error.response === "undefined") {
      alert(
        "A server/network error occurred. " +
          "Looks like CORS might be the problem. " +
          "Sorry about this - we will get it fixed shortly."
      );
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      originalRequest.url === baseURL + "jwt/refresh/"
    ) {
      localStorage.removeItem("user");
      window.location.href = "/login/";
      return Promise.reject(error);
    }

    if (
      error.response.data.code === "token_not_valid" &&
      error.response.status === 401 &&
      error.response.statusText === "Unauthorized"
    ) {
      const refreshToken = JSON.parse(localStorage.getItem("user")).refresh;

      if (refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));
        const now = Math.ceil(Date.now() / 1000);
        if (tokenParts.exp > now) {
          try {
            const response = await axiosInstance.post("jwt/refresh/", {
              refresh: refreshToken,
            });

            const user = JSON.parse(localStorage.getItem("user"));
            user.access = response.data.access;
            user.refresh = response.data.refresh;
            localStorage.setItem("user", JSON.stringify(user));

            axiosInstance.defaults.headers["Authorization"] =
              "JWT " + response.data.access;
            originalRequest.headers["Authorization"] =
              "JWT " + response.data.access;

            return axiosInstance(originalRequest);
          } catch (err) {
            console.log(err);
            localStorage.removeItem("user");
            window.location.href = "/login/";
          }
        } else {
          console.log("Refresh token is expired", tokenParts.exp, now);
          localStorage.removeItem("user");
          window.location.href = "/login/";
        }
      } else {
        console.log("Refresh token not available.");
        localStorage.removeItem("user");
        window.location.href = "/login/";
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
