import React, { useEffect, useState } from "react";
import "./DepthProbBuildingSealevel.scss";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  ReferenceArea,
} from "recharts";
import { Slider } from "antd";
import { setTargetInfo } from "../../redux/targetInfoReducer"; // Redux actions
import { useSelector, useDispatch } from "react-redux";

const GateProbBuilding = ({ report, waterProbData }) => {
  const dispatch = useDispatch();

  const targetInfoRedux = useSelector((state) => state.targetInfo);
  const targetInfo = JSON.parse(report?.product.target_info);
  const [data, setData] = useState(waterProbData || []);
  const [selectedGateHeight, setSelectedGateHeight] = useState(50); // Track gate height with slider
  const gate50cm_prob = waterProbData[`${targetInfo.maskingHeight + 50}cm`];

  const [probability, setProbability] = useState(gate50cm_prob); // Track corresponding probability
  const [carLoss, setCarLoss] = useState(targetInfo.carValue * (50 / 100));

  // console.log(targetInfo.maskingHeight + 50);
  // console.log(gate50cm_prob);
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: "2px",
          }}
        >
          <p>{`水深: ${payload[0].payload.淹水深度} cm | 機率: ${payload[0].value}%`}</p>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    if (waterProbData) {
      const newData = Object.entries(waterProbData)
        .map(([depth, probability]) => {
          const depthValue = parseInt(depth.replace("cm", ""));
          // househeight + watergateheight

          const combinedHeight = targetInfo.maskingHeight + selectedGateHeight;

          // Adjust depth by subtracting gate height (masking height)
          let adjustedProbability =
            depthValue <= combinedHeight ? 0 : parseFloat(probability) || 0;

          // Cap the probability at 100
          adjustedProbability = Math.min(adjustedProbability, 100);

          return {
            淹水深度: depthValue - combinedHeight, // Adjust water depth based on gate height
            機率: adjustedProbability,
          };
        })
        .filter((entry) => entry.淹水深度 > 0);

      // Sort data by water depth
      newData.sort((a, b) => a.淹水深度 - b.淹水深度);

      setData(newData);
    }
  }, [waterProbData, selectedGateHeight, targetInfo.maskingHeight]);

  // Handle slider change for the gate height
  const handleSliderChange = (value) => {
    setSelectedGateHeight(value);

    // Update the gate height (masking height) in the Redux store
    dispatch(
      setTargetInfo({
        field: "maskingHeight",
        value: parseFloat(value),
      })
    );

    // Find the closest probability based on the gate height
    const foundData = data.find((d) => d.淹水深度 === value);
    if (foundData) {
      setProbability(foundData.機率);
      // Calculate car loss based on the found data
      const depthInMeters = value / 100;
      let carLossRatio;
      if (depthInMeters >= 0.9) {
        carLossRatio = 100;
      } else if (depthInMeters >= 0.6) {
        carLossRatio = 50;
      } else if (depthInMeters >= 0.4) {
        carLossRatio = 27;
      } else if (depthInMeters >= 0.3) {
        carLossRatio = 14;
      } else {
        carLossRatio = 0; // Default ratio
      }

      const calculatedCarLoss = (
        targetInfo.carValue *
        (carLossRatio / 100)
      ).toFixed(2);
      setCarLoss(calculatedCarLoss); // Set the car loss state
    } else {
      setProbability(null);
      setCarLoss(0); // Reset car loss if no matching depth found
    }
  };

  return (
    <div className="DepthProb" style={{ fontFamily: "Arial", padding: "0px" }}>
      {/* Chart and Slider for Gate Height */}
      <div style={{ position: "relative", width: "100%", maxWidth: "900px" }}>
        <div
          className="chart-container-sealevel"
          style={{
            position: "relative",
            zIndex: 1,
            width: "100%",
            height: "300px",
            alignItems: "flex-end",
            maxWidth: "1366px",
          }} // Fix height and widths
        >
          <ResponsiveContainer width="100%" height="70%">
            <AreaChart
              data={data}
              margin={{ top: 20, right: 30, left: 10, bottom: 20 }}
              style={{ background: "transparent" }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#000" />
              <XAxis
                type="number"
                dataKey="機率"
                // scale="log"
                // domain={["dataMin", "dataMax"]}
                domain={[0, 100]}
                label={{
                  value: "機率(%)",
                  position: "insideBottom",
                  offset: -10,
                  fill: "#000",
                }}
                stroke="#000"
              />
              <YAxis
                type="number"
                dataKey="淹水深度"
                domain={[0, 200]}
                label={{
                  value: "水深(cm)",
                  angle: -90,
                  position: "insideLeft",
                  offset: 10,
                  fill: "#000",
                }}
                stroke="#000"
              />

              <Tooltip content={<CustomTooltip />} />

              <Area
                type="monotone"
                dataKey="淹水深度"
                stroke="#8884d8"
                fill="#8884d8"
              />

              {/* Show Reference Line and Area for selected gate height */}
              <ReferenceLine
                y={selectedGateHeight + targetInfo.maskingHeight}
                stroke="#5a5a5a" // Grey stroke for gate height
                label={{
                  value: `擋水閘板高度: ${selectedGateHeight} cm`,
                  position: "middle", // You can use 'insideLeft', 'insideRight', 'middle', or 'outside'
                  fill: "black", // Label color
                  fontSize: 12, // Adjust font size if needed
                  fontWeight: "bold", // Optional for bold font
                  offset: 10, // Horizontal offset
                  dy: -10, // Moves the label above the line
                }}
              />
              <ReferenceArea
                y1={selectedGateHeight + targetInfo.maskingHeight}
                y2={0}
                fill="#5a5a5a"
                fillOpacity={0.6} // Grey color to show gate height
              />

              <ReferenceLine
                y={targetInfo.maskingHeight}
                stroke="#CD7F32"
                label={{
                  value: `1F樓地板比人行道高 ${targetInfo.maskingHeight} 公分`,
                  // position: "insideRight", // You can use 'insideLeft', 'insideRight', 'middle', or 'outside'
                  position: "insideRight", // You can use 'insideLeft', 'insideRight', 'middle', or 'outside'
                  fill: "black", // Label color
                  fontSize: 12, // Adjust font size if needed
                  fontWeight: "bold", // Optional for bold font
                  offset: 10, // Horizontal offset
                  dy: -10, // Moves the label above the line
                }}
              />
              <ReferenceArea
                y1={targetInfo.maskingHeight}
                y2={0}
                fill="#CD7F32"
                fillOpacity={0.6}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>

        {/* Slider to Control Gate Height */}
        <div style={{ padding: "20px", width: "100%", textAlign: "center" }}>
          <p>請滑動以設定擋水閘板高度</p>
          <p style={{ fontWeight: "bold", fontSize: "18px" }}>
            擋水閘板高度: {selectedGateHeight} cm, 超過檔水閘版的機率:{" "}
            {probability}%
          </p>
          <p>汽機車損失: {carLoss} 萬元</p>

          <Slider
            min={data.length > 0 ? Math.min(...data.map((d) => d.淹水深度)) : 0}
            max={
              data.length > 0 ? Math.max(...data.map((d) => d.淹水深度)) : 200
            }
            value={selectedGateHeight}
            onChange={handleSliderChange}
            tooltip={{ open: false }}
          />
        </div>
      </div>
    </div>
  );
};

export default GateProbBuilding;
