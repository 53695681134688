import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Title = ({ title, description, keywords }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
    </HelmetProvider>
  );
};

Title.defaultProps = {
  title: "歡迎來到安家百科",
  description: "最先進的地產災害風險評估與安家策略",
  keywords: "災害, 安全, 最佳選擇",
};

export default Title;
