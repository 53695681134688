import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { Timeline } from "antd";

const teamInfo = {
  title: "專業的團隊與得獎:",
  description: `
    我們是土木、大氣、資料科學等專家所組成的公司，並且有數個大學團隊合作與支援。專業領域：
    定量降雨模擬、河川水文水理、都市水文水理、地震與坡地災害等。安家百科成立前，本團隊已執行水利署、國土署、
    各縣市政府滯水模擬計畫，累積達40+案。
  `,
  awards: [
    "2020 民生公共物聯網資料應用競賽：三獎",
    "2021 FITI創新創業競賽：入圍前17",
    "2022 智慧城鄉前瞻應用與公益創新實證賽：前6",
    "2022 智在家鄉-聯發科技數位社會創新競賽：潛力獎",
    "2023 SBIR補助",
    "2023 新創採購補助 – 高雄市",
    "2024 新創採購補助 – 宜蘭縣",
  ],
};

const TeamAndAwards = () => {
  //   const timelineItems = teamInfo.awards.map((award) => ({
  //     label: award, // Label prop for the timeline item
  //     color: "black", // Setting the color to black
  //   }));

  //   console.log(timelineItems)
  return (
    <Box sx={{ padding: { xs: "40px", md: "40px 80px" } }}>
      <Grid container spacing={4}>
        {/* Left Side: Team Info */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {teamInfo.title}
          </Typography>
          <Typography variant="body1" paragraph>
            {teamInfo.description}
          </Typography>
        </Grid>

        {/* Right Side: Timeline for Awards using Ant Design */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            歷年得獎：
          </Typography>
          <Timeline mode="left">
            {teamInfo.awards.map((award, index) => (
              <Timeline.Item color="black" key={index}>
                {award}
              </Timeline.Item>
            ))}
          </Timeline>
          {/* <Timeline items={timelineItems} /> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TeamAndAwards;
