import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Spinner/Spinner";
import Title from "../../components/Title/Title";
import { toast } from "react-toastify";
import { register, reset } from "../../features/auth/authSlice";
import "./register.scss";
import ServiceTerm from "../ServiceTerm/ServiceTerm";
import PrivacyTerm from "../PrivacyTerm/PrivacyTerm";

const Register = () => {
  const [username, setUsername] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [re_password, setRePassword] = useState("");

  // Checkboxes for terms
  const [isServiceAccepted, setIsServiceAccepted] = useState(false);
  const [isPrivacyAccepted, setIsPrivacyAccepted] = useState(false);
  const [isDisclaimerAccepted, setIsDisclaimerAccepted] = useState(false);

  // Modal control state
  const [isServiceModalOpened, setIsServiceModalOpened] = useState(false);
  const [isPrivacyModalOpened, setIsPrivacyModalOpened] = useState(false);
  const [isDisclaimerModalOpened, setIsDisclaimerModalOpened] = useState(false);

  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (user) {
      toast.error("目前為登入狀態，切換使用者請先登出!");
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    if (isError) {
      toast.error(message || "請使用有效信箱，或是8位以上的密碼再進行嘗試。");
    }

    if (isSuccess) {
      toast.success("驗證信件已寄到您的信箱，請收信驗證以啟動帳號!");
      navigate("/");
      dispatch(reset());
    }

    return () => {
      dispatch(reset());
    };
  }, [isError, isSuccess, message, navigate, dispatch]);

  const validatePassword = (password) => {
    const minLength = 8;

    if (password.length < minLength) {
      toast.error(`密碼需至少 ${minLength} 個字元長度`);
      return false;
    }

    if (!isNaN(password)) {
      toast.error("密碼不可為全數字，請包含字母或符號");
      return false;
    }

    return true;
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!validatePassword(password)) {
      return;
    }

    if (password !== re_password) {
      toast.error("兩次密碼不相同，請再次確認密碼輸入一致");
      return;
    }

    if (!isServiceAccepted || !isPrivacyAccepted || !isDisclaimerAccepted) {
      toast.error("請閱讀並同意所有條款後再進行註冊");
      return;
    }

    const userData = {
      username,
      first_name,
      last_name,
      email,
      password,
      re_password,
    };

    dispatch(register(userData));
  };

  const handleModalClose = (modalSetter) => {
    modalSetter(false);
  };

  return (
    <div className="register-background">
      <Title title="安家百科-帳號註冊" />
      {isLoading && <Spinner />}
      <div className="register-container">
        <div className="card">
          <div className="left">
            <h1>安家百科 帳號註冊</h1>
            <span>
              您已經有帳號了嗎? <Link to="/login">點我登入</Link>
            </span>
            <p style={{ fontSize: "10px" }}>密碼請輸入8位以上的英文+數字</p>
          </div>

          <div className="right">
            <form onSubmit={submitHandler}>
              <input
                type="text"
                placeholder="使用者名稱"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                type="text"
                placeholder="名字"
                value={first_name}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <input
                type="text"
                placeholder="姓氏"
                value={last_name}
                onChange={(e) => setLastName(e.target.value)}
              />
              <input
                type="email"
                placeholder="電子信箱"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="輸入密碼"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                type="password"
                placeholder="再次輸入密碼"
                value={re_password}
                onChange={(e) => setRePassword(e.target.value)}
              />

              {/* Terms and Disclaimer checkboxes */}
              <div className="checkbox-container">
                <label>
                  <input
                    type="checkbox"
                    checked={isServiceAccepted}
                    onChange={(e) => setIsServiceAccepted(e.target.checked)}
                    disabled={!isServiceModalOpened}
                  />
                  我已閱讀並同意
                  <span
                    className="re-link"
                    onClick={() => {
                      setShowServiceModal(true);
                      setIsServiceModalOpened(true);
                    }}
                  >
                    《網站會員條款》
                  </span>
                </label>
                <br />
                <label>
                  <input
                    type="checkbox"
                    checked={isPrivacyAccepted}
                    onChange={(e) => setIsPrivacyAccepted(e.target.checked)}
                    disabled={!isPrivacyModalOpened}
                  />
                  我已閱讀並同意
                  <span
                    className="re-link"
                    onClick={() => {
                      setShowPrivacyModal(true);
                      setIsPrivacyModalOpened(true);
                    }}
                  >
                    《隱私權條款》
                  </span>
                </label>
                <br />
                <label>
                  <input
                    type="checkbox"
                    checked={isDisclaimerAccepted}
                    onChange={(e) => setIsDisclaimerAccepted(e.target.checked)}
                    disabled={!isDisclaimerModalOpened}
                  />
                  <span
                    className="re-link"
                    onClick={() => {
                      setShowDisclaimerModal(true);
                      setIsDisclaimerModalOpened(true);
                    }}
                  >
                    我已閱讀並同意《免責聲明》
                  </span>
                </label>
              </div>

              <button
                type="submit"
                // disabled={
                //   !isServiceAccepted || !isPrivacyAccepted || !isDisclaimerAccepted
                // }
              >
                註冊
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Privacy Modal */}
      {showPrivacyModal && (
        <div
          className="modal"
          onClick={() => handleModalClose(setShowPrivacyModal)}
        >
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={() => setShowPrivacyModal(false)}>
              &times;
            </span>
            <PrivacyTerm />
          </div>
        </div>
      )}

      {/* Service Terms Modal */}
      {showServiceModal && (
        <div
          className="modal"
          onClick={() => handleModalClose(setShowServiceModal)}
        >
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={() => setShowServiceModal(false)}>
              &times;
            </span>
            <ServiceTerm />
          </div>
        </div>
      )}

      {/* Disclaimer Modal */}
      {showDisclaimerModal && (
        <div
          className="modal"
          onClick={() => handleModalClose(setShowDisclaimerModal)}
        >
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span
              className="close"
              onClick={() => setShowDisclaimerModal(false)}
            >
              &times;
            </span>
            <div>
              免責聲明：
              「本系統的分析結果是基於資料產生時所使用的最佳演算法和資料進行的評估。由於現實狀況可能因特定不動產本身或附近排水設施的維護情況、水工結構物及地形變化等因素而與模擬結果有所不同，安家百科致力於維持本系統內容的正確性。本分析內容旨在提供風險資訊供使用者參考，並不構成任何投資建議或結論，亦不代表安家百科的立場。使用者必須自行承擔使用資訊的風險，並對其使用結果負責。安家百科不會對因使用本系統分析內容而產生的任何損失或損害承擔責任。」
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Register;
