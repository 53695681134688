import React, { useEffect, useState } from "react";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import RiskRadarChart from "../../components/Charts/RiskRadarChart";
import "./RiskReport.scss";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../axios";
import RiskMapClip from "../../components/RiskMapAll/RiskMapClip";
import RiskMapClipSeaLevel from "../../components/RiskMapAll/RiskMapClipSeaLevel";
import RiskMapClipLiq from "../../components/RiskMapAll/RiskMapClipLiq";
import RiskMapClipEarthquake from "../../components/RiskMapAll/RiskMapClipEarthquake";
import RiskMapClipLandSlide from "../../components/RiskMapAll/RiskMapClipLandSlide2";
import floodLevels from "./floodLevels.js";
import { Tabs, Tab } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home"; // Example icon
import InfoIcon from "@mui/icons-material/Info"; // Another example icon
import FloodIcon from "@mui/icons-material/Flood";
import PublicIcon from "@mui/icons-material/Public";
import HouseboatIcon from "@mui/icons-material/Houseboat";
import LandslideIcon from "@mui/icons-material/Landslide";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import BuildingRisk from "../../components/RiskMapAll/BuildingRisk";
import BuildingRiskAll from "../../components/RiskMapAll/BuildingRiskAll";
import FloodResult from "../../components/RiskMapAll/FloodResult_v2";
import EerthquakeResult from "../../components/RiskMapAll/EerthquakeResult_v2";
import TsunamiIcon from "@mui/icons-material/Tsunami";
import { RiEarthquakeFill } from "react-icons/ri";
import LiqResult from "../../components/RiskMapAll/LiqResult_v2";
import SealevelResult from "../../components/RiskMapAll/SealevelResult_v2";
import Summary from "./Summary_v2";
import Sugguestions from "./Suggestions.jsx";

const PaidReport = ({ report, productTargetInfo }) => {
  const { pkid } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const CustomTab = ({ label, icon, active }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // Optionally adjust icon size here if it's a component
    const adjustedIcon = React.cloneElement(icon, {
      style: {
        // fontSize: isMobile ? "16px" : "24px",
        fontSize: isMobile ? "24px" : "36px", // Adjust the icon size based on screen size
        // color: "black", // Set icon color to black
        color: active ? "white" : "#4C839B", // Change icon color based on active state
      },
    });

    // Define a fixed width for the tabs
    const tabWidth = isMobile ? "40px" : "80px"; // Example widths, adjust as needed

    return (
      <div
        style={{
          display: "flex",
          width: tabWidth, // Apply fixed width to each tab
          height: tabWidth, // Apply fixed width to each tab
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center", // Center content vertically
          fontWeight: "bold",
          padding: isMobile ? "4px" : "10px",
          border: "1px solid",
          borderRadius: "2px",
          // color: "#4C839B"
          // color: active ? "white" : "black", // Text color changes based on active state
          color: active ? "white" : "#4C839B", // Text color changes based on active state
          // backgroundColor: active ? "black" : "transparent", // Background color changes based on active state
          backgroundColor: active ? "#4C839B" : "transparent", // Background color changes based on active state
        }}
      >
        {adjustedIcon}
        {!isMobile && <span>{label}</span>}
      </div>
    );
  };

  const [furnitureLoss, setFurnitureLoss] = useState("");

  useEffect(() => {
    // Check if productTargetInfo and report are not undefined
    if (productTargetInfo && report) {
      if (productTargetInfo["floorNumber"] > 1) {
        setFurnitureLoss(
          `您物件位於本建物的${productTargetInfo["floorNumber"]}樓，因此不會受到淹水影響。`
        );
      } else {
        setFurnitureLoss(
          `您物件位於本建物的1樓，家俱家電可能每年有${report.risk_results["flood"][2]}元的期望損失。`
        );
      }
    }
  }, [productTargetInfo, report]); // Add productTargetInfo and report to the dependency array

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDownloadClick = async () => {
    try {
      // Ensure that pkid is a number
      const product_pkid = Number(pkid);
      if (!product_pkid) {
        console.error("Invalid pkid:", pkid);
        return;
      }

      const response = await axiosInstance.get(
        `risk/generate_report/${product_pkid}`,
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `report_${product_pkid}.docx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the report:", error);
    }
  };

  const preventionMeasures = [
    "熟知住所附近淹水情況，遠離淹水潛勢區。",
    "購買包括淹水保障的保險，以防意外發生。",
    "對於可能的淹水事件，提前制定緊急應對計劃。",
    "在家裡安裝防水設施，例如沙袋、防水門檻等。",
    "定期查看氣象預報，提前預警淹水可能。",
  ];

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const tabData = [
    { label: "摘要", Icon: SummarizeIcon },
    { label: "淹水", Icon: FloodIcon },
    { label: "地震", Icon: RiEarthquakeFill },
    { label: "土壤液化", Icon: HouseboatIcon },
    { label: "坡地災害", Icon: LandslideIcon },
    { label: "氣候變遷", Icon: TsunamiIcon },
    { label: "安家建議", Icon: SummarizeIcon },
    // Add more tabs as needed
  ];

  if (!report || Object.keys(report).length === 0) {
    return (
      <div className="detail-root">
        <div className="detail-description">
          <h2 className="section-heading">專屬報告仍在準備中，請耐心等候...</h2>
        </div>
      </div>
    );
  }
  return (
    // <Container className="risk-report" sx={{ padding: "0px" }}>
    <div className="detail-root" style={{ padding: "0px" }}>
      <Paper
        className="detail-paper1"
        elevation={3}
        sx={{ paddingTop: "10px" }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons={isMobile ? true : "auto"} // Enable scroll buttons on mobile
          allowScrollButtonsMobile
          sx={{
            "& .MuiTabs-flexContainer": {
              justifyContent: "center",
            },
            "& .MuiTab-root": {
              minWidth: isMobile ? "40px" : "80px", // Adjust tab minWidth for better fit on mobile
              padding: isMobile ? "2px" : "10px",
            },
          }}
        >
          {tabData.map((tab, index) => (
            <Tab
              key={index}
              icon={
                <CustomTab
                  icon={<tab.Icon />}
                  label={tab.label}
                  active={activeTab === index}
                />
              }
              {...a11yProps(index)} // Ensure you have defined this function for accessibility
            />
          ))}
        </Tabs>

        {/* Conditional rendering based on the active tab */}

        {activeTab === 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography className="section">摘要</Typography>
              <Summary
                report={report}
                productTargetInfo={productTargetInfo}
                furnitureLoss={furnitureLoss}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className="section">綜合實體風險</Typography>
              <RiskRadarChart report={report} />
            </Grid>
          </Grid>
        )}

        {activeTab === 1 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography className="section">建物淹水風險</Typography>
              <div>
                <FloodResult
                  report={report}
                  productTargetInfo={productTargetInfo}
                  furnitureLoss={furnitureLoss}
                />
              </div>
            </Grid>
          </Grid>
        )}
        {activeTab === 2 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography className="section">建物地震風險:</Typography>
              <EerthquakeResult report={report} />
            </Grid>
          </Grid>
        )}
        {activeTab === 3 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography className="section">建物土壤液化風險:</Typography>
              <LiqResult
                report={report}
                productTargetInfo={productTargetInfo}
              />

              {/* <RiskMapClipLiq report={report} /> */}
            </Grid>
          </Grid>
        )}
        {activeTab === 4 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography className="section">坡地災害風險:</Typography>
              <RiskMapClipLandSlide report={report} />
            </Grid>
          </Grid>
        )}
        {activeTab === 5 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography className="section">氣候變遷:</Typography>
              <SealevelResult
                report={report}
                productTargetInfo={productTargetInfo}
                furnitureLoss={furnitureLoss}
              />
              {/* <RiskMapClipSeaLevel report={report} /> */}
            </Grid>
          </Grid>
        )}

        {activeTab === 6 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Sugguestions report={report} />
            </Grid>
          </Grid>
        )}
      </Paper>
    </div>
    // {/* </Container> */}
  );
};

export default PaidReport;
