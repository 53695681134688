import React from "react";
import FaultDistanceTable from "../Table/FaultDistanceTable";
import PgaProbBuilding2 from "../Prob/PgaProbBuilding2";
import EarthPGAProb from "../Prob/EarthPGAProb";
// import { Container } from "@mui/material";

import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RiskMapClipEarthquake from "./RiskMapClipEarthquake";
import EarthPrepare from "./EarthPrepare";
import EarthquakeLoss from "./EarthquakeLoss";
import EarthQuakeTable from "../Table/EarthQuakeTable";
import RiskMapClipFault from "./RiskMapClipFault";

const EerthQuakeResult_v2 = ({ report }) => {
  //   const [distanceInfo, setDistanceInfo] = useState([]);

  const polygonData = useSelector((state) => state.polygonData.polygonData);

  // const targetInfo = useSelector((state) => state.targetInfo);
  const targetInfo = report?.product?.target_info;
  // console.log("polygonData:", polygonData);
  // console.log("polygonData1:", polygonData1);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            建物與斷層距離
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RiskMapClipFault report={report} />
          <br />
          <FaultDistanceTable report={report} />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            建築物的地震風險分析
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RiskMapClipEarthquake report={report} />
        </AccordionDetails>
      </Accordion>
      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            建築的設計地震，全倒發生機率(old)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PgaProbBuilding2
            report={report}
            polygonData={polygonData}
            targetInfo={targetInfo}
          />
        </AccordionDetails>
      </Accordion> */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            建築的設計地震，全倒發生機率
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EarthPGAProb
            report={report}
            polygonData={polygonData}
            targetInfo={targetInfo}
          />
          <EarthQuakeTable report={report} />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            每年平均損失與建物重建成本
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EarthquakeLoss report={report} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            地震保險
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            根據上述每年平均損壞比率與建物成本，本建物的每年平均損失(年期望損失)為
            <span style={{ color: "red" }}>
              {report.risk_results["earthquake"][0]}元/年
            </span>
            的期望損失
            如果您只購買基本地震險(保費1350元，購屋時銀行的要求火險與地震險)，其保額僅150萬，且僅在半倒與全倒時才會有理賠。
            若未購買基本地震險，目前您的房屋仍有
            <span style={{ color: "red" }}>
              {(report.risk_results["earthquake"][8] / 10000).toFixed(2)}萬元
            </span>
            的保險缺額。 若已購買基本地震險，則有{" "}
            <span style={{ color: "red" }}>
              {(report.risk_results["earthquake"][8] / 10000 - 150).toFixed(2)}
              萬元
            </span>
            的保險缺額。
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            地震預防方案
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EarthPrepare />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default EerthQuakeResult_v2;
