import React, { useEffect, useState } from "react";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import RiskRadarChart from "../../components/Charts/RiskRadarChart";
import "./RiskReport.scss";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../axios";
import RiskMapClip from "../../components/RiskMapAll/RiskMapClip";
import RiskMapClipFree from "../../components/RiskMapAll/RiskMapClipFree";
import RiskMapClipSeaLevel from "../../components/RiskMapAll/RiskMapClipSeaLevel";
import RiskMapClipLiq from "../../components/RiskMapAll/RiskMapClipLiq";
import RiskMapClipEarthquake from "../../components/RiskMapAll/RiskMapClipEarthquake";
import RiskMapClipLandSlide from "../../components/RiskMapAll/RiskMapClipLandSlide2";
import PointProb from "../../components/Map/PointProbRisk";
// import PointProb from "../../components/Map/PointProbAll";
import Prob2 from "../../components/Prob/Prob2";
import DepthProb from "../../components/Prob/DepthProb";
import DepthProbTable from "../../components/Prob/DepthProbTable";
import ReportMapSlider from "../../components/Map/ReportMapSlider";
import ReportMapSlider2 from "../../components/Map/ReportMapSlider2";
import html2canvas from "html2canvas";
import axios from "axios";
import { Tabs, Tab } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home"; // Example icon
import InfoIcon from "@mui/icons-material/Info"; // Another example icon
import DiamondIcon from "@mui/icons-material/Diamond";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import FloodIcon from "@mui/icons-material/Flood";
import PublicIcon from "@mui/icons-material/Public";

import HouseboatIcon from "@mui/icons-material/Houseboat";
import LandslideIcon from "@mui/icons-material/Landslide";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import BuildingRisk from "../../components/RiskMapAll/BuildingRisk";
import BuildingRiskAll from "../../components/RiskMapAll/BuildingRiskAll";
import FloodResult from "../../components/RiskMapAll/FloodResult";
import EerthquakeResult from "../../components/RiskMapAll/EerthquakeResult";
import TsunamiIcon from "@mui/icons-material/Tsunami";
import { RiEarthquakeFill } from "react-icons/ri";
import LiqResult from "../../components/RiskMapAll/LiqResult";
import SealevelResult from "../../components/RiskMapAll/SealevelResult";
import BuildingMap from "../../components/RiskMapAll/BuildingMap";
import FloodMapboxSyncMaps from "../../components/RiskMapAll/FloodMapboxSyncMaps";
import Compare from "../../components/RiskMapAll/Compare";
import PaidReport from "./PaidReport";
import Summary from "./Summary";

const FreeReport = ({ report }) => {
  const navigate = useNavigate();
  const { pkid } = useParams();

  return (
    // <div style={{ width: "100%" }}>
    // <div className="detail-root" style={{ padding: "0px" }}>
    <div className="detail-root" style={{ padding: "0px" }}>
      <Grid container spacing={3}>
        {/* <Grid item xs={12} md={12}>
          <Typography className="section">公開風險地圖</Typography>
          <RiskMapClip report={report} />
        </Grid> */}
        <Grid item xs={12} md={12}>
          <Typography className="section">公開風險地圖</Typography>
          <RiskMapClipFree report={report} />
        </Grid>
      </Grid>
    </div>
  );
};

export default FreeReport;
