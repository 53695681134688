import React, { useEffect, useState } from "react";
import { Watermark } from "antd";
import {
  Button,
  Container,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../axios";
import SelectVersion from "./SelectVersion_v2";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./RiskReport_dev.scss";
import { useSelector } from "react-redux";

const RiskReport4 = () => {
  const { pkid } = useParams(); // Ensure this correctly gets the pkid from the URL
  // console.log("URL pkid:", pkid);
  const { user } = useSelector((state) => state.auth);

  const [report, setReport] = useState({});
  const [product, setProduct] = useState({});
  const [productTargetInfo, setProductTargetInfo] = useState(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [messageIndex, setMessageIndex] = useState(0);
  const loadingMessages = [
    "載入淹水風險...",
    "載入地震風險...",
    "載入土壤液化風險...",
    "載入海平面上升風險...",
    "載入坡地風險...",
    "完成報告...",
  ];

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));

    // Check if user is logged in and token exists
    if (!user || !userData?.access) {
      navigate("/login"); // Navigate to login if no user or token
    } else {
      // Check if the access token has expired
      const tokenParts = JSON.parse(atob(userData.access.split(".")[1]));
      const now = Date.now() / 1000; // Convert current time to seconds

      if (tokenParts.exp < now) {
        navigate("/login"); // Navigate to login if token is expired
      }
    }
  }, [user, navigate]);

  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchProduct = async (pkid) => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(`risk/products/${pkid}/`);
        if (isMounted && response.data) {
          setProduct(response.data);
          setLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setError("Failed to load product data."); // Set error message
          setLoading(false);
        }
      }
    };

    if (pkid) {
      fetchProduct(pkid);
    }

    return () => {
      isMounted = false;
    };
  }, [pkid]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const res = await axiosInstance.get("profile/me/");
        setProfile(res.data.profile);
      } catch (err) {
        console.error("Error fetching profile:", err);
      }
    };
    fetchProfile();
  }, []);

  // on hold
  const handleDownloadClick = async () => {
    try {
      const product_pkid = Number(pkid);
      if (!product_pkid) {
        console.error("Invalid pkid:", pkid);
        return;
      }

      const response = await axiosInstance.get(
        `risk/generate_report/${product_pkid}`,
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `report_${product_pkid}.docx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the report:", error);
    }
  };

  // change logic to freereport using product,
  // paid report need to  pay by point then generate report

  useEffect(() => {
    if (product && product.target_info) {
      try {
        const parsedTargetInfo = JSON.parse(product.target_info);
        setProductTargetInfo(parsedTargetInfo);
      } catch (error) {
        console.error("Error parsing target_info:", error);
      }
    }
  }, [product]);

  if (error) {
    return (
      <div>
        <Typography variant="h5" color="error">
          {error}
        </Typography>
      </div>
    );
  }

  if (!product || Object.keys(product).length === 0 || loading) {
    return (
      <div className="detail-root">
        <div className="detail-description">
          <Container className="risk-report-loading" maxWidth="sm">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="50vh"
            >
              <CircularProgress sx={{ color: "#4C839B" }} />
              <Typography variant="h5" sx={{ ml: 2, color: "#4C839B" }}>
                {loadingMessage || "載入報告..."}
              </Typography>
            </Box>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <Container className="risk-report" sx={{ padding: "0px" }}>
      <Watermark
        content={[
          "安家百科版權所有",
          `使用者:${profile.username}`,
          `${profile.email}`,
          `${profile.phone_number}`,
        ]}
        zIndex={0}
      >
        <div className="detail-root1">
          <div className="title-container">
            <div className="report-h0">SAFEHOUSING</div>
            <h3 className="title-text">安家百科-專屬報告</h3>
            <hr className="title-divider" />
          </div>
          {/* <div className="detail-h0">SAFEHOUSING</div> */}
          <div className="detail-description1">
            <div className="detail-title">
              {/* <h1 className="section-heading-1">安家百科-專屬報告</h1> */}
              <Typography variant="subtitle1" component="div" gutterBottom>
                產品編號: {product.pkid}
                {/* 版本號: {report.version} */}
              </Typography>
            </div>
            <div>
              <Button
                variant="contained"
                onClick={handleDownloadClick}
                sx={{ backgroundColor: "#4C839B" }}
              >
                <Typography
                  variant="section"
                  component="div"
                  style={{ marginRight: "8px", fontWeight: "bold" }}
                >
                  下載報告
                </Typography>
                <CloudDownloadIcon />
              </Button>
            </div>
          </div>

          <SelectVersion
            // report={report}
            product={product}
            productTargetInfo={productTargetInfo}
          />
          <p style={{ padding: "10px" }}>
            免責聲明：
            「本系統的分析結果是基於資料產生時所使用的最佳演算法和資料進行的評估。由於現實狀況可能因特定不動產本身或附近排水設施的維護情況、水工結構物及地形變化等因素而與模擬結果有所不同，安家百科致力於維持本系統內容的正確性。本分析內容旨在提供風險資訊供使用者參考，並不構成任何投資建議或結論，亦不代表安家百科的立場。使用者必須自行承擔使用資訊的風險，並對其使用結果負責。安家百科不會對因使用本系統分析內容而產生的任何損失或損害承擔責任。」
          </p>
        </div>
      </Watermark>
    </Container>
  );
};

export default RiskReport4;
