import React from "react";
import "./GoldenCircle.css";

const GoldenCircle = () => {
  return (
    <div className="golden-circle-page">
      {/* <div className="circle-container">
        <div className="outer-circle">
          <div className="middle-circle">
            <div className="inner-circle">
              <h2>為什麼 (Why)</h2>
            </div>
            <div className="how-text">
              <h2>如何 (How)</h2>
            </div>
          </div>
          <div className="what-text">
            <h2>做什麼 (What)</h2>
          </div>
        </div>
      </div> */}
      <div className="section-container">
        <section className="section">
          <h2>保護您的最重要資產，守護您的家人未來 </h2>
          <p>
            房屋是您一生中最大的資產，也是您和家人安全與幸福的堡壘。然而，天然災害的無法預測性威脅著這一切。我們深信，每個人都應該有能力了解並應對這些風險，確保居住環境的安全與穩定。
          </p>
        </section>
        <section className="section">
          <h2>我們提供量化風險分析與專業風險管理策略</h2>
          <p>
            透過先進科學的數據分析技術，我們量化各種天然災害對您房屋的潛在影響，並提供具體的避險建議。我們的專家團隊結合最新的研究成果和實用工具，幫助您做出明智的決策，保護您的資產不受損失。
          </p>
        </section>
        <section className="section">
          <h2>我們給您安家百科 - 您的住房安全資訊平台</h2>
          <p>
            我們創建了「安家百科」網站，提供全面的風險評估工具、專家指南以及個性化的避險策略。無論您是首次購屋者還是資深房產投資者、工廠老闆、物流業者、銀行業、產險業等，這裡都有適合您的資源，助您打造一個更安全、更穩固的居住環境。
          </p>
          {/* <button className="cta-button">了解更多</button> */}
        </section>
      </div>
    </div>
  );
};

export default GoldenCircle;
