import React from "react";
import {
  Tabs,
  Tab,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DepthProbBuildingSealevel from "./DepthProbBuildingSealevel";
import DepthProbTableBuildingSealevel from "./DepthProbTableBuildingSealevel";

const SealevelProb = ({ report, productTargetInfo }) => {
  const [tabValue, setTabValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const prob_s1 = report?.risk_results?.["sealevel"][7][0][0];
  const prob_s2 = report?.risk_results?.["sealevel"][7][1][0];
  const prob_s3 = report?.risk_results?.["sealevel"][7][2][0];
  const prob_s4 = report?.risk_results?.["sealevel"][7][3][0];
  const d_all = report?.risk_results?.["sealevel"][8];

  const sealevelCarLoss = report?.risk_results["sealevel"][3];
  const sealevelFurnitureLoss = report?.risk_results["sealevel"][4];
  const sealevelTotalLoss = report?.risk_results["sealevel"][5];
  const sealevel_rise_masked_and_probabilities_all =
    report?.risk_results["sealevel"][7];

  //   console.log("prob_s1", prob_s1);
  //   console.log("report", report);
  //   console.log("prob_s1", prob_s1);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        各情境水深機率表
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="flood info tabs"
            centered={!isMobile}
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : false}
          >
            <Tab label="海平面上升1.5m" />
            <Tab label="淹平面上升2.0m" />
            <Tab label="海平面上升4.0m" />
            <Tab label="海平面上升4.7m" />
          </Tabs>
        </Box>
        {tabValue === 0 && (
          <Box p={3}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>S1:海平面上升1.5m+AR6降雨情境：</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    height: "50vh",
                    marginBottom: "50px",
                    minHeight: "410px",
                  }}
                >
                  <DepthProbBuildingSealevel
                    report={report}
                    waterProbData={prob_s1}
                  />
                </div>
                <Typography
                  sx={{ padding: "15px" }}
                  variant="body1"
                  component="div"
                  gutterBottom
                >
                  依據本系統分析，本建物一樓地板比人行道高
                  {productTargetInfo?.["maskingHeight"]}
                  公分，本建物一樓每年
                  <span style={{ fontSize: "16px", color: "red" }}>
                    大於10公分的淹水機率
                    {sealevel_rise_masked_and_probabilities_all[0][0]["10cm"]}%
                  </span>
                  。 考量不同淹水深度與對應的發生機率，此建物的汽機車每年有
                  <span style={{ fontSize: "16px", color: "red" }}>
                    {sealevelCarLoss[0]}
                  </span>
                  元的期望損失，每年因淹水平均期望損失為
                  <span style={{ fontSize: "16px", color: "red" }}>
                    {sealevelTotalLoss[0]}元
                  </span>
                  。相關建議列於淹水保險與淹水預防措施兩章節。
                </Typography>

                <div className="flood-prob-table-container">
                  <DepthProbTableBuildingSealevel
                    report={report}
                    waterProbData={prob_s1}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
            {/* Repeat for other items as needed */}
          </Box>
        )}
        {tabValue === 1 && (
          <Box p={3}>
            <Accordion
              defaultExpanded
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>S2:海平面上升2.0m+AR6降雨情境：</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    height: "50vh",
                    marginBottom: "50px",
                    minHeight: "410px",
                  }}
                >
                  <DepthProbBuildingSealevel
                    report={report}
                    waterProbData={prob_s2}
                  />
                </div>
                <Typography
                  sx={{ padding: "15px" }}
                  variant="body1"
                  component="div"
                  gutterBottom
                >
                  依據本系統分析，本建物一樓地板比人行道高
                  {productTargetInfo?.["maskingHeight"]}
                  公分，本建物一樓每年
                  <span style={{ fontSize: "16px", color: "red" }}>
                    大於10公分的淹水機率
                    {sealevel_rise_masked_and_probabilities_all[1][0]["10cm"]}%
                  </span>
                  。 考量不同淹水深度與對應的發生機率，此建物的汽機車每年有
                  <span style={{ fontSize: "16px", color: "red" }}>
                    {sealevelCarLoss[1]}
                  </span>
                  元的期望損失，每年因淹水平均期望損失為
                  <span style={{ fontSize: "16px", color: "red" }}>
                    {sealevelTotalLoss[1]}元
                  </span>
                  。相關建議列於淹水保險與淹水預防措施兩章節。
                </Typography>

                <div className="flood-prob-table-container">
                  <DepthProbTableBuildingSealevel
                    report={report}
                    waterProbData={prob_s2}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
        {tabValue === 2 && (
          <Box p={3}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>S3:海平面上升4.0m+AR6降雨情境：</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    height: "50vh",
                    marginBottom: "50px",
                    minHeight: "410px",
                  }}
                >
                  <DepthProbBuildingSealevel
                    report={report}
                    waterProbData={prob_s3}
                  />
                </div>
                <Typography
                  sx={{ padding: "15px" }}
                  variant="body1"
                  component="div"
                  gutterBottom
                >
                  依據本系統分析，本建物一樓地板比人行道高
                  {productTargetInfo?.["maskingHeight"]}
                  公分，本建物一樓每年
                  <span style={{ fontSize: "16px", color: "red" }}>
                    大於10公分的淹水機率
                    {sealevel_rise_masked_and_probabilities_all[2][0]["10cm"]}%
                  </span>
                  。 考量不同淹水深度與對應的發生機率，此建物的汽機車每年有
                  <span style={{ fontSize: "16px", color: "red" }}>
                    {sealevelCarLoss[2]}
                  </span>
                  元的期望損失，每年因淹水平均期望損失為
                  <span style={{ fontSize: "16px", color: "red" }}>
                    {sealevelTotalLoss[2]}元
                  </span>
                  。相關建議列於淹水保險與淹水預防措施兩章節。
                </Typography>

                <div className="flood-prob-table-container">
                  <DepthProbTableBuildingSealevel
                    report={report}
                    waterProbData={prob_s3}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
        {tabValue === 3 && (
          <Box p={3}>
            <Accordion
              defaultExpanded
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>S4:海平面上升4.7m+AR6降雨情境：</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    height: "50vh",
                    marginBottom: "50px",
                    minHeight: "410px",
                  }}
                >
                  <DepthProbBuildingSealevel
                    report={report}
                    waterProbData={prob_s4}
                  />
                </div>
                <Typography
                  sx={{ padding: "15px" }}
                  variant="body1"
                  component="div"
                  gutterBottom
                >
                  依據本系統分析，本建物一樓地板比人行道高
                  {productTargetInfo?.["maskingHeight"]}
                  公分，本建物一樓每年
                  <span style={{ fontSize: "16px", color: "red" }}>
                    大於10公分的淹水機率
                    {sealevel_rise_masked_and_probabilities_all[3][0]["10cm"]}%
                  </span>
                  。 考量不同淹水深度與對應的發生機率，此建物的汽機車每年有
                  <span style={{ fontSize: "16px", color: "red" }}>
                    {sealevelCarLoss[3]}
                  </span>
                  元的期望損失，每年因淹水平均期望損失為
                  <span style={{ fontSize: "16px", color: "red" }}>
                    {sealevelTotalLoss[3]}元
                  </span>
                  。相關建議列於淹水保險與淹水預防措施兩章節。
                </Typography>

                <div className="flood-prob-table-container">
                  <DepthProbTableBuildingSealevel
                    report={report}
                    waterProbData={prob_s4}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default SealevelProb;
