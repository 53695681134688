// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/img/city-l.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-background {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); /* Replace with your background image URL */\n  background-size: cover;\n  background-position: center;\n  height: 110vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/Login/login.scss"],"names":[],"mappings":"AAEE;EACE,yDAAA,EAAA,2CAAA;EACA,sBAAA;EACA,2BAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADJ","sourcesContent":["\n\n  .login-background {\n    background-image: url('../../../public/img/city-l.png'); /* Replace with your background image URL */\n    background-size: cover;\n    background-position: center;\n    height: 110vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
