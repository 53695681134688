import { Typography } from "@mui/material";
import React from "react";

const NewsSearch = ({ report }) => {
  const address = JSON.parse(report?.product?.description)?.searchData?.address;
  //   console.log(report);
  //   console.log(address);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    fontFamily: "Arial, sans-serif",
  };

  const headingStyle = {
    fontSize: "24px",
    marginBottom: "20px",
    color: "#333",
  };

  const linkStyle = {
    fontSize: "18px",
    color: "#1a73e8",
    fontWeight: "bold",
    textDecoration: "none",
    border: "1px solid #1a73e8",
    // padding: "10px 20px",
    borderRadius: "5px",
    transition: "background-color 0.3s",
    margin: "10px 0px",
  };

  return (
    <div>
      <Typography variant="h6" style={{ fontWeight: "bold" }}>
        您的搜尋地址: {address}
      </Typography>
      {/* <h1 style={headingStyle}>您的搜尋地址: {address}</h1> */}
      <a
        href={`https://www.google.com/search?q=${address}淹水`}
        target="_blank"
        rel="noopener noreferrer"
        style={linkStyle}
        onMouseEnter={(e) => (e.target.style.backgroundColor = "#f1f1f1")}
        onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")}
      >
        歷史淹水搜尋結果
      </a>
    </div>
  );
};

export default NewsSearch;
