import React, { useState } from "react";
import "./InclinedBuildings.scss";

const InclinedBuildings = () => {
  return (
    <div className="incliend-questionnaire">
      <h1>房屋傾斜扶正工法</h1>

      <p>
        房屋傾斜並不代表無法修復！無論是地震後造成的傾斜，或因設計不良等原因導致，若房屋傾斜角度在1/200至1/40之間，仍可透過多種扶正工法進行修復。以下是幾種常見的扶正工法介紹：
      </p>
      {/* <p>以下介紹幾種常見的房屋扶正工法</p> */}
      <br />
      <br />

      <div className="incliend-question">
        <div className="incliend-check_area">
          <label>1.灌漿工法</label>
        </div>
        <div className="incliend-desc_area">
          <div className="incliend-desc">
            灌漿工法使用斜撐先暫時扶正建築物，然後通過低壓或中壓灌漿將混凝土注入建築物底部，將房屋頂起，達到扶正效果。同時，灌漿還能改善土壤品質，降低土壤液化的風險，增加穩定性。
          </div>
          <div className="incliend-images">
            <div>
              <img
                className="incliend-img"
                src="/img/灌漿工法.svg"
                alt="灌漿工法"
              />
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="incliend-question">
        <div className="incliend-check_area">
          <label>2.千斤頂工法</label>
        </div>
        <div className="incliend-desc_area">
          <div className="incliend-desc">
            千斤頂工法此方法先將建築基腳移除，重新製作基腳使建物恢復水平。操作過程中先用斜撐支撐建築，挖出一個地下施工空間，放置千斤頂使建築暫時恢復水平。接著，拆除舊有的梁柱，重建堅固基腳，最後回填施工空間，完成修復。
          </div>
          <div className="incliend-images">
            <div>
              <img
                className="incliend-img"
                src="/img/千斤頂工法.svg"
                alt="千斤頂工法"
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="incliend-question">
        <div className="incliend-check_area">
          <label>3.加載工法</label>
        </div>
        <div className="incliend-desc_area">
          <div className="incliend-desc">
            加載工法類似蹺蹺板的原理，當一側因重量過大而造成傾斜時，可以在另一側增加重量，使兩側沉陷量相同，達到扶正的效果。
          </div>
          <div className="incliend-images">
            <div>
              <img
                className="incliend-img"
                src="/img/加載工法.svg"
                alt="加載工法"
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="incliend-question">
        <div className="incliend-check_area">
          <label>4.洗土工法</label>
        </div>
        <div className="incliend-desc_area">
          <div className="incliend-desc">
            洗土工法原理與加載工法相似，但此方法是用水軟化土壤，移除沉陷較少一側的部分土壤，從而達到扶正效果。
          </div>
          <div className="incliend-images">
            <div>
              <img
                className="incliend-img"
                src="/img/洗土工法.svg"
                alt="洗土工法"
              />
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default InclinedBuildings;
