import React from "react";
import "./Prob2.scss";

const ProbTable = ({ report }) => {
  const { p10_200 } = report;

  // Convert the object into an array of objects for easy mapping
  const tableData = Object.keys(p10_200).map((key) => {
    const depth = key.replace("cm", ""); // remove 'cm' to convert to depth in meters
    const probability = p10_200[key];
    const frequency =
      probability > 0 ? `${(100 / probability).toFixed(0)}年` : "N/A年";
    // 10年內發生機率=1-10年都不發生
    // (1-(1-發生機率)^10)*100 (%)

    const tenYearRisk = (1 - (1 - probability/100) ** 10)*100;
    return {
      depth: `${depth / 100}m`,
      probability: `${probability.toFixed(3)}%`,
      frequency,
      tenYearRisk: `${tenYearRisk.toFixed(3)}%`,
    };
  });

  return (
    <div className="prob-table-all">
      <div className="prob-table">
        <table cellSpacing="5">
          <thead>
            <tr>
              <th>淹水深度 (m)</th>
              <th>發生機率 (%)</th>
              <th>多少年發生一次</th>
              <th>10年內發生的機率 (%)</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td>{row.depth}</td>
                <td>{row.probability}</td>
                <td>{row.frequency}</td>
                <td>{row.tenYearRisk}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProbTable;
