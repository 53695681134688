import React from "react";
import CloudIcon from "@mui/icons-material/Cloud";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import SatelliteIcon from "@mui/icons-material/Satellite";
import MapIcon from "@mui/icons-material/Map"; // Replace with MUI icon
import "./Tech.scss";

const Tech = () => {
  return (
    <div className="tech">
      <div className="title-container">
        <div className="report-h0">SAFEHOUSING</div>
        <h3 className="title-text">技術理論</h3>
        <hr className="title-divider" />
      </div>

      <div className="tech-video-container">
        <video className="tech-video" autoPlay muted>
          <source src="/tech00.mp4" type="video/mp4" />
        </video>
      </div>

      <div className="tech-content">
        <div className="tech-item item-1">
          <div className="tech-header">
            <CloudIcon className="tech-icon" />
            <h5>水理演算法</h5>
          </div>
          <div className="tech-text">
            <p>
              採用高精度淹水演算法，可處理10億個網格，以1m
              x1m網格來看，可達到1000平方公里的範圍，由此進行高精度淹水模型，並用於各種降雨情境的模擬、計算每個房屋的水深對機率曲線等。
            </p>
          </div>
        </div>

        <div className="tech-item item-2">
          <div className="tech-header">
            <ArchitectureIcon className="tech-icon" />
            <h5>雲端架構</h5>
          </div>
          <div className="tech-text">
            <p>
              數據資料和演算分析都在雲端進行，系統能根據用戶輸入的地點及精細度等要求，客製化即時彙整出分析結果，以迴響式設計以便在不同尺寸比例的裝置上清晰瀏覽。
            </p>
          </div>
        </div>

        <div className="tech-item item-3">
          <div className="tech-header">
            <SatelliteIcon className="tech-icon" />
            <h5>巨量資料演算法</h5>
          </div>
          <div className="tech-text">
            <p>
              數據來自水利署、縣市政府1165座路面淹水站和267座水位站、氣象局的1091座雨量站及中央氣象局雷達整合回波圖等，以自動化的模式，實時萃取巨量原始數據，以自家研發的演算法綜合分析出最具實用價值的成果。
            </p>
          </div>
        </div>

        <div className="tech-item item-4">
          <div className="tech-header">
            <MapIcon className="tech-icon" />
            <h5>最佳化理論</h5>
          </div>
          <div className="tech-text">
            <p>優化地圖，使高精度地形圖能夠與現實地形一致。牽涉速度優化。</p>
          </div>
        </div>
      </div>
      {/* <div className="tech-content">
        <div className="tech-item item-1">
          <div className="tech-icon-container">
            <div className="tech-icon linear01_bg">
              <i className="fa-solid fa-cloud-showers-water"></i>
            </div>
          </div>
          <div className="tech-text">
            <h5>水理演算法</h5>
            <p>
              採用高精度淹水演算法，可處理10億個網格，以1m
              x1m網格來看，可達到1000平方公里的範圍，由此進行高精度淹水模型，並用於各種降雨情境的模擬、計算每個房屋的水深對機率曲線等。
            </p>
          </div>
        </div>

        <div className="tech-item item-2">
          <div className="tech-icon-container">
            <div className="tech-icon linear02_bg">
              <i className="fa-solid fa-sitemap"></i>
            </div>
          </div>
          <div className="tech-text">
            <h5>雲端架構</h5>
            <p>
              數據資料和演算分析都在雲端進行，系統能根據用戶輸入的地點及精細度等要求，客製化即時彙整出分析結果，以迴響式設計以便在不同尺寸比例的裝置上清晰瀏覽。
            </p>
          </div>
        </div>

        <div className="tech-item item-3">
          <div className="tech-icon-container">
            <div className="tech-icon linear03_bg">
              <i className="fa-solid fa-satellite-dish"></i>
            </div>
          </div>
          <div className="tech-text">
            <h5>巨量資料演算法</h5>
            <p>
              數據來自水利署、縣市政府1165座路面淹水站和267座水位站、氣象局的1091座雨量站及中央氣象局雷達整合回波圖等，以自動化的模式，實時萃取巨量原始數據，以自家研發的演算法綜合分析出最具實用價值的成果。
            </p>
          </div>
        </div>

        <div className="tech-item item-4">
          <div className="tech-icon-container">
            <div className="tech-icon linear04_bg">
              <i className="fa-solid fa-map-location-dot"></i>
            </div>
          </div>
          <div className="tech-text">
            <h5>最佳化理論</h5>
            <p>優化地圖，使高精度地形圖能夠與現實地形一致。牽涉速度優化。</p>
          </div>
        </div>
      </div> */}

      <div className="tech-theory">
        <div className="title-container mt-150">
          <div className="report-h0">SAFEHOUSING</div>
          <h3 className="title-text">技術理論</h3>
          <hr className="title-divider" />
        </div>
        <div className="tech-theory-content">
          <div className="tech-theory-images">
            <img src="/img/tech-pic2.png" alt="Tech Pic 2" />
            <p>圖2 3Di計算解析度示意圖</p>
            <img src="/img/tech-pic3.png" alt="Tech Pic 3" />
            <p>圖3 傳統模式與3Di在台北市文山區的差異比較</p>
            <img src="/img/tech-pic4.png" alt="Tech Pic 4" />
            <p>圖3 Horton入滲率示意圖</p>
          </div>
          <p className="tech-theory-text">
            3Di以有限體積法(finite volume)解二維淺水方程式(shallow water
            equation)，藉由Quadtree將網格整合(如
            <span className="primary_color">圖1</span>
            )，用以減少計算網格量，並以Subgrid考慮每一網格的特徵(水理模式參數)藉以修正摩擦係數與動量，計算解析度至DEM網格，因此採用光達DEM，則計算解析度可達到0.5
            m x 0.5 m(如<span className="primary_color">圖2</span>)。
            <br />
            <br />
            都市水文較自然環境的水文複雜的原因主要為有人工結構物與地下水工結構物等，如
            <span className="primary_color">圖4</span>
            所示，(1)降雨非均勻、(2)截流、(3)入滲、(3)屋頂排水至下水道或空地、(4)地表逕流(考量粗糙度)、(5)下水道排水、(6)人工構造物：抽水站、堰、孔口等、(7)LID設施(
            <span className="primary_color">圖5</span>所示）、(8)建築物阻礙。
            <br />
            <br />
            1、淺水波方程式
            <br />
            在3Di模擬中，數值解利用迪聖凡南(de Saint Venant)淺水波方程式(shallow
            water
            equation)可解出流路。淹水模擬所計算之流體屬於無粘性淺水流(inviscid
            water)，及相對坡度較小之狀況(Alkema
            2007)。淺水流方程式包含了連續方程式及動量方程式於一維及二維的方向。下列方程式為計算2D地表逕流之根據方程式：
            <br />
            ∂h/∂t+u ∂h/∂x+v ∂h/∂y=i　　　　　　　　　　　連續方程式
            <br />
            ∂u/∂t+u ∂u/∂x+v ∂u/∂y+g ∂ζ/∂x+c_f/h u‖u‖=0　　X方向之動量方程式
            <br />
            ∂v/∂t+u ∂v/∂x+v ∂v/∂y+g ∂ζ/∂y+c_f/h v‖v‖=0　　 Y方向之動量方程式
            <br />
            式中h為水深(h=ζ-e) ，ζ 為某參考平面之上之水深，e
            為某參考平面上地表高程，u 及v 為X及Y方向的平均水深流速，‖u‖
            為流速之大小，c_f 為曼寧公式及切西方程式之無因此摩擦係數(Stelling
            2012; van’t Veld 2015)。
            <br />
            模擬管流必須假設雨水下水道系統必須是互相聯通及遵守一維情況下之連續方程式和動量方程式：
            <br />
            ∂A/∂t+u ∂Au/∂x=0　　　　　　　　 連續方程式
            <br />
            ∂h/∂t+u ∂u/∂x=-g ∂η/∂x-γu　　　　　X方向之動量方程式
            <br />
            式中t 代表時間、x 代表地區座標系統之空間分佈、u
            代表未知之水深及水寬下之流速、η
            代表在水面或當被壓縮之水壓頭所產生之水壓、A 代表通水斷面、g
            代表重力加速度、最後 γ 代表非負數和非線性變化之變數(Casulli &
            Stelling 2013)。
            <br />
            建立非穩定流之數值解為具挑戰性的任務，藉由牛頓定律(Newton
            iteration)確保遵守質量守恆定律來解非穩定流系統。在雨水下水道系統中，如
            <span className="primary_color">圖1</span>
            所示當水深越深時，通水寬度也會隨之變小，其中必須利用兩個定律(Nested-Newton
            定律) 來確保體積守恆方程式。如需要更多資料可查詢下列：Casulli 2009;
            Casulli & Stelling 2013; Nelen & Schuurmans 2016。
            <br />
            <br />
            2、子網格法及四分樹法技術
            <br />
            由水深網格(bathymetry raster)或數值地形圖(DEM， digital elevation
            map) 、或摩擦因子網格(friction
            raster)來求淺水流方程式的2D地表逕流，除此之外也可用來計算水位和流速之間的關聯性。在計算水位與流速中將精細的網格資料，將相同屬性的網個進行聚集成一個大的網格來計算，這技術便稱為子網格法(subgrid
            technique)。至少四個DEM網格匯聚成一個大個的計算網格，此假設只能運用於水位或是地形變化不大的空間。3Di
            利用子網格法可求解連續方程式及動量方程式，在有限的計算量中運用高解析度的水深資料和糙度資料(Volp
            et al. 2013)可保持優勢的準確性和計算速度(Stelling 2012)。
            <br />
            在建立模型時，某些地區必須更著重其細部資料，如堤防(levee)周圍的資訊比平坦地形(polder)的更為重要、都市區域比鄉村區域來的重要(Niemczynowicz，1999)。四分樹法(quadtree)是一種數據的架構形式類似樹狀圖，將二維區域分成四個正方形。每四個正方形又可以進行一次分割成四個大小相同的正方格，建立一個可以分級計算精度的技術。如
            <span className="primary_color">圖2</span>
            所示，水面為有兩種大小不同的計算網格，因此具有兩種的
            計算精度。當網格精細化後，計算網格的解析度將會是上一層四分樹網格的兩倍。
            <br />如<span className="primary_color">圖2</span>
            所示，一個計算網格由4x4與16個DEM組成，那麼就可以減少至5個計算網格(van’t
            Veld 2015)。3Di
            是唯一的二維動力波模式利用子網格法和四分數法技術，一般模式只利用DEM原本的解析度大小來進行演算，如SOBEK
            2及 Delft3D。
            <br />
            <br />
            3、水文損失
            <br />
            3Di 模式中，每個網格的入滲體積(infiltration
            volume)為該地區土壤的入滲率(infiltration
            rate)及土地利用的透水面積的乘積，入滲率也會隨時間而變化，而入滲容量(infiltration
            capacity，亦即最大入滲率)為定值。此外，3Di模式可藉由土地利用圖層來計算截流總量，以精緻的土地利用圖層可計算出該區域的截流容量。
            <br />
            入滲量的計算在3Di中分為兩種：
            <br />
            (1)
            簡化入滲率：以恆定的入滲率(mm/day)進行入滲量的計算，並可配合最大入滲量，避免過大的入滲總量；
            <br />
            (2) Horton入滲率：Horton
            的入滲公式描述了隨時間衰減的入滲率，由三個變量決定入滲率，基於以下概念：由於土壤特性的變化，入滲速率衰減到平衡值。在數學上，它定義為：
            f(x，y，t)=f_equ (x，y)+[f_ini (x，y)-f_equ (x，y)] e^(-t/T(x，y) )
            <br />
            其中，f(x，y，t)是隨時間和空間變化的入滲率，f_equ (x，y)與f_ini
            (x，y)分別是平衡和初始入滲率。衰減期決定了入滲率達到其平衡的時間，衰減函數的示例如
            <span className="primary_color">圖3</span>
            所示。使用上，一般未考慮地下水、伏流水與地表水互動時，採用簡化入滲率；在考慮地下水、伏流水與地表水互動時，採用Horton入滲率。
            <br />
            <br />
            4、地表逕流路徑
            <br />
            3Di模式以DEM作為地表網格的高程(如
            <span className="primary_color">圖4[a]</span>
            )，組成以非線性體積變化-水位(V-H) 關係的計算網格(如
            <span className="primary_color">圖4[b]</span>
            )。此計算方式所得出水深將作為解2D淺水流方程式的參數(Dahm et al.
            2014)。因為它屬於基礎資料和易於模式使用，使得可以省去大量的計算時間。
            <br />
            DEM網格的高差決定了兩個計算網格(adjacent calculation
            cell)之間水流的走向。當計算結果水深高於臨近地表高程時，水流便會流向低的高程計算網格。兩個計算網格的計算流速將會受到陰影處(shaded
            cells)的地表結構物所影響，如
            <span className="primary_color">圖5</span>
            所示。除此之外，被區分成四分網格的大網格或被區分成一半的小網格也會是計算流速的考慮因子之一。
            <br />
            <br />
            5、1D-2D流體計算的交互作用
            <br />
            3Di模式可結合1D及2D流體計算(Nelen & Schuurmans
            2016)，2D計算網格的中心高程和1D的人孔空間分佈為兩個重要的因子。
            <br />
            模式中，人孔所存在的類型可分為三種：
            (1)孤立式(isolated)；(2)連接式(connected)；(3)嵌入式(embedded)。
            <br />
            孤立式(isolated)
            ：人孔內的水體不會與地表逕流水體相互影響。主要用於不會受到二維水位影響的一維節點，例如雨水下水道箱涵的分支點(例如壓力箱涵)或污水下水道人孔，可反應水壓，卻不會因此與二維水位有體積交換。
            <br />
            連接式(connected)
            ：當計算水深大於地表排水系統或是人孔高程時，水體會流入雨水下水道系統內；反之，當人孔內的計算水深大於地表高程時，雨水下水道系統內的水體將溢流出地表。主要用於雨水下水道系統的人孔的水量進出。
            <br />
            嵌入式(embedded)：雨水下水道水體當水位高於地表高程時不被約束而全部溢流出地表，反之亦然。在此設定下，計算水深與雨水下水道系統的水位相同。主要用於開放式水域，如一維節點的出入口位於二維河川、湖泊等。
            <br />
            <span className="primary_color">圖6</span>
            所示，在一個計算網格內有一個人孔。但在模式中，一個計算網格可存在許多人孔及可同時設定不同的人孔連接形式。
            <br />
            <br />
            6、1D 結構物
            <br />
            雨水下水道和地表排水結構物，例：人孔(manhole)、孔口(orifices)、涵洞(culverts)及堰(short
            weirs)等等。只需要調整通洪斷面資料和流量的變化，便可利用同一公式來進行演算。在此結構物下，不會存在某水深下的平均流速，但拇指定律可被接受的(rule
            of
            thumb)。下列方程式利用堰的潛沒(submerge)及未潛沒(unsubmerged)狀態公式，計算水體在1D結構物下的流速：
            <br />
            Q_i=mB 2/3 √(2/3 g) H_i^(2/3)　&
            <br />
            Q_i=mA_i √(2g(H ̃-h_i))
            <br />
            式中：m 代表流量在水流方向的坐標、H ̃ 代表能量能量水頭、B 為渠道寬。
            <br />
            <br />
            與公開淹水潛勢圖的差異
            <br />
            模擬條件：公開淹水潛勢圖的模擬條件主要以公務單位使用為主，不符合一般民眾使用的需求
            模式：公開淹水潛勢圖採用荷蘭SOBEK模式，該模式由於無法回應氣候變遷與大數據資料的需求而在2018年除役(洪水模擬指南Leidraad
            overstormingssimulaties,
            2018)，本淹水圖採用該指南認定的3Di模式進行模擬。
            <br />
            假設：公開淹水潛勢圖採用荷蘭SOBEK模式產製，其假設下雨在渠道或下水道，當渠道或下水道容量不足時，水才會溢淹出來。我們產製的高精度淹水圖，重現水文過程(降雨在地面，由物理決定水應該怎麼流動)，因此淹水的地點更接近實際狀況。
          </p>
        </div>
      </div>

      <div className="tech-case-study">
        <div className="title-container mt-150">
          <div className="report-h0">SAFEHOUSING</div>
          <h3 className="title-text">案例分享</h3>
          <hr className="title-divider" />
        </div>
        <div className="tech-case-images">
          <img src="/img/coke.jpg" alt="Coke" />
          <img src="/img/cityhall.png" alt="City Hall" />
          <img src="/img/road.jpg" alt="Road" />
        </div>
        <div className="case-study-footer">
          <p>SAFEHOUSING</p>
        </div>
      </div>
    </div>
  );
};

export default Tech;
