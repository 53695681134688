import React from "react";
import { motion, useInView } from "framer-motion";
import "./CustomAnalysis.css";

const visible = { opacity: 1, y: 0, transition: { duration: 0.8 } };

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible,
};

const CustomAnalysis = () => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { triggerOnce: true, threshold: 0.1 });

  return (
    <motion.div
      className="custom-analysis-container"
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      ref={ref}
      variants={{ visible: { transition: { staggerChildren: 0.2 } } }}
    >
      <motion.div className="custom-analysis-info" variants={itemVariants}>
        <div className="text-content">
          <motion.h2 variants={itemVariants}>
            超越公開資料，安家百科量化您的損失風險與安家建議
          </motion.h2>
          <motion.p variants={itemVariants}>
            「政府公開資料僅告訴您災害的規模，卻無法了解發生機率與可能損失。安家百科為您提供個別化的專業分析，量化您的損失資訊並提供具體的避險方法，讓您做出更有力的決策。」
          </motion.p>
          {/* <motion.h2 variants={itemVariants}>
            安家百科的淹水深度模擬解析度為公開資料的1600倍
          </motion.h2> */}
        </div>
        {/* <motion.div className="images-section" variants={itemVariants}>
          <div className="image-container">
            <motion.img
              src="/img/open_flood.png"
              alt="Public Data Analysis"
              variants={itemVariants}
            />
            <motion.p variants={itemVariants}>公開資料解析度 40*40 m</motion.p>
          </div>
          <div className="image-container">
            <motion.img
              src="/img/safe_flood.png"
              alt="Custom Analysis"
              variants={itemVariants}
            />
            <motion.p variants={itemVariants}>安家百科解析度 1m</motion.p>
          </div>
        </motion.div> */}
      </motion.div>

      <motion.div className="analysis-table" variants={itemVariants}>
        <table>
          <thead>
            <tr>
              <th>災害</th>
              <th>資料來源</th>
              <th>考量災害規模情境</th>
              <th>災害衝擊</th>
              <th>發生機率</th>
              <th>特定分析對象</th>
              <th>期望損失 (每年平均損失)</th>
              <th>安家建議</th>
            </tr>
          </thead>
          <tbody>
            {/* 淹水 */}
            <tr>
              <td rowSpan="2">淹水</td>
              <td>公開資料</td>
              <td>少量降雨量情境</td>
              <td>淹水潛勢圖，解析度 40m x 40m</td>
              <td>未提供</td>
              <td>未針對您的房屋分析</td>
              <td>未提供</td>
              <td>未提供</td>
            </tr>
            <tr>
              <td>安家百科</td>
              <td>大量降雨情境</td>
              <td>
                高精度淹水模擬圖，解析度 0.5m x 0.5m (資料密度為公開資料的 6400
                倍)
              </td>
              <td>本網站分析提供</td>
              <td>針對您的房屋分析</td>
              <td>針對您的房屋進行損失期望值分析</td>
              <td>針對您的房屋提出安家建議</td>
            </tr>

            {/* 地震 */}
            <tr>
              <td rowSpan="2">地震</td>
              <td>公開資料</td>
              <td>提供斷層位置</td>
              <td>未提供</td>
              <td>未提供</td>
              <td>未針對您的房屋分析</td>
              <td>未提供</td>
              <td>未提供</td>
            </tr>
            <tr>
              <td>安家百科</td>
              <td>引用斷層位置(公開資料)進行地震危害度分析</td>
              <td>導入台美日三國脆弱度曲線</td>
              <td>本網站分析提供</td>
              <td>針對您的房屋分析</td>
              <td>針對您的房屋進行損失期望值分析</td>
              <td>針對您的房屋提出安家建議</td>
            </tr>

            {/* 土壤液化 */}
            <tr>
              <td rowSpan="2">土壤液化</td>
              <td>公開資料</td>
              <td>土壤液化潛勢分析</td>
              <td>未提供</td>
              <td>未提供</td>
              <td>未針對您的房屋分析</td>
              <td>未提供</td>
              <td>未提供</td>
            </tr>
            <tr>
              <td>安家百科</td>
              <td>引用上述公開資料</td>
              <td>本網站分析提供</td>
              <td>本網站分析提供</td>
              <td>針對您的房屋分析</td>
              <td>針對您的房屋進行損失期望值分析</td>
              <td>針對您的房屋提出安家建議</td>
            </tr>

            {/* 氣候變遷 */}
            <tr>
              <td rowSpan="2">氣候變遷</td>
              <td>公開資料</td>
              <td>尚未公布</td>
              <td>無</td>
              <td>未提供</td>
              <td>未針對您的房屋分析</td>
              <td>未提供</td>
              <td>未提供</td>
            </tr>
            <tr>
              <td>安家百科</td>
              <td>海平面上升雨量增加</td>
              <td>
                高精度淹水模擬圖，解析度 0.5m x 0.5m (資料密度為公開資料的 6400
                倍)
              </td>
              <td>本網站分析提供</td>
              <td>針對您的房屋分析</td>
              <td>針對您的房屋進行損失期望值分析</td>
              <td>針對您的房屋提出安家建議</td>
            </tr>
          </tbody>
        </table>
      </motion.div>
    </motion.div>
  );
};

export default CustomAnalysis;
