import React, { useEffect, useState } from "react";
import "./DepthProbBuilding.scss";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  ReferenceArea,
} from "recharts";
import { setTargetInfo, resetTargetInfo } from "../../redux/targetInfoReducer";
import { useSelector, useDispatch } from "react-redux";

const DepthProbBuilding = ({ report, waterProbData }) => {
  const dispatch = useDispatch();

  // the targetInfo should come from report
  // const targetInfo = useSelector((state) => state.targetInfo);
  const targetInfo = JSON.parse(report?.product.target_info);
  // console.log("targetInfo", targetInfo);
  // const [maskingHeight, setMaskingHeight] = useState(0);
  const [data, setData] = useState(waterProbData || {});
  const [hoverDepth, setHoverDepth] = useState(null); // New state for tracking hover depth

  const CustomTooltip = ({ active, payload, label }) => {
    useEffect(() => {
      if (active && payload && payload.length) {
        setHoverDepth(payload[0].payload.淹水深度); // Assuming '淹水深度' is the depth
      } else {
        setHoverDepth(null); // Clear the hover depth when not active
      }
    }, [active, payload]);

    if (active && payload && payload.length) {
      const depthInMeters = (payload[0].value / 100).toFixed(1); // Convert to meters and format to one decimal
      let carLossRatio;
      if (depthInMeters >= 0.9) {
        carLossRatio = 100;
      } else if (depthInMeters >= 0.6) {
        carLossRatio = 50;
      } else if (depthInMeters >= 0.4) {
        carLossRatio = 27;
      } else if (depthInMeters >= 0.3) {
        carLossRatio = 14;
      } else {
        carLossRatio = 0; // Or set to default if there is a default ratio
      }

      const carLoss = `${(targetInfo.carValue * (carLossRatio / 100)).toFixed(
        2
      )}`;

      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: "2px",
          }}
        >
          <p>{`每年淹水>= ${payload[0].value}公分的機率為${label}%，汽機車損失為${carLoss}萬元`}</p>
          {/* <p>{`機率為${label}%`}</p> */}
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    // console.log("DepthProbBuilding received new data:", waterProbData);

    if (waterProbData) {
      const newData = Object.entries(waterProbData)
        .map(([depth, probability]) => {
          const depthValue = parseInt(depth.replace("cm", ""));
          // Apply masking, if the depth is less than masking height, probability is 0
          const adjustedProbability =
            depthValue <= targetInfo.maskingHeight
              ? 0
              : parseFloat(probability) || 0;

          return {
            淹水深度: depthValue - targetInfo.maskingHeight, // Adjust depth by subtracting masking height
            機率: adjustedProbability,
          };
        })
        .filter((entry) => entry.淹水深度 > 0); // Remove entries that are now less than or equal to 0

      // Sort the data by 淹水深度 in ascending order
      newData.sort((a, b) => a.淹水深度 - b.淹水深度);

      setData(newData);
      // console.log("setData:", newData);
    }
  }, [waterProbData, targetInfo.maskingHeight]);

  // console.log("data:", data);

  // console.log("targetInfo", targetInfo);

  const handleMaskingHeightChange = (event) => {
    // Parse the input value as an integer, default to 0 if not a number
    let inputHeight = parseInt(event.target.value) || 0;
    // If inputHeight is negative, reset it to 0
    inputHeight = Math.max(inputHeight, 0);

    dispatch(
      setTargetInfo({
        field: "maskingHeight",
        value: parseFloat(inputHeight),
      })
    );
  };

  return (
    <div className="DepthProb" style={{ fontFamily: "Arial", padding: "0px" }}>
      {/* <div className="input-container">
        <label htmlFor="maskingHeight">建物1F地板比人行道高(公分): </label>
        <input
          id="maskingHeight"
          type="number"
          min="0" // Set a minimum value of 0 to prevent negative numbers
          value={targetInfo.maskingHeight}
          onChange={handleMaskingHeightChange}
        />
        
      </div> */}
      <div className="chart-container">
        <ResponsiveContainer width="100%" height="70%">
          {/* <ResponsiveContainer width="100%" height="70%"> */}
          <AreaChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              // right: 30,
              left: 10,
              bottom: 20,
            }}
            style={{
              position: "absolute",
              bottom: 0, // Align the bottom of the chart with the container
              left: 0, // Align the left side of the chart with the container
              background: "transparent", // Ensure the background is transparent
            }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#000" />
            <XAxis
              type="number"
              dataKey="機率"
              // domain={[0.1, 50]}
              domain={["dataMin", 99]}
              // domain={[0.1, "dataMax"]}

              // scale="log"
              // ticks={[0.1, 0.15, 0.2, 0.3, 0.4, 0.5, 1, 5, 10, 25, 50, 75, 99]} // Specify custom ticks
              // domain={["auto", "auto"]}
              label={{
                value: "機率(%)",
                position: "insideBottom",
                offset: -10,
                fill: "#000", // Label text color
              }}
              stroke="#000" // Tick color
            />
            <YAxis
              type="number"
              dataKey="淹水深度"
              label={{
                value: "水深(cm)",
                angle: -90,
                position: "insideLeft",
                offset: 10,
                fill: "#000", // Label text color
              }}
              stroke="#000" // Tick color
            />
            {/* <Tooltip /> */}
            <Tooltip content={<CustomTooltip />} />

            <Area
              type="monotone"
              dataKey="淹水深度"
              stroke="#8884d8"
              fill="#8884d8"
            />

            {hoverDepth !== null && (
              <ReferenceLine
                y={hoverDepth}
                stroke="darkblue"
                // stroke="red"
                // label="Current Depth"
              />
            )}
            {hoverDepth !== null && (
              <ReferenceArea
                y1={hoverDepth}
                y2={0}
                fill="darkblue"
                // stroke="red"
                fillOpacity={0.3}
              />
            )}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default DepthProbBuilding;
