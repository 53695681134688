// import React from "react";
// import { useLocation } from "react-router-dom";

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

// const ResultPage = () => {
//   const query = useQuery();
//   const status = query.get("status");
//   const message = query.get("message");
//   const transactionId = query.get("transaction_id");

//   return (
//     <div>
//       <h1>{status === "success" ? "付款成功" : "付款失敗"}</h1>
//       {/* <h1>{status === 'success' ? 'Payment Success' : 'Payment Failure'}</h1> */}
//       {/* <p>{message}</p> */}
//       {transactionId && <p>交易編號: {transactionId}</p>}
//     </div>
//   );
// };

// export default ResultPage;

import React from "react";
import { useLocation } from "react-router-dom";
import { Container, Typography, Paper } from "@mui/material";
import "./ResultPage.scss"; // Importing SCSS for additional styling

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResultPage = () => {
  const query = useQuery();
  const status = query.get("status");
  const message = query.get("message");
  const transactionId = query.get("transaction_id");

  return (
    <Container
      component="main"
      maxWidth="sm"
      className="result-container"
      sx={{ marginTop: "80px" }}
    >
      <Paper elevation={3} className="result-paper">
        <Typography variant="h4" component="h1" className="result-header">
          {status === "success" ? "付款成功" : "付款失敗"}
        </Typography>
        {transactionId && (
          <Typography variant="body1" className="result-info">
            交易編號: {transactionId}
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default ResultPage;
