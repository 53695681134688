import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { persistor, store } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// import "./styles.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={"loading"} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>

  // <React.StrictMode>
  //   <Provider store={store}>
  //     <PersistGate loading={"loading"} persistor={persistor}>
  //       <App />
  //     </PersistGate>
  //   </Provider>
  // </React.StrictMode>
);
