import axiosInstance from "../../axios";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
// import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useSelector } from "react-redux";
import { removeItem } from "../../redux/cartReducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { resetCart } from "../../redux/cartReducer";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import "./CartPage.scss";
import DashboardMap from "../Map/DashboardMapRisk";

const CartPage = () => {
  const [cartItem, setCartItem] = useState([]);
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const products = useSelector((state) => state.cart.products);
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedItems(cartItem.map((item) => item.id)); // Select all items
    } else {
      setSelectedItems([]); // Deselect all items
    }
  };

  const handleSelectItem = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedItems, itemId];
      }
    });
  };

  const deleteProduct = async (productId) => {
    try {
      await axiosInstance.delete(`risk/products/${productId}/`);
      // await axiosInstance.delete(`shop/products/${productId}/`);
      setCartItem(cartItem.filter((item) => item.pkid !== productId));
      // dispatch(removeItem(productId));
      dispatch(removeItem([productId]));
    } catch (err) {
      console.log(err);
    }
  };

  // console.log(cartItem);
  const confirmPayment = async () => {
    try {
      const selectedProductIds = cartItem
        .filter((item) => selectedItems.includes(item.id))
        .map((item) => item.pkid); // Assuming 'pkid' is the identifier

      const response = await axiosInstance.post(
        `shop/membership/use_points_to_purchase_product/`,
        { selectedProductIds } // Send selected product IDs in the request
      );
      // console.log(response);

      if (response.data.success) {
        setMessages([response.data.success]);
        dispatch(removeItem(selectedProductIds));

        toast.success(response.data.success);

        // dispatch(resetCart());
        navigate("/dashboard");
      }
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        setMessages([error.response.data.error]);
        toast.error("付款失敗: " + error.response.data.error);
      } else {
        console.error("Error posting request:", error);
      }
    }
    setOpenDialog(false);
  };

  const handlePointPay = async () => {
    if (selectedItems.length === 0) {
      toast.error("請選擇至少一項商品進行結帳");
      return;
    }
    setOpenDialog(true);
  };

  const calculateTotalPoints = () => {
    return cartItem
      .filter((item) => selectedItems.includes(item.id))
      .reduce((total, item) => total + item.total_price, 0);
  };

  const handleEdit = async (productId) => {
    try {
      //   await axiosInstance.patch(`shop/products/${productId}/`);
      // 先暫時刪掉重新做
      await axiosInstance.delete(`risk/products/${productId}/`);
      setCartItem(cartItem.filter((item) => item.pkid !== productId));

      // dispatch(removeItem(productId));
      dispatch(removeItem([productId]));

      console.log("edit", productId);
      // navigate("/ChooseMap");
      navigate("/Choosetype");
      //   <Link to="/buy">View Details</Link>
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getCartItem = async () => {
      try {
        const res = await axiosInstance.get("shop/carts/");
        setCartItem(res.data[0].products);
        // console.log(res.data[0].products);
      } catch (err) {
        // console.log(err);
      }
    };
    getCartItem();
  }, []);

  let totalPrice = 0;
  products.forEach((product) => {
    // cartItem.forEach((product) => {
    totalPrice += product.price;
  });

  //   console.log(totalPrice);

  return (
    <Container maxWidth="md" className="cart-container">
      <Typography
        variant="h4"
        component="h1"
        align="center"
        gutterBottom
        style={{ fontWeight: "bold" }}
      >
        您的購物車
      </Typography>
      {cartItem.length === 0 ? (
        <>
          <Typography variant="subtitle1" align="center" gutterBottom>
            您的購物車目前是空的。
            <Link
              to="/search_result"
              style={{ color: "black", fontWeight: "bold" }}
            >
              立即前往選購!
            </Link>
          </Typography>
        </>
      ) : (
        <>
          <Checkbox
            checked={selectAll}
            onChange={handleSelectAll}
            color="primary"
          />
          <Typography variant="subtitle1" component="span">
            全選
          </Typography>

          <Grid container spacing={3}>
            {cartItem.map((item) => (
              <Grid item key={item.id} xs={12}>
                <Card style={{ minHeight: "10vh" }}>
                  <Grid container style={{ minHeight: "10vh" }}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        minHeight: "10vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="product-grid-map">
                        <div>
                          <Checkbox
                            checked={selectedItems.includes(item.id)}
                            onChange={() => handleSelectItem(item.id)}
                            size="small"
                          />
                        </div>
                        {/* <FormControlLabel
                          control={<Checkbox
                            checked={selectedItems.includes(item.id)}
                            onChange={() => handleSelectItem(item.id)}
                            size="small"
                          />}
                          label="選擇"
                        /> */}

                        {/* don't show wms */}
                        <DashboardMap product={item} />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CardContent
                        className="card-content"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <div style={{ flex: 1 }}>
                          <Typography
                            variant="h6"
                            component="h6"
                            noWrap
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            名稱: {item.name}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            component="code"
                            noWrap
                          >
                            pkid: {item.pkid}
                          </Typography>
                          <br />
                          <Typography
                            variant="subtitle2"
                            component="code"
                            noWrap
                          >
                            id: {item.id}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color="span"
                            noWrap
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            會員點數: {item.total_price.toFixed(0)}
                          </Typography>
                          <div>
                            <button
                              className="cart-item-edit"
                              onClick={() => handleEdit(item.pkid)}
                            >
                              {/* 修改 */}
                              修改
                            </button>
                            <button
                              className="cart-item-delete"
                              onClick={() => deleteProduct(item.pkid)}
                            >
                              刪除
                            </button>
                          </div>
                        </div>
                      </CardContent>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            className="checkout-container"
          >
            <Grid item>
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                style={{ color: "black", fontWeight: "bold" }}
              >
                需求會員點數: {totalPrice.toFixed(0)}
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                style={{ color: "black", fontWeight: "bold" }}
              >
                總數: {cartItem.length}
              </Typography>
              <Button
                onClick={handlePointPay}
                variant="contained"
                color="primary"
                className="checkout-button"
                style={{ fontWeight: "bold" }}
              >
                使用點數結帳 (選擇項目: {selectedItems.length}, 總點數:{" "}
                {calculateTotalPoints().toFixed(0)})
              </Button>

              <p>{messages}</p>
            </Grid>
          </Grid>
        </>
      )}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>確認付款</DialogTitle>
        <DialogContent>
          <Typography component="div">
            您將要支付以下產品:
            <ul>
              {cartItem
                .filter((item) => selectedItems.includes(item.id))
                .map((item) => (
                  <li key={item.id}>
                    {item.name} (ID: {item.id}, 會員點數:{" "}
                    {item.total_price.toFixed(0)})
                  </li>
                ))}
            </ul>
            <br />
            總需會員點數: {calculateTotalPoints().toFixed(0)}
            <br />
            確定要進行付款嗎?
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            取消
          </Button>
          <Button onClick={confirmPayment} color="primary">
            確認付款
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CartPage;
