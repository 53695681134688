import React from "react";
import Questionnaire from "../RiskMapAll/InclinedBuildings";
import TrialReport from "../../pages/RiskReport/TrialReport";
import GoldenCircle from "../GoldenCircle/GoldenCircle";
import ProductCompare from "../Table/ProductCompare";

const Test = () => {
  return (
    <div style={{ marginTop: "80px" }}>
      {/* <TrialReport /> */}
      <ProductCompare />
    </div>
  );
};

export default Test;
