import React from "react";
import { motion, useInView } from "framer-motion";
import "./LossInfo.scss";

const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible,
};

const LossInfo = () => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { triggerOnce: true, threshold: 0.2 });

  return (
    <motion.div
      ref={ref}
      className="loss-info-container"
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      exit={{ opacity: 0, transition: { duration: 1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
    >
      <motion.div className="loss-info-content">
        <motion.h2 variants={itemVariants}>
          我們深信房屋是您一生中最重要的資產，它關係到您和家人的安全與未來，如何保護它至關重要的。
        </motion.h2>
        <motion.h2 variants={itemVariants}>
          我們透過先進的科學分析方法，來量化災害風險和提供專業的避險建議，幫助您做出明智的決策來保護資產。
        </motion.h2>
        <motion.h2 variants={itemVariants}>
          安家百科提供全面的風險評估工具、專家指南以及個性化的避險方案，讓您輕鬆掌握住房安全的關鍵。
        </motion.h2>
        {/* <motion.h2 variants={itemVariants}>
          您知道天災造成您每年多少資產損失嗎？
        </motion.h2>
        <motion.p variants={itemVariants}>
          台灣易受地震和氣候變遷等災害衝擊
        </motion.p>
        <motion.p variants={itemVariants}>
          政府的工程與法規已大大減少天災造成的損失
        </motion.p>
        <motion.p variants={itemVariants}>但仍需要對個人資產進行保護</motion.p>
        <motion.h2 variants={itemVariants}>
          不知道預期損失多少保險費讓人隨便開價？
        </motion.h2>
        <motion.p variants={itemVariants}>
          2023年美國房屋保險費率平均上漲21%
        </motion.p>
        <motion.p variants={itemVariants}>
          氣候變遷、物價造成保險費率逐年上升
        </motion.p>
        <motion.p variants={itemVariants}>
          知道天災的位置、規模大小、機率，才有辦法避險與轉移風險
        </motion.p>
        <motion.p variants={itemVariants}>
          量化災害損失，才有辦法聰明得購買保險
        </motion.p> */}
      </motion.div>
      <motion.div className="loss-info-graphics" variants={itemVariants}>
        <motion.div className="loss-info-line" variants={itemVariants}>
          <span className="line-label left">過去</span>
          <div className="line-content">
            <div className="line">
              <span className="line-text top">天然災害造成的損失</span>
            </div>
          </div>
        </motion.div>

        <motion.div className="loss-info-line" variants={itemVariants}>
          <span className="line-label left">現況</span>
          <div className="line-content">
            <div className="line2">
              <span className="line-text bottom">剩餘的災害損失？</span>
              <span className="line-text middle">政府法規、工程措施</span>
            </div>
          </div>
        </motion.div>

        <motion.div className="loss-info-venn" variants={itemVariants}>
          <div className="venn-circle left">
            <span>個人、社區工程措施</span>
          </div>
          <div className="venn-circle right">
            <span>保險</span>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default LossInfo;
