import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./RiskMapClip.scss";
import { Grid } from "@mui/material";
import axios from "axios";
import * as turf from "@turf/turf";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
// const gs_username = process.env.REACT_APP_GEOSERVER_BASIC_USERNAME;
// const gs_pass = process.env.REACT_APP_GEOSERVER_BASIC_PASSWORD;
const baseURL = process.env.REACT_APP_API_URL;
mapboxgl.accessToken = mapboxtoken;

const RiskMapClipFree = ({ product }) => {
  const polyGon = product.calculated_geometry;

  const [mapInstances, setMapInstances] = useState({});
  const [legendUrls, setLegendUrls] = useState({});

  const fetchLegendImage = async () => {
    layers.forEach(async (layer) => {
      const url = getLegendUrl(layer.id);
      try {
        const response = await axios.get(url, {
          responseType: "blob",
          // auth: {
          //   username: gs_username,
          //   password: gs_pass,
          // },
        });
        const imageUrl = URL.createObjectURL(response.data);
        setLegendUrls((prev) => ({ ...prev, [layer.id]: imageUrl }));
      } catch (error) {
        console.error(`Error fetching legend image for ${layer.id}:`, error);
      }
    });
  };

  useEffect(() => {
    fetchLegendImage();
  }, []);

  const getLegendUrl = (layerName) => {
    // return `${baseURL}risk/geo/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layerName}`;
    return `${baseURL}risk/geo/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layerName}&legend_options=fontColor:0xFFFFFF;bgColor:0x1B1B1E;dpi:300`;
    // return `${geoserver_url}geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layerName}`;
  };

  const layers = [
    {
      id: "opendata6h350mm40m3857",
      title: "淹水",
      wms:
        // geoserver_url +
        // `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:opendata6h350mm40m3857&FORMAT=image/png&TILED=true`,
        `${baseURL}risk/geo/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:opendata6h350mm40m3857&FORMAT=image/png&TILED=true`,
      legend: getLegendUrl("staging:opendata6h350mm40m3857"),
      zoomLevel: 17,
      description: (
        <div>
          <div className="map-title" style={{ textAlign: "center" }}>
            <strong>淹水風險說明</strong>
          </div>
          <p>
            您的家每年<span className="blurred-text">??</span>%機率會淹水
          </p>
          <p>
            每年因淹水的平均損失<span className="blurred-text">???</span>元/年
          </p>
          <p>
            您知道您的家一樓的樓地板只要比隔壁高1公分，也許你家不會淹水，但是鄰居卻淹水了？
          </p>
          <p>您知道350mm/6hr降雨的意義？</p>
          <p>
            安家百科給你最準確的淹水圖資外，並直接告訴你：房屋淹水的機率、汽車淹水的機率、淹水10公分、20公分、…、100公分的發生機率，並告訴你每年的平均損失外，並告訴你如何避險與如何轉移淹水風險。
          </p>
          <hr />
        </div>
      ),
    },
    {
      id: "tw_liq_all40m3857",
      title: "土壤液化",
      wms:
        // geoserver_url +
        `${baseURL}risk/geo/wms??SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:tw_liq_all40m3857&FORMAT=image/png&TILED=true`,
      legend: getLegendUrl("staging:tw_liq_all40m3857"),
      zoomLevel: 17,
      description: (
        <div>
          <div className="map-title" style={{ textAlign: "center" }}>
            <strong>土壤液化風險說明</strong>
          </div>
          <p>
            您的家受到土壤液化的影響每年平均損失{" "}
            <span className="blurred-text">?????</span>元/年
            您的家為在土壤液化的高潛勢區域，難道就有液化風險了嗎？
            土壤液化造成土壤不均勻沈陷，可能造成建築物傾斜，影響安全性與價格。
          </p>
          <p>
            安家百科告訴您，土壤液化可能會有多少損失外，告訴你發生的機率、每年的平均損失外，並告訴你怎麼減少土壤液化的風險與災後的補救措施。
          </p>
          <hr />
        </div>
      ),
    },

    // {
    //   id: "tsunami_risk3857",
    //   title: "氣候變遷",
    //   wms:
    //     // geoserver_url +
    //     `${baseURL}risk/geo/wms??SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:tsunami_risk3857&FORMAT=image/png&TILED=true`,
    //   legend: getLegendUrl("staging:tsunami_risk3857"),
    //   zoomLevel: 17,
    //   description: (
    //     <div>
    //       <div className="map-title" style={{ textAlign: "center" }}>
    //         <strong>說明</strong>
    //       </div>
    //       <p>
    //         您知道氣候變遷會造成海平面上升以及降雨強度增加嗎？
    //         這兩者都會讓您的房屋更容易有淹水的風險 會淹水的地方會更嚴重
    //         不會淹水的地方也許也會淹水
    //       </p>
    //       <p>
    //         安家百科告訴您氣候變遷下，您的家是否會更容易淹水？以及防範措施。
    //       </p>
    //     </div>
    //   ),
    // },
    {
      id: "2010",
      title: "活動斷層",
      wms:
        // geoserver_url +
        `${baseURL}risk/geo/wms??SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:2010&FORMAT=image/png&TILED=true`,
      legend: getLegendUrl("staging:2010"),
      zoomLevel: 9,
      description: (
        <div>
          <div className="map-title" style={{ textAlign: "center" }}>
            <strong>活動斷層風險說明</strong>
          </div>
          <p>
            你家離活動斷層多遠？你家離地質敏感區多遠？{" "}
            <span className="blurred-text">??</span>公里
          </p>
          <p>
            離開這斷層與敏感區，你家就不會有地震損失了嗎？{" "}
            <span className="blurred-text">??</span>公里
          </p>
          <p>
            每次地震都會讓您的家有或大或小的損失，您知道有多少嗎？{" "}
            <span className="blurred-text">????</span>元
          </p>
          <p>您知道基本地震險只有在你家全倒/半倒時才有理賠</p>
          <p>
            安家百科幫您計算各種震度的機率、損失比例、損失金額，並告訴你每年平均損失多少。地震沒辦法避免，因此地震對房屋產生的裂痕也無法避免，我們會告訴您怎麼利用保險來轉移地震損失，聰明的投保才可以讓您安心住、省錢繳。
          </p>
          <hr />
        </div>
      ),
    },

    // {
    //   id: "debris",
    //   title: "土石流區域",
    //   wms:
    //     geoserver_url +
    //     `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:debris&FORMAT=image/png&TILED=true`,
    //   legend: getLegendUrl("staging:debris"),
    //   description: (
    //     <div>
    //       <div className="map-title" style={{ textAlign: "center" }}>
    //         <strong>說明</strong>
    //       </div>
    //       <p>
    //         您知道你家一樓的樓地板只要比隔壁高1公分，也許你家不會淹水，但是鄰居卻淹水了？
    //       </p>
    //       <p>您知道350mm/6hr降雨的意義？</p>
    //       <p>
    //         安家百科給你最準確的淹水圖資外，並直接告訴你，淹水10公分、20公分、…、100公分的發生機率，並告訴你每年的平均損失外，並告訴你如何避險與如何轉移淹水風險。
    //       </p>
    //     </div>
    //   ),
    // },
    // {
    //   id: "debrisstream",
    //   title: "土石流潛勢溪",
    //   wms:
    //     geoserver_url +
    //     `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:debrisstream&FORMAT=image/png&TILED=true`,
    //   legend: getLegendUrl("staging:debrisstream"),
    //   description: (
    //     <div>
    //       <div className="map-title" style={{ textAlign: "center" }}>
    //         <strong>說明</strong>
    //       </div>
    //       <p>
    //         您知道你家一樓的樓地板只要比隔壁高1公分，也許你家不會淹水，但是鄰居卻淹水了？
    //       </p>
    //       <p>您知道350mm/6hr降雨的意義？</p>
    //       <p>
    //         安家百科給你最準確的淹水圖資外，並直接告訴你，淹水10公分、20公分、…、100公分的發生機率，並告訴你每年的平均損失外，並告訴你如何避險與如何轉移淹水風險。
    //       </p>
    //     </div>
    //   ),
    // },
    // {
    //   id: "largelandslide3857",
    //   title: "大規模崩塌區",
    //   wms:
    //     geoserver_url +
    //     `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:largelandslide3857&FORMAT=image/png&TILED=true`,
    //   legend: getLegendUrl("staging:largelandslide3857"),
    //   description: (
    //     <div>
    //       <div className="map-title" style={{ textAlign: "center" }}>
    //         <strong>說明</strong>
    //       </div>
    //       <p>
    //         您知道你家一樓的樓地板只要比隔壁高1公分，也許你家不會淹水，但是鄰居卻淹水了？
    //       </p>
    //       <p>您知道350mm/6hr降雨的意義？</p>
    //       <p>
    //         安家百科給你最準確的淹水圖資外，並直接告訴你，淹水10公分、20公分、…、100公分的發生機率，並告訴你每年的平均損失外，並告訴你如何避險與如何轉移淹水風險。
    //       </p>
    //     </div>
    //   ),
    // },
  ];

  const layers2 = [
    {
      id: "tsunami_risk3857",
      title: "氣候變遷",
      wms:
        // geoserver_url +
        `${baseURL}risk/geo/wms??SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:tsunami_risk3857&FORMAT=image/png&TILED=true`,
      legend: getLegendUrl("staging:tsunami_risk3857"),
      zoomLevel: 17,
      description: (
        <div>
          <div className="map-title" style={{ textAlign: "center" }}>
            <strong>氣候變遷風險說明</strong>
          </div>
          <p>
            您知道氣候變遷會造成海平面上升以及降雨強度增加嗎？
            這兩者都會讓您的房屋更容易有淹水的風險 會淹水的地方會更嚴重
            不會淹水的地方也許也會淹水
          </p>
          <p>
            安家百科告訴您氣候變遷下，您的家是否會更容易淹水？以及防範措施。
          </p>
          <hr />
        </div>
      ),
    },
  ];

  const featureCollection = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: polyGon,
      },
    ],
  };

  const calculateBounds = (geometry) => {
    const bounds = new mapboxgl.LngLatBounds();
    geometry.coordinates[0].forEach((coord) => bounds.extend(coord));
    return bounds;
  };

  const mapContainers = useRef(
    layers.reduce((acc, layer) => {
      acc[layer.id] = React.createRef();
      return acc;
    }, {})
  ).current;

  const mapContainers2 = useRef(
    layers2.reduce((acc, layer) => {
      acc[layer.id] = React.createRef();
      return acc;
    }, {})
  ).current;

  const setupMap = (mapContainerRef, wmsLayer) => {
    let center;
    try {
      center = turf.center(product.calculated_geometry).geometry.coordinates;
    } catch (error) {
      console.error("Error calculating center:", error);
      return;
    }

    if (!center || center.length !== 2 || center.some(isNaN)) {
      console.error("Invalid center coordinates:", center);
      return;
    }

    // Use the custom zoom level from the layer configuration, default to 14 if not provided
    const zoom = wmsLayer.zoomLevel || 14;

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/light-v10",
      zoom: zoom,
      center: center,
      dragPan: false,
      scrollZoom: false,
      touchZoomRotate: false,
      // transformRequest: (url, resourceType) => {
      //   if (
      //     (resourceType === "Source" || resourceType === "Tile") &&
      //     url.indexOf(geoserver_url + `geoserver/staging/wms`) > -1
      //   ) {
      //     const username = gs_username;
      //     const password = gs_pass;
      //     const credentials = btoa(`${username}:${password}`);
      //     const headers = {
      //       Authorization: `Basic ${credentials}`,
      //     };

      //     return {
      //       url: url,
      //       headers: headers,
      //       credentials: "include",
      //     };
      //   }
      // },
    });

    map.addControl(new mapboxgl.NavigationControl(), "top-left");
    map.addControl(
      new mapboxgl.FullscreenControl({
        container: mapContainerRef.current.parentElement, // Pass the parent container to keep the legend
      }),
      "top-left"
    );

    map.on("load", () => {
      map.addSource(wmsLayer.id, {
        type: "raster",
        tiles: [wmsLayer.wms],
        tileSize: 256,
      });

      map.addLayer({
        id: wmsLayer.id,
        type: "raster",
        source: wmsLayer.id,
        paint: {
          "raster-opacity": 0.7,
        },
      });

      if (polyGon && polyGon.coordinates) {
        if (!map.getSource("polygon")) {
          map.addSource("polygon", {
            type: "geojson",
            data: featureCollection,
          });
        }

        if (!map.getLayer("polygon")) {
          map.addLayer({
            id: "polygon",
            type: "line",
            source: "polygon",
            layout: {},
            paint: {
              "line-color": "#000",
              "line-width": 2,
              "line-dasharray": [2, 2],
            },
          });
        }

        const bounds = calculateBounds(polyGon);

        // Add the marker at the center of the geometry
        new mapboxgl.Marker({ color: "#4C839B" }) // Customize marker color if needed
          .setLngLat(center) // Use the center coordinates calculated by Turf.js
          .addTo(map); // Add the marker to the map
        // map.fitBounds(bounds, { padding: 50 });
      }
    });

    mapContainerRef.current.mapInstance = map;
  };

  useEffect(() => {
    layers.forEach((layer) => {
      setupMap(mapContainers[layer.id], layer);
    });

    return () => {
      Object.keys(mapContainers).forEach((key) => {
        const mapContainerRef = mapContainers[key];
        if (
          mapContainerRef &&
          mapContainerRef.current &&
          mapContainerRef.current.mapInstance
        ) {
          mapContainerRef.current.mapInstance.remove();
        }
      });
    };
  }, []);

  useEffect(() => {
    layers2.forEach((layer) => {
      setupMap(mapContainers2[layer.id], layer);
    });

    return () => {
      Object.keys(mapContainers2).forEach((key) => {
        const mapContainerRef = mapContainers2[key];
        if (
          mapContainerRef &&
          mapContainerRef.current &&
          mapContainerRef.current.mapInstance
        ) {
          mapContainerRef.current.mapInstance.remove();
        }
      });
    };
  }, []);

  return (
    // <div className="riskmap-container" style={{ height: "115vh" }}>
    <div className="riskmap-container" style={{ height: "auto" }}>
      <Grid container spacing={2}>
        {layers.map((layer) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            key={layer.id}
            sx={{ marginBottom: "10px" }}
          >
            <Grid container spacing={2}>
              {/* Map on the left on large screens, full-width on small screens */}
              <Grid item xs={12} md={6}>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <div
                    className="map-tile"
                    style={{
                      position: "relative",
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="map-title" style={{ textAlign: "center" }}>
                      <strong>{layer.title}</strong>
                    </div>
                    <div
                      className="map-tile"
                      style={{
                        position: "relative",
                        height: "35vh",
                        flexGrow: 1,
                        borderRadius: "4px",
                        border: "2px solid",
                      }}
                    >
                      <div
                        ref={mapContainers[layer.id]}
                        className="map-container"
                        style={{
                          height: "100%",
                        }}
                      />
                      <div
                        className="legend-container"
                        style={{
                          position: "absolute",
                          bottom: "10px",
                          right: "10px",
                          border: "2px solid",
                          borderRadius: "4px",
                          zIndex: 5,
                          backgroundColor: "#1B1B1E",
                          // backgroundColor: "white",
                          padding: "5px",
                        }}
                      >
                        {legendUrls[layer.id] && (
                          <img
                            src={legendUrls[layer.id]}
                            alt={`Legend for ${layer.title}`}
                            style={{ maxWidth: "60px" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ flex: 1 }}>{layer.description}</div>
              </Grid>
            </Grid>
          </Grid>
        ))}

        {layers2.map((layer) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            key={layer.id}
            sx={{ marginBottom: "10px" }}
          >
            <Grid container spacing={2}>
              {/* Map on the left on large screens, full-width on small screens */}
              <Grid item xs={12} md={6}>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <div
                    className="map-tile"
                    style={{
                      position: "relative",
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="map-title" style={{ textAlign: "center" }}>
                      <strong>{layer.title}</strong>
                    </div>
                    <div
                      className="map-tile"
                      style={{
                        position: "relative",
                        height: "35vh",
                        flexGrow: 1,
                        borderRadius: "4px",
                        border: "2px solid",
                      }}
                    >
                      <div
                        ref={mapContainers2[layer.id]}
                        className="map-container"
                        style={{
                          height: "100%",
                        }}
                      />
                      {/* Red "?" icon */}
                      <div
                        className="info-icon"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)", // Center the icon
                          backgroundColor: "rgba(255, 0, 0, 0.7)", // Semi-transparent red
                          color: "white",
                          borderRadius: "50%",
                          width: "150px", // Adjust the size as needed
                          height: "150px", // Adjust the size as needed
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "60px", // Adjust the font size as needed
                          fontWeight: "bold",
                          cursor: "pointer",
                          zIndex: 6,
                        }}
                        // title="Click for more info"
                      >
                        ?
                      </div>
                      {/* <div
                        className="legend-container"
                        style={{
                          position: "absolute",
                          bottom: "10px",
                          right: "10px",
                          border: "2px solid",
                          borderRadius: "4px",
                          zIndex: 5,
                          backgroundColor: "#1B1B1E",
                          // backgroundColor: "white",
                          padding: "5px",
                        }}
                      >
                        {legendUrls[layer.id] && (
                          <img
                            src={legendUrls[layer.id]}
                            alt={`Legend for ${layer.title}`}
                            style={{ maxWidth: "60px" }}
                          />
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ flex: 1 }}>{layer.description}</div>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default RiskMapClipFree;
