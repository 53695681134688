import React from "react";
import {
  Tabs,
  Tab,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const FloodSolution = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState("panel1");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        探索個人化的解決方案:
      </Typography>
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        結構選擇可以幫助房屋防洪，減輕物理損壞，或使房屋更具吸水性，以減少逕流並防止洪水。
      </Typography>
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        公寓大樓、一般透天厝：建議採用檔水閘版。請與我們合作廠商聯繫取得優惠價格。(優惠碼)
      </Typography>
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        大型庭院之住家：所需之房淹水工程規模較大，可以與我們合作廠商聯繫。
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="flood info tabs"
            centered={!isMobile}
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : false}
          >
            <Tab label="強降雨造成淹水" sx={{ fontWeight: "bold" }} />
            <Tab label="單一區域持續積水" sx={{ fontWeight: "bold" }} />
            <Tab label="持續輕微浸水" sx={{ fontWeight: "bold" }} />
            <Tab label="持續大量浸水" sx={{ fontWeight: "bold" }} />
          </Tabs>
        </Box>
        {tabValue === 0 && (
          <Box p={3} sx={{ padding: "0px" }}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>強降雨造成淹水</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  當雨水積聚得太快而地面無法吸收或使地面過度飽和時，雨水就會開始在海拔較低的地區聚集。這可能會導致地下室發生洪水或您家周圍區域積水。
                </Typography>
              </AccordionDetails>
            </Accordion>
            {/* Repeat for other items as needed */}
          </Box>
        )}
        {tabValue === 1 && (
          <Box p={3} sx={{ padding: "0px" }}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>單一區域常常積水:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  如果您的地下室、入口通道或窗戶周圍的同一區域在下雨時繼續積水，則可能表示您需要在該區域進行一些額外的防水處理。
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
        {tabValue === 2 && (
          <Box p={3} sx={{ padding: "0px" }}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>持續輕微浸水:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  漲潮或持續溢出的水體造成的定期小規模洪水可能會對房屋造成結構性損壞。
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
        {tabValue === 3 && (
          <Box p={3} sx={{ padding: "0px" }}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>持續大量浸水:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  如果您的家多次受到大風暴的嚴重影響，這方面的風險可能是不可避免的。
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default FloodSolution;
