import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./RiskMapClip.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as turf from "@turf/turf";
import axios from "axios";
import { stringify } from "wkt";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const gs_username = process.env.REACT_APP_GEOSERVER_BASIC_USERNAME;
const gs_pass = process.env.REACT_APP_GEOSERVER_BASIC_PASSWORD;
mapboxgl.accessToken = mapboxtoken;

const RiskMapAll = ({ report }) => {
  const { user } = useSelector((state) => state.auth);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const layer_names =
    report?.return_period_layer_names["return_period_layer_names"];
  const [activeLayer, setActiveLayer] = useState(layer_names[3]);

  const [bboxPolygon, setBboxPolygon] = useState(report?.product?.bbox_b100);
  const [wkt3857, setWkt3857] = useState(
    stringify(report?.product?.bbox_b100_3857)
  ); // Initialize to an empty string

  const [layerOpacity, setLayerOpacity] = useState(1); // Default to fully opaque
  const polyGon = report.product.calculated_geometry;
  const [legendUrl, setLegendUrl] = useState("");

  // console.log("layer_names", layer_names);
  // console.log("report", report);

  // Function to fetch the legend image with authentication
  const fetchLegendImage = async (layerName) => {
    const url = `${geoserver_url}geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layerName}`;
    try {
      const response = await axios.get(url, {
        responseType: "blob", // Important: handle the response as a Blob
        auth: {
          username: gs_username,
          password: gs_pass,
        },
      });

      // Create a local URL for the image Blob
      const imageUrl = URL.createObjectURL(response.data);
      setLegendUrl(imageUrl); // Store the Blob URL in the state
    } catch (error) {
      console.error("Error fetching legend image:", error);
      // Handle error, e.g., show a notification
    }
  };

  useEffect(() => {
    // Call this function when you need to load a legend, e.g., when a layer is toggled
    if (activeLayer) {
      fetchLegendImage(activeLayer);
    }
  }, [activeLayer]);
  // Function to get the legend URL for a given layer
  const getLegendUrl = (layerName) => {
    return `${geoserver_url}geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layerName}`;
  };

  const layers = [
    {
      id: layer_names[3],
      // id: "sealevel15_merge_bilinear10m",
      title: "海平面高1.5m",
      wms:
        geoserver_url +
        `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:${layer_names[3]}&FORMAT=image/png&TILED=true&clip=${wkt3857}`,
    },
    {
      // id: "sealevel22_merge_bilinear10m",
      id: layer_names[4],
      title: "海平面高2.2m",
      wms:
        geoserver_url +
        `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:${layer_names[4]}&FORMAT=image/png&TILED=true&clip=${wkt3857}`,
    },
    {
      // id: "sealevel40_merge_bilinear10m",
      id: layer_names[5],
      title: "海平面高4.0m",
      wms:
        geoserver_url +
        `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:${layer_names[5]}&FORMAT=image/png&TILED=true&clip=${wkt3857}`,
    },
    {
      // id: "sealevel47_merge_bilinear10m",
      id: layer_names[6],
      title: "海平面高4.7m",
      wms:
        geoserver_url +
        `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:${layer_names[6]}&FORMAT=image/png&TILED=true&clip=${wkt3857}`,
    },

    {
      id: "tsunami_risk3857",
      title: "海嘯潛勢區",
      wms:
        geoserver_url +
        `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:tsunami_risk3857&FORMAT=image/png&TILED=true`,
    },
  ];

  // console.log("polyGon", polyGon);

  const featureCollection = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: polyGon,
      },
    ],
  };

  const calculateBounds = (geometry) => {
    const bounds = new mapboxgl.LngLatBounds();
    geometry.coordinates[0].forEach((coord) => bounds.extend(coord));
    return bounds;
  };

  const changeOpacity = (newOpacity) => {
    setLayerOpacity(newOpacity);
    layers.forEach((layer) => {
      if (map.current.getLayer(layer.id)) {
        map.current.setPaintProperty(layer.id, "raster-opacity", newOpacity);
      }
    });
  };

  const switchBaseMap = (style) => {
    if (map.current) {
      map.current.setStyle(`mapbox://styles/mapbox/${style}`);

      // Once the style has loaded, re-add the WMS layers
      map.current.once("style.load", () => {
        addWMSLayers(); // Re-add the WMS layers
        addPolygonLayer(); // Re-add the polygon layer
      });
    }
  };

  const addWMSLayers = () => {
    layers.forEach((layer) => {
      // Only add the source if it doesn't already exist
      if (!map.current.getSource(layer.id)) {
        map.current.addSource(layer.id, {
          type: "raster",
          tiles: [layer.wms],
          tileSize: 256,
        });
      }

      // Only add the layer if it doesn't already exist
      if (!map.current.getLayer(layer.id)) {
        map.current.addLayer({
          id: layer.id,
          type: "raster",
          source: layer.id,
          paint: {
            "raster-opacity": layerOpacity, // use the current opacity
          },
          layout: {
            // Use the ternary operator to set visibility based on the active layer
            visibility: layer.id === activeLayer ? "visible" : "none",
          },
        });
      } else {
        // If the layer already exists, just update its visibility
        map.current.setLayoutProperty(
          layer.id,
          "visibility",
          layer.id === activeLayer ? "visible" : "none"
        );
      }
    });
  };

  const addPolygonLayer = () => {
    if (polyGon && polyGon.coordinates) {
      // Check if polygon source and layer already exist
      if (!map.current.getSource("polygon")) {
        map.current.addSource("polygon", {
          type: "geojson",
          data: featureCollection, // Use featureCollection here
        });
      }

      if (!map.current.getLayer("polygon")) {
        map.current.addLayer({
          id: "polygon",
          type: "line",
          source: "polygon",
          layout: {},
          paint: {
            "line-color": "#000", // black color
            "line-width": 2,
            // "line-dasharray": [2, 2], // dotted line pattern
          },
        });
      }

      map.current.addSource("bboxPolygon", {
        type: "geojson",
        data: bboxPolygon,
      });

      map.current.addLayer({
        id: "bboxPolygon-layer",
        type: "line",
        source: "bboxPolygon",
        paint: {
          "line-color": "black",
          "line-width": 2,
          "line-dasharray": [2, 2],
        },
      });

      // Calculate and fit the map bounds to the polygon
      const bounds = calculateBounds(polyGon); // Use polyGon geometry for bounds

      const bbox = turf.bbox(report?.product?.bbox_b100);
      // const bbox = turf.bbox(report?.product?.bbox_b10);
      // const bbox = turf.bbox(detailOrder?.calculated_geometry);
      const product_bbox = [
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]],
      ];

      // map.current.fitBounds(bounds, { padding: 40 });
      map.current.fitBounds(product_bbox, { padding: 50 });
    }
  };

  useEffect(() => {
    // if (map.current) return; // Initialize map only once
    if (!map.current && mapContainer.current) {
      // for detailorder
      let center;
      try {
        center = turf.center(report.product.calculated_geometry).geometry
          .coordinates;
      } catch (error) {
        console.error("Error calculating center:", error);
        return;
      }

      if (!center || center.length !== 2 || center.some(isNaN)) {
        console.error("Invalid center coordinates:", center);
        return;
      }

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/light-v11",
        // style: "mapbox://styles/wayne32545/cle6fqgc3000601t6g17e3arw",
        // center: [121.31552, 24.99337],
        center: center,
        zoom: 14,
        minZoom: 14,
        transformRequest: (url, resourceType) => {
          if (
            (resourceType === "Source" || resourceType === "Tile") &&
            url.indexOf(geoserver_url + `geoserver/staging/wms`) > -1
          ) {
            const username = gs_username;
            const password = gs_pass;
            const credentials = btoa(`${username}:${password}`);
            const headers = {
              Authorization: `Basic ${credentials}`,
            };

            return {
              url: url,
              headers: headers,
              credentials: "include", // Include cookies for cross-origin requests
            };
          }
        },
      });
      if (map.current) {
        map.current.on("load", () => {
          addWMSLayers(); // Add the WMS layers
          addPolygonLayer(); // Add the polygon layer
        });
      }
    }

    // Cleanup function to run when the component unmounts
    // return () => {
    //   if (map.current) {
    //     map.current.remove();
    //   }
    // };
  }, [layerOpacity, polyGon]);

  const toggleLayer = (layerId) => {
    setActiveLayer(layerId);
    layers.forEach((layer) => {
      map.current.setLayoutProperty(
        layer.id,
        "visibility",
        layer.id === layerId ? "visible" : "none"
      );
    });
    // const layer = layers.find((l) => l.id === layerId);
    // if (layer) {
    //   setLegendUrl(layer.legend);
    // }
  };

  if (!user) {
    // If no user is logged in, display a login message
    return (
      <div className="login-message">
        <p>請先登入</p>
        <Link to="/login">登入</Link>{" "}
        {/* Replace "/login" with your login route */}
      </div>
    );
  }

  return (
    <div className="riskmap-container">
      <div
        className="map-container"
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: "60vh",
          borderRadius: "4px",
        }}
      >
        {/* Map container covering the full width and 60vh height */}
        <div
          ref={mapContainer}
          style={{
            width: "100%",
            height: "60vh",
            padding: "10px",
            borderRadius: "4px",
          }}
        />
        {/* <div className="map-container" ref={mapContainer} /> */}

        <div
          className="legend-container"
          style={{
            position: "absolute",
            bottom: "20px",
            right: "10px",
            border: "2px solid",
            borderRadius: "4px",
          }}
        >
          {legendUrl && <img src={legendUrl} alt="Legend" />}
        </div>
        {/* Base Map Switcher */}
        <div
          id="menu"
          style={{
            position: "absolute",
            top: 10, // Adjust as needed for top spacing
            left: "50%", // Center horizontally
            transform: "translateX(-50%)", // Adjust for exact centering
            background: "#efefef",
            padding: "5px",
            zIndex: 1, // Ensure it's above the map
            border: "solid 1px",
            borderRadius: "4px",
          }}
        >
          <input
            id="satellite-streets-v12"
            type="radio"
            name="rtoggle"
            value="satellite-streets-v12"
            onChange={() => switchBaseMap("satellite-streets-v12")}
          />
          <label
            htmlFor="satellite-streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            衛星
          </label>
          <input
            id="light-v11"
            type="radio"
            name="rtoggle"
            value="light-v11"
            onChange={() => switchBaseMap("light-v11")}
            defaultChecked
          />
          <label
            htmlFor="light-v11"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            白底
          </label>
        </div>
      </div>
      {/* <div className="map-container" ref={mapContainer} /> */}
      <div className="sidebar">
        {layers.map((layer) => (
          <button
            key={layer.id}
            className={`sidebar-button ${
              activeLayer === layer.id ? "active" : ""
            }`}
            onClick={() => toggleLayer(layer.id)}
          >
            {layer.title}
          </button>
        ))}
      </div>
    </div>
  );
};

export default RiskMapAll;
