import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./BuildingMap.scss";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import area from "@turf/area";
// import DepthProbBuilding from "../Prob/DepthProbBuilding";
// import DepthProbTableBuilding from "../Prob/DepthProbTableBuilding";
import * as turf from "@turf/turf";
import { stringify } from "wkt";
import { useDispatch } from "react-redux";
import { setWaterProbData } from "../../redux/waterProbDataReducer";
import { setFaultData } from "../../redux/faultDataReducer";
import { setPolygonData } from "../../redux/polygonDataReducer";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const gs_username = process.env.REACT_APP_GEOSERVER_BASIC_USERNAME;
const gs_pass = process.env.REACT_APP_GEOSERVER_BASIC_PASSWORD;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = mapboxtoken;

const BuildingMap = ({ report }) => {
  const detailOrder = report.product;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pkid, setPkid] = useState(null);
  const mapContainer = useRef(null);
  const mapRef = useRef(null); // New reference for the map instance
  const [isLoading, setIsLoading] = useState(false);
  const [clickedBuildingIds, setClickedBuildingIds] = useState(new Set());
  const [buildingInfo, setBuildingInfo] = useState([]);
  const [updatedBuildingInfo, setUpdatedBuildingInfo] = useState([]);
  // const drawRef = useRef(null);
  const [legendUrl, setLegendUrl] = useState("");
  const [currentStyle, setCurrentStyle] = useState("branch_4_discrete_1_2023"); // Replace 'initialStyleName' with your default style
  const updateStyleAndFetchLegend = (newStyle) => {
    setCurrentStyle(newStyle); // Update the current style state
  };

  const toggleBuildingsVisibility = () => {
    if (!mapRef.current) return; // Exit if map is not initialized

    const visibility = mapRef.current.getLayoutProperty(
      "3d-buildings",
      "visibility"
    );

    // If layer is currently visible, hide it, otherwise show it
    if (visibility === "visible") {
      mapRef.current.setLayoutProperty("3d-buildings", "visibility", "none");
    } else {
      mapRef.current.setLayoutProperty("3d-buildings", "visibility", "visible");
    }
  };

  const [circlePolygon, setCirclePolygon] = useState(
    // detailOrder?.bbox_b10
    detailOrder?.calculated_geometry
  );
  const [bboxPolygon, setBboxPolygon] = useState(detailOrder?.bbox_b10);

  const [wmsLayerName, setWmsLayerName] = useState(detailOrder?.wms_layer_name); // Set to your initial WMS layer name
  const [wkt3857, setWkt3857] = useState(
    // stringify(detailOrder?.calculated_geometry_3857)
    stringify(detailOrder?.bbox_b10_3857)
  ); // Initialize to an empty string

  const switchBaseMap = (style) => {
    if (mapRef.current) {
      mapRef.current.setStyle("mapbox://styles/mapbox/" + style);
      mapRef.current.once("style.load", () => {
        // Re-add the buildings source and layer here
        addCircleToMap();
        addBuildingsLayer();
        addWMSLayers();
      });
    }
  };

  const addBuildingsLayer = () => {
    if (!mapRef.current.getSource("buildings")) {
      // Add source for buildings
      mapRef.current.addSource("buildings", {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] }, // initial empty data
      });
    }

    if (!mapRef.current.getLayer("3d-buildings")) {
      // Add layer for buildings
      mapRef.current.addLayer({
        id: "3d-buildings",
        source: "buildings",
        type: "fill-extrusion",
        layout: {
          visibility: "visible", // Ensure visibility is explicitly set
        },
        paint: {
          "fill-extrusion-color": "#aaa",

          "fill-extrusion-height": ["get", "build_h"],
          "fill-extrusion-base": 0,
          "fill-extrusion-opacity": 0.9,
        },
      });
    }

    // Call your function to fetch and set the data for buildings
    fetchBuildingsInView(mapRef.current);
  };

  useEffect(() => {
    const updatedBuildings = buildingInfo.map((building) => {
      // const geometry = JSON.parse(building.geometry);
      // Check if building.geometry is already an object
      const geometry =
        typeof building.geometry === "string"
          ? JSON.parse(building.geometry)
          : building.geometry;
      const area = turf.area(geometry); // Calculates the area in square meters
      return { ...building, area };
    });
    setUpdatedBuildingInfo(updatedBuildings);
  }, [buildingInfo]); // Dependency array ensures this runs only when buildingInfo changes

  const totalArea = updatedBuildingInfo.reduce(
    (sum, building) => sum + building.area,
    0
  );

  // Function to add the circle to the map
  const addCircleToMap = () => {
    if (mapRef.current.getSource("circle-polygon")) {
      mapRef.current.getSource("circle-polygon").setData(circlePolygon);
      mapRef.current.getSource("bboxPolygon").setData(bboxPolygon);
    } else {
      mapRef.current.addSource("circle-polygon", {
        type: "geojson",
        data: circlePolygon,
      });

      mapRef.current.addLayer({
        id: "circle-polygon-layer",
        type: "line",
        source: "circle-polygon",
        paint: {
          "line-color": "red",
          "line-width": 2,
          // "line-dasharray": [2, 2],
        },
      });

      mapRef.current.addSource("bboxPolygon", {
        type: "geojson",
        data: bboxPolygon,
      });

      mapRef.current.addLayer({
        id: "bboxPolygon-layer",
        type: "line",
        source: "bboxPolygon",
        paint: {
          "line-color": "black",
          "line-width": 2,
          "line-dasharray": [2, 2],
        },
      });
    }
  };

  const addWMSLayers = () => {
    //wms
    mapRef.current.addSource("wms-test-source", {
      type: "raster",
      tiles: [
        geoserver_url +
          // `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=${wmsLayerName}&FORMAT=image/png&TILED=true&clip=${wkt3857}`,
          // `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&styles=earthquake&width=256&height=256&LAYERS=${wmsLayerName}&FORMAT=image/png&TILED=true&clip=${wkt3857}`,
          `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&styles=branch_4_discrete_1_2023&width=256&height=256&LAYERS=${wmsLayerName}&FORMAT=image/png&TILED=true&clip=${wkt3857}`,
      ],
      tileSize: 256,
    });

    mapRef.current.addLayer(
      {
        id: "wms-test-layer",
        type: "raster",
        source: "wms-test-source",
        paint: {},
      },
      "circle-polygon-layer",
      "3d-buildings"
    );

    mapRef.current.setPaintProperty("wms-test-layer", "raster-opacity", 1);
  };

  //get the user
  useEffect(() => {
    const getPkid = async () => {
      try {
        const res = await axiosInstance.get("auth/users/me/");
        setPkid(res.data.pkid);
      } catch (err) {
        // console.error(err);
        toast.warning("您目前尚未登入喔!");
      }
    };
    getPkid();
  }, []);

  useEffect(() => {
    // Only initialize the map if it doesn't already exist
    if (mapRef.current) return; // This prevents multiple initializations

    // for detailorder
    let center;
    try {
      center = turf.center(detailOrder.calculated_geometry).geometry
        .coordinates;
    } catch (error) {
      console.error("Error calculating center:", error);
      return;
    }

    if (!center || center.length !== 2 || center.some(isNaN)) {
      console.error("Invalid center coordinates:", center);
      return;
    }

    // console.log("Center coordinates:", center);

    // Use Turf.js to calculate the center and bbox
    const bbox = turf.bbox(detailOrder?.bbox_b10);
    // const bbox = turf.bbox(detailOrder?.calculated_geometry);
    const product_bbox = [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ];

    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      // style: "mapbox://styles/mapbox/satellite-v9", // style URL
      center: center,
      transformRequest: (url, resourceType) => {
        if (
          (resourceType === "Source" || resourceType === "Tile") &&
          url.indexOf(geoserver_url + `geoserver/staging/wms`) > -1
        ) {
          const username = gs_username;
          const password = gs_pass;
          const credentials = btoa(`${username}:${password}`);
          const headers = {
            Authorization: `Basic ${credentials}`,
          };
          return {
            url: url,
            headers: headers,
            credentials: "include", // Include cookies for cross-origin requests
          };
        }
      },

      zoom: 15.5,
      minZoom: 15.5,
      // maxZoom: 15.5,
      dragPan: false,
    });

    // Fit the map to the bbox extent
    mapRef.current.fitBounds(product_bbox, { padding: 50 });

    if (mapRef.current) {
      mapRef.current.on("load", () => {
        //circle
        addCircleToMap();
        //3d building
        addBuildingsLayer();

        //wms
        addWMSLayers();
      });
    }

    class SwitchStyleControl {
      onAdd(map) {
        this.map = map;
        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
        this.button = document.createElement("button");
        this.button.textContent = "換圖";
        this.button.style.fontWeight = "bold";
        this.button.onclick = () => this.switchWMSStyle();
        this.container.appendChild(this.button);
        return this.container;
      }

      switchWMSStyle() {
        // Example of toggling between two styles
        const source = this.map.getSource("wms-test-source");
        if (!source) return; // Ensure the source exists

        const currentTiles = source.tiles[0];
        const newStyle = currentTiles.includes("branch_4_discrete_1_2023")
          ? "red1"
          : "branch_4_discrete_1_2023";
        const newTiles = currentTiles.replace(
          /styles=branch_4_discrete_1_2023|styles=red1/,
          `styles=${newStyle}`
        );

        // Correct approach: using setTiles to update the tiles URL
        source.setTiles([newTiles]);

        updateStyleAndFetchLegend(newStyle);

        // Optionally, refresh the layer to ensure the new tiles are loaded
        this.map.triggerRepaint();
      }

      onRemove() {
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
      }
    }

    // Usage: Assuming mapRef.current is your map instance
    mapRef.current.addControl(new SwitchStyleControl(), "top-left");

    class ToggleBuildingsControl {
      onAdd(map) {
        this.map = map;
        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
        this.button = document.createElement("button");
        this.button.textContent = "建物"; // You can use an icon or text
        // Set the button's font weight to bold
        this.button.style.fontWeight = "bold";
        // SVG placeholder for the DomainAddIcon
        // const iconSVG = `<svg xmlns="http://www.w3.org/2000/svg" height="12" viewBox="0 0 24 24" width="12"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm1 9h-2V8h2v7zm-1-15C5.92 1 1 5.92 1 12s4.92 11 11 11 11-4.92 11-11S18.08 1 12 1z"/></svg>`;
        // this.button.innerHTML = iconSVG; // Use the SVG for the button

        this.button.onclick = () => {
          let visibility = this.map.getLayoutProperty(
            "3d-buildings",
            "visibility"
          );
          if (visibility === "visible") {
            this.map.setLayoutProperty("3d-buildings", "visibility", "none");
          } else {
            this.map.setLayoutProperty("3d-buildings", "visibility", "visible");
          }
        };
        this.container.appendChild(this.button);
        return this.container;
      }

      onRemove() {
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
      }
    }

    // Add the control to the map
    mapRef.current.addControl(new ToggleBuildingsControl(), "top-left");

    class Toggle3DControl {
      constructor(pitchAngle = 50) {
        this.pitchAngle = pitchAngle; // The pitch angle for the 3D view
      }

      onAdd(map) {
        this.map = map;
        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
        this.button = document.createElement("button");
        this.button.textContent = "3D"; // Initial text
        this.button.onclick = () => {
          // Check the current pitch of the map
          const currentPitch = map.getPitch();
          // Toggle between 0 and the specified pitch angle
          const newPitch = currentPitch === 0 ? this.pitchAngle : 0;
          map.easeTo({ pitch: newPitch });
          // Update the button text based on the new pitch value
          this.button.textContent = newPitch === 0 ? "3D" : "2D";
        };
        this.container.appendChild(this.button);
        return this.container;
      }

      onRemove() {
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
      }
    }

    mapRef.current.addControl(new Toggle3DControl(50), "top-left");

    mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-right");
    mapRef.current.addControl(new mapboxgl.FullscreenControl(), "top-right");

    mapRef.current.on("moveend", () => fetchBuildingsInView(mapRef.current));

    return () => {
      if (mapRef.current) {
        // Remove event listeners first
        mapRef.current.off("load");
        mapRef.current.off("moveend");

        // Then remove the map
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [detailOrder, circlePolygon]);

  const fetchBuildingsInView = (map) => {
    const bounds = map.getBounds();
    setIsLoading(true);

    axios
      .get(`${geoserver_url}geoserver/staging/ows`, {
        params: {
          service: "WFS",
          version: "1.0.0",
          request: "GetFeature",
          typeName: `staging:buildings_all3857_s`,
          bbox: `${bounds.getWest()},${bounds.getSouth()},${bounds.getEast()},${bounds.getNorth()}`,
          outputFormat: "application/json",
        },
        auth: {
          username: gs_username,
          password: gs_pass,
        },
      })
      .then((response) => {
        const data = response.data;
        // Assign uid as id for each feature
        data.features.forEach((feature) => {
          feature.id = feature.properties.uid;
        });
        map.getSource("buildings").setData(data);

        // Reapply 'clicked' state to buildings
        clickedBuildingIds.forEach((id) => {
          map.setFeatureState({ source: "buildings", id }, { clicked: true });
        });

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the GeoServer data:", error);
        setIsLoading(false);
      });
  };

  const calculateTotalArea = (features) => {
    let totalArea = 0;
    features.forEach((feature) => {
      if (
        feature.geometry.type === "Polygon" ||
        feature.geometry.type === "MultiPolygon"
      ) {
        totalArea += area(feature);
      }
    });
    return totalArea;
  };

  useEffect(() => {
    const totalArea = calculateTotalArea(buildingInfo);
    // console.log("Total area: ", totalArea); // You can remove this line later
    // Update the state or display this area in your component
  }, [buildingInfo]);

  useEffect(() => {
    // Function to fetch the legend image with authentication
    const fetchLegendImage = async (legendRequestUrl) => {
      try {
        const response = await axios.get(legendRequestUrl, {
          responseType: "blob", // Important: this tells Axios to handle the response as a Blob
          auth: {
            username: gs_username,
            password: gs_pass,
          },
        });

        // Create a local URL for the image blob
        const imageUrl = URL.createObjectURL(response.data);

        // Set the local URL as the image source
        setLegendUrl(imageUrl);
      } catch (error) {
        console.error("Error fetching legend image:", error);
        toast.error("無法加載圖例。");
      }
    };

    if (detailOrder?.wms_layer_name) {
      // Construct the legend URL using the layer name from detailOrder
      // const legendRequestUrl = `${geoserver_url}geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&LAYER=${detailOrder.wms_layer_name}&styles=${currentStyle}`;
      const legendRequestUrl = `${geoserver_url}geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&LAYER=${detailOrder.wms_layer_name}&STYLE=${currentStyle}`;
      // const legendRequestUrl = `${geoserver_url}geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&LAYER=${detailOrder.wms_layer_name}`;
      // Call the function to fetch the legend image
      fetchLegendImage(legendRequestUrl);
    }
  }, [detailOrder, geoserver_url, gs_username, gs_pass, currentStyle]);

  // console.log(currentStyle);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // height: "150vh",
        height: "60vh",
        // height: "90vh",
        // padding: "10px",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          borderRadius: "4px",
        }}
      >
        {/* Map container covering the full width and 60vh height */}
        <div
          ref={mapContainer}
          style={{
            width: "100%",
            height: "60vh",
            padding: "10px",
            borderRadius: "4px",
          }}
        />
        {/* Add the Toggle Buildings */}
        {/* <div className="map-overlay-controls">
          <button onClick={toggleBuildingsVisibility}>顯示建物</button>
        </div> */}

        {/* Add the legend container */}
        <div
          className="legend-container"
          style={{
            position: "absolute",
            bottom: "20px",
            right: "10px",
            border: "2px solid",
            // padding: "2px",
            borderRadius: "4px",
          }}
        >
          {legendUrl && <img src={legendUrl} alt="Legend" />}
        </div>
        {/* Base Map Switcher */}
        <div
          id="menu"
          style={{
            position: "absolute",
            bottom: 10, // Adjust as needed for top spacing
            left: "50%", // Center horizontally
            transform: "translateX(-50%)", // Adjust for exact centering
            background: "#efefef",
            padding: "5px",
            zIndex: 1, // Ensure it's above the map
            border: "solid 1px",
            borderRadius: "4px",
          }}
        >
          <input
            id="streets-v12"
            type="radio"
            name="rtoggle"
            value="streets-v12"
            onChange={() => switchBaseMap("streets-v12")}
            defaultChecked
          />
          <label
            htmlFor="streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            街道
          </label>
          <input
            id="satellite-streets-v12"
            type="radio"
            name="rtoggle"
            value="satellite-streets-v12"
            onChange={() => switchBaseMap("satellite-streets-v12")}
          />
          <label
            htmlFor="satellite-streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            衛星
          </label>
        </div>
      </div>
    </div>
  );
};

export default BuildingMap;
