import Pagination from "./Pagination";
import React, { useEffect, useState } from "react";
import ProductGrid from "./ProductGrid";
import UserHeader from "./UserHeader";
import "./OrderDashboard.scss";
import axiosInstance from "../../axios";
import ProductMap from "./ProductMap2";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  // Typography,
  // Tooltip,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MapIcon from "@mui/icons-material/Map";

const Dashboard = () => {
  const [order, setOrder] = useState([]);
  const [profile, setProfile] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(4);
  const [viewMode, setViewMode] = useState("card"); // 'card' or 'table'
  const [searchTerm, setSearchTerm] = useState("");
  const [productNumber, setProductNumber] = useState(0);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const navigate = useNavigate();

  // Function to handle view mode change
  const handleChangeViewMode = (mode) => {
    setViewMode(mode);
  };

  // Render component based on viewMode
  const renderViewModeComponent = () => {
    switch (viewMode) {
      case "card":
        return <ProductGrid products={currentProducts} />;
      case "table":
        return <ProductTable products={currentProducts} />;
      case "map":
        return <ProductMap products={currentProducts} />; // Pass necessary props
      default:
        return <ProductGrid products={currentProducts} />;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axiosInstance.get("risk/user-products/");
        // Sort the products based on the 'created_at' field in descending order
        const sortedProducts = res.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setOrder(sortedProducts);
        setProductNumber(sortedProducts.length); // Set the product number here
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();

    const fetchProfile = async () => {
      try {
        const res = await axiosInstance.get("profile/me/");
        setProfile(res.data.profile);
      } catch (err) {
        console.error("Error fetching profile:", err);
        // Show the login/register dialog if fetching profile fails
        setShowLoginDialog(true);
      }
    };
    fetchProfile();
  }, []);

  const handleLogin = () => {
    // Redirect to login page
    navigate("/login");
  };

  const handleRegister = () => {
    // Redirect to register page
    navigate("/register");
  };

  const filteredProducts = searchTerm
    ? order.filter(
        (product) =>
          product.name &&
          product.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : order;

  // Calculate the number of pages based on filteredProducts
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const ProductTable = ({ products }) => {
    return (
      <table style={{ margin: "16px 0px" }}>
        <thead>
          <tr>
            <th>名稱</th>
            <th>編號</th>
            <th>點數</th>
            <th>連結</th>
            {/* Add other headers as needed */}
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id}>
              <td>{product.name}</td>
              <td>{product.pkid}</td>
              <td>{product.points}</td>
              <td>
                <Link to={`riskreport/${product.pkid}`}>看報告</Link>
              </td>
              {/* Add other product details as needed */}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="dashboard-root">
      <div className="dashboard-content">
        <UserHeader profile={profile} productNumber={productNumber} />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {/* Buttons to switch between view modes */}
          <Button
            onClick={() => handleChangeViewMode("card")}
            variant="contained"
            // color="primary"
            sx={{ backgroundColor: "#4C839B" }}
          >
            圖磚
          </Button>
          <Button
            onClick={() => handleChangeViewMode("table")}
            variant="contained"
            // color="primary"
            sx={{ backgroundColor: "#4C839B" }}
          >
            表格
          </Button>
          <Button
            onClick={() => handleChangeViewMode("map")}
            variant="contained"
            // color="primary"
            sx={{ backgroundColor: "#4C839B" }}
          >
            地圖
          </Button>
          {/* <Button
            onClick={() => setViewMode(viewMode === "card" ? "table" : "card")}
            variant="contained"
            color="primary"
            sx={{ color: "white" }}
          >
            {viewMode === "card" ? "表格" : "圖磚"}
          </Button>
          <Button variant="contained" color="primary" sx={{ color: "white" }}>
            <Link style={{ color: "white" }} to={"/ProductMap"}>
              <Tooltip title="地圖總覽">
                <MapIcon sx={{ color: "white" }} />
              </Tooltip>
            </Link>
          </Button> */}

          <input
            type="text"
            placeholder="搜尋產品名稱..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ flex: 1, width: "100px" }}
          />
        </div>

        {/* {viewMode === "card" ? (
          <ProductGrid products={currentProducts} />
        ) : (
          <ProductTable products={currentProducts} />
        )} */}
        {renderViewModeComponent()}

        {/* Conditionally render pagination only if viewMode is not "map" */}
        {viewMode !== "map" && (
          <Pagination
            productsPerPage={productsPerPage}
            totalProducts={filteredProducts.length}
            paginate={paginate}
          />
        )}

        {/* <Pagination
          productsPerPage={productsPerPage}
          totalProducts={filteredProducts.length}
          paginate={paginate}
        /> */}
      </div>

      <Dialog open={showLoginDialog} onClose={() => setShowLoginDialog(false)}>
        <DialogTitle>未登錄</DialogTitle>
        <DialogContent>您需要登錄才能訪問此頁面。</DialogContent>
        <DialogActions>
          <Button onClick={handleLogin} color="primary">
            登入
          </Button>
          <Button onClick={handleRegister} color="secondary">
            註冊
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Dashboard;
