import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close"; // Import the CloseIcon
import "./Navbar22.scss";
import AccountMenu from "./AccountMenu";
import Badge from '@mui/material/Badge';

const NavbarAppbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  // const [productNumber, setProductNumber] = useState(0);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await axiosInstance.get("risk/user-products/");
  //       setProductNumber(res.data.length); // Set the product number here
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const MenuItems = [
    { id: 1, Name: "會員點數", Link: "/BuyPoint" },
    { id: 3, Name: "商品介紹", Link: "/Product_intro" },
    { id: 5, Name: "技術理論", Link: "/Tech" },
    { id: 6, Name: "購買圖資", Link: "/search_result" },
    { id: 7, Name: "我的報告", Link: "/dashboard" },
  ];

  return (
    <nav className="navbar">
      <div className="navbar__container">
        <div className="navbar__logo">
          <Link to="/">
            {/* <img src="/img/logo-right.svg" alt="Logo" /> */}
            <img src="/img/logo-white.svg" alt="Logo" />
          </Link>
        </div>

        <ul className={`navbar__menu ${openMenu ? "active" : ""}`}>
          {MenuItems.map((item) => (
            <li key={item.id} onClick={() => setOpenMenu(false)}>
              <Link to={item.Link}>{item.Name}</Link>
            </li>
          ))}
          {openMenu && (
            <li className="navbar__account-mobile">
              <AccountMenu />
            </li>
          )}
        </ul>

        <div className="navbar__account desktop">
          <AccountMenu />
        </div>

        <div className="navbar__toggle" onClick={() => setOpenMenu(!openMenu)}>
          {openMenu ? <CloseIcon /> : <MenuIcon />}{" "}
          {/* Toggle between MenuIcon and CloseIcon */}
        </div>
      </div>
    </nav>
  );
};

export default NavbarAppbar;
