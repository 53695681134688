import axiosInstance from "../../axios";
import "./RiskMapRadius.scss";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import { point, circle } from "@turf/turf";
// import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
// import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/cartReducer";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import GooglePlacesAutocomplete from "../Map/GooglePlacesAutocomplete";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const gs_username = process.env.REACT_APP_GEOSERVER_BASIC_USERNAME;
const gs_pass = process.env.REACT_APP_GEOSERVER_BASIC_PASSWORD
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = mapboxtoken;

const RiskMapRadius = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const mapContainer = useRef(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [circlePolygon, setCirclePolygon] = useState(null); // Added state for circlePolygon
  const { countyCode } = useParams();
  const dispatch = useDispatch();
  const [pkid, setPkid] = useState(null);
  const [radius, setRadius] = useState(100);
  const currentRadiusRef = useRef(radius);

  const [productName, setProductName] = useState("R-我的報告");
  const [coor, setCoor] = useState({ lng: 121.30128, lat: 24.99265 });
  const [buildingName, setBuildingName] = useState("buildings_taipei3857");
  const [clickedBuildingIds, setClickedBuildingIds] = useState(new Set());
  const [buildingInfo, setBuildingInfo] = useState([]);
  const [initialCoor, setInitialCoor] = useState({
    lng: 121.30128,
    lat: 24.99265,
  });

  useEffect(() => {
    currentRadiusRef.current = radius;
  }, [radius]);
  

  // Function to handle place selection from GooglePlacesAutocomplete
  const handlePlaceSelected = (place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    setCoor({ lat, lng });

    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [lng, lat],
        zoom: 15,
      });

      if (markerRef.current) {
        markerRef.current.setLngLat([lng, lat]);
      } else {
        markerRef.current = new mapboxgl.Marker()
          .setLngLat([lng, lat])
          .addTo(mapRef.current);
      }

      // updateCircle(lng, lat, radius);
      updateCircle(lng, lat, currentRadiusRef.current);
    }
  };

  // Function to calculate points based on radius
  const calculatePoints = (radius) => {
    switch (radius) {
      case 50:
        return 300;
      case 100:
        return 900;
      case 150:
        return 1500;
      default:
        return 0;
    }
  };

  const addBuildingsLayer = () => {
    if (!mapRef.current.getSource("buildings")) {
      // Add source for buildings
      mapRef.current.addSource("buildings", {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] }, // initial empty data
      });
    }

    if (!mapRef.current.getLayer("3d-buildings")) {
      // Add layer for buildings
      mapRef.current.addLayer({
        id: "3d-buildings",
        source: "buildings",
        type: "fill-extrusion",
        paint: {
          "fill-extrusion-color": "#aaa",

          "fill-extrusion-height": ["get", "build_h"],
          "fill-extrusion-base": 0,
          "fill-extrusion-opacity": 0.9,
        },
      });
    }

    // Call your function to fetch and set the data for buildings
    fetchBuildingsInView(mapRef.current);
  };

  const points = calculatePoints(radius);

  const switchBaseMap = (style) => {
    if (mapRef.current) {
      const currentStyle = mapRef.current.getStyle();
      mapRef.current.setStyle("mapbox://styles/mapbox/" + style);

      // Once the style is loaded, re-add the circle
      mapRef.current.once("style.load", () => {
        addBuildingsLayer();
        // Here, re-add any sources or layers that your map needs.
        if (circlePolygon) {
          if (mapRef.current.getLayer("circle-layer")) {
            mapRef.current.removeLayer("circle-layer");
          }
          if (mapRef.current.getSource("circle-source")) {
            mapRef.current.removeSource("circle-source");
          }

          mapRef.current.addSource("circle-source", {
            type: "geojson",
            data: circlePolygon,
          });

          mapRef.current.addLayer({
            id: "circle-layer",
            type: "fill",
            source: "circle-source",
            layout: {},
            paint: {
              "fill-color": "#29AB87",
              "fill-opacity": 0.3,
            },
          });
        }

        // Re-position the marker if it exists
        if (markerRef.current) {
          markerRef.current.addTo(mapRef.current);
        }
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the user is not logged in
    if (pkid === null) {
      toast.warning("您尚未登入！請先登入再進行此操作。");
      return;
    }

    if (circlePolygon === null) {
      toast.error("請先在地圖上點選分析位置!");
      return;
    }
    const product_title = `安家報告-${radius.toString()}m`;

    // const product_title =
    //   "水災-" +
    //   radius.toString() +
    //   "m/座標(" +
    //   coor["lng"].toFixed(5).toString() +
    //   ", " +
    //   coor["lat"].toFixed(5).toString() +
    //   ")";

    // Construct the geo_data object
    const geoData = {
      center: {
        lng: coor["lng"],
        lat: coor["lat"],
      },
      radius: radius,
    };

    const formData = {
      title: product_title,
      name: productName,
      description: "Risk analysis for selected radius", //
      hazard_type: "Flood",
      total_price: points,
      radius: radius,
      created_by_customer: pkid,
      product_type: "radius",
      geo_data: JSON.stringify(geoData),
    };
    console.log("formData", formData);
    const response = await axiosInstance.post(`risk/products/`, formData);
    const data = response.data;
    // console.log(response.data);

    // console.log("after post to backend");
    // console.log(formData);
    // to redux cart
    dispatch(
      addToCart({
        id: data.pkid,
        title: data.title,
        desc: data.desc,
        price: data.total_price,
        // img: data.attributes.img.data.attributes.url,
        quantity: 1,
      })
    );

    toast.success("成功加入購物車!");
  };

  const county_location = {
    9007: ["連江縣", 119.9436, 26.1519],
    10002: ["宜蘭縣", 121.76088, 24.7288],
    10007: ["彰化縣", 120.516, 24.0521],
    10008: ["南投縣", 120.9911, 23.8388],
    10009: ["雲林縣", 120.482, 23.6649],
    10017: ["基隆市", 121.7415, 25.1282],
    63000: ["臺北市", 121.5637, 25.0375],
    65000: ["新北市", 121.4628, 25.0168],
    66000: ["臺中市", 120.6736, 24.1477],
    67000: ["臺南市", 120.2053, 23.0007],
    68000: ["桃園市", 121.30128, 24.99265],
    10005: ["苗栗縣", 120.8214, 24.5602],
    10020: ["嘉義市", 120.45205, 23.47675],
    10010: ["嘉義縣", 120.6244, 23.4346],
    9020: ["金門縣", 118.319, 24.4324],
    64000: ["高雄市", 120.312, 22.6209],
    10014: ["臺東縣", 121.1136, 22.7649],
    10015: ["花蓮縣", 121.6014, 23.991],
    10016: ["澎湖縣", 119.5793, 23.568],
    10018: ["新竹市", 120.9665, 24.805],
    10004: ["新竹縣", 121.0027, 24.8392],
    10013: ["屏東縣", 120.5489, 22.5495],
  };

  useEffect(() => {
    // Get the county location from the county_location object using the countyCode
    const location = county_location[countyCode];
    if (location) {
      const [countyName, lng, lat, wfsName] = location;
      setInitialCoor({ lng, lat });
      setBuildingName(wfsName);
    }
  }, [countyCode]);

  useEffect(() => {
    // Get the county location from the county_location object using the countyCode
    const location = county_location[countyCode];
    if (location) {
      const [countyName, lng, lat] = location;
      setInitialCoor({ lng, lat });
    }
  }, [countyCode]);

  useEffect(() => {
    const getPkid = async () => {
      try {
        const res = await axiosInstance.get("auth/users/me/");
        setPkid(res.data.pkid);
      } catch (err) {
        toast.warning("您目前尚未登入喔!");
      }
    };
    getPkid();
  }, []);

  useEffect(() => {
    if (mapRef.current) return; // Skip if map already initialized

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [initialCoor.lng, initialCoor.lat],
      zoom: 16,
    });

    mapRef.current = map;

    if (mapRef.current) {
      mapRef.current.on("load", () => {
        //3d building
        addBuildingsLayer();
      });
    }

    // mapRef.current.addControl(
    //   new MapboxGeocoder({
    //     accessToken: mapboxgl.accessToken,
    //     mapboxgl: mapboxgl,
    //     language: "zh_TW",
    //   }),
    //   "top-left"
    // );
    mapRef.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      }),
      "top-right"
    );
    mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-right");
    mapRef.current.addControl(new mapboxgl.FullscreenControl(), "top-right");

    mapRef.current.on("moveend", () => fetchBuildingsInView(mapRef.current));

    map.on("click", (e) => {
      const { lng, lat } = e.lngLat;
      setCoor({ lng, lat });

      if (!markerRef.current) {
        markerRef.current = new mapboxgl.Marker()
          .setLngLat([lng, lat])
          .addTo(mapRef.current);
      } else {
        markerRef.current.setLngLat([lng, lat]);
      }

      // Fly the map to the new location
      mapRef.current.flyTo({
        center: [lng, lat],
        duration: 2000,
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        zoom: 17,
      });

      // Update Circle
      // updateCircle(lng, lat, radius);
      updateCircle(lng, lat, currentRadiusRef.current);
    });

    return () => {
      if (mapRef.current) {
        mapRef.current.off("load");
        mapRef.current.off("moveend");

        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [initialCoor]);

  const fetchBuildingsInView = (map) => {
    const bounds = map.getBounds();
    setIsLoading(true);

    axios
      .get(`${geoserver_url}geoserver/staging/ows`, {
        params: {
          service: "WFS",
          version: "1.0.0",
          request: "GetFeature",
          // typeName: `staging:building_all_20230208_uid`,
          typeName: `staging:buildings_all3857_s`,
          bbox: `${bounds.getWest()},${bounds.getSouth()},${bounds.getEast()},${bounds.getNorth()}`,
          outputFormat: "application/json",
        },
        auth: {
          username: gs_username,
          password: gs_pass
        }
      })
      .then((response) => {
        const data = response.data;
        // Assign uid as id for each feature
        data.features.forEach((feature) => {
          feature.id = feature.properties.uid;
        });
        map.getSource("buildings").setData(data);

        // Reapply 'clicked' state to buildings
        clickedBuildingIds.forEach((id) => {
          map.setFeatureState({ source: "buildings", id }, { clicked: true });
        });

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the GeoServer data:", error);
        setIsLoading(false);
      });
  };

  const updateCircle = (lng, lat, radius) => {
    const newCirclePolygon = circle(point([lng, lat]), radius, {
      steps: 64,
      units: "meters",
    });
    setCirclePolygon(newCirclePolygon);

    if (mapRef.current.getLayer("circle-layer")) {
      mapRef.current.getSource("circle-source").setData(newCirclePolygon);
    } else {
      mapRef.current.addSource("circle-source", {
        type: "geojson",
        data: newCirclePolygon,
      });

      mapRef.current.addLayer({
        id: "circle-layer",
        type: "fill",
        source: "circle-source",
        layout: {},
        paint: {
          "fill-color": "#29AB87",
          "fill-opacity": 0.3,
        },
      });
    }
  };

  const handleRadiusChange = (event) => {
    const newRadius = parseInt(event.target.value, 10);
    setRadius(newRadius);
    if (coor.lng && coor.lat) {
      updateCircle(coor.lng, coor.lat, newRadius);
    }
  };

  const handleProductNameChange = (event) => {
    setProductName(event.target.value);
  };

  return (
    <div
      className="container choose-container"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        padding: "10px",
        position: "relative",
      }}
    >
      {/* Map container */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          borderRadius: "4px",
        }}
      >
        {/* Map container covering the full width and 60vh height */}
        <div
          ref={mapContainer}
          style={{
            width: "100%",
            height: "60vh",
            padding: "10px",
            borderRadius: "4px",
          }}
        />
        {/* Google Places Autocomplete component */}
        <div style={{ position: "absolute" }}>
          <GooglePlacesAutocomplete
            onPlaceSelected={handlePlaceSelected}
            isMapLoaded={true}
          />
        </div>
        {/* Base Map Switcher */}
        <div
          id="menu"
          style={{
            position: "absolute",
            bottom: 10, // Adjust as needed for top spacing
            left: "50%", // Center horizontally
            transform: "translateX(-50%)", // Adjust for exact centering
            background: "#efefef",
            padding: "5px",
            zIndex: 1, // Ensure it's above the map
            border: "solid 1px",
            borderRadius: "4px",
          }}
        >
          <input
            id="streets-v12"
            type="radio"
            name="rtoggle"
            value="streets-v12"
            onChange={() => switchBaseMap("streets-v12")}
            defaultChecked
          />
          <label
            htmlFor="streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            街道
          </label>
          <input
            id="satellite-streets-v12"
            type="radio"
            name="rtoggle"
            value="satellite-streets-v12"
            onChange={() => switchBaseMap("satellite-streets-v12")}
          />
          <label
            htmlFor="satellite-streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            衛星
          </label>
        </div>
      </div>

      {/* Second row split into 2:1 ratio */}
      <div
        style={{
          padding: "10px",
          display: "flex",
          flex: 1,
          maxHeight: "40vh",
          gap: "10px",
          fontSize: "20px",
        }}
      >
        {/* Left part of the second row with a flex ratio of 2 */}
        <div
          style={{
            backgroundColor: "white", // Corrected the property name
            fontSize: "small",
            flex: 1, // 2 parts of the second row
            overflowY: "auto", // If you want a scrollbar
            gap: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="stack"
            style={{
              display: "flex",
              alignItems: "start",
              gap: "5px",
              flexDirection: "column",
            }}
          >
            <label
              htmlFor="product-name"
              style={{
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              報告名稱:{" "}
            </label>
            <input
              style={{
                fontSize: "16px",
                fontWeight: "bold",
              }}
              type="text"
              id="product-name"
              value={productName}
              onChange={handleProductNameChange}
            />
          </div>
          <div
            className="stack"
            style={{
              display: "flex",
              alignItems: "start",
              gap: "5px",
              flexDirection: "column",
            }}
          >
            <label
              style={{
                fontSize: "18px",
                fontWeight: "bold",
              }}
              htmlFor="radius"
            >
              選擇點位半徑:{" "}
            </label>
            <select
              style={{
                fontSize: "16px",
                fontWeight: "bold",
              }}
              id="radius"
              value={radius}
              onChange={handleRadiusChange}
            >
              <option value={50}>半徑50公尺</option>
              <option value={100}>半徑100公尺</option>
              <option value={150}>半徑150公尺</option>
            </select>
          </div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            <strong>在地圖上點選位置:</strong>
            <br />
            座標({coor["lng"].toFixed(5)}, {coor["lat"].toFixed(5)})
          </p>
        </div>

        {/* Right part of the second row with a flex ratio of 1 */}
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <p>
            <strong>會員點數: {points} 點</strong>
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <button className="add" onClick={handleSubmit}>
              加入購物車
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskMapRadius;
