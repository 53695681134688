import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./select.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import { addToCart } from "../../redux/cartReducer";
import * as turf from "@turf/turf";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import area from "@turf/area";
import TargetInfoInput from "./TargetInfoInput";
import { setTargetInfo, resetTargetInfo } from "../../redux/targetInfoReducer";
import { setMapState } from "../../redux/mapStateReducer";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const gs_username = process.env.REACT_APP_GEOSERVER_BASIC_USERNAME;
const gs_pass = process.env.REACT_APP_GEOSERVER_BASIC_PASSWORD;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = mapboxtoken;

const ClickPolygonMapSelect = ({ initialCoor }) => {
  const targetInfo = useSelector((state) => state.targetInfo);
  const mapState = useSelector((state) => state.mapState);
  // console.log("mapState", mapState);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pkid, setPkid] = useState(null);
  const { countyCode } = useParams();
  const mapContainer = useRef(null);
  const mapRef = useRef(null); // New reference for the map instance
  const [isLoading, setIsLoading] = useState(false);
  const [clickedBuildingIds, setClickedBuildingIds] = useState(new Set());
  const [buildingInfo, setBuildingInfo] = useState([]);
  const [updatedBuildingInfo, setUpdatedBuildingInfo] = useState([]);
  const [unionGeometry, setUnionGeometry] = useState(null);
  const [targetArea, setTargetArea] = useState(0);
  const [hullValid, setHullValid] = useState(true);
  // const [initialCoor, setInitialCoor] = useState({
  //   lng: 121.30128,
  //   lat: 24.99265,
  // });

  // console.log("buildingInfo", buildingInfo);

  // console.log("updatedBuildingInfo", updatedBuildingInfo);
  // console.log("clickedBuildingIds", clickedBuildingIds);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [productName, setProductName] = useState("B-我的報告");

  // this is to calculate and prevent big area
  const updateConvexHullAndCalculateArea = () => {
    // console.log("updateConvexHullAndCalculateArea call");
    if (buildingInfo.length === 0) {
      console.log("No buildings selected");
      return;
    }

    // Create an array of all coordinates from all selected buildings
    const allCoords = [];
    buildingInfo.forEach((building) => {
      const parsedGeometry = JSON.parse(building.geometry);
      // MultiPolygon coordinates are nested, so we need to flatten them appropriately
      parsedGeometry.coordinates.forEach((polygon) => {
        polygon.forEach((ring) => {
          ring.forEach((coord) => {
            allCoords.push(coord);
          });
        });
      });
    });

    // Generate points from allCoords and create a FeatureCollection
    const points = allCoords.map((coord) => turf.point(coord));
    const featureCollection = turf.featureCollection(points);

    // Generate a convex hull from the FeatureCollection
    const hull = turf.convex(featureCollection);

    if (hull) {
      // console.log("Convex Hull GeoJSON:", JSON.stringify(hull));
      // Now calculate the area of the convex hull
      const hullArea = turf.area(hull); // The area is in square meters
      // console.log("Convex Hull Area:", hullArea);

      // Calculate the bbox of the convex hull
      const bbox = turf.bbox(hull);
      // Use turf.point to create points for distance calculation
      const bottomLeft = turf.point([bbox[0], bbox[1]]);
      const bottomRight = turf.point([bbox[2], bbox[1]]);
      const topLeft = turf.point([bbox[0], bbox[3]]);

      // Calculate width and height of the bbox in meters
      const width = turf.distance(bottomLeft, bottomRight, { units: "meters" });
      const height = turf.distance(bottomLeft, topLeft, { units: "meters" });

      // console.log(
      //   `Convex Hull BBox Width: ${width} meters, Height: ${height} meters`
      // );

      // Check if width or height is more than 200 meters
      if (width > 400 || height > 400) {
        // toast.error("Width or height of the area exceeds 200 meters.");
        toast.error(
          // `選擇區域的長或寬需小於400公尺 Width: ${width} meters, Height: ${height} meters`
          `選擇區域的長或寬需小於400公尺`
        );
        setHullValid(false);
      }

      // Optionally, visualize the hull on your map
      // addHullLayerToMap(hull); // Assuming you have a function to add the hull to your map
    } else {
      console.log("Could not create a convex hull");
    }
  };

  // const addHullLayerToMap = (geometry) => {
  //   console.log("addHullLayerToMap call");
  //   // Remove existing layer and source if they exist
  //   if (mapRef.current.getLayer("hull-layer")) {
  //     mapRef.current.removeLayer("hull-layer");
  //     mapRef.current.removeSource("hull-layer");
  //   }

  //   // Add new source and layer
  //   mapRef.current.addSource("hull-layer", {
  //     type: "geojson",
  //     data: geometry,
  //   });

  //   mapRef.current.addLayer({
  //     id: "hull-layer",
  //     type: "fill", // Assuming it's a polygon
  //     source: "hull-layer",
  //     paint: {
  //       "fill-color": "#000000",
  //       "fill-opacity": 0.5,
  //     },
  //   });
  // };

  useEffect(() => {
    if (buildingInfo.length > 0) {
      updateConvexHullAndCalculateArea();
    }
  }, [buildingInfo]);

  const handleProductNameChange = (event) => {
    setProductName(event.target.value);
  };
  // const handleReset = () => {
  //   dispatch(resetTargetInfo());
  //   setBuildingInfo([]);
  // };

  const handleReset = () => {
    dispatch(resetTargetInfo());
    setBuildingInfo([]);

    // Assuming you've stored the IDs of the clicked buildings in clickedBuildingIds state
    if (mapRef.current && mapRef.current.isStyleLoaded()) {
      clickedBuildingIds.forEach((id) => {
        mapRef.current.setFeatureState(
          { source: "buildings", id },
          { clicked: false }
        );
      });
    }

    // Clear the clicked buildings IDs set
    setClickedBuildingIds(new Set());

    // Optionally, you can also remove or reset any layers or sources you've added to the map
    // that are related to the building info. This step depends on how you've structured your map layers.

    // For example, if you added a 'union-layer' or 'hull-layer' that needs to be reset or removed:
    if (mapRef.current.getLayer("union-layer")) {
      mapRef.current.removeLayer("union-layer");
      mapRef.current.removeSource("union-layer");
    }

    // Similar for 'hull-layer' or any other custom layers you've used
  };

  const updateUnionOfBuildings = () => {
    // console.log("updateUnionOfBuildings call");
    // console.log(buildingInfo);

    if (buildingInfo.length === 0) {
      // console.log("No buildings selected");
      // Clear the layer if no buildings are selected
      if (mapRef.current.getSource("union-layer")) {
        mapRef.current.getSource("union-layer").setData({
          type: "FeatureCollection",
          features: [],
        });
      }
      return;
    }

    let unionGeometry;
    buildingInfo.forEach((building, index) => {
      const buildingGeometry = JSON.parse(building.geometry);

      if (index === 0) {
        unionGeometry = buildingGeometry; // Start with the first building
      } else {
        // Union with the next building
        unionGeometry = turf.union(unionGeometry, buildingGeometry);
      }
    });

    if (unionGeometry) {
      // console.log("Union of Buildings GeoJSON:", JSON.stringify(unionGeometry));
      addUnionLayerToMap(unionGeometry);
      setUnionGeometry(unionGeometry);
    } else {
      console.log("Could not create a union of building geometries");
      setUnionGeometry(null);
    }
  };

  const addUnionLayerToMap = (geometry) => {
    // console.log("addUnionLayerToMap call");
    // Remove existing layer and source if they exist
    if (mapRef.current.getLayer("union-layer")) {
      mapRef.current.removeLayer("union-layer");
      mapRef.current.removeSource("union-layer");
    }

    // Add new source and layer
    mapRef.current.addSource("union-layer", {
      type: "geojson",
      data: geometry,
    });

    mapRef.current.addLayer({
      id: "union-layer",
      type: "fill", // Assuming it's a polygon
      source: "union-layer",
      paint: {
        // "fill-color": "#4b19e3",
        "fill-color": "#000000",
        "fill-opacity": 0.2,
      },
    });
  };

  const switchBaseMap = (style) => {
    if (mapRef.current) {
      mapRef.current.setStyle("mapbox://styles/mapbox/" + style);
      mapRef.current.once("style.load", () => {
        // Re-add the buildings source and layer here
        addBuildingsLayer();
      });
    }
  };

  const addBuildingsLayer = () => {
    if (!mapRef.current.getSource("buildings")) {
      // Add source for buildings
      mapRef.current.addSource("buildings", {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] }, // initial empty data
      });
    }

    if (!mapRef.current.getLayer("3d-buildings")) {
      // Add layer for buildings
      mapRef.current.addLayer({
        id: "3d-buildings",
        source: "buildings",
        type: "fill-extrusion",
        paint: {
          "fill-extrusion-color": [
            "case",
            ["boolean", ["feature-state", "clicked"], false],
            // "#ff0000",
            "#3182b5",
            "#aaa",
          ],
          "fill-extrusion-height": ["get", "build_h"],
          "fill-extrusion-base": 0,
          "fill-extrusion-opacity": 0.9,
        },
      });
    }

    // Call your function to fetch and set the data for buildings
    fetchBuildingsInView(mapRef.current);
  };

  useEffect(() => {
    const updatedBuildings = buildingInfo.map((building) => {
      // const geometry = JSON.parse(building.geometry);
      // Check if building.geometry is already an object
      const geometry =
        typeof building.geometry === "string"
          ? JSON.parse(building.geometry)
          : building.geometry;
      const area = turf.area(geometry); // Calculates the area in square meters
      return { ...building, area };
    });
    setUpdatedBuildingInfo(updatedBuildings);
  }, [buildingInfo]); // Dependency array ensures this runs only when buildingInfo changes

  //get the user
  useEffect(() => {
    let isMounted = true; // Flag to track component's mount state

    const getPkid = async () => {
      try {
        const res = await axiosInstance.get("auth/users/me/");
        if (isMounted) setPkid(res.data.pkid);
      } catch (err) {
        if (isMounted) toast.warning("您目前尚未登入喔!");
      }
    };

    getPkid();

    // Cleanup function to set isMounted to false when the component unmounts
    return () => {
      isMounted = false;
    };
  }, []);

  const totalArea = updatedBuildingInfo.reduce(
    (sum, building) => sum + building.area,
    0
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the user is not logged in
    if (pkid === null) {
      toast.warning("您尚未登入！請先登入再進行此操作。");
      return;
    }

    if (buildingInfo.length === 0) {
      toast.error("請先在地圖上點選分析區域!");
      return;
    }
    if (targetArea > 50000) {
      toast.error(
        "請選擇小於50,000平方公尺的面積，或是聯繫客服取得客製化分析!"
      );
      return;
    }
    if (!hullValid) {
      toast.error(
        "選擇區域的長度或寬度需小於400公尺，或是聯繫客服取得客製化分析!"
      );
      return;
    }

    // Initialize geometry variable
    let geometry;

    // If only one building is selected, use its geometry directly
    if (buildingInfo.length === 1) {
      geometry = JSON.parse(buildingInfo[0].geometry);
    }
    // If more than one building is selected, use the union geometry
    else if (unionGeometry) {
      geometry = unionGeometry.geometry;
    } else {
      // If no union geometry is set, you can decide how to handle this
      toast.error("未能生成聯合幾何圖形!");
      return;
    }

    const product_title = productName;
    const formData = {
      title: product_title,
      name: product_title,
      description: "Risk analysis for selected buildings", // Or any other description you wish to add
      total_price: 500,
      radius: 100,
      created_by_customer: pkid,
      product_type: "building",
      geo_data: JSON.stringify(geometry), // Stringify the entire buildingInfo array
      target_info: JSON.stringify(targetInfo),
    };
    // console.log("formData", formData);
    const response = await axiosInstance.post(`risk/products/`, formData);
    // const response = await axiosInstance.post(`shop/riskproducts/`, formData);
    const data = response.data;
    // console.log(response.data);
    // console.log("after post to backend");
    // console.log(formData);
    // to redux cart
    dispatch(
      addToCart({
        id: data.pkid,
        title: data.title,
        desc: data.desc,
        price: data.total_price,
        // img: data.attributes.img.data.attributes.url,
        quantity: 1,
      })
    );

    toast.success("成功加入購物車!");
    // console.log(buildingInfo);
  };

  useEffect(() => {
    // Only initialize the map if it doesn't already exist
    if (mapRef.current) return; // This prevents multiple initializations

    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      // center: [initialCoor.lng, initialCoor.lat],
      // zoom: 15,
      // maxZoom: 19,
      // minZoom: 14,
      center: mapState.center, // starting position [lng, lat]
      zoom: mapState.zoom, // starting zoom
      pitch: mapState.pitch, // starting pitch
      bearing: mapState.bearing, // starting bearing
    });

    if (mapRef.current) {
      mapRef.current.on("load", () => {
        //3d building
        addBuildingsLayer();
      });
    }

    mapRef.current.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        language: "zh_TW",
      }),
      "top-left"
    );
    mapRef.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      }),
      "top-right"
    );
    mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-right");
    mapRef.current.addControl(new mapboxgl.FullscreenControl(), "top-right");

    class Toggle3DControl {
      constructor(pitchAngle = 50) {
        this.pitchAngle = pitchAngle; // The pitch angle for the 3D view
      }

      onAdd(map) {
        this.map = map;
        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
        this.button = document.createElement("button");
        // Set initial button text based on the current pitch
        this.button.textContent = map.getPitch() === 0 ? "3D" : "2D";
        this.button.onclick = () => {
          // Check the current pitch of the map
          const currentPitch = map.getPitch();
          // Toggle between 0 and the specified pitch angle
          const newPitch = currentPitch === 0 ? this.pitchAngle : 0;
          map.easeTo({ pitch: newPitch });
          // Update the button text based on the new pitch value
          this.button.textContent = newPitch === 0 ? "3D" : "2D";
        };
        this.container.appendChild(this.button);
        return this.container;
      }

      onRemove() {
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
      }
    }

    mapRef.current.addControl(new Toggle3DControl(50), "top-right");

    // mapRef.current.on("moveend", () => fetchBuildingsInView(mapRef.current));
    // mapRef.current.on("moveend", () => {
    //   debouncedUpdateMapState();
    // });

    mapRef.current.on("moveend", () => {
      fetchBuildingsInView(mapRef.current);
      debouncedUpdateMapState();
    });

    mapRef.current.on("click", "3d-buildings", (e) =>
      handleBuildingClick(e, mapRef.current)
    );

    // Add event listener for 'mouseenter' event on the '3d-buildings' layer
    mapRef.current.on("mouseenter", "3d-buildings", () => {
      mapRef.current.getCanvas().style.cursor = "pointer";
    });

    // Add event listener for 'mouseleave' event on the '3d-buildings' layer
    mapRef.current.on("mouseleave", "3d-buildings", () => {
      mapRef.current.getCanvas().style.cursor = "";
    });

    // Zoom event listener
    mapRef.current.on("zoom", () => {
      const currentZoom = mapRef.current.getZoom();
      // console.log("zoom level: ", currentZoom);
      if (currentZoom >= 15.5) {
        addBuildingsLayer();
      } else {
        // Optional: Remove the buildings layer or adjust its visibility
        // This is just an example to remove, adapt as needed
        if (mapRef.current.getLayer("3d-buildings")) {
          mapRef.current.removeLayer("3d-buildings");
          mapRef.current.removeSource("buildings");
        }
      }
    });

    return () => {
      if (mapRef.current) {
        // Remove event listeners first
        mapRef.current.off("load");
        mapRef.current.off("moveend");
        mapRef.current.off("click", "3d-buildings");

        mapRef.current.off("mouseenter", "3d-buildings");
        mapRef.current.off("mouseleave", "3d-buildings");

        // Then remove the map
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [initialCoor]);

  function debounce(func, wait) {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  // Creating a debounced function
  const debouncedUpdateMapState = debounce(() => {
    const center = mapRef.current.getCenter();
    const zoom = mapRef.current.getZoom();
    const pitch = mapRef.current.getPitch();
    const bearing = mapRef.current.getBearing();

    // Getting the current state from Redux for comparison
    const currentState = mapState; // Assuming you have access to mapState here

    // Check for significant changes (adjust thresholds as needed)
    const hasSignificantChange =
      Math.abs(center.lng - currentState.center[0]) > 0.0001 ||
      Math.abs(center.lat - currentState.center[1]) > 0.0001 ||
      Math.abs(zoom - currentState.zoom) > 0.1 ||
      Math.abs(pitch - currentState.pitch) > 1 ||
      Math.abs(bearing - currentState.bearing) > 1;

    if (hasSignificantChange) {
      dispatch(
        setMapState({
          center: [center.lng, center.lat],
          zoom,
          pitch,
          bearing,
        })
      );
    }
  }, 250); // Debounce time in milliseconds

  const fetchBuildingsInView = (map) => {
    const bounds = map.getBounds();
    setIsLoading(true);

    axios
      .get(`${geoserver_url}geoserver/staging/ows`, {
        params: {
          service: "WFS",
          version: "1.0.0",
          request: "GetFeature",
          typeName: `staging:building_all_20230208_uid`,
          // typeName: `staging:buildings_all3857_s`,
          bbox: `${bounds.getWest()},${bounds.getSouth()},${bounds.getEast()},${bounds.getNorth()}`,
          outputFormat: "application/json",
        },
        auth: {
          username: gs_username,
          password: gs_pass,
        },
      })
      .then((response) => {
        const data = response.data;
        // Assign uid as id for each feature
        data.features.forEach((feature) => {
          feature.id = feature.properties.uid;
        });
        map.getSource("buildings").setData(data);

        // Reapply 'clicked' state to buildings
        clickedBuildingIds.forEach((id) => {
          map.setFeatureState({ source: "buildings", id }, { clicked: true });
        });

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the GeoServer data:", error);
        setIsLoading(false);
      });
  };

  // Modified handleBuildingClick to select by dis_uid
  const handleBuildingClick = (e, map) => {
    if (e.features.length > 0) {
      const clickedFeature = e.features[0];
      const clickedDisUid = clickedFeature.properties.dis_uid;

      // Ensure dis_uid is defined
      if (typeof clickedDisUid === "undefined") return;

      // Filter buildingsInView by dis_uid and check if any point of each is within the circle
      const relatedBuildings = map.getSource("buildings")._data.features.filter(
        (feature) => feature.properties.dis_uid === clickedDisUid
        // feature.properties.dis_uid === clickedDisUid &&
        // isAnyPointWithinCircle(feature.geometry, circlePolygon)
      );

      const relatedBuildingIds = relatedBuildings.map(
        (building) => building.properties.uid
      );

      // Determine the current state based on the first clicked feature to decide on selection/deselection
      const currentState =
        map.getFeatureState({
          source: "buildings",
          id: clickedFeature.properties.uid,
        }).clicked || false;

      relatedBuildingIds.forEach((uid) => {
        map.setFeatureState(
          { source: "buildings", id: uid },
          { clicked: !currentState }
        );
      });

      // Update buildingInfo and clickedBuildingIds based on the operation
      setBuildingInfo((prev) => {
        if (!currentState) {
          // Add all related buildings that meet the criteria to the state
          return [
            ...prev,
            ...relatedBuildings.map((building) => ({
              uid: building.properties.uid,
              region: "test", // Example property
              geometry: JSON.stringify(building.geometry),
              door_height: building.properties.build_h, // Example property
              area: turf.area(building.geometry),
            })),
          ];
        } else {
          // Remove all related buildings from the state
          return prev.filter(
            (building) => !relatedBuildingIds.includes(building.uid)
          );
        }
      });

      setClickedBuildingIds((prev) => {
        const newClickedIds = new Set(prev);
        if (!currentState) {
          relatedBuildingIds.forEach((id) => newClickedIds.add(id));
        } else {
          relatedBuildingIds.forEach((id) => newClickedIds.delete(id));
        }
        return newClickedIds;
      });

      // console.log("Clicked building", featureUid);
      // console.log("Current buildingInfo", buildingInfo);

      // // After updating buildingInfo and clickedBuildingIds
      // updateConvexHull();
    }
  };

  useEffect(() => {
    if (buildingInfo.length > 0) {
      // updateConvexHull();
      updateUnionOfBuildings();
    }
  }, [buildingInfo]);

  const clearSelection = () => {
    // Reset the buildingInfo state
    setBuildingInfo([]);

    // Reset Redux polygon data
    // dispatch(resetPolygonData()); // Assuming resetPolygonData() is an action creator

    // resetPolygonData();

    // Reset the clickedBuildingIds state
    setClickedBuildingIds(new Set());

    // Reset feature state for each clicked building
    // mapRef.current.getSource("buildings")._data.features.forEach((feature) => {
    //   mapRef.current.setFeatureState(
    //     { source: "buildings", id: feature.properties.uid },
    //     { clicked: false }
    //   );
    // });

    // Remove the convex hull layer from the map if it exists
    if (mapRef.current.getLayer("hull-layer")) {
      mapRef.current.removeLayer("hull-layer");
      mapRef.current.removeSource("hull-layer");
    }

    // Remove the convex hull layer from the map if it exists
    if (mapRef.current.getLayer("union-layer")) {
      mapRef.current.removeLayer("union-layer");
      mapRef.current.removeSource("union-layer");
    }

    // Optionally, clear any visual indications of selected buildings on the map
    // This would depend on how you're indicating selection, for example:
    clickedBuildingIds.forEach((id) => {
      mapRef.current.setFeatureState(
        { source: "buildings", id },
        { clicked: false }
      );
    });
  };

  useEffect(() => {
    const totalArea = updatedBuildingInfo.reduce(
      (sum, building) => sum + building.area,
      0
    );

    // const totalArea = calculateTotalArea(updatedBuildingInfo);

    // console.log("calculateTotalArea", totalArea);
    // console.log("updatedBuildingInfo", updatedBuildingInfo);
    setTargetArea(totalArea);
    if (totalArea > 50000) {
      toast.error("您選擇的面積太大了，需小於50,000平方公尺.");
    }

    // console.log("Total area: ", totalArea); // You can remove this line later
    // Update the state or display this area in your component
  }, [updatedBuildingInfo]);

  return (
    <div
      className="select-container"
      // style={{
      //   display: "flex",
      //   flexDirection: "column",
      //   height: "140vh",
      //   padding: "10px",
      //   borderRadius: "4px",
      // }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          borderRadius: "4px",
        }}
      >
        {/* Map container covering the full width and 60vh height */}
        <div
          ref={mapContainer}
          style={{
            width: "100%",
            height: "50vh",
            padding: "10px",
            borderRadius: "4px",
          }}
        />
        {/* Base Map Switcher */}
        <div
          id="menu"
          style={{
            position: "absolute",
            bottom: 10, // Adjust as needed for top spacing
            left: "50%", // Center horizontally
            transform: "translateX(-50%)", // Adjust for exact centering
            background: "#efefef",
            padding: "5px",
            zIndex: 1, // Ensure it's above the map
            border: "solid 1px",
            borderRadius: "4px",
          }}
        >
          <input
            id="streets-v12"
            type="radio"
            name="rtoggle"
            value="streets-v12"
            onChange={() => switchBaseMap("streets-v12")}
            defaultChecked
          />
          <label
            htmlFor="streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            街道
          </label>
          <input
            id="satellite-streets-v12"
            type="radio"
            name="rtoggle"
            value="satellite-streets-v12"
            onChange={() => switchBaseMap("satellite-streets-v12")}
          />
          <label
            htmlFor="satellite-streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            衛星
          </label>
        </div>
      </div>

      <TargetInfoInput />

      {/* Second row split into 2:1 ratio */}
      <div
        className="submit-container"
        // style={{
        //   padding: "10px 0px",
        //   display: "flex",
        //   flex: 1,
        //   maxHeight: "20vh",
        //   gap: "10px",
        // }}
      >
        {/* Left part of the second row with a flex ratio of 2 */}
        <div
          style={{
            backgroundColor: "white", // Corrected the property name
            fontSize: "small",
            // flex: 1, // 2 parts of the second row
            overflowY: "auto", // If you want a scrollbar
          }}
        >
          <div
            className="stack"
            style={{
              display: "flex",
              alignItems: "start",
              gap: "5px",
              padding: "10px",
              // flexDirection: "column",
              flexDirection: "row",
            }}
          >
            <label
              htmlFor="product-name"
              style={{
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              名稱:{" "}
            </label>
            <input
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                maxWidth: "150px",
              }}
              type="text"
              id="product-name"
              value={productName}
              onChange={handleProductNameChange}
            />
          </div>
        </div>

        {/* Right part of the second row with a flex ratio of 1 */}

        <div
          style={{
            // flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            {/* <button className="add" onClick={() => dispatch(resetTargetInfo())}> */}
            <button className="add" onClick={handleReset}>
              重置參數
            </button>
            <button className="add" onClick={handleSubmit}>
              加入購物車
            </button>
          </div>

          <p>
            <strong>分析總面積: {totalArea.toFixed(2)} m²</strong>
            <strong> 會員點數: {500} 點</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ClickPolygonMapSelect;
