import React, { useEffect, useState } from "react";
import "./Prob2.scss";

const Prob2 = ({ report }) => {
  return (
    <div className="prob2">
      <table cellSpacing="5">
        <thead>
          <tr>
            <th>環域統計分析</th>
            <th>最大值</th>
            <th>平均值</th>
            <th>最小值</th>
            {/* <th>中位數</th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>深度</td>
            <td>{report.maximum}</td>
            <td>{report.mean}</td>
            <td>{report.minimum}</td>
            {/* <td>{report.percentile_values[4].toFixed(2)}</td> */}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Prob2;
