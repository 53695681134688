import React from "react";

const ComingSoon = () => {
  return (
    <div>
      <div className="h1">功能即將上線，敬請期待!</div>
    </div>
  );
};

export default ComingSoon;
