import React from "react";

const EarthquakeLoss = ({ report }) => {
  const productTargetInfo = JSON.parse(report.product.target_info);
  const earthquake_loss = report.risk_results["earthquake"][0];
  const earthquake_level = report.risk_results["earthquake"][1];
  const pga_all = report.risk_results["earthquake"][2];
  const cwb_level = report.risk_results["earthquake"][3];
  const design_pga_loss_rate = report.risk_results["earthquake"][7];
  const rebuild_price_per_ping = report.risk_results["earthquake"][5];
  const renovationValue = productTargetInfo?.["renovationValue"];
  const pingAll = productTargetInfo?.["pingAll"];
  return (
    <div style={{ padding: "0px 20px" }}>
      <ul
        // className="content-list"
        style={{ fontSize: "16px", fontWeight: "bold" }}
      >
        <li>
          依據本系統分析，考量各種地震來源對此地的震度、發生機率與損壞程度，您的房屋在50年內的損壞比率約為
          <span style={{ color: "red" }}>
            {(report.risk_results["earthquake"][6] * 100).toFixed(2)}
            %，每年平均損壞為
            {((report.risk_results["earthquake"][6] * 100) / 50).toFixed(2)}%
          </span>
        </li>
        <li>
          依據產物保險商業同業公會的住宅類建築造價參考，您的房屋建坪為
          {pingAll}
          （每坪重建成本為{rebuild_price_per_ping}元），裝潢為
          {renovationValue}
          萬元，因此您的建物價值成本(此為重建成本)為
          {(rebuild_price_per_ping * pingAll) / 10000 + renovationValue}
          萬元。
        </li>
        <li>
          根據上述每年平均損壞比率與建物成本，本建物的每年平均損失(年期望損失)為
          <span style={{ color: "red" }}>
            {report.risk_results["earthquake"][0]}元/年
          </span>
          。
        </li>
      </ul>
    </div>
  );
};

export default EarthquakeLoss;
