import React from "react";
import "./Product_intro.scss";
import { Link } from "react-router-dom";

const Product_intro = () => {
  return (
    <div className="product-intro">
      <div className="report-container">
        <div className="title-container">
          <div className="report-h0">SAFEHOUSING</div>
          <h3 className="title-text">商品介紹</h3>
          <hr className="title-divider" />
        </div>

        <div className="content-container">
          <ul className="content-list">
            <li className="content-header">說明:</li>
            <li>
              本產品提供用戶自行選擇地點，並透過巨量資料水文水理模式(詳見
              <Link to="/tech">技術理論</Link>
              ),模擬出高精度淹水潛勢圖，再搭配統計學模型計算出各地點10-200公分以上之淹水機率，並給予安家建議之專屬報告，提供用戶參考該地區淹水風險，作為安家置產/保險投資的決策依據。
            </li>
            <li>
              本產品使用最先進的流體動力、資料科學與統計學等分析，產製與公開資料不同等級的精緻風險分析圖(本產品解析度約0.5-2m/公開資料約20-40m)。
            </li>
            <li>
              目前除提供高精度淹水風險評估，亦開發其他自然災害風險分析，依據統計學原理估計災害潛勢機率與行動方案。
            </li>
            <li>目前服務區域限台灣本島地區。</li>
          </ul>

          <hr />
          <h5>市面上圖資產品差異比較</h5>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan="2">特徵</th>
                  <th>公開淹水潛勢圖</th>
                  <th>高解析度淹水模擬圖(本產品)</th>
                  <th>影響對淹水模擬結果的差異</th>
                </tr>
              </thead>
              <tbody>
                {/* Repeat rows as necessary */}
                <tr>
                  <th rowSpan="5">模型設定</th>
                  <th>地形網格解析度</th>
                  <td>
                    <ul className="small-font">
                      <li>40m (每40m一個高程數值);</li>
                      <li>
                        均化40m*40m範圍的所有都市特徵的高程(道路剖面 人行道
                        一樓樓地板 堤防等)
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul className="small-font">
                      <li>
                        0.5m~1m (每50cm一個高程數值,
                        資料密度為右側的1600~6400倍);
                      </li>
                      <li>
                        以0.5m*0.5m或1m*1m的網格來描述都市特徵的高程,
                        包含道路剖面 人行道 一樓樓地板 堤防等高程細節
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul className="small-font">
                      <li>
                        0.5m網格可輕易描述都市特徵，而40m網格會均化各種特徵的高程，造成淹水模擬不正確。
                      </li>
                      <li>淹水資訊正不正確會影響你的決策</li>
                    </ul>
                  </td>
                </tr>
                {/* Continue with other rows */}
                <tr>
                  <th>地表排水</th>
                  <td>
                    <ul className="small-font align-start">
                      <li>
                        有；採用1D計算，無法有效描述短距離內的高程與斷面的變化
                      </li>
                      <li>網格密度為10,000cm</li>
                    </ul>
                  </td>
                  <td>
                    <ul className="small-font align-start">
                      <li>
                        有；採用2D與1D混搭計算，有效描述排水斷面 走向
                        高程變化等細節。
                      </li>
                      <li>網格密度達50cm~100cm</li>
                    </ul>
                  </td>
                  <td>資料密度影響渠道水流計算的正確性</td>
                </tr>
                <tr>
                  <th>側溝(路肩水溝)</th>
                  <td>無</td>
                  <td>有</td>
                  <td>
                    雨水皆透過側溝進入下水道與排水系統，有側溝資料可提升整體計算正確性,反之無側溝資料將影響整體正確性
                  </td>
                </tr>
                <tr>
                  <th>下水道</th>
                  <td>有</td>
                  <td>有</td>
                  <td>下水道數據有助於管中水流計算</td>
                </tr>
                <tr>
                  <th>水工結構物</th>
                  <td>有</td>
                  <td>有</td>
                  <td>精確模擬水工結構物對洪水控制的重要性</td>
                </tr>
                <tr>
                  <th rowSpan="5">淹水圖資</th>
                  <th>淹水解析度</th>
                  <td>
                    <ul className="small-font align-start">
                      <li>每4000cm*4000cm一個淹水數字</li>
                      <li>路肩與人行道騎樓一樣淹水深度</li>
                    </ul>
                  </td>
                  <td>
                    <ul className="small-font align-start">
                      <li>每50cm*50cm一個淹水數字</li>
                      <li>路肩、人行道、騎樓的淹水深度不同</li>
                    </ul>
                  </td>
                  <td>路肩、人行道、騎樓、建物的淹水深度不同</td>
                </tr>
                <tr>
                  <th>淹水呈現</th>
                  <td>
                    <ul className="small-font align-start">
                      <li>30cm~2m</li>
                      <li>僅3～5個間距</li>
                      <li>30cm以下淹水不呈現</li>
                    </ul>
                  </td>
                  <td>
                    <ul className="small-font align-start">
                      <li>0cm~200cm</li>
                      <li> 20個間距，每10公分一個間距</li>
                      <li>各種淹水細節都呈現</li>
                    </ul>
                  </td>
                  <td>
                    淹水即使小於30cm也可能造成不同程度的損失,尤其在路肩停放的汽機車影響最大
                  </td>
                </tr>
                <tr>
                  <th>建築物淹水評估</th>
                  <td>無</td>
                  <td>有</td>
                  <td>允許針對特定建築評估淹水風險，增加模擬的實用性</td>
                </tr>
                <tr>
                  <th>水深-機率關係</th>
                  <td>無</td>
                  <td>
                    <ul className="small-font align-start">
                      <li>建立每個網格的水深-機率關係</li>
                      <li>
                        模擬數百種降雨情境藉以建立每一個網格的淹水深度與發生頻率的關係
                      </li>
                    </ul>
                  </td>
                  <td>
                    提供每格水深機率，能更準確預測淹水深度與範圍與對應的淹水損失
                  </td>
                </tr>
                <tr>
                  <th>更新頻率</th>
                  <td>每10年更新一次</td>
                  <td>每年更新一次</td>
                  <td>
                    各縣市政府每年皆會進行淹水改善工程，每年更新資料有助於了解淹水的狀況
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <hr />
          <h5>購買流程示意圖</h5>
          <img className="full-width" src="/img/buyflow.png" alt="buyflow" />
        </div>

        {/* <div className="title mt-150">
          <div className="report-h0">SAFEHOUSING</div>
          <h3>專屬報告範例:</h3>
          <hr />
        </div> */}

        {/* <div className="report-example">
          <div className="report-image">
            <img
              className="rounded-circle"
              src="/img/floodmap2.png"
              alt="floodmap"
            />
            <img
              className="mt-20 full-width"
              src="/img/prob.png"
              alt="probability"
            />
          </div>

          <div className="report-details">
            <h5 className="primary_color">情境與結果說明</h5>
            <p>
              您選擇的點位(121.235, 24.665)
              在6小時350mm降雨情境下之高精度淹水模擬結果之淹水高度為0.3公尺，為中高淹水潛勢地區。
            </p>

            <h5 className="primary_color mt-5">安家建議</h5>
            <p>
              你所選擇區域的周邊道路在此降雨情境下多處淹水50公分以上，豪雨期間建議避開此路段，並注意停車位置使否在高淹水潛勢地區，以便在降雨前期提早移動車輛，若為地下停車場，應安裝並升起起擋水閘門。
            </p>

            <h5 className="primary_color mt-5">其他說明</h5>
            <p>
              地形高程、水工結構物、排水管線、降雨情境、土地利用(入滲、粗糙度)。
            </p>

            <h5 className="primary_color mt-5">模擬資料</h5>
            <p>
              淹水潛勢圖製作係依據各縣（市）自然環境資料、過去災害發生狀況及相關研究資料，利用淹水模式進行災害潛勢模擬及評估，製作成各縣（市）淹水潛勢範圍、規模之防災參考用圖。
            </p>

            <h5 className="primary_color mt-5">用途限制</h5>
            <p>僅供購買用戶之防救災風險評估使用、不得轉載。</p>

            <h5 className="primary_color mt-5">模型參數說明</h5>
            <p>
              水理模式: 3Di模式
              <br />
              <br />
              水文條件: 依據用戶購買種類，可為分為
            </p>
          </div>
        </div> */}

        {/* <div className="button-container">
          <Link to="/choosemap">
            <button className="purchase-button">購買報告</button>
          </Link>
        </div> */}
      </div>

      {/* <div className="city-bg">
        <img className="full-width" src="/img/city-l.png" alt="city" />
      </div> */}
    </div>
  );
};

export default Product_intro;
