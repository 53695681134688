import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./ClickPolygonMap.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import area from "@turf/area";
import DepthProbBuilding from "../Prob/DepthProbBuilding";
import DepthProbTableBuilding from "../Prob/DepthProbTableBuilding";
import * as turf from "@turf/turf";
import { stringify } from "wkt";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = mapboxtoken;

const BuildingRisk = ({ detailOrder }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pkid, setPkid] = useState(null);
  const mapContainer = useRef(null);
  const mapRef = useRef(null); // New reference for the map instance
  const [isLoading, setIsLoading] = useState(false);
  const [clickedBuildingIds, setClickedBuildingIds] = useState(new Set());
  const [buildingInfo, setBuildingInfo] = useState([]);
  const [updatedBuildingInfo, setUpdatedBuildingInfo] = useState([]);

  const [legendUrl, setLegendUrl] = useState("");
  const [convexHullGeometry, setConvexHullGeometry] = useState(null);
  const [unionGeometry, setUnionGeometry] = useState(null);
  const [circlePolygon, setCirclePolygon] = useState(
    detailOrder?.calculated_geometry
  );

  const [wmsLayerName, setWmsLayerName] = useState(detailOrder?.wms_layer_name); // Set to your initial WMS layer name
  const [wkt3857, setWkt3857] = useState(
    stringify(detailOrder?.calculated_geometry_3857)
  ); // Initialize to an empty string

  const [waterProbData, setWaterProbData] = useState({
    "10cm": 100.0,
    "20cm": 100.0,
    "30cm": 100.0,
    "40cm": 99.732,
    "50cm": 83.015,
    "60cm": 29.465,
    "70cm": 4.254,
    "80cm": 0.715,
    "90cm": 0.052,
    "100cm": 0.003,
    "110cm": 0.0,
    "120cm": 0.0,
    "130cm": 0.0,
    "140cm": 0.0,
    "150cm": 0.0,
    "160cm": 0.0,
    "170cm": 0.0,
    "180cm": 0.0,
    "190cm": 0.0,
    "200cm": 0.0,
  });

  const updateConvexHull = () => {
    // console.log("updateConvexHull call");
    // console.log(buildingInfo);
    if (buildingInfo.length === 0) {
      console.log("No buildings selected");
      if (mapRef.current.getSource("hull-layer")) {
        mapRef.current.getSource("hull-layer").setData({
          type: "FeatureCollection",
          features: [],
        });
      }
      return;
    }

    // Create an array of all coordinates from all selected buildings
    const allCoords = buildingInfo.flatMap((building) => {
      const parsedGeometry = JSON.parse(building.geometry);
      return parsedGeometry.coordinates[0]; // Assuming these are polygons
    });

    // Create a FeatureCollection from the coordinates
    const featureCollection = turf.featureCollection(
      allCoords.map((coords) => {
        return turf.point(coords);
      })
    );

    // Generate a convex hull from the FeatureCollection
    const hull = turf.convex(featureCollection);

    if (hull) {
      // console.log("Convex Hull GeoJSON:", JSON.stringify(hull));
      setConvexHullGeometry(hull); // Save the hull geometry to the state
      addHullLayerToMap(hull);
    } else {
      // console.log("Could not create a convex hull");
      setConvexHullGeometry(null);
    }
  };

  const updateUnionOfBuildings = () => {
    // console.log("updateUnionOfBuildings call");
    console.log(buildingInfo);

    if (buildingInfo.length === 0) {
      // console.log("No buildings selected");
      // Clear the layer if no buildings are selected
      if (mapRef.current.getSource("union-layer")) {
        mapRef.current.getSource("union-layer").setData({
          type: "FeatureCollection",
          features: [],
        });
      }
      return;
    }

    let unionGeometry;
    buildingInfo.forEach((building, index) => {
      const buildingGeometry = JSON.parse(building.geometry);

      if (index === 0) {
        unionGeometry = buildingGeometry; // Start with the first building
      } else {
        // Union with the next building
        unionGeometry = turf.union(unionGeometry, buildingGeometry);
      }
    });

    if (unionGeometry) {
      // console.log("Union of Buildings GeoJSON:", JSON.stringify(unionGeometry));
      addUnionLayerToMap(unionGeometry);
      setUnionGeometry(unionGeometry);
    } else {
      console.log("Could not create a union of building geometries");
      setUnionGeometry(null);
    }
  };

  const addUnionLayerToMap = (geometry) => {
    console.log("addUnionLayerToMap call");
    // Remove existing layer and source if they exist
    if (mapRef.current.getLayer("union-layer")) {
      mapRef.current.removeLayer("union-layer");
      mapRef.current.removeSource("union-layer");
    }

    // Add new source and layer
    mapRef.current.addSource("union-layer", {
      type: "geojson",
      data: geometry,
    });

    mapRef.current.addLayer({
      id: "union-layer",
      type: "fill", // Assuming it's a polygon
      source: "union-layer",
      paint: {
        // "fill-color": "#4b19e3",
        "fill-color": "#000000",
        "fill-opacity": 0.2,
      },
    });
  };

  const addHullLayerToMap = (geometry) => {
    // console.log("addHullLayerToMap call");
    // Remove existing layer and source if they exist
    if (mapRef.current.getLayer("hull-layer")) {
      mapRef.current.removeLayer("hull-layer");
      mapRef.current.removeSource("hull-layer");
    }

    // Add new source and layer
    mapRef.current.addSource("hull-layer", {
      type: "geojson",
      data: geometry,
    });

    mapRef.current.addLayer({
      id: "hull-layer",
      type: "fill", // Assuming it's a polygon
      source: "hull-layer",
      paint: {
        "fill-color": "#000000",
        "fill-opacity": 0.5,
      },
    });
  };

  const switchBaseMap = (style) => {
    if (mapRef.current) {
      mapRef.current.setStyle("mapbox://styles/mapbox/" + style);
      mapRef.current.once("style.load", () => {
        // Re-add the buildings source and layer here
        addBuildingsLayer();
        addCircleToMap();
        addWMSLayers();
      });
    }
  };

  const addBuildingsLayer = () => {
    if (!mapRef.current.getSource("buildings")) {
      // Add source for buildings
      mapRef.current.addSource("buildings", {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] }, // initial empty data
      });
    }

    if (!mapRef.current.getLayer("3d-buildings")) {
      // Add layer for buildings
      mapRef.current.addLayer({
        id: "3d-buildings",
        source: "buildings",
        type: "fill-extrusion",
        paint: {
          "fill-extrusion-color": [
            "case",
            ["boolean", ["feature-state", "clicked"], false],
            // "#ff0000",
            "#3182b5",
            "#aaa",
          ],
          "fill-extrusion-height": ["get", "build_h"],
          "fill-extrusion-base": 0,
          "fill-extrusion-opacity": 0.9,
        },
      });
    }

    // Call your function to fetch and set the data for buildings
    fetchBuildingsInView(mapRef.current);
  };

  useEffect(() => {
    const updatedBuildings = buildingInfo.map((building) => {
      // const geometry = JSON.parse(building.geometry);
      // Check if building.geometry is already an object
      const geometry =
        typeof building.geometry === "string"
          ? JSON.parse(building.geometry)
          : building.geometry;
      const area = turf.area(geometry); // Calculates the area in square meters
      return { ...building, area };
    });
    setUpdatedBuildingInfo(updatedBuildings);
  }, [buildingInfo]); // Dependency array ensures this runs only when buildingInfo changes

  const totalArea = updatedBuildingInfo.reduce(
    (sum, building) => sum + building.area,
    0
  );

  //get the user
  useEffect(() => {
    const getPkid = async () => {
      try {
        const res = await axiosInstance.get("auth/users/me/");
        setPkid(res.data.pkid);
      } catch (err) {
        // console.error(err);
        toast.warning("您目前尚未登入喔!");
      }
    };
    getPkid();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the user is not logged in
    if (pkid === null) {
      toast.warning("您尚未登入！請先登入再進行此操作。");
      return;
    }

    // if (buildingInfo.length === 0 || !buildingInfo[0].geometry) {
    //   toast.error("請先在地圖上畫出分析區域!");
    //   return;
    // }

    // Check if no building is selected
    if (buildingInfo.length === 0) {
      toast.error("請先在地圖上選出建物區域!");
      return;
    }
    // if (!convexHullGeometry) {
    //   toast.error("請先在地圖上選出建物區域!");
    //   return;
    // }

    // Extract the geometry from the GeoJSON Feature
    // const geometry = convexHullGeometry.geometry;

    // Initialize geometry variable
    let geometry;

    // If only one building is selected, use its geometry directly
    if (buildingInfo.length === 1) {
      geometry = JSON.parse(buildingInfo[0].geometry);
    }
    // If more than one building is selected, use the union geometry
    else if (unionGeometry) {
      geometry = unionGeometry.geometry;
    } else {
      // If no union geometry is set, you can decide how to handle this
      toast.error("未能生成聯合幾何圖形!");
      return;
    }

    const formData = new FormData();
    formData.append("geo_data", JSON.stringify(geometry));

    console.log(formData);
    try {
      toast.info("分析已提交！");
      const response = await axiosInstance.post(
        `shop/waterdepth-probability/`,
        formData
      );
      const data = response.data[0];
      setWaterProbData(data);
      // console.log("Water Probability Data:", data);
      // Success toast if needed
      toast.success("分析已完成！");
    } catch (error) {
      console.error("Error submitting convex hull geometry:", error);
      toast.error("分析提交失敗！");
    }
  };

  // Function to add the circle to the map
  const addCircleToMap = () => {
    if (mapRef.current.getSource("circle-polygon")) {
      mapRef.current.getSource("circle-polygon").setData(circlePolygon);
    } else {
      mapRef.current.addSource("circle-polygon", {
        type: "geojson",
        data: circlePolygon,
      });

      mapRef.current.addLayer({
        id: "circle-polygon-layer",
        type: "line",
        source: "circle-polygon",
        paint: {
          "line-color": "black",
          "line-width": 2,
          "line-dasharray": [2, 2],
        },
      });
    }
  };

  const addWMSLayers = () => {
    //wms
    mapRef.current.addSource("wms-test-source", {
      type: "raster",
      tiles: [
        geoserver_url +
          `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=${wmsLayerName}&FORMAT=image/png&TILED=true&clip=${wkt3857}`,
      ],
      tileSize: 256,
    });

    mapRef.current.addLayer(
      {
        id: "wms-test-layer",
        type: "raster",
        source: "wms-test-source",
        paint: {},
      },
      // "building"
      "3d-buildings"
    );

    mapRef.current.setPaintProperty("wms-test-layer", "raster-opacity", 1);
  };

  // Function to check if a point is within the circle
  // const isWithinCircle = (point, circle) => {
  //   return turf.booleanPointInPolygon(point, circle);
  // };

  useEffect(() => {
    // Only initialize the map if it doesn't already exist
    if (mapRef.current) return; // This prevents multiple initializations

    // for detailorder
    let center;
    try {
      center = turf.center(detailOrder.calculated_geometry).geometry
        .coordinates;
    } catch (error) {
      console.error("Error calculating center:", error);
      return;
    }

    if (!center || center.length !== 2 || center.some(isNaN)) {
      console.error("Invalid center coordinates:", center);
      return;
    }

    // console.log("Center coordinates:", center);

    // Use Turf.js to calculate the center and bbox
    const bbox = turf.bbox(detailOrder?.calculated_geometry);
    const product_bbox = [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ];

    // const wkt3857 = stringify(detailOrder?.calculated_geometry_3857);

    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      // style: "mapbox://styles/mapbox/satellite-v9", // style URL
      // center: [initialCoor.lng, initialCoor.lat],
      center: center,
      zoom: 15.5,
      minZoom: 15.5,
      // maxZoom: 15.5,
      dragPan: false,
    });

    // Assuming `wms_layer_name` is available and valid
    const newLegendUrl = `${geoserver_url}geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&LAYER=${detailOrder?.wms_layer_name}`;
    setLegendUrl(newLegendUrl);

    // Fit the map to the bbox extent
    mapRef.current.fitBounds(product_bbox, { padding: 20 });
    if (mapRef.current) {
      mapRef.current.on("load", () => {
        //circle
        addCircleToMap();
        //3d building
        addBuildingsLayer();
        //wms
        addWMSLayers();
      });
    }

    mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-right");
    mapRef.current.addControl(new mapboxgl.FullscreenControl(), "top-right");

    mapRef.current.on("moveend", () => fetchBuildingsInView(mapRef.current));
    mapRef.current.on("click", "3d-buildings", (e) =>
      handleBuildingClick(e, mapRef.current)
    );

    // Add event listener for 'mouseenter' event on the '3d-buildings' layer
    mapRef.current.on("mouseenter", "3d-buildings", () => {
      mapRef.current.getCanvas().style.cursor = "pointer";
    });

    // Add event listener for 'mouseleave' event on the '3d-buildings' layer
    mapRef.current.on("mouseleave", "3d-buildings", () => {
      mapRef.current.getCanvas().style.cursor = "";
    });

    return () => {
      if (mapRef.current) {
        // Remove event listeners first
        mapRef.current.off("load");
        mapRef.current.off("moveend");
        mapRef.current.off("click", "3d-buildings");

        mapRef.current.off("mouseenter", "3d-buildings");
        mapRef.current.off("mouseleave", "3d-buildings");

        // Then remove the map
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [detailOrder, circlePolygon]);

  const fetchBuildingsInView = (map) => {
    const bounds = map.getBounds();
    setIsLoading(true);

    axios
      .get(`${geoserver_url}geoserver/staging/ows`, {
        params: {
          service: "WFS",
          version: "1.0.0",
          request: "GetFeature",
          typeName: `staging:buildings_all3857_s`,
          bbox: `${bounds.getWest()},${bounds.getSouth()},${bounds.getEast()},${bounds.getNorth()}`,
          outputFormat: "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        // Assign uid as id for each feature
        data.features.forEach((feature) => {
          feature.id = feature.properties.uid;
        });
        map.getSource("buildings").setData(data);

        // Reapply 'clicked' state to buildings
        clickedBuildingIds.forEach((id) => {
          map.setFeatureState({ source: "buildings", id }, { clicked: true });
        });

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the GeoServer data:", error);
        setIsLoading(false);
      });
  };

  // Function to check if any point of the building's polygon is within the circle
  const isAnyPointWithinCircle = (buildingGeometry, circle) => {
    // Check if the geometry type is Polygon or MultiPolygon
    if (buildingGeometry.type === "Polygon") {
      // Iterate over each coordinate of the first ring of the polygon
      for (let pointCoordinates of buildingGeometry.coordinates[0]) {
        const point = turf.point(pointCoordinates);
        if (turf.booleanPointInPolygon(point, circle)) {
          return true; // Return true if any point is within the circle
        }
      }
    } else if (buildingGeometry.type === "MultiPolygon") {
      // Iterate over each polygon in the MultiPolygon
      for (let polygon of buildingGeometry.coordinates) {
        // Iterate over each coordinate of the first ring of the polygon
        for (let pointCoordinates of polygon[0]) {
          const point = turf.point(pointCoordinates);
          if (turf.booleanPointInPolygon(point, circle)) {
            return true; // Return true if any point is within the circle
          }
        }
      }
    }
    return false; // Return false if no points are within the circle
  };

  const handleBuildingClick = (e, map) => {
    if (e.features.length > 0) {
      const feature = e.features[0];
      const featureUid = feature.properties.uid;

      // Ensure featureUid is defined
      if (typeof featureUid === "undefined") return;

      // const coordinates = feature.geometry.coordinates[0][0]; // For Polygon
      // const point = turf.point(coordinates);
      // console.log(feature.geometry.coordinates);

      const buildingGeometry = feature.geometry;

      // Check if the building is within the circle
      // if (isWithinCircle(point, circlePolygon)) {
      if (isAnyPointWithinCircle(buildingGeometry, circlePolygon)) {
        // Calculate the area of the building
        const geometry = feature.geometry;
        const area = turf.area(geometry);

        // Check the current state of the clicked feature
        const currentState =
          map.getFeatureState({ source: "buildings", id: featureUid })
            .clicked || false;

        map.setFeatureState(
          { source: "buildings", id: featureUid },
          { clicked: !currentState }
        );

        // Update the building info state
        setBuildingInfo((prev) => {
          // Check if the building is already in the array
          const existingBuildingIndex = prev.findIndex(
            (b) => b.uid === featureUid
          );

          if (existingBuildingIndex === -1) {
            // If the building was not previously selected, add it to the array
            return [
              ...prev,
              {
                uid: featureUid,
                region: "test", // Assuming 'region' is a property
                geometry: JSON.stringify(feature.geometry), // Convert geometry object to string
                door_height: feature.properties.build_h, // Assuming 'door_height' is a property
                area: area, // Add the area here
              },
            ];
          } else {
            // If the building was previously selected, remove it from the array
            return prev.filter((_, index) => index !== existingBuildingIndex);
          }
        });

        // Update the clicked building IDs
        setClickedBuildingIds((prev) => {
          const newClickedIds = new Set(prev);
          if (!currentState) {
            newClickedIds.add(featureUid);
          } else {
            newClickedIds.delete(featureUid);
          }
          return newClickedIds;
        });

        // console.log("Clicked building", featureUid);
        // console.log("Current buildingInfo", buildingInfo);

        // // After updating buildingInfo and clickedBuildingIds
        // updateConvexHull();
      } else {
        // If the building is outside the circle, show an error or do nothing
        toast.error("建物不在選定區域內！");
      }
    }
  };

  useEffect(() => {
    if (buildingInfo.length > 0) {
      // updateConvexHull();
      updateUnionOfBuildings();
    }
  }, [buildingInfo]);

  const calculateTotalArea = (features) => {
    let totalArea = 0;
    features.forEach((feature) => {
      if (
        feature.geometry.type === "Polygon" ||
        feature.geometry.type === "MultiPolygon"
      ) {
        totalArea += area(feature);
      }
    });
    return totalArea;
  };

  const clearSelection = () => {
    // Reset the buildingInfo state
    setBuildingInfo([]);

    // Reset the clickedBuildingIds state
    setClickedBuildingIds(new Set());

    // Remove the convex hull layer from the map if it exists
    if (mapRef.current.getLayer("hull-layer")) {
      mapRef.current.removeLayer("hull-layer");
      mapRef.current.removeSource("hull-layer");
    }

    // Remove the convex hull layer from the map if it exists
    if (mapRef.current.getLayer("union-layer")) {
      mapRef.current.removeLayer("union-layer");
      mapRef.current.removeSource("union-layer");
    }

    // Optionally, clear any visual indications of selected buildings on the map
    // This would depend on how you're indicating selection, for example:
    clickedBuildingIds.forEach((id) => {
      mapRef.current.setFeatureState(
        { source: "buildings", id },
        { clicked: false }
      );
    });
  };

  useEffect(() => {
    const totalArea = calculateTotalArea(buildingInfo);
    // console.log("Total area: ", totalArea); // You can remove this line later
    // Update the state or display this area in your component
  }, [buildingInfo]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "220vh",
        // height: "100vh",
        padding: "10px",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {/* Map container covering the full width and 60vh height */}
        <div
          ref={mapContainer}
          style={{ width: "100%", height: "60vh", padding: "10px" }}
        />
        {/* Add the legend container */}
        <div
          className="legend-container"
          style={{
            position: "absolute",
            bottom: "20px",
            right: "10px",
            border: "2px solid",
            // padding: "2px",
            borderRadius: "5px",
          }}
        >
          {legendUrl && <img src={legendUrl} alt="Legend" />}
        </div>
        {/* Base Map Switcher */}
        <div
          id="menu"
          style={{
            position: "absolute",
            bottom: 10, // Adjust as needed for top spacing
            left: "50%", // Center horizontally
            transform: "translateX(-50%)", // Adjust for exact centering
            background: "#efefef",
            padding: "10px",
            zIndex: 1, // Ensure it's above the map
            border: "solid 1px",
            borderRadius: "1px",
          }}
        >
          <input
            id="streets-v12"
            type="radio"
            name="rtoggle"
            value="streets-v12"
            onChange={() => switchBaseMap("streets-v12")}
            defaultChecked
          />
          <label
            htmlFor="streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            街道
          </label>
          <input
            id="satellite-streets-v12"
            type="radio"
            name="rtoggle"
            value="satellite-streets-v12"
            onChange={() => switchBaseMap("satellite-streets-v12")}
          />
          <label
            htmlFor="satellite-streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            衛星
          </label>
        </div>
      </div>

      {/* Second row split into 2:1 ratio */}
      <div
        style={{
          padding: "10px",
          display: "flex",
          flex: 1,
          maxHeight: "20vh",
          gap: "10px",
        }}
      >
        {/* Left part of the second row with a flex ratio of 2 */}
        <div
          style={{
            backgroundColor: "white", // Corrected the property name
            fontSize: "small",
            flex: 1, // 2 parts of the second row
            overflowY: "auto", // If you want a scrollbar
          }}
        >
          <table>
            <thead>
              <tr>
                <th>Building ID</th>
                <th>Height</th>
                <th>Area (m²)</th>
              </tr>
            </thead>
            <tbody>
              {buildingInfo.map((building, index) => (
                <tr key={building.uid}>
                  <td>{building.uid}</td>
                  <td>{building.door_height}</td>
                  <td>{building.area ? building.area.toFixed(2) : "N/A"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Right part of the second row with a flex ratio of 1 */}
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <button className="add" onClick={handleSubmit}>
              開始分析
            </button>
            <button className="add" onClick={clearSelection}>
              重新選擇
            </button>
          </div>

          <p>
            <strong>分析建物總面積: {totalArea.toFixed(2)} m²</strong>
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <div
          style={{
            height: "50vh",
            margin: "20px",
          }}
        >
          <DepthProbBuilding waterProbData={waterProbData} />
        </div>

        <div className="depth-prob-table-container">
          <DepthProbTableBuilding waterProbData={waterProbData} />
        </div>
      </div>
    </div>
  );
};

export default BuildingRisk;
