import React from "react";

const LiqTable = ({ report }) => {
  // console.log(report?.product?.target_info);

  const parsedTargetInfo = JSON.parse(report?.product?.target_info);
  const inclination = report?.risk_results?.["liguefaction"]?.[6] || [];
  const buildingH = parsedTargetInfo["totalFloorNumber"] * 3.5 || [];
  const exceedance_probabilities =
    report?.risk_results?.["liguefaction"]?.[4].toFixed(5) * 100 || [];

  console.log(buildingH);
  console.log("inclination", inclination);
  console.log("exceedance_probabilities", exceedance_probabilities);
  return (
    <div>
      <div className="table-container">
        <table className="impact-table">
          <caption>土壤液化衝擊參考表</caption>
          <thead>
            <tr>
              <th>建物高(m)</th>
              <th>傾斜率</th>
              <th>機率(%)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{buildingH}</td>
              <td>
                {inclination[0]}~{inclination[1]}
              </td>
              <td>{exceedance_probabilities}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <img
          src="https://dmap.ncdr.nat.gov.tw/media/akgbmjjc/b6.png"
          style={{ width: "100%", height: "auto", maxWidth: "100%" }}
          alt="Soil liquefaction impact reference"
        />
        <p>資料來源:國家災害防救中心</p>
        <br />
      </div>
    </div>
  );
};

export default LiqTable;
