import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MapboxSyncMaps = () => {
  mapboxgl.accessToken = mapboxtoken;

  const mapContainer1 = useRef(null);
  const mapContainer2 = useRef(null);
  const map1 = useRef(null);
  const map2 = useRef(null);

  // Function to synchronize map movements
  const syncMaps = (sourceMap, targetMap) => {
    const center = sourceMap.getCenter();
    const zoom = sourceMap.getZoom();
    const bearing = sourceMap.getBearing();
    const pitch = sourceMap.getPitch();

    // Avoid circular updates by temporarily disabling the "move" event listener
    targetMap.off("move", targetMap._onMove);
    targetMap.jumpTo({ center, zoom, bearing, pitch });
    requestAnimationFrame(() => {
      targetMap.on("move", targetMap._onMove);
    });
  };

  useEffect(() => {
    if (mapContainer1.current && !map1.current) {
      map1.current = new mapboxgl.Map({
        container: mapContainer1.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [121.0066, 25.7135],
        zoom: 9,
      });

      map1.current.on("load", () => {
        map1.current._onMove = () => syncMaps(map1.current, map2.current);
        map1.current.on("move", map1.current._onMove);
      });
    }

    if (mapContainer2.current && !map2.current) {
      map2.current = new mapboxgl.Map({
        container: mapContainer2.current,
        style: "mapbox://styles/mapbox/light-v10",
        center: [121.0066, 25.7135],
        zoom: 9,
      });

      map2.current.on("load", () => {
        map2.current._onMove = () => syncMaps(map2.current, map1.current);
        map2.current.on("move", map2.current._onMove);
      });
    }

    return () => {
      if (map1.current) {
        map1.current.remove();
        map1.current = null; // Clear the reference
      }
      if (map2.current) {
        map2.current.remove();
        map2.current = null; // Clear the reference
      }
    };
  }, []);

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <div
        ref={mapContainer1}
        style={{ width: "50%", height: "100%", float: "left" }}
      />
      <div
        ref={mapContainer2}
        style={{ width: "50%", height: "100%", float: "left" }}
      />
    </div>
  );
};

export default MapboxSyncMaps;
