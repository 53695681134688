// import axios from "axios";
import axiosInstance from "../../axios"; // Import your configured axios instance

const REGISTER_URL = "/auth/users/";
const LOGIN_URL = "/auth/jwt/create/";
const ACTIVATE_URL = "/auth/users/activation/";
//
const REFRESH_TOKEN_URL = "/auth/jwt/refresh/";

const refreshToken = async (refreshToken) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await axiosInstance.post(
    REFRESH_TOKEN_URL,
    { refresh: refreshToken },
    config
  );
  return response.data;
};

// Register user
const register = async (userData) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axiosInstance.post(REGISTER_URL, userData, config);
  return response.data;
};

// Login user

const login = async (userData) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await axiosInstance.post(LOGIN_URL, userData, config);
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

const logout = () => localStorage.removeItem("user");

const activate = async (userData) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await axiosInstance.post(ACTIVATE_URL, userData, config);
  return response.data;
};

// const authService = { register, login, logout, activate };

// export default authService;

const authService = { register, login, logout, activate, refreshToken };
export default authService;
