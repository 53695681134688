import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./ClickPolygonMap.scss";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import area from "@turf/area";
// import DepthProbBuilding from "../Prob/DepthProbBuilding";
// import DepthProbTableBuilding from "../Prob/DepthProbTableBuilding";
import * as turf from "@turf/turf";
import { stringify } from "wkt";
import { useDispatch } from "react-redux";
import { setWaterProbData } from "../../redux/waterProbDataReducer";
import { setFaultData } from "../../redux/faultDataReducer";
import { setPolygonData } from "../../redux/polygonDataReducer";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const gs_username = process.env.REACT_APP_GEOSERVER_BASIC_USERNAME;
const gs_pass = process.env.REACT_APP_GEOSERVER_BASIC_PASSWORD;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = mapboxtoken;

const DrawPolygonMapAll = ({ detailOrder }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pkid, setPkid] = useState(null);
  const mapContainer = useRef(null);
  const mapRef = useRef(null); // New reference for the map instance
  const [isLoading, setIsLoading] = useState(false);
  const [clickedBuildingIds, setClickedBuildingIds] = useState(new Set());
  const [buildingInfo, setBuildingInfo] = useState([]);
  const [updatedBuildingInfo, setUpdatedBuildingInfo] = useState([]);
  const drawRef = useRef(null);
  const [legendUrl, setLegendUrl] = useState("");

  const updateWaterProbData = (data) => {
    dispatch(setWaterProbData(data));
  };
  const updateFaultData = (data) => {
    dispatch(setFaultData(data));
  };
  const updatePolygonData = (data) => {
    dispatch(setPolygonData(data));
  };

  const [circlePolygon, setCirclePolygon] = useState(
    detailOrder?.calculated_geometry
  );

  const [wmsLayerName, setWmsLayerName] = useState(detailOrder?.wms_layer_name); // Set to your initial WMS layer name
  const [wkt3857, setWkt3857] = useState(
    stringify(detailOrder?.calculated_geometry_3857)
  ); // Initialize to an empty string

  const switchBaseMap = (style) => {
    if (mapRef.current) {
      mapRef.current.setStyle("mapbox://styles/mapbox/" + style);
      mapRef.current.once("style.load", () => {
        // Re-add the buildings source and layer here
        addBuildingsLayer();
        addCircleToMap();
        addWMSLayers();
      });
    }
  };

  const addBuildingsLayer = () => {
    if (!mapRef.current.getSource("buildings")) {
      // Add source for buildings
      mapRef.current.addSource("buildings", {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] }, // initial empty data
      });
    }

    if (!mapRef.current.getLayer("3d-buildings")) {
      // Add layer for buildings
      mapRef.current.addLayer({
        id: "3d-buildings",
        source: "buildings",
        type: "fill-extrusion",
        paint: {
          "fill-extrusion-color": "#aaa",

          "fill-extrusion-height": ["get", "build_h"],
          "fill-extrusion-base": 0,
          "fill-extrusion-opacity": 0.9,
        },
      });
    }

    // Call your function to fetch and set the data for buildings
    fetchBuildingsInView(mapRef.current);
  };

  useEffect(() => {
    const updatedBuildings = buildingInfo.map((building) => {
      // const geometry = JSON.parse(building.geometry);
      // Check if building.geometry is already an object
      const geometry =
        typeof building.geometry === "string"
          ? JSON.parse(building.geometry)
          : building.geometry;
      const area = turf.area(geometry); // Calculates the area in square meters
      return { ...building, area };
    });
    setUpdatedBuildingInfo(updatedBuildings);
  }, [buildingInfo]); // Dependency array ensures this runs only when buildingInfo changes

  const totalArea = updatedBuildingInfo.reduce(
    (sum, building) => sum + building.area,
    0
  );

  // Function to add the circle to the map
  const addCircleToMap = () => {
    if (mapRef.current.getSource("circle-polygon")) {
      mapRef.current.getSource("circle-polygon").setData(circlePolygon);
    } else {
      mapRef.current.addSource("circle-polygon", {
        type: "geojson",
        data: circlePolygon,
      });

      mapRef.current.addLayer({
        id: "circle-polygon-layer",
        type: "line",
        source: "circle-polygon",
        paint: {
          "line-color": "black",
          "line-width": 2,
          "line-dasharray": [2, 2],
        },
      });
    }
  };

  const addWMSLayers = () => {
    //wms
    mapRef.current.addSource("wms-test-source", {
      type: "raster",
      tiles: [
        geoserver_url +
          `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=${wmsLayerName}&FORMAT=image/png&TILED=true&clip=${wkt3857}`,
      ],
      tileSize: 256,
    });

    mapRef.current.addLayer(
      {
        id: "wms-test-layer",
        type: "raster",
        source: "wms-test-source",
        paint: {},
      },
      "3d-buildings"
    );

    mapRef.current.setPaintProperty("wms-test-layer", "raster-opacity", 1);
  };

  //get the user
  useEffect(() => {
    const getPkid = async () => {
      try {
        const res = await axiosInstance.get("auth/users/me/");
        setPkid(res.data.pkid);
      } catch (err) {
        // console.error(err);
        toast.warning("您目前尚未登入喔!");
      }
    };
    getPkid();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the user is not logged in
    if (pkid === null) {
      toast.warning("您尚未登入！請先登入再進行此操作。");
      return;
    }

    // Ensure there is a polygon to work with
    if (buildingInfo.length === 0 || !buildingInfo[0].geometry) {
      toast.error("請先在地圖上畫出分析區域!");
      return;
    }

    // Get the drawn polygon
    const drawnPolygon = buildingInfo[0].geometry;

    // Check if all points of the drawn polygon are within the circlePolygon
    const allPointsInside = areAllPointsWithinCircle(
      drawnPolygon,
      circlePolygon
    );

    if (!allPointsInside) {
      toast.error("所有點必須在指定區域內！");
      return;
    }

    // Convert geometry to GeoJSON string for submission
    const geoJsonGeometry = JSON.stringify(drawnPolygon);

    const formData = {
      geo_data: geoJsonGeometry,
    };

    updatePolygonData({ geo_data: geoJsonGeometry });

    try {
      toast.info("分析已提交！");
      const response = await axiosInstance.post(
        `shop/waterdepth-probability/`,
        formData
      );
      const data = response.data[0];
      updateWaterProbData(data);
      // console.log("update waterprobdata to redux :", data);

      // const response1 = await axiosInstance.post(
      //   `risk/fault-distance/`,
      //   formData
      // );
      // const data1 = response1.data;
      // updateFaultData(data1);
      // console.log("update updateFaultData to redux :", data1);

      // Success toast if needed
      toast.success("分析完成！");
    } catch (error) {
      console.error("Error submitting polygon geometry:", error);
      toast.error("分析提交失敗！");
    }
  };

  useEffect(() => {
    // Only initialize the map if it doesn't already exist
    if (mapRef.current) return; // This prevents multiple initializations

    // for detailorder
    let center;
    try {
      center = turf.center(detailOrder.calculated_geometry).geometry
        .coordinates;
    } catch (error) {
      console.error("Error calculating center:", error);
      return;
    }

    if (!center || center.length !== 2 || center.some(isNaN)) {
      console.error("Invalid center coordinates:", center);
      return;
    }

    // console.log("Center coordinates:", center);

    // Use Turf.js to calculate the center and bbox
    const bbox = turf.bbox(detailOrder?.calculated_geometry);
    const product_bbox = [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ];

    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      // style: "mapbox://styles/mapbox/satellite-v9", // style URL
      center: center,
      transformRequest: (url, resourceType) => {
        if (
          (resourceType === "Source" || resourceType === "Tile") &&
          url.indexOf(geoserver_url + `geoserver/staging/wms`) > -1
        ) {
          const username = gs_username;
          const password = gs_pass;
          const credentials = btoa(`${username}:${password}`);
          const headers = {
            Authorization: `Basic ${credentials}`,
          };
          return {
            url: url,
            headers: headers,
            credentials: "include", // Include cookies for cross-origin requests
          };
        }
      },

      zoom: 15.5,
      minZoom: 15.5,
      // maxZoom: 15.5,
      dragPan: false,
    });

    // Fit the map to the bbox extent
    mapRef.current.fitBounds(product_bbox, { padding: 20 });

    if (mapRef.current) {
      mapRef.current.on("load", () => {
        //circle
        addCircleToMap();
        //3d building
        addBuildingsLayer();
        //wms
        addWMSLayers();
      });
    }

    // const draw = new MapboxDraw({
    //   displayControlsDefault: false,
    //   controls: {
    //     polygon: true,
    //     trash: true,
    //   },
    // });
    // mapRef.current.addControl(draw);
    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
      styles: [
        // Change the color of line strings to red
        {
          id: "gl-draw-line",
          type: "line",
          filter: [
            "all",
            ["==", "$type", "LineString"],
            ["!=", "mode", "static"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#00008b",
            "line-dasharray": [0.2, 2],
            "line-width": 2,
          },
        },
        // Change the color of polygons to blue with semi-transparent fill
        {
          id: "gl-draw-polygon-fill",
          type: "fill",
          filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
          paint: {
            "fill-color": "#00f", // Blue color
            "fill-outline-color": "#00f", // Blue outline color
            "fill-opacity": 0.1, // Semi-transparent fill
          },
        },
        // Change the color of polygon outlines to blue
        {
          id: "gl-draw-polygon-stroke-active",
          type: "line",
          filter: [
            "all",
            ["==", "$type", "Polygon"],
            ["==", "active", "true"],
            ["!=", "mode", "static"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#00008b",
            "line-dasharray": [0.2, 2],
            "line-width": 2,
          },
        },
        // Change the color of polygon vertices to blue
        {
          id: "gl-draw-polygon-and-line-vertex-active",
          type: "circle",
          filter: [
            "all",
            ["==", "meta", "featureEdited"],
            ["==", "active", "true"],
            ["==", "$type", "Point"],
          ],
          paint: {
            "circle-radius": 5,
            "circle-color": "#00008b",
          },
        },
      ],
    });

    mapRef.current.addControl(drawRef.current);

    mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-right");
    mapRef.current.addControl(new mapboxgl.FullscreenControl(), "top-right");

    // mapRef.current.on("draw.create", updateArea);

    // Function to delete all existing polygons
    // const deleteAllPolygons = () => {
    //   const allFeatures = draw.getAll();
    //   allFeatures.features.forEach((feature) => {
    //     if (feature.geometry.type === "Polygon") {
    //       draw.delete(feature.id);
    //     }
    //   });
    // };
    const deleteAllPolygons = () => {
      const allFeatures = drawRef.current.getAll();
      allFeatures.features.forEach((feature) => {
        if (feature.geometry.type === "Polygon") {
          drawRef.current.delete(feature.id);
        }
      });
    };

    // Event listener for draw.create
    mapRef.current.on("draw.create", (e) => {
      deleteAllPolygons(); // Ensure only one polygon exists

      // Add the newly drawn polygon
      const newPolygon = e.features[0];
      drawRef.current.add(newPolygon);

      // Update buildingInfo with the newly drawn polygon
      setBuildingInfo([newPolygon]);
    });

    // // Event listener for draw.create
    // mapRef.current.on("draw.create", (e) => {
    //   deleteAllPolygons(); // Delete existing polygons

    //   // Add the newly drawn polygon
    //   // Assuming e.features[0] is the newly drawn polygon
    //   const newPolygon = e.features[0];
    //   draw.add(newPolygon);

    //   // Update buildingInfo with the newly drawn polygon
    //   setBuildingInfo([newPolygon]);
    // });

    // mapRef.current.on("draw.delete", updateArea);
    // mapRef.current.on("draw.update", updateArea);

    // function updateArea(e) {
    //   const data = draw.getAll();
    //   setBuildingInfo(data.features);
    // }

    // Add event listeners for draw.create and draw.delete
    mapRef.current.on("draw.create", updateArea);
    mapRef.current.on("draw.delete", updateArea);
    mapRef.current.on("draw.update", updateArea);

    function updateArea(e) {
      const data = drawRef.current.getAll();
      setBuildingInfo(data.features);
    }

    mapRef.current.on("moveend", () => fetchBuildingsInView(mapRef.current));

    return () => {
      if (mapRef.current) {
        // Remove event listeners first
        mapRef.current.off("load");
        mapRef.current.off("moveend");
        // mapRef.current.off("click", "3d-buildings");

        // mapRef.current.off("draw.create", updateArea);
        // mapRef.current.off("draw.delete", updateArea);
        // mapRef.current.off("draw.update", updateArea);

        mapRef.current.removeControl(drawRef.current);

        // Then remove the map
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [detailOrder, circlePolygon]);

  useEffect(() => {
    // Function to update tooltips with Traditional Chinese translations
    const updateToolTipsToChinese = () => {
      // Mapping of English tooltips to Traditional Chinese
      const translations = {
        "Polygon tool (p)": "多邊形工具", // Example translation
        Delete: "刪除",
        // "Delete (Backspace)": "刪除 (Backspace)",
        // Add more translations as needed
      };

      // Select all elements that could contain tooltips and update their titles
      document.querySelectorAll("[title]").forEach((element) => {
        const title = element.getAttribute("title");
        if (translations[title]) {
          element.setAttribute("title", translations[title]);
        }
      });
    };

    // Call the function to update tooltips
    updateToolTipsToChinese();

    // Since tooltips might be dynamically generated, consider calling this function
    // after certain events or in intervals, depending on how Mapbox GL Draw works in your application
  }, []); // Empty dependency array means this effect runs once after the initial render

  const handleDrawPolygon = () => {
    // Enable drawing mode for polygon
    drawRef.current.changeMode("draw_polygon");
  };

  const handleResetDraw = () => {
    // Delete all features and reset buildingInfo
    drawRef.current.deleteAll();
    setBuildingInfo([]);
    drawRef.current.changeMode("simple_select"); // Change to default mode after reset
  };

  const fetchBuildingsInView = (map) => {
    const bounds = map.getBounds();
    setIsLoading(true);

    axios
      .get(`${geoserver_url}geoserver/staging/ows`, {
        params: {
          service: "WFS",
          version: "1.0.0",
          request: "GetFeature",
          typeName: `staging:buildings_all3857_s`,
          bbox: `${bounds.getWest()},${bounds.getSouth()},${bounds.getEast()},${bounds.getNorth()}`,
          outputFormat: "application/json",
        },
        auth: {
          username: gs_username,
          password: gs_pass,
        },
      })
      .then((response) => {
        const data = response.data;
        // Assign uid as id for each feature
        data.features.forEach((feature) => {
          feature.id = feature.properties.uid;
        });
        map.getSource("buildings").setData(data);

        // Reapply 'clicked' state to buildings
        clickedBuildingIds.forEach((id) => {
          map.setFeatureState({ source: "buildings", id }, { clicked: true });
        });

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the GeoServer data:", error);
        setIsLoading(false);
      });
  };

  // Function to check if all points of the polygon are within the circle
  const areAllPointsWithinCircle = (polygon, circle) => {
    // Assuming polygon is of type Polygon and not MultiPolygon for simplicity
    return polygon.coordinates[0].every((pointCoordinates) => {
      const point = turf.point(pointCoordinates);
      return turf.booleanPointInPolygon(point, circle);
    });
  };

  const calculateTotalArea = (features) => {
    let totalArea = 0;
    features.forEach((feature) => {
      if (
        feature.geometry.type === "Polygon" ||
        feature.geometry.type === "MultiPolygon"
      ) {
        totalArea += area(feature);
      }
    });
    return totalArea;
  };

  useEffect(() => {
    const totalArea = calculateTotalArea(buildingInfo);
    // console.log("Total area: ", totalArea); // You can remove this line later
    // Update the state or display this area in your component
  }, [buildingInfo]);

  useEffect(() => {
    // Function to fetch the legend image with authentication
    const fetchLegendImage = async (legendRequestUrl) => {
      try {
        const response = await axios.get(legendRequestUrl, {
          responseType: "blob", // Important: this tells Axios to handle the response as a Blob
          auth: {
            username: gs_username,
            password: gs_pass,
          },
        });

        // Create a local URL for the image blob
        const imageUrl = URL.createObjectURL(response.data);

        // Set the local URL as the image source
        setLegendUrl(imageUrl);
      } catch (error) {
        console.error("Error fetching legend image:", error);
        toast.error("無法加載圖例。");
      }
    };

    if (detailOrder?.wms_layer_name) {
      // Construct the legend URL using the layer name from detailOrder
      const legendRequestUrl = `${geoserver_url}geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&LAYER=${detailOrder.wms_layer_name}`;
      // Call the function to fetch the legend image
      fetchLegendImage(legendRequestUrl);
    }
  }, [detailOrder, geoserver_url, gs_username, gs_pass]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // height: "150vh",
        height: "75vh",
        // height: "90vh",
        // padding: "10px",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          borderRadius: "4px",
        }}
      >
        {/* Map container covering the full width and 60vh height */}
        <div
          ref={mapContainer}
          style={{
            width: "100%",
            height: "60vh",
            padding: "10px",
            borderRadius: "4px",
          }}
        />
        {/* Add the legend container */}
        <div
          className="legend-container"
          style={{
            position: "absolute",
            bottom: "20px",
            right: "10px",
            border: "2px solid",
            // padding: "2px",
            borderRadius: "4px",
          }}
        >
          {legendUrl && <img src={legendUrl} alt="Legend" />}
        </div>
        {/* Base Map Switcher */}
        <div
          id="menu"
          style={{
            position: "absolute",
            bottom: 10, // Adjust as needed for top spacing
            left: "50%", // Center horizontally
            transform: "translateX(-50%)", // Adjust for exact centering
            background: "#efefef",
            padding: "5px",
            zIndex: 1, // Ensure it's above the map
            border: "solid 1px",
            borderRadius: "4px",
          }}
        >
          <input
            id="streets-v12"
            type="radio"
            name="rtoggle"
            value="streets-v12"
            onChange={() => switchBaseMap("streets-v12")}
            defaultChecked
          />
          <label
            htmlFor="streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            街道
          </label>
          <input
            id="satellite-streets-v12"
            type="radio"
            name="rtoggle"
            value="satellite-streets-v12"
            onChange={() => switchBaseMap("satellite-streets-v12")}
          />
          <label
            htmlFor="satellite-streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            衛星
          </label>
        </div>
      </div>

      {/* Second row split into 2:1 ratio */}
      <div
        style={{
          padding: "10px",
          display: "flex",
          flex: 1,
          maxHeight: "20vh",
          gap: "10px",
        }}
      >
        {/* Left part of the second row with a flex ratio of 2 */}
        {/* <div
          style={{
            backgroundColor: "white", // Corrected the property name
            fontSize: "small",
            flex: 1, // 2 parts of the second row
            overflowY: "auto", // If you want a scrollbar
          }}
        >
          <h4>分析區域</h4>
          <p>請點選圖上右Polygon tool上方自行畫取分析區域</p>
          {buildingInfo.length > 0 && (
            <div>
              <h4>幾何圖形:</h4>
              <div>
                {JSON.stringify(buildingInfo[buildingInfo.length - 1].geometry)}
              </div>
            </div>
          )}
        </div> */}

        {/* Right part of the second row with a flex ratio of 1 */}
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <button className="add" onClick={handleDrawPolygon}>
              手繪
            </button>
            <button className="add" onClick={handleResetDraw}>
              重設
            </button>
            <button className="add" onClick={handleSubmit}>
              開始分析
            </button>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              justifyContent: "center",
            }}
          >
            <p>
              <strong>
                分析總面積:{calculateTotalArea(buildingInfo).toFixed(2)} m²
              </strong>
            </p>
          </div>

          {/* <FloodResult /> */}
        </div>
      </div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <div
          style={{
            height: "50vh",
            margin: "20px",
          }}
        >
          <DepthProbBuilding waterProbData={waterProbData} />
        </div>

        <div className="depth-prob-table-container">
          <DepthProbTableBuilding waterProbData={waterProbData} />
        </div>
      </div> */}
    </div>
  );
};

export default DrawPolygonMapAll;
