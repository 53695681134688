import React, { useEffect, useState } from "react";
// import { Container } from "@mui/material";
// import { useSelector } from "react-redux";
import axiosInstance from "../../axios";

const LiqResult = ({ report, productTargetInfo }) => {
  // const polygonData = useSelector((state) => state.polygonData.polygonData);
  const polygonData = report?.product?.geo_data;
  const [liqProb, setLiqProb] = useState({});
  const [loading, setLoading] = useState(false);

  // console.log("polygonData:", polygonData);

  const riskfrombuildingtime =
    productTargetInfo?.["buildingTime"] > 1999 ? "低" : "高";

  // Define initial state for checkboxes (false means unchecked)
  const [checkboxStates, setCheckboxStates] = useState({
    q1: false,
    q2: false,
    q3: false,
    q4: false,
    q5: false,
    q6: false,
    q7: false,
  });

  const liq_result = report.risk_results["liguefaction"];

  // Handler for checkbox state changes
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxStates((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      if (polygonData) {
        setLoading(true);
        try {
          const formData = {
            geo_data: polygonData,
          };
          const response = await axiosInstance.post(
            `risk/liq-prob/`,
            formData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const data = response.data.result;

          setLiqProb(data);
          console.log("get liq analysis done:", data);
        } catch (error) {
          console.error("Error getting liq:", error);
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [polygonData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!liqProb) {
    return <div>No liquefaction probability data available.</div>;
  }

  return (
    <div>
      {/* <div style={{ padding: "10px" }}>
        <table>
          <thead>
            <tr>
              <th>風險</th>
              <th>a</th>
              <th>b</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>無潛勢</td>
              <td>0.020%</td>
              <td>1%</td>
            </tr>
            <tr>
              <td>低潛勢</td>
              <td>0.040%</td>
              <td>2%</td>
            </tr>
            <tr>
              <td>中潛勢</td>
              <td>0.103%</td>
              <td>5%</td>
            </tr>
            <tr>
              <td>高潛勢</td>
              <td>0.211%</td>
              <td>10%</td>
            </tr>
          </tbody>
        </table>
      </div> */}
      {/* qa */}
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
          margin: "10px",
        }}
      >
        <img
          style={{
            width: "500px", // Specify your desired width
            maxWidth: "90%", // Use a percentage to maintain responsiveness
            height: "auto", // This ensures the aspect ratio is maintained
          }}
          src="/img/foundation.png"
          alt="foundation"
        />
        <p style={{ marginTop: "10px", fontSize: "0.8rem", color: "#666" }}>
          資料來源: 經濟部地質調查及礦業管理中心
        </p>
      </div> */}

      {/* <div style={{ margin: "10px 0px" }}>
        <div>請勾選以下問卷做進一步分析</div>
        <table>
          <thead>
            <tr>
              <th>選擇</th>
              <th>項目</th>
              <th>說明</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  type="checkbox"
                  name="q1"
                  checked={checkboxStates.q1}
                  onChange={handleCheckboxChange}
                />
              </td>
              <td>
                依據 921 地震後，88 年 12 月 29
                日公告修正之『建築物耐震設計規範與解 說』所設計之建築物。{" "}
              </td>
              <td>
                說明： 88 年 12 月 29
                日修正之建築物耐震設計規範，已要求建築物設計時
                需進行液化潛能評估並納入耐震設計中。{" "}
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  name="q2"
                  checked={checkboxStates.q2}
                  onChange={handleCheckboxChange}
                />
              </td>
              <td>地下室三層(含)以上之建築物。</td>
              <td>
                說明：地下室三層(含)以上之建築物，可能發生液化的地層已在施作地下室
                時已大部分挖除，且其基礎通常採用筏式基礎或樁基礎，因而發生液
                化時對建築物之危害較小。
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  name="q3"
                  checked={checkboxStates.q3}
                  onChange={handleCheckboxChange}
                />
              </td>
              <td>採用樁基礎之建築物。 </td>
              <td>
                說明：建築物若採用樁基礎且穿過液化地層，當地層產生液化時仍可以支撐
                建築物，對建築物之危害較小。
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  name="q4"
                  checked={checkboxStates.q4}
                  onChange={handleCheckboxChange}
                />
              </td>
              <td>地上三層(含)以下，採用筏式或版式基礎。 </td>
              <td>
                說明：由 921
                地震之經驗，在液化區三層樓以下且採用筏式或版式基礎的
                建築物，因液化而受損的情況都不嚴重。
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  name="q5"
                  checked={checkboxStates.q5}
                  onChange={handleCheckboxChange}
                />
              </td>
              <td>基礎下方已無會產生液化地層之建築物。</td>
              <td>
                說明：建築物因開挖或基礎加深，基礎已座落於不會產生液化的地層，如岩
                盤、卵礫石層、承載力足夠之黏土層，或地下水位很深的地層，建築
                物雖位於土壤液化潛勢區，也不會因液化而發生損壞。{" "}
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  name="q6"
                  checked={checkboxStates.q6}
                  onChange={handleCheckboxChange}
                />
              </td>
              <td>非以上情境。</td>
              <td>說明：</td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  name="q7"
                  checked={checkboxStates.q7}
                  onChange={handleCheckboxChange}
                />
              </td>
              <td>情境未知。</td>
              <td>說明：</td>
            </tr>
          </tbody>
        </table>
      </div> */}

      <div>
        <ul style={{ fontSize: "14px", fontWeight: "bold" }}>
          <li>
            土壤液化除所在位置的本身岩土性質外，地下水高度也會影響是否容易有土壤液化現象，地下水為越高，土壤液化機率越高；反之，土壤液化機率降低。
          </li>
          <li>
            每年發生土壤液化的機率: {liqProb?.a ? liqProb.a.toFixed(3) : "N/A"}
            (%)， 未來50年發生土壤液化的機率:{" "}
            {liqProb?.b ? liqProb.b.toFixed(3) : "N/A"} (%)
          </li>
          <li>
            每年發生土壤液化的工程損失:
            {report.risk_results.liguefaction[2][0] -
              report.risk_results.liguefaction[2][1]}
            ， 每年發生土壤液化的非工程損失:
            {report.risk_results.liguefaction[3][0] -
              report.risk_results.liguefaction[3][1]}
          </li>
          <li>
            發生土壤液化年期望損失
            {report.risk_results.liguefaction[5][0] -
              report.risk_results.liguefaction[5][1]}
            元/年，其中搬遷費用 、房租、營業損失未考慮
          </li>

          <li>
            依據經濟部地質調查及礦業管理中心資料，本建物所在位置土壤液化潛勢屬
            {liq_result[0]}潛勢。同時考量地震的影響，在本建物分級屬
            {liq_result[1]}級
          </li>
          <li>
            1999年12月29日修正的建築物耐震設計規範，以要求建築物設計時須進行液化潛能評估並納入耐震設計中。本建物於
            {productTargetInfo?.["buildingTime"]}
            年完工，因此建物的受液化的影響程度{riskfrombuildingtime}
          </li>
          <li>
            土壤液化潛勢圖呈現的潛勢，是根據建築法規的規範，以鑽探得到的地下地質資訊，加上地下水位的資料，計算在設計地震強度下，各地可能發生土壤液化的輕重程度。每個潛勢所代表的意義：
          </li>
          <li>低潛勢(綠)：設計地震發生時，地基可能無影響或輕微影響。</li>
          <li>中潛勢(黃)：設計地震發生時，地基可能輕微影響至中度影響。</li>
          <li>高潛勢(紅)：設計地震發生時，地基可能中度至嚴重影響。</li>
          <li>
            臺北市政府公開的土壤液化潛勢圖分為4級，圖資顯示在5~
            6級震度下，土壤發生液化的機率及嚴重程度分級，並以4種顏色作為判別：紅色區具有相對較高的土壤液化風險，強烈地震發生時，地基可能嚴重影響。黃色區具有相對次高的土壤液化風險，強烈地震發生時，地基可能中度影響。綠色區具有相對較低的土壤液化風險，強烈地震發生時，地基可能輕微影響。
          </li>
          <li>
            [註]設計地震為土壤液化評估所採用之地震設計，依建築法規選擇發生週期為每
            475年發生一次最大震度的地震，各地區地震造成的地表加速度值因地震之震源位置、震源機制、傳播路徑及基地之地盤性質等改變，而略有不同，一般約為
            0.24 g – 0.4 g。如臺北所採用之加速度值為 0.24 g，屬於5級震度，宜蘭為
            0.32 g ，屬於6級震度。
          </li>
        </ul>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
          margin: "10px",
        }}
      >
        <img
          style={{
            width: "500px", // Specify your desired width
            maxWidth: "90%", // Use a percentage to maintain responsiveness
            height: "auto", // This ensures the aspect ratio is maintained
          }}
          src="/img/liq1.png"
          alt=""
        />
        <p style={{ marginTop: "10px", fontSize: "0.8rem", color: "#666" }}>
          資料來源: 經濟部地質調查及礦業管理中心
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
          margin: "10px",
        }}
      >
        <img
          style={{
            width: "500px", // Specify your desired width
            maxWidth: "90%", // Use a percentage to maintain responsiveness
            height: "auto", // This ensures the aspect ratio is maintained
          }}
          src="/img/liq2.png"
          alt=""
        />
        <p style={{ marginTop: "10px", fontSize: "0.8rem", color: "#666" }}>
          資料來源: 經濟部地質調查及礦業管理中心
        </p>
      </div>
    </div>
  );
};

export default LiqResult;
