// src/features/waterProbData/waterProbDataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchData: {
    location: null,
    address: "",
  },
};

export const searchDataSlice = createSlice({
  name: "searchData",
  initialState,
  reducers: {
    setSearchData: (state, action) => {
      state.searchData = action.payload;
    },
    resetSearchData: (state) => {
      state.searchData = initialState.searchData;
    },
  },
});

export const { setSearchData, resetSearchData } = searchDataSlice.actions;

export default searchDataSlice.reducer;
