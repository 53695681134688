import React, { useState } from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import PaidReport from "./PaidReport_v2Trial";
import FreeReport from "./FreeReport_v2Trial";

const CustomTab = ({ label, active }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "bold",
        color: active ? "white" : "#4C839B", // Text color changes based on active state
        backgroundColor: active ? "#4C839B" : "transparent", // Background color changes based on active state
        border: "1px solid #4C839B",
        borderRadius: "2px",
        width: "100%", // Ensure the tab fills the container width provided by the parent
        padding: "10px 15px",
      }}
    >
      <span>{label}</span>
    </div>
  );
};

const SelectVersion = ({ report, productTargetInfo }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div style={{ width: "100%", padding: "10px" }}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="fullWidth" // Allow the tabs to auto-size
        indicatorColor="primary"
        textColor="primary"
        sx={{
          // width: "30%",
          width: {
            xs: "100%", // On extra-small and small screens, tabs take full width
            sm: "100%", // Adjust this as needed
            md: "30%", // On medium screens and above, tabs take 30% of width
          },

          ".MuiTabs-flexContainer": {
            // borderBottom: "2px solid #ccc",
          },
          ".MuiTab-root": {
            minWidth: 0, // Remove min-width
            padding: 0, // Remove padding
            marginRight: "0px", // Tabs touch each other
            "&:last-child": {
              borderRight: "none", // No right border for the last tab
            },
          },
        }}
      >
        <Tab label={<CustomTab label="免費版" active={activeTab === 0} />} />
        <Tab label={<CustomTab label="付費版" active={activeTab === 1} />} />
      </Tabs>
      {activeTab === 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <FreeReport report={report} />
          </Grid>
        </Grid>
      )}
      {activeTab === 1 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <PaidReport report={report} productTargetInfo={productTargetInfo} />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default SelectVersion;
