import React, { useState, useEffect, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./GooglePlacesAutocompleteHome.scss"; // Import the SCSS file for styling

const GooglePlacesAutocomplete = ({ onPlaceSelected, isMapLoaded }) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      libraries: ["places"],
    });

    loader.load().then(() => {
      const autocomplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current
      );
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        onPlaceSelected(place);
      });
      setAutocomplete(autocomplete);
    });
  }, []);

  return (
    <div className="search-container-home" style={{ position: "relative" }}>
      <TextField
        inputRef={autoCompleteRef}
        variant="outlined"
        placeholder="輸入地址按下搜尋..."
        disabled={!isMapLoaded} // Disable the input until the map is loaded
        className="search-input-home" // Add a class name for styling
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default GooglePlacesAutocomplete;
