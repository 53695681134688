import React from "react";
import "./About.scss";

const About = () => {
  return (
    <div className="about-container">
      <div className="title-container">
        <div className="report-h0">SAFEHOUSING</div>
        <h3 className="title-text">關於我們</h3>
        <hr className="title-divider" />
      </div>
      <div>
        安家百科專注於提供專業且精確的房屋風險資訊服務，幫助購房者在購房決策過程中做出更明智、更安全的選擇。我們的目標是讓每位買房者在掌握全面風險資訊的基礎上，保障自己的房屋投資並確保長期居住的安全與舒適。
      </div>

      <div className="about-section">
        <div className="about-image">
          <img src="/img/about01.jpg" alt="Vision" />
        </div>
        <div className="about-text">
          <div className="about-title">
            <div className="title-text">
              <h5 className="gray">我們的使命</h5>
              <h5 className="gray">&nbsp;Our Mission</h5>
            </div>
            <p className="second_color text-end">我們的目標是什麼？</p>
          </div>
          <p>
            在購房過程中，隱藏的風險往往被忽視，這些風險可能來自自然災害、環境變化或政策波動。安家百科的使命是讓這些風險不再成為未知數。我們致力於提供易於理解且值得信賴的風險評估報告，幫助購房者預見潛在問題，避免未來不必要的損失。
          </p>
          <br />
          <br />
        </div>
        <div className="small-word">
          <hr />
          <p>SAFEHOUSING</p>
        </div>
      </div>

      <div className="about-section reverse">
        <div className="about-image">
          <img src="/img/about01.jpg" alt="Services" />
        </div>
        <div className="about-text">
          <div className="about-title">
            <div className="title-text">
              <h5 className="gray">我們提供的服務</h5>
              <h5 className="gray">&nbsp;Our Services</h5>
            </div>
            <p className="second_color text-end">我們提供什麼服務？</p>
          </div>

          <ul>
            <li>
              自然災害風險評估：
              <ul style={{ paddingLeft: "20px", fontSize: "0.9em" }}>
                <li style={{ listStyle: "none" }}>
                  我們會評估房屋所在區域的地震、洪水、土壤液化等風險，讓您對房屋的安全性有全面了解。
                </li>
              </ul>
            </li>
            <li>
              專業報告定製：
              <ul style={{ paddingLeft: "20px", fontSize: "0.9em" }}>
                <li style={{ listStyle: "none" }}>
                  根據您的需求，提供詳細的房屋風險報告，讓您掌握所有關鍵數據。
                </li>
              </ul>
            </li>
          </ul>
          <br />
          <br />
        </div>
        <div className="small-word">
          <hr />
          <p>SAFEHOUSING</p>
        </div>
      </div>

      <div className="about-section">
        <div className="about-image">
          <img src="/img/about02.jpg" alt="Core Values" />
        </div>
        <div className="about-text">
          <div className="about-title">
            <div className="title-text">
              <h5 className="gray">我們的核心價值</h5>
              <h5 className="gray">&nbsp;Our Core Values</h5>
            </div>
            <p className="second_color text-end">我們相信什麼？</p>
          </div>
          <div style={{ padding: "0px 20px" }}>
            <ul>
              <li>
                專業性：我們的團隊由專業的數據分析師和風險評估專家組成，保證每份報告的準確性和可靠性。
              </li>
              <li>
                透明性：我們堅持透明化，確保購房者清楚了解每個風險評估過程。
              </li>
              <li>
                客戶至上:您的安全和利益是我們的首要任務。我們竭誠為每位購房者提供個性化的服務，確保您能夠在資訊充分的情況下安心購房。
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="about-section reverse">
        <div className="about-image">
          <img src="/img/about01.jpg" alt="Vision" />
        </div>
        <div className="about-text">
          <div className="about-title">
            <div className="title-text">
              <h5 className="gray">我們的願景</h5>
              <h5 className="gray">&nbsp;Our Vision</h5>
            </div>
            <p className="second_color text-end">我們希望達成什麼？</p>
          </div>
          <p>
            我們的願景是讓每個人都擁有保障其住房資產的能力。透過安家百科，我們致力於提供精確的風險分析和實用的避險建議，確保您能夠安心居住並有效管理風險。
          </p>
          <br />
          <br />
        </div>
        <div className="small-word">
          <hr />
          <p>SAFEHOUSING</p>
        </div>
      </div>
    </div>
  );
};

export default About;
