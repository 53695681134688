import React, { useEffect, useState } from "react";
import { Watermark } from "antd";

import {
  Button,
  Container,
  Grid,
  Box,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import "./RiskReport.scss";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../axios";
import SelectVersion from "./SelectVersion";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const RiskReport = () => {
  const { pkid } = useParams();
  const [report, setReport] = useState({});
  // State to hold the parsed product target info
  const [productTargetInfo, setProductTargetInfo] = useState(null);
  // console.log(productTargetInfo);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [messageIndex, setMessageIndex] = useState(0);
  const loadingMessages = [
    "載入淹水風險...",
    "載入地震風險...",
    "載入土壤液化風險...",
    "載入海平面上升風險...",
    "載入坡地風險...",
    "完成報告...",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (messageIndex < loadingMessages.length - 1) {
        setMessageIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(interval); // Clear interval when all messages are displayed
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setLoadingMessage(loadingMessages[messageIndex]);
  }, [messageIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      const fetchReport = async () => {
        try {
          const response = await axiosInstance.get(
            `shop/reports/?product_id=${pkid}`
          );
          if (response.data.length > 0) {
            setReport(response.data[0]);
            setLoading(false);
            clearInterval(interval); // Stop checking when report is found
          }
        } catch (error) {
          console.error("Error fetching the report:", error);
          setLoading(false);
          clearInterval(interval); // Stop checking on error
        }
      };

      if (loading) {
        fetchReport();
      }
    }, 3000); // Check every 3 seconds

    return () => clearInterval(interval);
  }, [pkid, loading]);

  // console.log("profile", profile);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const res = await axiosInstance.get("profile/me/");
        setProfile(res.data.profile);
      } catch (err) {
        console.error("Error fetching profile:", err);
        // Show the login/register dialog if fetching profile fails
        // setShowLoginDialog(true);
      }
    };
    fetchProfile();
  }, []);

  const CustomTab = ({ label, icon }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // Optionally adjust icon size here if it's a component
    const adjustedIcon = React.cloneElement(icon, {
      style: {
        // fontSize: isMobile ? "16px" : "24px",
        fontSize: isMobile ? "24px" : "36px", // Adjust the icon size based on screen size
        color: "black", // Set icon color to black
      },
    });

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontWeight: "bold",
          color: "black", // Set text color to black
        }}
      >
        {adjustedIcon}
        {!isMobile && <span style={{ marginLeft: 8 }}>{label}</span>}
      </div>
    );
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDownloadClick = async () => {
    try {
      // Ensure that pkid is a number
      const product_pkid = Number(pkid);
      if (!product_pkid) {
        console.error("Invalid pkid:", pkid);
        return;
      }

      const response = await axiosInstance.get(
        `risk/generate_report/${product_pkid}`,
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `report_${product_pkid}.docx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the report:", error);
    }
  };

  const preventionMeasures = [
    "熟知住所附近淹水情況，遠離淹水潛勢區。",
    "購買包括淹水保障的保險，以防意外發生。",
    "對於可能的淹水事件，提前制定緊急應對計劃。",
    "在家裡安裝防水設施，例如沙袋、防水門檻等。",
    "定期查看氣象預報，提前預警淹水可能。",
  ];

  // Effect to parse the product target info when report changes
  useEffect(() => {
    if (report && report.product && report.product.target_info) {
      try {
        const parsedTargetInfo = JSON.parse(report.product.target_info);
        setProductTargetInfo(parsedTargetInfo);
      } catch (error) {
        console.error("Error parsing target_info:", error);
        // Handle error (e.g., set default value, show error message)
      }
    }
  }, [report]);

  //get the report
  useEffect(() => {
    const getReport = async () => {
      try {
        const res = await axiosInstance.get(`shop/reports/?product_id=${pkid}`);
        setReport(res.data[0]);
        // console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getReport();
  }, []);

  if (!report || Object.keys(report).length === 0 || loading) {
    return (
      <div className="detail-root">
        <div className="detail-description">
          <div>
            <Container className="risk-report-loading" maxWidth="sm">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="50vh"
              >
                <CircularProgress />
                <Typography variant="h5" sx={{ ml: 2 }}>
                  {loadingMessage || "載入報告..."}
                </Typography>
              </Box>
            </Container>
          </div>
        </div>
      </div>
    );
  }

  // if (!report || Object.keys(report).length === 0) {
  //   return (
  //     <div className="detail-root">
  //       <div className="detail-description">
  //         <h2 className="section-heading">專屬報告仍在準備中，請耐心等候...</h2>
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <Container className="risk-report" sx={{ padding: "0px" }}>
      <Watermark
        // image="https://mdn.alipayobjects.com/huamei_7uahnr/afts/img/A*lkAoRbywo0oAAAAAAAAAAAAADrJ8AQ/original"
        // height={130}
        // width={130}
        content={[
          "安家百科版權所有",
          `使用者:${profile.username}`,
          `${profile.email}`,
          `${profile.phone_number}`,
        ]}
        zIndex={0}
      >
        <div className="detail-root">
          <Paper className="detail-paper" elevation={3}>
            <div className="detail-h0">SAFEHOUSING</div>
            <hr className="m-0" />
            <div className="detail-description">
              <h1 className="section-heading-1">安家百科-專屬報告</h1>
              <hr className="m-0" />
              <Typography variant="subtitle1" component="div" gutterBottom>
                {/* 產品代號: {report.id} */}
                產品編號: {report.pkid} 版本號: {report.version}
              </Typography>
              {/* <Typography variant="subtitle1" component="div" gutterBottom>
              版本號: {report.version}
            </Typography> */}
              <Button variant="contained" onClick={() => handleDownloadClick()}>
                {/* Download Report */}
                <Typography
                  variant="section"
                  component="div"
                  style={{ marginRight: "8px" }}
                >
                  下載報告
                </Typography>

                <CloudDownloadIcon />
              </Button>
            </div>
            <hr />
            <SelectVersion
              report={report}
              productTargetInfo={productTargetInfo}
            />

            <hr />
            <hr />

            <hr />

            <hr />
          </Paper>
        </div>
      </Watermark>
    </Container>
  );
};

export default RiskReport;
