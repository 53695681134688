import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./RiskMapAll.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const gs_username = process.env.REACT_APP_GEOSERVER_BASIC_USERNAME;
const gs_pass = process.env.REACT_APP_GEOSERVER_BASIC_PASSWORD;
mapboxgl.accessToken = mapboxtoken;

const RiskMapAll = () => {
  const { user } = useSelector((state) => state.auth);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [activeLayer, setActiveLayer] = useState(null);
  const [layerOpacity, setLayerOpacity] = useState(0.7); // Default to fully opaque
  const [legendUrl, setLegendUrl] = useState("");
  // Function to get the legend URL for a given layer
  const getLegendUrl = (layerName) => {
    return `${geoserver_url}geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layerName}`;
  };

  const layers = [
    {
      id: "extreme-rain-open",
      title: "極端降雨公開資料",
      wms:
        geoserver_url +
        `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:opendata6h350mm40m3857&FORMAT=image/png&TILED=true`,
      legend: getLegendUrl("staging:opendata6h350mm40m3857"),
    },

    {
      id: "extreme-rain",
      title: "極端降雨",
      wms:
        geoserver_url +
        // `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:taoyuan_1_v1_350mm6hr-max-water-depth_merge&FORMAT=image/png&TILED=true`,
        `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:fm_base_350mm6hr&FORMAT=image/png&TILED=true`,
    },

    {
      id: "liquefaction",
      title: "土壤液化",
      wms:
        geoserver_url +
        `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:tw_liq_all40m3857&FORMAT=image/png&TILED=true`,
        legend: getLegendUrl("staging:tw_liq_all40m3857"),
      },
    {
      id: "extreme-rain-road",
      title: "極端降雨道路",
      wms:
        geoserver_url +
        `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:ty1_350mm6hr_road0&FORMAT=image/png&TILED=true`,
        legend: getLegendUrl("staging:ty1_350mm6hr_road0"),
      },
    {
      id: "sea-level-rise",
      title: "海平面上升",
      wms:
        geoserver_url +
        `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:seal_level_from10m_ty3_1m_f&FORMAT=image/png&TILED=true`,
    },

    {
      id: "earthquake",
      title: "地震",
      wms:
        geoserver_url +
        `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:taoyuan_1_v1_350mm6hr-max-water-depth_merge&FORMAT=image/png&TILED=true`,
    },
  ];

  const switchBaseMap = (style) => {
    if (map.current) {
      map.current.setStyle(`mapbox://styles/mapbox/${style}`);

      // Once the style has loaded, re-add the WMS layers
      map.current.once("style.load", () => {
        addWMSLayers(); // This will ensure only the active layer is visible
      });
    }
  };

  const addWMSLayers = () => {
    layers.forEach((layer) => {
      // Only add the source if it doesn't already exist
      if (!map.current.getSource(layer.id)) {
        map.current.addSource(layer.id, {
          type: "raster",
          tiles: [layer.wms],
          tileSize: 256,
        });
      }

      // Only add the layer if it doesn't already exist
      if (!map.current.getLayer(layer.id)) {
        map.current.addLayer({
          id: layer.id,
          type: "raster",
          source: layer.id,
          paint: {
            "raster-opacity": layerOpacity, // use the current opacity
          },
          layout: {
            // Use the ternary operator to set visibility based on the active layer
            visibility: layer.id === activeLayer ? "visible" : "none",
          },
        });
      } else {
        // If the layer already exists, just update its visibility
        map.current.setLayoutProperty(
          layer.id,
          "visibility",
          layer.id === activeLayer ? "visible" : "none"
        );
      }
    });
  };

  const changeOpacity = (newOpacity) => {
    setLayerOpacity(newOpacity);
    layers.forEach((layer) => {
      if (map.current.getLayer(layer.id)) {
        map.current.setPaintProperty(layer.id, "raster-opacity", newOpacity);
      }
    });
  };

  useEffect(() => {
    // if (map.current) return; // Initialize map only once
    if (!map.current && mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [121.31552, 24.99337],
        zoom: 10,
        transformRequest: (url, resourceType) => {
          if (
            (resourceType === "Source" || resourceType === "Tile") &&
            url.indexOf(geoserver_url + `geoserver/staging/wms`) > -1
          ) {
            const username = gs_username;
            const password = gs_pass;
            const credentials = btoa(`${username}:${password}`);
            const headers = {
              Authorization: `Basic ${credentials}`,
            };

            return {
              url: url,
              headers: headers,
              credentials: "include", // Include cookies for cross-origin requests
            };
          }
        },
      });

      map.current.on("load", () => {
        addWMSLayers();
      });
    }

    // Cleanup function to run when the component unmounts
    // return () => {
    //   if (map.current) {
    //     map.current.remove();
    //   }
    // };
    // }, [layerOpacity]);
  }, []);

  const toggleLayer = (layerId) => {
    setActiveLayer(layerId);
    layers.forEach((layer) => {
      map.current.setLayoutProperty(
        layer.id,
        "visibility",
        layer.id === layerId ? "visible" : "none"
      );
    });
    const layer = layers.find((l) => l.id === layerId);
    if (layer) {
      setLegendUrl(layer.legend);
    }
  };

  if (!user) {
    // If no user is logged in, display a login message
    return (
      <div className="login-message">
        <p>請先登入</p>
        <Link to="/login">登入</Link>{" "}
        {/* Replace "/login" with your login route */}
      </div>
    );
  }

  return (
    <div className="riskmap-container">
      <div
        className="map-container"
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: "60vh",
        }}
      >
        {/* Map container covering the full width and 60vh height */}
        <div
          ref={mapContainer}
          style={{ width: "100%", height: "60vh", padding: "10px" }}
        />
        {/* <div className="map-container" ref={mapContainer} /> */}
        {/* Add legend display */}
        <div
          className="legend-container"
          style={{
            position: "absolute",
            bottom: "20px",
            right: "15px",
            border: "1px solid",
            padding: "2px",
            borderRadius: "5px",
          }}
        >
          {legendUrl && <img src={legendUrl} alt="Legend" />}
        </div>
        {/* Base Map Switcher */}
        <div
          id="menu"
          style={{
            position: "absolute",
            top: 10, // Adjust as needed for top spacing
            left: "50%", // Center horizontally
            transform: "translateX(-50%)", // Adjust for exact centering
            background: "#efefef",
            padding: "5px",
            zIndex: 1, // Ensure it's above the map
            border: "solid 1px",
            borderRadius: "1px",
          }}
        >
          <input
            id="streets-v12"
            type="radio"
            name="rtoggle"
            value="streets-v12"
            onChange={() => switchBaseMap("streets-v12")}
            defaultChecked
          />
          <label
            htmlFor="streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            街道
          </label>
          <input
            id="satellite-streets-v12"
            type="radio"
            name="rtoggle"
            value="satellite-streets-v12"
            onChange={() => switchBaseMap("satellite-streets-v12")}
          />
          <label
            htmlFor="satellite-streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            衛星
          </label>
          <input
            id="light-v11"
            type="radio"
            name="rtoggle"
            value="light-v11"
            onChange={() => switchBaseMap("light-v11")}
          />
          <label
            htmlFor="light-v11"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            白底
          </label>
          <input
            id="dark-v11"
            type="radio"
            name="rtoggle"
            value="dark-v11"
            onChange={() => switchBaseMap("dark-v11")}
          />
          <label
            htmlFor="dark-v11"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            黑底
          </label>
        </div>
      </div>

      <div className="sidebar">
        {layers.map((layer) => (
          <button
            key={layer.id}
            className={`sidebar-button ${
              activeLayer === layer.id ? "active" : ""
            }`}
            onClick={() => toggleLayer(layer.id)}
          >
            {layer.title}
          </button>
        ))}
        <div>
          <label htmlFor="opacity-slider">透明度:</label>
          <input
            id="opacity-slider"
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={layerOpacity}
            onChange={(e) => changeOpacity(parseFloat(e.target.value))}
          />
        </div>
      </div>
    </div>
  );
};

export default RiskMapAll;
