import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSearchData } from "../../redux/searchDataReducer";
import { setMapState } from "../../redux/mapStateReducer";
import GooglePlacesAutocomplete from "../../components/Map/GooglePlacesAutocompleteHome";
import "./SearchHome.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { motion } from "framer-motion";

const SearchHomeGoogle = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const dispatch = useDispatch();
  const [isMapLoaded, setIsMapLoaded] = useState(true);

  const handlePlaceSelected = (place) => {
    if (!place.geometry) {
      alert("No details available for input: '" + place.name + "'");
      return;
    }

    const coordinates = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    const address = place.formatted_address;
    setAddress(address);

    dispatch(setSearchData({ location: coordinates, address }));
    dispatch(
      setMapState({
        center: [coordinates.lng, coordinates.lat],
        zoom: 16,
        pitch: 0,
        bearing: 0,
      })
    );
  };

  const handleSearchClick = () => {
    if (!address) {
      alert("請輸入搜尋地址，再請進搜尋!");
      return;
    }
    navigate("/search_result");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleRegister = () => {
    navigate("/register");
  };

  return (
    <motion.div
      className="search-home-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }} // Reduced duration for a faster load
    >
      <motion.div
        className="overlay"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.7 }}
        transition={{ duration: 1 }} // Slightly faster
      />
      <motion.div
        className="content"
        initial={{ opacity: 0, scale: 0.95 }} // Simple scale and opacity instead of vertical translation
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6, ease: "easeOut" }} // Eased-out for a smooth effect
      >
        <motion.div
          className="search-box"
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }} // Reduced complexity
        >
          <motion.div
            initial="hidden"
            animate="visible"
            variants={{
              visible: {
                opacity: 1,
                transition: {
                  staggerChildren: 0.2, // Reduced staggerChildren for smoother transition on mobile
                },
              },
              hidden: { opacity: 0 },
            }}
          >
            <motion.h2
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              transition={{ duration: 0.5 }} // Simple opacity transition
            >
              房屋是您一生最重要的資產
            </motion.h2>
            <motion.h3
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              transition={{ duration: 0.5 }} // Simple opacity transition
            >
              立即了解風險，獲取專業避險策略，確保家人與財產安全！
            </motion.h3>
          </motion.div>
          <div className="geocoder-container">
            <GooglePlacesAutocomplete
              onPlaceSelected={handlePlaceSelected}
              isMapLoaded={isMapLoaded}
            />
            <motion.button
              className="search-button"
              onClick={handleSearchClick}
              disabled={!address}
              whileHover={{ scale: 1.05 }} // Reduced scale on hover for less intense effect
              whileTap={{ scale: 0.95 }}
            >
              搜尋
            </motion.button>
          </div>
        </motion.div>
        <motion.div
          className="login-container"
          initial="hidden"
          animate="visible"
          variants={{
            visible: { opacity: 1, transition: { staggerChildren: 0.3 } },
            hidden: { opacity: 0 },
          }}
        >
          <motion.button
            className="login-button"
            onClick={handleLogin}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0 },
            }}
            transition={{ duration: 0.5, ease: "easeOut" }} // Faster ease-out for smoother mobile transitions
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            登入
          </motion.button>
          <motion.button
            className="register-button"
            onClick={handleRegister}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0 },
            }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            註冊
          </motion.button>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default SearchHomeGoogle;
