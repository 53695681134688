import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  buildingType: 1,
  buildingTime: 2010,
  buildingMaterial: "SRC",
  // buildingMaterial: 1,
  floorNumber: 1,
  totalFloorNumber: 5,
  designPga: 0.25,
  maskingHeight: 0,
  buildingValue: 1000,
  businessLoss: 0,
  carValue: 50,
  furnitureLoss: 15,
  renovationValue: 100,
  pingAll: 50,
  pingIndoor: 20,  
  buildingFoundation: "未知(可重新評估)", // this is new field
  underground: 1, // this is new field
  test_migrate: 3
};

export const targetInfoSlice = createSlice({
  name: "targetInfo",
  initialState,
  reducers: {
    setTargetInfo: (state, action) => {
      // This action will allow you to update any field in the state
      const { field, value } = action.payload;
      state[field] = value;
    },
    resetTargetInfo: () => initialState, // Reset to initial state
  },
});

export const { setTargetInfo, resetTargetInfo } = targetInfoSlice.actions;

export default targetInfoSlice.reducer;
