import React, { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import "./Price.scss";
import PriceCard from "../PriceCard/PriceCard2";

const Price = () => {
  const [pointPackage, setPointPackage] = useState([]);
  useEffect(() => {
    const get_pointpackage = async () => {
      try {
        const res = await axiosInstance.get("shop/membership/point_packages/");
        setPointPackage(res.data);
      } catch (error) {
        console.log("error getting pointpackage");
      }
    };
    get_pointpackage();
    // console.log(pointPackage);
  }, []);
  return (
    <div style={{ backgroundColor: "#eeeeee" }}>
      <div className="title-container">
        <div className="report-h0">SAFEHOUSING</div>
        <h3 className="title-text">價格</h3>
        <hr className="title-divider" />
      </div>
      <div className="price-container">
        <PriceCard type="Basic" pointPackage={pointPackage[0]} />
        <PriceCard type="Premium" pointPackage={pointPackage[1]} />
        <PriceCard type="Ultra" pointPackage={pointPackage[2]} />
      </div>
    </div>
  );
};

export default Price;
