import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
// import "./Map.css";
import "./CityMap.scss";
import geojsonData from "./taiwan_city4326.geojson";
import { useNavigate } from "react-router-dom";

const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = mapboxtoken;

const CityMap = () => {
  const mapContainerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!geojsonData || !mapContainerRef.current) {
      return;
    }
    const map = new mapboxgl.Map({
      style: "mapbox://styles/mapbox/streets-v9",
      center: [120.86, 23.869], //22.99865, 120.21154  //25.04315, 121.5275  23.869, 120.860
      zoom: 1,
      container: mapContainerRef.current,
      antialias: true,
      dragPan: false,
    });

    const bounds = [
      [116.04673, 16.51441],
      [125.62939, 30.52261],
    ];

    // Set the bounds on the map instance
    map.setMaxBounds(bounds);

    map.on("load", () => {
      //serving-area
      map.addSource("counties", {
        type: "geojson",
        data: geojsonData,
      });

      // Add the fill layer for the counties
      map.addLayer(
        {
          id: "counties-fill",
          type: "fill",
          source: "counties",
          paint: {
            "fill-color": "#0080ff",
            "fill-opacity": 0.7,
          },
        },
        "building"
      );

      // Add the outline layer for the counties
      map.addLayer(
        {
          id: "counties-outline",
          type: "line",
          source: "counties",
          paint: {
            "line-color": "#000000",
            "line-width": 1,
          },
        },
        "building"
      );

      // Add the highlighted fill layer for the counties
      map.addLayer(
        {
          id: "counties-highlight",
          type: "fill",
          source: "counties",
          paint: {
            "fill-color": "#ff0000",
            "fill-opacity": 0.7,
          },
          filter: ["in", "COUNTYCODE", ""], // Replace '' with your desired COUNTYCODE
        },
        "building"
      );

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      map.on("mousemove", "counties-fill", (e) => {
        map.getCanvas().style.cursor = "pointer";
        const countyName = e.features[0].properties.COUNTYNAME;
        const countyCode = e.features[0].properties.COUNTYCODE; // Get COUNTYCODE of hovered feature
        const coordinates = e.lngLat;

        // Update the filter of the counties-highlight layer to include the hovered county
        map.setFilter("counties-highlight", ["==", "COUNTYCODE", countyCode]);

        popup.remove();
        popup
          .setLngLat(coordinates)
          .setHTML(`<h3>${countyName}</h3>`)
          .addTo(map);
      });

      map.on("mouseleave", "counties-fill", () => {
        map.getCanvas().style.cursor = "";
        // Reset the filter of the counties-highlight layer to exclude all features
        map.setFilter("counties-highlight", ["==", "COUNTYCODE", ""]);
        popup.remove();
      });

      map.on("click", "counties-fill", (e) => {
        const countyCode = e.features[0].properties.COUNTYCODE;
        // Navigate to another page with the countyCode as a parameter
        navigate(`/riskmapdraw/${countyCode}`);
        // console.log("goto" + countyCode);
      });
    });

    // // Clean up on unmount
    return () => map.remove();
  }, [geojsonData]);

  return (
    <div className="citymap-container">
      <div className="citymap-overlay">
        <h5 className="citymap-overlay__title">選擇欲購買圖資的縣市</h5>
        <p className="citymap-overlay__description">目前提供縣市: 桃園市</p>
      </div>
      <div ref={mapContainerRef} className="citymap-container2" />
    </div>
  );
};

export default CityMap;
