import React from "react";
import "./NotFound.scss";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <h1 className="not-found-title">404 Not Found</h1>
      <div className="not-found-icons">
        <span className="not-found-icon">💔</span>
        <span className="not-found-icon">😢</span>
      </div>
      <p className="not-found-message">The page you're looking for doesn't exist.</p>
    </div>
  );
};

export default NotFound;
