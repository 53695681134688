import React from "react";
// import Questionnaire from "../RiskMapAll/InclinedBuildings";
// import FloodMapboxSyncMaps_v2 from "../RiskMapAll/FloodMapboxSyncMaps_v2";
// import TrialReport from "../../pages/RiskReport/TrialReport";
// import GoldenCircle from "../GoldenCircle/GoldenCircle";
// import ProductCompare from "../Table/ProductCompare";
// import FloodRiskChart from "../FloodRiskChart/FloodRiskChartdummy";
// import Statistics from "../Statistics/Statistics";
import TestimonialSlider from "../Testimonials/Testimonials";
// import TeamAndAwards from "../Team/Team";
// import DepthProbBuildingFixedGate from "../Prob/DepthProbBuildingFixedGate";
// import DepthProbBuildingFixedclimate from "../Prob/DepthProbBuildingFixedclimate";
// import DepthProbTableBuildingFixedSealevel from "../Prob/DepthProbTableBuildingFixedSealevel";
// import DepthProbBuildingFixedSealevel from "../Prob/DepthProbBuildingFixedSealevel";
// import DepthProbBuildingFixedGateclimate from "../Prob/DepthProbBuildingFixedGateclimate";
// import EarthQuakeTable from "../Table/EarthQuakeTable";
// import LiqTable from "../Table/LiqTable";
import report from "./riskreport302.json"; // Import the JSON file
// import SummaryChartEarthquake from "../Charts/SummaryChartEarthquake";
// import SummaryChartLiq from "../Charts/SummaryChartLiq";
// import SummaryChartSealevel from "../Charts/SummaryChartSealevel";
// import SummaryChartAll from "../Charts/SummaryChartAll";
// import RiskMapClipSeaLevel from "../RiskMapAll/RiskMapClipSeaLevel";
// import SealevelResult_v2 from "../RiskMapAll/SealevelResult_v2";
import EerthQuakeResult_v2 from "../RiskMapAll/EerthquakeResult_v2";
import FloodResult_v2 from "../RiskMapAll/FloodResult_v2";
import HomeCompare from "../HomeCompare/HomeCompare";

const Test = () => {
  const waterProbData = report?.p0_200_interpolated;
  // sealevel_rise_masked_and_probabilities_all
  const prob_s1 = report?.risk_results?.["sealevel"][7][0][0];
  const prob_s2 = report?.risk_results?.["sealevel"][7][1][0];
  const prob_s3 = report?.risk_results?.["sealevel"][7][2][0];
  const prob_s4 = report?.risk_results?.["sealevel"][7][3][0];
  const d_all = report?.risk_results?.["sealevel"][8];
  const productTargetInfo = JSON.parse(report.product.target_info);
  const furnitureLoss = 0;

  return (
    <div style={{ marginTop: "80px" }}>
      {/* <RiskMapClipSeaLevel report={report} /> */}
      {/* <EerthQuakeResult_v2 report={report} /> */}
      {/* <SealevelResult_v2
        report={report}
        productTargetInfo={productTargetInfo}
        furnitureLoss={furnitureLoss}
      /> */}
      {/* <HomeCompare/> */}
      {/* <FloodResult_v2
        report={report}
        productTargetInfo={productTargetInfo}
        furnitureLoss={furnitureLoss}
      /> */}
      {/* <FloodMapboxSyncMaps_v2 report={report} /> */}
      {/* <LiqTable report={report} />
      <EarthQuakeTable report={report} /> */}
      {/* <TrialReport /> */}
      {/* <ProductCompare /> */}
      {/* <FloodRiskChart/> */}
      <TestimonialSlider/>
      {/* <Statistics /> */}
      {/* <SummaryChartEarthquake report={report} /> */}
      {/* <SummaryChartLiq report={report} /> */}
      {/* <SummaryChartAll report={report} /> */}
      {/* <TeamAndAwards /> */}
      {/* <DepthProbBuildingFixedGate report={report} waterProbData={waterProbData} /> */}
      {/* <DepthProbBuildingFixedclimate report={report} waterProbData={prob_s1} />

      <DepthProbBuildingFixedGateclimate
        report={report}
        waterProbData={prob_s1}
      />

      <DepthProbBuildingFixedSealevel report={report} waterProbData={prob_s1} /> */}
      {/* <DepthProbTableBuildingFixedSealevel
        report={report}
        waterProbData={prob_s1}
        d={d_all[0]}
      /> */}
    </div>
  );
};

export default Test;
