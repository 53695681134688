import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./SearchResultDraw.scss";
// import "./RiskMapAll.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import { addToCart } from "../../redux/cartReducer";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
// import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import area from "@turf/area";
import * as turf from "@turf/turf";
import { kinks } from "@turf/turf";
import TargetInfoInput from "../../pages/Select/TargetInfoInput";
import { resetTargetInfo } from "../../redux/targetInfoReducer";
import { setMapState } from "../../redux/mapStateReducer";
import GooglePlacesAutocomplete from "../../components/Map/GooglePlacesAutocomplete";

// const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
// const gs_username = process.env.REACT_APP_GEOSERVER_BASIC_USERNAME;
// const gs_pass = process.env.REACT_APP_GEOSERVER_BASIC_PASSWORD;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const baseURL = process.env.REACT_APP_API_URL;
mapboxgl.accessToken = mapboxtoken;

const SearchResultDraw = ({ initialCoor }) => {
  const navigate = useNavigate();
  const markerRef = useRef(null);
  const [coor, setCoor] = useState({ lng: 121.30128, lat: 24.99265 });
  const dispatch = useDispatch();
  const [pkid, setPkid] = useState(null);
  const searchData = useSelector((state) => state.searchData.searchData);
  const { countyCode } = useParams();
  const mapContainer = useRef(null);
  const mapRef = useRef(null); // New reference for the map instance
  const [isLoading, setIsLoading] = useState(false);
  const [clickedBuildingIds, setClickedBuildingIds] = useState(new Set());
  const [buildingInfo, setBuildingInfo] = useState([]);
  const [updatedBuildingInfo, setUpdatedBuildingInfo] = useState([]);
  const drawRef = useRef(null);
  const [targetArea, setTargetArea] = useState(0);
  const [drawingMode, setDrawingMode] = useState(false);
  // const [initialCoor, setInitialCoor] = useState({
  //   lng: 121.30128,
  //   lat: 24.99265,
  // });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [productName, setProductName] = useState("D-我的報告");
  const targetInfo = useSelector((state) => state.targetInfo);
  const [hullValid, setHullValid] = useState(true);
  const mapState = useSelector((state) => state.mapState);
  // console.log("mapState", mapState);

  // console.log("buildingInfo", buildingInfo);

  const validateGeometry = (geometry) => {
    // Ensure the geometry is a valid Polygon
    if (geometry.type !== "Polygon") {
      toast.error("請畫出有效的多邊形，不可只是線段!");
      setDrawingMode(false);
      return false;
    }

    // Get the coordinates of the polygon
    const coordinates = geometry.coordinates[0];

    // Check if there are at least 3 distinct points (excluding the closing point)
    const distinctCoordinates = coordinates.filter(
      (coord, index, self) =>
        index === self.findIndex((t) => t[0] === coord[0] && t[1] === coord[1])
    );

    // Ensure that there are at least 3 distinct points
    if (distinctCoordinates.length < 3) {
      toast.error("多邊形至少需要三個不同的頂點!");
      setDrawingMode(false);
      return false;
    }

    // Check distances between consecutive points to detect if the last point was automatically closed
    let hasValidDistance = true;
    for (let i = 0; i < distinctCoordinates.length - 1; i++) {
      const pointA = distinctCoordinates[i];
      const pointB = distinctCoordinates[i + 1];

      // Calculate the distance between consecutive points
      const distance = turf.distance(turf.point(pointA), turf.point(pointB), {
        units: "meters",
      });

      // If distance is less than a threshold (e.g., 1 meter), consider it invalid
      if (distance < 1) {
        hasValidDistance = false;
        break;
      }
    }

    if (!hasValidDistance) {
      toast.error("請不要繪製自動封閉的線段，至少需要三個有效的點!");
      setDrawingMode(false);
      return false;
    }

    // Calculate the area and check if it's below the minimum threshold (10 m²)
    const polygonArea = area(geometry);
    if (polygonArea < 10) {
      toast.error("繪製的區域面積至少需要10平方公尺!");
      setDrawingMode(false);
      return false;
    }

    // Detect self-intersections (kinks)
    const hasKinks = kinks(geometry);

    // Check if the polygon has self-intersections
    // if (hasKinks.features.length > 0) {
    //   toast.error("您的圖形包含自相交的邊!");
    //   setDrawingMode(false);
    //   return false;
    // }

    return true;
  };

  const handlePlaceSelected = (place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    setCoor({ lat, lng });

    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [lng, lat],
        zoom: 17,
      });

      if (markerRef.current) {
        markerRef.current.setLngLat([lng, lat]);
      } else {
        markerRef.current = new mapboxgl.Marker()
          .setLngLat([lng, lat])
          .addTo(mapRef.current);
      }
    }
  };

  const handleProductNameChange = (event) => {
    setProductName(event.target.value);
  };

  const switchBaseMap = (style) => {
    if (mapRef.current) {
      // Check if the style is a custom Mapbox style URL or a predefined style
      const isCustomStyle = style.startsWith("mapbox://styles/");
      const styleURL = isCustomStyle
        ? style
        : `mapbox://styles/mapbox/${style}`;
      mapRef.current.setStyle(styleURL);

      // mapRef.current.setStyle("mapbox://styles/mapbox/" + style);
      // mapRef.current.once("style.load", () => {
      //   // Re-add the buildings source and layer here
      //   addBuildingsLayer();
      // });
    }
  };

  const addBuildingsLayer = () => {
    if (!mapRef.current.getSource("buildings")) {
      // Add source for buildings
      mapRef.current.addSource("buildings", {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] }, // initial empty data
      });
    }

    if (!mapRef.current.getLayer("3d-buildings")) {
      // Add layer for buildings
      mapRef.current.addLayer({
        id: "3d-buildings",
        source: "buildings",
        type: "fill-extrusion",
        paint: {
          "fill-extrusion-color": "#aaa",
          // "fill-extrusion-color": "#242424",

          "fill-extrusion-height": 0,
          // "fill-extrusion-height": ["get", "build_h"],
          "fill-extrusion-base": 0,
          "fill-extrusion-opacity": 0.5,
        },
      });
    }

    // Call your function to fetch and set the data for buildings
    fetchBuildingsInView(mapRef.current);
  };

  useEffect(() => {
    const updatedBuildings = buildingInfo.map((building) => {
      // const geometry = JSON.parse(building.geometry);
      // Check if building.geometry is already an object
      const geometry =
        typeof building.geometry === "string"
          ? JSON.parse(building.geometry)
          : building.geometry;
      const area = turf.area(geometry); // Calculates the area in square meters
      return { ...building, area };
    });
    setUpdatedBuildingInfo(updatedBuildings);
  }, [buildingInfo]); // Dependency array ensures this runs only when buildingInfo changes

  //get the user
  useEffect(() => {
    const getPkid = async () => {
      try {
        const res = await axiosInstance.get("auth/users/me/");
        setPkid(res.data.pkid);
      } catch (err) {
        // console.error(err);
        toast.warning("您目前尚未登入喔!");
      }
    };
    getPkid();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the user is not logged in
    if (pkid === null) {
      toast.warning("您尚未登入！請先登入再進行此操作。");
      return;
    }

    if (buildingInfo.length === 0 || !buildingInfo[0].geometry) {
      toast.error("請先在地圖上畫出分析區域!");
      return;
    }
    if (targetArea > 50000) {
      toast.error(
        "請選擇小於50,000平方公尺的面積，或是聯繫客服取得客製化分析!"
      );
      return;
    }
    if (!hullValid) {
      toast.error(
        "選擇區域的長度或寬度需小於400公尺，或是聯繫客服取得客製化分析!"
      );
      return;
    }

    const geometry = buildingInfo[0].geometry;
    // Convert geometry to GeoJSON string
    const geoJsonGeometry = JSON.stringify(geometry);
    const build_h = 3 * targetInfo.totalFloorNumber;
    const desc = {
      searchData: searchData,
      final_location: {},
      choose_type: "draw",
      selectedBuildingInfo: {
        properties: {
          build_h: build_h,
        },
      }, // Stringify the building info
    };

    const product_title = productName;
    const formData = {
      title: product_title,
      name: productName,
      description: JSON.stringify(desc),
      hazard_type: "Flood",
      total_price: 500,
      radius: 100,
      created_by_customer: pkid,
      product_type: "manual",
      geo_data: geoJsonGeometry,
      target_info: JSON.stringify(targetInfo),
    };

    try {
      const response = await axiosInstance.post(`risk/products/`, formData);
      const data = response.data;

      dispatch(
        addToCart({
          id: data.pkid,
          title: data.title,
          desc: data.desc,
          price: data.total_price,
          quantity: 1,
        })
      );

      // toast.success("成功加入購物車!");
      toast.success("報告生產成功! 請到我的報告查看結果!");
    } catch (error) {
      // Check if the error response contains the max report generation error
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error.includes("您已達到最大生產報告次數")
      ) {
        toast.error(
          "您已達到最大生產報告次數! 一般用戶可免費產生5次報告，付費用戶(已購買會員點數)則可以產生20次報告!"
        );
      } else {
        toast.error("報告生產失敗，請重試或聯繫客服人員。");
      }
    }

    // // console.log(formData);
    // const response = await axiosInstance.post(`risk/products/`, formData);
    // const data = response.data;
    // // to redux cart
    // dispatch(
    //   addToCart({
    //     id: data.pkid,
    //     title: data.title,
    //     desc: data.desc,
    //     price: data.total_price,
    //     // img: data.attributes.img.data.attributes.url,
    //     quantity: 1,
    //   })
    // );

    toast.success("成功加入購物車!");
    // console.log(buildingInfo);
  };

  useEffect(() => {
    // Only initialize the map if it doesn't already exist
    if (mapRef.current) return; // This prevents multiple initializations

    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: mapState.center, // starting position [lng, lat]
      zoom: mapState.zoom, // starting zoom
      pitch: mapState.pitch, // starting pitch
      bearing: mapState.bearing, // starting bearing
      // pixelRatio: window.devicePixelRatio || 1
    });

    // if (mapRef.current) {
    //   mapRef.current.on("load", () => {
    //     //3d building
    //     addBuildingsLayer();
    //   });
    // }

    // mapRef.current.addControl(
    //   new MapboxGeocoder({
    //     accessToken: mapboxgl.accessToken,
    //     mapboxgl: mapboxgl,
    //     language: "zh_TW",
    //   }),
    //   "top-left"
    // );
    mapRef.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      }),
      "top-right"
    );
    mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-right");
    mapRef.current.addControl(new mapboxgl.FullscreenControl(), "top-right");

    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
      styles: [
        // ACTIVE (being drawn)
        // Line style
        {
          id: "gl-draw-line-active",
          type: "line",
          filter: [
            "all",
            ["==", "$type", "LineString"],
            ["!=", "mode", "static"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#000", // black
            "line-dasharray": [0.2, 2],
            "line-width": 2,
          },
        },
        // Polygon fill style
        {
          id: "gl-draw-polygon-fill",
          type: "fill",
          filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
          paint: {
            "fill-color": "#000", // black
            "fill-opacity": 0.1,
          },
        },
        // Polygon stroke style
        {
          id: "gl-draw-polygon-stroke-active",
          type: "line",
          filter: [
            "all",
            ["==", "$type", "Polygon"],
            ["==", "active", "true"],
            ["!=", "mode", "static"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#000", // black
            "line-width": 2,
          },
        },
        // Vertex point style
        {
          id: "gl-draw-polygon-and-line-vertex-active",
          type: "circle",
          filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
          ],
          paint: {
            "circle-radius": 5,
            "circle-color": "#000", // black
          },
        },
        // INACTIVE (not being drawn)
        // Line style
        {
          id: "gl-draw-line",
          type: "line",
          filter: [
            "all",
            ["==", "$type", "LineString"],
            ["==", "active", "false"],
            ["!=", "mode", "static"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#000", // black
            "line-dasharray": [0.2, 2],
            "line-width": 2,
          },
        },
        // Polygon stroke style
        {
          id: "gl-draw-polygon-stroke",
          type: "line",
          filter: [
            "all",
            ["==", "$type", "Polygon"],
            ["==", "active", "false"],
            ["!=", "mode", "static"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#000", // black
            "line-width": 2,
          },
        },
      ],
    });

    mapRef.current.addControl(drawRef.current);

    const deleteAllPolygons = () => {
      const allFeatures = drawRef.current.getAll();
      allFeatures.features.forEach((feature) => {
        if (feature.geometry.type === "Polygon") {
          drawRef.current.delete(feature.id);
        }
      });
    };

    // Event listener for draw.create
    mapRef.current.on("draw.create", (e) => {
      deleteAllPolygons(); // Ensure only one polygon exists

      // Add the newly drawn polygon
      const newPolygon = e.features[0];
      drawRef.current.add(newPolygon);

      // Update buildingInfo with the newly drawn polygon
      setBuildingInfo([newPolygon]);
    });

    // Add event listeners for draw.create and draw.delete
    mapRef.current.on("draw.create", updateArea);
    mapRef.current.on("draw.delete", updateArea);
    mapRef.current.on("draw.update", updateArea);

    // function updateArea(e) {
    //   const data = drawRef.current.getAll();
    //   setBuildingInfo(data.features);
    // }

    function updateArea(e) {
      const data = drawRef.current.getAll();
      if (data.features.length > 0) {
        const drawnPolygon = data.features[0]; // Assuming only one polygon is drawn at a time

        // Update buildingInfo with the newly drawn polygon
        setBuildingInfo([drawnPolygon]);

        // Calculate the bbox of the drawn polygon
        const bbox = turf.bbox(drawnPolygon);
        const bottomLeft = turf.point([bbox[0], bbox[1]]);
        const bottomRight = turf.point([bbox[2], bbox[1]]);
        const topLeft = turf.point([bbox[0], bbox[3]]);

        // Calculate width and height of the bbox in meters
        const width = turf.distance(bottomLeft, bottomRight, {
          units: "meters",
        });
        const height = turf.distance(bottomLeft, topLeft, { units: "meters" });

        // console.log(
        //   `Drawn Polygon BBox Width: ${width} meters, Height: ${height} meters`
        // );

        setHullValid(true);

        // Check if width or height is more than 400 meters
        if (width > 400 || height > 400) {
          toast.error(`選擇區域的長或寬需小於400公尺`);
          setHullValid(false);
        }
      }
    }

    mapRef.current.on("moveend", () => fetchBuildingsInView(mapRef.current));
    mapRef.current.on("moveend", () => {
      // Fetching the map's current state
      const center = mapRef.current.getCenter();
      const zoom = mapRef.current.getZoom();
      const pitch = mapRef.current.getPitch();
      const bearing = mapRef.current.getBearing();

      // Dispatching the new state to the Redux store
      dispatch(
        setMapState({
          center: [center.lng, center.lat],
          zoom,
          pitch,
          bearing,
        })
      );
    });

    // Zoom event listener
    // mapRef.current.on("zoom", () => {
    //   const currentZoom = mapRef.current.getZoom();
    //   // console.log("zoom level: ", currentZoom);
    //   if (currentZoom >= 15.5) {
    //     addBuildingsLayer();
    //   } else {
    //     // Optional: Remove the buildings layer or adjust its visibility
    //     // This is just an example to remove, adapt as needed
    //     if (mapRef.current.getLayer("3d-buildings")) {
    //       mapRef.current.removeLayer("3d-buildings");
    //       mapRef.current.removeSource("buildings");
    //     }
    //   }
    // });

    return () => {
      if (mapRef.current) {
        // Remove event listeners first
        mapRef.current.off("load");
        mapRef.current.off("moveend");
        // mapRef.current.off("click", "3d-buildings");

        // mapRef.current.off("draw.create", updateArea);
        // mapRef.current.off("draw.delete", updateArea);
        // mapRef.current.off("draw.update", updateArea);

        // Then remove the map
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [initialCoor]);

  useEffect(() => {
    // Function to update tooltips with Traditional Chinese translations
    const updateToolTipsToChinese = () => {
      // Mapping of English tooltips to Traditional Chinese
      const translations = {
        "Polygon tool (p)": "多邊形工具", // Example translation
        Delete: "刪除",
        // "Delete (Backspace)": "刪除 (Backspace)",
        // Add more translations as needed
      };

      // Select all elements that could contain tooltips and update their titles
      document.querySelectorAll("[title]").forEach((element) => {
        const title = element.getAttribute("title");
        if (translations[title]) {
          element.setAttribute("title", translations[title]);
        }
      });
    };

    // Call the function to update tooltips
    updateToolTipsToChinese();

    // Since tooltips might be dynamically generated, consider calling this function
    // after certain events or in intervals, depending on how Mapbox GL Draw works in your application
  }, []); // Empty dependency array means this effect runs once after the initial render

  const handleDrawPolygon = () => {
    // Enable drawing mode for polygon
    drawRef.current.changeMode("draw_polygon");
  };
  const handleResetDraw = () => {
    // Delete all features and reset buildingInfo
    drawRef.current.deleteAll();
    setBuildingInfo([]);
    drawRef.current.changeMode("simple_select"); // Change to default mode after reset
  };

  const fetchBuildingsInView = (map) => {
    const bounds = map.getBounds();
    setIsLoading(true);

    axios
      // .get(`${geoserver_url}geoserver/staging/ows`, {
      .get(`${baseURL}risk/geo/ows?`, {
        params: {
          service: "WFS",
          version: "1.0.0",
          request: "GetFeature",
          typeName: `staging:buildings_all3857_s`,
          bbox: `${bounds.getWest()},${bounds.getSouth()},${bounds.getEast()},${bounds.getNorth()}`,
          outputFormat: "application/json",
        },
        // auth: {
        //   username: gs_username,
        //   password: gs_pass,
        // },
      })
      .then((response) => {
        const data = response.data;
        // Assign uid as id for each feature
        data.features.forEach((feature) => {
          feature.id = feature.properties.uid;
        });
        map.getSource("buildings").setData(data);

        // Reapply 'clicked' state to buildings
        clickedBuildingIds.forEach((id) => {
          map.setFeatureState({ source: "buildings", id }, { clicked: true });
        });

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the GeoServer data:", error);
        setIsLoading(false);
      });
  };

  const calculateTotalArea = (features) => {
    let totalArea = 0;
    features.forEach((feature) => {
      if (
        feature.geometry.type === "Polygon" ||
        feature.geometry.type === "MultiPolygon"
      ) {
        totalArea += area(feature);
      }
    });
    return totalArea;
  };

  useEffect(() => {
    const totalArea = calculateTotalArea(buildingInfo);
    // console.log("Total area: ", totalArea); // You can remove this line later
    // Update the state or display this area in your component
    setTargetArea(totalArea);
    if (totalArea > 50000) {
      toast.error("您選擇的面積太大了，需小於50,000平方公尺.");
    }
  }, [buildingInfo]);

  const toggleDrawing = () => {
    const drawnFeatures = drawRef.current.getAll();
    // console.log("drawnFeatures", drawnFeatures);

    if (drawingMode && drawnFeatures.features.length > 0) {
      const newPolygon = drawnFeatures.features[0];
      // console.log("newPolygon", newPolygon);

      // console.log("validateGeometry", validateGeometry(newPolygon.geometry));

      // Validate the drawn polygon when the user finishes drawing
      if (!validateGeometry(newPolygon.geometry)) {
        // console.log("validateGeometry", validateGeometry(newPolygon.geometry));

        // If invalid, delete the drawn feature and show an error
        drawRef.current.delete(newPolygon.id);
        return;
      }
    }

    if (!drawingMode) {
      drawRef.current.changeMode("draw_polygon");
      setDrawingMode(true);
    } else {
      drawRef.current.changeMode("simple_select");
      setDrawingMode(false);
    }
  };

  // const toggleDrawing = () => {

  //   if (!drawingMode) {
  //     drawRef.current.changeMode("draw_polygon");
  //     setDrawingMode(true);
  //   } else {
  //     drawRef.current.changeMode("simple_select");
  //     setDrawingMode(false);
  //   }
  // };

  const deleteAll = () => {
    drawRef.current.deleteAll();
    setDrawingMode(false);
  };

  return (
    <div className="select-container">
      <p
        style={{
          fontSize: "12px",
        }}
      >
        一般用戶可產生5次免費版報告，付費用戶(已購買會員點數)則可以產生20次免費版報告!
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          borderRadius: "4px",
        }}
      >
        {/* Map container covering the full width and 60vh height */}
        <div
          ref={mapContainer}
          style={{
            width: "100%",
            height: "50vh",
            // padding: "10px",
            borderRadius: "4px",
          }}
        />
        <div style={{ position: "absolute" }} className="google-geocoder">
          <GooglePlacesAutocomplete
            onPlaceSelected={handlePlaceSelected}
            isMapLoaded={true}
          />
        </div>

        {/* Base Map Switcher */}
        <div
          id="menu"
          style={{
            position: "absolute",
            bottom: 10, // Adjust as needed for top spacing
            left: "50%", // Center horizontally
            transform: "translateX(-50%)", // Adjust for exact centering
            background: "#efefef",
            padding: "5px",
            zIndex: 1, // Ensure it's above the map
            border: "solid 1px",
            borderRadius: "4px",
          }}
        >
          <input
            id="streets-v12"
            type="radio"
            name="rtoggle"
            value="streets-v12"
            onChange={() => switchBaseMap("streets-v12")}
            defaultChecked
          />
          <label
            htmlFor="streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            街道
          </label>
          <input
            id="satellite-streets-v12"
            type="radio"
            name="rtoggle"
            value="satellite-streets-v12"
            onChange={() => switchBaseMap("satellite-streets-v12")}
          />
          <label
            htmlFor="satellite-streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            衛星
          </label>
          {/* <input
            id="custom-style"
            type="radio"
            name="rtoggle"
            value="mapbox://styles/wayne32545/cluhyupep02qz01oi7lj73arv"
            onChange={() =>
              switchBaseMap(
                "mapbox://styles/wayne32545/cluhyupep02qz01oi7lj73arv"
              )
            }
          />
          <label
            htmlFor="custom-style"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            黑底
          </label> */}
        </div>
      </div>
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            // position: "absolute",
            right: "5px",
            fontSize: "12px",
            // top: "5px"
          }}
        >
          3D底圖來源：內政部國土測繪中心
        </p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          // fontWeight: "bold",
        }}
      >
        <Link
          className="link"
          to={`/search_result`}
          // style={{
          //   color: "red",
          // }}
        >
          點我
        </Link>
        返回點選建物模式
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px 0",
          flexDirection: "row",
          gap: "10px",

          // margin: "0 10px",
        }}
      >
        <div style={{ padding: "10px 0px 0px", fontWeight: "bold" }}>
          {drawingMode ? (
            <p>在地圖上畫出分析區域，繪製完成後請點選完成繪圖</p>
          ) : (
            <p>點選開始繪圖畫出分析區域</p>
          )}
        </div>
        <button className="add" onClick={toggleDrawing}>
          {drawingMode ? "完成繪圖" : "開始繪圖"}
        </button>
        <button className="add" onClick={deleteAll}>
          刪除繪圖
        </button>
      </div>

      <TargetInfoInput />
      <div className="submit-container">
        <div
          style={
            {
              // backgroundColor: "white", // Corrected the property name
              // fontSize: "small",
              // overflowY: "auto", // If you want a scrollbar
            }
          }
        >
          <div
            className="stack"
            style={{
              display: "flex",
              alignItems: "start",
              gap: "5px",
              padding: "10px",
              // flexDirection: "column",
              flexDirection: "row",
            }}
          >
            <label
              htmlFor="product-name"
              style={{
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              名稱:{" "}
            </label>
            <input
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                maxWidth: "150px",
              }}
              type="text"
              id="product-name"
              value={productName}
              onChange={handleProductNameChange}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <button
                className="add"
                onClick={() => dispatch(resetTargetInfo())}
              >
                重置參數
              </button>
              <button className="add" onClick={handleSubmit}>
                {/* 加入購物車 */}
                生產報告
              </button>
            </div>
          </div>
        </div>

        {/* Right part of the second row with a flex ratio of 1 */}
        <div
          style={{
            // flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "10px",
          }}
        >
          <p>
            <strong>
              分析總面積:{calculateTotalArea(buildingInfo).toFixed(2)} m²
            </strong>
            {/* <strong> 會員點數: {500} 點</strong> */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SearchResultDraw;
