import React, { useEffect, useState } from "react";

const Summary = ({ report, productTargetInfo, furnitureLoss }) => {
  // const [furnitureLoss, setFurnitureLoss] = useState("");

  // useEffect(() => {
  //   // Check if productTargetInfo and report are not undefined
  //   if (productTargetInfo && report) {
  //     if (productTargetInfo["floorNumber"] > 1) {
  //       setFurnitureLoss(
  //         `您物件位於本建物的${productTargetInfo["floorNumber"]}樓，因此不會受到淹水影響。`
  //       );
  //     } else {
  //       setFurnitureLoss(
  //         `您物件位於本建物的1樓，家俱家電可能每年有${report.risk_results["flood"][2]}元的期望損失。`
  //       );
  //     }
  //   }
  // }, [productTargetInfo, report]); // Add productTargetInfo and report to the dependency array

  return (
    <div>
      <ul className="content-list" style={{ listStyleType: "disc" }}>
        <li>
          依據本系統分析，本建物一樓地板比人行道高
          {productTargetInfo?.["maskingHeight"]}
          公分，本建物一樓每年
          <span style={{ fontSize: "16px", color: "red" }}>
            大於10公分的淹水機率
            {report.p0_200_interpolated["10cm"]}％
          </span>
          。{furnitureLoss}
          考量不同淹水深度與對應的發生機率，此建物的汽機車每年有
          <span style={{ fontSize: "16px", color: "red" }}>
            {report.risk_results["flood"][1]}
          </span>
          元的期望損失，
          建議可在汽機車出入口、較低的通風口、人員出入口在大豪雨期間加裝防水閘版，以及購買淹水保險。可能保險費用約落在
          <span style={{ fontSize: "16px", color: "red" }}>
            {(report.risk_results["flood"][1] * 1.2).toFixed(0)}～
            {(report.risk_results["flood"][1] * 1.5).toFixed(0)}
          </span>
          元/年為合理的保險費用。
        </li>

        <li>
          <span style={{ fontSize: "16px", fontWeight: "bolder" }}>
            地震:
            <br />
          </span>
          依據本系統分析顯示，本建物所在位置，未來50年有10％的機率發生
          {report.risk_results["earthquake"][2][0]}g（中央氣象署定義
          {report.risk_results["earthquake"][3][0]}）與2％的機率發生
          {report.risk_results["earthquake"][2][1]}g （中央氣象署定義
          {report.risk_results["earthquake"][3][1]}
          ）的地震，考量不同震度對建物所造成的損失比率、以及地震發生機率，本建物的年期望損失為
          <span style={{ fontSize: "16px", color: "red" }}>
            {report.risk_results["earthquake"][0]}
          </span>
          元，建議可加保超額地震險、擴大地震險。、輕損地震險。
          {/* 可能保險費用約落在
          <span style={{ fontSize: "16px", color: "red" }}>
            {(report.risk_results["earthquake"][0] * 1.2).toFixed(0)}～
            {(report.risk_results["earthquake"][0] * 1.5).toFixed(0)}
          </span>
          元/年為合理的保險費用。 */}
          <br />
        </li>
        <li>
          <span style={{ fontSize: "16px", fontWeight: "bolder" }}>
            土壤液化:
            <br />
          </span>
          依據經濟部地質調查及礦業管理中心資料，本建物所在位置土壤液化潛勢屬{" "}
          {report.risk_results["liguefaction"][0]}潛勢。 本建物的基礎為{" "}
          <span style={{ fontSize: "16px", color: "red" }}>XXX(或未知)</span>
          ，建造時間為
          <span style={{ fontSize: "16px", color: "red" }}>
            {productTargetInfo?.["buildingTime"]}
          </span>
          年，可能需要注意土壤液化對建物的影響，建議可進一步找土木技師工會進行專業判斷。
          本建物之土壤液化年期望損失為
          <span style={{ fontSize: "16px", color: "red" }}>
            {report.risk_results["liguefaction"][5][0]}~
            {report.risk_results["liguefaction"][5][1]}元
          </span>
          <br />
        </li>
        <li>
          <span style={{ fontSize: "16px", fontWeight: "bolder" }}>
            坡地災害:
            <br />
          </span>
          依據農業部農村發展及水土保持署資料，本建物所在位置離既有崩坍區、本系統分析土石流潛勢區最短距離為
          {report.risk_results["landslide"][0] / 1000}公里，屬第
          {report.risk_results["landslide"][1]}級安全區域。
          本建物之坡地災害年期望損失為
          <span style={{ fontSize: "16px", color: "red" }}>
            {report.risk_results["landslide"][2]}元
          </span>
        </li>
        <li>
          <span style={{ fontSize: "16px", fontWeight: "bolder" }}>
            海平面上升、海嘯:
            <br />
          </span>
          依據本系統分析顯示，本建物所在位置在海平面上升1.2m~4.7m皆無淹水潛勢。且非位於海嘯影響區域。
          {/* 本建物之海平面上升、海嘯年期望損失為<span style={{ fontSize: "16px", color: "red" }}>
            {report.risk_results["sealevel"][2]}
          </span> */}
          <br />
        </li>
        <li>
          <span style={{ fontSize: "16px", fontWeight: "bolder" }}>
            安家建議/保險連結:
            <br />
          </span>
          依本系統分析結果顯示，本建物可能會有淹水而造成損失的機率，建議除加強住家防水安全外，可以增加保險，藉以保障財產安全。此外，地震風險保額仍有不足的現象，建議可加保超額與擴大地震險。
        </li>
        {/* <li>
        限制/說明:
        分析模型之詳細設置、假設、用途限制與使用者權利義務。
      </li> */}
      </ul>
    </div>
  );
};

export default Summary;
