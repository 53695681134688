import React from "react";
// import MapMove from "../Map/MapMove";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
import Slider3 from "../Slider/Slider3";
import MapMoveCompare from "../../components/Map/MapMoveCompare";
import "./Showcase.scss";
import { Link } from "react-router-dom";

const Showcase = () => {
  return (
    <>
      <div className="showcase">
        <div className="showcase-container">
          <div className="showcase-slider3">
            <Slider3 />
          </div>
          <div className="showcase-mapmove">
            {/* <Card sx={{ maxWidth: 345 }}> */}
            <Card>
              {/* <CardMedia
                sx={{ height: 290 }}
                // sx={{ height: "100%" }}
                image="/img/flood_car.jpeg"
                title="green iguana"
              /> */}
              <CardContent>
                {/* <Typography gutterBottom variant="h4" component="div">
                  安家置產，我們如何找到安居的所在?
                </Typography> */}
                <h3 className="primary_color mb-4 w-100">
                  安家置產時，我們該如何找到安居的所在?
                </h3>
                <p>
                  <strong>
                    您正打算投資大筆費用購置房產，那麼如何確保其遠離災害的風險呢?
                    <br />
                    您的全新愛車，又該如何找到最安全的停放地點?
                    <br />
                    公開的災害潛勢圖，真的足以讓您作出明智的決策嗎?
                    <br />
                    為了解答所有的困擾與疑問，安家百科匯聚了眾多災害防護領域的專家，採用全球最尖端的災害模擬與分析技術，無懼成本，旨在為每位顧客評估他們的安居地點，確保他們安心置產的理想場所。
                  </strong>
                </p>
              </CardContent>
              <CardActions>
                {/* <Button size="small">分享</Button> */}
                <Link to="/Report">
                  {/* <Link className="link" to="/Report"> */}
                  <Button
                    className="primary_bg white p-2 pe-3 ps-3"
                    size="small"
                  >
                    了解更多
                  </Button>
                </Link>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
      <div className="showcase">
        {/* <div className="title flex-col align-items-center position-relative mt-150">
          <div className="h0">SHOWCASE</div>
          <h3 className="text-center w-100">情境展示</h3>
          <hr className="m-0"></hr>
        </div> */}
        <div className="title-container">
          <div className="report-h0">SAFEHOUSING</div>
          <h3 className="title-text">情境展示</h3>
          <hr className="title-divider" />
        </div>
        <h5 className="col-12 col-xl-10 text-md-center mt-3 mb-4">
          左為公開資料350mm6hr降雨情境，右為本產品相同情境計算結果
        </h5>
        <div className="showcase-container">
          <MapMoveCompare />
        </div>
      </div>
    </>
  );
};

export default Showcase;
