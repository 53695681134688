import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
import axiosInstance from "../../axios";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { Container } from "@mui/material";
import * as turf from "@turf/turf";
import "./ProductMap.scss";

// const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = mapboxtoken;

const ProductMap = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const popup = new mapboxgl.Popup({
    closeOnClick: false,
    className: "custom-popup my-unique-popup", // Add a unique class name
    offset: 25, // Adjust based on your needs
    closeButton: true,
  });

  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axiosInstance.get("risk/user-products/");
        // Sort the products based on the 'created_at' field in descending order
        const sortedProducts = res.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setData(sortedProducts);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data.length === 0) return;
    if (map.current) return; // Initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11", // Specify the map style
      center: [121.299722, 24.992377], // Initial map center coordinates
      zoom: 11,
    });

    map.current.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        language: "zh_TW",
      }),
      "top-left"
    );

    map.current.addControl(new mapboxgl.NavigationControl(), "top-right");
    map.current.addControl(new mapboxgl.FullscreenControl(), "top-right");

    map.current.on("load", () => {
      const bounds = new mapboxgl.LngLatBounds();

      data.forEach((item) => {
        const geometry = item.calculated_geometry;
        const center = turf.centerOfMass(geometry).geometry.coordinates;
        const sourceID = item.pkid.toString(); // Ensure this is a stringv

        console.log(geometry);

        // // Add marker at the geometry's center with click event to show the popup
        // const marker = new mapboxgl.Marker()
        //   .setLngLat(center)
        //   .addTo(map.current);

        // marker.getElement().addEventListener("click", () => {
        //   const { name, pkid } = item; // Assuming these properties exist in your item object
        //   const html = `<strong>${name}</strong><p>編號: ${pkid}</p><a href="/riskreport/${pkid}">看報告</a>`;
        //   popup.setLngLat(center).setHTML(html).addTo(map.current);
        // });

        // Create a custom marker element
        // const el = document.createElement("div");
        // el.className = "marker"; // Add a class for CSS styling
        // el.style.cursor = "pointer"; // Ensure cursor changes to pointer on hover

        //logo32
        // Create a custom marker element with your own image
        const el = document.createElement("div");
        el.className = "custom-marker"; // Add this class for additional styling if needed
        el.style.backgroundImage = "url(/img/building_24736.png)"; // Path to your marker image
        // el.style.backgroundImage = "url(/img/logo_word12.png)"; // Path to your marker image
        el.style.width = "40px"; // Adjust size as needed
        el.style.height = "40px"; // Adjust size as needed
        el.style.backgroundSize = "cover"; // Cover the div area with your image

        // el.style.backgroundColor = "white"; // Cover the div area with your image
        el.style.cursor = "pointer"; // Cursor pointer on hover

        const marker = new mapboxgl.Marker(el)
          .setLngLat(center)
          .addTo(map.current);

        // Add click event listener to the marker
        el.addEventListener("click", () => {
          const { name, pkid } = item; // Assuming these properties exist in your item object
          const html = `<div>
          <strong>${name}</strong>
          <p>編號: ${pkid}</p>
          <a href="/riskreport/${pkid}" target="_blank">看報告</a>
        </div>`;
          popup.setLngLat(center).setHTML(html).addTo(map.current);
        });

        // Extend the bounds to include the current geometry
        bounds.extend(turf.bbox(geometry));

        // Add a new source for each polygon
        map.current.addSource(sourceID, {
          type: "geojson",
          data: {
            type: "Feature",
            properties: item,
            geometry: geometry,
          },
        });

        // Add a line layer for the borders
        map.current.addLayer({
          id: `${sourceID}-line`,
          type: "line",
          source: sourceID,
          paint: {
            "line-color": "#000000",
            "line-width": 2,
            "line-dasharray": [2, 2],
          },
        });

        // Add a fill layer that's initially transparent or very low opacity
        map.current.addLayer({
          id: `${sourceID}-fill`,
          type: "fill",
          source: sourceID,
          paint: {
            "fill-color": "#888888", // Use a neutral color but very low opacity
            "fill-opacity": 0.1, // Adjust opacity as needed
          },
        });

        // Mouse enter event to change fill to red for the entire geometry
        map.current.on("mouseenter", `${sourceID}-fill`, () => {
          map.current.getCanvas().style.cursor = "pointer";
          map.current.setPaintProperty(
            `${sourceID}-fill`,
            "fill-color",
            "#ff0000"
          );
          map.current.setPaintProperty(`${sourceID}-fill`, "fill-opacity", 0.4);
        });

        map.current.on("mouseleave", `${sourceID}-fill`, () => {
          map.current.getCanvas().style.cursor = "";
          map.current.setPaintProperty(
            `${sourceID}-fill`,
            "fill-color",
            "#888888"
          );
          map.current.setPaintProperty(`${sourceID}-fill`, "fill-opacity", 0.1);
        });

        // Ensure the click event setup is correct
        map.current.on("click", `${sourceID}-fill`, (e) => {
          if (e.features.length > 0) {
            const coordinates = e.lngLat;
            const { name, pkid } = e.features[0].properties;
            const html = `<div>
            <strong>${name}</strong>
            <p>編號: ${pkid}</p>
            <a href="/riskreport/${pkid}" target="_blank">看報告</a>
          </div>`;

            // Ensure the popup is set with the correct content and added to the map
            popup.setLngLat(coordinates).setHTML(html).addTo(map.current);
          }
        });
      });

      // Adjust map view to fit all geometries
      map.current.fitBounds(bounds, {
        padding: 60, // or any other value you see fit
      });
    });

    return () => {
      // Cleanup function
      if (map.current) {
        map.current.remove(); // This removes the map and frees up resources
        map.current = null; // Reset the ref to null
      }
    };
  }, [data]); // Depend on the data prop

  return (
    <div style={{ margin: "20px" }}>
      <Container sx={{ padding: "0px" }}>
        <div
          ref={mapContainer}
          style={{ width: "100%", height: "80vh", borderRadius: "5px" }}
        />
      </Container>
    </div>
  );
};

export default ProductMap;
