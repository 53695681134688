import React, { useState } from "react";

// import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setTargetInfo, resetTargetInfo } from "../../redux/targetInfoReducer";
// import axiosInstance from "../../axios";
// import { toast } from "react-toastify";

import Box from "@mui/material/Box";
import { Grid } from "@mui/material";

const TargetInfoInput = () => {
  const dispatch = useDispatch();
  const [pkid, setPkid] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const targetInfo = useSelector((state) => state.targetInfo);

  const handleBuildingTypeChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "buildingType",
        value: parseFloat(event.target.value),
      })
    );
  };
  const handleBuildingTimeChange = (event) => {
    // // Parse the input value as an integer, default to 0 if not a number
    let inputTime = parseInt(event.target.value) || "";
    // // If inputTime is negative, reset it to
    inputTime = Math.max(inputTime, 0);

    dispatch(
      setTargetInfo({
        field: "buildingTime",
        value: parseFloat(event.target.value),
        // value: parseFloat(inputTime),
      })
    );
  };

  const handleBuildingMaterialChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "buildingMaterial",
        value: event.target.value, // No need to parse here since it's a string
      })
    );
  };
  const handleBuildingFoundationChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "buildingFoundation",
        value: event.target.value, // No need to parse here since it's a string
      })
    );
  };
  const handleUndergroundChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "underground",
        value: event.target.value, // No need to parse here since it's a string
      })
    );
  };

  const handleTotalfloorNumberChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "totalFloorNumber",
        value: parseFloat(event.target.value),
      })
    );
  };
  const handleFloorNumberChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "floorNumber",
        value: parseFloat(event.target.value),
      })
    );
  };
  const handleDesignPgaChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "designPga",
        value: parseFloat(event.target.value),
      })
    );
  };
  const handleMaskingHeightChange = (event) => {
    // Parse the input value as an integer, default to 0 if not a number
    let inputHeight = parseInt(event.target.value) || "";
    // If inputHeight is negative, reset it to 0
    inputHeight = Math.max(inputHeight, 0);

    dispatch(
      setTargetInfo({
        field: "maskingHeight",
        value: parseInt(event.target.value),
        // value: parseFloat(inputHeight),
      })
    );
  };
  const handleBuildingValueChange = (event) => {
    // Parse the input value as an integer, default to 0 if not a number
    let inputValue = parseInt(event.target.value) || "";
    // If inputHeight is negative, reset it to 0
    inputValue = Math.max(inputValue, 0);
    dispatch(
      setTargetInfo({
        field: "buildingValue",
        value: parseInt(event.target.value),
        // value: parseFloat(inputValue),
      })
    );
  };
  const handleBusinessLossChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "businessLoss",
        value: parseFloat(event.target.value),
      })
    );
  };
  const handleCarValueChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "carValue",
        value: parseFloat(event.target.value),
      })
    );
  };
  const handleFurnitureLossChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "furnitureLoss",
        value: parseFloat(event.target.value),
      })
    );
  };
  const handleRenovationChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "renovationValue",
        value: parseFloat(event.target.value),
      })
    );
  };
  const handlePingAllChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "pingAll",
        value: parseFloat(event.target.value),
      })
    );
  };
  const handlePingIndoorChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "pingIndoor",
        value: parseFloat(event.target.value),
      })
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // height: "100%",
        // padding: "10px",
        position: "relative",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <div style={{ padding: "10px" }}>
          <ul
            className="content-list"
            style={{ listStyleType: "disc", fontWeight: "bold" }}
          >
            {/* <li>步驟:</li> */}
            <li>1. 請填入分析建物之基本資料</li>
            <li>
              2. 請在地圖上選擇建築物範圍，或切換自行畫圖分析
              (自行畫圖時，請依建築物投射地面的形狀為主，過大的範圍會影響分析結果)
            </li>
          </ul>
          <hr />

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <div
                style={{ gap: "5px", display: "flex", flexDirection: "column" }}
              >
                <div
                  style={{
                    marginRight: "10px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  建物資訊輸入
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    // gap: "5px",
                    flexDirection: "row",
                  }}
                >
                  <label
                    htmlFor="designPga"
                    style={{
                      marginRight: "5px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    建築抗震設計(g)，或不輸入，系統將採用抗震設計規範值:
                  </label>
                  <input
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "60px",
                    }}
                    id="designPga"
                    type="number"
                    min="0"
                    step="0.01"
                    value={targetInfo.designPga || ""}
                    onChange={handleDesignPgaChange}
                    // defaultValue={0.25}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    // gap: "5px",
                    flexDirection: "row",
                  }}
                >
                  <label
                    htmlFor="totalFloorNumber"
                    style={{
                      marginRight: "5px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    建物總樓層數:
                  </label>
                  <input
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "60px",
                    }}
                    id="totalFloorNumber"
                    type="number"
                    min="1"
                    step="1"
                    // value={targetInfo.totalFloorNumber}
                    value={targetInfo.totalFloorNumber || ""} // Use empty string when null
                    onChange={handleTotalfloorNumberChange}
                    // defaultValue={5}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    // gap: "5px",
                    flexDirection: "row",
                  }}
                >
                  <label
                    htmlFor="floorNumber"
                    style={{
                      marginRight: "5px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    建物位於幾樓:
                  </label>
                  <input
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "60px",
                    }}
                    id="floorNumber"
                    type="number"
                    min="1"
                    step="1"
                    value={targetInfo.floorNumber || ""}
                    onChange={handleFloorNumberChange}
                    // defaultValue={2}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    // alignItems: "start",
                    gap: "5px",
                    flexDirection: "row",
                  }}
                >
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginRight: "5px",
                    }}
                    htmlFor="building-type"
                  >
                    建物種類:
                  </label>
                  <select
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      maxWidth: "200px", // Adjust the max width as needed
                      overflowX: "auto", // Enable horizontal scrolling if necessary
                    }}
                    id="building-type"
                    // value={targetInfo.buildingType}
                    value={targetInfo.buildingType || ""}
                    onChange={handleBuildingTypeChange}
                  >
                    <option value={1.25}>
                      公共建築(活動中心、博物館、百貨公司、大賣場、電影院、車站等)
                    </option>
                    <option value={1}>住宅</option>
                    <option value={1.5}>
                      政府辦公室、醫院、學校、電廠、水廠等
                    </option>
                    <option value={1.5}>油槽、加油站、化學廠房</option>
                  </select>
                </div>
                <div
                  style={{
                    display: "flex",
                    // alignItems: "start",
                    gap: "5px",
                    flexDirection: "row",
                  }}
                >
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginRight: "5px",
                    }}
                    htmlFor="building-material"
                  >
                    建物材質:
                  </label>
                  <select
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                    id="building-material"
                    // value={targetInfo.buildingMaterial}
                    value={targetInfo.buildingMaterial || "SRC"}
                    onChange={handleBuildingMaterialChange}
                  >
                    <option value="SRC">SRC</option>
                    <option value="RC">RC</option>
                    <option value="磚造">磚造</option>
                    <option value="加強磚造">加強磚造</option>
                    <option value="木材">木材</option>
                  </select>
                </div>
                <div
                  style={{
                    display: "flex",
                    // alignItems: "start",
                    gap: "5px",
                    flexDirection: "row",
                  }}
                >
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginRight: "5px",
                    }}
                    htmlFor="foundation"
                  >
                    建物基礎:
                  </label>
                  <select
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                    id="foundation"
                    // value={targetInfo.buildingMaterial}
                    value={targetInfo.buildingFoundation || "未知(可重新評估)"}
                    onChange={handleBuildingFoundationChange}
                  >
                    <option value="樁基礎">樁基礎</option>
                    <option value="筏式基礎">筏式基礎 </option>
                    <option value="版式基礎">版式基礎</option>
                    <option value="未知(可重新評估)">未知(可重新評估)</option>
                  </select>
                </div>
                <div
                  style={{
                    display: "flex",
                    // alignItems: "start",
                    gap: "5px",
                    flexDirection: "row",
                  }}
                >
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginRight: "5px",
                    }}
                    htmlFor="underground"
                  >
                    是否有地下室:
                  </label>
                  <select
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                    id="underground"
                    // value={targetInfo.buildingMaterial}
                    value={targetInfo.underground || "有地下室"}
                    onChange={handleUndergroundChange}
                    // defaultValue={1}
                  >
                    <option value={1}>有地下室</option>
                    <option value={0}>無地下室</option>
                  </select>
                </div>

                <div
                  style={{
                    display: "flex",
                    // alignItems: "start",
                    gap: "5px",
                    flexDirection: "row",
                  }}
                >
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginRight: "5px",
                    }}
                    htmlFor="building-time"
                  >
                    建造時間(西元年):
                  </label>
                  <input
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "60px",
                    }}
                    id="building-time"
                    type="number"
                    min="1950"
                    step="1"
                    // value={targetInfo.buildingTime}
                    onChange={handleBuildingTimeChange}
                    value={targetInfo.buildingTime || ""} // Provide empty string fallback
                    // defaultValue={2000}
                  />
                </div>
                <div>
                  <label
                    htmlFor="maskingHeight"
                    style={{
                      marginRight: "5px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    建物1F地板比人行道高幾公分(一般為10~30公分):
                  </label>
                  <input
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "60px",
                    }}
                    id="maskingHeight"
                    type="number"
                    min="0" // Set a minimum value of 0 to prevent negative numbers
                    value={targetInfo.maskingHeight || ""}
                    onChange={handleMaskingHeightChange}
                    // defaultValue={0}
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div
                style={{ gap: "5px", display: "flex", flexDirection: "column" }}
              >
                <div
                  style={{
                    marginRight: "5px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  財產資料
                </div>
                <div>
                  <label
                    htmlFor="buildingValue"
                    style={{
                      marginRight: "5px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    建物實體價值(萬元):
                  </label>
                  <input
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "60px",
                    }}
                    id="buildingValue"
                    type="number"
                    min="0" // Set a minimum value of 0 to prevent negative numbers
                    value={targetInfo.buildingValue || ""}
                    onChange={handleBuildingValueChange}
                    // defaultValue={1000}
                  />
                </div>
                {/* <div>
                  <label
                    htmlFor="businessLoss"
                    style={{
                      marginRight: "5px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    營業損失(萬元):
                  </label>
                  <input
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "60px",
                    }}
                    id="businessLoss"
                    type="number"
                    min="0" // Set a minimum value of 0 to prevent negative numbers
                    value={targetInfo.businessLoss}
                    onChange={handleBusinessLossChange}
                  />
                </div> */}
                <div>
                  <label
                    htmlFor="carValue"
                    style={{
                      marginRight: "5px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    汽機車價值(萬元):
                  </label>
                  <input
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "60px",
                    }}
                    id="carValue"
                    type="number"
                    min="0" // Set a minimum value of 0 to prevent negative numbers
                    value={targetInfo.carValue || ""}
                    onChange={handleCarValueChange}
                    // defaultValue={50}
                  />
                </div>
                <div>
                  <label
                    htmlFor="furnitureLoss"
                    style={{
                      marginRight: "5px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    附屬物價值(傢俱)(萬元):
                  </label>
                  <input
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "60px",
                    }}
                    id="furnitureLoss"
                    type="number"
                    min="0" // Set a minimum value of 0 to prevent negative numbers
                    value={targetInfo.furnitureLoss || ""}
                    onChange={handleFurnitureLossChange}
                    // defaultValue={10}
                  />
                </div>
                <div>
                  <label
                    htmlFor="renovation-value"
                    style={{
                      marginRight: "5px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    裝潢總價(萬元):
                  </label>
                  <input
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "60px",
                    }}
                    id="renovation-value"
                    type="number"
                    min="0" // Set a minimum value of 0 to prevent negative numbers
                    value={targetInfo.renovationValue || ""}
                    onChange={handleRenovationChange}
                    // defaultValue={0}
                  />
                </div>
                <div>
                  <label
                    htmlFor="pingall"
                    style={{
                      marginRight: "5px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    坪數(含公設):
                  </label>
                  <input
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "60px",
                    }}
                    id="pingall"
                    type="number"
                    min="0" // Set a minimum value of 0 to prevent negative numbers
                    value={targetInfo.pingAll || ""}
                    onChange={handlePingAllChange}
                    // defaultValue={50}
                  />
                </div>
                <div>
                  <label
                    htmlFor="pingIndoor"
                    style={{
                      marginRight: "5px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    坪數(不含公設):
                  </label>
                  <input
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "60px",
                    }}
                    id="pingIndoor"
                    type="number"
                    min="0" // Set a minimum value of 0 to prevent negative numbers
                    value={targetInfo.pingIndoor || ""}
                    onChange={handlePingIndoorChange}
                    // defaultValue={30}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Box>
    </div>
  );
};

export default TargetInfoInput;
