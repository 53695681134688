import "./PriceCard.scss";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../axios";
const newebpay_url = process.env.REACT_APP_NEWEBPAY_URL;

const data = [
  {
    price: 100,
    points: 100,
    type: "basic",
    report: "專屬分析報告",
    share: "分享點數功能",
  },
  {
    price: 950,
    points: 1000,
    type: "Premium",
    report: "專屬分析報告",
    share: "分享點數功能",
  },
  {
    price: 9000,
    points: 10000,
    type: "Ultra",
    report: "專屬分析報告",
    share: "分享點數功能",
  },
];

const PriceCard = ({ type, pointPackage }) => {
  const post_newebpayform = async (pointpackage_id) => {
    try {
      const response = await axiosInstance.post(
        "/shop/membership/newebpay_payment/",
        {
          pointpackage_id: pointpackage_id,
        }
      );
      const paymentData = response.data.payment_data;
      const form = document.createElement("form");
      form.method = "post";
      //   form.action = "https://ccore.newebpay.com/MPG/mpg_gateway";
      form.action = newebpay_url;
      for (const key in paymentData) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = paymentData[key];
        form.appendChild(input);
      }

      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="price-card-container">
      <div className="priceContainer">
        $
        <span className="price">
          {isNaN(pointPackage?.price) ? " " : Math.floor(pointPackage?.price)}
        </span>
        TWD
        {/* $<span className="price">{Math.floor(pointPackage?.price)}</span>TWD */}
      </div>
      <button className="type">{type} Plan</button>
      <ul className="list">
        <li className="listItem">{pointPackage?.points}點會員點數</li>
        <li className="listItem">專屬分析報告</li>
        <li className="listItem">分享點數功能</li>
      </ul>
      <button
        className="price-card-button"
        onClick={() => post_newebpayform(pointPackage?.pkid)}
      >
        立即購買
      </button>
    </div>
  );
};

export default PriceCard;
