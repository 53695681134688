// src/features/waterProbData/waterProbDataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  risksData: {
    flood: 3,
    earthquake: 2,
    landslide: 1,
    liquefaction: 1,
    sealevel: 1,
  },
};

export const risksDataSlice = createSlice({
  name: "risksData",
  initialState,
  reducers: {
    setRisksData: (state, action) => {
      state.risksData = action.payload;
    },
    resetRisksData: (state) => {
      state.risksData = initialState.risksData;
    },
  },
});

export const { setRisksData, resetRisksData } = risksDataSlice.actions;

export default risksDataSlice.reducer;
