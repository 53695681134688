import React, { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import "./Price.scss";
import PriceCard from "../PriceCard/PriceCard2";

const Price = () => {
  const [pointPackage, setPointPackage] = useState([]);
  useEffect(() => {
    const get_pointpackage = async () => {
      try {
        const res = await axiosInstance.get("shop/membership/point_packages/");
        setPointPackage(res.data);
      } catch (error) {
        console.log("error getting pointpackage");
      }
    };
    get_pointpackage();
    // console.log(pointPackage);
  }, []);
  return (
    <div className="price-container">
      <PriceCard type="Basic" pointPackage={pointPackage[0]} />
      <PriceCard type="Premium" pointPackage={pointPackage[1]} />
      <PriceCard type="Ultra" pointPackage={pointPackage[2]} />
    </div>
  );
};

export default Price;
