import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./RiskMapClip.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as turf from "@turf/turf";
import axios from "axios";
import { stringify } from "wkt";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
// const gs_username = process.env.REACT_APP_GEOSERVER_BASIC_USERNAME;
// const gs_pass = process.env.REACT_APP_GEOSERVER_BASIC_PASSWORD;
const baseURL = process.env.REACT_APP_API_URL;
mapboxgl.accessToken = mapboxtoken;

const RiskMapClipSeaLevel = ({ report }) => {
  const [isLoadingBuildings, setIsLoadingBuildings] = useState(true); // Loading state for buildings
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const layer_names =
    report?.return_period_layer_names["return_period_layer_names"];
  const [activeLayer, setActiveLayer] = useState(layer_names[3]);
  const [layerOpacity, setLayerOpacity] = useState(1); // Default to fully opaque
  // const polyGon = report.product.calculated_geometry;
  const [legendUrl, setLegendUrl] = useState("");
  const [clickedBuildingIds, setClickedBuildingIds] = useState(new Set());
  const detailOrder = report?.product;
  const wkt3857 = stringify(detailOrder?.b200_3857);
  const buildingH =
    JSON.parse(detailOrder?.description)?.selectedBuildingInfo?.properties
      ?.build_h || 10;
  const circlePolygon = detailOrder?.calculated_geometry;
  const bboxPolygon = detailOrder?.b200;

  // Cleanup function for layers and controls
  const cleanupMap = () => {
    if (map.current) {
      map.current.remove();
      map.current = null;
    }
  };

  const fetchBuildingsInView = (map) => {
    const bounds = map.getBounds();

    // console.log("Bounds:", bounds);
    setIsLoading(true);

    axios
      .get(`${baseURL}risk/geo/ows?`, {
        params: {
          service: "WFS",
          version: "1.0.0",
          request: "GetFeature",
          typeName: `staging:buildings_all3857_s`,
          bbox: `${bounds.getWest()},${bounds.getSouth()},${bounds.getEast()},${bounds.getNorth()}`,
          outputFormat: "application/json",
        },
        // auth: {
        //   username: gs_username,
        //   password: gs_pass,
        // },
      })
      .then((response) => {
        const data = response.data;
        data.features.forEach((feature) => {
          feature.id = feature.properties.uid;
        });
        map.getSource("buildings").setData(data);
        clickedBuildingIds.forEach((id) => {
          map.setFeatureState({ source: "buildings", id }, { clicked: true });
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the GeoServer data:", error);
        setIsLoading(false);
      });
  };

  const fetchLegendImage = async (legendRequestUrl) => {
    try {
      const response = await axios.get(legendRequestUrl, {
        responseType: "blob",
        // auth: { username: gs_username, password: gs_pass },
      });

      const imageUrl = URL.createObjectURL(response.data);
      setLegendUrl(imageUrl);
    } catch (error) {
      console.error("Error fetching legend image:", error);
      toast.error("無法加載圖例。");
    }
  };

  //
  useEffect(() => {
    // Call this function when you need to load a legend, e.g., when a layer is toggled
    if (activeLayer) {
      const legendRequestUrl = `${baseURL}risk/geo/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&LAYER=${activeLayer}&STYLE=red1`;
      fetchLegendImage(legendRequestUrl);
    }
  }, [activeLayer]);

  const layers = [
    {
      id: layer_names[3],
      // id: "sealevel15_merge_bilinear10m",
      title: "海平面高1.5m",
      wms:
        // geoserver_url +
        `${baseURL}risk/geo/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:${layer_names[3]}&FORMAT=image/png&TILED=true&clip=${wkt3857}&styles=red1`,
    },
    {
      // id: "sealevel22_merge_bilinear10m",
      id: layer_names[4],
      title: "海平面高2.2m",
      wms:
        // geoserver_url +
        `${baseURL}risk/geo/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:${layer_names[4]}&FORMAT=image/png&TILED=true&clip=${wkt3857}&styles=red1`,
    },
    {
      // id: "sealevel40_merge_bilinear10m",
      id: layer_names[5],
      title: "海平面高4.0m",
      wms:
        // geoserver_url +
        `${baseURL}risk/geo/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:${layer_names[5]}&FORMAT=image/png&TILED=true&clip=${wkt3857}&styles=red1`,
    },
    {
      // id: "sealevel47_merge_bilinear10m",
      id: layer_names[6],
      title: "海平面高4.7m",
      wms:
        // geoserver_url +
        `${baseURL}risk/geo/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:${layer_names[6]}&FORMAT=image/png&TILED=true&clip=${wkt3857}&styles=red1`,
    },

    // {
    //   id: "tsunami_risk3857",
    //   title: "海嘯潛勢區",
    //   wms:
    //     geoserver_url +
    //     `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:tsunami_risk3857&FORMAT=image/png&TILED=true`,
    // },
  ];

  const switchBaseMap = (style) => {
    if (map.current) {
      map.current.setStyle(`mapbox://styles/mapbox/${style}`);

      // Once the style has loaded, re-add the WMS layers
      map.current.once("style.load", () => {
        addWMSLayers(map); // Re-add the WMS layers
        // addPolygonLayer(); // Re-add the polygon layer
        addBuildingsLayer(map.current);
        addCircleToMap(map.current);
      });
    }
  };

  const addWMSLayers = (map) => {
    layers.forEach((layer) => {
      // Only add the source if it doesn't already exist
      if (!map.current.getSource(layer.id)) {
        map.current.addSource(layer.id, {
          type: "raster",
          tiles: [layer.wms],
          tileSize: 256,
        });
      }

      // Only add the layer if it doesn't already exist
      if (!map.current.getLayer(layer.id)) {
        map.current.addLayer({
          id: layer.id,
          type: "raster",
          source: layer.id,
          paint: {
            "raster-opacity": layerOpacity, // use the current opacity
          },
          layout: {
            // Use the ternary operator to set visibility based on the active layer
            visibility: layer.id === activeLayer ? "visible" : "none",
          },
        });
      } else {
        // If the layer already exists, just update its visibility
        map.current.setLayoutProperty(
          layer.id,
          "visibility",
          layer.id === activeLayer ? "visible" : "none"
        );
      }
    });
  };

  const addBuildingsLayer = (map) => {
    console.log("addBuildingsLayer run! ");
    if (!map.getSource("buildings")) {
      map.addSource("buildings", {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
      });
    }

    if (!map.getLayer("3d-buildings")) {
      map.addLayer({
        id: "3d-buildings",
        source: "buildings",
        type: "fill-extrusion",
        layout: { visibility: "none" }, // Set initial visibility to 'none'
        // layout: { visibility: "visible" }, // Set initial visibility to 'none'
        paint: {
          "fill-extrusion-color": "#242424",
          "fill-extrusion-height": ["get", "build_h"],
          "fill-extrusion-base": 0,
          "fill-extrusion-opacity": 0.8,
        },
      });

      fetchBuildingsInView(map); // Fetch the building data
      console.log("fetchBuildingsInView run! ");
    }
  };

  // const addBuildingsLayer = (map) => {
  //   if (!map.getSource("buildings")) {
  //     map.addSource("buildings", {
  //       type: "geojson",
  //       data: { type: "FeatureCollection", features: [] },
  //     });
  //   }

  //   if (!map.getLayer("3d-buildings")) {
  //     map.addLayer({
  //       id: "3d-buildings",
  //       source: "buildings",
  //       type: "fill-extrusion",
  //       layout: { visibility: "none" },
  //       // layout: { visibility: "visible" },
  //       paint: {
  //         "fill-extrusion-color": "#242424",
  //         "fill-extrusion-height": ["get", "build_h"],
  //         "fill-extrusion-base": 0,
  //         "fill-extrusion-opacity": 0.8,
  //       },
  //     });
  //   }

  //   fetchBuildingsInView(map);
  // };

  const addCircleToMap = (map) => {
    if (map.getSource("circle-polygon")) {
      map.getSource("circle-polygon").setData(circlePolygon);
      map.getSource("bboxPolygon").setData(bboxPolygon);
    } else {
      map.addSource("circle-polygon", {
        type: "geojson",
        data: circlePolygon,
      });

      map.addLayer({
        id: "circle-polygon-layer",
        type: "fill-extrusion",
        source: "circle-polygon",
        paint: {
          "fill-extrusion-color": "red",
          "fill-extrusion-height": buildingH + 1,
          "fill-extrusion-base": 0,
          "fill-extrusion-opacity": 1,
        },
      });

      map.addSource("bboxPolygon", {
        type: "geojson",
        data: bboxPolygon,
      });

      map.addLayer({
        id: "bboxPolygon-layer",
        type: "line",
        source: "bboxPolygon",
        paint: {
          "line-color": "black",
          "line-width": 2,
          "line-dasharray": [2, 2],
        },
      });
    }
  };

  useEffect(() => {
    if (!map.current && mapContainer.current) {
      let center;
      try {
        center = turf.center(report.product.calculated_geometry).geometry
          .coordinates;
      } catch (error) {
        console.error("Error calculating center:", error);
        return;
      }

      if (!center || center.length !== 2 || center.some(isNaN)) {
        console.error("Invalid center coordinates:", center);
        return;
      }

      const bbox = turf.bbox(report?.product?.bbox_b100);
      const product_bbox = [
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]],
      ];

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/light-v11",
        center: center,
        zoom: 14,
        pitch: 50,
        dragPan: false,
        scrollZoom: false,
        touchZoomRotate: false,
        dragRotate: false,
        pitchWithRotate: false,
        // transformRequest: (url, resourceType) => {
        //   if (
        //     (resourceType === "Source" || resourceType === "Tile") &&
        //     url.indexOf(geoserver_url + `geoserver/staging/wms`) > -1
        //   ) {
        //     const username = gs_username;
        //     const password = gs_pass;
        //     const credentials = btoa(`${username}:${password}`);
        //     const headers = {
        //       Authorization: `Basic ${credentials}`,
        //     };

        //     return {
        //       url: url,
        //       headers: headers,
        //       credentials: "include",
        //     };
        //   }
        // },
      });

      map.current.on("load", () => {
        // Add WMS layers and other sources when the map is fully loaded
        addWMSLayers(map);
        // addPolygonLayer();
        addBuildingsLayer(map.current);
        addCircleToMap(map.current);
        map.current.fitBounds(product_bbox, { padding: 80, pitch: 50 });
      });

      // class ToggleBuildingsControl {
      //   onAdd(map) {
      //     this.map = map;
      //     this.container = document.createElement("div");
      //     this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
      //     this.button = document.createElement("button");
      //     this.button.textContent = "建物";
      //     this.button.style.fontWeight = "bold";
      //     this.button.onclick = () => {
      //       const toggleBuildings = (map) => {
      //         let visibility = map.getLayoutProperty(
      //           "3d-buildings",
      //           "visibility"
      //         );
      //         if (visibility === "visible") {
      //           map.setLayoutProperty("3d-buildings", "visibility", "none");
      //         } else {
      //           map.setLayoutProperty("3d-buildings", "visibility", "visible");
      //         }
      //       };

      //       toggleBuildings(map.current);
      //     };

      //     this.container.appendChild(this.button);
      //     return this.container;
      //   }

      //   onRemove() {
      //     this.container.parentNode.removeChild(this.container);
      //     this.map = undefined;
      //   }
      // }

      class ToggleBuildingsControl {
        onAdd(map) {
          this.map = map;
          this.container = document.createElement("div");
          this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
          this.button = document.createElement("button");
          this.button.textContent = "建物";
          this.button.style.fontWeight = "bold";
          this.button.onclick = () => {
            const toggleBuildings = (map) => {
              if (map.getLayer("3d-buildings")) {
                // Check if the layer exists
                let visibility = map.getLayoutProperty(
                  "3d-buildings",
                  "visibility"
                );
                if (visibility === "visible") {
                  map.setLayoutProperty("3d-buildings", "visibility", "none");
                } else {
                  map.setLayoutProperty(
                    "3d-buildings",
                    "visibility",
                    "visible"
                  );
                }
              } else {
                // If the layer doesn't exist, log an error and try re-adding the layer
                console.error(
                  "Layer '3d-buildings' not found, re-adding the layer"
                );
                addBuildingsLayer(map.current); // Ensure you re-add the layer if it's missing
              }
            };

            toggleBuildings(map.current);
          };

          this.container.appendChild(this.button);
          return this.container;
        }

        onRemove() {
          this.container.parentNode.removeChild(this.container);
          this.map = undefined;
        }
      }

      class BearingControl {
        constructor(rotationStep = 45) {
          this.rotationStep = rotationStep;
          this.currentBearing = 0; // Start from 0 degree
        }

        onAdd(map) {
          this.map = map;
          this.container = document.createElement("div");
          this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

          this.button = document.createElement("button");
          this.button.textContent = "旋轉";
          this.button.style.fontWeight = "bold";
          this.button.onclick = this.rotateBearing.bind(this); // Bind to class instance

          this.container.appendChild(this.button);
          return this.container;
        }

        rotateBearing() {
          // Update bearing by the specified rotation step
          this.currentBearing = (this.currentBearing + this.rotationStep) % 360;

          // Apply the bearing change to both maps
          map.current.easeTo({ bearing: this.currentBearing });
        }

        onRemove() {
          this.container.parentNode.removeChild(this.container);
          this.map = undefined;
        }
      }

      class Toggle3DControl {
        constructor(pitchAngle = 50) {
          this.pitchAngle = pitchAngle;
          this.is3D = true;
        }

        onAdd(map) {
          this.map = map;
          this.container = document.createElement("div");
          this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
          this.button = document.createElement("button");
          this.updateButtonText();
          this.button.onclick = () => {
            this.togglePitch();
          };
          this.container.appendChild(this.button);
          return this.container;
        }

        togglePitch() {
          if (this.is3D) {
            this.map.easeTo({ pitch: 0 });
            this.is3D = false;
          } else {
            this.map.easeTo({ pitch: this.pitchAngle });
            this.is3D = true;
          }
          this.updateButtonText();
        }

        updateButtonText() {
          this.button.textContent = this.is3D ? "2D" : "3D";
        }

        onRemove() {
          this.container.parentNode.removeChild(this.container);
          this.map = undefined;
        }
      }

      // Add controls after map initialization
      const toggleBuildingsControl = new ToggleBuildingsControl();
      // map.current.addControl(toggleBuildingsControl, "top-left");

      const bearingControl = new BearingControl(45);
      map.current.addControl(bearingControl, "top-left");

      const toggle3DControl = new Toggle3DControl();
      map.current.addControl(toggle3DControl, "top-left");
    }

    // Clean up function when component unmounts
    return cleanupMap;
  }, [layerOpacity]);

  const toggleLayer = (layerId) => {
    setActiveLayer(layerId);
    layers.forEach((layer) => {
      map.current.setLayoutProperty(
        layer.id,
        "visibility",
        layer.id === layerId ? "visible" : "none"
      );
    });
    // const layer = layers.find((l) => l.id === layerId);
    // if (layer) {
    //   setLegendUrl(layer.legend);
    // }
  };

  // if (!user) {
  //   // If no user is logged in, display a login message
  //   return (
  //     <div className="login-message">
  //       <p>請先登入</p>
  //       <Link to="/login">登入</Link>{" "}
  //       {/* Replace "/login" with your login route */}
  //     </div>
  //   );
  // }

  return (
    <div className="riskmap-container">
      <div
        className="map-container"
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: "60vh",
          borderRadius: "4px",
        }}
      >
        {/* Map container covering the full width and 60vh height */}
        <div
          ref={mapContainer}
          style={{
            width: "100%",
            height: "60vh",
            padding: "10px",
            borderRadius: "4px",
          }}
        />
        {/* <div className="map-container" ref={mapContainer} /> */}

        <div
          className="legend-container"
          style={{
            position: "absolute",
            bottom: "20px",
            right: "10px",
            border: "2px solid",
            borderRadius: "4px",
          }}
        >
          {legendUrl && <img src={legendUrl} alt="Legend" />}
        </div>
        {/* Base Map Switcher */}
        <div
          id="menu"
          style={{
            position: "absolute",
            top: 10, // Adjust as needed for top spacing
            left: "50%", // Center horizontally
            transform: "translateX(-50%)", // Adjust for exact centering
            background: "#efefef",
            padding: "5px",
            zIndex: 1, // Ensure it's above the map
            border: "solid 1px",
            borderRadius: "4px",
          }}
        >
          <input
            id="satellite-streets-v12"
            type="radio"
            name="rtoggle"
            value="satellite-streets-v12"
            onChange={() => switchBaseMap("satellite-streets-v12")}
          />
          <label
            htmlFor="satellite-streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            衛星
          </label>
          <input
            id="light-v11"
            type="radio"
            name="rtoggle"
            value="light-v11"
            onChange={() => switchBaseMap("light-v11")}
            defaultChecked
          />
          <label
            htmlFor="light-v11"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            白底
          </label>
        </div>
      </div>

      {/* <div className="map-container" ref={mapContainer} /> */}
      <div className="sidebar">
        {layers.map((layer) => (
          <button
            key={layer.id}
            className={`sidebar-button ${
              activeLayer === layer.id ? "active" : ""
            }`}
            onClick={() => toggleLayer(layer.id)}
          >
            {layer.title}
          </button>
        ))}
      </div>
    </div>
  );
};

export default RiskMapClipSeaLevel;
