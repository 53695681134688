import React from "react";
import { motion, useInView } from "framer-motion";
import CountUp from "react-countup";
import "./SummaryChart.css";

const SummaryChartSealevel = ({ report }) => {
  const minHeight = 40; // Minimum height for bars
  const minHeight2 = 80; // Minimum height for bars

  const sealevelCarLoss = report?.risk_results["sealevel"][3];
  const sealevelFurnitureLoss = report?.risk_results["sealevel"][4];
  const sealevelTotalLoss = report?.risk_results["sealevel"][5];
  const sealevel_rise_masked_and_probabilities_all =
    report?.risk_results["sealevel"][7];

  const sealevel_prob_yearly_over10_sl15 =
    sealevel_rise_masked_and_probabilities_all[0][0]["10cm"] || 100;
  const sealevel_prob_yearly_over10_sl22 =
    sealevel_rise_masked_and_probabilities_all[1][0]["10cm"] || 100;
  const sealevel_prob_yearly_over10_sl40 =
    sealevel_rise_masked_and_probabilities_all[2][0]["10cm"] || 100;
  const sealevel_prob_yearly_over10_sl47 =
    sealevel_rise_masked_and_probabilities_all[3][0]["10cm"] || 100;
  const sealevel_prob_yearly_over30_sl15 =
    sealevel_rise_masked_and_probabilities_all[0][0]["30cm"] || 100;
  const sealevel_prob_yearly_over30_sl22 =
    sealevel_rise_masked_and_probabilities_all[1][0]["30cm"] || 100;
  const sealevel_prob_yearly_over30_sl40 =
    sealevel_rise_masked_and_probabilities_all[2][0]["30cm"] || 100;
  const sealevel_prob_yearly_over30_sl47 =
    sealevel_rise_masked_and_probabilities_all[3][0]["30cm"] || 100;

  const sealevel_loss_yearly_car_sl15 = sealevelCarLoss[0] / 10000;
  const sealevel_loss_yearly_car_sl22 = sealevelCarLoss[1] / 10000;
  const sealevel_loss_yearly_car_sl40 = sealevelCarLoss[2] / 10000;
  const sealevel_loss_yearly_car_sl47 = sealevelCarLoss[3] / 10000;

  const sealevel_loss_yearly_furniture_sl15 = sealevelFurnitureLoss[0] / 10000;
  const sealevel_loss_yearly_furniture_sl22 = sealevelFurnitureLoss[1] / 10000;
  const sealevel_loss_yearly_furniture_sl40 = sealevelFurnitureLoss[2] / 10000;
  const sealevel_loss_yearly_furniture_sl47 = sealevelFurnitureLoss[3] / 10000;

  const sealevel_loss_yearly_total_sl15 = sealevelTotalLoss[0] / 10000;
  const sealevel_loss_yearly_total_sl22 = sealevelTotalLoss[1] / 10000;
  const sealevel_loss_yearly_total_sl40 = sealevelTotalLoss[2] / 10000;
  const sealevel_loss_yearly_total_sl47 = sealevelTotalLoss[3] / 10000;

  // For animating numbers when they come into view
  const ref = React.useRef(null);
  const isInView = useInView(ref, { triggerOnce: true, threshold: 0.2 });

  return (
    <div className="flood-loss-container">
      <div className="f-title">S1:海平面上升1.5m+AR6降雨情境</div>
      <div className="f-title">因氣候變遷發生淹水損失機率</div>
      <div className="bar-container">
        <div className="bar">
          <div
            className="bar-value-prob"
            style={{
              height: `${Math.max(
                sealevel_prob_yearly_over10_sl15 / 100,
                minHeight
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_prob_yearly_over10_sl15}
                duration={2.5}
                suffix="%"
              />
            ) : (
              `${sealevel_prob_yearly_over10_sl15}%`
            )}
          </div>
          <div className="bar-label">家俱家電(大於10cm淹水)</div>
        </div>

        <div className="bar">
          <div
            className="bar-value-prob"
            style={{
              height: `${Math.max(
                sealevel_prob_yearly_over30_sl15 / 100,
                minHeight
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_prob_yearly_over30_sl15}
                duration={2.5}
                suffix="%"
              />
            ) : (
              `${sealevel_prob_yearly_over30_sl15}%`
            )}
          </div>
          <div className="bar-label">汽機車(大於30cm淹水)</div>
        </div>
      </div>
      <br />
      <div className="f-title">每年平均淹水損失</div>
      <div className="bar-container">
        <div className="bar">
          <div
            className="bar-value"
            style={{
              width: "80px",
              height: `${Math.max(
                sealevel_loss_yearly_furniture_sl15 / 100000,
                minHeight2
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_loss_yearly_furniture_sl15}
                duration={2.5}
                suffix="萬元/年"
              />
            ) : (
              `${sealevel_loss_yearly_furniture_sl15}萬元/年`
            )}
          </div>
          <div className="bar-label">家俱家電</div>
        </div>

        <div className="plus-sign">+</div>

        <div className="bar">
          <div
            className="bar-value"
            style={{
              width: "80px",
              height: `${Math.max(
                sealevel_loss_yearly_car_sl15 / 100000,
                minHeight2
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_loss_yearly_car_sl15}
                duration={2.5}
                suffix="萬元/年"
              />
            ) : (
              `${sealevel_loss_yearly_car_sl15}萬元/年`
            )}
          </div>
          <div className="bar-label">汽機車</div>
        </div>

        <div className="equals-sign">=</div>

        <div className="bar">
          <div
            className="bar-value"
            style={{
              width: "80px",
              height: `${Math.max(
                sealevel_loss_yearly_total_sl15 / 100000,
                minHeight2
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_loss_yearly_total_sl15}
                duration={2.5}
                suffix="萬元/年"
              />
            ) : (
              `${sealevel_loss_yearly_total_sl15}萬元/年`
            )}
          </div>
          <div className="bar-label">綜合</div>
        </div>
      </div>

      <hr />

      <div className="f-title">S2:海平面上升2.2m+AR6降雨情境</div>
      <div className="f-title">因氣候變遷發生淹水損失機率</div>
      <div className="bar-container">
        <div className="bar">
          <div
            className="bar-value-prob"
            style={{
              height: `${Math.max(
                sealevel_prob_yearly_over10_sl22 / 100,
                minHeight
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_prob_yearly_over10_sl22}
                duration={2.5}
                suffix="%"
              />
            ) : (
              `${sealevel_prob_yearly_over10_sl22}%`
            )}
          </div>
          <div className="bar-label">家俱家電(大於10cm淹水)</div>
        </div>

        <div className="bar">
          <div
            className="bar-value-prob"
            style={{
              height: `${Math.max(
                sealevel_prob_yearly_over30_sl22 / 100,
                minHeight
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_prob_yearly_over30_sl22}
                duration={2.5}
                suffix="%"
              />
            ) : (
              `${sealevel_prob_yearly_over30_sl22}%`
            )}
          </div>
          <div className="bar-label">汽機車(大於30cm淹水)</div>
        </div>
      </div>
      <br />
      <div className="f-title">每年平均淹水損失</div>
      <div className="bar-container">
        <div className="bar">
          <div
            className="bar-value"
            style={{
              width: "80px",
              height: `${Math.max(
                sealevel_loss_yearly_furniture_sl22 / 100000,
                minHeight2
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_loss_yearly_furniture_sl22}
                duration={2.5}
                suffix="萬元/年"
              />
            ) : (
              `${sealevel_loss_yearly_furniture_sl22}萬元/年`
            )}
          </div>
          <div className="bar-label">家俱家電</div>
        </div>

        <div className="plus-sign">+</div>

        <div className="bar">
          <div
            className="bar-value"
            style={{
              width: "80px",
              height: `${Math.max(
                sealevel_loss_yearly_car_sl22 / 100000,
                minHeight2
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_loss_yearly_car_sl22}
                duration={2.5}
                suffix="萬元/年"
              />
            ) : (
              `${sealevel_loss_yearly_car_sl22}萬元/年`
            )}
          </div>
          <div className="bar-label">汽機車</div>
        </div>

        <div className="equals-sign">=</div>

        <div className="bar">
          <div
            className="bar-value"
            style={{
              width: "80px",
              height: `${Math.max(
                sealevel_loss_yearly_total_sl22 / 100000,
                minHeight2
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_loss_yearly_total_sl22}
                duration={2.5}
                suffix="萬元/年"
              />
            ) : (
              `${sealevel_loss_yearly_total_sl22}萬元/年`
            )}
          </div>
          <div className="bar-label">綜合</div>
        </div>
      </div>
      <hr />
      <div className="f-title">S1:海平面上升4.0m+AR6降雨情境</div>
      <div className="f-title">因氣候變遷發生淹水損失機率</div>
      <div className="bar-container">
        <div className="bar">
          <div
            className="bar-value-prob"
            style={{
              height: `${Math.max(
                sealevel_prob_yearly_over10_sl40 / 100,
                minHeight
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_prob_yearly_over10_sl40}
                duration={2.5}
                suffix="%"
              />
            ) : (
              `${sealevel_prob_yearly_over10_sl40}%`
            )}
          </div>
          <div className="bar-label">家俱家電(大於10cm淹水)</div>
        </div>

        <div className="bar">
          <div
            className="bar-value-prob"
            style={{
              height: `${Math.max(
                sealevel_prob_yearly_over30_sl40 / 100,
                minHeight
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_prob_yearly_over30_sl40}
                duration={2.5}
                suffix="%"
              />
            ) : (
              `${sealevel_prob_yearly_over30_sl40}%`
            )}
          </div>
          <div className="bar-label">汽機車(大於30cm淹水)</div>
        </div>
      </div>
      <br />
      <div className="f-title">每年平均淹水損失</div>
      <div className="bar-container">
        <div className="bar">
          <div
            className="bar-value"
            style={{
              width: "80px",
              height: `${Math.max(
                sealevel_loss_yearly_furniture_sl40 / 100000,
                minHeight2
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_loss_yearly_furniture_sl40}
                duration={2.5}
                suffix="萬元/年"
              />
            ) : (
              `${sealevel_loss_yearly_furniture_sl40}萬元/年`
            )}
          </div>
          <div className="bar-label">家俱家電</div>
        </div>

        <div className="plus-sign">+</div>

        <div className="bar">
          <div
            className="bar-value"
            style={{
              width: "80px",
              height: `${Math.max(
                sealevel_loss_yearly_car_sl40 / 100000,
                minHeight2
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_loss_yearly_car_sl40}
                duration={2.5}
                suffix="萬元/年"
              />
            ) : (
              `${sealevel_loss_yearly_car_sl40}萬元/年`
            )}
          </div>
          <div className="bar-label">汽機車</div>
        </div>

        <div className="equals-sign">=</div>

        <div className="bar">
          <div
            className="bar-value"
            style={{
              width: "80px",
              height: `${Math.max(
                sealevel_loss_yearly_total_sl40 / 100000,
                minHeight2
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_loss_yearly_total_sl40}
                duration={2.5}
                suffix="萬元/年"
              />
            ) : (
              `${sealevel_loss_yearly_total_sl40}萬元/年`
            )}
          </div>
          <div className="bar-label">綜合</div>
        </div>
      </div>
      <hr />

      <div className="f-title">S1:海平面上升4.7m+AR6降雨情境</div>
      <div className="f-title">因氣候變遷發生淹水損失機率</div>
      <div className="bar-container">
        <div className="bar">
          <div
            className="bar-value-prob"
            style={{
              height: `${Math.max(
                sealevel_prob_yearly_over10_sl47 / 100,
                minHeight
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_prob_yearly_over10_sl47}
                duration={2.5}
                suffix="%"
              />
            ) : (
              `${sealevel_prob_yearly_over10_sl47}%`
            )}
          </div>
          <div className="bar-label">家俱家電(大於10cm淹水)</div>
        </div>

        <div className="bar">
          <div
            className="bar-value-prob"
            style={{
              height: `${Math.max(
                sealevel_prob_yearly_over30_sl47 / 100,
                minHeight
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_prob_yearly_over30_sl47}
                duration={2.5}
                suffix="%"
              />
            ) : (
              `${sealevel_prob_yearly_over30_sl47}%`
            )}
          </div>
          <div className="bar-label">汽機車(大於30cm淹水)</div>
        </div>
      </div>
      <br />
      <div className="f-title">每年平均淹水損失</div>
      <div className="bar-container">
        <div className="bar">
          <div
            className="bar-value"
            style={{
              width: "80px",
              height: `${Math.max(
                sealevel_loss_yearly_furniture_sl47 / 100000,
                minHeight2
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_loss_yearly_furniture_sl47}
                duration={2.5}
                suffix="萬元/年"
              />
            ) : (
              `${sealevel_loss_yearly_furniture_sl47}萬元/年`
            )}
          </div>
          <div className="bar-label">家俱家電</div>
        </div>

        <div className="plus-sign">+</div>

        <div className="bar">
          <div
            className="bar-value"
            style={{
              width: "80px",
              height: `${Math.max(
                sealevel_loss_yearly_car_sl47 / 100000,
                minHeight2
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_loss_yearly_car_sl47}
                duration={2.5}
                suffix="萬元/年"
              />
            ) : (
              `${sealevel_loss_yearly_car_sl47}萬元/年`
            )}
          </div>
          <div className="bar-label">汽機車</div>
        </div>

        <div className="equals-sign">=</div>

        <div className="bar">
          <div
            className="bar-value"
            style={{
              width: "80px",
              height: `${Math.max(
                sealevel_loss_yearly_total_sl47 / 100000,
                minHeight2
              )}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={sealevel_loss_yearly_total_sl47}
                duration={2.5}
                suffix="萬元/年"
              />
            ) : (
              `${sealevel_loss_yearly_total_sl47}萬元/年`
            )}
          </div>
          <div className="bar-label">綜合</div>
        </div>
      </div>
      <hr />

      <br />
      <div className="f-title">建議</div>
      <div>
        氣候變遷風險乃依照現況水利工程設施進行評估，未來政府應會加強水利工程並減少氣候所帶來的淹水風險，建議2030年後再進行評估。
      </div>
      <br />

      <div className="f-title">詳細內容</div>
      <div>請見氣候變遷分析專章</div>
    </div>
  );
};

export default SummaryChartSealevel;
