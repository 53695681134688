import React, { useEffect, useState } from "react";
// import { Container } from "@mui/material";
import { useSelector } from "react-redux";
import axiosInstance from "../../axios";

const SealevelResult = ({ report }) => {
  const polygonData = useSelector((state) => state.polygonData.polygonData);
  const [liqProb, setLiqProb] = useState({});
  const [loading, setLoading] = useState(false);
  const sealevel = report?.risk_results["sealevel"][2];

  const [sealevel15, setSealevel15] = useState(0);
  const [sealevel22, setSealevel22] = useState(0);
  const [sealevel40, setSealevel40] = useState(0);
  const [sealevel47, setSealevel47] = useState(0);

  // Assuming report.product.target_info is a string containing JSON data
  const targetInfoString = report.product.target_info;

  // Parse the string into a JSON object
  const targetInfoJSON = JSON.parse(targetInfoString);

  // Extracting the mask height
  const maskHeight = targetInfoJSON.maskingHeight;

  useEffect(() => {
    const calc_waterdepth = (sealevel_depth, setState) => {
      if (sealevel_depth > 0) {
        const mask_depth = sealevel_depth - maskHeight / 100;
        if (mask_depth > 0) {
          setState(mask_depth);
        } else {
          setState(0);
        }
      } else {
        setState(0);
      }
    };

    // Calculate water depth for each sea level
    calc_waterdepth(sealevel[0], setSealevel15);
    calc_waterdepth(sealevel[1], setSealevel22);
    calc_waterdepth(sealevel[2], setSealevel40);
    calc_waterdepth(sealevel[3], setSealevel47);
  }, [sealevel, maskHeight]);

  if (loading) {
    return <div>Loading...</div>;
  }

  // if (!liqProb) {
  //   return <div>No liquefaction probability data available.</div>;
  // }

  return (
    <div>
      <div>
        <ul style={{ fontSize: "14px", fontWeight: "bold" }}>
          <li>
            依據IPCC
            AR6之升溫2°C情境，將導致臺灣周邊海域海平面上升0.5公尺，升溫4°C情境，將導致海平面上升1.2公尺。
          </li>
          <li>
            依據氣象局資料，2003-2022年間全年最高高潮位/暴潮位約為1-3.5(公尺)，以此為基準計算海平面上升可能造成的海水位高度，分別計算海水位1.5m、2.2m、4.0m、4.7m的低窪淹水區域
          </li>
          <li>
            本建物一樓地板提高:
            <span style={{ color: "red" }}>{maskHeight}</span>
            公分
          </li>
          {/* <li>海平面上升1.5m時，本建物淹水深度為{sealevel15}公尺</li>
          <li>海平面上升2.2m時，本建物淹水深度為{sealevel22}公尺</li>
          <li>海平面上升4.0m時，本建物淹水深度為{sealevel40}公尺</li>
          <li>海平面上升4.7m時，本建物淹水深度為{sealevel47}公尺</li> */}
        </ul>
      </div>
    </div>
  );
};

export default SealevelResult;
