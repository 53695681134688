// import { useNavigate } from "react-router-dom";
// import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
// import "./Payment.scss";
// import React, { useEffect, useState } from "react";
// // import PaymentForm from "../../components/PaymentForm/PaymentForm";
// import axiosInstance from "../../axios";
// import { toast } from "react-toastify";
// import { resetCart } from "../../redux/cartReducer";
// import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
// import Price from "../../components/Price/Price2";

// const Payment = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [pointPackage, setPointPackage] = useState([]);
//   const products = useSelector((state) => state.cart.products);

//   useEffect(() => {
//     const get_pointpackage = async () => {
//       try {
//         const res = await axiosInstance.get("shop/membership/point_packages/");
//         setPointPackage(res.data);
//         // console.log(res);
//       } catch (error) {
//         console.log("error getting pointpackage");
//       }
//     };
//     get_pointpackage();
//     // console.log(pointPackage);
//   }, []);

//   return (
//     <div className="my-component">
//       <Price />
//     </div>
//   );
// };

// export default Payment;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import axiosInstance from "../../axios";
import Price from "../../components/Price/Price2";
import "./Payment.scss";

const Payment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const products = useSelector((state) => state.cart.products);
  const [pointPackage, setPointPackage] = useState([]);

  useEffect(() => {
    if (!user) {
      toast.error("Please log in to access this page.");
      navigate("/login");
    }

    const getPointPackage = async () => {
      try {
        const res = await axiosInstance.get("shop/membership/point_packages/");
        setPointPackage(res.data);
      } catch (error) {
        console.error("Error fetching point packages:", error);
        toast.error("Failed to load point packages.");
      }
    };

    if (user) {
      getPointPackage();
    }
  }, [user, navigate]);

  return (
    <div className="my-component">
      <Price />
    </div>
  );
};

export default Payment;
