import React from "react";
import "./TermsOfService.scss"; // Optional for styling

const TermsOfService = () => {
  return (
    <div>
      <div className="terms-of-service">
        <h1>安家百科會員註冊服務條款</h1>
        <p>
          <strong>生效日期：2024年9月19日</strong>
        </p>

        <p>
          歡迎蒞臨「安家百科」，為保障您的權益及讓您合法使用網站上的服務，特訂定「會員註冊服務條款」（下稱「服務條款」）適用於您和
          安家百科股份有限公司
          之間的關係。該公司是台灣公司（公司編號：00254696），（下稱「安家百科」或「我們」）；內容關乎您使用安家百科應用程式、網站和相關服務（下稱「服務」）時的注意事項。使用有關服務同時受安家百科隱私政策和其他相關政策約束，有關政策已列舉於此以作參考。凡是進入本網站者，皆應詳細閱讀下列所訂條款。使用本網站所提供之服務者，視為已閱讀、知悉並同意本約定條款所訂之內容。
        </p>

        <h2>一、網站服務內容：</h2>
        <ul>
          <li>
            （一）安家百科提供會員在電腦、手機、平板...等載具檢索、查詢、線上瀏覽、列印在紙上、下載……等會員個人非商業化使用之數位內容服務。
          </li>
          <li>
            （二）
            本數位內容服務係採綁定會員帳戶提供服務之方式。會員取得數位內容服務並匯入會員帳戶後，對於該數位內容並無所有權而僅有指定範圍之使用權。此使用權僅限於該會員帳號使用，不得轉讓、不得與人共享，且若該會員帳號因刪除或其他任何原因而不存在時，使用權亦隨之消滅，會員及任何人均不得因此而向安家百科要求退款及任何賠償、補償。
          </li>
          <li>
            （三）
            本數位內容服務並不保證可運行於所有行動裝置，會員需自行準備合宜之裝置。數位內容服務於IOS裝置、Android
            裝置、瀏覽器平台、以及其他現有或未來可能增加之系統平台等，其所提供之功能將因運行的系統平台不同而可能產生部分體驗上的差異，會員皆需理解及接受。
          </li>
          <li>
            （四）
            因數位內容服務下載檔案與連線所產生第三方所收取之存取費用或資料傳輸費用，會員需自行負擔。
          </li>
          <li>
            （五）會員刪除帳號後，將無法再使用本數位內容服務，刪除前之查詢紀錄及數位內容服務檔案將全數遭刪除，無法再提取。
          </li>
        </ul>

        <h2>二、會員資格：</h2>
        <ul>
          <li>
            （一）使用者應於安家百科網站註冊會員後，方能使用安家百科網站數位內容服務。
          </li>
          <li>
            （二）
            會員應年滿18歲、提供本人正確、最新的資料，且不得以第三人之名義註冊為會員。每位會員僅能註冊登錄一個帳號，不可重覆註冊登錄。若會員提供任何錯誤或不實的資料，安家百科有權不經事先通知，逕行暫停或終止會員的帳號，並拒絕會員使用本服務之全部或一部。
          </li>
          <li>
            （三）
            會員資料如有變更，應主動至「修改會員資料」網頁進行修正，若因未修正而致會員權益受損，由會員自行負責，本網站不負其責。
          </li>
          <li>
            （四）
            會員應妥善管理帳號密碼。如未成年人士未經授權使用本服務，會員應負擔全責。如未成年人使用會員之信用卡或其他支付工具（如
            Paypal），會員應承擔有關責任。如果會員得知或有合理理由懷疑存在安全威脅，包括但不限於帳號遺失，帳號被盜，或登入資訊經未經授權披露，會員應通知安家百科並修改登入資訊。
          </li>
        </ul>

        <h2>三、會員權益及費用：</h2>
        <ul>
          <li>
            （一）會員付費購買「安家百科」點數，可享有安家百科之網站服務。
          </li>
          <li>
            （二）
            相關會員權利、會員期限及相關規定，以申請成為會員時之會員說明規定為準，請自行參考相關說明
            。
          </li>
        </ul>

        <h2>四、會員行為規範及守法承諾：</h2>
        <ul>
          <li>
            （一）
            本網站之會員應遵守本條款所訂之內容、本網站公佈之相關規範、中華民國相關法規以及網際網路之慣例或規範。如有違反，安家百科得暫停全部或部分會員權利，或取消會員資格。
          </li>
          <li>
            （二）
            會員同意並保證不得利用安家百科提供之服務從事侵害他人權益或違法之行為，包括但不限於：
            <ul>
              <li>
                1.
                上傳或發布任何猥褻、毀謗、詐欺、賭博、侵害他人智慧財產權或其他法定權益、或其他違反法規之文字、圖片或任何資料。
              </li>
              <li>
                2. 破壞、入侵、毀損、盜取等任何有損本網站或本網站內容之行為。
              </li>
              <li>3. 妨礙或干擾其他會員使用本網站之權利。</li>
              <li>
                4.
                侵害或毀損本網站或他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。
              </li>
              <li>
                5.
                實施、協助或參與任何形式的攻擊行為，包括但不限於病毒傳播、對服務進行分散式阻斷服務攻擊（DDoS），抑或其他企圖擾亂服務，或影響其他人使用或享用服務的行為。
              </li>
              <li>6. 從事未經安家百科事前授權之商業行為。</li>
              <li>
                7. 利用本服務向任何人發送垃圾郵件、重覆訊息或誤導他人的訊息。
              </li>
              <li>
                8.
                其他不符本服務使用目的之行為，或安家百科有正當理由認為不適當之行為。
              </li>
            </ul>
          </li>
          <li>
            （三）
            安家百科所提供之數位內容服務，僅限於會員個人非商業化使用，會員應遵守第七條「智慧財產權及權利聲明」相關規定。
          </li>
        </ul>

        <h2>五、隱私權保護：</h2>
        <p>請參考安家百科隱私權政策。</p>

        <h2>六、安家百科網站責任：</h2>
        <ul>
          <li>
            （一）
            安家百科將依符合當時科技或專業水準認為合理之安全性與技術提供服務，並維持網站系統及服務之正常運作。但於發生突發性電子通信設備故障、電腦系統故障或電磁紀錄受到破壞、電腦系統運作異常、天災等不可抗力因素，或其他不可歸責於安家百科致使安家百科無法提供服務時，安家百科將暫停或中止本服務之提供，並採取合理措施後儘速回覆會員。
          </li>
          <li>
            （二） 會員在安家百科網站所上傳之資料或經由本網站所傳遞之資料
            ，由會員個人自行負責，安家百科網站不負審查、核對或查證之責。
          </li>
          <li>
            （三）
            在不影響安家百科承擔責任的前提下，服務依「現況」及「可行」為考量提供，安家百科就此不作任何明示或暗示的保證，包括但不限於涉及適銷，適用於特定目的，所有權、不侵權，以及其他由交易過程或商業慣例產生的任何保證。安家百科並不保證您可在自行選擇的時間或地點存取或使用服務；不保證服務不會中斷或不存在錯誤；同樣不保證相關錯誤會被修正，程式和服務並無病毒或其他有害內容。
          </li>
          <li>
            （四）
            安家百科致力於維持本系統內容的正確性，但對所提供之資料正確性、真實性不為任何保證。影響資料準確性的因素主要有演算法與模型資料兩者。安家百科採用可用的最佳技術(best
            practice
            available)進行相關災害的模擬與分析；模型資料採用公開資料、購買、合作夥伴提供的資料進行融合，相關資料的正確性會影響評估的結果，本公司已依據歷史災害的觀測資料，針對模型準確性進行驗證，準確率達98％。{" "}
          </li>
          <li>
            （五）
            本系統的分析結果是基於資料產生時所使用的最佳演算法和資料進行的評估。由於現實狀況可能因特定不動產本身或附近排水設施的維護情況、水工結構物及地形變化等因素而與模擬結果有所不同，安家百科致力於維持本系統內容的正確性。本分析內容旨在提供風險資訊供使用者參考，並不構成任何投資建議或結論，亦不代表安家百科的立場。使用者必須自行承擔使用資訊的風險，並對其使用結果負責。安家百科不會對因使用本系統分析內容而產生的任何損失或損害承擔責任。
          </li>
        </ul>

        <h2>七、智慧財產權及權利聲明：</h2>
        <ul>
          <li>
            （一）
            安家百科資料庫、安家百科運算程式、安家百科網站之內容，包括但不限於安家百科網站內所有之影像、圖片、動畫、視訊、音效、音樂、文字、檔案、資料、網站架構、網站畫面設計以及全部應用程式...等，均由安家百科或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等，均受著作權法、公平交易法或其他法令，和國際著作權條約及其他相關法令與條約之保護。
          </li>
          <li>
            （二） 對於安家百科網站或連結網站
            所提供之內容，僅限於會員個人非商業化使用，不得從事使用、修改、重製、出售、出租、互易、出借、散布、出版、改作、改篡割裂、公開播送、公開展示、公開發表、進行還原工程、解編或反向組譯、透過網路及其他方式對外公佈其內容或為其他足以侵害安家百科及「
            安家百科股份有限公司」權益之行為。如有違反，會員應對安家百科負損害賠償責任，包括但不限於訴訟費用及律師費用等。
          </li>
          <li>
            （三）
            安家百科僅限於會員個人在電腦、手機、平板...等載具，以非商業化使用之目的，利用瀏覽器使用本網站所提供之功能查詢、檢閱或列印在紙上，但不包括以下權限
            ：
            <ul>
              <li>
                1.
                將安家百科之內容列印、存檔、複製、剪貼，不論是否經過編輯，進一步利用於其他印刷物或電子媒體
                。
              </li>
              <li>
                2.
                將安家百科網站之內容，重製於磁碟、光碟、硬碟、電子儲存媒體或其他載體，有償或無償提供自己或他人使用。
              </li>
              <li>
                3.
                以程式或自動化方式執行查詢、下載、擷取內容…等方式，使用安家百科網站。
              </li>
              <li>
                4.
                將安家百科網站之內容，載入自行或他人開發之系統中，充作資料庫之部分或全部。
              </li>
            </ul>
          </li>
          <li>
            （四）
            會員若需要下載、重製或其他使用授權，請洽「安家百科股份有限公司」或安家百科客服中心。
          </li>
        </ul>

        <h2>八、終止服務：</h2>
        <p>
          會員若違反本約定條款或其他相關規定，安家百科網站有權決定是否暫時停止或終止會員權利。遭暫時停止或終止權利之會員，不得要求退還相關費用
          ，如有相關事證可證明未違規者，可向安家百科網站提出要求重新處理。
        </p>

        <h2>九、修改會員註冊條款之權利：</h2>
        <p>
          安家百科得因應市場環境的改變、法律規定之修正以及本公司內部管理制度等需要，隨時修正本服務條款、隱私政策之內容、及其他相關安家百科政策。會員如繼續使用服務，將視為接受上述變更，如會員不同意前述修訂，會員將不能繼續使用本網站所提供之各項服務並終止本契約。安家百科承諾會將修正後之約定條款公佈於本網站相關網頁，並自公佈之日起即時生效，安家百科網站不會對會員進行個別通知，請會員自行留意。會員若對於安家百科網站會員註冊條款、隱私權政策、或與個人資料有關之相關事項有任何疑問，可以洽詢安家百科客服中心。
        </p>

        <h2>十、豁免條款；唯一救濟權；賠償：</h2>
        <p>
          在法律允許的最大範圍內，安家百科無須向會員承擔與本服務條款或服務自身引起或有關的任何間接、偶然、從屬、特殊、懲罰性或其他近似損害的賠償責任，包括但不限於收入損失、利潤損失、數據丟失、商業中斷或其他無形損失；不論有關損失是基於契約，侵權或其他法律原理產生，亦不論安家百科是否得悉有可能發生以上損害。在法律允許的範圍內，安家百科
          賠償金額不會多於在會員首次索償的六（6）個月內前，會員根據本服務條款向安家百科繳付的款項。會員明白並同意如自己未有在上述時段向安家百科繳付任何款項，會員只能就安家百科禁止您使用服務或取消您的帳號行使唯一救濟權（安家百科
          的唯一責任） 。
        </p>
        <p>
          本服務條款的任何條款均不影響會員的任何法定權利，也不會排除或限制安家百科因過錯或詐欺行為，而導致人身傷亡和人身損害的賠償責任。
        </p>
        <p>
          您同意就任何索賠，要求，損害或其他損失，賠償
          安家百科（以及我們的管理人員、董事、代理、子公司、合資企業和員工），為我們抗辯，抑或免除我們承擔上述責任；包括由任何第三方基於您的服務使用行為，或違反本服務條款的行為而主張的合理律師費。不過若然侵權行為並非由您故意或意外引起，上述條款則不適用。
        </p>
        <p></p>

        <h2>十一、可分割性：</h2>
        <p>本服務條款中一部分條款無效，不影響其他部分條款之法律效力。</p>

        <h2>十二、一般條款：</h2>
        <ul>
          <li>
            （一）
            轉讓：安家百科有權隨時將全部或部分本服務條款，或安家百科隱私權政策，轉讓或委託給其他人士或實體，並無需事先徵詢會員的同意。未經安家百科事先書面同意，會員不得將本服務條款或隱私權政策下的任何權利或義務轉讓或委託給其他人士或實體。任何未經授權的轉讓或委託均無法律效力。
          </li>
          <li>
            （二）
            補充條款：安家百科可以發布與特定服務（如論壇、競賽或忠誠度計畫）有關的補充條款。使用服務過程應受本服務條款和特定補充條款約束。
          </li>
          <li>
            （三）
            完整協議：本服務條款、任何補充條款，以及透過引用併入的任何文件（包括安家百科隱私政策），會員和安家百科之間的所有共識，得替代先前雙方就本條款內容和服務產生的所有共識，不論相關共識是透過電子形式、口頭、書面，或根據交易習慣、慣例、政策或先例而確立。{" "}
          </li>
          <li>
            （四） 不棄權：安家百科若未能及時要求或嚴格執行本服務條款或
            安家百科隱私政策，抑或未能及時行使相關條款下的任何權利，上述行為不能視作安家百科免除或放棄本服務條款或隱私權政策之權利。即使安家百科明示放棄本服務條款或安家百科
            隱私政策的任何條款、條件或要求，亦不等同放棄會員將來遵守該等條款、條件或要求的義務。除非本服務條款另有明確具體規定，否則任何陳述、聲明、同意、棄權或其他安家百科
            的作為或不作為，均不等同修改本服務條款，亦不具法律約束力。
          </li>
          <li>
            （五） 通知：安家百科可能會在網站或程式中以發佈公告、在
            safehousing.info
            上、以電子郵件或任何會員提供之聯繫方式向會員發出通知。會員根據本服務條款或安家百科隱私政策要求所作的任何通知應以書面形式完成，並寄至：桃園市中壢區青埔里青商路61號2樓。收件人：安家百科股份有限公司，副本：
            service@safehousing.info
          </li>
          <li>
            （六）
            公平補償：會員明白於本服務條款下享有的權利和承擔的義務為獨特和不可替代，由於會員的違約行為或預期違約行為導致的任何損失，都會對安家百科造成無法補救的損害，單單金錢賠償亦不能彌補相應損失；因此，安家百科享有懲罰性救濟或其他公平補償的權利（沒有提供保證、擔保或證據的義務）。會員放棄尋求懲罰性救濟或其他公平補償的所有權利，或禁止或限制服務或任何安家百科營運的權利，利用相關廣告或其他資料，或利用服務和其他透過服務使用或顯示內容的權利，以上決定均不可撤銷。同時，會員亦同意自己享有的金錢損害賠償權（如有）須受第7條規定限制。{" "}
          </li>
          <li>
            （七）
            不可抗力條款：安家百科對於超出其控制範圍而導致的延誤或未能履行的義務不承擔任何責任，包括但不限於不能預見或超出其控制範圍的原因：例如天災、戰爭、恐怖活動、動亂、禁運、民事或軍事當局行為、火災、水災、事故、罷工、交通運輸設施短缺、燃料或能源短缺、勞動力短缺和原料短缺。
          </li>
        </ul>

        <h2>十三、準據法及管轄法院：</h2>
        <p>
          本服務條款之解釋及適用，和與本服務條款有關或會員與安家百科間因交易行為而產生之爭議或糾紛，應適用中華民國法律，並以台灣台中地方法院為第一審管轄法院。
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
