import axiosInstance from "../../axios";
import "./SearchResultDraw.scss";
import axios from "axios";
// import { CircularProgress, Box } from "@mui/material";
import React, { useState, useEffect, useRef, forwardRef } from "react";
import mapboxgl from "mapbox-gl";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/cartReducer";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import GooglePlacesAutocomplete from "../../components/Map/GooglePlacesAutocomplete";
import { setMapState } from "../../redux/mapStateReducer";
// import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import * as turf from "@turf/turf";
// import area from "@turf/area";
import TargetInfoInput from "../../pages/Select/TargetInfoInput";
import { resetTargetInfo } from "../../redux/targetInfoReducer";
import tw_industrial_area_4326 from "./tw_industrial_area_4326.json";
import { debounce } from "lodash";
// import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { TourProvider, useTour } from "@reactour/tour";

// const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
// const gs_username = process.env.REACT_APP_GEOSERVER_BASIC_USERNAME;
// const gs_pass = process.env.REACT_APP_GEOSERVER_BASIC_PASSWORD;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const baseURL = process.env.REACT_APP_API_URL;
mapboxgl.accessToken = mapboxtoken;

const SearchResult = () => {
  const targetInfo = useSelector((state) => state.targetInfo);
  const location = useSelector((state) => state.searchData.searchData.location);
  const searchData = useSelector((state) => state.searchData.searchData);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [clickedBuildingIds, setClickedBuildingIds] = useState(new Set());
  const mapContainer = useRef(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const dispatch = useDispatch();
  const [pkid, setPkid] = useState(null);
  const mapState = useSelector((state) => state.mapState);
  const [productName, setProductName] = useState("M-我的報告");
  const [coor, setCoor] = useState({ lng: 121.30128, lat: 24.99265 });
  const [selectedBuildingId, setSelectedBuildingId] = useState(null);
  const [selectedBuildingInfo, setSelectedBuildingInfo] = useState(null);
  const [isInIndustrialArea, setIsInIndustrialArea] = useState(false);
  const [runTour, setRunTour] = useState(true); // To control the tour
  const [showBeacon, setShowBeacon] = useState(true); // Control the beacon visibility
  const [stepIndex, setStepIndex] = useState(0); // Start from the first step
  const [isTourOpen, setIsTourOpen] = useState(true);

  const { setIsOpen } = useTour();

  useEffect(() => {
    setIsOpen(true); // Automatically start the tour when the app loads
  }, [setIsOpen]);

  // console.log("selectedBuildingInfo", selectedBuildingInfo);
  // console.log("build_h", selectedBuildingInfo?.properties?.build_h);
  // console.log("tw_industrial_area_4326", tw_industrial_area_4326);

  const addIndustrialAreaLayer = () => {
    if (!mapRef.current.getSource("industrial-area")) {
      mapRef.current.addSource("industrial-area", {
        type: "geojson",
        data: tw_industrial_area_4326, // Directly use the imported GeoJSON data
      });

      mapRef.current.addLayer({
        id: "industrial-area",
        type: "fill",
        source: "industrial-area",
        paint: {
          "fill-color": "#ffcc00",
          "fill-opacity": 0.5,
        },
      });
    }
  };

  const checkIfInIndustrialArea = (lng, lat) => {
    const markerPoint = turf.point([lng, lat]);
    // console.log("tw_industrial_area_4326", tw_industrial_area_4326);

    const isInside = tw_industrial_area_4326.features.some((feature) =>
      turf.booleanPointInPolygon(markerPoint, feature)
    );

    setIsInIndustrialArea(isInside);

    if (isInside) {
      toast.error("此建物在工業區內，如欲購買相關資訊，請洽客服人員!");
    }
  };

  const handleBuildingClick = (e, map) => {
    const clickedBuilding = e.features[0];
    const clickedBuildingId = clickedBuilding.properties.uid;

    if (selectedBuildingId) {
      map.setFeatureState(
        { source: "buildings", id: selectedBuildingId },
        { clicked: false }
      );
    }

    map.setFeatureState(
      { source: "buildings", id: clickedBuildingId },
      { clicked: true }
    );
    setSelectedBuildingId(clickedBuildingId);
    setSelectedBuildingInfo(clickedBuilding);
  };

  const addBuildingsLayer = () => {
    if (!mapRef.current.getSource("buildings")) {
      mapRef.current.addSource("buildings", {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
      });
    }

    if (!mapRef.current.getLayer("3d-buildings")) {
      mapRef.current.addLayer({
        id: "3d-buildings",
        type: "fill-extrusion",
        source: "buildings",
        paint: {
          "fill-extrusion-color": [
            "case",
            ["boolean", ["feature-state", "clicked"], false],
            "#f00",
            "#aaa",
          ],
          "fill-extrusion-height": ["get", "build_h"],
          "fill-extrusion-base": 0,
          "fill-extrusion-opacity": 0.75,
        },
      });
    }

    fetchBuildingsInView(mapRef.current);
    mapRef.current.on("moveend", () =>
      fetchBuildingsInViewThrottled(mapRef.current)
    );
  };

  const handlePlaceSelected = (place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    setCoor({ lat, lng });
    checkIfInIndustrialArea(lng, lat);

    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [lng, lat],
        zoom: 17,
      });

      if (markerRef.current) {
        markerRef.current.setLngLat([lng, lat]);
      } else {
        markerRef.current = new mapboxgl.Marker()
          .setLngLat([lng, lat])
          .addTo(mapRef.current);
      }
    }
  };

  // const handlePlaceSelected = (place) => {
  //   const lat = place.geometry.location.lat();
  //   const lng = place.geometry.location.lng();

  //   setCoor({ lat, lng });

  //   if (mapRef.current) {
  //     mapRef.current.flyTo({
  //       center: [lng, lat],
  //       zoom: 15,
  //     });

  //     if (markerRef.current) {
  //       markerRef.current.setLngLat([lng, lat]);
  //     } else {
  //       markerRef.current = new mapboxgl.Marker()
  //         .setLngLat([lng, lat])
  //         .addTo(mapRef.current);
  //     }
  //   }
  // };

  const switchBaseMap = (style) => {
    if (mapRef.current) {
      const currentStyle = mapRef.current.getStyle();
      mapRef.current.setStyle("mapbox://styles/mapbox/" + style);
      mapRef.current.once("style.load", () => {
        if (markerRef.current) {
          markerRef.current.addTo(mapRef.current);
        }
      });
    }
  };

  useEffect(() => {
    return () => {
      if (mapRef.current && selectedBuildingId) {
        mapRef.current.setFeatureState(
          { source: "buildings", id: selectedBuildingId },
          { clicked: false }
        );
      }
    };
  }, [selectedBuildingId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (pkid === null) {
      toast.warning("您尚未登入！請先登入再進行此操作。");
      return;
    }

    if (selectedBuildingId === null) {
      toast.error("請先在地圖上點選分析區域!");
      return;
    }
    if (isInIndustrialArea) {
      toast.error("此建物在工業區內，如欲購買相關資訊，請洽客服人員!");
      return;
    }

    // Validate targetInfo values
    if (
      !Number.isInteger(targetInfo.totalFloorNumber) ||
      targetInfo.totalFloorNumber <= 0
    ) {
      toast.error("請輸入有效的建物總樓層數，且總樓層數應為正整數。");
      return;
    }

    if (
      !Number.isInteger(targetInfo.floorNumber) ||
      targetInfo.floorNumber <= 0 ||
      targetInfo.floorNumber > targetInfo.totalFloorNumber
    ) {
      toast.error(
        "請輸入有效的建物所在樓層，且樓層應為正整數，並不超過總樓層數。"
      );
      return;
    }

    if (
      targetInfo.buildingTime &&
      (targetInfo.buildingTime < 1950 ||
        targetInfo.buildingTime > new Date().getFullYear())
    ) {
      toast.error("請輸入有效的建造時間，必須介於1950年到當前年份之間。");
      return;
    }

    // Add any additional validation checks for other fields here
    if (!targetInfo.buildingMaterial || !targetInfo.buildingFoundation) {
      toast.error("請選擇建物材質與基礎。");
      return;
    }

    if (targetInfo.designPga < 0 || !targetInfo.designPga) {
      toast.error("建築抗震設計應為非負數。");
      return;
    }

    if (targetInfo.buildingValue < 0 || !targetInfo.buildingValue) {
      toast.error("建物價值應為非負數。");
      return;
    }

    if (targetInfo.pingAll < targetInfo.pingIndoor) {
      toast.error("總坪數應大於或等於室內坪數。");
      return;
    }
    if (!targetInfo.buildingTime) {
      toast.error("請填入建造時間。");
      return;
    }
    if (!targetInfo.maskingHeight) {
      toast.error("請填入建物1F地板比人行道高幾公分。");
      return;
    }
    if (!targetInfo.furnitureLoss) {
      toast.error("請填入附屬物價值(傢俱)。");
      return;
    }
    if (!targetInfo.carValue) {
      toast.error("請填入汽機車價值。");
      return;
    }
    if (!targetInfo.renovationValue) {
      toast.error("請填入裝潢總價。");
      return;
    }
    if (!targetInfo.pingIndoor) {
      toast.error("請填入坪數(不含公設)。");
      return;
    }
    if (!targetInfo.pingAll) {
      toast.error("請填入坪數(含公設)。");
      return;
    }

    const product_title = `安家報告202409`;

    const desc = {
      searchData: searchData,
      final_location: {
        lng: coor["lng"],
        lat: coor["lat"],
      },
      choose_type: "click",
      // selectedBuildingInfo: JSON.stringify(selectedBuildingInfo), // Stringify the building info
      selectedBuildingInfo: selectedBuildingInfo, // Stringify the building info
    };

    const geometryData = selectedBuildingInfo.geometry;

    const formData = {
      title: product_title,
      name: productName,
      description: JSON.stringify(desc),
      hazard_type: "Flood",
      total_price: 500,
      radius: 105,
      created_by_customer: pkid,
      product_type: "building",
      geo_data: JSON.stringify(geometryData),
      target_info: JSON.stringify(targetInfo),
    };

    // console.log("formData", formData);

    try {
      const response = await axiosInstance.post(`risk/products/`, formData);
      const data = response.data;

      dispatch(
        addToCart({
          id: data.pkid,
          title: data.title,
          desc: data.desc,
          price: data.total_price,
          quantity: 1,
        })
      );

      toast.success("報告生產成功! 請到我的報告查看結果!");
      // toast.success("成功加入購物車!");
    } catch (error) {
      // Check if the error response contains the max report generation error
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error.includes("您已達到最大生產報告次數")
      ) {
        toast.error(
          "您已達到最大生產報告次數! 一般用戶可免費產生5次報告，付費用戶(已購買會員點數)則可以產生20次報告!"
        );
      } else {
        toast.error("報告生產失敗，請重試或聯繫客服人員。");
      }
    }

    // const response = await axiosInstance.post(`risk/products/`, formData);
    // const data = response.data;

    // dispatch(
    //   addToCart({
    //     id: data.pkid,
    //     title: data.title,
    //     desc: data.desc,
    //     price: data.total_price,
    //     quantity: 1,
    //   })
    // );

    // toast.success("成功加入購物車!");
  };

  useEffect(() => {
    const getPkid = async () => {
      try {
        const res = await axiosInstance.get("auth/users/me/");
        setPkid(res.data.pkid);
      } catch (err) {
        toast.warning("您目前尚未登入喔!");
      }
    };
    getPkid();
  }, []);

  useEffect(() => {
    if (mapRef.current) return;

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: mapState.center,
      zoom: mapState.zoom,
      maxZoom: 17,
      minZoom: 11,
      pitch: 0,
      bearing: 0,
      // pitch: mapState.pitch,
      // bearing: mapState.bearing,
    });

    mapRef.current = map;

    markerRef.current = new mapboxgl.Marker()
      .setLngLat(mapState.center)
      .addTo(map);

    mapRef.current.on("load", () => {
      addBuildingsLayer();
      addIndustrialAreaLayer();
      selectInitialBuilding(mapRef.current, mapState.center);
    });

    mapRef.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      }),
      "top-right"
    );
    mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-right");
    mapRef.current.addControl(new mapboxgl.FullscreenControl(), "top-right");
    // mapRef.current.on("moveend", () => fetchBuildingsInView(mapRef.current));
    mapRef.current.on("moveend", () =>
      fetchBuildingsInViewThrottled(mapRef.current)
    );

    map.on("click", (e) => {
      const { lng, lat } = e.lngLat;
      setCoor({ lng, lat });
      // console.log({ lng, lat })
      checkIfInIndustrialArea(lng, lat);

      if (!markerRef.current) {
        markerRef.current = new mapboxgl.Marker()
          .setLngLat([lng, lat])
          .addTo(mapRef.current);
      } else {
        markerRef.current.setLngLat([lng, lat]);
      }

      mapRef.current.flyTo({
        center: [lng, lat],
        duration: 2000,
        essential: true,
        zoom: 17,
      });
    });

    mapRef.current.on("click", "3d-buildings", (e) =>
      handleBuildingClick(e, mapRef.current)
    );

    mapRef.current.on("moveend", () => {
      const center = mapRef.current.getCenter();
      const zoom = mapRef.current.getZoom();
      const pitch = mapRef.current.getPitch();
      const bearing = mapRef.current.getBearing();

      dispatch(
        setMapState({
          center: [center.lng, center.lat],
          zoom,
          pitch,
          bearing,
        })
      );
    });

    mapRef.current.on("zoom", () => {
      const currentZoom = mapRef.current.getZoom();
      if (currentZoom >= 15.5) {
        addBuildingsLayer();
      } else {
        if (mapRef.current.getLayer("3d-buildings")) {
          mapRef.current.removeLayer("3d-buildings");
          mapRef.current.removeSource("buildings");
        }
      }
    });

    return () => {
      if (mapRef.current) {
        mapRef.current.off("load");
        mapRef.current.off("moveend");
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  const selectInitialBuilding = (map, center) => {
    if (!map.isStyleLoaded()) {
      console.error("Style not loaded");
      return;
    }

    const point = map.project(center);

    const features = map.queryRenderedFeatures(point, {
      layers: ["3d-buildings"],
    });

    if (features.length > 0) {
      const building = features[0];
      map.setFeatureState(
        { source: "buildings", id: building.properties.uid },
        { clicked: true }
      );
      setSelectedBuildingId(building.properties.uid);
    } else {
      console.error("No buildings found at this point");
    }
  };

  const cache = new Map();

  const fetchBuildingsInView = (map) => {
    // Get the map bounds
    const bounds = map.getBounds();
    const boundsKey = JSON.stringify(bounds); // Create a key for caching

    // Add a buffer (e.g., 0.01 degrees in each direction)
    const buffer = 0.001;

    const bufferedBounds = [
      bounds.getWest() - buffer,
      bounds.getSouth() - buffer,
      bounds.getEast() + buffer,
      bounds.getNorth() + buffer,
    ];

    setIsLoading(true);

    if (cache.has(boundsKey)) {
      // Use cached data
      map.getSource("buildings").setData(cache.get(boundsKey));
      return;
    }

    // Make the request using the buffered bounds
    axios
      .get(`${baseURL}risk/geo/ows?`, {
        // .get(`${geoserver_url}geoserver/staging/ows`, {
        params: {
          service: "WFS",
          version: "1.0.0",
          request: "GetFeature",
          typeName: `staging:building_all_20230208_uid`,
          bbox: bufferedBounds.join(","), // Use the buffered bounds
          outputFormat: "application/json",
        },
        // auth: {
        //   username: gs_username,
        //   password: gs_pass,
        // },
      })
      .then((response) => {
        const data = response.data;
        data.features.forEach((feature) => {
          feature.id = feature.properties.uid;
        });
        map.getSource("buildings").setData(data);
        cache.set(boundsKey, data); // Cache the data

        clickedBuildingIds.forEach((id) => {
          map.setFeatureState({ source: "buildings", id }, { clicked: true });
        });

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the GeoServer data:", error);
        setIsLoading(false);
      });
  };

  const fetchBuildingsInViewThrottled = debounce(fetchBuildingsInView, 500);

  const handleProductNameChange = (event) => {
    setProductName(event.target.value);
  };

  return (
    <div className="select-container">
      {/* <button onClick={() => setIsOpen(true)}>Open Tour</button> */}
      <p style={{ fontSize: "12px" }}>
        一般用戶可產生5次免費版報告，付費用戶(已購買會員點數)則可以產生20次免費版報告!
      </p>
      <div //map part
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          borderRadius: "4px",
        }}
        className="report-rules"
      >
        <div
          ref={mapContainer}
          style={{
            width: "100%",
            height: "50vh",
            padding: "10px",
            borderRadius: "4px",
          }}
        />
        <div
          style={{
            position: "absolute",
            // top: "10%",
            // left: "10%",
            top: "20px",
            left: "20px",
            width: "20px",
            height: "20px",
            // transform: "translate(-10%, -10%)",
          }}
          className="google-ride"
        />
        <div style={{ position: "absolute" }} className="google-geocoder">
          <GooglePlacesAutocomplete
            onPlaceSelected={handlePlaceSelected}
            isMapLoaded={true}
          />
        </div>

        <div
          id="menu"
          style={{
            position: "absolute",
            bottom: 10,
            left: "50%",
            transform: "translateX(-50%)",
            background: "#efefef",
            padding: "5px",
            zIndex: 1,
            border: "solid 1px",
            borderRadius: "4px",
          }}
        >
          <input
            id="streets-v12"
            type="radio"
            name="rtoggle"
            value="streets-v12"
            onChange={() => switchBaseMap("streets-v12")}
            defaultChecked
          />
          <label
            htmlFor="streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            街道
          </label>
          <input
            id="satellite-streets-v12"
            type="radio"
            name="rtoggle"
            value="satellite-streets-v12"
            onChange={() => switchBaseMap("satellite-streets-v12")}
          />
          <label
            htmlFor="satellite-streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            衛星
          </label>
        </div>
      </div>
      <div //note part
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            // position: "absolute",
            right: "5px",
            fontSize: "12px",
            // top: "5px"
          }}
        >
          3D底圖來源：內政部國土測繪中心
        </p>
      </div>
      <div
        className="click-on-map"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <p>
          <span
            style={{ fontSize: "16px", color: "black", fontWeight: "bold" }}
          >
            點選出紅色的分析區域!
          </span>
          或切換
          <Link to={`/search_result_draw`}>
            <span
              style={{
                fontWeight: "bold",
                color: "red",
                textDecoration: "none",
              }}
            >
              手繪模式
            </span>
          </Link>
          自行繪製分析區域
        </p>
      </div>
      <div className="target-ride">
        <TargetInfoInput />
      </div>
      <div className="submit-container" style={{ padding: "10px 0px" }}>
        <div
          style={{
            backgroundColor: "white",
            fontSize: "small",
            overflowY: "auto",
            gap: "10px",
            display: "flex",
            flexDirection: "column",
            padding: "10px",
          }}
        >
          <div
            className="stack"
            style={{
              display: "flex",
              alignItems: "start",
              gap: "5px",
              flexDirection: "row",
            }}
          >
            <label
              htmlFor="product-name"
              style={{
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              名稱:{" "}
            </label>
            <input
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                maxWidth: "150px",
              }}
              type="text"
              id="product-name"
              value={productName}
              onChange={handleProductNameChange}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <button
              className="reset"
              onClick={() => dispatch(resetTargetInfo())}
            >
              重置參數
            </button>
            <button className="add" onClick={handleSubmit}>
              {/* 加入購物車 */}
              生產報告
            </button>
            {/* <p>
              <strong>會員點數: 500 點</strong>
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResult;
