// src/features/waterProbData/waterProbDataSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  waterProbData: {
    "10cm": 53.0,
    "20cm": 44.0,
    "30cm": 30.0,
    "40cm": 20.732,
    "50cm": 13.015,
    "60cm": 9.465,
    "70cm": 4.254,
    "80cm": 0.715,
    "90cm": 0.52,
    "100cm": 0.3,
    "110cm": 0.1,
    "120cm": 0.1,
    "130cm": 0.1,
    "140cm": 0.1,
    "150cm": 0.1,
    "160cm": 0.1,
    "170cm": 0.0,
    "180cm": 0.0,
    "190cm": 0.0,
    "200cm": 0.0,
  },
};

export const waterProbDataSlice = createSlice({
  name: 'waterProbData',
  initialState,
  reducers: {
    setWaterProbData: (state, action) => {
      state.waterProbData = action.payload;
    },
    resetWaterProbData: (state) => {
      state.waterProbData = initialState.waterProbData;
    },
  },
});

export const { setWaterProbData, resetWaterProbData } = waterProbDataSlice.actions;

export default waterProbDataSlice.reducer;
