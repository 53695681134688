import React, { useEffect, useState } from "react";
import "./Qa.scss";
import axiosInstance from "../../axios";

const Qa = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [qa, setQa] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const get_qa = async () => {
      try {
        const res = await axiosInstance.get("blog/qaviewset/");
        setQa(res.data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    get_qa();
  }, []);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  if (loading) return <div className="qa-container">Loading...</div>;

  return (
    <div className="qa-container">
      <h1>常見問答Q&A</h1>
      {qa.map((item, index) => (
        <div key={index} className="qa-block">
          <button className="qa-button" onClick={() => handleClick(index)}>
            {`${index + 1}. ${item.question}`}
          </button>
          {activeIndex === index && <p className="qa-answer">{item.answer}</p>}
        </div>
      ))}
    </div>
  );
};

export default Qa;
