import authReducer from "../features/auth/authSlice";
import cartReducer from "./cartReducer";
import waterProbDataReducer from "./waterProbDataReducer";
import searchDataReducer from "./searchDataReducer";
// import faultDataReducer from "./faultDataReducer";
import polygonDataReducer from "./polygonDataReducer";
import targetInfoReducer from "./targetInfoReducer";
import risksDataReducer from "./risksDataReducer";
import mapStateReducer from "./mapStateReducer";
import { configureStore } from "@reduxjs/toolkit";

//for persistence
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "@reduxjs/toolkit";
import { createMigrate } from "redux-persist";

const migrations = {
  1: (state) => {
    // Your previous migration logic here...
    return {
      ...state,
    };
  },
  2: (state) => {
    // Migration for new fields in targetInfoReducer
    return {
      ...state,
      targetInfo: {
        ...state.targetInfo,
        buildingFoundation:
          state.targetInfo.buildingFoundation ?? "未知(可重新評估)",
        underground: state.targetInfo.underground ?? 1,
      },
    };
  },
  3: (state) => {
    // Migration for new fields in targetInfoReducer
    return {
      ...state,
      targetInfo: {
        ...state.targetInfo,
        buildingFoundation:
          state.targetInfo.buildingFoundation ?? "未知(可重新評估)",
        underground: state.targetInfo.underground ?? 1,
        test_migrate: state.targetInfo.test_migrate ?? 2,
      },
    };
  },
};

const rootReducer = combineReducers({
  cart: cartReducer,
  auth: authReducer,
  mapState: mapStateReducer,
  waterProbData: waterProbDataReducer,
  polygonData: polygonDataReducer,
  searchData: searchDataReducer,
  // faultData: faultDataReducer,
  targetInfo: targetInfoReducer,
  risksData: risksDataReducer,
});

const persistConfig = {
  key: "root",
  version: 3, // Make sure this is the highest number in your migrations object
  storage,
  migrate: createMigrate(migrations, { debug: true }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);
