import React from "react";
import "./About.scss";

const About = () => {
  return (
    <div className="about-container">
      <div className="title-container">
        <div className="report-h0">SAFEHOUSING</div>
        <h3 className="title-text">關於我們</h3>
        <hr className="title-divider" />
      </div>

      <div className="about-section">
        <div className="about-image">
          <img src="/img/about01.jpg" alt="Vision" />
        </div>
        <div className="about-text">
          <div className="about-title">
            <div className="title-text">
              <h5 className="white">願景</h5>
              <h5 className="gray">&nbsp;Vision</h5>
            </div>
            <p className="second_color text-end">What we want to achieve?</p>
          </div>
          <p>
            普羅大眾得以安居樂業，是穩定社會狀態的顯著表徵。
            <br />
            <br />
            保障人類的生命安全、守護人文的珍貴資產、協助建立穩固的防災措施，是我們用心致力奉獻、回饋社會的目標。
            <br />
            <br />
            我們追求幸福，希望讓世上每個人，都能住得安心。
          </p>
        </div>
        <div className="small-word">
          <hr />
          <p>SAFEHOUSING</p>
        </div>
      </div>

      <div className="about-section reverse">
        <div className="about-image">
          <img src="/img/about02.jpg" alt="Values and Beliefs" />
        </div>
        <div className="about-text">
          <div className="about-title">
            <div className="title-text">
              <h5>理念</h5>
              <h5 className="gray">&nbsp;Values and Beliefs</h5>
            </div>
            <p className="second_color text-end">Why are we here?</p>
          </div>
          <p>
            不斷看到世界各地受到天然災害嚴重影響生活，人命的傷亡和財產的損失，極端氣候令這些災難帶來的破壞性愈來愈大。
            <br />
            <br />
            有幸我們活在資訊爆發的年代，數據的蒐集、分析和運算，都有條件高效地、精準地、務實地讓未來更可控。
            <br />
            <br />
            我們致力為世界帶來更完備的資訊，利用科技的力量改善生活素質。
          </p>
        </div>
      </div>

      <div className="about-section">
        <div className="about-image">
          <img src="/img/about03.jpg" alt="Mission" />
        </div>
        <div className="about-text">
          <div className="about-title">
            <div className="title-text">
              <h5 className="white">使命</h5>
              <h5 className="gray">&nbsp;Mission</h5>
            </div>
            <p className="second_color text-end">What we want to achieve?</p>
          </div>
          <p>
            天然災害是地球自古以來就不斷反復進行的自然活動，不能把它消滅，就要想辦法與它和平共處。
            <br />
            <br />
            然而市場顯然未能有效將原始數據帶到應用層面，我們希望運用精密的演算工程，釋放出真正對人有價值的實用潛能，讓社會共同享受資訊帶來的進步紅利。
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
