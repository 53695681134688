import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
} from "@mui/material";
import "./ChooseType.scss";

const ChooseType = () => {
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <Box className="choose-type" style={{ padding: "20px" }}>
      <Typography
        variant="h4"
        component="h1"
        className="bold-title"
        style={{ marginBottom: "20px" }}
      >
        選擇分析區域方式
      </Typography>

      <Box className="card-group">
        <Card style={{ flex: 1 }}>
          <CardMedia
            component="img"
            height="140"
            image="/radius.png"
            alt="Radius Method"
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="bold-title"
            >
              以中心點與半徑選擇區域
            </Typography>
            <Typography variant="body2" color="text.secondary">
              在地圖上點選要分析的中心點位與其半徑，此方法適用於想要分析一個中心點半徑範圍內的實體風險，如房屋150公尺內的災害風險
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={() => handleClick("/citymapradius")}
              sx={{
                backgroundColor: "#2879fe", // Button color
                fontWeight: "bolder",
                color: "white", // Text color
                "&:hover": {
                  backgroundColor: "darken(#2879fe, 10%)", // Darken on hover
                },
              }}
            >
              下一步
            </Button>
          </CardActions>
        </Card>
        <Card style={{ flex: 1 }}>
          <CardMedia
            component="img"
            height="140"
            image="/building.png"
            alt="Radius Method"
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="bold-title"
            >
              以建物選擇區域
            </Typography>
            <Typography variant="body2" color="text.secondary">
              在地圖上點選要分析的建物，此方法適用於想要分析單一建物/相連建物的實體風險...
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={() => handleClick("/citymapbuilding")}
              sx={{
                backgroundColor: "#2879fe", // Button color
                fontWeight: "bolder",
                color: "white", // Text color
                "&:hover": {
                  backgroundColor: "darken(#2879fe, 10%)", // Darken on hover
                },
              }}
            >
              下一步
            </Button>
          </CardActions>
        </Card>
        <Card style={{ flex: 1 }}>
          <CardMedia
            component="img"
            height="140"
            image="/draw.png"
            alt="Radius Method"
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="bold-title"
            >
              以畫圖選擇區域
            </Typography>
            <Typography variant="body2" color="text.secondary">
              自行在地圖上畫出要分析的位置，此方法適用於想要分析一特定形狀與區域的實體風險...
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={() => handleClick("/citymapdraw")}
              sx={{
                backgroundColor: "#2879fe", // Button color
                fontWeight: "bold",
                color: "white", // Text color
                "&:hover": {
                  backgroundColor: "darken(#2879fe, 10%)", // Darken on hover
                },
              }}
            >
              下一步
            </Button>
          </CardActions>
        </Card>
        {/* Repeat for other options */}
      </Box>
    </Box>
  );
};

export default ChooseType;
