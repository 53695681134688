import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function StackedVerticalComparisonBarChart({ total_loss }) {
  const pastLoss = total_loss * 3.5;
  const nowLoss = total_loss;
  const govermentLoss = pastLoss - total_loss;

  const data = [
    {
      name: "過去",
      個人或社區工程: 0,
      保險: 0,
      政府作為: 0,
      災害風險: pastLoss,
    },
    {
      name: "現況",
      個人或社區工程: 0,
      保險: 0,
      政府作為: govermentLoss,
      災害風險: nowLoss,
    },
    {
      name: "工程",
      個人或社區工程: nowLoss,
      保險: 0,
      政府作為: govermentLoss,
      災害風險: 0,
    },
    {
      name: "工程+保險",
      個人或社區工程: 0.5 * nowLoss,
      保險: 0.5 * nowLoss,
      政府作為: govermentLoss,
      災害風險: 0,
    },
    {
      name: "保險",
      個人或社區工程: 0,
      保險: nowLoss,
      政府作為: govermentLoss,
      災害風險: 0,
    },
  ];

  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        barSize={50}
        barGap={1}
        barCategoryGap={20}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="category"
          dataKey="name"
          //   interval={0}
          //   height={70}
          //   angle={-30}
          //   textAnchor="end"
        />
        <YAxis
          type="number"
          label={{
            value: `新台幣(元)`,
            angle: -90,
            position: "insideLeft",
            dy: 50, // Adjust this value as needed to position the label lower
            dx: -2,
            fill: "black",
          }}
          style={{ fontSize: "10px" }}
        />
        <Tooltip />
        <Legend />
        <Bar dataKey="個人或社區工程" stackId="a" fill="#00b9b3" />
        <Bar dataKey="保險" stackId="a" fill="#415434" />
        <Bar dataKey="災害風險" stackId="a" fill="red" />
        <Bar dataKey="政府作為" stackId="a" fill="#147d6a" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default StackedVerticalComparisonBarChart;
