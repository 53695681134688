import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import floodLevels from "./floodLevels.js";

const Sugguestions = ({ report, productTargetInfo }) => {
  const preventionMeasures = [
    "熟知住所附近淹水情況，遠離淹水潛勢區。",
    "購買包括淹水保障的保險，以防意外發生。",
    "對於可能的淹水事件，提前制定緊急應對計劃。",
    "在家裡安裝防水設施，例如沙袋、防水門檻等。",
    "定期查看氣象預報，提前預警淹水可能。",
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            防災建議
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {report.sugguestions.replace(/^\('(.*)',\)$/, "$1")}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            行動方案
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {report.action_plans.replace(/^\('(.*)',\)$/, "$1")}
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Repeat for each type of risk or data segment */}

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            淹水深度的影響與行動方案參考
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* <Typography> */}
          <div className="img_floodHeight">
            <img
              className="floos_obj"
              src="/img/report_floodHeight_house.png"
              alt="Flood Height House"
            ></img>
            <div className="water_height" style={{ height: "4%", zIndex: "5" }}>
              <p className="water_height_text">10 cm</p>
            </div>
            <div className="water_height" style={{ height: "8%", zIndex: "4" }}>
              <p className="water_height_text">20 cm</p>
            </div>
            <div
              className="water_height"
              style={{ height: "12%", zIndex: "3" }}
            >
              {" "}
              <p className="water_height_text">30 cm</p>
            </div>
            <div
              className="water_height"
              style={{ height: "16%", zIndex: "2" }}
            >
              {" "}
              <p className="water_height_text">40 cm</p>
            </div>
            <div
              className="water_height"
              style={{ height: "20%", zIndex: "1" }}
            >
              {" "}
              <p className="water_height_text">50 cm</p>
            </div>
          </div>

          <div className="flood-level-explain">
            <h5 className="primary_color pb-2 mb-3 mb-0 mt-5">
              淹水深度的影響與行動方案參考:
            </h5>
            <ul style={{ listStyle: "square" }}>
              {floodLevels.map((floodLevel, index) => (
                <li key={index}>
                  {floodLevel.level}
                  <ul>
                    {floodLevel.impacts.map((impact, i) => (
                      <li key={i}>
                        {impact.category}: {impact.description}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
              <li>
                預防措施
                <ul>
                  {preventionMeasures.map((measure, index) => (
                    <li key={index}>{measure}</li>
                  ))}
                </ul>
              </li>
            </ul>
            <p>
              以上所提供的數據和價格只是大致的估計，具體情況會因地理位置、產品品牌、維修服務等多種因素變化，可能需要由專業人員進行評估。
            </p>
          </div>
          {/* </Typography> */}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Sugguestions;
