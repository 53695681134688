import React from "react";
import Categories from "../../components/Categories/Categories";
// import Follow from "../../components/Follow/Follow";
// import FeaturedProducts from '../../components/FeaturedProducts/FeaturedProducts'
// import Slider from "../../components/Slider/Slider";
import Rain from "../../components/Rain/Rain";
// import Slider2 from "../../components/Slider/Slider2";
// import Slider3 from "../../components/Slider/Slider3";
import StormText from "../../components/StormText/StormText";
import FeatureText from "../../components/FeatureText/FeatureText";
// import MapMove from "../../components/Map/MapMove";


// import Compare from '../../components/Compare/Compare'
import "./Home.scss";
import Title from "../../components/Title/Title";
import Showcase from "../../components/Showcase/Showcase";
import Price from "../../components/Price/Price";
import SearchHome from "../../components/SearchHome/SearchHome";
// import MapboxSpin from '../../components/Map/MapboxSpin'

const Home = () => {
  return (
    <div className="home">
      <Title title="歡迎來到-安家百科" />
      <Rain />
      {/* <SearchHome/> */}
      <Showcase />
      <StormText />
      <FeatureText />
      {/* <Price/> */}
      {/* <FeaturedProducts type="featured"/> */}
      {/* <Compare/> */}
      {/* <FeaturedProducts type="trending"/> */}
      <Categories />
      {/* <Follow /> */}
    </div>
  );
};

export default Home;
