import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import "./Profile.scss";
import axiosInstance from "../../axios";

export default function Profile() {
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    full_name: "",
    email: "",
    phone_number: "",
    profile_photo: "http://localhost/mediafiles/profile_default.png",
    // profile_photo: "/img/avatar/female.jpg",
    about_me: "",
    gender: "",
    country: "",
    city: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSave = () => {
    // send formData to server to save changes
    const updateProfile = async () => {
      try {
        // console.log(formData);

        const updatedata = {
          // username: formData.username,
          // email: formData.email,
          phone_number: formData.phone_number,
          about_me: formData.about_me,
          gender: formData.gender,
          country: formData.country,
          city: formData.city,
        };
        const res = await axiosInstance.patch(
          `profile/update/${formData.username}/`,
          updatedata
        );

        // console.log(formData)
        // Update the profile state with the updated data
        // setFormData(res.data.profile);
        setFormData({ ...formData, ...res.data.profile });
        // Set editing to false to exit the editing mode
        setEditing(false);
      } catch (err) {
        // Handle any errors
        console.error(err);
      }
    };
    updateProfile();
    setEditing(false);
  };

  useEffect(() => {
    const getProfile = async () => {
      try {
        const res = await axiosInstance.get("profile/me/");
        setFormData(res.data.profile);
      } catch (err) {
        // console.log(err);
      }
    };
    getProfile();
  }, []);

  return (
    <div className="profile-container">
      <Avatar
        // alt={formData.full_name}
        // src={formData.profile_photo}
        src="/img/avatar/logo_word.png"
        className="avatar"
      />
      {editing ? (
        <div className="form-container">
          <TextField
            label="E-mail"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="text-field"
          />
          <TextField
            label="電話"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            className="text-field"
          />
          <TextField
            label="大頭照"
            name="profile_photo"
            value={formData.profile_photo}
            onChange={handleChange}
            className="text-field"
          />
          {/* <TextField
            label="關於我"
            name="about_me"
            value={formData.about_me}
            onChange={handleChange}
            className="text-field"
            multiline
            rows={4}
          /> */}
          <FormControl component="fieldset" className="text-field">
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
            >
              <FormControlLabel
                value="Female"
                control={<Radio />}
                label="女性"
              />
              <FormControlLabel value="Male" control={<Radio />} label="男性" />
              <FormControlLabel
                value="Other"
                control={<Radio />}
                label="其他"
              />
            </RadioGroup>
          </FormControl>
          <TextField
            label="國家"
            name="country"
            value={formData.country}
            onChange={handleChange}
            className="text-field"
          />
          <TextField
            label="城市"
            name="city"
            value={formData.city}
            onChange={handleChange}
            className="text-field"
          />
        </div>
      ) : (
        // <div className="info-container">{/* ... info elements */}</div>
        <div className="info-container">
          <div>
            <strong>名稱:</strong> {formData.username}
          </div>
          <div>
            <strong>E-mail:</strong> {formData.email}
          </div>
          <div>
            <strong>電話:</strong> {formData.phone_number}
          </div>
          <div>
            {/* <strong>Profile Photo:</strong>{" "}
            <img
              src={formData.profile_photo}
              alt={formData.full_name}
              width="100"
              className="profile-photo"
            /> */}
            {/* <div>
              <strong>關於我:</strong> {formData.about_me}
            </div> */}
            <div>
              <strong>性別:</strong> {formData.gender}
            </div>
            <div>
              <strong>地點:</strong> {formData.city}, {formData.country}
            </div>
          </div>
        </div>
      )}
      {editing ? (
        <div className="button-container">
          <Button
            variant="contained"
            color="primary"
            className="save-button"
            onClick={handleSave}
            style={{ marginRight: "10px" }} // Add right margin
          >
            儲存
          </Button>
          <Button
            variant="contained"
            className="cancel-button"
            onClick={() => setEditing(false)}
            style={{ marginLeft: "10px" }} // Add right margin
          >
            取消
          </Button>
        </div>
      ) : (
        <div className="button-container">
          <Button
            variant="contained"
            color="primary"
            className="edit-button"
            onClick={() => setEditing(true)}
          >
            編輯資料
          </Button>
        </div>
      )}
    </div>
  );
}
