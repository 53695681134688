import React from "react";
import "./Categories.scss";
import { Link } from "react-router-dom";

const Categories = () => {
  return (
    <div className="sfh-categories mt-3 mb-2">
      <div className="sfh-col">
        <div className="sfh-row sfh-hover">
          <img
            src="https://images.pexels.com/photos/142497/pexels-photo-142497.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=""
          />
          <div className="black w-100 position-absolute"></div>
          <div className="sfh-button">
            <Link className="link" to="/buypoint">
              現正特賣
            </Link>
          </div>
        </div>
        <div className="sfh-row sfh-hover">
          <img
            src="https://images.pexels.com/photos/763398/pexels-photo-763398.jpeg"
            alt=""
          />
          <div className="black w-100 position-absolute"></div>
          <div className="sfh-button">
            <Link to="/" className="link">
              高精度淹水圖
            </Link>
          </div>
        </div>
      </div>
      <div className="sfh-col">
        <div className="sfh-row sfh-hover">
          {" "}
          <img
            src="https://images.pexels.com/photos/7806175/pexels-photo-7806175.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt=""
          />
          <div className="black w-100 position-absolute"></div>
          <div className="sfh-button">
            <Link to="/comingsoon" className="link">
              斷層帶/房屋地震風險
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="col col-l"> */}
      <div className="sfh-col">
        <div className="sfh-row">
          <div className="sfh-col">
            <div className="sfh-row sfh-hover">
              <img
                src="https://images.unsplash.com/photo-1621315898086-0e940d7a221e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
                alt=""
              />
              <div className="black w-100 position-absolute"></div>
              <div className="sfh-button">
                <Link to="/comingsoon" className="link">
                  土壤液化/山崩
                </Link>
              </div>
            </div>
          </div>
          <div className="sfh-col">
            <div className="sfh-row sfh-hover">
              {" "}
              <img
                src="https://images.unsplash.com/photo-1454789476662-53eb23ba5907?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=452&q=80"
                alt=""
              />
              <div className="black w-100 position-absolute"></div>
              <div className="sfh-button">
                <Link to="/" className="link">
                  極端事件評估
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="sfh-row sfh-hover">
          <img
            src="https://images.pexels.com/photos/2258536/pexels-photo-2258536.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=""
          />
          <div className="black w-100 position-absolute"></div>
          <div className="sfh-button">
            <Link to="/" className="link">
              客製化分析
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
