import React from "react";
import { useSelector } from "react-redux";
import DepthProbBuilding from "../Prob/DepthProbBuilding";
import DepthProbBuildingFixed from "../Prob/DepthProbBuildingFixed";
import DepthProbTableBuilding from "../Prob/DepthProbTableBuilding";
import DepthProbTableBuildingFixed from "../Prob/DepthProbTableBuildingFixed";
import FloodRiskChart from "../FloodRiskChart/FloodRiskChart";
import NewsSearch from "./NewsSearch";
import "./FloodResult.scss";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Compare from "./Compare_v2";
import FloodPrepare from "./FloodPrepare";
import FloodSolution from "./FloodSolution";
const FloodResult = ({ report, productTargetInfo, furnitureLoss }) => {
  const waterProbData = report?.p0_200_interpolated;
  // const waterProbDataMasked = report?.risk_results["flood"];
  // console.log("test loop");

  const floodRisk = report.risk_results["flood"][0];
  let riskMessage = "";

  if (floodRisk > 1000) {
    riskMessage = "建議加裝防水閘版、淹水保險。";
  } else if (floodRisk > 100 && floodRisk <= 1000) {
    riskMessage = "可加裝防水閘版、或斟酌是否購買淹水保險。";
  } else {
    riskMessage = "恭喜你買到淹水機率低的房屋!";
  }

  if (!waterProbData) {
    console.error("waterProbData is not available");
    return <div>Loading or error...</div>;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Accordion>
        {/* <Accordion defaultExpanded> */}
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            淹水潛勢圖比較
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="section">
            高精度淹水分析(左側為公開資料，右側為本系統分析
            )，6小時350mm降雨情境
          </Typography>
          <div>
            <Compare report={report} />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            淹水風險概況
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <FloodRiskChart total_loss={report.risk_results["flood"][0]} />
          </div>
          <Typography
            sx={{ padding: "15px" }}
            variant="body1"
            component="div"
            gutterBottom
          >
            氣候變遷的主要影響為海平面上升與降雨增強，因此淹水深度與發生機率增加。
          </Typography>
          <Typography
            sx={{ padding: "15px" }}
            variant="body1"
            component="div"
            gutterBottom
          >
            政府作了許多工程與法規，讓淹水損失大大的減少了，但個人也需要針對個人資產(房屋、家具、汽車、機電設施等)進行保護，可以透過個人或社區之工程措施，或是購改保險來予以轉嫁風險。
          </Typography>
          <Typography
            sx={{ padding: "15px" }}
            variant="body1"
            component="div"
            gutterBottom
          >
            {report.summary.replace(/^'(.*)',\)$/, "$1")}
          </Typography>
          <Typography
            sx={{ padding: "15px" }}
            variant="body1"
            component="div"
            gutterBottom
          >
            依據本系統分析，本建物一樓地板比人行道高
            {productTargetInfo?.["maskingHeight"]}
            公分，本建物一樓每年
            <span style={{ fontSize: "16px", color: "red" }}>
              大於10公分的淹水機率
              {/* {report.p0_200_interpolated["10cm"]}％ */}
              {report.risk_results["flood"][4]["10cm"]}%
            </span>
            。{furnitureLoss}
            考量不同淹水深度與對應的發生機率，此建物的汽機車每年有
            <span style={{ fontSize: "16px", color: "red" }}>
              {report.risk_results["flood"][1]}
            </span>
            元的期望損失，每年因淹水平均期望損失為
            <span style={{ fontSize: "16px", color: "red" }}>
              {report.risk_results["flood"][0]}元
            </span>
            淹水平均期望損失大於1000 建議加裝防水閘版、保險， 100～1000
            可加裝防水閘版、或斟酌是否購買保險， 0~100
            恭喜你買到淹水機率低的房屋!
            。建議可在汽機車出入口、較低的通風口、人員出入口在大豪雨期間加裝防水閘版，以及購買淹水保險。
            {/* 可能保險費用約落在
            <span style={{ fontSize: "16px", color: "red" }}>
              {(report.risk_results["flood"][1] * 1.2).toFixed(0)}～
              {(report.risk_results["flood"][1] * 1.5).toFixed(0)}
            </span>
            元/年為合理的保險費用。 */}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            歷史淹水事件
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            網路搜尋該地點的淹水相關得新聞資料有助於瞭解歷史的淹水事件，但是新聞資料不一定能找出是否過去有淹水的紀錄，即使沒有歷史淹水紀錄也不代表未來不會淹水
          </Typography>

          <NewsSearch report={report} />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            每年平均淹水損失(建物淹水機率分析)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ padding: "15px" }}
            variant="body1"
            component="div"
            gutterBottom
          >
            依據本系統分析，本建物一樓地板比人行道高
            {productTargetInfo?.["maskingHeight"]}
            公分，本建物一樓每年
            <span style={{ fontSize: "16px", color: "red" }}>
              大於10公分的淹水機率
              {/* {report.p0_200_interpolated["10cm"]}％ */}
              {report.risk_results["flood"][4]["10cm"]}%
            </span>
            。{furnitureLoss}
            考量不同淹水深度與對應的發生機率，此建物的汽機車每年有
            <span style={{ fontSize: "16px", color: "red" }}>
              {report.risk_results["flood"][1]}
            </span>
            元的期望損失，每年因淹水平均期望損失為
            <span style={{ fontSize: "16px", color: "red" }}>
              {report.risk_results["flood"][0]}元
            </span>
            。相關建議列於淹水保險與淹水預防措施兩章節。
          </Typography>

          <div
            style={{
              height: "50vh",
              marginBottom: "50px",
              minHeight: "410px",
            }}
          >
            <DepthProbBuildingFixed
              report={report}
              waterProbData={waterProbData}
            />
          </div>

          <div className="flood-prob-table-container">
            <DepthProbTableBuildingFixed
              report={report}
              waterProbData={waterProbData}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            淹水保險方案
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ padding: "15px" }}
            variant="body1"
            component="div"
            gutterBottom
          >
            經淹水分析後，發現本建物每年因淹水平均期望損失為
            <span style={{ fontSize: "16px", color: "red" }}>
              {report.risk_results["flood"][0]}元。
            </span>
            {riskMessage}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            淹水預防措施
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
          // style={{
          //   height: "50vh",
          //   marginBottom: "50px",
          //   minHeight: "410px",
          // }}
          >
            <DepthProbBuilding waterProbData={waterProbData} />
          </div>

          <div className="flood-prob-table-container">
            <DepthProbTableBuilding waterProbData={waterProbData} />
          </div>
          <Typography
            sx={{ padding: "15px" }}
            variant="body1"
            component="div"
            gutterBottom
          >
            依據本系統分析，本建物一樓地板比人行道高
            {productTargetInfo?.["maskingHeight"]}
            公分，本建物一樓每年
            <span style={{ fontSize: "16px", color: "red" }}>
              大於10公分的淹水機率
              {/* {report.p0_200_interpolated["10cm"]}％ */}
              {report.risk_results["flood"][4]["10cm"]}%
            </span>
            。{furnitureLoss}
            考量不同淹水深度與對應的發生機率，此建物的汽機車每年有
            <span style={{ fontSize: "16px", color: "red" }}>
              {report.risk_results["flood"][1]}
            </span>
            元的期望損失，每年因淹水平均期望損失為
            <span style={{ fontSize: "16px", color: "red" }}>
              {report.risk_results["flood"][0]}元
            </span>
            。相關建議列於淹水保險與淹水預防措施兩章節。
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            防災準備
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FloodPrepare />
          <br />
          <FloodSolution />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            其他說明
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12} md={12}>
            <Typography className="section">其他說明</Typography>
            <div className="detail-description">
              <Typography
                variant="body1"
                component="div"
                gutterBottom
                style={{ textAlign: "left", width: "100%" }}
              >
                <h5>不確定性說明</h5>
                {report.other_info
                  .replace(/^\('(.*)',\)$/, "$1")
                  .replace(/(\\n|\n)/g, "")}
              </Typography>
              <Typography variant="body1" component="div" gutterBottom>
                {/* <h2 className="section-heading">模擬資料說明</h2> */}
                <h5>模擬資料說明</h5>
                {report.sim_info
                  .replace(/^\('(.*)',\)$/, "$1")
                  .replace(/(\\n|\n)/g, "")}
              </Typography>
              <Typography variant="body1" component="div" gutterBottom>
                {/* <h2 className="section-heading">模型參數說明</h2> */}
                <h5>模型參數說明</h5>
                {report.para_info
                  .replace(/^\('(.*)',\)$/, "$1")
                  .replace(/(\\n|\n)/g, "")}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography className="section">用途限制</Typography>
            <div className="detail-description">
              <Typography
                variant="body1"
                component="div"
                gutterBottom
                style={{ textAlign: "left", width: "100%" }}
              >
                {/* <h2 className="section-heading">
                    IP protection and disclaimer
                  </h2> */}
                僅供購買用戶之防救災風險評估使用、未經同意不得轉載、或商用用途。
              </Typography>
            </div>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FloodResult;
