import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
} from "@mui/material";
import axiosInstance from "../../axios";
import { toast } from "react-toastify";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
export default function FaultDistanceTable({ report }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("distance"); // Default sort by distance
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Display 5 rows per page
  // const [dist, setDist] = useState({}); // Display 5 rows per page
  const [dist, setDist] = useState([]);
  const [nearestLine, setNearestLine] = useState(null);
  // console.log(nearestLine);
  // console.log("report", report);

  const [loading, setLoading] = useState(false);

  // from report directly
  useEffect(() => {
    if (report) {
      setLoading(true);

      const data = report.fault_nearest_lines.distance_info; // Assuming this should be an array  test from report
      setDist(Array.isArray(data) ? data : []); // Ensure it's an array
      // setNearestLine(response.data.nearest_line);

      setLoading(false);
    }
  }, [report]);

  const handleRequestSort = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          {/* <Table sx={{ minWidth: 650 }} aria-label="simple table"> */}
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === "fault_name"}
                  direction={orderBy === "fault_name" ? order : "asc"}
                  onClick={handleRequestSort("fault_name")}
                >
                  斷層名稱
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === "distance"}
                  direction={orderBy === "distance" ? order : "asc"}
                  onClick={handleRequestSort("distance")}
                >
                  距離 (km)
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(dist, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{row.fault_name}</TableCell>
                  <TableCell align="right">
                    {(row.distance / 1000).toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={dist.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          ".MuiTablePagination-toolbar": {
            justifyContent: "space-between", // Adjust the pagination bar
            alignContent: "center",
            p: {
              margin: "auto", // Override margins for p elements
            },
          },

          ".MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-selectIcon":
            {
              margin: "auto",
            },
          // Add this block to override styles from reboot.scss
          "& .MuiTablePagination-toolbar p": {
            margin: "auto", // Override the top and bottom margins of p tag
          },
        }}
      />
    </>
  );
}
