import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import "./PgaProbBuilding.scss";

const PgaProbBuilding = ({ report, polygonData, targetInfo }) => {
  const [designPga, setDesignPga] = useState(0.25);
  // const [hoverPga, setHoverPga] = useState(null); // New state for tracking hover pga
  const [chartData, setChartData] = useState([]);
  const [buildingType, setBuildingType] = useState(1);
  const [buildingTime, setBuildingTime] = useState(1);
  const [buildingMaterial, setBuildingMaterial] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [earthquakeLoss, setEarthquakeLoss] = useState(null);
  // const productTargetInfo = report.product.target_info;
  const productTargetInfo = JSON.parse(report.product.target_info);

  const risk_results_earthquake = report.risk_results["earthquake"];
  const min_fault_dist = report.risk_results["earthquake"][4];
  const earthquake_loss = report.risk_results["earthquake"][0];
  const earthquake_level = report.risk_results["earthquake"][1];
  const pga_all = report.risk_results["earthquake"][2];
  const cwb_level = report.risk_results["earthquake"][3];
  const design_pga_loss_rate = report.risk_results["earthquake"][7];
  // const floor_number = targetInfo.floorNumber;
  // const material = targetInfo.buildingMaterial;
  // const building_value = targetInfo.buildingValue;

  // console.log("floor_number", floor_number);
  // console.log("productTargetInfo", productTargetInfo);
  // console.log("report", report);
  // console.log("material", material);
  // console.log("building_value", building_value);
  // console.log("earthquakeLoss", earthquakeLoss);

  const handleBuildingTypeChange = (event) => {
    const newBuildingType = event.target.value;
    setBuildingType(newBuildingType);
  };
  const handleBuildingTimeChange = (event) => {
    const newBuildingTime = event.target.value;
    setBuildingTime(newBuildingTime);
  };
  const handleBuildingMaterialChange = (event) => {
    const newBuildingMaterial = event.target.value;
    setBuildingMaterial(newBuildingMaterial);
  };

  // const CustomTooltip = ({ active, payload, label }) => {
  //   // useEffect(() => {
  //   //   if (active && payload && payload.length) {
  //   //     setHoverPga(payload[0].payload.淹水深度); // Assuming '淹水深度' is the depth
  //   //   } else {
  //   //     setHoverPga(null); // Clear the hover depth when not active
  //   //   }
  //   // }, [active, payload]);

  //   if (active && payload && payload.length) {
  //     return (
  //       <div
  //         className="custom-tooltip"
  //         style={{
  //           backgroundColor: "#fff",
  //           padding: "5px",
  //           border: "1px solid #ccc",
  //           borderRadius: "2px",
  //         }}
  //       >
  //         <p>{`建物設計PGA強度為${payload[0].value.toFixed(
  //           2
  //         )} 發生破壞的的機率為${label}%`}</p>
  //       </div>
  //     );
  //   }

  //   return null;
  // };

  // const transformDataForChart = (data) => {
  //   const points = [
  //     { name: "X1", x: (data.x1 * 100).toFixed(2), y: data.y1 },
  //     { name: "X2", x: (data.x2 * 100).toFixed(2), y: data.y2 },
  //     { name: "X3", x: (data.x3 * 100).toFixed(2), y: data.y3 },
  //   ];
  //   points.sort((a, b) => a.x - b.x);
  //   return points;
  // };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       const formData = {
  //         geo_data: polygonData.geo_data,
  //         design_pga: designPga * buildingType,
  //       };
  //       const response = await axiosInstance.post(`risk/pga-prob/`, formData, {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       });
  //       const data = response.data.result;
  //       const chartData = transformDataForChart(data);
  //       setChartData(chartData);
  //       // console.log("get pga analysis  done:", chartData);
  //     } catch (error) {
  //       console.error("Error getting pga:", error);
  //       setError("分析PGA失敗，請選擇建物後重新嘗試!");
  //       toast.error("分析PGA失敗，請選擇建物後重新嘗試!");
  //     }
  //     setLoading(false);
  //   };

  //   if (polygonData) {
  //     fetchData();
  //   }
  // }, [designPga, polygonData, buildingType]);

  // get earthquake loss
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       const formData = {
  //         geo_data: polygonData.geo_data,
  //         floor_number: targetInfo.floorNumber,
  //         material: targetInfo.buildingMaterial,
  //         building_value: targetInfo.buildingValue * 10000,
  //       };

  //       const response = await axiosInstance.post(
  //         `risk/earthquake-loss/`,
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       const data = response.data.earthquake_expected_loss_per_year;
  //       setEarthquakeLoss(data);
  //       // console.log("earthquake-loss analysis  done:", data);
  //     } catch (error) {
  //       console.error("Error getting earthquake-loss:", error);
  //       toast.error("分析earthquake-loss失敗，請選擇建物後重新嘗試!");
  //     }
  //     setLoading(false);
  //   };

  //   if (polygonData) {
  //     fetchData();
  //   }
  // }, [
  //   polygonData,
  //   targetInfo.floorNumber,
  //   targetInfo.buildingMaterial,
  //   targetInfo.buildingValue,
  // ]);

  const handleDesignPgaChange = (event) => {
    setDesignPga(parseFloat(event.target.value));
  };

  // if (!polygonData) {
  //   return <div>尚未選擇建物/多邊形...</div>;
  //   // return <div>Not chosen polygon yet...</div>;
  // }

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  return (
    <div style={{ fontFamily: "Arial", padding: "0px", marginBottom: "10px" }}>
      {/* <p style={{ fontSize: "16px", fontWeight: "bold" }}>
       
        地震風險分析
      </p> */}
      <div>
        <ul style={{ fontSize: "16px", fontWeight: "bold" }}>
          <li>
            您的建物依據防震設計規範為
            <span style={{ color: "red" }}>
              {productTargetInfo?.["designPga"]}g
            </span>
            的防震，發生大於或等於{productTargetInfo?.["designPga"]}
            g的機率為
            <span style={{ color: "red" }}>
              {(report.risk_results["earthquake"][2][2] * 100).toFixed(2)}%/年
            </span>
            ， 50年內發生{productTargetInfo?.["designPga"]}
            g震度的機率約
            <span style={{ color: "red" }}>
              {(
                (1 - (1 - report.risk_results["earthquake"][2][2]) ** 50) *
                100
              ).toFixed(2)}
              %
            </span>
            。
          </li>
          
          <li>
            本建物50年內發生全倒或半倒的機率小於
            <span style={{ color: "red" }}>
              {(
                (1 - (1 - report.risk_results["earthquake"][2][2]) ** 50) *
                100
              ).toFixed(2)}
              %
            </span>
            。依國內外統計顯示，在此震度下房屋損壞比率約{" "}
            <span style={{ color: "red" }}>{design_pga_loss_rate}%</span>。
          </li>
          <li>
            依據本系統分析，考量各種震度、發生機率與損壞程度，您的房屋在50年內的損壞比率約為
            <span style={{ color: "red" }}>
              {(report.risk_results["earthquake"][6] * 100).toFixed(2)}%
            </span>
          </li>
          <li>
            依據產物保險商業同業公會的住宅類建築造價參考，您的建物價值為
            <span style={{ color: "red" }}>
              {(report.risk_results["earthquake"][8] / 10000).toFixed(2)}萬元
            </span>
          </li>
          <li>
            本建物有
            <span style={{ color: "red" }}>
              {report.risk_results["earthquake"][0]}元/年
            </span>
            的期望損失
          </li>
          {/* <li>
            考量臺灣各縣市的建築成本與裝潢成本，本建物有新台幣
            <span style={{ color: "red" }}>
              {report.risk_results["earthquake"][0]}元/年
            </span>
            的期望損失。
          </li> */}
          <li>
            如果您只購買基本地震險(保費1350元，購屋時銀行的要求火險與地震險)，其保額僅150萬，且僅在半倒與全倒時才會有理賠。
          </li>
          <li>
            若您未購買基本地震險，目前您的房屋仍有{" "}
            <span style={{ color: "red" }}>
              {(report.risk_results["earthquake"][8] / 10000).toFixed(2)}萬元
            </span>
            的保險缺額。若已購買基本地震險，則有
            <span style={{ color: "red" }}>
              {(report.risk_results["earthquake"][8] / 10000 - 150).toFixed(2)}
              萬元
            </span>
            的保險缺額。
            {/* 可能保險費用約落在
            <span style={{ color: "red" }}>
              {(report.risk_results["earthquake"][0] * 1.2).toFixed(0)}~
              {(report.risk_results["earthquake"][0] * 1.5).toFixed(0)}元/年
            </span>
            為合理的保險費用 */}
          </li>
          <li>
            目前您的房屋仍有
            <span style={{ color: "red" }}>
              {(report.risk_results["earthquake"][5] *
                productTargetInfo?.["pingAll"]) /
                10000}
            </span>
            萬元的保險缺額。
          </li>
          <li>
            本系統建議可加購超額地震險、擴大地震險、輕損地震險，不只在全倒或半倒，房屋在發生任何地震損壞時，都能夠得到理賠，藉以保障您的重要資產。
          </li>
          {/* <li>
            本建物最近斷層距離為{(min_fault_dist / 1000).toFixed(2)}
            公里，非屬禁限建範圍
          </li>
          <li>
           
            未來50年有10％的機率發生PGA大於{pga_all[0]}
            g的地震，相當於氣象屬定義{cwb_level[0]}地震
          </li>
          <li>
           
            未來50年有2％機率發生PGA大於{pga_all[1]}
            g的地震，相當於氣象屬定義{cwb_level[1]}地震
          </li> */}
          {/* <li>
            依據美國地震建物損壞統計數據，建物材質影響建物的地震災損，本建物的年期望損失為新台幣
            <span style={{ fontSize: "20px", color: "red" }}>
              {earthquake_loss}
            </span>
            元，在本系統分類屬風險等級
            <span style={{ fontSize: "20px", color: "red" }}>
              {earthquake_level}
            </span>
            。
          </li> */}
          <hr />
          <hr />
          <li>
            說明： <br />
            基本地震險保費1350元(一般購屋貸款的強制險)，其保額為150萬+20萬臨時住宿費。建議可購買超額地震險。
          </li>
          <li>
            禁限建範圍:活動斷層線兩側各15公尺範圍內之帶狀地區。活動斷層未明確地區：活動斷層線西側（下盤）30公尺、東側（上盤）50公尺範圍內之帶狀地區。考量地圖比例尺誤差*，
            <span style={{ color: "red" }}>建議採保守措施50公尺。</span>
          </li>
          <li>
            禁限規定：
            <br />
            公有建築物禁止興建。◼私有建築物高度不得超過2層樓（7公尺），得特許興建，並限作自用農舍或住宅使用。◼活動斷層未明確地區，經詳細地質調查確定其斷層線位置後，得比照活動斷層線明確地區之禁限建範圍，予以縮小。
          </li>
          <li>
            *註：活動斷層地質敏感區（比例尺二萬五千分之一，亦即圖面誤差左右各25公尺）。
          </li>
        </ul>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
          margin: "10px",
        }}
      >
        <img style={{ width: "90%" }} src="/img/地震分級.png" alt="地震分級" />
        <p style={{ marginTop: "10px", fontSize: "0.8rem", color: "#666" }}>
          資料來源: 交通部中央氣象署
        </p>
      </div>

      <div>
        <ul
          // className="content-list"
          style={{ fontSize: "16px", fontWeight: "bold" }}
        >
          <li>
            PGA, Peak Ground Acceleration, 最大地震動加速度。單位是 g,
            重力加速度=980 公分/秒平方
          </li>
          <li>0.001g 比較敏感的人會感受到震動</li>
          <li>0.02g 會感覺到失去平衡</li>
          <li>
            0.5g
            非常大的震動。房屋如果沒有經過耐震設計的話，若震動時間長，將會產生災害。
          </li>
          <li>中央氣象局震度表，震度五級相當於0.08g-0.25g。</li>
        </ul>
      </div>
      {/* <div style={{ width: "100%", height: 400 }}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <ResponsiveContainer>
            <AreaChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 10, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#000" />
              <XAxis
                dataKey="x"
                name="Probability"
                label={{
                  value: "地震破壞機率(%)",
                  position: "insideBottom",
                  offset: -10,
                  fill: "#000",
                }}
                stroke="#000"
              />
              <YAxis
                dataKey="y"
                name="Depth"
                label={{
                  value: "地表加速度(PGA)",
                  angle: -90,
                  position: "insideLeft",
                  offset: 10,
                  fill: "#000",
                }}
                stroke="#000"
              />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey="y"
                stroke="#8884d8"
                fill="#8884d8"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div> */}
    </div>
  );
};

export default PgaProbBuilding;
