import React from "react";
import {
  Tabs,
  Tab,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const FloodPrepare = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState("panel1");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        各階段整備行動
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="flood info tabs"
            centered={!isMobile}
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : false}
          >
            <Tab label="淹水前（防災前）" sx={{ fontWeight: "bold" }} />
            <Tab label="淹水時（防災中）" sx={{ fontWeight: "bold" }} />
            <Tab label="淹水後（防災後）" sx={{ fontWeight: "bold" }} />
          </Tabs>
        </Box>
        {tabValue === 0 && (
          <Box p={3} sx={{ padding: "0px" }}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>淹水風險減輕方案：</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  我們建議住家淹水風險減輕方案： 淹水保險
                  防水工程(檔水閘版、大型工程) 換一個居住地點
                </Typography>
              </AccordionDetails>
            </Accordion>
            {/* Repeat for other items as needed */}
          </Box>
        )}
        {tabValue === 1 && (
          <Box p={3} sx={{ padding: "0px" }}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>啟動防水設施:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  啟動防水設施：啟動檔水閘版及其他設施、堆沙包、關閉電氣系統、
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
        {tabValue === 2 && (
          <Box p={3} sx={{ padding: "0px" }}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>災後行動:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  拍照 找里長 主動聯繫保險業務員 住家環境消毒
                </Typography>
                <Typography>
                  一、清理家園時，切忌赤足及穿著拖鞋打掃，避免感染鉤端螺旋體、類鼻疽及破傷風
                  災後易接觸到污水、污泥或災害廢棄物，可能因此感染鉤端螺旋體病、類鼻疽、破傷風及其他皮膚病，該局提醒民眾清理家園時，務必穿著雨鞋或防水長靴、配戴防水手套及口罩，做好個人防護措施，也應留意避免被生鏽器物（如鐵釘、鐵片等）刺傷或割傷，工作結束後，應以肥皂及清水洗手，並仔細檢視有無傷口。老年人、身體衰弱、免疫功能不佳、慢性病患者（糖尿病、心臟病、高血壓、癌症及酒癮者等）及皮膚有外傷者，應避免皮膚接觸污水或淤泥，否則引發細菌感染，嚴重時可能需截肢。如被生鏽器物割傷或出現發燒、頭痛、肌肉痛、腹痛、腹瀉、黃疸、倦怠或其他身體不適的症狀，請儘快就醫。就醫時務必告訴醫師居住地區、污水淤泥接觸史、創傷原因及傷口污染情形，以利醫師提供妥適的診療服務
                  二、注意飲水、食物及環境衛生，預防腸道傳染病
                  豪雨可能導致飲用水受到汙染，若住宅的蓄水池淹水，應確實清洗消毒後再蓄水；飲用水要徹底煮沸或飲用包裝水；泡水之廚房用具及餐具要消毒後才能使用，泡過水或解凍過久的食物也不要食用；民眾可自行購買市售含氯漂白水並稀釋100倍，擦拭居家環境，而餐具部分應煮沸消毒，不能煮沸者，用清水10公升加40毫升漂白水稀釋消毒。
                  三、清除病媒蚊孳生源，預防登革熱
                  目前是登革熱流行季，颱風豪雨過後會產生大量積水容器，易形成病媒蚊孳生源，該局呼籲民眾及社區主動清除居家環境內外積水容器；屋頂陽台、大樓地下室應經常巡視，排除積水；住家四周環境的廢棄容器，例如輪胎、鐵鋁罐、帆布、寶特瓶、盆栽墊盤等，也都要加以清理，不要讓病媒蚊孳生。民眾若有災後防疫相關疑問，亦可聯絡各縣市衛生局，或撥打國內免費民眾疫情通報與關懷專線1922洽詢。
                  來源:衛生福利部疾病管理署
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default FloodPrepare;
