import React, { PureComponent } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";
import "./RiskRadarChart.scss";
import { setRisksData, resetRisksData } from "../../redux/risksDataReducer";
import { useSelector, useDispatch } from "react-redux";

const RiskRadarChart = ({ report }) => {
  const riskData = useSelector((state) => state.risksData.risksData);
  // const productTargetInfo = JSON.parse(report?.product?.target_info);

  // console.log("flood.risk_results", report.risk_results);

  const flood_level = report.risk_results["flood"][3];
  const landslide_level = report.risk_results["landslide"][1];
  const earthquake_level = report.risk_results["earthquake"][1];
  const sealevel_level = report.risk_results["sealevel"][1];
  const liguefaction_level = report.risk_results["liguefaction"][1];
  // console.log("riskData", riskData);
  const data = [
    {
      subject: "水災",
      A: flood_level,
      // A: riskData.flood,
    },
    {
      subject: "地震",
      // A: 3,
      A: earthquake_level,
      // A: riskData.earthquake,
    },
    {
      subject: "土壤液化",
      // A: 3,
      A: liguefaction_level,
      // A: riskData.liquefaction,
    },
    {
      subject: "坡地災害",
      // A: 2,
      // A: riskData.landslide,
      A: landslide_level,
    },
    {
      subject: "氣候變遷",
      // A: 1,
      A: sealevel_level,
      // A: riskData.sealevel,
    },
  ];

  return (
    <>
      <div className="radarchart-container">
        <ResponsiveContainer width="100%" height="100%">
          <RadarChart
            cx="50%"
            cy="50%"
            outerRadius="60%"
            data={data}
            margin={
              {
                // top: 70,
                // right: 70,
                // bottom: 70, // Increase bottom margin
                // left: 70,
              }
            }
          >
            <PolarGrid stroke="#000" />
            <PolarAngleAxis
              dataKey="subject"
              stroke="#000"
              tick={{ fontWeight: "bold" }}
            />
            <PolarRadiusAxis
              domain={[0, 5]} // Set the domain from 0 to 5
              ticks={[1, 2, 3, 4, 5]} // Specify the tick values you want to display
              stroke="#000"
              tick={{ fontWeight: "bold" }}
            />

            <Radar
              name="Mike"
              dataKey="A"
              // stroke="#8884d8"
              stroke="#ba2222"
              // fill="#8884d8"
              fill="#ba2222"
              fillOpacity={0.6}
            />
          </RadarChart>
        </ResponsiveContainer>
      </div>
      {/* <div>
        <ul className="content-list" style={{ listStyleType: "disc" }}>
          <li>分析參數:</li>
          <li>建物材質:{productTargetInfo?.["buildingMaterial"]}</li>
          <li>建造時間:{productTargetInfo?.["buildingTime"]}</li>
          <li>建物種類:{productTargetInfo?.["buildingType"]}</li>
          <li>建物價值:{productTargetInfo?.["buildingValue"]} 萬元</li>
          <li>所在樓層:{productTargetInfo?.["floorNumber"]}</li>
          <li>總樓層:{productTargetInfo?.["totalFloorNumber"]}</li>
          <li>汽機車價值:{productTargetInfo?.["carValue"]}萬元</li>
          <li>傢俱價值:{productTargetInfo?.["furnitureLoss"]}萬元</li>
          <li>門檻高度:{productTargetInfo?.["maskingHeight"]}公分</li>
        </ul>
      </div> */}
    </>
  );
};

export default RiskRadarChart;
