import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./RiskMapAll.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import { addToCart } from "../../redux/cartReducer";
import * as turf from "@turf/turf";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const gs_username = process.env.REACT_APP_GEOSERVER_BASIC_USERNAME;
const gs_pass = process.env.REACT_APP_GEOSERVER_BASIC_PASSWORD;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = mapboxtoken;


const RiskHouse = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pkid, setPkid] = useState(null);
  const { countyCode } = useParams();
  const mapContainer = useRef(null);
  const mapRef = useRef(null); // New reference for the map instance
  const [isLoading, setIsLoading] = useState(false);
  const [clickedBuildingIds, setClickedBuildingIds] = useState(new Set());
  const [buildingInfo, setBuildingInfo] = useState([]);
  const [buildingName, setBuildingName] = useState("buildings_taipei3857");
  const [initialCoor, setInitialCoor] = useState({
    lng: 121.30128,
    lat: 24.99265,
  });
  const [updatedBuildingInfo, setUpdatedBuildingInfo] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [productName, setProductName] = useState("B-我的報告");

  const handleProductNameChange = (event) => {
    setProductName(event.target.value);
  };
  // const switchBaseMap = (style) => {
  //   if (mapRef.current) {
  //     mapRef.current.setStyle("mapbox://styles/mapbox/" + style);
  //   }
  // };

  const switchBaseMap = (style) => {
    if (mapRef.current) {
      mapRef.current.setStyle("mapbox://styles/mapbox/" + style);
      mapRef.current.once("style.load", () => {
        // Re-add the buildings source and layer here
        addBuildingsLayer();
      });
    }
  };

  const addBuildingsLayer = () => {
    if (!mapRef.current.getSource("buildings")) {
      // Add source for buildings
      mapRef.current.addSource("buildings", {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] }, // initial empty data
      });
    }

    if (!mapRef.current.getLayer("3d-buildings")) {
      // Add layer for buildings
      mapRef.current.addLayer({
        id: "3d-buildings",
        source: "buildings",
        type: "fill-extrusion",
        paint: {
          "fill-extrusion-color": [
            "case",
            ["boolean", ["feature-state", "clicked"], false],
            "#ff0000",
            "#aaa",
          ],
          "fill-extrusion-height": ["get", "build_h"],
          "fill-extrusion-base": 0,
          "fill-extrusion-opacity": 0.5,
        },
      });
    }

    // Call your function to fetch and set the data for buildings
    fetchBuildingsInView(mapRef.current);
  };

  const handleOptionChange = (option) => {
    setSelectedOptions((prev) => {
      if (prev.includes(option)) {
        return prev.filter((item) => item !== option);
      } else {
        return [...prev, option];
      }
    });
  };

  const county_location = {
    9007: ["連江縣", 119.9436, 26.1519, "buildings_taoyuan3857"],
    10002: ["宜蘭縣", 121.76088, 24.7288, "buildings_ilan3857"],
    10007: ["彰化縣", 120.516, 24.0521, "buildings_changhua3857"],
    10008: ["南投縣", 120.9911, 23.8388, "buildings_nantou3857"],
    10009: ["雲林縣", 120.482, 23.6649, "buildings_yunlin3857"],
    10017: ["基隆市", 121.7415, 25.1282, "buildings_keelung3857"],
    63000: ["臺北市", 121.5637, 25.0375, "buildings_taipei3857"],
    65000: ["新北市", 121.4628, 25.0168, "buildings_newtaipei3857"],
    66000: ["臺中市", 120.6736, 24.1477, "buildings_taichung3857"],
    67000: ["臺南市", 120.2053, 23.0007, "buildings_tainan3857"],
    68000: ["桃園市", 121.30128, 24.99265, "buildings_taoyuan3857"],
    10005: ["苗栗縣", 120.8214, 24.5602, "buildings_miaoli3857"],
    10020: ["嘉義市", 120.45205, 23.47675, "buildings_chiayi3857"],
    10010: ["嘉義縣", 120.6244, 23.4346, "buildings_chiayi3857"],
    9020: ["金門縣", 118.319, 24.4324, "buildings_taoyuan3857"],
    64000: ["高雄市", 120.312, 22.6209, "buildings_kaohsiung3857"],
    10014: ["臺東縣", 121.1136, 22.7649, "buildings_taitung3857"],
    10015: ["花蓮縣", 121.6014, 23.991, "buildings_hualien3857"],
    10016: ["澎湖縣", 119.5793, 23.568, "buildings_taoyuan3857"],
    10018: ["新竹市", 120.9665, 24.805, "buildings_hsinchu3857"],
    10004: ["新竹縣", 121.0027, 24.8392, "buildings_hsinchcounty3857"],
    10013: ["屏東縣", 120.5489, 22.5495, "buildings_pingtung3857"],
  };
  useEffect(() => {
    // Get the county location from the county_location object using the countyCode
    const location = county_location[countyCode];
    if (location) {
      const [countyName, lng, lat, wfsName] = location;
      setInitialCoor({ lng, lat });
      setBuildingName(wfsName);
    }
  }, [countyCode]);

  //get the user
  useEffect(() => {
    const getPkid = async () => {
      try {
        const res = await axiosInstance.get("auth/users/me/");
        setPkid(res.data.pkid);
      } catch (err) {
        // console.error(err);
        toast.warning("您目前尚未登入喔!");
      }
    };
    getPkid();
  }, []);

  useEffect(() => {
    const updatedBuildings = buildingInfo.map((building) => {
      const geometry = JSON.parse(building.geometry);
      const area = turf.area(geometry); // Calculates the area in square meters
      return { ...building, area };
    });
    setUpdatedBuildingInfo(updatedBuildings);
  }, [buildingInfo]); // Dependency array ensures this runs only when buildingInfo changes

  const totalArea = updatedBuildingInfo.reduce(
    (sum, building) => sum + building.area,
    0
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the user is not logged in
    if (pkid === null) {
      toast.warning("您尚未登入！請先登入再進行此操作。");
      return;
    }

    if (buildingInfo.length === 0) {
      toast.error("請先在地圖上點選分析區域!");
      return;
    }
    const product_title = productName;
    const formData = {
      title: product_title,
      name: product_title,
      description: "Risk analysis for selected buildings", // Or any other description you wish to add
      hazard_type: "Flood",
      total_price: 1000,
      created_by_customer: pkid,
      product_type: "building",
      geo_data: JSON.stringify(buildingInfo), // Stringify the entire buildingInfo array
    };
    // console.log(formData);
    const response = await axiosInstance.post(`risk/products/`, formData);
    // const response = await axiosInstance.post(`shop/riskproducts/`, formData);
    const data = response.data;
    // console.log(response.data);
    // console.log("after post to backend");
    // console.log(formData);
    // to redux cart
    dispatch(
      addToCart({
        id: data.pkid,
        title: data.title,
        desc: data.desc,
        price: data.total_price,
        // img: data.attributes.img.data.attributes.url,
        quantity: 1,
      })
    );

    toast.success("成功加入購物車!");
    // console.log(buildingInfo);
  };

  useEffect(() => {
    // Only initialize the map if it doesn't already exist
    if (mapRef.current) return; // This prevents multiple initializations

    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [initialCoor.lng, initialCoor.lat],
      zoom: 15,
      maxZoom: 19,
      minZoom: 14,
    });

    mapRef.current.on("load", () => {
      mapRef.current.addSource("buildings", {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
      });
      mapRef.current.addLayer({
        id: "3d-buildings",
        source: "buildings",
        type: "fill-extrusion",
        paint: {
          "fill-extrusion-color": [
            "case",
            ["boolean", ["feature-state", "clicked"], false],
            "#ff0000",
            "#aaa",
          ],
          "fill-extrusion-height": ["get", "build_h"],
          "fill-extrusion-base": 0,
          "fill-extrusion-opacity": 0.5,
        },
      });

      fetchBuildingsInView(mapRef.current);
    });

    mapRef.current.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        language: "zh_TW",
      }),
      "top-left"
    );
    mapRef.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      }),
      "top-right"
    );
    mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-right");
    mapRef.current.addControl(new mapboxgl.FullscreenControl(), "top-right");

    mapRef.current.on("moveend", () => fetchBuildingsInView(mapRef.current));
    mapRef.current.on("click", "3d-buildings", (e) =>
      handleBuildingClick(e, mapRef.current)
    );

    return () => {
      if (mapRef.current) {
        // Remove event listeners first
        mapRef.current.off("load");
        mapRef.current.off("moveend");
        mapRef.current.off("click", "3d-buildings");

        // Then remove the map
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [initialCoor]);

  const fetchBuildingsInView = (map) => {
    const bounds = map.getBounds();
    setIsLoading(true);

    axios
      .get(`${geoserver_url}geoserver/staging/ows`, {
        params: {
          service: "WFS",
          version: "1.0.0",
          request: "GetFeature",
          // typeName: "staging:buildings_taoyuan3857",
          // typeName: `staging:${buildingName}`,
          typeName: `staging:building_all_20230208_uid`,
          // typeName: "staging:building_all",
          // typeName: "staging:buildings_taipei3857",
          bbox: `${bounds.getWest()},${bounds.getSouth()},${bounds.getEast()},${bounds.getNorth()}`,
          outputFormat: "application/json",
        },
        auth: {
          username: gs_username,
          password: gs_pass,
        },
      })
      .then((response) => {
        const data = response.data;
        // Assign uid as id for each feature
        data.features.forEach((feature) => {
          feature.id = feature.properties.uid;
        });
        map.getSource("buildings").setData(data);

        // Reapply 'clicked' state to buildings
        clickedBuildingIds.forEach((id) => {
          map.setFeatureState({ source: "buildings", id }, { clicked: true });
        });

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the GeoServer data:", error);
        setIsLoading(false);
      });
  };

  const handleBuildingClick = (e, map) => {
    if (e.features.length > 0) {
      const feature = e.features[0];
      const featureUid = feature.properties.uid;

      // Calculate the area of the building
      const geometry = feature.geometry;
      const area = turf.area(geometry);

      // Check the current state of the clicked feature
      const currentState =
        map.getFeatureState({ source: "buildings", id: featureUid }).clicked ||
        false;

      map.setFeatureState(
        { source: "buildings", id: featureUid },
        { clicked: !currentState }
      );

      // Update the building info state
      setBuildingInfo((prev) => {
        // Check if the building is already in the array
        const existingBuildingIndex = prev.findIndex(
          (b) => b.uid === featureUid
        );

        if (existingBuildingIndex === -1) {
          // If the building was not previously selected, add it to the array
          return [
            ...prev,
            {
              uid: featureUid,
              region: "test", // Assuming 'region' is a property
              geometry: JSON.stringify(feature.geometry), // Convert geometry object to string
              door_height: feature.properties.build_h, // Assuming 'door_height' is a property
              area: area, // Add the area here
            },
          ];
        } else {
          // If the building was previously selected, remove it from the array
          return prev.filter((_, index) => index !== existingBuildingIndex);
        }
      });

      // Update the clicked building IDs
      setClickedBuildingIds((prev) => {
        const newClickedIds = new Set(prev);
        if (!currentState) {
          newClickedIds.add(featureUid);
        } else {
          newClickedIds.delete(featureUid);
        }
        return newClickedIds;
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        padding: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {/* Map container covering the full width and 60vh height */}
        <div
          ref={mapContainer}
          style={{ width: "100%", height: "60vh", padding: "10px" }}
        />
        {/* Base Map Switcher */}
        <div
          id="menu"
          style={{
            position: "absolute",
            bottom: 10, // Adjust as needed for top spacing
            left: "50%", // Center horizontally
            transform: "translateX(-50%)", // Adjust for exact centering
            background: "#efefef",
            padding: "10px",
            zIndex: 1, // Ensure it's above the map
          }}
        >
          <input
            id="streets-v12"
            type="radio"
            name="rtoggle"
            value="streets-v12"
            onChange={() => switchBaseMap("streets-v12")}
            defaultChecked
          />
          <label
            htmlFor="streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            街道
          </label>
          <input
            id="satellite-streets-v12"
            type="radio"
            name="rtoggle"
            value="satellite-streets-v12"
            onChange={() => switchBaseMap("satellite-streets-v12")}
          />
          <label
            htmlFor="satellite-streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            衛星
          </label>
        </div>
      </div>

      {/* Second row split into 2:1 ratio */}
      <div
        style={{
          padding: "10px",
          display: "flex",
          flex: 1,
          maxHeight: "40vh",
          gap: "10px",
        }}
      >
        {/* Left part of the second row with a flex ratio of 2 */}
        <div
          style={{
            backgroundColor: "white", // Corrected the property name
            fontSize: "small",
            flex: 1, // 2 parts of the second row
            overflowY: "auto", // If you want a scrollbar
          }}
        >
          <div
            className="textfield2"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              margin: "10px",
            }}
          >
            <label htmlFor="product-name">報告名稱: </label>
            <input
              type="text"
              id="product-name"
              value={productName}
              onChange={handleProductNameChange}
            />
          </div>
          {/* <h3>Building Details</h3> */}
          <table>
            <thead>
              <tr>
                <th>Building ID</th>
                <th>Height</th>
                <th>Area (m²)</th>
              </tr>
            </thead>
            <tbody>
              {buildingInfo.map((building, index) => (
                <tr key={building.uid}>
                  <td>{building.uid}</td>
                  <td>{building.door_height}</td>
                  <td>{building.area ? building.area.toFixed(2) : "N/A"}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* {buildingInfo.length > 0 && (
            <div>
              <h4>Geometry of Last Selected Building:</h4>
              <div>
                {JSON.stringify(buildingInfo[buildingInfo.length - 1].geometry)}
              </div>
            </div>
          )} */}
        </div>

        {/* Right part of the second row with a flex ratio of 1 */}
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <button className="add" onClick={handleSubmit}>
              加入購物車
            </button>

            <button onClick={() => navigate(`/riskmapdraw/${countyCode}`)}>
              以畫圖選擇
            </button>
            <button onClick={() => navigate(`/riskmapradius/${countyCode}`)}>
              以半徑選擇
            </button>
            {/* <div>{buildingInfo}</div> */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <p>分析災害:</p>
            <div>
              <input
                type="checkbox"
                id="flood"
                checked={selectedOptions.includes("淹水")}
                onChange={() => handleOptionChange("淹水")}
              />
              <label htmlFor="flood">淹水</label>
            </div>
            <div>
              <input
                type="checkbox"
                id="earthquake"
                checked={selectedOptions.includes("地震")}
                onChange={() => handleOptionChange("地震")}
              />
              <label htmlFor="earthquake">地震</label>
            </div>
            <div>
              <input
                type="checkbox"
                id="liquefaction"
                checked={selectedOptions.includes("土壤液化")}
                onChange={() => handleOptionChange("土壤液化")}
              />
              <label htmlFor="liquefaction">土壤液化</label>
            </div>
          </div>
          <p>
            <strong>分析總面積: {totalArea.toFixed(2)} m²</strong>
            <strong> 會員點數: {100} 點</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RiskHouse;
