import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import RiskRadarChart from "../../components/Charts/RiskRadarChart";
import "./RiskReport.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../axios";
import RiskMapClip from "../../components/RiskMapAll/RiskMapClip";
import RiskMapClipSeaLevel from "../../components/RiskMapAll/RiskMapClipSeaLevel";
import RiskMapClipLiq from "../../components/RiskMapAll/RiskMapClipLiq";
import RiskMapClipEarthquake from "../../components/RiskMapAll/RiskMapClipEarthquake";
import RiskMapClipLandSlide from "../../components/RiskMapAll/RiskMapClipLandSlide2";
import floodLevels from "./floodLevels.js";
import { Tabs, Tab } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home"; // Example icon
import InfoIcon from "@mui/icons-material/Info"; // Another example icon
import FloodIcon from "@mui/icons-material/Flood";
import PublicIcon from "@mui/icons-material/Public";
import HouseboatIcon from "@mui/icons-material/Houseboat";
import LandslideIcon from "@mui/icons-material/Landslide";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import BuildingRisk from "../../components/RiskMapAll/BuildingRisk";
import BuildingRiskAll from "../../components/RiskMapAll/BuildingRiskAll";
import FloodResult from "../../components/RiskMapAll/FloodResult_v2";
import EerthquakeResult from "../../components/RiskMapAll/EerthquakeResult_v2";
import TsunamiIcon from "@mui/icons-material/Tsunami";
import { RiEarthquakeFill } from "react-icons/ri";
import LiqResult from "../../components/RiskMapAll/LiqResult_v2";
import SealevelResult from "../../components/RiskMapAll/SealevelResult_v2";
import Summary from "./Summary_v2";
import Sugguestions from "./Suggestions.jsx";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";

const PaidReport = ({ product, productTargetInfo }) => {
  const { pkid } = useParams();
  const isLocked = product.is_locked;
  const [report, setReport] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDialog, setOpenDialog] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isUnlocking, setIsUnlocking] = useState(false); // To handle unlocking process
  const [loadingMessage, setLoadingMessage] = useState("");
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [messageIndex, setMessageIndex] = useState(0);
  const loadingMessages = [
    "載入淹水風險...",
    "載入地震風險...",
    "載入土壤液化風險...",
    "載入海平面上升風險...",
    "載入坡地風險...",
    "完成報告...",
  ];

  // console.log("isLocked", isLocked);

  const CustomTab = ({ label, icon, active }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // Optionally adjust icon size here if it's a component
    const adjustedIcon = React.cloneElement(icon, {
      style: {
        // fontSize: isMobile ? "16px" : "24px",
        fontSize: isMobile ? "24px" : "36px", // Adjust the icon size based on screen size
        // color: "black", // Set icon color to black
        color: active ? "white" : "#4C839B", // Change icon color based on active state
      },
    });

    // Define a fixed width for the tabs
    const tabWidth = isMobile ? "40px" : "80px"; // Example widths, adjust as needed

    return (
      <div
        style={{
          display: "flex",
          width: tabWidth, // Apply fixed width to each tab
          height: tabWidth, // Apply fixed width to each tab
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center", // Center content vertically
          fontWeight: "bold",
          padding: isMobile ? "4px" : "10px",
          border: "1px solid",
          borderRadius: "2px",
          // color: "#4C839B"
          // color: active ? "white" : "black", // Text color changes based on active state
          color: active ? "white" : "#4C839B", // Text color changes based on active state
          // backgroundColor: active ? "black" : "transparent", // Background color changes based on active state
          backgroundColor: active ? "#4C839B" : "transparent", // Background color changes based on active state
        }}
      >
        {adjustedIcon}
        {!isMobile && <span>{label}</span>}
      </div>
    );
  };

  const [furnitureLoss, setFurnitureLoss] = useState("");

  const handlePointPay = async () => {
    setOpenDialog(true);
  };

  // Handle loading messages and fetch report only after unlocking
  const fetchReport = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `shop/reports/?product_id=${pkid}`
      );
      if (response.data.length > 0) {
        setReport(response.data[0]);
        setLoading(false);
        setIsUnlocking(false);
        return true; // Report fetched successfully
      }
      return false; // Report not available yet
    } catch (error) {
      console.error("Error fetching the report:", error);
      toast.error("Error loading report. Please try again later.");
      setLoading(false);
      return false; // Report not available yet
    }
  };

  const confirmPayment = async () => {
    setIsUnlocking(true);
    try {
      const selectedProductIds = pkid;
      const response = await axiosInstance.post(
        `shop/membership/use_points_to_purchase_single_product/`,
        { selectedProductIds: selectedProductIds }
      );

      if (response.data.success) {
        product.is_locked = false;
        displayLoadingMessages();
        fetchReport(); // Fetch the report only after unlocking
        toast.success(response.data.success);
      }
    } catch (error) {
      toast.error("付款失敗: " + error.response?.data?.error || "未知錯誤");
    }
    setOpenDialog(false);
  };

  const displayLoadingMessages = () => {
    let index = 0;
    const messageInterval = setInterval(() => {
      if (index < loadingMessages.length) {
        setLoadingMessage(loadingMessages[index]);
        index++;
      } else {
        clearInterval(messageInterval);
      }
    }, 1000); // Every 1 second, display a new loading message
  };

  useEffect(() => {
    if (isUnlocking) {
      displayLoadingMessages();
    }
  }, [isUnlocking]);

  useEffect(() => {
    fetchReport();
    // if (loading) {
    //   const interval = setInterval(fetchReport, 3000);
    //   return () => clearInterval(interval);
    // }
  }, []);

  useEffect(() => {
    let interval;
    let timeout;

    const pollReport = () => {
      fetchReport()
        .then((reportFetched) => {
          if (reportFetched) {
            clearInterval(interval); // Stop polling once the report is available
            clearTimeout(timeout); // Clear timeout when report is fetched
          }
        })
        .catch((error) => {
          console.error("Error polling report:", error);
        });
    };

    // Fetch report immediately if the product is unlocked
    if (product.is_locked === false) {
      fetchReport();
    } else if (loading) {
      // Start polling if the report is not yet available and the product is locked
      interval = setInterval(pollReport, 3000);

      // Stop polling after 5 minutes (300,000 milliseconds)
      timeout = setTimeout(() => {
        clearInterval(interval);
        toast.error(
          "The report is taking longer than expected. Please try again later."
        );
      }, 300000); // 5 minutes = 300,000 ms
    }

    // Cleanup interval and timeout when component unmounts or the report is fetched
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [product, loading]);

  useEffect(() => {
    if (!isLocked && report) {
      setLoading(false);
    }
  }, [report]);

  useEffect(() => {
    if (
      !isLocked &&
      productTargetInfo &&
      report &&
      report.risk_results &&
      report.risk_results["flood"]
    ) {
      if (productTargetInfo["floorNumber"] > 1) {
        setFurnitureLoss(
          `您物件位於本建物的${productTargetInfo["floorNumber"]}樓，因此不會受到淹水影響。`
        );
      } else {
        setFurnitureLoss(
          `您物件位於本建物的1樓，家俱家電可能每年有${report.risk_results["flood"][2]}元的期望損失。`
        );
      }
    }
  }, [isLocked, productTargetInfo, report]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDownloadClick = async () => {
    try {
      // Ensure that pkid is a number
      const product_pkid = Number(pkid);
      if (!product_pkid) {
        console.error("Invalid pkid:", pkid);
        return;
      }

      const response = await axiosInstance.get(
        `risk/generate_report/${product_pkid}`,
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `report_${product_pkid}.docx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the report:", error);
    }
  };

  const preventionMeasures = [
    "熟知住所附近淹水情況，遠離淹水潛勢區。",
    "購買包括淹水保障的保險，以防意外發生。",
    "對於可能的淹水事件，提前制定緊急應對計劃。",
    "在家裡安裝防水設施，例如沙袋、防水門檻等。",
    "定期查看氣象預報，提前預警淹水可能。",
  ];

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const tabData = [
    { label: "摘要", Icon: SummarizeIcon },
    { label: "淹水", Icon: FloodIcon },
    { label: "地震", Icon: RiEarthquakeFill },
    { label: "土壤液化", Icon: HouseboatIcon },
    { label: "坡地災害", Icon: LandslideIcon },
    { label: "氣候變遷", Icon: TsunamiIcon },
    // { label: "安家建議", Icon: SummarizeIcon },
    // Add more tabs as needed
  ];

  if (isLocked) {
    return (
      <div className="detail-root">
        <div className="detail-description">
          <h2 className="section-heading">請以點數解鎖付費報告!</h2>
          <Button
            onClick={handlePointPay}
            variant="contained"
            color="primary"
            className="checkout-button"
            style={{ fontWeight: "bold" }}
          >
            點我解鎖
          </Button>
          <div style={{ margin: "10px" }}>
            沒有會員點數嗎?
            <Link to="/buypoint" style={{ fontWeight: "bold" }}>
              前往購買
            </Link>
          </div>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle sx={{ fontWeight: "bold" }}>
              您即將解鎖付費版風險報告
            </DialogTitle>
            <DialogContent sx={{ justifyContent: "center" }}>
              <Typography component="div" sx={{ justifyContent: "center" }}>
                您將要支付以下產品:
                <ul style={{ listStyle: "none" }}>
                  <li>風險報告-會員點數:1000點</li>
                  {/* <li></li> */}
                </ul>
                <br />
                {/* <hr /> */}
                確定要進行付款嗎?
              </Typography>
            </DialogContent>

            <DialogActions sx={{ justifyContent: "center" }}>
              <Button onClick={() => setOpenDialog(false)} color="primary">
                取消
              </Button>
              <Button onClick={confirmPayment} color="primary">
                確認付款
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }

  if (loading || isUnlocking || !report || Object.keys(report).length === 0) {
    return (
      <div className="detail-root">
        <div className="detail-description">
          <Container maxWidth="sm">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="50vh"
            >
              <CircularProgress />
              <Typography variant="h5" sx={{ ml: 2 }}>
                {loadingMessage || "載入報告..."}
              </Typography>
            </Box>
          </Container>
        </div>
      </div>
    );
  }

  // if (!report || Object.keys(report).length === 0) {
  //   return (
  //     <div className="detail-root">
  //       <div className="detail-description">
  //         <h2 className="section-heading">專屬報告仍在準備中，請耐心等候...</h2>
  //       </div>
  //     </div>
  //   );
  // }
  return (
    // <Container className="risk-report" sx={{ padding: "0px" }}>
    <div className="detail-root" style={{ padding: "0px" }}>
      <Paper
        className="detail-paper1"
        elevation={3}
        sx={{ paddingTop: "10px" }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons={isMobile ? true : "auto"} // Enable scroll buttons on mobile
          allowScrollButtonsMobile
          sx={{
            "& .MuiTabs-flexContainer": {
              justifyContent: "center",
            },
            "& .MuiTab-root": {
              minWidth: isMobile ? "40px" : "80px", // Adjust tab minWidth for better fit on mobile
              padding: isMobile ? "2px" : "10px",
            },
          }}
        >
          {tabData.map((tab, index) => (
            <Tab
              key={index}
              icon={
                <CustomTab
                  icon={<tab.Icon />}
                  label={tab.label}
                  active={activeTab === index}
                />
              }
              {...a11yProps(index)} // Ensure you have defined this function for accessibility
            />
          ))}
        </Tabs>

        {/* Conditional rendering based on the active tab */}

        {activeTab === 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography className="section">摘要</Typography>
              <Summary
                report={report}
                productTargetInfo={productTargetInfo}
                furnitureLoss={furnitureLoss}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className="section">綜合實體風險</Typography>
              <RiskRadarChart report={report} />
            </Grid>
          </Grid>
        )}
        {activeTab === 1 &&
          report.risk_results &&
          report.risk_results["flood"] && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Typography className="section">建物淹水風險</Typography>
                <div>
                  <FloodResult
                    report={report}
                    productTargetInfo={productTargetInfo}
                    furnitureLoss={furnitureLoss}
                  />
                </div>
              </Grid>
            </Grid>
          )}
        {activeTab === 2 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography className="section">建物地震風險:</Typography>
              <EerthquakeResult report={report} />
            </Grid>
          </Grid>
        )}
        {activeTab === 3 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography className="section">建物土壤液化風險:</Typography>
              <LiqResult
                report={report}
                productTargetInfo={productTargetInfo}
              />

              {/* <RiskMapClipLiq report={report} /> */}
            </Grid>
          </Grid>
        )}
        {activeTab === 4 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography className="section">坡地災害風險:</Typography>
              <RiskMapClipLandSlide report={report} />
            </Grid>
          </Grid>
        )}
        {activeTab === 5 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography className="section">氣候變遷:</Typography>
              <SealevelResult
                report={report}
                productTargetInfo={productTargetInfo}
                furnitureLoss={furnitureLoss}
              />
              {/* <RiskMapClipSeaLevel report={report} /> */}
            </Grid>
          </Grid>
        )}

        {/* {activeTab === 6 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Sugguestions report={report} />
            </Grid>
          </Grid>
        )} */}
      </Paper>
    </div>
    // {/* </Container> */}
  );
};

export default PaidReport;
