import React from "react";
// import "../../app.scss";
import "./Rain.scss";
import { Link } from "react-router-dom";

const Rain = () => {
  return (
    // <div className="oldhome w-100 vh-100 position-relative">
    <div className="homeIndex">
      <div
        className="bg w-100 h-100 position-relative"
        style={{ zIndex: "-10" }}
      >
        <img
          className="city-l w-100 opacity-75 center"
          src="/img/city-l.png"
          style={{ zIndex: "-9" }}
          alt=""
        />
        <img
          className="city-r w-100 opacity-50 center"
          src="/img/city-r.png"
          alt=""
        />
        {/* <img className="city-r opacity-50 center" src="/img/city-r.png" /> */}
        <div
          className="primary_bg h-100 position-absolute"
          style={{ zIndex: "-8" }}
        ></div>
        {/* <div className="primary_bg" style={{ zIndex: "-8" }}></div> */}
        <img
          className="dot mask w-100 center"
          src="/img/city-dot1.png"
          alt=""
        />
        <img
          className="dot mask w-100 center"
          src="/img/city-dot2.png"
          alt=""
        />
        <img
          className="dot mask w-100 center"
          src="/img/city-dot3.png"
          alt=""
        />
        <img
          className="dot mask w-100 center"
          src="/img/city-dot4.png"
          alt=""
        />
        <img
          className="dot mask w-100 center"
          src="/img/city-dot5.png"
          alt=""
        />
        <img
          className="dot mask w-100 center"
          src="/img/city-dot6.png"
          alt=""
        />
        <img
          className="dot mask w-100 center"
          src="/img/city-dot7.png"
          alt=""
        />
      </div>
      <div
        className="lines mx-auto vw-100 vh-100 position-absolute"
        style={{ top: "0", left: "0" }}
      >
        {/* <div className="lines" style={{ top: "0", left: "0" }}> */}
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <div className="logo_slogan position-absolute center">
        {/* <div className="logo_slogan"> */}
        <img className="logo" src="/img/logo-right-white.png" alt="安家百科" />
        <div className="flex-row ps-2 ps-sm-1 ps-md-3">
          <h1 className="gray mb-0">讓每個</h1>
          <h1 className="white mb-0">人都能住得安心</h1>
        </div>
      </div>
      <div className="small_word white flex-row position-absolute">
        <hr className="opacity-100 m-0 me-2" />
        <p className="small m-0">SAFEHOUSING</p>
      </div>
      <button
        className="cta second_bg border-0 d-flex align-items-end justify-content-end position-absolute p-4"
        style={{ bottom: "0" }}
      >
        {/* <button className="cta" style={{bottom:"0"}}> */}
        <Link className="link" to="/citymap">
          <h4 className="white m-0">馬上體驗</h4>
        </Link>
      </button>
    </div>
  );
};

export default Rain;
