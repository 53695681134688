import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { persistor, store } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// import "./styles.css";
import { TourProvider } from "@reactour/tour";

const steps = [
  {
    selector: ".report-rules", // This should match the class of the element for the rules
    content:
      "一般用戶可產生5次免費版報告，付費用戶(已購買會員點數)則可以產生20次免費版報告!",
    position: "top", // Positions the tooltip at the top of the selector element
  },
  {
    selector: ".click-on-map", // Selector for the map click instruction
    content:
      "請於地圖上點選分析建物，若建物圖層不合需求，請點選繪製手繪模式自行繪製分析區域",
    position: "top",
  },
  {
    selector: ".google-ride", // Selector for the search input or Google Places autocomplete
    content: "亦可直接搜尋分析地址",
    position: "right",
  },
  {
    selector: ".target-ride", // selector the property input section
    content: "輸入您想要分析的建物條件",
    position: "top",
  },
  {
    selector: ".submit-container button.add", // selector the report generation button
    content: "最後產製報告!",
    position: "top",
  },
  {
    selector: ".report-rules", // This should match the class of the element for the rules
    content: "開始選擇分析區域吧!",
    position: "top", // Positions the tooltip at the top of the target element
  },
  // More steps here
];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <Provider store={store}>
  //   <PersistGate loading={"loading"} persistor={persistor}>
  //     <App />
  //   </PersistGate>
  // </Provider>

  <TourProvider
    steps={steps}
    styles={{
      popover: (base) => ({
        ...base,
        borderRadius: "10px", // Adds rounded corners to the popover
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)", // Optional shadow
      }),
      // maskWrapper: (base) => ({
      //   ...base,
      //   borderRadius: "10px", // Rounded corners for the mask area
      // }),
      // maskArea: (base) => ({
      //   ...base,
      //   borderRadius: "10px", // Rounded corners for the highlighted area
      // }),
      // highlightedArea: (base) => ({
      //   ...base,
      //   borderRadius: "10px", // Rounded corners for the highlighted area
      // }),
    }}
  >
    <Provider store={store}>
      <PersistGate loading={"loading"} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </TourProvider>
);
