import React from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./Slider3.scss";

const Slider3 = () => {
  const images = [
    // "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    // "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
    // "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    "/img/coke.jpg",
    "/img/howto.png",
    "/img/web_slider013.png",
    // "/img/web_slider012.png",
    // "/img/web_slider0.png",
    "/img/web_slider.png",
    "/img/cityhall.png",
  ];

  return (
    <div className="slider3">
      <div className="slideshow-container">
        <Fade>
          <div className="each-slide">
            <div>
              <img src={images[0]} alt="" />
            </div>
          </div>
          <div className="each-slide">
            <div>
              <img src={images[1]} alt="" />
            </div>
          </div>
          <div className="each-slide">
            <div>
              <img src={images[2]} alt="" />
            </div>
          </div>
          <div className="each-slide">
            <div>
              <img src={images[3]} alt="" />
            </div>
          </div>
          <div className="each-slide">
            <div>
              <img src={images[4]} alt="" />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Slider3;
