import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./RiskMapAll.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import area from "@turf/area";
import DepthProbBuilding from "../Prob/DepthProbBuilding";
import * as turf from "@turf/turf";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import DrawPolygonMap from "./DrawPolygonMap";
import ClickPolygonMap from "./ClickPolygonMap";
import FactoryIcon from "@mui/icons-material/Factory";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
// import DrawIcon from '@mui/icons-material/Draw';
import ModeIcon from "@mui/icons-material/Mode";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = mapboxtoken;

const BuildingRisk = ({ report }) => {
  const [pkid, setPkid] = useState(null);
  const mapContainer = useRef(null);
  const mapRef = useRef(null); // New reference for the map instance
  const [isLoading, setIsLoading] = useState(false);
  const [clickedBuildingIds, setClickedBuildingIds] = useState(new Set());
  const [buildingInfo, setBuildingInfo] = useState([]);
  const [updatedBuildingInfo, setUpdatedBuildingInfo] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false); // New state to track drawing mode
  const [initialCoor, setInitialCoor] = useState({
    lng: 121.30128,
    lat: 24.99265,
  });
  const detailOrder = report.product;

  const [waterProbData, setWaterProbData] = useState({
    "10cm": 100.0,
    "20cm": 100.0,
    "30cm": 100.0,
    "40cm": 99.732,
    "50cm": 83.015,
    "60cm": 29.465,
    "70cm": 4.254,
    "80cm": 0.715,
    "90cm": 0.052,
    "100cm": 0.003,
    "110cm": 0.0,
    "120cm": 0.0,
    "130cm": 0.0,
    "140cm": 0.0,
    "150cm": 0.0,
    "160cm": 0.0,
    "170cm": 0.0,
    "180cm": 0.0,
    "190cm": 0.0,
    "200cm": 0.0,
  });
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const CustomTab = ({ label, icon }) => (
    <div style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}>
      {icon}
      {!isMobile && <span style={{ marginLeft: 8 }}>{label}</span>}
    </div>
  );

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "220vh",
        padding: "10px",
        position: "relative",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <div>
          <ul className="content-list" style={{ listStyleType: "disc" }}>
            {/* <li>步驟:</li> */}
            <li>
              1. 請在地圖上選擇建築物或自行畫圖
              (自行畫圖時，請依建築物投射地面的形狀為主，過大的範圍會影響分析結果)
            </li>
            <li>2. 選擇門檻高度 (建物一樓地板高度與旁邊地面的高度差)</li>
          </ul>
        </div>
        {/* <h5>
          步驟: 1. 請在地圖上選擇建築物或自行畫圖
          (自行畫圖時，請依建築物投射地面的形狀為主，過大的範圍會影響分析結果)
          2. 選擇門檻高度 (建物一樓地板高度與旁邊地面的高度差)
        </h5> */}

        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label={<CustomTab label="點選建物" icon={<HomeWorkIcon />} />} />
          <Tab label={<CustomTab label="手繪區域" icon={<ModeIcon />} />} />
        </Tabs>
      </Box>

      {/* Conditional Rendering based on tabValue */}
      {tabValue === 0 ? (
        <ClickPolygonMap detailOrder={detailOrder} />
      ) : (
        <DrawPolygonMap detailOrder={detailOrder} />
      )}
    </div>
  );
};

export default BuildingRisk;
