import React from "react";
import ReportMapSlider3 from "../Map/ReportMapSlider3";
import FloodMapboxSyncMaps from "./FloodMapboxSyncMaps_v2";

const Compare = ({ report }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "100%", // Ensure it fills the container
      }}
    >
      {/* Now directly rendering the ReportMapSlider3 component */}
      <div className="detail-map" style={{ height: "85vh" }}>
        {/* <ReportMapSlider3 detailOrder={report.product} /> */}
        <FloodMapboxSyncMaps report={report} />
      </div>
    </div>
  );
};

export default Compare;
