import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// import ReportMap from "../Map/ReportMap";
import ReportMapSlider from "../Map/ReportMapSlider";
// import "./styles.scss"; // import SCSS file
import "./ReportPage.scss"; // import SCSS file
import { useParams } from "react-router-dom";
import axiosInstance from "../../axios";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Prob from "../Prob/Prob";
// import PointProb from "../Map/PointProb";
import PointProb from "../Map/PointProbAll";
import Prob2 from "../Prob/Prob2";

// need radius, location, goejson, wms_layer_name, polygon_3857

const DetailPage = () => {
  const { pkid } = useParams();
  const [detailOrder, setDetailOrder] = useState({});
  const [report, setReport] = useState({});
  const navigate = useNavigate();
  const floodLevels = [
    {
      level: "10公分淹水",
      impacts: [
        {
          category: "人",
          description:
            "對成人來說，風險較低。但對於小孩和老年人，可能存在滑倒風險。盡量避免讓他們在此深度的積水中行走。",
        },
        {
          category: "房子",
          description:
            "房屋可能出現輕微的損害，如地毯潮濕，修復費用可能在幾百到幾千元。",
        },
        {
          category: "機車",
          description: "風險相對較低，但要注意避免水花進入引擎或排氣管。",
        },
        {
          category: "汽車",
          description: "影響較小，但需要注意避免水花飛濺到引擎。",
        },
      ],
    },
    {
      level: "20公分淹水",
      impacts: [
        {
          category: "人",
          description:
            "對成人來說，風險較低。但對於小孩和老年人，可能存在滑倒風險。盡量避免讓他們在此深度的積水中行走。",
        },
        {
          category: "房子",
          description:
            "房屋可能出現輕微的損害，如地毯潮濕，修復費用可能在幾百到幾千元。",
        },
        {
          category: "機車",
          description: "風險相對較低，但要注意避免水花進入引擎或排氣管。",
        },
        {
          category: "汽車",
          description: "影響較小，但需要注意避免水花飛濺到引擎。",
        },
      ],
    },
    {
      level: "30公分淹水",
      impacts: [
        {
          category: "人",
          description:
            "此深度的積水可能導致所有人都有生命危險，特別是對於無法游泳的人來說。避免涉水是最好的選擇。",
        },
        {
          category: "房子",
          description:
            "如果水位達到這種深度，可能會直接對房屋結構造成損害，並導致內部財產遭受重大損失。修復成本可能會達到數萬元，甚至更高。",
        },
        {
          category: "機車",
          description:
            "此深度的積水可能會導致機車完全熄火，並可能對電路和引擎造成損壞。修理成本可能達到幾千元，或者可能需要更換新車。",
        },
        {
          category: "汽車",
          description:
            "如果積水達到這種深度，汽車可能會無法運作，並可能導致嚴重的機械損壞。修理成本可能在數千到數萬元之間，或者可能需要更換新車。",
        },
      ],
    },
    {
      level: "40公分淹水",
      impacts: [
        {
          category: "人",
          description:
            "此深度的積水會對所有人都有生命危險，特別是對於無法游泳的人來說。在這種情況下，避免涉水是最好的選擇。",
        },
        {
          category: "房子",
          description:
            "此深度的積水會對房屋結構造成重大損害，包括地板、牆壁和電氣系統。修復成本可能達到數萬元，甚至上百萬元。",
        },
        {
          category: "機車",
          description:
            "此深度的積水將導致機車全面熄火，引擎、電路和內部部件可能全部損壞，可能需要全面更換，成本可能達到新車價格。",
        },
        {
          category: "汽車",
          description:
            "此深度的積水會導致引擎進水並可能引發嚴重機械損壞。修復可能需要花費數萬元，或者需要更換新車。",
        },
      ],
    },
    {
      level: "50公分淹水",
      impacts: [
        {
          category: "人",
          description:
            "此深度的積水對所有人都構成巨大生命危險。人們應該絕對避免在此深度的水中嘗試涉水。",
        },
        {
          category: "房子",
          description:
            "此深度的積水會對房屋結構和內部設施造成極大的損害，可能導致房屋不再適合居住。修復成本可能達到上百萬元。",
        },
        {
          category: "機車",
          description: "此深度的積水將使機車全面損壞，需要更換新車。",
        },
        {
          category: "汽車",
          description: "此深度的積水將導致汽車全面損壞，可能需要更換新車。",
        },
      ],
    },

    // add other flood levels here...
  ];

  const preventionMeasures = [
    "熟知住所附近淹水情況，遠離淹水潛勢區。",
    "購買包括淹水保障的保險，以防意外發生。",
    "對於可能的淹水事件，提前制定緊急應對計劃。",
    "在家裡安裝防水設施，例如沙袋、防水門檻等。",
    "定期查看氣象預報，提前預警淹水可能。",
  ];

  useEffect(() => {
    const getDetailOrder = async () => {
      try {
        const res = await axiosInstance.get(`risk/products/${pkid}/`);
        // const res = await axiosInstance.get(`shop/products/${pkid}/`);
        setDetailOrder(res.data);
        // console.log(res.data);
      } catch (err) {
        // console.log(err);
      }
    };
    getDetailOrder();

    // console.log(cartItem);
  }, []);

  // console.log("detailOrder", detailOrder);

  useEffect(() => {
    const getReport = async () => {
      try {
        const res = await axiosInstance.get(`shop/reports/?product_id=${pkid}`);
        setReport(res.data[0]);
        // console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getReport();

    // console.log(cartItem);
  }, []);

  if (!report || Object.keys(report).length === 0) {
    return (
      <div className="detail-root">
        <div className="detail-description">
          <h2 className="section-heading">專屬報告仍在準備中，請耐心等候...</h2>
        </div>
      </div>
    ); // display loading message until the data is available
  }

  return (
    <div className="detail-root">
      <Paper className="detail-paper" elevation={3}>
        <div className="detail-h0">SAFEHOUSING</div>
        <hr className="m-0" />
        <div className="detail-description">
          <h1 className="section-heading-1">安家百科-專屬報告</h1>
          <hr className="m-0" />
          <Typography variant="subtitle1" component="div" gutterBottom>
            產品代號: {report.id}
          </Typography>
          <Typography variant="subtitle1" component="div" gutterBottom>
            版本號: {report.version}
          </Typography>
          <hr />
          <Typography variant="subtitle1" component="div" gutterBottom>
            本報告包含以下內容：
            <br /> 1. 高精度淹水分析結果
            <br /> 2. 淹水機率分析
            <br /> 3. 安家建議
            <br /> 4. 其他說明
          </Typography>
          <hr className="m-0" />
        </div>
        <hr />
        <h2 className="section-heading">高精度淹水分析結果:</h2>
        <span>
          下圖為6小時350mm降雨淹水潛勢圖，左為公開資料，右為本產品分析
        </span>
        <div className="detail-map">
          <ReportMapSlider detailOrder={report.product} />
        </div>
        <div className="prob-component-container">
          <Prob2 report={report} />
        </div>
        <div className="detail-description">
          <Typography variant="subtitle1" component="div" gutterBottom>
            <h2 className="section-heading">情境與結果說明</h2>
            {report.summary.replace(/^'(.*)',\)$/, "$1")}
          </Typography>
        </div>
        <hr />

        <h2 className="section-heading">淹水機率分析:</h2>
        <span>於下圖中點選淹水點位、以計算淹水機率:</span>

        <div className="detail-pointprob-map">
          <PointProb detailOrder={report.product} report={report} />
        </div>

        <hr />
        <hr />
        <hr />
        <hr />

        <h5 className="primary_color pb-2 mb-3 mb-0 mt-5">淹水高度示意圖:</h5>

        <div className="img_floodHeight">
          <img
            className="floos_obj"
            src="/img/report_floodHeight_house.png"
            alt="Flood Height House"
          ></img>

          <div className="water_height" style={{ height: "4%", zIndex: "5" }}>
            <p className="water_height_text">10 cm</p>
          </div>
          <div className="water_height" style={{ height: "8%", zIndex: "4" }}>
            <p className="water_height_text">20 cm</p>
          </div>
          <div className="water_height" style={{ height: "12%", zIndex: "3" }}>
            {" "}
            <p className="water_height_text">30 cm</p>
          </div>
          <div className="water_height" style={{ height: "16%", zIndex: "2" }}>
            {" "}
            <p className="water_height_text">40 cm</p>
          </div>
          <div className="water_height" style={{ height: "20%", zIndex: "1" }}>
            {" "}
            <p className="water_height_text">50 cm</p>
          </div>
        </div>
        <p className="small mb-0">
          比例尺: 男性1.75m、女性1.6m、孩童1.3m、機車1.4m、汽車1.5m、房屋2.5m。
        </p>

        {/* <h2 className="section-heading">淹水深度的影響與行動方案參考:</h2> */}
        <h5 className="primary_color pb-2 mb-3 mb-0 mt-5">
          淹水深度的影響與行動方案參考:
        </h5>

        <div className="flood-level-explain">
          <ul style={{ listStyle: "square" }}>
            {floodLevels.map((floodLevel, index) => (
              <li key={index}>
                {floodLevel.level}
                <ul>
                  {floodLevel.impacts.map((impact, i) => (
                    <li key={i}>
                      {impact.category}: {impact.description}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
            <li>
              預防措施
              <ul>
                {preventionMeasures.map((measure, index) => (
                  <li key={index}>{measure}</li>
                ))}
              </ul>
            </li>
          </ul>
          <p>
            以上所提供的數據和價格只是大致的估計，具體情況會因地理位置、產品品牌、維修服務等多種因素變化，可能需要由專業人員進行評估。
          </p>
        </div>
        <hr />
        {/* <hr/> */}

        <h2 className="section-heading">安家建議:</h2>

        <div className="detail-description">
          {/* <Typography variant="subtitle1" component="div" gutterBottom>
            <h2 className="section-heading">情境與結果說明</h2>
            {report.summary.replace(/^'(.*)',\)$/, "$1")}
          </Typography> */}
          <Typography variant="body1" component="div" gutterBottom>
            <h2 className="section-heading">防災建議</h2>
            {/* <h2 className="section-heading">情境與結果說明</h2> */}
            {report.sugguestions.replace(/^\('(.*)',\)$/, "$1")}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            <h2 className="section-heading">行動方案</h2>
            {report.action_plans.replace(/^\('(.*)',\)$/, "$1")}
          </Typography>
        </div>

        <h2 className="section-heading">其他說明:</h2>

        <div className="detail-description">
          <Typography
            variant="body1"
            component="div"
            gutterBottom
            style={{ textAlign: "left", width: "100%" }}
          >
            <h2 className="section-heading">不確定性說明</h2>
            {report.other_info
              .replace(/^\('(.*)',\)$/, "$1")
              .replace(/(\\n|\n)/g, "")}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            <h2 className="section-heading">模擬資料說明</h2>
            {report.sim_info
              .replace(/^\('(.*)',\)$/, "$1")
              .replace(/(\\n|\n)/g, "")}
          </Typography>

          <Typography
            variant="body1"
            component="div"
            gutterBottom
            style={{ textAlign: "left", width: "100%" }}
          >
            <h2 className="section-heading">用途限制</h2>
            {/* {report.use_info.replace(/^\('(.*)',\)$/, "$1").replace(/\n/g, "")} */}
            僅供購買用戶之防救災風險評估使用、未經同意不得轉載、或商用用途。
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            <h2 className="section-heading">模型參數說明</h2>
            {report.para_info
              .replace(/^\('(.*)',\)$/, "$1")
              .replace(/(\\n|\n)/g, "")}
          </Typography>
          {/* <Typography variant="body1" component="div" gutterBottom>
            <h2 className="section-heading">版本號:</h2>
            {report.version
              .replace(/^\('(.*)',\)$/, "$1")
              .replace(/(\\n|\n)/g, "")}
          </Typography> */}

          {/* <Typography variant="body1" component="div" gutterBottom>
            <h2 className="section-heading">備註</h2>
            {report.note}
          </Typography> */}
        </div>
      </Paper>
    </div>
  );
};

export default DetailPage;
