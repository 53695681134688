import React from "react";
import { motion, useInView } from "framer-motion";
import CountUp from "react-countup";
import "./SummaryChart.css";

const SummaryChartLiq = ({ report }) => {
  const minHeight = 40; // Minimum height for bars
  const minHeight2 = 80; // Minimum height for bars

  const liq_loss_rate_yearly =
    report.risk_results["liguefaction"][7][0].toFixed(2);
  const liq_loss_rate_50y =
    report.risk_results["liguefaction"][7][1].toFixed(2);

  const liq_en_loss = report.risk_results.liguefaction[2][1];
  const liq_non_en_loss = report.risk_results.liguefaction[3][1];
  const liq_loss = liq_en_loss + liq_non_en_loss;

  // For animating numbers when they come into view
  const ref = React.useRef(null);
  const isInView = useInView(ref, { triggerOnce: true, threshold: 0.2 });

  return (
    <div className="flood-loss-container">
      <div className="f-title">發生土壤液化損失機率</div>
      <div>每年發生土壤液化的機率: {liq_loss_rate_yearly}(%)</div>
      <div>未來50年發生土壤液化的機率: {liq_loss_rate_50y} (%)</div>
      <div className="bar-container">
        <div className="bar">
          <div
            className="bar-value-prob"
            style={{
              height: `${Math.max(liq_loss_rate_yearly / 100, minHeight)}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={liq_loss_rate_yearly}
                duration={2.5}
                suffix="%"
              />
            ) : (
              `${liq_loss_rate_yearly}%`
            )}
          </div>
          <div className="bar-label">每年發生機率</div>
        </div>
      </div>

      <br />
      <div className="f-title">因土壤液化每年平均損失</div>
      <div>
        每年發生土壤液化的工程損失:
        {report.risk_results.liguefaction[2][0]} ~
        {report.risk_results.liguefaction[2][1]}， 每年發生土壤液化的非工程損失:
        {report.risk_results.liguefaction[3][0]} ~
        {report.risk_results.liguefaction[3][1]}
        發生土壤液化年期望損失
        {report.risk_results.liguefaction[5][0]} ~
        {report.risk_results.liguefaction[5][1]}
        元/年，其中搬遷費用 、房租、營業損失未考慮。
      </div>
      <div className="bar-container">
        <div className="bar">
          <div
            className="bar-value"
            style={{
              height: `${Math.max(liq_en_loss / 300, minHeight2)}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={liq_en_loss}
                duration={2.5}
                suffix="元/年"
              />
            ) : (
              `${liq_en_loss}元/年`
            )}
          </div>
          <div className="bar-label">工程損失</div>
        </div>

        <div className="plus-sign">+</div>

        <div className="bar">
          <div
            className="bar-value"
            style={{
              height: `${Math.max(liq_non_en_loss / 300, minHeight2)}px`,
            }}
          >
            {isInView ? (
              <CountUp
                start={0}
                end={liq_non_en_loss}
                duration={2.5}
                suffix="元/年"
              />
            ) : (
              `${liq_non_en_loss}元/年`
            )}
          </div>
          <div className="bar-label">非工程損失</div>
        </div>

        <div className="equals-sign">=</div>

        <div className="bar">
          <div
            className="bar-value"
            style={{
              height: `${Math.max(liq_loss / 300, minHeight2)}px`,
            }}
          >
            {isInView ? (
              <CountUp start={0} end={liq_loss} duration={2.5} suffix="元/年" />
            ) : (
              `${liq_loss}元/年`
            )}
          </div>
          <div className="bar-label">綜合</div>
        </div>
      </div>

      <br />
      <div className="f-title">建議</div>
      <div>土壤液化為地震災害的一種，建議可以依據需求增加地震保險的保額</div>
      <br />

      <div className="f-title">詳細內容</div>
      <div>請見土壤液化分析專章</div>
    </div>
  );
};

export default SummaryChartLiq;
