import React from "react";
import { motion, useInView } from "framer-motion";
import CountUp from "react-countup";
import "./SummaryChart.css";

const SummaryChartFlood = ({ report }) => {
  const minHeight = 40; // Minimum height for bars
  const minHeight2 = 80; // Minimum height for bars

  const floodRisk = report.risk_results["flood"][0];
  const flood_prob_yearly_over10 = report.risk_results["flood"][4]["10cm"];
  const flood_prob_yearly_over30 = report.risk_results["flood"][4]["30cm"];

  const flood_loss_yearly_car = report.risk_results["flood"][1];
  const flood_loss_yearly_total = floodRisk;
  const flood_loss_yearly_furniture = floodRisk - flood_loss_yearly_car;

  // For animating numbers when they come into view
  const ref = React.useRef(null);
  const isInView = useInView(ref, { triggerOnce: true, threshold: 0.2 });

  return (
    <div className="flood-loss-container">
      <div className="f-title">發生淹水損失機率</div>
      <div className="bar-container">
        <div className="bar">
          <div
            className="bar-value-prob"
            style={{
              height: `${Math.max(flood_prob_yearly_over10 / 100, minHeight)}px`,
            }}
          >
            {isInView ? (
              <CountUp start={0} end={flood_prob_yearly_over10} duration={2.5} suffix="%" />
            ) : (
              `${flood_prob_yearly_over10}%`
            )}
          </div>
          <div className="bar-label">家俱家電(大於10cm淹水)</div>
        </div>

        <div className="bar">
          <div
            className="bar-value-prob"
            style={{
              height: `${Math.max(flood_prob_yearly_over30 / 100, minHeight)}px`,
            }}
          >
            {isInView ? (
              <CountUp start={0} end={flood_prob_yearly_over30} duration={2.5} suffix="%" />
            ) : (
              `${flood_prob_yearly_over30}%`
            )}
          </div>
          <div className="bar-label">汽機車(大於30cm淹水)</div>
        </div>
      </div>

      <br />
      <div className="f-title">每年平均淹水損失</div>
      <div className="bar-container">
        <div className="bar">
          <div
            className="bar-value"
            style={{
              height: `${Math.max(flood_loss_yearly_furniture / 300, minHeight)}px`,
            }}
          >
            {isInView ? (
              <CountUp start={0} end={flood_loss_yearly_furniture} duration={2.5} suffix="元/年" />
            ) : (
              `${flood_loss_yearly_furniture}元/年`
            )}
          </div>
          <div className="bar-label">家俱家電</div>
        </div>

        <div className="plus-sign">+</div>

        <div className="bar">
          <div
            className="bar-value"
            style={{
              height: `${Math.max(flood_loss_yearly_car / 300, minHeight2)}px`,
            }}
          >
            {isInView ? (
              <CountUp start={0} end={flood_loss_yearly_car} duration={2.5} suffix="元/年" />
            ) : (
              `${flood_loss_yearly_car}元/年`
            )}
          </div>
          <div className="bar-label">汽機車</div>
        </div>

        <div className="equals-sign">=</div>

        <div className="bar">
          <div
            className="bar-value"
            style={{
              height: `${Math.max(flood_loss_yearly_total / 300, minHeight2)}px`,
            }}
          >
            {isInView ? (
              <CountUp start={0} end={flood_loss_yearly_total} duration={2.5} suffix="元/年" />
            ) : (
              `${flood_loss_yearly_total}元/年`
            )}
          </div>
          <div className="bar-label">綜合</div>
        </div>
      </div>

      <br />
      <div className="f-title">建議</div>
      <div>
        {floodRisk > 1000
          ? "本物件每年平均損失超過1000元，建議可在出入口、氣窗安裝檔水閘版，或可購買汽機車淹水險。"
          : floodRisk > 100 && floodRisk <= 1000
          ? "建議可加裝防水閘版、或斟酌是否購買淹水保險。"
          : "恭喜你買到淹水機率低的房屋!"}
      </div>
      <br />

      <div className="f-title">詳細內容</div>
      <div>請見淹水分析專章</div>
    </div>
  );
};

export default SummaryChartFlood;
