import React, { useEffect, useState } from "react";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import RiskRadarChart from "../../components/Charts/RiskRadarChart";
import "./RiskReport.scss";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../axios";
import RiskMapClip from "../../components/RiskMapAll/RiskMapClip";
import RiskMapClipSeaLevel from "../../components/RiskMapAll/RiskMapClipSeaLevel";
import RiskMapClipLiq from "../../components/RiskMapAll/RiskMapClipLiq";
import RiskMapClipEarthquake from "../../components/RiskMapAll/RiskMapClipEarthquake";
import RiskMapClipLandSlide from "../../components/RiskMapAll/RiskMapClipLandSlide2";
import PointProb from "../../components/Map/PointProbRisk";
// import PointProb from "../../components/Map/PointProbAll";
import Prob2 from "../../components/Prob/Prob2";
import DepthProb from "../../components/Prob/DepthProb";
import DepthProbTable from "../../components/Prob/DepthProbTable";
import ReportMapSlider from "../../components/Map/ReportMapSlider";
import ReportMapSlider2 from "../../components/Map/ReportMapSlider2";
import html2canvas from "html2canvas";
import axios from "axios";
import { Tabs, Tab } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home"; // Example icon
import InfoIcon from "@mui/icons-material/Info"; // Another example icon
import FloodIcon from "@mui/icons-material/Flood";
import PublicIcon from "@mui/icons-material/Public";
import HouseboatIcon from "@mui/icons-material/Houseboat";
import LandslideIcon from "@mui/icons-material/Landslide";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import BuildingRisk from "../../components/RiskMapAll/BuildingRisk";
import BuildingRiskAll from "../../components/RiskMapAll/BuildingRiskAll";
import FloodResult from "../../components/RiskMapAll/FloodResult";
import EerthquakeResult from "../../components/RiskMapAll/EerthquakeResult";
import TsunamiIcon from "@mui/icons-material/Tsunami";
import { RiEarthquakeFill } from "react-icons/ri";
import LiqResult from "../../components/RiskMapAll/LiqResult";
import SealevelResult from "../../components/RiskMapAll/SealevelResult";
import BuildingMap from "../../components/RiskMapAll/BuildingMap";
import FloodMapboxSyncMaps from "../../components/RiskMapAll/FloodMapboxSyncMaps";
import Compare from "../../components/RiskMapAll/Compare";
import Summary from "./Summary";

const PaidReport = ({ report, productTargetInfo }) => {
  const { pkid } = useParams();
  // const [report, setReport] = useState({});
  // State to hold the parsed product target info
  // const [productTargetInfo, setProductTargetInfo] = useState(null);
  // console.log(productTargetInfo);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const CustomTab = ({ label, icon }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // Optionally adjust icon size here if it's a component
    const adjustedIcon = React.cloneElement(icon, {
      style: {
        // fontSize: isMobile ? "16px" : "24px",
        fontSize: isMobile ? "24px" : "36px", // Adjust the icon size based on screen size
        color: "black", // Set icon color to black
      },
    });

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontWeight: "bold",
          color: "black", // Set text color to black
        }}
      >
        {adjustedIcon}
        {!isMobile && <span style={{ marginLeft: 8 }}>{label}</span>}
      </div>
    );
  };

  const [furnitureLoss, setFurnitureLoss] = useState("");

  useEffect(() => {
    // Check if productTargetInfo and report are not undefined
    if (productTargetInfo && report) {
      if (productTargetInfo["floorNumber"] > 1) {
        setFurnitureLoss(
          `您物件位於本建物的${productTargetInfo["floorNumber"]}樓，因此不會受到淹水影響。`
        );
      } else {
        setFurnitureLoss(
          `您物件位於本建物的1樓，家俱家電可能每年有${report.risk_results["flood"][2]}元的期望損失。`
        );
      }
    }
  }, [productTargetInfo, report]); // Add productTargetInfo and report to the dependency array

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDownloadClick = async () => {
    try {
      // Ensure that pkid is a number
      const product_pkid = Number(pkid);
      if (!product_pkid) {
        console.error("Invalid pkid:", pkid);
        return;
      }

      const response = await axiosInstance.get(
        `risk/generate_report/${product_pkid}`,
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `report_${product_pkid}.docx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the report:", error);
    }
  };

  const floodLevels = [
    {
      level: "10公分淹水",
      impacts: [
        {
          category: "人",
          description:
            "對成人來說，風險較低。但對於小孩和老年人，可能存在滑倒風險。盡量避免讓他們在此深度的積水中行走。",
        },
        {
          category: "房子",
          description:
            "房屋可能出現輕微的損害，如地毯潮濕，修復費用可能在幾百到幾千元。",
        },
        {
          category: "機車",
          description: "風險相對較低，但要注意避免水花進入引擎或排氣管。",
        },
        {
          category: "汽車",
          description: "影響較小，但需要注意避免水花飛濺到引擎。",
        },
      ],
    },
    {
      level: "20公分淹水",
      impacts: [
        {
          category: "人",
          description:
            "對成人來說，風險較低。但對於小孩和老年人，可能存在滑倒風險。盡量避免讓他們在此深度的積水中行走。",
        },
        {
          category: "房子",
          description:
            "房屋可能出現輕微的損害，如地毯潮濕，修復費用可能在幾百到幾千元。",
        },
        {
          category: "機車",
          description: "風險相對較低，但要注意避免水花進入引擎或排氣管。",
        },
        {
          category: "汽車",
          description: "影響較小，但需要注意避免水花飛濺到引擎。",
        },
      ],
    },
    {
      level: "30公分淹水",
      impacts: [
        {
          category: "人",
          description:
            "此深度的積水可能導致所有人都有生命危險，特別是對於無法游泳的人來說。避免涉水是最好的選擇。",
        },
        {
          category: "房子",
          description:
            "如果水位達到這種深度，可能會直接對房屋結構造成損害，並導致內部財產遭受重大損失。修復成本可能會達到數萬元，甚至更高。",
        },
        {
          category: "機車",
          description:
            "此深度的積水可能會導致機車完全熄火，並可能對電路和引擎造成損壞。修理成本可能達到幾千元，或者可能需要更換新車。",
        },
        {
          category: "汽車",
          description:
            "如果積水達到這種深度，汽車可能會無法運作，並可能導致嚴重的機械損壞。修理成本可能在數千到數萬元之間，或者可能需要更換新車。",
        },
      ],
    },
    {
      level: "40公分淹水",
      impacts: [
        {
          category: "人",
          description:
            "此深度的積水會對所有人都有生命危險，特別是對於無法游泳的人來說。在這種情況下，避免涉水是最好的選擇。",
        },
        {
          category: "房子",
          description:
            "此深度的積水會對房屋結構造成重大損害，包括地板、牆壁和電氣系統。修復成本可能達到數萬元，甚至上百萬元。",
        },
        {
          category: "機車",
          description:
            "此深度的積水將導致機車全面熄火，引擎、電路和內部部件可能全部損壞，可能需要全面更換，成本可能達到新車價格。",
        },
        {
          category: "汽車",
          description:
            "此深度的積水會導致引擎進水並可能引發嚴重機械損壞。修復可能需要花費數萬元，或者需要更換新車。",
        },
      ],
    },
    {
      level: "50公分淹水",
      impacts: [
        {
          category: "人",
          description:
            "此深度的積水對所有人都構成巨大生命危險。人們應該絕對避免在此深度的水中嘗試涉水。",
        },
        {
          category: "房子",
          description:
            "此深度的積水會對房屋結構和內部設施造成極大的損害，可能導致房屋不再適合居住。修復成本可能達到上百萬元。",
        },
        {
          category: "機車",
          description: "此深度的積水將使機車全面損壞，需要更換新車。",
        },
        {
          category: "汽車",
          description: "此深度的積水將導致汽車全面損壞，可能需要更換新車。",
        },
      ],
    },

    // add other flood levels here...
  ];
  const preventionMeasures = [
    "熟知住所附近淹水情況，遠離淹水潛勢區。",
    "購買包括淹水保障的保險，以防意外發生。",
    "對於可能的淹水事件，提前制定緊急應對計劃。",
    "在家裡安裝防水設施，例如沙袋、防水門檻等。",
    "定期查看氣象預報，提前預警淹水可能。",
  ];

  // // Effect to parse the product target info when report changes
  // useEffect(() => {
  //   if (report && report.product && report.product.target_info) {
  //     try {
  //       const parsedTargetInfo = JSON.parse(report.product.target_info);
  //       setProductTargetInfo(parsedTargetInfo);
  //     } catch (error) {
  //       console.error("Error parsing target_info:", error);
  //       // Handle error (e.g., set default value, show error message)
  //     }
  //   }
  // }, [report]);

  // //get the report
  // useEffect(() => {
  //   const getReport = async () => {
  //     try {
  //       const res = await axiosInstance.get(`shop/reports/?product_id=${pkid}`);
  //       setReport(res.data[0]);
  //       // console.log(res.data);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   getReport();
  // }, []);

  if (!report || Object.keys(report).length === 0) {
    return (
      <div className="detail-root">
        <div className="detail-description">
          <h2 className="section-heading">專屬報告仍在準備中，請耐心等候...</h2>
        </div>
      </div>
    );
  }
  return (
    // <Container className="risk-report" sx={{ padding: "0px" }}>
    <div className="detail-root" style={{ padding: "0px" }}>
      {/* <Paper className="detail-paper" elevation={3}> */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography className="section">摘要</Typography>
          <div>
            <ul className="content-list" style={{ listStyleType: "disc" }}>
              <li>
                <span style={{ fontSize: "16px", fontWeight: "bolder" }}>
                  分析參數:
                  <br />
                </span>
                建物材質:{productTargetInfo?.["buildingMaterial"]}/ 建造時間:
                {productTargetInfo?.["buildingTime"]}年/ 建物價值:
                {productTargetInfo?.["buildingValue"]} 萬元/ 所在樓層:
                {productTargetInfo?.["floorNumber"]}樓/ 總樓層:
                {productTargetInfo?.["totalFloorNumber"]}樓/ 汽機車價值:
                {productTargetInfo?.["carValue"]}萬元/ 傢俱價值:
                {productTargetInfo?.["furnitureLoss"]}萬元/ 門檻高度:
                {productTargetInfo?.["maskingHeight"]}公分
              </li>
            </ul>
          </div>
          <Summary
            report={report}
            productTargetInfo={productTargetInfo}
            furnitureLoss={furnitureLoss}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="section">綜合實體風險</Typography>
          <RiskRadarChart report={report} />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Typography className="section">選擇分析結果</Typography>
        </Grid>
      </Grid>

      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        // variant="fullWidth"
        scrollButtons="auto"
        variant="scrollable"
        sx={{
          "& .MuiTabs-flexContainer": {
            justifyContent: "center", // Center the tabs if using flex layout
          },
        }}
      >
        <Tab
          sx={(theme) => ({
            minWidth: 0,
            marginRight: theme.spacing(1),
            padding: theme.spacing(1),
            [theme.breakpoints.down("sm")]: {
              marginRight: theme.spacing(0.5), // Less space on small screens
            },
          })}
          label={<CustomTab label="淹水" icon={<FloodIcon />} />}
        />
        <Tab
          sx={(theme) => ({
            minWidth: 0,
            marginRight: theme.spacing(1),
            padding: theme.spacing(1),
            [theme.breakpoints.down("sm")]: {
              marginRight: theme.spacing(0.5), // Less space on small screens
            },
          })}
          label={<CustomTab label="地震" icon={<RiEarthquakeFill />} />}
        />
        <Tab
          sx={(theme) => ({
            minWidth: 0,
            marginRight: theme.spacing(1),
            padding: theme.spacing(1),
            [theme.breakpoints.down("sm")]: {
              marginRight: theme.spacing(0.5), // Less space on small screens
            },
          })}
          label={<CustomTab label="土壤液化" icon={<HouseboatIcon />} />}
        />
        <Tab
          sx={(theme) => ({
            minWidth: 0,
            marginRight: theme.spacing(1),
            padding: theme.spacing(1),
            [theme.breakpoints.down("sm")]: {
              marginRight: theme.spacing(0.5), // Less space on small screens
            },
          })}
          label={<CustomTab label="坡地災害" icon={<LandslideIcon />} />}
        />
        <Tab
          sx={(theme) => ({
            minWidth: 0,
            marginRight: theme.spacing(1),
            padding: theme.spacing(1),
            [theme.breakpoints.down("sm")]: {
              marginRight: theme.spacing(0.5), // Less space on small screens
            },
          })}
          label={<CustomTab label="海平面上升、海嘯" icon={<TsunamiIcon />} />}
        />
        {/* <Tab
              sx={(theme) => ({
                minWidth: 0,
                marginRight: theme.spacing(1),
                padding: theme.spacing(1),
                [theme.breakpoints.down("sm")]: {
                  marginRight: theme.spacing(0.5), // Less space on small screens
                },
              })}
              label={<CustomTab label="公開資料" icon={<PublicIcon />} />}
            /> */}
        <Tab
          sx={(theme) => ({
            minWidth: 0,
            marginRight: theme.spacing(1),
            padding: theme.spacing(1),
            [theme.breakpoints.down("sm")]: {
              marginRight: theme.spacing(0.5), // Less space on small screens
            },
          })}
          label={<CustomTab label="安家建議" icon={<SummarizeIcon />} />}
        />
      </Tabs>

      {/* Conditional rendering based on the active tab */}

      {activeTab === 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Typography className="section">
              高精度淹水分析(左側為公開資料，右側為本系統分析 )
            </Typography>
            <Compare report={report} />
            {/* <div className="detail-map">
                  <ReportMapSlider2 detailOrder={report.product} />
                </div> */}
            {/* <div className="prob-component-container">
                  <Prob2 report={report} />
                </div> */}
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography className="section">建物淹水風險說明:</Typography>
            <Typography
              sx={{ padding: "15px" }}
              variant="body1"
              component="div"
              gutterBottom
            >
              {/* 建物淹水風險以2年、5年、10年、25年50年100年200年之重現期降雨為模型輸入條件，依據高精度淹水模擬結果，計算建物範圍之淹水深度，並以統計模型計算個深度淹水機率 */}
              以過去雨量與氣候變遷AR6雨量進行統計分析，並以IS-LHS取樣法產生200組降雨量，進行淹水分析
            </Typography>
            <Typography
              sx={{ padding: "15px" }}
              variant="body1"
              component="div"
              gutterBottom
            >
              高精度淹水分析為6小時350mm降雨淹水潛勢圖，左為公開資料，右為本產品分析
            </Typography>
            <Typography
              sx={{ padding: "15px" }}
              variant="body1"
              component="div"
              gutterBottom
            >
              {report.summary.replace(/^'(.*)',\)$/, "$1")}
            </Typography>
            <Typography
              sx={{ padding: "15px" }}
              variant="body1"
              component="div"
              gutterBottom
            >
              依據本系統分析，本建物一樓地板比人行道高
              {productTargetInfo?.["maskingHeight"]}
              公分，本建物一樓每年
              <span style={{ fontSize: "16px", color: "red" }}>
                大於10公分的淹水機率
                {report.p0_200_interpolated["10cm"]}％
              </span>
              。{furnitureLoss}
              考量不同淹水深度與對應的發生機率，此建物的汽機車每年有
              <span style={{ fontSize: "16px", color: "red" }}>
                {report.risk_results["flood"][1]}
              </span>
              元的期望損失，
              建議可在汽機車出入口、較低的通風口、人員出入口在大豪雨期間加裝防水閘版，以及購買淹水保險。
              {/* 可能保險費用約落在
              <span style={{ fontSize: "16px", color: "red" }}>
                {(report.risk_results["flood"][1] * 1.2).toFixed(0)}～
                {(report.risk_results["flood"][1] * 1.5).toFixed(0)}
              </span>
              元/年為合理的保險費用。 */}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography className="section">建物淹水風險</Typography>
            <div>
              <FloodResult report={report} />
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <Typography className="section">其他說明</Typography>
            <div className="detail-description">
              <Typography
                variant="body1"
                component="div"
                gutterBottom
                style={{ textAlign: "left", width: "100%" }}
              >
                <h5>不確定性說明</h5>
                {report.other_info
                  .replace(/^\('(.*)',\)$/, "$1")
                  .replace(/(\\n|\n)/g, "")}
              </Typography>
              <Typography variant="body1" component="div" gutterBottom>
                {/* <h2 className="section-heading">模擬資料說明</h2> */}
                <h5>模擬資料說明</h5>
                {report.sim_info
                  .replace(/^\('(.*)',\)$/, "$1")
                  .replace(/(\\n|\n)/g, "")}
              </Typography>
              <Typography variant="body1" component="div" gutterBottom>
                {/* <h2 className="section-heading">模型參數說明</h2> */}
                <h5>模型參數說明</h5>
                {report.para_info
                  .replace(/^\('(.*)',\)$/, "$1")
                  .replace(/(\\n|\n)/g, "")}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography className="section">用途限制</Typography>
            <div className="detail-description">
              <Typography
                variant="body1"
                component="div"
                gutterBottom
                style={{ textAlign: "left", width: "100%" }}
              >
                {/* <h2 className="section-heading">
                    IP protection and disclaimer
                  </h2> */}
                僅供購買用戶之防救災風險評估使用、未經同意不得轉載、或商用用途。
              </Typography>
            </div>
          </Grid>
        </Grid>
      )}
      {activeTab === 1 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Typography className="section">建物強震風險圖:</Typography>

            <RiskMapClipEarthquake report={report} />
          </Grid>

          <Grid item xs={12} md={12}>
            <Typography className="section">建物強震風險</Typography>
            <EerthquakeResult report={report} />
          </Grid>
        </Grid>
      )}
      {activeTab === 2 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Typography className="section">建物土壤液化潛勢風險:</Typography>
            <LiqResult report={report} productTargetInfo={productTargetInfo} />

            <RiskMapClipLiq report={report} />
          </Grid>
        </Grid>
      )}
      {activeTab === 3 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Typography className="section">坡地災害風險:</Typography>
            <RiskMapClipLandSlide report={report} />
          </Grid>
        </Grid>
      )}
      {activeTab === 4 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Typography className="section">海平面上升、海嘯:</Typography>
            <SealevelResult report={report} />
            <RiskMapClipSeaLevel report={report} />
          </Grid>
        </Grid>
      )}
      {/* {activeTab === 5 && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Typography className="section">公開風險地圖</Typography>
                <RiskMapClip report={report} />
               
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography className="section">公開風險地圖說明</Typography>
                <Typography
                  sx={{ padding: "15px" }}
                  variant="body1"
                  component="div"
                  gutterBottom
                >
                  公開淹水圖採用350mm6hr極端降雨(約十年重現期)
                </Typography>
              </Grid>
            </Grid>
          )} */}
      {activeTab === 6 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Typography className="section">安家建議</Typography>
            <div className="detail-description">
              <Typography variant="body1" component="div" gutterBottom>
                <h2 className="section-heading">防災建議</h2>
                {/* <h2 className="section-heading">情境與結果說明</h2> */}
                {report.sugguestions.replace(/^\('(.*)',\)$/, "$1")}
              </Typography>
              <Typography variant="body1" component="div" gutterBottom>
                <h2 className="section-heading">行動方案</h2>
                {report.action_plans.replace(/^\('(.*)',\)$/, "$1")}
              </Typography>
            </div>
          </Grid>
          <hr />

          {/* <h5 className="primary_color pb-2 mb-3 mb-0 mt-5">
                淹水高度示意圖:
              </h5> */}
          <div className="img_floodHeight">
            <img
              className="floos_obj"
              src="/img/report_floodHeight_house.png"
              alt="Flood Height House"
            ></img>
            <div className="water_height" style={{ height: "4%", zIndex: "5" }}>
              <p className="water_height_text">10 cm</p>
            </div>
            <div className="water_height" style={{ height: "8%", zIndex: "4" }}>
              <p className="water_height_text">20 cm</p>
            </div>
            <div
              className="water_height"
              style={{ height: "12%", zIndex: "3" }}
            >
              {" "}
              <p className="water_height_text">30 cm</p>
            </div>
            <div
              className="water_height"
              style={{ height: "16%", zIndex: "2" }}
            >
              {" "}
              <p className="water_height_text">40 cm</p>
            </div>
            <div
              className="water_height"
              style={{ height: "20%", zIndex: "1" }}
            >
              {" "}
              <p className="water_height_text">50 cm</p>
            </div>
          </div>
          {/* <p className="small mb-0">
                比例尺:
                男性1.75m、女性1.6m、孩童1.3m、機車1.4m、汽車1.5m、房屋2.5m。
              </p> */}

          {/* <h2 className="section-heading">淹水深度的影響與行動方案參考:</h2> */}

          <div className="flood-level-explain">
            <h5 className="primary_color pb-2 mb-3 mb-0 mt-5">
              淹水深度的影響與行動方案參考:
            </h5>
            <ul style={{ listStyle: "square" }}>
              {floodLevels.map((floodLevel, index) => (
                <li key={index}>
                  {floodLevel.level}
                  <ul>
                    {floodLevel.impacts.map((impact, i) => (
                      <li key={i}>
                        {impact.category}: {impact.description}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
              <li>
                預防措施
                <ul>
                  {preventionMeasures.map((measure, index) => (
                    <li key={index}>{measure}</li>
                  ))}
                </ul>
              </li>
            </ul>
            <p>
              以上所提供的數據和價格只是大致的估計，具體情況會因地理位置、產品品牌、維修服務等多種因素變化，可能需要由專業人員進行評估。
            </p>
          </div>
        </Grid>
      )}
      {activeTab === 7 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography className="section">用途限制</Typography>
            <div className="detail-description">
              <Typography
                variant="body1"
                component="div"
                gutterBottom
                style={{ textAlign: "left", width: "100%" }}
              >
                {/* <h2 className="section-heading">
                    IP protection and disclaimer
                  </h2> */}
                僅供購買用戶之防救災風險評估使用、未經同意不得轉載、或商用用途。
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography className="section">其他說明</Typography>
            <div className="detail-description">
              <Typography
                variant="body1"
                component="div"
                gutterBottom
                style={{ textAlign: "left", width: "100%" }}
              >
                <h2 className="section-heading">不確定性說明</h2>
                {report.other_info
                  .replace(/^\('(.*)',\)$/, "$1")
                  .replace(/(\\n|\n)/g, "")}
              </Typography>
              <Typography variant="body1" component="div" gutterBottom>
                <h2 className="section-heading">模擬資料說明</h2>
                {report.sim_info
                  .replace(/^\('(.*)',\)$/, "$1")
                  .replace(/(\\n|\n)/g, "")}
              </Typography>
              <Typography variant="body1" component="div" gutterBottom>
                <h2 className="section-heading">模型參數說明</h2>
                {report.para_info
                  .replace(/^\('(.*)',\)$/, "$1")
                  .replace(/(\\n|\n)/g, "")}
              </Typography>
            </div>
          </Grid>
        </Grid>
      )}

      <hr />
      <hr />

      <hr />

      <hr />
      {/* </Paper> */}
    </div>
    // {/* </Container> */}
  );
};

export default PaidReport;
