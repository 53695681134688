import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./RiskMapClip.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as turf from "@turf/turf";
import axios from "axios";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const gs_username = process.env.REACT_APP_GEOSERVER_BASIC_USERNAME;
const gs_pass = process.env.REACT_APP_GEOSERVER_BASIC_PASSWORD;
mapboxgl.accessToken = mapboxtoken;

const RiskMapAll = ({ report }) => {
  const { user } = useSelector((state) => state.auth);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [activeLayer, setActiveLayer] = useState(null);
  const [layerOpacity, setLayerOpacity] = useState(0.7); // Default to fully opaque
  const polyGon = report.product.calculated_geometry;
  const [legendUrl, setLegendUrl] = useState("");

  // Function to fetch the legend image with authentication
  const fetchLegendImage = async (layerName) => {
    const url = `${geoserver_url}geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layerName}`;
    try {
      const response = await axios.get(url, {
        responseType: "blob", // Important: handle the response as a Blob
        auth: {
          username: gs_username,
          password: gs_pass,
        },
      });

      // Create a local URL for the image Blob
      const imageUrl = URL.createObjectURL(response.data);
      setLegendUrl(imageUrl); // Store the Blob URL in the state
    } catch (error) {
      console.error("Error fetching legend image:", error);
      // Handle error, e.g., show a notification
    }
  };

  useEffect(() => {
    // Call this function when you need to load a legend, e.g., when a layer is toggled
    if (activeLayer) {
      fetchLegendImage(activeLayer);
    }
  }, [activeLayer]);
  // Function to get the legend URL for a given layer
  const getLegendUrl = (layerName) => {
    return `${geoserver_url}geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layerName}`;
  };

  const layers = [
    {
      id: "opendata6h350mm40m3857",
      title: "淹水",
      wms:
        geoserver_url +
        `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:opendata6h350mm40m3857&FORMAT=image/png&TILED=true`,
      legend: getLegendUrl("staging:opendata6h350mm40m3857"),
    },
    {
      id: "tw_liq_all40m3857",
      title: "土壤液化",
      wms:
        geoserver_url +
        `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:tw_liq_all40m3857&FORMAT=image/png&TILED=true`,
      legend: getLegendUrl("staging:tw_liq_all40m3857"),
    },
    // {
    //   id: "earthquake",
    //   title: "地震",
    //   wms:
    //     geoserver_url +
    //     `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:earthquake_temp&FORMAT=image/png&TILED=true`,
    //   legend: getLegendUrl("staging:earthquake_temp"),
    // },
    {
      id: "tsunami_risk3857",
      title: "海嘯潛勢區",
      wms:
        geoserver_url +
        `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:tsunami_risk3857&FORMAT=image/png&TILED=true`,
      legend: getLegendUrl("staging:tsunami_risk3857"),
    },
    {
      id: "2010",
      title: "活動斷層",
      wms:
        geoserver_url +
        `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:2010&FORMAT=image/png&TILED=true`,
      legend: getLegendUrl("staging:2010"),
    },

    {
      id: "debris",
      title: "土石流區域",
      wms:
        geoserver_url +
        `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:debris&FORMAT=image/png&TILED=true`,
      legend: getLegendUrl("staging:debris"),
    },
    {
      id: "debrisstream",
      title: "土石流潛勢溪",
      wms:
        geoserver_url +
        `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:debrisstream&FORMAT=image/png&TILED=true`,
      legend: getLegendUrl("staging:debrisstream"),
    },
    {
      id: "largelandslide3857",
      title: "大規模崩塌區",
      wms:
        geoserver_url +
        `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:largelandslide3857&FORMAT=image/png&TILED=true`,
      legend: getLegendUrl("staging:largelandslide3857"),
    },
    // {
    //   id: "extreme-rain",
    //   title: "極端降雨",
    //   wms:
    //     geoserver_url +
    //     // `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:taoyuan_1_v1_350mm6hr-max-water-depth_merge&FORMAT=image/png&TILED=true`,
    //     `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:fm_base_350mm6hr&FORMAT=image/png&TILED=true`,
    // },

    // {
    //   id: "extreme-rain-road",
    //   title: "淹水道路",
    //   wms:
    //     geoserver_url +
    //     `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:ty1_350mm6hr_road0&FORMAT=image/png&TILED=true`,
    // },

    // {
    //   id: "sea-level-rise",
    //   title: "海平面上升",
    //   wms:
    //     geoserver_url +
    //     `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:seal_level_from10m_ty3_1m_f&FORMAT=image/png&TILED=true`,
    // },
  ];

  // console.log("polyGon", polyGon);

  const featureCollection = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: polyGon,
      },
    ],
  };

  const calculateBounds = (geometry) => {
    const bounds = new mapboxgl.LngLatBounds();
    geometry.coordinates[0].forEach((coord) => bounds.extend(coord));
    return bounds;
  };

  const changeOpacity = (newOpacity) => {
    setLayerOpacity(newOpacity);
    layers.forEach((layer) => {
      if (map.current.getLayer(layer.id)) {
        map.current.setPaintProperty(layer.id, "raster-opacity", newOpacity);
      }
    });
  };

  const switchBaseMap = (style) => {
    if (map.current) {
      map.current.setStyle(`mapbox://styles/mapbox/${style}`);

      // Once the style has loaded, re-add the WMS layers
      map.current.once("style.load", () => {
        addWMSLayers(); // Re-add the WMS layers
        addPolygonLayer(); // Re-add the polygon layer
      });
    }
  };

  const addWMSLayers = () => {
    layers.forEach((layer) => {
      // Only add the source if it doesn't already exist
      if (!map.current.getSource(layer.id)) {
        map.current.addSource(layer.id, {
          type: "raster",
          tiles: [layer.wms],
          tileSize: 256,
        });
      }

      // Only add the layer if it doesn't already exist
      if (!map.current.getLayer(layer.id)) {
        map.current.addLayer({
          id: layer.id,
          type: "raster",
          source: layer.id,
          paint: {
            "raster-opacity": layerOpacity, // use the current opacity
          },
          layout: {
            // Use the ternary operator to set visibility based on the active layer
            visibility: layer.id === activeLayer ? "visible" : "none",
          },
        });
      } else {
        // If the layer already exists, just update its visibility
        map.current.setLayoutProperty(
          layer.id,
          "visibility",
          layer.id === activeLayer ? "visible" : "none"
        );
      }
    });
  };

  const addPolygonLayer = () => {
    if (polyGon && polyGon.coordinates) {
      // Check if polygon source and layer already exist
      if (!map.current.getSource("polygon")) {
        map.current.addSource("polygon", {
          type: "geojson",
          data: featureCollection, // Use featureCollection here
        });
      }

      if (!map.current.getLayer("polygon")) {
        map.current.addLayer({
          id: "polygon",
          type: "line",
          source: "polygon",
          layout: {},
          paint: {
            "line-color": "#000", // black color
            "line-width": 2,
            "line-dasharray": [2, 2], // dotted line pattern
          },
        });
      }

      // Calculate and fit the map bounds to the polygon
      const bounds = calculateBounds(polyGon); // Use polyGon geometry for bounds
      map.current.fitBounds(bounds, { padding: 20 });
    }
  };

  useEffect(() => {
    // if (map.current) return; // Initialize map only once
    if (!map.current && mapContainer.current) {
      // for detailorder
      let center;
      try {
        center = turf.center(report.product.calculated_geometry).geometry
          .coordinates;
      } catch (error) {
        console.error("Error calculating center:", error);
        return;
      }

      if (!center || center.length !== 2 || center.some(isNaN)) {
        console.error("Invalid center coordinates:", center);
        return;
      }

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        // style: "mapbox://styles/wayne32545/cle6fqgc3000601t6g17e3arw",
        // center: [121.31552, 24.99337],
        center: center,
        zoom: 14,
        transformRequest: (url, resourceType) => {
          if (
            (resourceType === "Source" || resourceType === "Tile") &&
            url.indexOf(geoserver_url + `geoserver/staging/wms`) > -1
          ) {
            const username = gs_username;
            const password = gs_pass;
            const credentials = btoa(`${username}:${password}`);
            const headers = {
              Authorization: `Basic ${credentials}`,
            };

            return {
              url: url,
              headers: headers,
              credentials: "include", // Include cookies for cross-origin requests
            };
          }
        },
      });
      if (map.current) {
        map.current.on("load", () => {
          addWMSLayers(); // Add the WMS layers
          addPolygonLayer(); // Add the polygon layer
        });
      }
    }

    // Cleanup function to run when the component unmounts
    // return () => {
    //   if (map.current) {
    //     map.current.remove();
    //   }
    // };
  }, [layerOpacity, polyGon]);

  const toggleLayer = (layerId) => {
    setActiveLayer(layerId);
    layers.forEach((layer) => {
      map.current.setLayoutProperty(
        layer.id,
        "visibility",
        layer.id === layerId ? "visible" : "none"
      );
    });
    const layer = layers.find((l) => l.id === layerId);
    // if (layer) {
    //   setLegendUrl(layer.legend);
    // }
  };

  if (!user) {
    // If no user is logged in, display a login message
    return (
      <div className="login-message">
        <p>請先登入</p>
        <Link to="/login">登入</Link>{" "}
        {/* Replace "/login" with your login route */}
      </div>
    );
  }

  return (
    <div className="riskmap-container">
      <div
        className="map-container"
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: "60vh",
          borderRadius: "4px",
        }}
      >
        {/* Map container covering the full width and 60vh height */}
        <div
          ref={mapContainer}
          style={{
            width: "100%",
            height: "60vh",
            padding: "10px",
            borderRadius: "4px",
          }}
        />
        {/* <div className="map-container" ref={mapContainer} /> */}
        {/* Add legend display */}
        {/* <div
          className="legend-container"
          style={{
            position: "absolute",
            bottom: "20px",
            right: "10px",
            border: "2px solid",
            // padding: "2px",
            borderRadius: "4px",
          }}
        >
          {legendUrl && <img src={legendUrl} alt="Legend" />}
        </div> */}
        <div
          className="legend-container"
          style={{
            position: "absolute",
            bottom: "20px",
            right: "10px",
            border: "2px solid",
            borderRadius: "4px",
          }}
        >
          {legendUrl && <img src={legendUrl} alt="Legend" />}
        </div>
        {/* Base Map Switcher */}
        <div
          id="menu"
          style={{
            position: "absolute",
            top: 10, // Adjust as needed for top spacing
            left: "50%", // Center horizontally
            transform: "translateX(-50%)", // Adjust for exact centering
            background: "#efefef",
            padding: "5px",
            zIndex: 1, // Ensure it's above the map
            border: "solid 1px",
            borderRadius: "4px",
          }}
        >
          <input
            id="streets-v12"
            type="radio"
            name="rtoggle"
            value="streets-v12"
            onChange={() => switchBaseMap("streets-v12")}
            defaultChecked
          />
          <label
            htmlFor="streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            街道
          </label>
          <input
            id="satellite-streets-v12"
            type="radio"
            name="rtoggle"
            value="satellite-streets-v12"
            onChange={() => switchBaseMap("satellite-streets-v12")}
          />
          <label
            htmlFor="satellite-streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            衛星
          </label>
          <input
            id="light-v11"
            type="radio"
            name="rtoggle"
            value="light-v11"
            onChange={() => switchBaseMap("light-v11")}
          />
          <label
            htmlFor="light-v11"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            白底
          </label>
          <input
            id="dark-v11"
            type="radio"
            name="rtoggle"
            value="dark-v11"
            onChange={() => switchBaseMap("dark-v11")}
          />
          <label
            htmlFor="dark-v11"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            黑底
          </label>
        </div>
      </div>
      {/* <div className="map-container" ref={mapContainer} /> */}
      <div className="sidebar">
        <div>
          {/* <div style={{ display: "flex", flexDirection: "row", gap: "2px" }}> */}
          <label
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginBottom: "0px",
            }}
            htmlFor="opacity-slider"
          >
            透明度:
          </label>
          <input
            id="opacity-slider"
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={layerOpacity}
            style={{ marginTop: "1px" }}
            onChange={(e) => changeOpacity(parseFloat(e.target.value))}
          />
        </div>
        {layers.map((layer) => (
          <button
            key={layer.id}
            className={`sidebar-button ${
              activeLayer === layer.id ? "active" : ""
            }`}
            onClick={() => toggleLayer(layer.id)}
          >
            {layer.title}
          </button>
        ))}
      </div>
    </div>
  );
};

export default RiskMapAll;
