import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./RiskMapClipEarthquake.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as turf from "@turf/turf";
import axios from "axios";
import axiosInstance from "../../axios";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { buffer } from "@turf/turf";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
// const gs_username = process.env.REACT_APP_GEOSERVER_BASIC_USERNAME;
// const gs_pass = process.env.REACT_APP_GEOSERVER_BASIC_PASSWORD;
mapboxgl.accessToken = mapboxtoken;
const baseURL = process.env.REACT_APP_API_URL;

const RiskMapClipEarthquake = ({ report }) => {
  const earthquake_loss = report.risk_results["earthquake"][0];
  const earthquake_level = report.risk_results["earthquake"][1];
  const pga_all = report.risk_results["earthquake"][2];
  const cwb_level = report.risk_results["earthquake"][3];
  const productTargetInfo = JSON.parse(report.product.target_info);

  const layers = [
    {
      id: "2010",
      title: "活動斷層",
      wms:
        // geoserver_url +
        `${baseURL}risk/geo/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:2010&FORMAT=image/png&TILED=true`,
    },
    {
      id: "2_Probin50yrs_clip",
      title: "地震危害度1",
      wms:
        // geoserver_url +
        `${baseURL}risk/geo/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:2_Probin50yrs_clip&FORMAT=image/png&TILED=true`,
    },
    {
      id: "10_Probin50yrs_clip",
      title: "地震危害度2",
      wms:
        // geoserver_url +
        `${baseURL}risk/geo/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:10_Probin50yrs_clip&FORMAT=image/png&TILED=true`,
    },
    {
      id: "fault200",
      title: "地質敏感區",
      wms:
        // geoserver_url +
        `${baseURL}risk/geo/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:fault200&FORMAT=image/png&TILED=true`,
    },
    {
      id: "fault50",
      title: "限禁建範圍",
      wms:
        // geoserver_url +
        `${baseURL}risk/geo/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:fault50&FORMAT=image/png&TILED=true`,
    },
    {
      id: "faultgroup",
      title: "faultgroup",
      wms:
        // geoserver_url +
        `${baseURL}risk/geo/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:faultgroup&FORMAT=image/png&TILED=true`,
    },
  ];
  const { user } = useSelector((state) => state.auth);
  const mapContainer = useRef(null);
  // Create three refs for the three map containers
  const mapContainer1 = useRef(null);
  const mapContainer2 = useRef(null);
  const mapContainer3 = useRef(null);

  const reportFaultData = report?.fault_nearest_lines;
  const nearestLines = [report?.fault_nearest_lines?.nearest_line?.[0]];
  // const nearestLines = report?.fault_nearest_lines?.nearest_line;
  const [result, setResult] = useState(nearestLines);

  const [isSensitive, setIsSensitive] = useState("否");

  // const [result, setResult] = useState({});
  const map = useRef(null);
  const map1 = useRef(null);
  const map2 = useRef(null);
  const map3 = useRef(null);
  // const [activeLayer, setActiveLayer] = useState("2010");
  const [activeLayer, setActiveLayer] = useState("2010_3857");
  const [layerOpacity, setLayerOpacity] = useState(1); // Default to fully opaque
  const polyGon = report.product.calculated_geometry;
  const [legendUrl, setLegendUrl] = useState("");
  const [legendUrls, setLegendUrls] = useState({});
  const [isLoading, setIsLoading] = useState(false); // New state for loading status

  const polygonData = useSelector((state) => state.polygonData.polygonData);

  // console.log("legendUrls", legendUrls);
  // const nearestLines = report?.fault_nearest_lines?.nearest_line;
  // console.log("nearestLines", nearestLines);
  // console.log("reportFaultData", reportFaultData);

  useEffect(() => {
    if (nearestLines && nearestLines.length > 0) {
      const distance = nearestLines[0]?.properties?.distance / 1000;
      setIsSensitive(distance < 2 ? "是" : "否");
    } else {
      setIsSensitive("否");
    }
  }, [nearestLines]);

  const addNearestLineLayer = (map) => {
    setIsLoading(true);
    // Ensure the map is loaded before adding sources and layers
    if (map.current) {
      // if (map.current.isStyleLoaded()) {
      // Remove existing nearest line layers and sources
      nearestLines.forEach((_, index) => {
        const lineId = `nearest-line-${index}`;
        if (map.current.getLayer(lineId)) {
          map.current.removeLayer(lineId);
        }
        if (map.current.getSource(lineId)) {
          map.current.removeSource(lineId);
        }
      });

      // Add new nearest line layers
      nearestLines.forEach((lineGeoJSON, index) => {
        const lineId = `nearest-line-${index}`;

        map.current.addSource(lineId, {
          type: "geojson",
          data: lineGeoJSON,
        });

        map.current.addLayer({
          id: lineId,
          type: "line",
          source: lineId,
          layout: {},
          paint: {
            "line-color": "#1e453e", // Adjust the color as needed
            "line-width": 3,
            "line-dasharray": [3, 3], // Adjust the style as needed
          },
        });

        // Calculate the midpoint of the line
        const start = turf.point(lineGeoJSON.geometry.coordinates[0]);
        const end = turf.point(
          lineGeoJSON.geometry.coordinates[
            lineGeoJSON.geometry.coordinates.length - 1
          ]
        );
        const midpoint = turf.midpoint(start, end);

        // Create a popup at the midpoint with the distance
        const popup = new mapboxgl.Popup({
          offset: 25,
          closeButton: false,
          closeOnClick: false,
          anchor: "bottom",
        })
          .setLngLat(midpoint.geometry.coordinates)
          .setHTML(
            `<strong>${lineGeoJSON.properties.fault_name}: ${(
              lineGeoJSON.properties.distance / 1000
            ).toFixed(2)} 公里</strong>`
          )
          .addTo(map.current);
      });

      // Fit the map to the bounds of all lines
      const bounds = new mapboxgl.LngLatBounds();
      nearestLines.forEach((lineGeoJSON) => {
        lineGeoJSON.geometry.coordinates.forEach((coord) => {
          bounds.extend(coord);
        });
      });
      // map.current.fitBounds(bounds, { padding: 20 });
      map.current.fitBounds(bounds, { padding: 40 });
      setIsLoading(false);
    } else {
      map.current.on("load", () => addNearestLineLayer(nearestLines));
    }
  };

  // Adjusted function to fetch and set legend images for specific layers
  const fetchLegendImage = async (layerId) => {
    const url = `${baseURL}risk/geo/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layerId}`;
    // const url = `${geoserver_url}geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layerId}`;
    try {
      const response = await axios.get(url, {
        responseType: "blob", // Important: handle the response as a Blob
        // auth: {
        //   username: gs_username,
        //   password: gs_pass,
        // },
      });
      // Create a local URL for the image Blob
      const imageUrl = URL.createObjectURL(response.data);

      // Update the state with the new legend URL for the layer
      setLegendUrls((prev) => ({ ...prev, [layerId]: imageUrl }));
    } catch (error) {
      console.error(
        "Error fetching legend image for layer",
        layerId,
        ":",
        error
      );
    }
  };

  // Call fetchLegendImage for each layer when the component mounts or when layers change
  useEffect(() => {
    layers.forEach((layer) => {
      fetchLegendImage(layer.id);
    });
  }, []);

  const featureCollection = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: polyGon,
      },
    ],
  };

  const calculateBounds = (geometry) => {
    const bounds = new mapboxgl.LngLatBounds();
    geometry.coordinates[0].forEach((coord) => bounds.extend(coord));
    return bounds;
  };

  const changeOpacity = (newOpacity) => {
    setLayerOpacity(newOpacity);
    layers.forEach((layer) => {
      if (map.current.getLayer(layer.id)) {
        map.current.setPaintProperty(layer.id, "raster-opacity", newOpacity);
      }
    });
  };

  const addWMSLayers_sinle = (map, layer) => {
    // Only add the source if it doesn't already exist
    if (!map.current.getSource(layer.id)) {
      map.current.addSource(layer.id, {
        type: "raster",
        tiles: [layer.wms],
        tileSize: 256,
      });
    }

    // Only add the layer if it doesn't already exist
    if (!map.current.getLayer(layer.id)) {
      map.current.addLayer({
        id: layer.id,
        type: "raster",
        source: layer.id,
        paint: {
          "raster-opacity": 0.8, // use the current opacity
        },
      });
    }
  };

  const addWMSLayers = () => {
    layers.forEach((layer) => {
      // Only add the source if it doesn't already exist
      if (!map.current.getSource(layer.id)) {
        map.current.addSource(layer.id, {
          type: "raster",
          tiles: [layer.wms],
          tileSize: 256,
        });
      }

      // Only add the layer if it doesn't already exist
      if (!map.current.getLayer(layer.id)) {
        map.current.addLayer({
          id: layer.id,
          type: "raster",
          source: layer.id,
          paint: {
            "raster-opacity": layerOpacity, // use the current opacity
          },
          layout: {
            // Use the ternary operator to set visibility based on the active layer
            visibility: layer.id === activeLayer ? "visible" : "none",
          },
        });
      } else {
        // If the layer already exists, just update its visibility
        map.current.setLayoutProperty(
          layer.id,
          "visibility",
          layer.id === activeLayer ? "visible" : "none"
        );
      }
    });
  };

  const addPolygonLayer = (map) => {
    if (polyGon && polyGon.coordinates) {
      // Check if polygon source and layer already exist
      if (!map.current.getSource("polygon")) {
        map.current.addSource("polygon", {
          type: "geojson",
          data: featureCollection, // Use featureCollection here
        });
      }

      if (!map.current.getLayer("polygon")) {
        map.current.addLayer({
          id: "polygon",
          type: "line",
          source: "polygon",
          layout: {},
          paint: {
            "line-color": "#000", // black color
            "line-width": 2,
            "line-dasharray": [2, 2], // dotted line pattern
          },
        });
      }
    }
  };

  // useEffect(() => {
  //   // if (map.current) return; // Initialize map only once
  //   if (!map1.current && mapContainer1.current) {
  //     // for detailorder
  //     let center;
  //     try {
  //       center = turf.center(report.product.calculated_geometry).geometry
  //         .coordinates;
  //     } catch (error) {
  //       console.error("Error calculating center:", error);
  //       return;
  //     }

  //     if (!center || center.length !== 2 || center.some(isNaN)) {
  //       console.error("Invalid center coordinates:", center);
  //       return;
  //     }

  //     map1.current = new mapboxgl.Map({
  //       container: mapContainer1.current,
  //       style: "mapbox://styles/mapbox/light-v11",
  //       // style: "mapbox://styles/mapbox/streets-v11",
  //       // style: "mapbox://styles/wayne32545/cle6fqgc3000601t6g17e3arw",
  //       center: center,
  //       // center: [121.31552, 24.99337],
  //       zoom: 11,
  //       // maxZoom: 11,
  //       minZoom: 7,
  //       dragPan: false,
  //       // transformRequest: (url, resourceType) => {
  //       //   if (
  //       //     (resourceType === "Source" || resourceType === "Tile") &&
  //       //     url.indexOf(geoserver_url + `geoserver/staging/wms`) > -1
  //       //   ) {
  //       //     const username = gs_username;
  //       //     const password = gs_pass;
  //       //     const credentials = btoa(`${username}:${password}`);
  //       //     const headers = {
  //       //       Authorization: `Basic ${credentials}`,
  //       //     };

  //       //     return {
  //       //       url: url,
  //       //       headers: headers,
  //       //       credentials: "include", // Include cookies for cross-origin requests
  //       //     };
  //       //   }
  //       // },
  //     });

  //     if (map1.current) {
  //       map1.current.on("load", () => {
  //         // addWMSLayers_sinle(map1, layers[3]); // Add the WMS layers
  //         // addWMSLayers_sinle(map1, layers[4]); // Add the WMS layers
  //         // addWMSLayers_sinle(map1, layers[0]); // Add the WMS layers
  //         addWMSLayers_sinle(map1, layers[5]); // Add the WMS layers
  //         addPolygonLayer(map1); // Add the polygon layer
  //         //
  //         addNearestLineLayer(map1);
  //         // test
  //         // fetchFault(map1);
  //       });

  //       const centerCoordinates = turf.center(polyGon).geometry.coordinates;

  //       const marker = new mapboxgl.Marker()
  //         .setLngLat(centerCoordinates)
  //         .addTo(map1.current);
  //     }
  //   }

  //   // Cleanup function to run when the component unmounts
  //   // return () => {
  //   //   if (map.current) {
  //   //     map.current.remove();
  //   //   }
  //   // };
  // }, [layerOpacity, polyGon]);

  useEffect(() => {
    // if (map.current) return; // Initialize map only once
    if (!map2.current && mapContainer2.current) {
      // for detailorder
      let center;
      try {
        center = turf.center(report.product.calculated_geometry).geometry
          .coordinates;
      } catch (error) {
        console.error("Error calculating center:", error);
        return;
      }

      if (!center || center.length !== 2 || center.some(isNaN)) {
        console.error("Invalid center coordinates:", center);
        return;
      }

      map2.current = new mapboxgl.Map({
        container: mapContainer2.current,
        style: "mapbox://styles/mapbox/light-v11",

        center: center,

        zoom: 11,
        // maxZoom: 11,
        minZoom: 7,
        dragPan: false,
        // transformRequest: (url, resourceType) => {
        //   if (
        //     (resourceType === "Source" || resourceType === "Tile") &&
        //     url.indexOf(geoserver_url + `geoserver/staging/wms`) > -1
        //   ) {
        //     const username = gs_username;
        //     const password = gs_pass;
        //     const credentials = btoa(`${username}:${password}`);
        //     const headers = {
        //       Authorization: `Basic ${credentials}`,
        //     };

        //     return {
        //       url: url,
        //       headers: headers,
        //       credentials: "include", // Include cookies for cross-origin requests
        //     };
        //   }
        // },
      });

      if (map2.current) {
        map2.current.on("load", () => {
          addWMSLayers_sinle(map2, layers[1]); // Add the WMS layers
          addPolygonLayer(map2); // Add the polygon layer

          // Define the popup content
          // const popupContent2 = `未來50年內，有2％機率發生震度${pga_all[0]}的地震（相當於中央氣象署${cwb_level[0]}震度）`;

          // // Create and attach the popup to map2
          // new mapboxgl.Popup({
          //   className: "earthquake-popup", // Assign your custom class
          //   offset: 50, // Example: Displaces the popup 25 pixels downwards. Adjust as needed
          // })
          //   .setLngLat(center) // Using the center of your feature as an example position
          //   .setHTML(popupContent2)
          //   .addTo(map2.current);

          //
          // addNearestLineLayer(map2);
        });

        const centerCoordinates = turf.center(polyGon).geometry.coordinates;

        const marker = new mapboxgl.Marker()
          .setLngLat(centerCoordinates)
          .addTo(map2.current);
      }

      // fetchLegendImage(layers[1].layerName);
    }

    // Cleanup function to run when the component unmounts
    // return () => {
    //   if (map.current) {
    //     map.current.remove();
    //   }
    // };
  }, [layerOpacity, polyGon]);

  useEffect(() => {
    // if (map.current) return; // Initialize map only once
    if (!map3.current && mapContainer3.current) {
      // for detailorder
      let center;
      try {
        center = turf.center(report.product.calculated_geometry).geometry
          .coordinates;
      } catch (error) {
        console.error("Error calculating center:", error);
        return;
      }

      if (!center || center.length !== 2 || center.some(isNaN)) {
        console.error("Invalid center coordinates:", center);
        return;
      }

      map3.current = new mapboxgl.Map({
        container: mapContainer3.current,
        style: "mapbox://styles/mapbox/light-v11",
        center: center,
        zoom: 11,
        // maxZoom: 11,
        minZoom: 7,
        dragPan: false,
        // transformRequest: (url, resourceType) => {
        //   if (
        //     (resourceType === "Source" || resourceType === "Tile") &&
        //     url.indexOf(geoserver_url + `geoserver/staging/wms`) > -1
        //   ) {
        //     const username = gs_username;
        //     const password = gs_pass;
        //     const credentials = btoa(`${username}:${password}`);
        //     const headers = {
        //       Authorization: `Basic ${credentials}`,
        //     };

        //     return {
        //       url: url,
        //       headers: headers,
        //       credentials: "include", // Include cookies for cross-origin requests
        //     };
        //   }
        // },
      });

      if (map3.current) {
        map3.current.on("load", () => {
          // addWMSLayers(); // Add the WMS layers
          addWMSLayers_sinle(map3, layers[2]); // Add the WMS layers
          addPolygonLayer(map3); // Add the polygon layer
          // addNearestLineLayer(map3);
        });

        const centerCoordinates = turf.center(polyGon).geometry.coordinates;

        const marker = new mapboxgl.Marker()
          .setLngLat(centerCoordinates)
          .addTo(map3.current);
      }
    }

    // Cleanup function to run when the component unmounts
    // return () => {
    //   if (map.current) {
    //     map.current.remove();
    //   }
    // };
  }, [layerOpacity, polyGon]);

  // if (!user) {
  //   // If no user is logged in, display a login message
  //   return (
  //     <div className="login-message">
  //       <p>請先登入</p>
  //       <Link to="/login">登入</Link>{" "}
  //       {/* Replace "/login" with your login route */}
  //     </div>
  //   );
  // }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // position: "relative",
        // padding: "0px 20px",
      }}
    >
      <div>
        <ul style={{ fontSize: "14px", fontWeight: "bold", listStyle: "none" }}>
          {/* <li>您的建物距離活動斷層如下：</li>
          <li>
            是否位於地質敏感區:
            <span style={{ color: "red" }}>{isSensitive}</span>
          </li> */}
          <li>
            您的建物依據防震設計規範至少為
            <span style={{ color: "red" }}>
              {productTargetInfo?.["designPga"]}g
            </span>
            的防震，亦即小於此震度不會發生全倒，但小於此震度仍會受到大小不等的損傷。
          </li>
          <li>
            建物強震風險以建物設計之地表加速度(PGA)計算可能發生強震破壞的風險
          </li>
          {/* <li>離最近斷層位置為:</li>
          <li>
            {result?.[0]?.properties?.fault_name}:{" "}
            {(result?.[0]?.properties?.distance / 1000).toFixed(2)} 公里
          </li>
          <li>
            說明:
            “震度0.44g”是指地震時地面加速度的強度，g是指重力加速度的單位。換句話說，這個數字用來表示地震造成的地面搖晃有多劇烈，而0.44g表示的是一個相當強烈的搖晃程度。
          </li> */}
          {/* <li>
            2.{result?.[1]?.properties?.fault_name}:{" "}
            {(result?.[1]?.properties?.distance / 1000).toFixed(2)} 公里
          </li>
          <li>
            3.{result?.[2]?.properties?.fault_name}:{" "}
            {(result?.[2]?.properties?.distance / 1000).toFixed(2)} 公里
          </li> */}
        </ul>
      </div>
      <div
        className="earthquake-maps-container"
        // style={{ flexDirection: "row" }}
      >
        {isLoading && (
          <div
            className="loading-indicator"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)", // Center the div
              zIndex: 3, // Ensure it's above the map
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(255, 255, 255, 0.8)", // Optional: Add a semi-transparent background
              padding: "20px",
              borderRadius: "10px",
              border: "2px solid #ccc", // Optional: Style as needed
              fontWeight: "bold",
            }}
          >
            分析中, 請稍後...
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
            {/* Here you can also add a spinner or any other visual indicator */}
          </div>
        )}
        {/* <div className="earthquake-map" style={{ position: "relative" }}>
          <div ref={mapContainer1} className="earthquake-map" />
          <div
            className="legend-container"
            style={{
              position: "absolute",
              bottom: "10px",
              right: "10px",
              border: "2px solid",
              zIndex: 3,
              borderRadius: "5px",
            }}
          >
            {legendUrls["2010"] && (
              // <img src={legendUrls["2010"]} alt="Legend" />
              <img src={legendUrls["faultgroup"]} alt="Legend" />
            )}
          </div>
        </div> */}
        <div className="earthquake-map" style={{ position: "relative" }}>
          <div ref={mapContainer2} className="earthquake-map" />
          <div
            style={{
              position: "absolute",
              top: "10px",
              left: "50%", // Moves the left edge of the div to the center of the container
              transform: "translateX(-50%)", // Shifts the div back to the left by half its width
              zIndex: 3,
              fontWeight: "bold",
            }}
          >
            未來50年內，有10％機率發生震度{pga_all[0]}g的地震(相當於中央氣象署
            {cwb_level[0]}震度)
          </div>
          <div
            className="legend-container"
            style={{
              position: "absolute",
              bottom: "10px",
              right: "10px",
              border: "2px solid",
              zIndex: 3,
              borderRadius: "5px",
            }}
          >
            <div style={{ width: "60px" }}>
              <img
                src={legendUrls["2_Probin50yrs_clip"]}
                alt="Legend"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            {/* {legendUrls["2_Probin50yrs_clip"] && (
              <img src={legendUrls["2_Probin50yrs_clip"]} alt="Legend" />
            )} */}
          </div>
        </div>
        <div className="earthquake-map" style={{ position: "relative" }}>
          <div ref={mapContainer3} className="earthquake-map" />
          <div
            style={{
              position: "absolute",
              top: "10px",
              left: "50%", // Moves the left edge of the div to the center of the container
              transform: "translateX(-50%)", // Shifts the div back to the left by half its width
              zIndex: 3,
              fontWeight: "bold",
            }}
          >
            未來50年內，有2％機率發生震度{pga_all[1]}g的地震(相當於中央氣象署
            {cwb_level[1]}震度)
          </div>
          <div
            className="legend-container"
            style={{
              position: "absolute",
              bottom: "10px",
              right: "10px",
              border: "2px solid",
              zIndex: 3,
              borderRadius: "5px",
            }}
          >
            <div style={{ width: "60px" }}>
              <img
                src={legendUrls["10_Probin50yrs_clip"]}
                alt="Legend"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            {/* {legendUrls["10_Probin50yrs_clip"] && (
              <img src={legendUrls["10_Probin50yrs_clip"]} alt="Legend" />
            )} */}
          </div>
        </div>
        {/* <div>上圖依序為:</div> */}
      </div>
    </div>
  );
};

export default RiskMapClipEarthquake;
