import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close"; // Import the CloseIcon
import "./Navbar22.scss";
import AccountMenu from "./AccountMenu";

const NavbarAppbar = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const MenuItems = [
    { id: 1, Name: "會員點數", Link: "/BuyPoint" },
    { id: 3, Name: "商品介紹", Link: "/Product_intro" },
    { id: 5, Name: "技術理論", Link: "/Tech" },
    { id: 6, Name: "我的報告", Link: "/dashboard" },
  ];

  return (
    <nav className="navbar">
      <div className="navbar__container">
        <div className="navbar__logo">
          <Link to="/">
            {/* <img src="/img/logo-right.svg" alt="Logo" /> */}
            <img src="/img/logo-white.svg" alt="Logo" />
          </Link>
        </div>

        <ul className={`navbar__menu ${openMenu ? "active" : ""}`}>
          {MenuItems.map((item) => (
            <li key={item.id} onClick={() => setOpenMenu(false)}>
              <Link to={item.Link}>{item.Name}</Link>
            </li>
          ))}
          {openMenu && (
            <li className="navbar__account-mobile">
              <AccountMenu />
            </li>
          )}
        </ul>

        <div className="navbar__account desktop">
          <AccountMenu />
        </div>

        <div className="navbar__toggle" onClick={() => setOpenMenu(!openMenu)}>
          {openMenu ? <CloseIcon /> : <MenuIcon />} {/* Toggle between MenuIcon and CloseIcon */}
        </div>
      </div>
    </nav>
  );
};

export default NavbarAppbar;
