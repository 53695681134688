import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./RiskMapAll.css";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = mapboxtoken;

const RiskHouse = () => {
  const mapContainer = useRef(null);
  const mapRef = useRef(null); // New reference for the map instance
  const [isLoading, setIsLoading] = useState(false);
  const [clickedBuildingIds, setClickedBuildingIds] = useState(new Set());
  // const [selectedBuilding, setSelectedBuilding] = useState(null); // New state for storing selected building details
  const [selectedBuildings, setSelectedBuildings] = useState([]); // Array to hold selected building details
  const [buildingInfo, setBuildingInfo] = useState([]);

  const generateReport = () => {
    // axios.post('YOUR_BACKEND_API_ENDPOINT', buildingInfo)
    //   .then(response => {
    //     // Handle the response from the server
    //     console.log('Report generated successfully:', response.data);
    //     // Implement any further actions like showing success message or redirection
    //   })
    //   .catch(error => {
    //     // Handle any errors here
    //     console.error('Error generating report:', error);
    //     // Implement error handling logic, such as showing an error message
    //   });
    console.log(buildingInfo);
  };

  useEffect(() => {
    // Only initialize the map if it doesn't already exist
    if (mapRef.current) return; // This prevents multiple initializations

    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      // center: [121.54837778, 25.01686925],
      center: [121.31552, 24.99337],
      zoom: 15,
      maxZoom: 18,
      minZoom: 14,
    });

    mapRef.current.on("load", () => {
      mapRef.current.addSource("buildings", {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
      });
      mapRef.current.addLayer({
        id: "3d-buildings",
        source: "buildings",
        type: "fill-extrusion",
        paint: {
          "fill-extrusion-color": [
            "case",
            ["boolean", ["feature-state", "clicked"], false],
            "#ff0000",
            "#aaa",
          ],
          "fill-extrusion-height": ["get", "build_h"],
          "fill-extrusion-base": 0,
          "fill-extrusion-opacity": 0.9,
        },
      });

      fetchBuildingsInView(mapRef.current);
    });

    mapRef.current.on("moveend", () => fetchBuildingsInView(mapRef.current));
    mapRef.current.on("click", "3d-buildings", (e) =>
      handleBuildingClick(e, mapRef.current)
    );

    return () => {
      if (mapRef.current) {
        // Remove event listeners first
        mapRef.current.off("load");
        mapRef.current.off("moveend");
        mapRef.current.off("click", "3d-buildings");

        // Then remove the map
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  const fetchBuildingsInView = (map) => {
    const bounds = map.getBounds();
    setIsLoading(true);

    axios
      .get(`${geoserver_url}geoserver/staging/ows`, {
        params: {
          service: "WFS",
          version: "1.0.0",
          request: "GetFeature",
          typeName: "staging:buildings_taoyuan3857",
          // typeName: "staging:building_all",
          // typeName: "staging:buildings_taipei3857",
          bbox: `${bounds.getWest()},${bounds.getSouth()},${bounds.getEast()},${bounds.getNorth()}`,
          outputFormat: "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        // Assign uid as id for each feature
        data.features.forEach((feature) => {
          feature.id = feature.properties.uid;
        });
        map.getSource("buildings").setData(data);

        // Reapply 'clicked' state to buildings
        clickedBuildingIds.forEach((id) => {
          map.setFeatureState({ source: "buildings", id }, { clicked: true });
        });

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the GeoServer data:", error);
        setIsLoading(false);
      });
  };

  const handleBuildingClick = (e, map) => {
    if (e.features.length > 0) {
      const feature = e.features[0];
      const featureUid = feature.properties.uid;

      // Check the current state of the clicked feature
      const currentState =
        map.getFeatureState({ source: "buildings", id: featureUid }).clicked ||
        false;
      map.setFeatureState(
        { source: "buildings", id: featureUid },
        { clicked: !currentState }
      );

      // Update the selected buildings list
      if (!currentState) {
        // If the building was not previously selected, add it to the list
        setSelectedBuildings((prev) => [
          ...prev,
          {
            uid: featureUid,
            height: feature.properties.build_h,
            geometry: feature.geometry,
          },
        ]);
        setBuildingInfo((prev) => [
          ...prev,
          {
            uid: featureUid,
            // region: feature.properties.region, // Assuming 'region' is a property
            region: "test",
            geometry: JSON.stringify(feature.geometry), // Convert geometry object to string
            door_height: feature.properties.build_h, // Assuming 'door_height' is a property
          },
        ]);
      } else {
        // If the building was previously selected, remove it from the list
        setSelectedBuildings((prev) =>
          prev.filter((building) => building.uid !== featureUid)
        );
        setBuildingInfo((prev) =>
          prev.filter((building) => building.uid !== featureUid)
        );
      }

      // Update the clicked building IDs
      setClickedBuildingIds((prev) => {
        const newClickedIds = new Set(prev);
        if (!currentState) {
          newClickedIds.add(featureUid);
        } else {
          newClickedIds.delete(featureUid);
        }
        return newClickedIds;
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        padding: "10px",
      }}
    >
      {/* Map container covering the full width and 60vh height */}
      <div
        ref={mapContainer}
        style={{ width: "100%", height: "60vh", padding: "10px" }}
      />

      {/* Second row split into 2:1 ratio */}
      <div
        style={{
          padding: "10px",
          display: "flex",
          flex: 1,
          maxHeight: "40vh",
          gap: "10px",
        }}
      >
        {/* Left part of the second row with a flex ratio of 2 */}
        <div
          style={{
            backgroundColor: "white", // Corrected the property name
            fontSize: "small",
            flex: 2, // 2 parts of the second row
            overflowY: "auto", // If you want a scrollbar
          }}
        >
          <h3>Building Details</h3>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Height</th>
              </tr>
            </thead>
            <tbody>
              {selectedBuildings.map((building, index) => (
                <tr key={building.uid}>
                  <td>{building.uid}</td>
                  <td>{building.height}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {selectedBuildings.length > 0 && (
            <div>
              <h4>Geometry of Last Selected Building:</h4>
              <div>
                {JSON.stringify(
                  selectedBuildings[selectedBuildings.length - 1].geometry
                )}
              </div>
            </div>
          )}
        </div>

        {/* Right part of the second row with a flex ratio of 1 */}
        <div style={{ flex: 1 }}>
          <h2>other info</h2>
          <button onClick={generateReport}>Generate Report</button>
          {/* <div>{buildingInfo}</div> */}
        </div>
      </div>
    </div>
  );
};

export default RiskHouse;
