import React, { useState } from "react";
import "./Pagination.scss";

const Pagination = ({ productsPerPage, totalProducts, paginate }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pagesToShow = 5; // Number of pages to show

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalProducts / productsPerPage); i++) {
    pageNumbers.push(i);
  }

  // Ensure current page is always in the middle of the pagination
  let middle = Math.floor(pagesToShow / 2);
  let start = Math.max(currentPage - middle, 1);
  let end = Math.min(start + pagesToShow - 1, pageNumbers.length);

  // Adjust the start and end if necessary
  if (end - start + 1 < pagesToShow) {
    start = Math.max(end - pagesToShow + 1, 1);
  }

  const currentButtons = pageNumbers.slice(start - 1, end);

  const handleClick = (number) => {
    paginate(number);
    setCurrentPage(number);
  };

  return (
    <nav>
      <ul className="pagination">
        <li className="page-item">
          {/* <button
            className="page-link"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          >
            上一頁
          </button> */}
          <button
            className="page-link"
            onClick={() => {
              const newPage = Math.max(currentPage - 1, 1);
              paginate(newPage); // Call paginate when previous page is clicked
              setCurrentPage(newPage);
            }}
          >
            上一頁
          </button>
        </li>
        {currentButtons.map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            <button className="page-link" onClick={() => handleClick(number)}>
              {number}
            </button>
          </li>
        ))}
        <li className="page-item">
          <button
            className="page-link"
            onClick={() => {
              const newPage = Math.min(currentPage + 1, pageNumbers.length);
              paginate(newPage); // Call paginate when next page is clicked
              setCurrentPage(newPage);
            }}
          >
            下一頁
          </button>
          {/* <button
            className="page-link"
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, pageNumbers.length))
            }
          >
            下一頁
          </button> */}
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
