import React, { useEffect } from "react";
// import { Button, Col, Container, Row } from "react-bootstrap";
// import { FaCheckCircle } from "react-icons/fa";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import Title from "../../components/Title/Title";
import { toast } from "react-toastify";
import { activate, reset } from "../../features/auth/authSlice";
import "./Activate.scss";

const ActivatePage = () => {
	const { uid, token } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { isLoading, isError, isSuccess, message } = useSelector(
		(state) => state.auth
	);

	useEffect(() => {
		if (isError) {
			toast.error(message);
		}

		if (isSuccess) {
			navigate("/");
		}

		dispatch(reset());
	}, [isError, isSuccess, message, navigate, dispatch]);

	const submitHandler = () => {
		const userData = {
			uid,
			token,
		};

		dispatch(activate(userData));
		toast.success("您的帳戶已成功被啟用，您現在可以登入了!");
		// toast.success("Your account has been activated! You can login now");
	};

	return (
		<div className="activate">
			<Title title="啟用帳戶" />
			<Container className="mg-top text-center">
				<Grid>
					<Grid className="mg-top text-center">
						<section>
							<h1>
								<CheckCircleIcon /> 啟用您的帳戶
							</h1>
							<hr className="hr-text" />
						</section>
					</Grid>
				</Grid>
				{isLoading && <Spinner />}
				<Grid className="mt-3">
					<Grid className="text-center">
						<Button
							type="submit"
							variant="outlined"
							size="medium"
							// className="mt-3 large-btn"
							onClick={submitHandler}
						>
							啟用
						</Button>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default ActivatePage;
