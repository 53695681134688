import React from "react";
import {
  Tabs,
  Tab,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
// import DepthProbBuildingFixedSealevel from "./DepthProbBuildingFixedSealevel";
import DepthProbBuildingFixedclimate from "./DepthProbBuildingFixedclimate";
import DepthProbTableBuildingFixedSealevel from "./DepthProbTableBuildingFixedSealevel";

const SealevelProbTable = ({ report }) => {
  const [tabValue, setTabValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState("panel1");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const prob_s1 = report?.risk_results?.["sealevel"][9][0];
  const prob_s2 = report?.risk_results?.["sealevel"][9][1];
  const prob_s3 = report?.risk_results?.["sealevel"][9][2];
  const prob_s4 = report?.risk_results?.["sealevel"][9][3];
  // const prob_s1 = report?.risk_results?.["sealevel"][7][0][0];
  // const prob_s2 = report?.risk_results?.["sealevel"][7][1][0];
  // const prob_s3 = report?.risk_results?.["sealevel"][7][2][0];
  // const prob_s4 = report?.risk_results?.["sealevel"][7][3][0];


  // const d_all = report?.risk_results?.["sealevel"][8];
  // console.log("d_all", d_all);
  //   console.log("prob_s1", prob_s1);
  //   console.log("report", report);
  //   console.log("prob_s1", prob_s1);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        各情境水深機率表
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="flood info tabs"
            centered={!isMobile}
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : false}
          >
            <Tab label="海平面上升1.5m" />
            <Tab label="淹平面上升2.0m" />
            <Tab label="海平面上升4.0m" />
            <Tab label="海平面上升4.7m" />
          </Tabs>
        </Box>
        {tabValue === 0 && (
          <Box p={3}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>S1:海平面上升1.5m+AR6降雨情境：</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DepthProbBuildingFixedclimate
                  report={report}
                  waterProbData={prob_s1}
                />
                {/* <DepthProbTableBuildingFixedSealevel
                  report={report}
                  waterProbData={prob_s1}
                  d={d_all[0]}
                /> */}
              </AccordionDetails>
            </Accordion>
            {/* Repeat for other items as needed */}
          </Box>
        )}
        {tabValue === 1 && (
          <Box p={3}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>S2:海平面上升2.0m+AR6降雨情境：</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* <DepthProbBuildingFixedSealevel
                  report={report}
                  waterProbData={prob_s1}
                /> */}
                <DepthProbBuildingFixedclimate
                  report={report}
                  waterProbData={prob_s2}
                />
                {/* <DepthProbTableBuildingFixedSealevel
                  report={report}
                  waterProbData={prob_s2}
                  d={d_all[1]}
                /> */}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
        {tabValue === 2 && (
          <Box p={3}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>S3:海平面上升4.0m+AR6降雨情境：</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DepthProbBuildingFixedclimate
                  report={report}
                  waterProbData={prob_s3}
                />
                {/* <DepthProbTableBuildingFixedSealevel
                  report={report}
                  waterProbData={prob_s3}
                  d={d_all[2]}
                /> */}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
        {tabValue === 3 && (
          <Box p={3}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>S4:海平面上升4.7m+AR6降雨情境：</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DepthProbBuildingFixedclimate
                  report={report}
                  waterProbData={prob_s4}
                />
                {/* <DepthProbTableBuildingFixedSealevel
                  report={report}
                  waterProbData={prob_s4}
                  d={d_all[3]}
                /> */}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default SealevelProbTable;
