// src/features/waterProbData/waterProbDataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  faultData: { distance_info: [] },
};

export const faultDataSlice = createSlice({
  name: "faultData",
  initialState,
  reducers: {
    setFaultData: (state, action) => {
      state.faultData = action.payload;
    },
    resetFaultData: (state) => {
      state.faultData = initialState.faultData;
    },
  },
});

export const { setFaultData, resetFaultData } = faultDataSlice.actions;

export default faultDataSlice.reducer;
