// import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

import React, { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import { Link } from "react-router-dom";
import "./Navbar3.scss";
import Cart from "../Cart/Cart";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, reset } from "../../features/auth/authSlice";
//mui
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import LoginIcon from "@mui/icons-material/Login";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { addToCart, resetCart } from "../../redux/cartReducer";
import { Badge } from "@mui/material";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [membershipPoints, setMembershipPoints] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cartItem, setCartItem] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  // const token = localStorage.getItem("user");
  // console.log("user", user);
  const [productNumber, setProductNumber] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axiosInstance.get("risk/user-products/");
        setProductNumber(res.data.length); // Set the product number here
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  // Use cleanup() in the catch block and the useEffect cleanup function.

  useEffect(() => {
    const fetchCartItemsAndMembershipPoints = async () => {
      // Check if the user is logged in
      if (!user) {
        console.log("User is not logged in. Skipping data fetch.");
        setIsLoading(false);

        dispatch(logout());
        dispatch(reset());
        dispatch(resetCart());
        setMembershipPoints(0);
        setIsLoggedIn(false);
        // navigate("/");
        return;
      }

      setIsLoading(true); // Start loading
      try {
        const cartResponse = await axiosInstance.get("shop/carts/");
        const cartItems = cartResponse.data[0].products;
        setCartItem(cartItems);

        // Add cart items to Redux store
        cartItems.forEach((product) =>
          dispatch(
            addToCart({
              id: product.pkid,
              title: product.title,
              desc: product.desc,
              price: product.total_price,
              quantity: 1,
            })
          )
        );

        const membershipResponse = await axiosInstance.get(
          "shop/membership/membership_status/"
        );
        setMembershipPoints(membershipResponse.data.points_balance);
      } catch (error) {
        console.error("Failed to get cart items or membership status:", error);
        dispatch(logout());
        dispatch(reset());
        dispatch(resetCart());
        setMembershipPoints(0);
        setIsLoggedIn(false);
      }
      setIsLoading(false); // Stop loading
    };

    // Additional check here
    if (user) {
      fetchCartItemsAndMembershipPoints();
    } else {
      // Clear the state if the user is not logged in
      // setCartItem([]);
      // setMembershipPoints(0);
      setIsLoading(false);

      dispatch(logout());
      dispatch(reset());
      dispatch(resetCart());
      setMembershipPoints(0);
      setIsLoggedIn(false);
    }
  }, [user]);

  const logoutHandler = () => {
    dispatch(logout());
    dispatch(reset());
    dispatch(resetCart());
    setMembershipPoints(0);
    setIsLoggedIn(false);
    navigate("/");
  };
  // const [open, setOpen] = useState(false);
  const products = useSelector((state) => state.cart.products);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const openuser = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const handleNavbarClick = () => {
    if (anchorEl) {
      setAnchorEl(null);
    }
  };

  // Function to format points
  const formatPoints = (points) => {
    if (points <= 10000) return `${points}`;
    if (points > 10000 && points <= 20000) return "10k+";
    if (points > 20000 && points <= 30000) return "20k+";
    if (points > 30000 && points <= 40000) return "30k+";
    if (points > 40000 && points <= 50000) return "40k+";
    if (points > 50000) return "50k+";

    return points.toString();
  };

  return (
    <div className="sfh-right">
      <div className="sfh-icons">
        {/* <div className="sfh-cartIcon">
          <Link className="link" to="/">
            <CurrencyExchangeIcon />
           
            <span className="nav-span">{formatPoints(membershipPoints)}</span>
          </Link>
        </div> */}

        {/* <div className="sfh-cartIcon">
          <Link className="link" to="/cart">
            <ShoppingCartOutlinedIcon sx={{ color: "#4C839B" }} />
            <span>{products ? products.length : 0}</span>
          </Link>
        </div> */}

        {user ? (
          <div>
            <Tooltip title="帳戶設定">
              <IconButton
                onClick={handleClick}
                aria-controls={openuser ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openuser ? "true" : undefined}
              >
                <Badge
                  badgeContent={productNumber}
                  color="secondary"
                  sx={{
                    "& .MuiBadge-badge": {
                      fontSize: "0.6rem", // Smaller font size for the badge
                      minWidth: "15px", // Customize width and height of the badge
                      height: "15px",
                      padding: "0px",
                    },
                  }}
                >
                  <AccountCircleOutlinedIcon
                    sx={{ color: "#4C839B", fontSize: "1.5rem" }}
                    // sx={{ width: 24, height: 24 }}
                    // sx={{ width: 26, height: 26 }}

                    alt="Remy Sharp"
                    // src="/img/avatar/logo_word.png"
                  />
                </Badge>
              </IconButton>
            </Tooltip>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openuser}
              // onClose={handleClose}
              onClick={handleClose}
              disableScrollLock={true} // Prevents the scroll lock
              PaperProps={{
                elevation: 0,
                sx: {
                  color: "#4C839B",
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <Divider />
              <div onClick={() => navigate("/Profile")}>
                <MenuItem>
                  <ListItemIcon>
                    <AccountCircleOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#4C839B" }}
                    />
                  </ListItemIcon>
                  我的帳戶
                </MenuItem>
              </div>
              <div onClick={() => navigate("/Profile")}>
                <MenuItem>
                  <ListItemIcon>
                    <CurrencyExchangeIcon
                      fontSize="small"
                      sx={{ color: "#4C839B" }}
                    />
                  </ListItemIcon>
                  點數: {membershipPoints}
                </MenuItem>
              </div>
              <div onClick={() => navigate("/dashboard")}>
                <MenuItem>
                  <ListItemIcon>
                    <AssessmentOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#4C839B" }}
                    />
                  </ListItemIcon>
                  我的報告:{productNumber}
                </MenuItem>
              </div>

              <div onClick={logoutHandler}>
                <MenuItem>
                  <ListItemIcon>
                    <Logout fontSize="small" sx={{ color: "#4C839B" }} />
                  </ListItemIcon>
                  登出
                </MenuItem>
              </div>
            </Menu>
          </div>
        ) : (
          <Link className="link" to="/login">
            <LoginIcon fontSize="small" sx={{ color: "#4C839B" }} />{" "}
            <strong style={{ color: "#4C839B" }}>登入</strong>
          </Link>
        )}
      </div>
    </div>
  );
}
