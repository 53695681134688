import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import Spinner from "../../components/Spinner/Spinner";
import { login, reset } from "../../features/auth/authSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "./login.scss";

const theme = createTheme();

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  // useEffect(() => {
  //   if (user) {
  //     toast.error("目前為登入狀態，切換使用者請先登出!");
  //     navigate("/"); // Redirect if already logged in
  //   }
  // }, [user, navigate]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (isSuccess || user) {
      navigate("/");
    }

    dispatch(reset());
  }, [isError, isSuccess, message, user, navigate, dispatch]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!email) {
      toast.error("請提供有效的電子信箱帳號");
      return;
    }

    if (!password) {
      toast.error("請提供有效的密碼");
      return;
    }

    const userData = {
      email,
      password,
    };

    dispatch(login(userData));
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="login-background">
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            color: "#4C839B",
            fontWeight: "bold",
            marginTop: 8,
            marginBottom: 8,
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              // marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 4,
              borderRadius: 2,
              boxShadow: 3,
              backgroundColor: "white",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h5"
              sx={{ color: "#4C839B", fontWeight: "bold" }}
            >
              帳號登入
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="信箱帳號"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="密碼"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="記住我"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#4C839B",
                  "&:hover": {
                    backgroundColor: "gray",
                  },
                  fontWeight: "bold",
                }}
              >
                登入
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    to="#"
                    style={{ color: "#1976d2", textDecoration: "none" }}
                  >
                    忘記密碼?
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    to="/register"
                    style={{ color: "#1976d2", textDecoration: "none" }}
                  >
                    {"還沒有帳號嗎? 立即註冊"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default Login;
