import React, { useEffect, useState } from "react";
import "./DepthProb.scss";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Prob3 = ({ report }) => {
  const [maskingHeight, setMaskingHeight] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (report?.p10_200) {
      const newData = Object.entries(report.p10_200)
        .map(([depth, probability]) => {
          const depthValue = parseInt(depth.replace("cm", ""));
          // Apply masking, if the depth is less than masking height, probability is 0
          const adjustedProbability =
            depthValue <= maskingHeight ? 0 : parseFloat(probability) || 0;
          return {
            淹水深度: depthValue - maskingHeight, // Adjust depth by subtracting masking height
            機率: adjustedProbability,
          };
        })
        .filter((entry) => entry.淹水深度 > 0); // Remove entries that are now less than or equal to 0

      // Sort the data by 淹水深度 in ascending order
      newData.sort((a, b) => a.淹水深度 - b.淹水深度);

      setData(newData);
    }
  }, [report, maskingHeight]);

  const handleMaskingHeightChange = (event) => {
    // Parse the input value as an integer, default to 0 if not a number
    let inputHeight = parseInt(event.target.value) || 0;
    // If inputHeight is negative, reset it to 0
    inputHeight = Math.max(inputHeight, 0);
    setMaskingHeight(inputHeight);
  };

  return (
    <div className="DepthProb" style={{ fontFamily: "Arial", padding: "20px" }}>
      <div className="input-container">
        <label htmlFor="maskingHeight">門檻高度(cm): </label>
        <input
          id="maskingHeight"
          type="number"
          min="0" // Set a minimum value of 0 to prevent negative numbers
          value={maskingHeight}
          onChange={handleMaskingHeightChange}
        />
      </div>
      <div className="chart-container">
        <ResponsiveContainer width="100%" height="77%">
          <AreaChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              // right: 30,
              left: 10,
              bottom: 20,
            }}
            style={{
              position: "absolute",
              bottom: 0, // Align the bottom of the chart with the container
              left: 0, // Align the left side of the chart with the container
              background: "transparent", // Ensure the background is transparent
            }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#000" />
            <XAxis
              type="number"
              dataKey="機率"
              domain={[0, "dataMax"]}
              label={{
                value: "機率(%)",
                position: "insideBottom",
                offset: -10,
                fill: '#000' // Label text color
              }}
              stroke="#000" // Tick color
            />
            <YAxis
              type="number"
              dataKey="淹水深度"
              label={{
                value: "水深(cm)",
                angle: -90,
                position: "insideLeft",
                offset: 10,
                fill: '#000' // Label text color
              }}
              stroke="#000" // Tick color
            />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="淹水深度"
              stroke="#8884d8"
              fill="#8884d8"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Prob3;
