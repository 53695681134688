import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { useSelector } from "react-redux";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const ProbTable = ({ report, waterProbData }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("depth");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  // Redux state
  // const targetInfo = useSelector((state) => state.targetInfo);
  const targetInfo = useSelector((state) => state.targetInfo);
  // const targetInfoReport = JSON.parse(report?.product.target_info);
  // const [maskingHeight, setMaskingHeight] = useState(
  //   targetInfoReport.maskingHeight
  // );
  // console.log("maskingHeight", maskingHeight);

  // Component's local state to hold processed table data
  const [tableData, setTableData] = useState([]);

  const handleRequestSort = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const processData = () => {
      return Object.entries(waterProbData)
        .map(([depth, probability]) => {
          const depthValue = parseInt(depth.replace("cm", ""));
          // Only include depths that are multiples of 10cm
          if (depthValue % 10 !== 0 || depthValue === 0) {
            return null;
          }
          const adjustedDepth = depthValue - targetInfo.maskingHeight;
          // Filter out entries where adjustedDepth <= 0
          if (adjustedDepth <= 0) {
            return null;
          }
          const depthInMeters = (adjustedDepth / 100).toFixed(1); // Convert to meters and format to one decimal
          const adjustedProbability =
            adjustedDepth > 0 ? parseFloat(probability) : 0;
          // Determine carLossRatio based on depthInMeters
          let carLossRatio;
          if (depthInMeters >= 0.9) {
            carLossRatio = 100;
          } else if (depthInMeters >= 0.6) {
            carLossRatio = 50;
          } else if (depthInMeters >= 0.4) {
            carLossRatio = 27;
          } else if (depthInMeters >= 0.3) {
            carLossRatio = 14;
          } else {
            carLossRatio = 0; // Or set to default if there is a default ratio
          }
          return {
            depth: depthInMeters,
            probability: `${adjustedProbability.toFixed(3)}%`,
            frequency:
              adjustedProbability > 0
                ? `${(100 / adjustedProbability).toFixed(0)}年`
                : "N/A年",
            tenYearRisk: `${(
              (1 - (1 - adjustedProbability / 100) ** 10) *
              100
            ).toFixed(3)}%`,
            // carLoss: `${targetInfo.carValue * (carLossRatio / 100)}`, // Assuming targetInfo has a carValue field

            carLoss: `${(targetInfo.carValue * (carLossRatio / 100)).toFixed(
              2
            )}`, // Assuming targetInfo has a carValue field
          };
        })
        .filter((entry) => entry && parseFloat(entry.depth) <= 2.0); // Filter out null entries and ensure depth is within range
    };

    const newData = processData();
    setTableData(newData);
  }, [waterProbData, targetInfo.maskingHeight]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          aria-label="simple table"
          sx={{ "MuiTableCell-root": { padding: "8px" } }}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "depth"}
                  direction={orderBy === "depth" ? order : "asc"}
                  onClick={handleRequestSort("depth")}
                >
                  淹水深度(m)
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "probability"}
                  direction={orderBy === "probability" ? order : "asc"}
                  onClick={handleRequestSort("probability")}
                >
                  發生機率(%)
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "frequency"}
                  direction={orderBy === "frequency" ? order : "asc"}
                  onClick={handleRequestSort("frequency")}
                >
                  多少年發生一次
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "tenYearRisk"}
                  direction={orderBy === "tenYearRisk" ? order : "asc"}
                  onClick={handleRequestSort("tenYearRisk")}
                >
                  10年內發生的機率(%)
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "tenYearRisk"}
                  direction={orderBy === "tenYearRisk" ? order : "asc"}
                  onClick={handleRequestSort("tenYearRisk")}
                >
                  1F附屬物損失(萬元)
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "tenYearRisk"}
                  direction={orderBy === "tenYearRisk" ? order : "asc"}
                  onClick={handleRequestSort("tenYearRisk")}
                >
                  車輛損失(萬元)
                </TableSortLabel>
              </TableCell>
              {/* <TableCell>
                <TableSortLabel
                  active={orderBy === "tenYearRisk"}
                  direction={orderBy === "tenYearRisk" ? order : "asc"}
                  onClick={handleRequestSort("tenYearRisk")}
                >
                  加權損失(萬元)
                </TableSortLabel>
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(tableData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.depth}</TableCell>
                  <TableCell>{row.probability}</TableCell>
                  <TableCell>{row.frequency}</TableCell>
                  <TableCell>{row.tenYearRisk}</TableCell>
                  <TableCell>{15}</TableCell>
                  <TableCell>{row.carLoss}</TableCell>
                  {/* <TableCell>{row.tenYearRisk}</TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          // rowsPerPageOptions={[5, 10]}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-toolbar": {
              justifyContent: "space-between", // Adjust the pagination bar
              alignContent: "center",
              p: {
                margin: "auto", // Override margins for p elements
              },
            },

            ".MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-selectIcon":
              {
                margin: "auto",
                marginRight: "8px",
              },
            // Add this block to override styles from reboot.scss
            "& .MuiTablePagination-toolbar p": {
              margin: "auto", // Override the top and bottom margins of p tag
            },
          }}
        />
      </TableContainer>
    </>
  );
};

export default ProbTable;
