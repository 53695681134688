import React from "react";
import Categories from "../../components/Categories/Categories";
// import Follow from "../../components/Follow/Follow";
// import FeaturedProducts from '../../components/FeaturedProducts/FeaturedProducts'
// import Slider from "../../components/Slider/Slider";
import Rain from "../../components/Rain/Rain";
// import Slider2 from "../../components/Slider/Slider2";
// import Slider3 from "../../components/Slider/Slider3";
import StormText from "../../components/StormText/StormText";
import FeatureText from "../../components/FeatureText/FeatureText";
// import MapMove from "../../components/Map/MapMove";

// import Compare from '../../components/Compare/Compare'
import "./Home.scss";
import Title from "../../components/Title/Title";
import Showcase from "../../components/Showcase/Showcase";
import Price from "../../components/Price/Price2";
import SearchHome from "../../components/SearchHome/SearchHome";
import SearchHomeGoogle from "../../components/SearchHome/SearchHomeGoogle";
import LossInfo from "../../components/LossInfo/LossInfo";
import Statistics from "../../components/Statistics/Statistics";
import CustomAnalysis from "../../components/CustomAnalysis/CustomAnalysis";
import ShowCompare from "../../components/ShowCompare/ShowCompare";
import FreeTrial from "../../components/Rain/FreeTrial";
import GoldenCircle from "../../components/GoldenCircle/GoldenCircle";
import ProductCompare from "../../components/Table/ProductCompare";
// import MapboxSpin from '../../components/Map/MapboxSpin'

const Home = () => {
  return (
    <div className="home" style={{ backgroundColor: "#EEEEEE" }}>
      <Title title="歡迎來到-安家百科" />
      {/* <SearchHome /> */}
      <SearchHomeGoogle />
      <GoldenCircle />
      <LossInfo />
      <Statistics />
      {/* <ProductCompare /> */}
      <CustomAnalysis />
      {/* <Showcase /> */}
      {/* <StormText /> */}
      <FeatureText />
      <Price />
      {/* <ShowCompare /> */}
      <FreeTrial />
      {/* <FeaturedProducts type="featured"/> */}
      {/* <Compare/> */}
      {/* <FeaturedProducts type="trending"/> */}
      {/* <Categories /> */}
      {/* <Follow /> */}
    </div>
  );
};

export default Home;
