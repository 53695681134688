import React, { useState } from "react";
import "./Contact.scss";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = () => {
    // Perform validation if needed
    // ...

    // Construct the email link
    const subject = encodeURIComponent("Email Subject");
    const body = encodeURIComponent(
      `Name: ${name}\nEmail: ${email}\nMessage: ${message}`
    );
    const mailtoLink = `mailto:service@safehousing.info?subject=${subject}&body=${body}`;

    // Open the user's default email client
    window.location.href = mailtoLink;
  };

  return (
    <div>
      <div className="contact-container">
        <div className="title-container">
          <div className="report-h0">SAFEHOUSING</div>
          <h3 className="title-text">聯絡我們</h3>
          <hr className="title-divider" />
        </div>

        <div className="contact-info-form">
          <div className="contact-info">
            <div className="logo-container">
              <img
                className="logo"
                src="/img/logo_word12.png"
                alt="Safehousing Logo"
              />
            </div>
            <div className="contact-details">
              <div className="contact-item">
                <i className="fas fa-envelope"></i>
                <p>
                  <a href="mailto:service@safehousing.info">
                    service@safehousing.info
                  </a>
                </p>
              </div>
              <div className="contact-item">
                <i className="fas fa-map-marked-alt"></i>
                <p>
                  <a
                    href="https://goo.gl/maps/1nux52sckt1LLvZG8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    桃園市中壢區青埔里青商路61號2樓
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="contact-form">
            <label htmlFor="name">您的姓名</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label htmlFor="email">您的信箱</label>
            <input
              type="text"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="example@xxx.com"
            />
            <label htmlFor="message">您的訊息</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <button onClick={sendEmail}>寄出</button>
          </div>
        </div>
      </div>
      {/* <div className="city-bg">
        <img src="/img/city-l.png" alt="City Background" />
      </div> */}
    </div>
  );
};

export default Contact;
