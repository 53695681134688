import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import axiosInstance from "../../axios";
import "./UserHeader.scss";

const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "16px",
});

const UserHeader = ({ profile }) => {
  const [membershipPoints, setMembershipPoints] = useState(0);

  useEffect(() => {
    const fetchMembershipPoints = async () => {
      try {
        const membershipResponse = await axiosInstance.get(
          "shop/membership/membership_status/"
        );
        setMembershipPoints(membershipResponse.data.points_balance);
      } catch (error) {
        console.error("Failed to get membership status:", error);
      }
    };
    fetchMembershipPoints();
  }, []);

  return (
    <StyledBox>
      <Box display="flex" alignItems="center">
        {/* <Avatar sx={{ width: 64, height: 64 }}>JD</Avatar> */}
        <Avatar
          sx={{ width: 100, height: 100 }}
          // src={formData.profile_photo}
          src="/img/logo_word12.png"
          // src="/img/avatar/logo_word.png"
          className="avatar"
        />
        <Box marginLeft="16px">
          <Typography variant="h4">使用者:{profile.username}</Typography>
          {/* <Typography variant="h4">John Doe</Typography> */}
          <Typography variant="subtitle1">
            會員點數: {membershipPoints}
          </Typography>
        </Box>
      </Box>
      <Box marginLeft="16px" className="user-info">
        <Typography variant="subtitle1">信箱: {profile.email}</Typography>
        <Typography variant="subtitle1">
          電話: {profile.phone_number}
        </Typography>
      </Box>
    </StyledBox>
  );
};

export default UserHeader;
