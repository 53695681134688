import React from "react";
import FaultDistanceTable from "../Table/FaultDistanceTable";
import PgaProbBuilding2 from "../Prob/PgaProbBuilding2";
// import { Container } from "@mui/material";
import { useSelector } from "react-redux";

const EerthQuakeResult = ({ report }) => {
  //   const [distanceInfo, setDistanceInfo] = useState([]);

  const polygonData = useSelector((state) => state.polygonData.polygonData);
  const polygonData1 = {
    geo_data: JSON.stringify(report.product.calculated_geometry),
  };
  const fault = { geo_data: report.product.calculated_geometry };
  const targetInfo = useSelector((state) => state.targetInfo);
  // console.log("polygonData:", polygonData);
  // console.log("polygonData1:", polygonData1);

  return (
    <div>
      <h5>建物與斷層距離</h5>
      <hr />

      <FaultDistanceTable report={report} />

      <h5>地震機率分析</h5>
      <hr />

      <PgaProbBuilding2 report={report} polygonData={polygonData} targetInfo={targetInfo} />
    </div>
  );
};

export default EerthQuakeResult;
