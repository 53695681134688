// import YouTube from 'react-youtube';
// import ReactPlayer from "react-player";
// import ReactPlayer from 'react-player/youtube'
import ReactPlayer from "react-player/lazy";
import React, { useState } from "react";
import "./Video.scss";

const Video = () => {
  const [videoId, setVideoId] = useState("hw2GjSRmY_w&ab");
  const video_list = [
    {
      name: "安家百科系列1-高精度淹水資訊可以完成哪些事?",
      url: "https://youtu.be/hw2GjSRmY_w",
    },
    {
      name: "安家百科系列2-高精度淹水系統的組成",
      url: "https://youtu.be/PaUrAWP0HqA",
    },
    {
      name: "安家百科系列3-高精度淹水資訊的優勢",
      url: "https://youtu.be/6u4WMN4QIjA",
    },
    {
      name: "安家百科系列4-防災知識家",
      url: "https://youtu.be/Wxx3S78KcuQ",
    },
    {
      name: "安家百科系列5-高精度淹水資訊的商務運用",
      url: "https://youtu.be/bJYv4MpdcYc",
    },
  ];

  return (
    <div className="video-page-container">
      {video_list.map((video, index) => (
        <div className="video-container" key={index}>
          <h3>{video.name}</h3>
          <ReactPlayer
            url={video.url}
            controls={true}
          />
          <hr />
          {/* <YouTube videoId={videoId} className="youtube-video" /> */}
        </div>
      ))}
    </div>
  );

  {
    /* <YouTube videoId={videoId} className="youtube-video" /> */
  }
};

export default Video;
