import React from "react";

const ServiceTerm = () => {
  return (
    // <div className="privacy-term-container" style={{ marginTop: "55px" }}>
    <div className="privacy-term-container">
      <div className="title-container">
        <div className="report-h0">SAFEHOUSING</div>
        <h3 className="title-text">服務條款</h3>
        <hr className="title-divider" />
      </div>
      <div className="privacy-term-row">
        <div className="privacy-term-col">
          {/* <h1 className="privacy-term-title primary_color">服務條款</h1> */}

          <p className="privacy-term-text">生效日期：2021 年 10 月 19 日</p>

          <p className="privacy-term-text">
            本協議列明的條款（下稱「服務條款」）適用於您和 白澤驅動有限公司
            之間的關係；該公司是台灣公司（公司編號：90688796），
            {/* 之間的關係；該公司是台灣公司（公司編號：90688796），公司註冊地址位於
            320
            桃園市中壢區青埔里青溪路二段125號2樓（下稱「安家百科」或「我們」）；內容關乎您使用 */}
            （下稱「安家百科」或「我們」）；內容關乎您使用 安家百科
            應用程式、網站和相關服務（下稱「服務」）時的注意事項。使用有關服務同時受
            安家百科隱私政策和其他相關政策約束，有關政策已列舉於此以作參考。
          </p>

          <p>
            存取或使用服務前（包括瀏覽任何 安家百科
            網站或應用程式），您必須同意本服務條款和隱私權政策。為了使用服務，我們可能會為您建立訪客帳號。您也可能被要求在服務中註冊一個帳號（統稱為「帳號」）。這些帳號包含應用程式帳號和
            安家百科 ID
            等。使用或註冊帳號或以其他方式使用服務，即表示您確認您在您居住的國家/地區已達到法定成年年齡。如果您未達到法定成年年齡，您的法定監護人必須檢視且同意本服務條款。
          </p>

          <p>
            <b>
              安裝、使用或以其他方式存取服務，即表示您同意本服務條款。如不同意本服務條款，請勿安裝、使用或以其他方式存取服務。違規使用服務視為無效。{" "}
            </b>
          </p>

          <p>
            安家百科
            有權隨時酌情變更、修改、增加或刪減本服務條款、隱私政策，以及其他相關
            安家百科
            政策；只需於服務中公佈修訂條款即可。一旦繼續使用服務，則等同接受上述變更。不論任何時候，如不同意當時生效的服務條款、安家百科
            隱私政策，或其他關乎使用服務情況的 安家百科
            條款、規則或行為準則，您使用服務的權利立即終止，您也須立即停止使用服務。
          </p>

          <h4 className="privacy-term-section-title">1. 使用服務</h4>

          <h3>1.1 您使用服務的權利</h3>

          <p>
            基於您同意並遵守服務條款和其他相關 安家百科
            政策，您擁有非獨享、不可轉讓、不得轉讓他人，另可撤銷的有限權利，令您可出於非商業娛樂目的存取及使用服務。您同意不得為任何其他目的使用服務。
          </p>

          <ul className="privacy-term-list">
            使用服務即受下列限制規範：
            <li className="privacy-term-list-item">
              如有未成年人士未經授權使用本服務，您應負擔全責。如有未成年人士使用您的信用卡或其他支付工具（如
              Paypal），您應承擔有關責任。
            </li>
            <li className="privacy-term-list-item">
              您不得（亦不得試圖）購買、出售、出租或贈送帳號；或使用虛假身份和資料，或以他人名義註冊帳號。如果
              安家百科 曾經刪除您的帳號或禁止您使用任何 安家百科
              應用程式，您不應繼續使用服務。
            </li>
            <li className="privacy-term-list-item">
              您不得透過服務向任何人發送垃圾郵件、重覆訊息或誤導他人的訊息。
            </li>
          </ul>

          <h4 className="privacy-term-section-title">登入資訊與您的帳號</h4>

          <p>
            您可能需要為帳號挑選密碼，或您也可以使用其他認證資料存取帳號（下稱「登入資訊」）。您不得與人共享帳號或登入資訊，抑或容許他人存取您的帳號，或作出可危及帳號安全的行為。如果您得知或有合理理由懷疑存在安全威脅，包括但不限於帳號遺失，帳號被盜，或登入資訊經未經授權披露，您必須立即通知
            安家百科
            並修改登入資訊。您須就保密登入資訊負上全責，另須就一切使用登入資訊的行為負上全責，包括購物在內（不論您授權與否）。您應就一切與個人帳號有關的事項承擔責任。
          </p>

          <p>
            安家百科
            有權隨時以任何理由刪除或回收任何帳號名稱，包括但不限於第三方聲稱相關帳號名稱侵權的情況。
          </p>

          <p>
            除非您使用 安家百科
            ID，否則服務只會於相容裝置上就每款應用程式支援一個帳號。
          </p>

          <h4 className="privacy-term-section-title">使用限制</h4>
          <p>
            我們嚴格禁止任何違反使用限制的服務使用行為；否則我們可立即撤銷您的有限權利，您亦或需就違反法律承擔責任。
          </p>
          <ul className="privacy-term-list">
            於任何情況下，您同意不得：
            <li className="privacy-term-list-item">
              從事任何 安家百科 認為會違背服務宗旨或意圖的行為，或不當使用
              安家百科 支援服務。
            </li>
            <li className="privacy-term-list-item">
              使用或參與（直接或間接）作弊行為、入侵程式、自動程式、模擬器、機器程式、駭客行為、遊戲插件或任何未經授權的第三方程式，意圖修改或干預安家百科服務或體驗。
            </li>
            <li className="privacy-term-list-item">
              未經 安家百科 明確書面同意，修改或促使修改服務或任何
              安家百科旗下任何檔案。
            </li>
            <li className="privacy-term-list-item">
              擾亂、干預或以其他方式影響服務正常流程；抑或作出可影響他人使用本服務或相關體驗的行為。有關行為包括利用服務漏洞取得優勢，以及任何其他有意濫用或違反服務設計的行為。
            </li>
            <li className="privacy-term-list-item">
              對任何用於提供或支援服務的電腦或伺服器，或任何 安家百科
              運作環境的中斷或超荷運作，造成干擾或超荷運作或提供幫助或協助。
            </li>
            <li className="privacy-term-list-item">
              實施、協助或參與任何形式的攻擊行為，包括但不限於病毒傳播、對服務進行分散式阻斷服務攻擊（DDoS），抑或其他企圖擾亂服務，或影響其他人使用或享用服務的行為。
            </li>
            <li className="privacy-term-list-item">
              企圖透過 安家百科
              用戶介面以外的方式，未經授權存取服務、他人註冊或使用過的帳號、電腦、伺服器或連接至服務的網絡；包括但不限於規避或修改、或試圖規避或修改、或鼓勵或協助他人規避或修改任何服務中的安全性、技術、裝置或程式。
            </li>
            <li className="privacy-term-list-item">
              發布任何涉及辱罵、威脅、淫穢、中傷或誹謗他人的資訊，或於種族、性、宗教信仰或其他方面令人反感或感冒犯的訊息；或涉及惡意行為，如在他人未經同意下重覆發送訊息。
            </li>
            <li className="privacy-term-list-item">
              發布任何含有裸露成分、過度暴力或令人反感的資訊，或張貼含有上述內容連結的訊息。
            </li>
            <li className="privacy-term-list-item">
              騷擾、辱罵或傷害他人，抑或嘗試、提倡或煽動他人或團體實施有關行為，包括
              安家百科 員工，亦包含 安家百科 客戶服務代表。
            </li>
            <li className="privacy-term-list-item">
              透過服務公開任何材料或資訊，侵犯他人或任何實體的著作權、商標權、專利權、商業秘密、隱私權、出版權或其他權利；抑或假冒其他人士，包括但不限於
              安家百科 員工。
            </li>
            <li className="privacy-term-list-item">
              反向工程、反編譯、拆解、破譯或以其他方式，試圖取得任何用於支援服務或任何
              安家百科 應用程式的底層程式或其他智慧財產權的原始碼；或以任何未經
              安家百科 表明許可的方式，經服務或任何 安家百科 程式獲取任何資訊。
            </li>
            <li className="privacy-term-list-item">
              索取或嘗試索取他人用於存取服務或任何 安家百科
              系統的登入資訊、其他認證資料或個人資訊。
            </li>
            <li className="privacy-term-list-item">
              透過服務收集或發布他人私人資訊，包括個人可識別資訊（包括文字、圖像和影片形式）、身份證明文件或財務資訊。
            </li>
            <li className="privacy-term-list-item">
              利用任何 安家百科
              程式進行賭博、押注或是任何能（直接或間接）贏取獎品或獎勵的類似活動，包含對您以玩家身分參與的比賽之結果進行押注，不論此行為是否牽涉費用或賭注。
            </li>
          </ul>
          <p>
            安家百科
            有權界定相關行為是否違反使用規則，抑或違背服務條款或服務本身的宗旨和精神。安家百科
            有權據此採取相關措施，包括停用您的帳號及禁止您使用全部或部分服務。
          </p>

          <h3>1.2 停用及終止帳號和服務</h3>
          <p>
            在不影響任何賠償方案的前提下，(i) 當您違反或 安家百科
            懷疑您違反本服務條款；抑或 (ii)
            參與或涉嫌參與違法或不當使用服務的行為時，安家百科
            有權在通知或未經通知的情況下限制、停用、終止、修改或刪除您的帳號，抑或限制您使用全部或部分服務。停用或限制使用帳號可導致您喪失帳號名稱和人物頭像，以及與您使用服務有關的任何利益、特權、所獲虛擬物品和購買虛擬物品，而
            安家百科 無須就有關損失或後果承擔任何賠償責任。
          </p>
          <p>
            在不影響任何其他賠償方案的前提下，如果我們相信用戶可能造成風險，行為涉及法律責任，侵犯第三方知識產權，抑或違背我們的條款或政策上的條文或精神；我們有權限制、停用或終止帳號服務或帳號的全部或部分權限，禁止用戶存取我們的遊戲和網站及其內容、服務和工具，延遲或移除託管內容，同時採取技術和法律措施防止用戶使用服務。此外，我們有權在適當的情況下，自行決定停用或終止屢次侵犯第三方智慧財產權的用戶帳號。
          </p>
          <p>安家百科 有權終止任何已連續 365天未曾使用的帳號。</p>
          <p>
            安家百科
            有權隨時停止提供和/或支援服務、特定程式，抑或部分服務；屆時授權使用有關一切或部分服務的權利會自動終止。在此情況下，安家百科
            無須就停止提供服務而承擔任何向用戶退款、返還利益或賠償損失的義務。終止帳號措施包括限制用戶使用服務的權限，包括您或他人上傳的內容。
          </p>

          <h4 className="privacy-term-section-title">2. 所有權</h4>
          <h3>2.1 遊戲與服務</h3>
          <p>
            與服務相關的一切權利、所有權和利益（包括但不限於任何標題、電腦程式碼、主題、物件、人物、人物名稱、故事、對話、短語、概念、圖形、動畫、聲音、音樂作品、視聽效果、操作方法、精神權利、文件、聊天記錄、人物檔案資訊、安家百科客戶端的記錄、安家百科客戶端和伺務器程式）均由
            安家百科 所有。安家百科
            保留與服務相關的所有權利，包括但不限於智慧財產權和其他專有權利。
          </p>

          <h3>2.2 帳號</h3>
          <p>
            即使本條款載有相反規定，您在此確認並同意自己並不享有帳號所有權或其他財產權利，並進一步確認和同意與帳號相關的所有權利均由
            安家百科 享有。
          </p>

          <h3>2.3 虛擬內容</h3>
          <p>
            安家百科
            享有在服務中，出現的一切內容的所有權，亦經授權或有權使用有關內容。即使本條款載有相反規定，您在此同意自己並不享有服務中出現的任何內容的所有權或任何其他權利，包括但不限於您從
            安家百科 購買，或從程式中賺取的任何 安家百科
            系統中產生或出現的虛擬物品、內容、功能、商品、服務或貨幣，以及任何與帳號相關或儲存於服務中的任何其他特質。
          </p>

          <h4 className="privacy-term-section-title">3. 用戶內容</h4>
          <h3>3.1 提交用戶內容</h3>
          <p>
            「用戶內容」指您透過
            安家百科客戶端或服務上傳或傳輸，或由其他用戶上傳或傳輸的資訊，包括但不限於牽涉聊天文本在內的任何通訊、圖像、聲音，以及所有材料和數據。一旦在使用服務的過程中傳輸或提交任何用戶內容，您即確認，表示並保證有關傳輸或提交內容：（a）準確無誤，並非機密資訊，亦不會誤導他人；（b）不違反任何法律規定、合同義務，且不侵犯第三方權利；如用戶內容包含第三方個人資訊或知識產權，您應事先徵求對方同意；（c）涉及內容並無病毒、廣告軟件、間諜軟件、蠕蟲和其他惡意程式碼；以及（d）您確認並同意
            安家百科 會時刻根據隱私權政策，處理有關內容中包含的個人資訊。
          </p>

          <h4 className="privacy-term-section-title">3.1.1 內容審查</h4>
          <p>
            安家百科
            對任何用戶提交用戶內容一事無須承擔任何責任，亦無責任監管服務中的不當內容或行為。我們不會，亦無法事先審查或監管所有用戶內容。如欲使用服務，您需自負風險。使用服務時，您可能接觸到帶有攻擊性、不適當，抑或與預期不符的用戶內容。您需承擔使用服務過程中，與用戶內容相關的任何潛在風險。透過代表或技術手段，我們有權自行監控和
            /
            或記錄您和服務的互動過程，抑或您在使用服務的過程中產生的通訊資訊（包括但不限於聊天文本）。{" "}
          </p>
          <p>
            一旦瀏覽本服務條款，您即同意並接受上述監管和記錄行為，亦不可撤銷有關決定。您確認並同意就傳輸任何用戶內容隱私方面不存任何期望，包括但不限於聊天文本或語音訊息。
          </p>
          <p>
            安家百科
            有權自行決定審查、監管、禁止、編輯、刪除、禁止訪問或以其他方式取消任何用戶內容（包括但不限於您的用戶內容），且無須提供任何理由或作事前通知。不論何時，如
            安家百科
            自行選擇監管服務，我們依然無須就用戶內容負上責任，亦無義務修改或刪除任何不適當的用戶內容。我們有權自行決定，但無義務編輯，拒絕發布或刪除任何用戶內容。
          </p>

          <h3>3.2 其他服務成員如何使用資訊</h3>
          <h4 className="privacy-term-section-title">3.2.1 公共領域</h4>
          <p>
            服務或包括允許用家發布用戶內容的各個論壇、部落格和聊天功能，包括您對特定主題所作的觀察和評論。安家百科
            無法保證其他成員不會使用您分享的想法和資訊。因此，如果您希望將某些想法和資訊保密或不希望他人使用，請勿於服務中張貼相關內容。安家百科
            無權評價或使用您選擇發布的觀點或資訊，並無需就此承擔任何賠償責任。
          </p>

          <h4 className="privacy-term-section-title">3.2.2 就個人內容負責</h4>
          <p>
            您須就透過服務或與服務相關發布的資訊，以及您向他人提供的資訊獨自承擔責任。安家百科
            能以任何理由或無理由拒絕發布或刪除任何用戶內容，包括但不限於
            安家百科 認定有關用戶內容違反服務條款的情況。{" "}
          </p>

          <h4 className="privacy-term-section-title">
            3.2.3 您的 安家百科 授權條款
          </h4>
          <p>
            您特此授予 安家百科
            一項不可撤銷、永久、可轉讓、全額付清、免許可費，並適用於全球範圍的許可（包括轉讓許可證予第三方的權利），以及複製、再造、修復、改編、修改、創造衍生作品、製造、商業化、出版、發行、銷售、許可、分配許可、轉讓、租賃、傳輸、公開展示、公開表演，或允許電子訪問、廣播、透過電信向公眾傳播、放映、表演及進入電腦記憶體的權利；以及透過任何方式使用和操作您的用戶內容，以及與我們提供服務（包括服務的市場營銷和推廣）相關的修改作品和衍生作品的權利。您還特此授予
            安家百科 以下權利：可授權他人行使 安家百科
            在本服務條款下享有的任何權利。您還進一步授予 安家百科
            以下無條件且不可撤銷的權利：能使用及開發您的名稱、肖像，以及用戶內容所包含，或與之相關的任何其他資訊或材料；且無需就此向您承擔任何義務。除非法律另有禁止，否則您等同放棄自己就任何用戶內容可能享有的署名權和
            /
            或精神權利，不論有關用戶內容有沒有以任何方式進行修改或變更。安家百科
            並不會就您的用戶內容索取任何所有權，本服務條款中的任何條款亦不會對您使用和開發用戶內容造成任何限制。安家百科
            並無義務監控或執行您對用戶內容享有的知識產權。
          </p>

          <h3>3.3 用戶互動</h3>
          <p>
            您須就與其他服務用戶，以及透過服務和 / 或 安家百科
            程式產生互動的第三方之間的互動負上全責。安家百科
            保留權利，但沒有義務以任何方式參與這些爭議。您應全力配合 安家百科
            調查任何涉嫌違法，詐欺或不當行為，包括但不限於允許 安家百科
            存取您受密碼保護的帳號。
          </p>
          <p>
            如果您與一名或多名用戶發生糾紛，您免除我們（包括我們的管理人員、董事、代理、子公司、合資企業和員工）受到由上述糾紛產生，或以任何方式與上述糾紛相關，各項已知和未知，任何類型和性質的主張、請求和索賠（包括實際損害和間接損害）。
          </p>

          <h4 className="privacy-term-section-title">4. 費用與購物條款</h4>
          <h3>4.1 購買</h3>
          <p>
            在本服務中，您可以透過「現實世界」的貨幣購買個人、不可轉讓、不得轉讓他人、另可撤銷的有限權利，適用於：(a)
            虛擬貨幣，包括但不限於全部用於 安家百科的虛擬貨幣；(b)
            程式內物品、內容或功能；以及 (c) 其他商品或服務（a – c
            並稱為「虛擬物品」）。您只可透過我們或授權合作夥伴於服務期間購買虛擬物品。{" "}
          </p>
          <p>
            安家百科
            可隨時管理、規定、控制、修改或刪除虛擬物品，無須另行通知。安家百科
            行使任何有關權利時，無須對您或任何第三方承擔任何責任。
          </p>
          <p>
            除非服務中另有明確授權，否則不得轉讓虛擬物品。除非服務中另有明確授權，否則您無權向任何人士或實體銷售，購買，兌換或以其它方式轉讓虛擬物品，包括但不限於
            安家百科、其他用戶或任何第三方。
          </p>
          <p>
            一旦透過服務購買或兌換虛擬物品，即視為<b>最終決定且不得退款</b>。
          </p>
          <p>
            向您提供用於 安家百科 程式中的虛擬物品是 安家百科
            服務的一部分，有關服務將於 安家百科 確認您的購買資訊後立即提供。
          </p>

          <h3>4.2 費用支付</h3>
          <p>
            您同意支付由您或他人使用您的註冊帳號，產生的一切費用和適用的稅項。安家百科
            可隨時調整虛擬物品的價格。您確認 安家百科
            並無義務因任何理由提供退款；即使帳號於自願或非自願的情況下關閉，您亦不會就尚未使用的虛擬物品獲得任何退款或其他補償。
          </p>

          <h4 className="privacy-term-section-title">5. 服務更新</h4>
          <p>
            您理解服務需與時並進，定期更新。安家百科
            可要求您接受安裝於電腦上的服務和 安家百科 程式的更新。您明白並同意
            安家百科 可更新服務和 安家百科
            程式，無需另行通知。您或需不時更新第三方程式，方可享用服務及安家百科
            程式。
          </p>

          <h4 className="privacy-term-section-title">6. 免責聲明</h4>
          <p>
            在不影響下方第 7 條所述 安家百科
            承擔責任的前提下，服務依「現況」及「可行」為考量提供，安家百科
            就此不作任何明示或暗示的保證，包括但不限於涉及適銷，適用於特定目的，所有權、不侵權，以及其他由交易過程或商業慣例產生的任何保證。安家百科
            並不保證您可在自行選擇的時間或地點存取或使用服務；不保證服務不會中斷或不存在錯誤；同樣不保證相關錯誤會被修正，程式和服務並無病毒或其他有害內容。
          </p>
          <p>
            部分司法管轄區不允許免除某些責任。因此，上述部分免責聲明或不適用於您的情況。
          </p>
          <p>
            安家百科致力於維持本系統內容的正確性，但對所提供之資料正確性、真實性不為任何保證。影響資料準確性的因素主要有演算法與模型資料兩者。安家百科採用可用的最佳技術(best
            practice
            available)進行相關災害的模擬與分析；模型資料採用公開資料、購買、合作夥伴提供的資料進行融合，相關資料的正確性會影響評估的結果，本公司已依據歷史災害的觀測資料，針對模型準確性進行驗證，準確率達98％。
          </p>

          <h4 className="privacy-term-section-title">
            7. 豁免條款；唯一救濟權；賠償
          </h4>
          <p>
            在法律允許的最大範圍內，安家百科
            無須向用戶承擔與本服務條款或服務自身引起或有關的任何間接、偶然、從屬、特殊、懲罰性或其他近似損害的賠償責任，包括但不限於收入損失、利潤損失、數據丟失、商業中斷或其他無形損失（不論如何定義以上損失）；不論有關損失是基於合同，侵權或其他法律原理產生，亦不論
            安家百科 是否得悉有可能發生以上損害。在法律允許的範圍內，安家百科
            賠償金額不會多於在您首次索償的六（6）個月內前，您根據本服務條款向
            安家百科 繳付的款項。您明白並同意如自己未有在上述時段向 安家百科
            繳付任何款項，您只能就 安家百科
            禁止您使用服務或取消您的帳號行使唯一救濟權（安家百科 的唯一責任）。
          </p>
          <p>
            本服務條款的任何條款均不影響客戶的任何法定權利，也不會排除或限制
            安家百科 因過錯或詐欺行為，而導致人身傷亡和人身損害的賠償責任。
          </p>
          <p>
            您同意就任何索賠，要求，損害或其他損失，賠償
            安家百科（以及我們的管理人員、董事、代理、子公司、合資企業和員工），為我們抗辯，抑或免除我們承擔上述責任；包括由任何第三方基於您的服務使用行為，或違反本服務條款的行為而主張的合理律師費。不過若然侵權行為並非由您故意或意外引起，上述條款則不適用。
          </p>

          <h4 className="privacy-term-section-title">8. 可分割性</h4>
          <p>
            您和 安家百科 同意，如果本服務條款或 安家百科
            隱私權政策旗下任何條款，被有管轄權的法院認定為違法或不可執行；該條款只會於該司法管轄區內，被認定為無效或不可執行的範圍內喪失法律效力，但不影響該條款在其他司法管轄區內的法律效力，該條款其他部分的法律效力亦不受影響。
          </p>

          <h4 className="privacy-term-section-title">9. 一般條款</h4>
          <h3>9.1 轉讓</h3>
          <p>
            安家百科 有權隨時將全部或部分本服務條款或 安家百科
            隱私權政策，轉讓或委託給其他人士或實體，並無需事先徵詢您的同意。未經
            安家百科
            事先書面同意，您不得將本服務條款或隱私權政策下的任何權利或義務轉讓或委託給其他人士或實體。任何未經授權的轉讓或委託均無法律效力。
          </p>

          <h3>9.2 補充條款</h3>
          <p>
            安家百科
            可以發布與特定服務（如論壇、競賽或忠誠度計畫）有關的補充條款。使用服務過程應受本服務條款和特定補充條款約束。
          </p>

          <h3>9.3 完整協議</h3>
          <p>
            本服務條款、任何補充條款，以及透過引用併入的任何文件（包括 安家百科
            隱私政策），包含您和 安家百科
            之間的所有共識，並替代先前雙方就本條款內容和服務產生的所有共識，不論相關共識是透過電子形式、口頭、書面，或根據交易習慣、慣例、政策或先例而確立。
          </p>

          <h3>9.4 不棄權</h3>
          <p>
            如果 安家百科 未能要求或對您嚴格執行本服務條款或 安家百科
            隱私政策的條款，抑或未能行使相關條款下的任何權利；上述行為不能視作
            安家百科
            免除或放棄在上述情形或任何其他情形下，主張或仰賴任何此類條款或權利。
          </p>
          <p>
            即使 安家百科 明示放棄本服務條款或 安家百科
            隱私政策的任何條款、條件或要求，亦不等同放棄將來遵守該等條款、條件或要求的義務。
          </p>
          <p>
            除非本服務條款另有明確具體規定，否則任何陳述、聲明、同意、棄權或其他
            安家百科
            的作為或不作為，均不等同修改本服務條款，亦不具法律約束力。除非雙方以書面形式另作約定，並由您和
            安家百科 授權人共同簽名。
          </p>

          <h3>9.5 通知</h3>
          <p>
            我們可能會在我們的程式中以發佈公告、在 safehousing.info
            上、以電子郵件或任何您向我們提供的其他聯繫方式向您發出通知。您根據本服務條款或
            安家百科
            隱私政策要求所作的任何通知應以書面形式完成，並寄至：桃園市中壢區青埔里青溪路二段125號2樓。收件人：白澤驅動有限公司，副本：
            service@safehousing.info
          </p>

          <h3>9.6 公平補償</h3>
          <p>
            您明白於 安家百科
            服務條款下享有的權利和承擔的義務為獨特和不可替代，由於您的違約行為或預期違約行為導致的任何損失，都會對
            安家百科
            造成無法補救的損害，單單金錢賠償亦不能彌補相應損失；因此，安家百科
            享有懲罰性救濟或其他公平補償的權利（沒有提供保證、擔保或證據的義務）。
          </p>
          <p>
            您須放棄尋求懲罰性救濟或其他公平補償的所有權利，或禁止或限制服務或任何
            安家百科營運的權利，利用相關廣告或其他資料，或利用服務和其他透過服務使用或顯示內容的權利，以上決定均不可撤銷。同時，您亦同意自己享有的金錢損害賠償權（如有）須受第
            7 條規定限制。
          </p>

          <h3>9.7 不可抗力條款</h3>
          <p>
            安家百科
            對超出其控制範圍而導致的延誤或未能履行的義務不承擔任何責任，包括但不限於不能預見或超出其控制範圍的原因：例如天災、戰爭、恐怖活動、動亂、禁運、民事或軍事當局行為、火災、水災、事故、罷工、交通運輸設施短缺、燃料或能源短缺、勞動力短缺和原料短缺。
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServiceTerm;
