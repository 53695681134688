import React, { useEffect, useState } from "react";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RiskRadarChart from "../../components/Charts/RiskRadarChart";
import "./RiskReport.scss";
import { useNavigate, useParams } from "react-router-dom";
// import axiosInstance from "../../axios";
// import RiskMapClip from "../../components/RiskMapAll/RiskMapClip";
import RiskMapClipFree from "../../components/RiskMapAll/RiskMapClipFreeTrial";
// import RiskMapClipFree from "../../components/RiskMapAll/RiskMapClipFree20240924";

const FreeReport = ({ report }) => {
  const navigate = useNavigate();
  const { pkid } = useParams();

  return (
    // <div style={{ width: "100%" }}>
    // <div className="detail-root" style={{ padding: "0px" }}>
    <div className="detail-root" style={{ padding: "0px" }}>
      <Paper className="detail-paper" elevation={3} sx={{ paddingTop: "10px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Typography className="section">公開風險地圖</Typography>
            <RiskMapClipFree report={report} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default FreeReport;
