import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import "./BuildingMap.scss";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as turf from "@turf/turf";
import { stringify } from "wkt";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const gs_username = process.env.REACT_APP_GEOSERVER_BASIC_USERNAME;
const gs_pass = process.env.REACT_APP_GEOSERVER_BASIC_PASSWORD;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const FloodMapboxSyncMaps = ({ report }) => {
  mapboxgl.accessToken = mapboxtoken;
  const containerRef = useRef(null); // Reference to the container div
  const [isMap1Loading, setIsMap1Loading] = useState(true);
  const [isMap2Loading, setIsMap2Loading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [mapsReady, setMapsReady] = useState({ map1: false, map2: false });
  const [clickedBuildingIds, setClickedBuildingIds] = useState(new Set());
  const [legendUrl, setLegendUrl] = useState("");
  const [currentStyle, setCurrentStyle] = useState("red1");

  const mapContainer1 = useRef(null);
  const mapContainer2 = useRef(null);
  const map1 = useRef(null);
  const map2 = useRef(null);
  const isSyncing = useRef(false);

  const detailOrder = report?.product;
  const buildingH =
    JSON.parse(detailOrder?.description)?.selectedBuildingInfo?.properties
      ?.build_h || 10;
  const circlePolygon = detailOrder?.calculated_geometry;
  const bboxPolygon = detailOrder?.b200;
  const wmsLayerName2 = detailOrder?.wms_layer_name;
  const wmsLayerName = "opendata6h350mm40m3857";
  const wkt3857 = stringify(detailOrder?.b200_3857);
  const isMobile = window.innerWidth <= 768;

  const updateStyleAndFetchLegend = (newStyle) => {
    setCurrentStyle(newStyle);
  };

  const switchBaseMap = (style) => {
    const updateMapStyle = (map) => {
      const isCustomStyle = style.startsWith("mapbox://styles/");
      const styleURL = isCustomStyle
        ? style
        : `mapbox://styles/mapbox/${style}`;

      map.setStyle(styleURL);
      map.once("style.load", () => {
        addCircleToMap(map);
        addBuildingsLayer(map);
        if (map === map1.current) {
          addWMSLayers(map);
        } else {
          addWMSLayers2(map);
        }
      });
    };

    if (map1.current) updateMapStyle(map1.current);
    if (map2.current) updateMapStyle(map2.current);
  };

  const addBuildingsLayer = (map) => {
    if (!map.getSource("buildings")) {
      map.addSource("buildings", {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
      });
    }

    if (!map.getLayer("3d-buildings")) {
      map.addLayer({
        id: "3d-buildings",
        source: "buildings",
        type: "fill-extrusion",
        layout: { visibility: "none" },
        // layout: { visibility: "visible" },
        paint: {
          "fill-extrusion-color": "#242424",
          "fill-extrusion-height": ["get", "build_h"],
          "fill-extrusion-base": 0,
          "fill-extrusion-opacity": 0.8,
        },
      });
    }

    fetchBuildingsInView(map);
  };

  const addCircleToMap = (map) => {
    if (map.getSource("circle-polygon")) {
      map.getSource("circle-polygon").setData(circlePolygon);
      map.getSource("bboxPolygon").setData(bboxPolygon);
    } else {
      map.addSource("circle-polygon", {
        type: "geojson",
        data: circlePolygon,
      });

      map.addLayer({
        id: "circle-polygon-layer",
        type: "fill-extrusion",
        source: "circle-polygon",
        paint: {
          "fill-extrusion-color": "red",
          "fill-extrusion-height": buildingH + 1,
          "fill-extrusion-base": 0,
          "fill-extrusion-opacity": 1,
        },
      });

      map.addSource("bboxPolygon", {
        type: "geojson",
        data: bboxPolygon,
      });

      map.addLayer({
        id: "bboxPolygon-layer",
        type: "line",
        source: "bboxPolygon",
        paint: {
          "line-color": "black",
          "line-width": 2,
          "line-dasharray": [2, 2],
        },
      });
    }
  };

  const addWMSLayers = (map) => {
    map.addSource("wms-test-source", {
      type: "raster",
      tiles: [
        geoserver_url +
          `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&styles=red1&width=256&height=256&LAYERS=${wmsLayerName}&FORMAT=image/png&TILED=true`,
      ],
      tileSize: 256,
    });

    map.addLayer(
      {
        id: "wms-test-layer",
        type: "raster",
        source: "wms-test-source",
        paint: {},
      },
      "circle-polygon-layer",
      "3d-buildings"
    );

    map.setPaintProperty("wms-test-layer", "raster-opacity", 1);
  };

  const addWMSLayers2 = (map) => {
    map.addSource("wms-test-source-2", {
      type: "raster",
      tiles: [
        geoserver_url +
          `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&styles=red1&width=256&height=256&LAYERS=${wmsLayerName2}&FORMAT=image/png&TILED=true&clip=${wkt3857}`,
      ],
      tileSize: 256,
    });

    map.addLayer(
      {
        id: "wms-test-layer-2",
        type: "raster",
        source: "wms-test-source-2",
        paint: {},
      },
      "circle-polygon-layer",
      "3d-buildings"
    );

    map.setPaintProperty("wms-test-layer-2", "raster-opacity", 1);
  };

  const syncMaps = (sourceMap, targetMap) => {
    if (isSyncing.current) return;
    isSyncing.current = true;

    const center = sourceMap.getCenter();
    const zoom = sourceMap.getZoom();
    const bearing = sourceMap.getBearing();
    const pitch = sourceMap.getPitch();

    targetMap.jumpTo({ center, zoom, bearing, pitch });
    requestAnimationFrame(() => {
      isSyncing.current = false;
    });
  };

  useEffect(() => {
    let center;
    try {
      center = turf.center(report?.product?.calculated_geometry).geometry
        .coordinates;
    } catch (error) {
      console.error("Error calculating center:", error);
      return;
    }

    if (!center || center.length !== 2 || center.some(isNaN)) {
      console.error("Invalid center coordinates:", center);
      return;
    }

    const bbox = turf.bbox(report?.product?.bbox_b100);
    const product_bbox = [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ];

    const initializeMap = (
      mapContainer,
      mapRef,
      setIsMapLoading,
      addWMSLayersFn
    ) => {
      if (mapContainer.current && !mapRef.current) {
        mapRef.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: "mapbox://styles/wayne32545/cluhyupep02qz01oi7lj73arv",
          center: center,
          pitch: 50,
          dragPan: false,
          scrollZoom: false,
          touchZoomRotate: false,
          dragRotate: false, // Disable bearing control
          pitchWithRotate: false, // Disable pitch control
          transformRequest: (url, resourceType) => {
            if (
              (resourceType === "Source" || resourceType === "Tile") &&
              url.indexOf(geoserver_url + `geoserver/staging/wms`) > -1
            ) {
              const credentials = btoa(`${gs_username}:${gs_pass}`);
              const headers = { Authorization: `Basic ${credentials}` };
              return { url, headers, credentials: "include" };
            }
          },
          zoom: 14,
          minZoom: 13,
        });

        mapRef.current.on("load", () => {
          setIsMapLoading(false);
          addCircleToMap(mapRef.current);
          addBuildingsLayer(mapRef.current);
          addWMSLayersFn(mapRef.current);
          mapRef.current.fitBounds(product_bbox, { padding: 50, pitch: 50 });
          setMapsReady((prev) => ({
            ...prev,
            [mapRef === map1 ? "map1" : "map2"]: true,
          }));
        });
      }
    };

    initializeMap(mapContainer1, map1, setIsMap1Loading, addWMSLayers);
    initializeMap(mapContainer2, map2, setIsMap2Loading, addWMSLayers2);

    return () => {
      if (map1.current) {
        map1.current.remove();
        map1.current = null;
      }
      if (map2.current) {
        map2.current.remove();
        map2.current = null;
      }
    };
  }, [report]);

  useEffect(() => {
    if (mapsReady.map1 && mapsReady.map2) {
      map1.current.on("move", () => syncMaps(map1.current, map2.current));
      map2.current.on("move", () => syncMaps(map2.current, map1.current));
    }
  }, [mapsReady]);

  const fetchBuildingsInView = (map) => {
    const bounds = map.getBounds();
    setIsLoading(true);

    axios
      .get(`${geoserver_url}geoserver/staging/ows`, {
        params: {
          service: "WFS",
          version: "1.0.0",
          request: "GetFeature",
          typeName: `staging:buildings_all3857_s`,
          bbox: `${bounds.getWest()},${bounds.getSouth()},${bounds.getEast()},${bounds.getNorth()}`,
          outputFormat: "application/json",
        },
        auth: {
          username: gs_username,
          password: gs_pass,
        },
      })
      .then((response) => {
        const data = response.data;
        data.features.forEach((feature) => {
          feature.id = feature.properties.uid;
        });
        map.getSource("buildings").setData(data);
        clickedBuildingIds.forEach((id) => {
          map.setFeatureState({ source: "buildings", id }, { clicked: true });
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the GeoServer data:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const fetchLegendImage = async (legendRequestUrl) => {
      try {
        const response = await axios.get(legendRequestUrl, {
          responseType: "blob",
          auth: { username: gs_username, password: gs_pass },
        });

        const imageUrl = URL.createObjectURL(response.data);
        setLegendUrl(imageUrl);
      } catch (error) {
        console.error("Error fetching legend image:", error);
        toast.error("無法加載圖例。");
      }
    };

    if (detailOrder?.wms_layer_name) {
      const legendRequestUrl = `${geoserver_url}geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&LAYER=${detailOrder.wms_layer_name}&STYLE=${currentStyle}`;
      fetchLegendImage(legendRequestUrl);
    }
  }, [detailOrder, geoserver_url, gs_username, gs_pass, currentStyle]);

  class SwitchStyleControl {
    constructor(maps, updateStyleAndFetchLegend) {
      this.maps = maps; // Expecting an array of map references
      this.updateStyleAndFetchLegend = updateStyleAndFetchLegend;
    }

    onAdd() {
      this.container = document.createElement("div");
      this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
      this.button = document.createElement("button");
      this.button.textContent = "換圖";
      this.button.style.fontWeight = "bold";
      this.button.onclick = () => this.switchWMSStyle();
      this.container.appendChild(this.button);
      return this.container;
    }

    switchWMSStyle() {
      // Define newStyle outside the forEach loop
      let newStyle = this.maps[0].current
        .getSource("wms-test-source")
        .tiles[0].includes("branch_4_discrete_1_2023")
        ? "red1"
        : "branch_4_discrete_1_2023";

      this.maps.forEach((mapRef) => {
        if (!mapRef.current) return; // Skip if the map reference is not valid
        const map = mapRef.current;
        ["wms-test-source", "wms-test-source-2"].forEach((sourceId) => {
          const source = map.getSource(sourceId);
          if (!source) return;

          const currentTiles = source.tiles[0];
          const newTiles = currentTiles.replace(
            /styles=branch_4_discrete_1_2023|styles=red1/,
            `styles=${newStyle}`
          );

          source.setTiles([newTiles]);
          map.triggerRepaint();
        });
      });

      // newStyle is now accessible here
      this.updateStyleAndFetchLegend(newStyle);
    }

    onRemove() {
      this.container.parentNode.removeChild(this.container);
    }
  }

  class ToggleBuildingsControl {
    onAdd(map) {
      this.map = map;
      this.container = document.createElement("div");
      this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
      this.button = document.createElement("button");
      this.button.textContent = "建物";
      this.button.style.fontWeight = "bold";
      this.button.onclick = () => {
        const toggleBuildings = (map) => {
          let visibility = map.getLayoutProperty("3d-buildings", "visibility");
          if (visibility === "visible") {
            map.setLayoutProperty("3d-buildings", "visibility", "none");
          } else {
            map.setLayoutProperty("3d-buildings", "visibility", "visible");
          }
        };

        toggleBuildings(map1.current);
        toggleBuildings(map2.current);
      };

      this.container.appendChild(this.button);
      return this.container;
    }

    onRemove() {
      this.container.parentNode.removeChild(this.container);
      this.map = undefined;
    }
  }

  class BearingControl {
    constructor(rotationStep = 45) {
      this.rotationStep = rotationStep;
      this.currentBearing = 0; // Start from 0 degree
    }

    onAdd(map) {
      this.map = map;
      this.container = document.createElement("div");
      this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

      this.button = document.createElement("button");
      this.button.textContent = "旋轉";
      this.button.style.fontWeight = "bold";
      this.button.onclick = this.rotateBearing.bind(this); // Bind to class instance

      this.container.appendChild(this.button);
      return this.container;
    }

    rotateBearing() {
      // Update bearing by the specified rotation step
      this.currentBearing = (this.currentBearing + this.rotationStep) % 360;

      // Apply the bearing change to both maps
      map1.current.easeTo({ bearing: this.currentBearing });
      map2.current.easeTo({ bearing: this.currentBearing });
    }

    onRemove() {
      this.container.parentNode.removeChild(this.container);
      this.map = undefined;
    }
  }

  class Toggle3DControl {
    constructor(pitchAngle = 50) {
      this.pitchAngle = pitchAngle;
      this.is3D = true;
    }

    onAdd(map) {
      this.map = map;
      this.container = document.createElement("div");
      this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
      this.button = document.createElement("button");
      this.updateButtonText();
      this.button.onclick = () => {
        this.togglePitch();
      };
      this.container.appendChild(this.button);
      return this.container;
    }

    togglePitch() {
      if (this.is3D) {
        this.map.easeTo({ pitch: 0 });
        this.is3D = false;
      } else {
        this.map.easeTo({ pitch: this.pitchAngle });
        this.is3D = true;
      }
      this.updateButtonText();
    }

    updateButtonText() {
      this.button.textContent = this.is3D ? "2D" : "3D";
    }

    onRemove() {
      this.container.parentNode.removeChild(this.container);
      this.map = undefined;
    }
  }

  useEffect(() => {
    if (mapsReady.map1 && mapsReady.map2) {
      const maps = [map1, map2];

      const toggleBuildingsControl = new ToggleBuildingsControl();
      map1.current.addControl(toggleBuildingsControl, "top-left");

      // Add the bearing control to both maps
      const bearingControl = new BearingControl(45); // 45 degree rotation per click
      map1.current.addControl(bearingControl, "top-left");

      const toggle3DControl = new Toggle3DControl();
      map1.current.addControl(toggle3DControl, "top-left");

      const switchStyleControl = new SwitchStyleControl(
        maps,
        updateStyleAndFetchLegend
      );
      map1.current.addControl(switchStyleControl, "top-left");

      map2.current.addControl(new mapboxgl.NavigationControl(), "top-right");
      // map2.current.addControl(new mapboxgl.FullscreenControl(), "top-right");
      const fullscreenControl = new mapboxgl.FullscreenControl({
        container: containerRef.current, // Pass the container reference here
      });

      map2.current.addControl(fullscreenControl, "top-right");

      map1.current.on("move", () => syncMaps(map1.current, map2.current));
      map2.current.on("move", () => syncMaps(map2.current, map1.current));
    }
  }, [mapsReady]);

  return (
    <div
      style={{
        width: "100%",
        // height: "50vh",
        height: "85vh",
        marginTop: "10px",
        position: "relative",
        display: "flex",
        gap: "5px",
        marginBottom: "5px",
      }}
    >
      <div
        style={{
          width: "50%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: "10px",
            background: "#efefef",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          公開資料情境
        </div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div
            ref={mapContainer1}
            style={{
              flexGrow: 1,
            }}
          />
        )}
      </div>

      <div
        style={{
          width: "50%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: "10px",
            background: "#efefef",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          本公司分析
        </div>
        <div
          ref={containerRef}
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
                height: "100%",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              ref={mapContainer2}
              style={{
                flexGrow: 1,
                height: "100%",
              }}
            />
          )}

          <div
            className="legend-container"
            style={{
              position: "absolute",
              bottom: "10px",
              right: "10px",
              border: "2px solid",
              borderRadius: "4px",
              zIndex: 2,
            }}
          >
            {legendUrl && <img src={legendUrl} alt="Legend" />}
          </div>
        </div>
        <div
          id="menu"
          style={{
            position: "absolute",
            top: 50,
            left: "50%",
            transform: "translateX(-50%)",
            background: "#efefef",
            padding: "5px",
            zIndex: 1,
            border: "solid 1px",
            borderRadius: "4px",
          }}
        >
          <input
            id="satellite-streets-v12"
            type="radio"
            name="rtoggle"
            value="satellite-streets-v12"
            onChange={() => switchBaseMap("satellite-streets-v12")}
          />
          <label
            htmlFor="satellite-streets-v12"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            衛星
          </label>
          <input
            id="custom-style"
            type="radio"
            name="rtoggle"
            value="mapbox://styles/wayne32545/cluhyupep02qz01oi7lj73arv"
            defaultChecked
            onChange={() =>
              switchBaseMap(
                "mapbox://styles/wayne32545/cluhyupep02qz01oi7lj73arv"
              )
            }
          />
          <label
            htmlFor="custom-style"
            style={{ margin: "0 5px", fontWeight: "bold" }}
          >
            街道
          </label>
        </div>
      </div>
    </div>
  );
};

export default FloodMapboxSyncMaps;
