import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import GooglePlacesAutocomplete from "./GooglePlacesAutocomplete";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MapComponent = () => {
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false); // New state to track map loaded status
  const [queuedPlace, setQueuedPlace] = useState(null);

  useEffect(() => {
    if (mapContainerRef.current && !map) {
      // This ensures the map initializes only once
      const newMap = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [-74.006, 40.7128],
        zoom: 9,
      });

      newMap.on("load", () => {
        setMap(newMap);
        setIsMapLoaded(true); // Set map as loaded once the 'load' event fires
      });
    }
  }, []); // Removed 'map' from dependencies to avoid re-initializing the map

  useEffect(() => {
    if (isMapLoaded && queuedPlace) {
      // Call the logic to handle the place selection now that the map is loaded
      handlePlaceSelected(queuedPlace);
      setQueuedPlace(null); // Clear the queued place after handling it
    }
  }, [isMapLoaded, queuedPlace]); // Depend on both isMapLoaded and queuedPlace

  const handlePlaceSelected = (place) => {
    console.log("Selected place:", place);

    if (!isMapLoaded) {
      console.error("Map is not initialized. Queuing place selection.");
      setQueuedPlace(place); // Queue the place for when the map is loaded
      return;
    }

    if (place.geometry && place.geometry.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      map.flyTo({
        center: [lng, lat],
        zoom: 15,
      });

      new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map);
    } else {
      console.error("Selected place does not have a location.");
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <GooglePlacesAutocomplete
        onPlaceSelected={handlePlaceSelected}
        isMapLoaded={isMapLoaded}
      />

      <div ref={mapContainerRef} style={{ height: "400px" }}></div>
    </div>
  );
};

export default MapComponent;
