import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { useNavigate } from "react-router-dom";
import "./Map.scss";
import { centerOfMass } from "@turf/turf";
import * as turf from "@turf/turf";

import { stringify } from "wkt";

const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = mapboxtoken;

// need radius, location, goejson, wms_layer_name, polygon_3857

const DashboardMap = ({ product }) => {
  const [map, setMap] = useState(null);
  const navigate = useNavigate();
  const mapContainer = useRef(null);

  useEffect(() => {
    if (!product || Object.keys(product).length === 0) {
      // console.log("no order yet");
      return; // exit if there is no data yet
    }

    // Assuming calculated_geometry is in GeoJSON format
    const geometry = product?.calculated_geometry;
    const bbox_b10 = product?.bbox_b10;
    // Calculate the center of the polygon
    const polygonCenter = centerOfMass(geometry).geometry.coordinates;

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: polygonCenter,
      zoom: 14,
      minZoom: 14,
      // maxZoom: 15.5,
      // minZoom: 15.5,
      dragPan: false,
    });

    // Use Turf.js to calculate the center and bbox
    const bbox = turf.bbox(bbox_b10);
    // const bbox = turf.bbox(detailOrder?.calculated_geometry);
    const product_bbox = [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ];

    // Fit the map to the bbox extent
    map.fitBounds(product_bbox, { padding: 100 });

    // map.on("style.load", () => {
    map.on("load", () => {
      map.addSource("polygon", {
        type: "geojson",
        data: geometry,
      });

      map.addLayer({
        id: "polygon-layer",
        type: "fill",
        source: "polygon",
        paint: {
          "fill-color": "transparent",
          //   "fill-opacity": 0.5,
        },
      });

      map.addLayer({
        id: "polygon-border-layer",
        type: "line",
        source: "polygon",
        paint: {
          "line-color": "red",
          "line-width": 2,
          // "line-dasharray": [2, 2],
        },
      });
      map.addSource("bbox_b10", {
        type: "geojson",
        data: bbox_b10,
      });

      map.addLayer({
        id: "bbox_b10-layer",
        type: "fill",
        source: "bbox_b10",
        paint: {
          "fill-color": "transparent",
          //   "fill-opacity": 0.5,
        },
      });

      map.addLayer({
        id: "bbox_b10-border-layer",
        type: "line",
        source: "bbox_b10",
        paint: {
          "line-color": "black",
          "line-width": 2,
          "line-dasharray": [2, 2],
        },
      });
    });

    setMap(map);

    return () => {
      map.remove();
    };
  }, [product]);

  if (!product || Object.keys(product).length === 0) {
    return <div>Loading...</div>; // display loading message until the data is available
  }

  return (
    <div
      ref={mapContainer}
      style={{ height: "100%", width: "100%", minHeight: "250px" }}
    />
  );
};

export default DashboardMap;
