import "./PriceCard.scss";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../axios";

const PriceFromEcpay = ({ type, pointPackage }) => {
  const postEcpayForm = async (pointPackageId) => {
    try {
      const response = await axiosInstance.post(
        `/shop/buy_points/${pointPackageId}/`
      );
      const parser = new DOMParser();
      const doc = parser.parseFromString(response.data, "text/html");
      const form = doc.getElementById("data_set");
      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="price-card-container">
      <div className="priceContainer">
        $
        <span className="price">
          {isNaN(pointPackage?.price) ? " " : Math.floor(pointPackage?.price)}
        </span>
        TWD
      </div>
      <button className="type">{type} Plan</button>
      <ul className="list">
        <li className="listItem">{pointPackage?.points}點會員點數</li>
        <li className="listItem">專屬分析報告</li>
        {/* <li className="listItem">分享點數功能</li> */}
      </ul>
      <button
        className="price-card-button"
        onClick={() => postEcpayForm(pointPackage?.pkid)}
      >
        立即購買
      </button>
    </div>
  );
};

export default PriceFromEcpay;
