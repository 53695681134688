import React from "react";
// import "../../app.scss";
import "./Report.scss";

const Report = () => {
  return (
    <div>
      <div className="container report_container d-flex flex-wrap position-relative">
        <div className="title flex-col align-items-center position-relative w-100 mt-150">
          {/* <div >SAFEHOUSING</div> */}
          <div className="report-h0">SAFEHOUSING</div>
          {/* <div className="h0">SAFEHOUSING</div> */}
          <h3 className="text-center w-100">專屬報告</h3>
          <hr className="m-0" />
        </div>
        <div
          className="col-12 col-md-4 pe-0 pe-md-3 pe-lg-0 mt-5 position-relative"
          style={{ height: "fit-content" }}
        >
          <i
            className="fas fa-map-marker-alt position-absolute center"
            style={{ fontSize: "40px", color: "black" }}
          ></i>
          <img
            className="rounded-circle w-100"
            src="/img/report_map.jpg"
            alt=""
          />
        </div>
        <div className="col-lg-1"></div>
        <div className="col-12 col-md-8 col-lg-7 ps-0 ps-md-3 ps-lg-0 mt-5">
          <h5 className="primary_color pb-2 mb-3 mb-0">情境與結果說明</h5>
          <p className="mb-0">
            您選擇的降雨情境為6小時350mm降雨，依據統計結果，約等於平均每25年會發生一次的大雨情境。
            <br />
            您選擇的點位(121.235, 24.665)
            在降雨情境下之高精度淹水模擬結果之淹水高度為0.3公尺，為中高淹水潛勢地區。
          </p>
          <h5 className="primary_color pb-2 mb-3 mb-0 mt-5">安家建議</h5>
          <p className="mb-0">
            你所選擇區域的周邊道路在此降雨情境下多處淹水50公分以上，豪雨期間建議避開此路段，並注意停車位置使否在高淹水潛勢地區，以便在降雨前期提早移動車輛，若為地下停車場，應安裝並升起起擋水閘門。
            <br />
            此位置地勢相對低漥，建議工廠或是住宅須將基地墊高，或是安裝擋水閘門，亦可購買相關淹水保險以降低淹水損失。
          </p>
          <h5 className="primary_color pb-2 mb-3 mb-0 mt-5">其他說明</h5>
          <p className="mb-0">
            地形高程、水工結構物、排水管線、降雨情境、土地利用(入滲、粗糙度)。
          </p>
          <h5 className="primary_color pb-2 mb-3 mb-0 mt-5">模擬資料</h5>
          <p className="mb-0">
            淹水潛勢圖製作係依據各縣（市）自然環境資料、過去災害發生狀況及相
            關研究資料，利用淹水模式進行災害潛勢模擬及評估，製作成各縣（市）淹水潛
            勢範圍、規模之防災參考用圖。本圖資採用基本假設為定量降雨情境下各地區可能發生之最大淹水深度。模擬條件中假設水庫與防洪設施正常操作，無設施破壞，並且堤防無溢堤與下游無暴潮。
            <br />
            淹水潛勢圖係基於設計降雨條件及特定年份地形資料下，運用客觀水里模式經數值模擬演算而得。因氣象及水文條件俱不確定性，故本圖資無法模擬未來單一颱洪事件，參考飲用時請特別留意此情形。
          </p>
          <h5 className="primary_color pb-2 mb-3 mb-0 mt-5">用途限制</h5>
          <p className="mb-0">僅供購買用戶之防救災風險評估使用、不得轉載。</p>
          <h5 className="primary_color pb-2 mb-3 mb-0 mt-5">模型參數說明</h5>
          <p className="mb-0">
            水理模式: 3Di模式
            <br />
            <br />
            水文條件: 依據用戶購買種類，可為分為
            <br />
            1.
            水利署第三代淹水潛勢圖情境，使用Horner設計降雨、使用歷年7-10月大潮平均高低潮位歷線。
            <br />
            2.
            參考政府公開之台灣地區雨量測站降雨強度-延時Horner公式參數分析報告，桃園地區選擇降雨尖峰最大的八德站作為代表製作重現情境。
            <br />
            <br />
            地文條件:
            模型建置數值高程模型(DEM)採用內政部20公尺網格數值地形模型資料並依據模型範圍進行OSM資料高精度轉換處理(0.5m
            ~
            2m)。土地利用參考OSM公開資料、內政部國土測繪中心WMS資料、Google衛星影像圖、Google街景圖。地表粗糙度可藉由土地利用分類地圖與各分類土地利用之粗糙度進行粗糙度係數地圖之產製。此外，若能持續細分建築線內，可以設定高粗糙度係數(例如，曼寧n值
            =
            1)來模擬建築物的阻礙效應，其中建築線可藉由1/1,000地形圖或正射影像或手動方式將建築線尋找出來、部分政府有開放的建築物資料，亦可直接使用OSM公開資料。本計畫所採用的土地利用分類地圖參考OSM公開資料、內政部國土測繪中心WMS資料、Google衛星影像圖、Google街景圖。
            <br />
            <br />
            雨水下水道與水工結構物:
            雨水下水道、人孔、閘門與抽水站等各項設施之參數資料來源為桃園市全區雨水下水道管線分布圖、桃園市全區雨水下水道人孔位置分佈圖、OSM資料、google衛星影響、google街景等方式。
          </p>
        </div>
        <div className="d-flex justify-content-center vw-100 mt-5">
          <button className="primary_bg white border-0 p-2 ps-3 pe-3 p-lg-3 ps-lg-5 pe-lg-5">
            <h5 className="border-0 mb-0">購買報告</h5>
          </button>
        </div>
      </div>

      <div className="city_bg bg vw-100 position-relative">
        <img className="w-100" src="/img/city-l.png" alt="" />
      </div>
    </div>
  );
};

export default Report;
