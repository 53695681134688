// src/features/waterProbData/waterProbDataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  center: [121.30128, 24.99265], // Default center
  zoom: 16, // Default zoom level
  pitch: 0, // Default pitch
  bearing: 0, // Default bearing
};

export const mapStateSlice = createSlice({
  name: "mapState",
  initialState,
  reducers: {
    setMapState: (state, action) => {
      const { center, zoom, pitch, bearing } = action.payload;
      state.center = center;
      state.zoom = zoom;
      state.pitch = pitch;
      state.bearing = bearing;
    },
  },
});

export const { setMapState } = mapStateSlice.actions;

export default mapStateSlice.reducer;
