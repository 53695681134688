import React, { useEffect, useState } from "react";
// import { Container } from "@mui/material";
// import { useSelector } from "react-redux";
import RiskMapClipLiq from "../../components/RiskMapAll/RiskMapClipLiq";
import InclinedBuildings from "../../components/RiskMapAll/InclinedBuildings";
import axiosInstance from "../../axios";
import LiqPrepare from "./LiqPrepare";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Questionnaire from "./Questionnaire";
import "./LiqResult_v2.scss";

const LiqResult = ({ report, productTargetInfo }) => {
  // const polygonData = useSelector((state) => state.polygonData.polygonData);
  const polygonData = report?.product?.geo_data;
  const [liqProb, setLiqProb] = useState({});
  const [loading, setLoading] = useState(false);

  // console.log("polygonData:", polygonData);

  const riskfrombuildingtime =
    productTargetInfo?.["buildingTime"] > 1999 ? "低" : "高";

  // Define initial state for checkboxes (false means unchecked)
  const [checkboxStates, setCheckboxStates] = useState({
    q1: false,
    q2: false,
    q3: false,
    q4: false,
    q5: false,
    q6: false,
    q7: false,
  });

  const liq_result = report.risk_results["liguefaction"];

  const buildingTime = productTargetInfo?.["buildingTime"];
  let riskMessage = "";

  if (buildingTime >= 1999) {
    riskMessage = `本建物建造時間為${buildingTime}年，採用的建築物耐震設計規範已有考慮土壤液化的影響，因此可能發生的傾斜角度極小。`;
  } else {
    riskMessage = `本建物建造時間為${buildingTime}年，未採用的建築物耐震設計規範，因此本建物在發生建物設計地震的震度以上時，可能發生${liq_result[6][1]}度的傾斜。
`;
  }

  // Handler for checkbox state changes
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxStates((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      if (polygonData) {
        setLoading(true);
        try {
          const formData = {
            geo_data: polygonData,
          };
          const response = await axiosInstance.post(
            `risk/liq-prob/`,
            formData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const data = response.data.result;

          setLiqProb(data);
          // console.log("get liq analysis done:", data);
        } catch (error) {
          console.error("Error getting liq:", error);
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [polygonData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!liqProb) {
    return <div>No liquefaction probability data available.</div>;
  }

  return (
    <div>
      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            土壤液化潛勢風險分析(old)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ padding: "0px 20px" }}>
            <ul style={{ fontSize: "14px", fontWeight: "bold" }}>
              <li>
                土壤液化除所在位置的本身岩土性質外，地下水高度也會影響是否容易有土壤液化現象，地下水為越高，土壤液化機率越高；反之，土壤液化機率降低。
              </li>
              <li>
                每年發生土壤液化的機率:{" "}
                {liqProb?.a ? liqProb.a.toFixed(3) : "N/A"}
                (%)， 未來50年發生土壤液化的機率:{" "}
                {liqProb?.b ? liqProb.b.toFixed(3) : "N/A"} (%)
              </li>
              <li>
                每年發生土壤液化的工程損失:
                {report.risk_results.liguefaction[2][0] -
                  report.risk_results.liguefaction[2][1]}
                ， 每年發生土壤液化的非工程損失:
                {report.risk_results.liguefaction[3][0] -
                  report.risk_results.liguefaction[3][1]}
              </li>
              <li>
                發生土壤液化年期望損失
                {report.risk_results.liguefaction[5][0] -
                  report.risk_results.liguefaction[5][1]}
                元/年，其中搬遷費用 、房租、營業損失未考慮
              </li>

              <li>
                依據經濟部地質調查及礦業管理中心資料，本建物所在位置土壤液化潛勢屬
                {liq_result[0]}潛勢。同時考量地震的影響，在本建物分級屬
                {liq_result[1]}級
              </li>
              <li>
                1999年12月29日修正的建築物耐震設計規範，以要求建築物設計時須進行液化潛能評估並納入耐震設計中。本建物於
                {productTargetInfo?.["buildingTime"]}
                年完工，因此建物的受液化的影響程度{riskfrombuildingtime}
              </li>
              <li>
                土壤液化潛勢圖呈現的潛勢，是根據建築法規的規範，以鑽探得到的地下地質資訊，加上地下水位的資料，計算在設計地震強度下，各地可能發生土壤液化的輕重程度。每個潛勢所代表的意義：
              </li>
              <li>低潛勢(綠)：設計地震發生時，地基可能無影響或輕微影響。</li>
              <li>中潛勢(黃)：設計地震發生時，地基可能輕微影響至中度影響。</li>
              <li>高潛勢(紅)：設計地震發生時，地基可能中度至嚴重影響。</li>
              <li>
                臺北市政府公開的土壤液化潛勢圖分為4級，圖資顯示在5~
                6級震度下，土壤發生液化的機率及嚴重程度分級，並以4種顏色作為判別：紅色區具有相對較高的土壤液化風險，強烈地震發生時，地基可能嚴重影響。黃色區具有相對次高的土壤液化風險，強烈地震發生時，地基可能中度影響。綠色區具有相對較低的土壤液化風險，強烈地震發生時，地基可能輕微影響。
              </li>
              <li>
                [註]設計地震為土壤液化評估所採用之地震設計，依建築法規選擇發生週期為每
                475年發生一次最大震度的地震，各地區地震造成的地表加速度值因地震之震源位置、震源機制、傳播路徑及基地之地盤性質等改變，而略有不同，一般約為
                0.24 g – 0.4 g。如臺北所採用之加速度值為 0.24
                g，屬於5級震度，宜蘭為 0.32 g ，屬於6級震度。
              </li>
            </ul>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              alignItems: "center",
              margin: "10px",
            }}
          >
            <img
              style={{
                width: "500px", // Specify your desired width
                maxWidth: "90%", // Use a percentage to maintain responsiveness
                height: "auto", // This ensures the aspect ratio is maintained
              }}
              src="/img/liq1.png"
              alt=""
            />
            <p style={{ marginTop: "10px", fontSize: "0.8rem", color: "#666" }}>
              資料來源: 經濟部地質調查及礦業管理中心
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              alignItems: "center",
              margin: "10px",
            }}
          >
            <img
              style={{
                width: "500px", // Specify your desired width
                maxWidth: "90%", // Use a percentage to maintain responsiveness
                height: "auto", // This ensures the aspect ratio is maintained
              }}
              src="/img/liq2.png"
              alt=""
            />
            <p style={{ marginTop: "10px", fontSize: "0.8rem", color: "#666" }}>
              資料來源: 經濟部地質調查及礦業管理中心
            </p>
          </div>
        </AccordionDetails>
      </Accordion> */}

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            土壤液化潛勢風險分析
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ padding: "0px 20px" }}>
            <RiskMapClipLiq report={report} />
            <ul style={{ fontSize: "14px", fontWeight: "bold" }}>
              <li>
                土壤液化除所在位置的本身岩土性質外，地下水高度也會影響是否容易有土壤液化現象，地下水為越高，土壤液化機率越高；反之，土壤液化機率降低。
              </li>
              <li>
                土壤液化潛勢圖呈現的潛勢，是根據建築法規的規範，以鑽探得到的地下地質資訊，加上地下水位的資料，計算在設計地震強度下，各地可能發生土壤液化的輕重程度。每個潛勢所代表的意義：
              </li>
              <li>低潛勢(綠)：設計地震發生時，地基可能無影響或輕微影響。</li>
              <li>中潛勢(黃)：設計地震發生時，地基可能輕微影響至中度影響。</li>
              <li>高潛勢(紅)：設計地震發生時，地基可能中度至嚴重影響。</li>
              <li>
                依據經濟部地質調查及礦業管理中心資料，本建物所在位置土壤液化潛勢屬
                {liq_result[0]}潛勢。同時考量地震的影響，在本建物分級屬
                {liq_result[1]}級
              </li>

              <li>
                本建物的基礎為{productTargetInfo?.["buildingFoundation"]}
                ，建造時間為{productTargetInfo?.["buildingTime"]}
                年，可能需要注意土壤液化對建物的影響，建議可進一步找土木技師工會進行專業判斷或自行調查判斷(可參考下方土壤液化潛勢區建築物簡易自主檢查表)。
              </li>
              <li>
                同時考量地震的影響， 每年發生土壤液化的機率:{" "}
                {liqProb?.a ? liqProb.a.toFixed(3) : "N/A"}
                (%)， 未來50年發生土壤液化的機率:{" "}
                {liqProb?.b ? liqProb.b.toFixed(3) : "N/A"} (%)
              </li>
            </ul>
            <br />
            <br />
            <ul
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                listStyle: "none",
              }}
            >
              <li>[註:]</li>
              <li>
                1.
                1999年12月29日修正的建築物耐震設計規範，已以要求建築物設計時須進行液化潛能評估並納入耐震設計中
              </li>
              <li>
                2.設計地震為土壤液化評估所採用之地震設計，依建築法規選擇發生週期為每
                475年發生一次最大震度的地震，各地區地震造成的地表加速度值因地震之震源位置、震源機制、傳播路徑及基地之地盤性質等改變，而略有不同，一般約為
                0.24 g – 0.4 g。如臺北所採用之加速度值為 0.24
                g，屬於5級震度，宜蘭為 0.32 g ，屬於6級震度。
              </li>
            </ul>
            <br></br>
            <Questionnaire />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            建築的設計地震，傾斜角度估計
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            1999年12月29日修正的建築物耐震設計規範，已要求建築物設計時須進行液化潛能評估並納入耐震設計中。
            {riskMessage}
          </Typography>
          <div className="tilt-table">
            {/* <h3>傾斜的房屋：</h3> */}
            <p>
              傾斜的房屋藏有潛在的安全疑慮與價值損失，以下為不同傾斜程度的評估與修復建議。
            </p>
            <table>
              <thead>
                <tr>
                  <th>傾斜率</th>
                  <th>風險評估</th>
                  <th>修復建議</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&lt;1/200</td>
                  <td>安全</td>
                  <td>修復</td>
                </tr>
                <tr>
                  <td>1/200~1/40</td>
                  <td>安全疑慮</td>
                  <td>修復或補強</td>
                </tr>
                <tr>
                  <td>&gt;1/40</td>
                  <td>明顯安全疑慮</td>
                  <td>重建</td>
                </tr>
              </tbody>
            </table>

            <p>
              一般情況下，建物傾斜標準如下表，但人可能受到土壤條件、地基與風壓等因素影響。
            </p>

            <table>
              <thead>
                <tr>
                  <th>結構類型</th>
                  <th>允許傾斜度</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>木造</td>
                  <td>限高兩層:1/150，限高三層:1/100</td>
                </tr>
                <tr>
                  <td>鋼骨造</td>
                  <td>1/300</td>
                </tr>
                <tr>
                  <td>磚造</td>
                  <td>1/200</td>
                </tr>
                <tr>
                  <td>混凝土造</td>
                  <td>1/600</td>
                </tr>
              </tbody>
            </table>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            每年平均損失與建物重建成本
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            <ul>
              <li>
                每年發生土壤液化的機率:{" "}
                {liqProb?.a ? liqProb.a.toFixed(3) : "N/A"}
                (%)， 未來50年發生土壤液化的機率:{" "}
                {liqProb?.b ? liqProb.b.toFixed(3) : "N/A"} (%)
              </li>
              <li>
                每年發生土壤液化的工程損失:
                {report.risk_results.liguefaction[2][0] -
                  report.risk_results.liguefaction[2][1]}
                ， 每年發生土壤液化的非工程損失:
                {report.risk_results.liguefaction[3][0] -
                  report.risk_results.liguefaction[3][1]}
              </li>
              <li>
                發生土壤液化年期望損失
                {report.risk_results.liguefaction[5][0] -
                  report.risk_results.liguefaction[5][1]}
                元/年，其中搬遷費用 、房租、營業損失未考慮。
              </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            保險
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            土壤液化為地震災害的一種，建議可以依據需求增加地震保險的保額
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            土壤液化防制方法
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LiqPrepare />
          <InclinedBuildings />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default LiqResult;
