import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import mapboxgl from "mapbox-gl";

import "./RiskMapAll.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setTargetInfo, resetTargetInfo } from "../../redux/targetInfoReducer";
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import DrawPolygonMapAll from "./DrawPolygonMapAll";
import ClickPolygonMapAll from "./ClickPolygonMapAll";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
// import DrawIcon from '@mui/icons-material/Draw';
import ModeIcon from "@mui/icons-material/Mode";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MdDraw } from "react-icons/md";
import { FaDrawPolygon } from "react-icons/fa";

const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = mapboxtoken;

const BuildingRiskAll = ({ report }) => {
  const dispatch = useDispatch();
  const [pkid, setPkid] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const targetInfo = useSelector((state) => state.targetInfo);

  const detailOrder = report.product;

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const CustomTab = ({ label, icon }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        color: "black",
        fontWeight: "bold",
      }}
    >
      {icon}
      {!isMobile && <span style={{ marginLeft: 8 }}>{label}</span>}
    </div>
  );

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleBuildingTypeChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "buildingType",
        value: parseFloat(event.target.value),
      })
    );
  };
  const handleBuildingTimeChange = (event) => {
    // // Parse the input value as an integer, default to 0 if not a number
    let inputTime = parseInt(event.target.value) || 0;
    // // If inputTime is negative, reset it to
    inputTime = Math.max(inputTime, 0);

    dispatch(
      setTargetInfo({
        field: "buildingTime",
        // value: parseFloat(event.target.value),
        value: parseFloat(inputTime),
      })
    );
  };
  // const handleBuildingMaterialChange = (event) => {
  //   dispatch(
  //     setTargetInfo({
  //       field: "buildingMaterial",
  //       value: parses(event.target.value),
  //     })
  //   );
  // };
  const handleBuildingMaterialChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "buildingMaterial",
        value: event.target.value, // No need to parse here since it's a string
      })
    );
  };

  const handleTotalfloorNumberChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "totalFloorNumber",
        value: parseFloat(event.target.value),
      })
    );
  };
  const handleFloorNumberChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "floorNumber",
        value: parseFloat(event.target.value),
      })
    );
  };
  const handleDesignPgaChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "designPga",
        value: parseFloat(event.target.value),
      })
    );
  };
  const handleMaskingHeightChange = (event) => {
    // Parse the input value as an integer, default to 0 if not a number
    let inputHeight = parseInt(event.target.value) || 0;
    // If inputHeight is negative, reset it to 0
    inputHeight = Math.max(inputHeight, 0);

    dispatch(
      setTargetInfo({
        field: "maskingHeight",
        value: parseFloat(inputHeight),
      })
    );
  };
  const handleBuildingValueChange = (event) => {
    // Parse the input value as an integer, default to 0 if not a number
    let inputValue = parseInt(event.target.value) || 0;
    // If inputHeight is negative, reset it to 0
    inputValue = Math.max(inputValue, 0);
    dispatch(
      setTargetInfo({
        field: "buildingValue",
        value: parseFloat(inputValue),
      })
    );
  };
  const handleBusinessLossChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "businessLoss",
        value: parseFloat(event.target.value),
      })
    );
  };
  const handleCarValueChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "carValue",
        value: parseFloat(event.target.value),
      })
    );
  };
  const handleFurnitureLossChange = (event) => {
    dispatch(
      setTargetInfo({
        field: "furnitureLoss",
        value: parseFloat(event.target.value),
      })
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // height: "110vh",
        // padding: "10px",
        position: "relative",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <div>
          <ul
            className="content-list"
            style={{ listStyleType: "disc", fontWeight: "bold" }}
          >
            {/* <li>步驟:</li> */}
            <li>1. 請填入分析建物之基本資料</li>
            <li>
              2. 請在地圖上選擇建築物範圍，或切換自行畫圖分析
              (自行畫圖時，請依建築物投射地面的形狀為主，過大的範圍會影響分析結果)
            </li>
            <li>3. 依照下方災害圖示切換分析結果</li>
          </ul>
          <hr />
          <div
            className="target-input-container"
            // style={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}
          >
            <div
              style={{
                marginRight: "10px",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <button onClick={() => dispatch(resetTargetInfo())}>重置</button>
            </div>
            <div
              style={{
                marginRight: "10px",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              建物資訊輸入
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                // gap: "5px",
                flexDirection: "row",
              }}
            >
              <label
                htmlFor="designPga"
                style={{
                  marginRight: "5px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                建築抗震設計(g)，或不輸入，系統將採用抗震設計規範值:
              </label>
              <input
                style={{ fontSize: "14px", fontWeight: "bold", width: "60px" }}
                id="designPga"
                type="number"
                min="0"
                step="0.01"
                value={targetInfo.designPga}
                onChange={handleDesignPgaChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                // gap: "5px",
                flexDirection: "row",
              }}
            >
              <label
                htmlFor="totalFloorNumber"
                style={{
                  marginRight: "5px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                建物總樓層數:
              </label>
              <input
                style={{ fontSize: "14px", fontWeight: "bold", width: "60px" }}
                id="totalFloorNumber"
                type="number"
                min="1"
                step="1"
                value={targetInfo.totalFloorNumber}
                onChange={handleTotalfloorNumberChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                // gap: "5px",
                flexDirection: "row",
              }}
            >
              <label
                htmlFor="floorNumber"
                style={{
                  marginRight: "5px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                建物位於幾樓:
              </label>
              <input
                style={{ fontSize: "14px", fontWeight: "bold", width: "60px" }}
                id="floorNumber"
                type="number"
                min="1"
                step="1"
                value={targetInfo.floorNumber}
                onChange={handleFloorNumberChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                // alignItems: "start",
                gap: "5px",
                flexDirection: "row",
              }}
            >
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginRight: "5px",
                }}
                htmlFor="building-type"
              >
                建物種類:
              </label>
              <select
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  maxWidth: "200px", // Adjust the max width as needed
                  overflowX: "auto", // Enable horizontal scrolling if necessary
                }}
                id="building-type"
                value={targetInfo.buildingType}
                onChange={handleBuildingTypeChange}
              >
                <option value={1.25}>
                  公共建築(活動中心、博物館、百貨公司、大賣場、電影院、車站等)
                </option>
                <option value={1}>住宅</option>
                <option value={1.5}>
                  政府辦公室、醫院、學校、電廠、水廠等
                </option>
                <option value={1.5}>油槽、加油站、化學廠房</option>
              </select>
            </div>
            <div
              style={{
                display: "flex",
                // alignItems: "start",
                gap: "5px",
                flexDirection: "row",
              }}
            >
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginRight: "5px",
                }}
                htmlFor="building-material"
              >
                建物材質:
              </label>
              <select
                style={{ fontSize: "14px", fontWeight: "bold" }}
                id="building-material"
                // value={targetInfo.buildingMaterial}
                value={targetInfo.buildingMaterial || 'SRC'}
                onChange={handleBuildingMaterialChange}
              >
                <option value="SRC">SRC</option>
                <option value="RC">RC</option>
                <option value="磚造">磚造</option>
                <option value="加強磚造">加強磚造</option>
                <option value="木材">木材</option>
                {/* <option value={1}>SRC</option>
                <option value={0.8}>RC</option>
                <option value={0.6}>磚造</option>
                <option value={0.8}>加強磚造</option>
                <option value={0.8}>木材</option> */}
              </select>
            </div>

            <div
              style={{
                display: "flex",
                // alignItems: "start",
                gap: "5px",
                flexDirection: "row",
              }}
            >
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginRight: "5px",
                }}
                htmlFor="building-time"
              >
                建造時間(西元年):
              </label>
              <input
                style={{ fontSize: "14px", fontWeight: "bold", width: "60px" }}
                id="building-time"
                type="number"
                min="0"
                step="1"
                value={targetInfo.buildingTime}
                onChange={handleBuildingTimeChange}
              />
              {/* <select
                style={{ fontSize: "14px", fontWeight: "bold" }}
                id="building-time"
                value={targetInfo.buildingTime}
                onChange={handleBuildingTimeChange}
              >
                <option value={1}>1999年以前</option>
                <option value={1.5}>1999年-2005年</option>
                <option value={1.2}>2005年以後</option>
              </select> */}
            </div>

            <div>
              <label
                htmlFor="maskingHeight"
                style={{
                  marginRight: "5px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                建物1F地板比人行道高幾公分(一般為10~30公分):
              </label>
              <input
                style={{ fontSize: "14px", fontWeight: "bold", width: "60px" }}
                id="maskingHeight"
                type="number"
                min="0" // Set a minimum value of 0 to prevent negative numbers
                value={targetInfo.maskingHeight}
                onChange={handleMaskingHeightChange}
              />
            </div>
            <hr />
            <div
              style={{
                marginRight: "5px",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              財產資料
            </div>
            <div>
              <label
                htmlFor="buildingValue"
                style={{
                  marginRight: "5px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                建物實體價值(萬元):
              </label>
              <input
                style={{ fontSize: "14px", fontWeight: "bold", width: "60px" }}
                id="buildingValue"
                type="number"
                min="0" // Set a minimum value of 0 to prevent negative numbers
                value={targetInfo.buildingValue}
                onChange={handleBuildingValueChange}
              />
            </div>
            <div>
              <label
                htmlFor="businessLoss"
                style={{
                  marginRight: "5px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                營業損失(萬元):
              </label>
              <input
                style={{ fontSize: "14px", fontWeight: "bold", width: "60px" }}
                id="businessLoss"
                type="number"
                min="0" // Set a minimum value of 0 to prevent negative numbers
                value={targetInfo.businessLoss}
                onChange={handleBusinessLossChange}
              />
            </div>
            <div>
              <label
                htmlFor="carValue"
                style={{
                  marginRight: "5px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                汽機車價值(萬元):
              </label>
              <input
                style={{ fontSize: "14px", fontWeight: "bold", width: "60px" }}
                id="carValue"
                type="number"
                min="0" // Set a minimum value of 0 to prevent negative numbers
                value={targetInfo.carValue}
                onChange={handleCarValueChange}
              />
            </div>
            <div>
              <label
                htmlFor="furnitureLoss"
                style={{
                  marginRight: "5px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                附屬物價值(家具)(萬元):
              </label>
              <input
                style={{ fontSize: "14px", fontWeight: "bold", width: "60px" }}
                id="furnitureLoss"
                type="number"
                min="0" // Set a minimum value of 0 to prevent negative numbers
                value={targetInfo.furnitureLoss}
                onChange={handleFurnitureLossChange}
              />
            </div>
          </div>
        </div>
        <hr />
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label={<CustomTab label="點選建物" icon={<HomeWorkIcon />} />} />
          <Tab label={<CustomTab label="手繪區域" icon={<ModeIcon />} />} />
          {/* <Tab label={<CustomTab label="手繪區域" icon={<FaDrawPolygon />} />} /> */}
        </Tabs>
      </Box>

      {/* Conditional Rendering based on tabValue */}
      {tabValue === 0 ? (
        <ClickPolygonMapAll detailOrder={detailOrder} />
      ) : (
        <DrawPolygonMapAll detailOrder={detailOrder} />
      )}
    </div>
  );
};

export default BuildingRiskAll;
