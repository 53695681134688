import React from "react";
import "./EarthQuakeTable.css";

const EarthQuakeTable = ({ report }) => {
  const exceedance_probabilities_cwb =
    report?.risk_results?.["earthquake"]?.[10] || [];
  const damage_rate_EACH_CWB = report?.risk_results?.["earthquake"]?.[11] || [];
  // console.log("exceedance_probabilities_cwb", exceedance_probabilities_cwb);
  // console.log("damage_rate_EACH_CWB", damage_rate_EACH_CWB);

  const renderRow = (
    intensity,
    pga,
    // pgv,
    probabilityIndex,
    damageRateIndex
  ) => {
    let probability;
    if (probabilityIndex == -1) {
      probability = 100;
    } else {
      probability = (
        exceedance_probabilities_cwb[probabilityIndex] * 100
      ).toFixed(2);
    }
    let damageRate;
    if (damageRateIndex == -1) {
      damageRate = "<0.0001%";
    } else if (damage_rate_EACH_CWB[damageRateIndex] == 0) {
      damageRate = "<0.0001%";
    } else {
      damageRate = (damage_rate_EACH_CWB[damageRateIndex] * 100).toFixed(2);
    }

    return (
      <tr>
        <td>{intensity}</td>
        <td>{pga}</td>
        {/* <td>{pgv}</td> */}
        <td>{probability}</td>
        <td>{damageRate}</td>
      </tr>
    );
  };

  return (
    <div>
      <div
        className="table-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <table className="disaster-table">
        {/* <table> */}
          <caption>
            本建物之氣象署地震分級分析結果(不同震度下對此建物之影響)
          </caption>
          <thead>
            <tr>
              <th>震度</th>
              <th>最大地動加速度值(PGA)(cm/sec2)</th>
              {/* <th>最大地動速度值(PGV)</th> */}
              <th>50年發生機率(%)</th>
              <th>建物損壞程度％ (台日美資料庫，保守估計)</th>
            </tr>
          </thead>
          <tbody>
            {renderRow("0級", "<0.8", -1, -1)}
            {renderRow("1級", "0.8~2.5", 0, 0)}
            {renderRow("2級", "2.5~8.0", 1, 1)}
            {renderRow("3級", "8.0~25", 2, 2)}
            {renderRow("4級", "25~80", 3, 3)}
            {renderRow("5弱級", "80~140", 4, 4)}
            {renderRow("5強級", "140~250", 5, 5)}
            {renderRow("6弱級", "250~440", 6, 6)}
            {renderRow("6強級", "440~800", 7, 7)}
            {renderRow("7級", ">800", 8, 8)}
            {/* {renderRow("0級", "<0.8", "<0.2", -1, -1)}
            {renderRow("1級", "0.8~2.5", "0.2~0.7", 0, 0)}
            {renderRow("2級", "2.5~8.0", "0.7~1.9", 1, 1)}
            {renderRow("3級", "8.0~25", "1.9~5.7", 2, 2)}
            {renderRow("4級", "25~80", "5.7~15", 3, 3)}
            {renderRow("5弱級", "80~140", "15~30", 4, 4)}
            {renderRow("5強級", "140~250", "30~50", 5, 5)}
            {renderRow("6弱級", "250~440", "50~80", 6, 6)}
            {renderRow("6強級", "440~800", "80~140", 7, 7)}
            {renderRow("7級", ">800", ">140", 8, 8)} */}
          </tbody>
        </table>
      </div>
      <br />
      <br />

      <img
        src="https://edu.cwa.gov.tw/PopularScience/images/02_SubjectMaterial/02_earthquake/02_earthquake_107/02_earthquake_107_03.png"
        style={{ width: "100%", height: "auto", maxWidth: "100%" }}
        alt="earthquake impact reference"
      />
      <p>圖片來源:中央氣象署數位科普網</p>

      {/* <div className="table-container">
        <table className="impact-table">
          <caption>氣象署地震分級衝擊參考</caption>
          <thead>
            <tr>
              <th>震度分級</th>
              <th>震度分類</th>
              <th>人的感受</th>
              <th>屋內情形</th>
              <th>屋外情形</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0 級</td>
              <td>無感</td>
              <td>人無感覺。</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>1 級</td>
              <td>微震</td>
              <td>人靜止或位於高樓層時可感覺微小搖晃。</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>2 級</td>
              <td>輕震</td>
              <td>大多數的人可感到搖晃，睡眠中的人有部分會醒來。</td>
              <td>電燈等懸掛物有小搖晃。</td>
              <td>靜止的汽車輕輕搖晃，類似卡車經過，但歷時很短。</td>
            </tr>
            <tr>
              <td>3 級</td>
              <td>弱震</td>
              <td>幾乎所有的人都感覺搖晃，有的人會有恐懼感。</td>
              <td>房屋震動，碗盤門窗發出聲音，懸掛物搖擺。</td>
              <td>靜止的汽車明顯搖動，電線略有搖晃。</td>
            </tr>
            <tr>
              <td>4 級</td>
              <td>中震</td>
              <td>
                有相當程度的恐懼感，部分的人會尋求躲避的地方，睡眠中的人幾乎都會驚醒。
              </td>
              <td>
                房屋搖動甚烈，少數未固定物品可能傾倒掉落，少數傢俱移動，可能有輕微災害。
              </td>
              <td>
                電線明顯搖晃，少數建築物牆磚可能剝落，小範圍山區可能發生落石，極少數地區電力或自來水可能中斷。
              </td>
            </tr>
            <tr>
              <td>5 弱級</td>
              <td>強震</td>
              <td>大多數人會感到驚嚇恐慌，難以走動。</td>
              <td>
                部分未固定物品傾倒掉落，少數傢俱可能移動或翻倒，少數門窗可能變形，部分牆壁產生裂痕。
              </td>
              <td>
                部分建築物牆磚剝落，部分山區可能發生落石，少數地區電力、自來水、瓦斯或通訊可能中斷。
              </td>
            </tr>
            <tr>
              <td>5 強級</td>
              <td>強震</td>
              <td>幾乎所有的人會感到驚嚇恐慌，難以走動。</td>
              <td>
                大量未固定物品傾倒掉落，傢俱移動或翻倒，部分門窗變形，部分牆壁產生裂痕，極少數耐震較差房屋可能損壞或崩塌。
              </td>
              <td>
                部分建築物牆磚剝落，部分山區發生落石，鬆軟土層可能出現噴沙噴泥現象，部分地區電力、自來水、瓦斯或通訊中斷，少數耐震較差磚牆可能損壞或崩塌。
              </td>
            </tr>
            <tr>
              <td>6 弱級</td>
              <td>烈震</td>
              <td>搖晃劇烈以致站立困難。</td>
              <td>
                大量傢俱大幅移動或翻倒，門窗扭曲變形，部分耐震能力較差房屋可能損壞或倒塌。
              </td>
              <td>
                部分地面出現裂痕，部分山區可能發生山崩，鬆軟土層出現噴沙噴泥現象，部分地區電力、自來水、瓦斯或通訊中斷。
              </td>
            </tr>
            <tr>
              <td>6 強級</td>
              <td>烈震</td>
              <td>搖晃劇烈以致無法站穩。</td>
              <td>
                大量傢俱大幅移動或翻倒，門窗扭曲變形，部分耐震能力較差房屋可能損壞或倒塌，耐震能力較強房屋亦可能受損。
              </td>
              <td>
                部分地面出現裂痕，山區可能發生山崩，鬆軟土層出現噴沙噴泥現象，可能大範圍地區電力、自來水、瓦斯或通訊中斷。
              </td>
            </tr>
            <tr>
              <td>7 級</td>
              <td>劇震</td>
              <td>搖晃劇烈以致無法依意志行動。</td>
              <td>
                幾乎所有傢俱都大幅移動或翻倒，部分耐震較強建築物可能損壞或倒塌。
              </td>
              <td>
                山崩地裂，地形地貌亦可能改變，多處鬆軟土層出現噴沙噴泥現象，大範圍地區電力、自來水、瓦斯或通訊中斷，鐵軌彎曲。
              </td>
            </tr>
          </tbody>
        </table>
        <p>資料來源:交通部中央氣象局</p>
      </div> */}
    </div>
  );
};

export default EarthQuakeTable;
