import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
// import Map4 from "../Map/Map4";
import DashboardMap from "../Map/DashboardMapRisk";
// import DashboardMap from "../Map/DashboardMap";
import "./ProductGrid.scss";
import { Link } from "react-router-dom";

const ProductGrid = ({ products }) => {
  // console.log(products);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // Use 24-hour clock. Set to true for AM/PM format.
  };

  return (
    <div className="product-grid-root">
      <Grid container spacing={3}>
        {products.map((product) => (
          <Grid item xs={12} sm={12} md={6} key={product.pkid}>
            <Card className="product-grid-card">
              <div className="product-grid-map">
                {/* don't show wms */}
                <DashboardMap product={product} />
              </div>
              <div className="product-grid-details-wrapper">
                <CardContent className="product-grid-details">
                  <h5>名稱:{product.name}</h5>
                  <br />
                  <h6>產品編號:{product.pkid}</h6>
                  <h6>
                    時間:
                    {new Date(product.created_at).toLocaleString(
                      undefined,
                      options
                    )}
                  </h6>

                  {/* <p>{product.created_at}</p> */}
                </CardContent>
                {/* <div className="product-grid-button-container">
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ color: "white" }}
                  >
                    <Link
                      to={`/riskreport/${product.pkid}`}
                      className="custom-link"
                    >
                      風險報告
                    </Link>
                  </Button>
                </div> */}
                <div className="product-grid-button-container">
                  <Button
                    variant="contained"
                    // color="primary"
                    sx={{ backgroundColor: "#4C839B" }}
                    // sx={{ color: "white" }}
                  >
                    <Link
                      to={`/riskreport/${product.pkid}`}
                      className="custom-link"
                    >
                      風險報告
                    </Link>
                  </Button>
                </div>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

ProductGrid.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      pkid: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ProductGrid;
