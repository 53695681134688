import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Container, Box } from "@mui/material";

const FreeTrial = () => {
  const navigate = useNavigate();

  const handleReadTrialReport = () => {
    navigate("/trial-report"); // Adjust the path according to your routing configuration
  };

  return (
    <Container maxWidth="sm" sx={{ color: "#4c839b" }}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <Typography variant="h4" gutterBottom>
          免費試讀專屬報告
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          了解更多關於您的專屬報告，點擊下方按鈕進行範本試讀。
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleReadTrialReport}
          sx={{ mt: 3, backgroundColor: "#4C839B", fontWeight: "bold" }}
        >
          點我試讀
        </Button>
      </Box>
    </Container>
  );
};

export default FreeTrial;
