import React from "react";
import { motion, useInView } from "framer-motion";
import CountUp from "react-countup";
import "./Statistics.scss"; // Import your styles

const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible,
};

const StatisticsCard = ({
  description,
  value,
  label,
  note,
  isPercentage,
  secondDescription,
  secondValue,
  secondLabel,
}) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { triggerOnce: true, threshold: 0.2 });

  return (
    <motion.div
      ref={ref}
      className="statistics-card"
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={itemVariants}
    >
      {description && <p className="description">{description}</p>}
      <p className="value">
        {isInView ? (
          <CountUp
            start={0}
            end={value}
            duration={2.5}
            suffix={isPercentage ? "%" : ""}
          />
        ) : (
          value
        )}
        {label && <span className="label">{label}</span>}
      </p>
      {secondDescription && <p className="description">{secondDescription}</p>}
      {secondValue && (
        <p className="value">
          {isInView ? (
            <CountUp
              start={0}
              end={secondValue}
              duration={2.5}
              suffix={isPercentage ? "%" : ""}
            />
          ) : (
            secondValue
          )}
          {secondLabel && <span className="label">{secondLabel}</span>}
        </p>
      )}
      {note && <p style={{ fontSize: "0.7rem" }}>{note}</p>}
    </motion.div>
  );
};

const Statistics = () => {
  return (
    <motion.div
      className="statistics-container"
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: 1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
    >
      <h2>未來，氣候變遷的災害會越來越嚴重</h2>

      <div className="statistics-grid">
        <div className="statistics-section">
          <h3>2020 全球十大洪水地震</h3>
          <StatisticsCard
            description="保險理賠達"
            value={306}
            label="億美元"
            secondDescription="未保險金額達"
            secondValue={926}
            secondLabel="億美元"
          />
          <StatisticsCard
            description="總災害損失"
            value={1232}
            label="億美元"
          />
          {/* <StatisticsCard
            description="未保險金額達"
            value={926}
            label="億美元"
          /> */}
        </div>

        <div className="statistics-section">
          <h3>經濟學人雜誌指出</h3>
          <StatisticsCard
            description="氣候變遷將造成屋主的損失"
            value={25}
            label="兆美元"
          />
          <StatisticsCard
            description="平均每個屋主損失"
            value={10000}
            label="美元"
          />
          {/* <StatisticsCard
            description="平均每個屋主損失約等於"
            value={32}
            label="萬台幣"
            note="*以全球80億人口、25億房屋計算"
          /> */}
        </div>

        <div className="statistics-section">
          <h3>內政部統計</h3>
          <StatisticsCard
            description="台灣平均每年因天災損失"
            value={350}
            label="億台幣"
          />
          <StatisticsCard
            value={88}
            label="洪水損失(308億)"
            secondValue={8.45}
            secondLabel="地震損失(3億)"
            isPercentage={true}
          />
          {/* <StatisticsCard
            description="平均每個屋主損失"
            value={3500}
            label="台幣/年"
          /> */}
        </div>
      </div>
      <div className="statistics-grid">
        <div className="statistics-section" style={{ maxWidth: "100%" }}>
          <motion.div
            // ref={ref}
            className="statistics-card"
            initial="hidden"
            animate={"visible"}
            variants={itemVariants}
          >
            <h3>有的人損失少，有的人損失多</h3>
            <h3>您知道您的房屋有多少天災風險？</h3>
            <h3>你知道怎麼聰明地保護資產嗎？</h3>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default Statistics;
