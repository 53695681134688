import "mapbox-gl/dist/mapbox-gl.css";
import React, { useState, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import MapboxCompare from "mapbox-gl-compare";
import "mapbox-gl-compare/dist/mapbox-gl-compare.css";
import "./MapMoveCompare.scss";
// import { useNavigate } from "react-router-dom";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
// import floodhouse from "./merge_demo_building.geojson";
import floodhouse from "./demo_building_v1.geojson";
const geoserver_url = process.env.REACT_APP_GEOSERVER_URL;
const mapboxtoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const gs_username = process.env.REACT_APP_GEOSERVER_BASIC_USERNAME;
const gs_pass = process.env.REACT_APP_GEOSERVER_BASIC_PASSWORD;
mapboxgl.accessToken = mapboxtoken;

const MapMoveCompare = () => {
  const [map, setMap] = useState(null);

  const demo_circle = {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [121.560414, 25.041399],
          [121.560282, 25.040644],
          [121.5599, 25.039963],
          [121.559303, 25.039423],
          [121.558552, 25.039076],
          [121.557719, 25.038957],
          [121.556887, 25.039076],
          [121.556135, 25.039423],
          [121.555539, 25.039963],
          [121.555156, 25.040644],
          [121.555024, 25.041399],
          [121.555156, 25.042153],
          [121.555539, 25.042834],
          [121.556135, 25.043374],
          [121.556887, 25.043721],
          [121.557719, 25.04384],
          [121.558552, 25.043721],
          [121.559303, 25.043374],
          [121.5599, 25.042834],
          [121.560282, 25.042153],
          [121.560414, 25.041399],
        ],
      ],
      [
        [
          [121.566115, 25.034734],
          [121.565983, 25.033979],
          [121.565601, 25.033299],
          [121.565004, 25.032759],
          [121.564253, 25.032412],
          [121.56342, 25.032292],
          [121.562588, 25.032412],
          [121.561836, 25.032759],
          [121.56124, 25.033299],
          [121.560857, 25.033979],
          [121.560725, 25.034734],
          [121.560857, 25.035488],
          [121.56124, 25.036169],
          [121.561836, 25.036709],
          [121.562588, 25.037056],
          [121.56342, 25.037176],
          [121.564253, 25.037056],
          [121.565004, 25.036709],
          [121.565601, 25.036169],
          [121.565983, 25.035488],
          [121.566115, 25.034734],
        ],
      ],
      [
        [
          [121.302125, 24.993624],
          [121.301994, 24.992869],
          [121.301611, 24.992188],
          [121.301015, 24.991648],
          [121.300263, 24.991301],
          [121.299431, 24.991181],
          [121.298598, 24.991301],
          [121.297846, 24.991648],
          [121.29725, 24.992188],
          [121.296868, 24.992869],
          [121.296736, 24.993624],
          [121.296868, 24.994379],
          [121.29725, 24.99506],
          [121.297846, 24.9956],
          [121.298598, 24.995947],
          [121.299431, 24.996067],
          [121.300263, 24.995947],
          [121.301015, 24.9956],
          [121.301611, 24.99506],
          [121.301994, 24.994379],
          [121.302125, 24.993624],
        ],
      ],
      [
        [
          [121.316021, 24.989881],
          [121.315889, 24.989126],
          [121.315506, 24.988445],
          [121.31491, 24.987905],
          [121.314159, 24.987558],
          [121.313326, 24.987438],
          [121.312493, 24.987558],
          [121.311742, 24.987905],
          [121.311146, 24.988445],
          [121.310763, 24.989126],
          [121.310631, 24.989881],
          [121.310763, 24.990636],
          [121.311146, 24.991317],
          [121.311742, 24.991857],
          [121.312493, 24.992204],
          [121.313326, 24.992324],
          [121.314159, 24.992204],
          [121.31491, 24.991857],
          [121.315506, 24.991317],
          [121.315889, 24.990636],
          [121.316021, 24.989881],
        ],
      ],
    ],
  };

  function handleFlyTo(location) {
    map.flyTo({
      center: location.center,
      zoom: location.zoom,
      bearing: location.bearing,
      pitch: location.pitch,
      essential: true,
      duration: 5000,
    });
  }

  useEffect(() => {
    const beforeMap = new mapboxgl.Map({
      container: "before",
      style: "mapbox://styles/mapbox/streets-v12",
      // style: 'mapbox://styles/mapbox/satellite-v9', // style URL
      transformRequest: (url, resourceType) => {
        // console.log(`Making a ${resourceType} request to ${url}`);
        if (
          (resourceType === "Source" || resourceType === "Tile") &&
          // url.indexOf("http://localhost:8080/geoserver/staging/wms") > -1
          url.indexOf(geoserver_url + `geoserver/staging/wms`) > -1
        ) {
          const username = gs_username;
          const password = gs_pass;
          const credentials = btoa(`${username}:${password}`);
          // const credentials = Buffer.from(`${username}:${password}`).toString('base64');
          const headers = {
            // 'my-custom-header': true,
            Authorization: `Basic ${credentials}`,
          };
          // console.log(`Adding headers: ${JSON.stringify(headers)}`);

          return {
            url: url,
            headers: headers,
            credentials: "include", // Include cookies for cross-origin requests
          };
        }
      },
      // style: "mapbox://styles/wayne32545/cle6fqgc3000601t6g17e3arw",
      center: [121.29943054854983586, 24.99362409757704384], // 24.99283, 121.30007  (121.29943054854983586 24.99362409757704384
      zoom: 16,
      bearing: 0,
      pitch: 45,
      maxZoom: 17,
      minZoom: 15,
      dragPan: false,
    });

    beforeMap.on("style.load", () => {
      //circle
      beforeMap.addSource("polygon", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: demo_circle,
        },
      });

      beforeMap.addLayer({
        id: "polygon-layer",
        type: "fill",
        source: "polygon",
        paint: {
          "fill-color": "transparent",
          //   "fill-opacity": 0.5,
        },
      });

      beforeMap.addLayer({
        id: "polygon-border-layer",
        type: "line",
        source: "polygon",
        paint: {
          "line-color": "black",
          "line-width": 4,
          "line-dasharray": [2, 2],
        },
      });

      //wms
      beforeMap.addSource("wms-test-source", {
        type: "raster",
        tiles: [
          //   `http://localhost:8080/geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:opendata6h250mm40m&FORMAT=image/png&TILED=true&clip=${wkt3857}`,
          // `http://localhost:8080/geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:opendata6h250mm40m&FORMAT=image/png&TILED=true`,
          geoserver_url +
            // `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:opendata6h350mm40m&FORMAT=image/png&TILED=true`,
            `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:opendata6h350mm40m3857&FORMAT=image/png&TILED=true`,
        ],

        tileSize: 256,
      });
      beforeMap.addLayer(
        {
          id: "wms-test-layer",
          type: "raster",
          source: "wms-test-source",
          paint: {},
        }
        // "building"
      );

      beforeMap.setPaintProperty("wms-test-layer", "raster-opacity", 0.7);

      // beforeMap.addLayer({
      //   id: "add-3d-buildings",
      //   source: "composite",
      //   "source-layer": "building",
      //   filter: ["==", "extrude", "true"],
      //   type: "fill-extrusion",
      //   minzoom: 15,
      //   paint: {
      //     "fill-extrusion-color": "#aaa",
      //     "fill-extrusion-height": [
      //       "interpolate",
      //       ["linear"],
      //       ["zoom"],
      //       15,
      //       0,
      //       15.05,
      //       ["get", "height"],
      //     ],
      //     "fill-extrusion-base": [
      //       "interpolate",
      //       ["linear"],
      //       ["zoom"],
      //       15,
      //       0,
      //       15.05,
      //       ["get", "min_height"],
      //     ],
      //     "fill-extrusion-opacity": 1,
      //   },
      // });

      //house
      // beforeMap.addSource("floorplan", {
      //   type: "geojson",
      //   data: floodhouse,
      // });
      // beforeMap.addLayer({
      //   id: "room-extrusion",
      //   type: "fill-extrusion",
      //   source: "floorplan",
      //   paint: {
      //     "fill-extrusion-color": "#aaa",
      //     "fill-extrusion-height": ["get", "build_h"],
      //     "fill-extrusion-opacity": 0.7,
      //   },
      // });
    });

    const afterMap = new mapboxgl.Map({
      container: "after",
      //   style: "mapbox://styles/mapbox/streets-v12",
      // style: "mapbox://styles/mapbox/dark-v11",
      //   style: "mapbox://styles/mapbox/navigation-night-v1",

      transformRequest: (url, resourceType) => {
        // console.log(`Making a ${resourceType} request to ${url}`);
        if (
          (resourceType === "Source" || resourceType === "Tile") &&
          // url.indexOf("http://localhost:8080/geoserver/staging/wms") > -1
          url.indexOf(geoserver_url + `geoserver/staging/wms`) > -1
        ) {
          const username = gs_username;
          const password = gs_pass;
          const credentials = btoa(`${username}:${password}`);
          // const credentials = Buffer.from(`${username}:${password}`).toString('base64');
          const headers = {
            // 'my-custom-header': true,
            Authorization: `Basic ${credentials}`,
          };
          // console.log(`Adding headers: ${JSON.stringify(headers)}`);

          return {
            url: url,
            headers: headers,
            credentials: "include", // Include cookies for cross-origin requests
          };
        }
      },
      style: "mapbox://styles/wayne32545/cle6fqgc3000601t6g17e3arw",
      // style: 'mapbox://styles/mapbox/satellite-v9', // style URL
      center: [121.29943054854983586, 24.99362409757704384], // 24.99283, 121.30007
      zoom: 16,
      bearing: 0,
      pitch: 45,
      maxZoom: 17,
      minZoom: 15,
      dragPan: false,
    });

    afterMap.on("style.load", () => {
      //circle
      afterMap.addSource("polygon", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: demo_circle,
        },
      });

      afterMap.addLayer({
        id: "polygon-layer",
        type: "fill",
        source: "polygon",
        paint: {
          "fill-color": "transparent",
          //   "fill-opacity": 0.5,
        },
      });

      afterMap.addLayer({
        id: "polygon-border-layer",
        type: "line",
        source: "polygon",
        paint: {
          "line-color": "black",
          "line-width": 4,
          "line-dasharray": [2, 2],
        },
      });

      //wms
      afterMap.addSource("wms-test-source", {
        type: "raster",

        tiles: [
          // `http://localhost:8080/geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:demo_merge20230426&FORMAT=image/png&TILED=true`,
          geoserver_url +
            `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:demo_merge_350mm6hr&FORMAT=image/png&TILED=true`,
        ],
        tileSize: 256,
      });

      // const layers = map.getStyle().layers;
      // const labelLayerId = layers.find(
      //   (layer) => layer.type === "symbol" && layer.layout["text-field"]
      // ).id;
      afterMap.addLayer(
        {
          id: "wms-test-layer",
          type: "raster",
          source: "wms-test-source",
          paint: {},
        }
        // "building"
      );

      afterMap.setPaintProperty("wms-test-layer", "raster-opacity", 0.8);
      // afterMap.addLayer(
      //   {
      //     id: "add-3d-buildings",
      //     source: "composite",
      //     "source-layer": "building",
      //     filter: ["==", "extrude", "true"],
      //     type: "fill-extrusion",
      //     minzoom: 15,
      //     paint: {
      //       "fill-extrusion-color": "black",
      //       "fill-extrusion-height": [
      //         "interpolate",
      //         ["linear"],
      //         ["zoom"],
      //         15,
      //         0,
      //         15.05,
      //         ["get", "height"],
      //       ],
      //       "fill-extrusion-base": [
      //         "interpolate",
      //         ["linear"],
      //         ["zoom"],
      //         15,
      //         0,
      //         15.05,
      //         ["get", "min_height"],
      //       ],
      //       "fill-extrusion-opacity": 1,
      //     },
      //   }
      //   // labelLayerId
      // );

      //house

      afterMap.addSource("floorplan", {
        type: "geojson",
        data: floodhouse,
      });
      afterMap.addLayer({
        id: "room-extrusion",
        type: "fill-extrusion",
        source: "floorplan",
        paint: {
          // Get the `fill-extrusion-color` from the source `color` property.
          "fill-extrusion-color": "black",
          // Get `fill-extrusion-height` from the source `height` property.
          "fill-extrusion-height": ["get", "build_h"],
          // Make extrusions slightly opaque to see through indoor walls.
          "fill-extrusion-opacity": 0.8,
        },
      });
    });

    //control layer
    //test control:
    class CustomControl {
      constructor() {
        this.currentStyle = "mapbox://styles/mapbox/streets-v12"; // Set the initial style here
        this.currentWmsLayer = null; // Track the current WMS layer
        this.currentStyle2 =
          "mapbox://styles/wayne32545/cle6fqgc3000601t6g17e3arw"; // Set the initial style here
        this.currentWmsLayer2 = null; // Track the current WMS layer
        this.isFirstWMSLayer = true;
      }
      removeCurrentWmsLayer(layerId) {
        if (layerId === this.currentWmsLayer && afterMap.getLayer(layerId)) {
          afterMap.removeLayer(layerId);
          if (
            afterMap.getSource(
              `wms-test-source${layerId.charAt(layerId.length - 1)}`
            )
          ) {
            afterMap.removeSource(
              `wms-test-source${layerId.charAt(layerId.length - 1)}`
            );
          }
          this.currentWmsLayer = null;
        }
      }

      onAdd(map) {
        this.map = map;
        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

        // Style Control
        const styleControl = document.createElement("button");
        styleControl.className =
          "basemap_icon mapboxgl-ctrl-icon mapboxgl-ctrl-style ";
        styleControl.textContent = "衛星底圖";

        styleControl.onclick = () => {
          // Check the current style and switch to the other one
          const newStyle =
            this.currentStyle === "mapbox://styles/mapbox/streets-v12"
              ? "mapbox://styles/mapbox/satellite-v9"
              : "mapbox://styles/mapbox/streets-v12";
          const newStyle2 =
            this.currentStyle2 ===
            "mapbox://styles/wayne32545/cle6fqgc3000601t6g17e3arw"
              ? "mapbox://styles/mapbox/satellite-v9"
              : "mapbox://styles/wayne32545/cle6fqgc3000601t6g17e3arw";

          this.currentStyle = newStyle;
          this.currentStyle2 = newStyle2;
          beforeMap.setStyle(newStyle);
          afterMap.setStyle(newStyle2);

          // Listen for the 'styledata' event on the map
          afterMap.once("styledata", () => {
            if (!afterMap.getSource("composite")) {
              // Add the 'composite' source and '3d-buildings' layer if they don't exist
              afterMap.addSource("composite", {
                type: "vector",
                url: "mapbox://mapbox.mapbox-streets-v8",
              });

              // afterMap.addLayer({
              //   id: "3d-buildings",
              //   source: "composite",
              //   "source-layer": "building",
              //   filter: ["==", "extrude", "true"],
              //   type: "fill-extrusion",
              //   minzoom: 15,
              //   paint: {
              //     "fill-extrusion-color": "#aaa",
              //     "fill-extrusion-height": ["get", "height"],
              //     "fill-extrusion-base": ["get", "min_height"],
              //     "fill-extrusion-opacity": 1,
              //   },
              // });
            }
          });
          // beforeMap.once("styledata", () => {
          //   if (!beforeMap.getSource("composite")) {
          //     // Add the 'composite' source and '3d-buildings' layer if they don't exist
          //     beforeMap.addSource("composite", {
          //       type: "vector",
          //       url: "mapbox://mapbox.mapbox-streets-v8",
          //     });

          //     beforeMap.addLayer({
          //       id: "3d-buildings",
          //       source: "composite",
          //       "source-layer": "building",
          //       filter: ["==", "extrude", "true"],
          //       type: "fill-extrusion",
          //       minzoom: 15,
          //       paint: {
          //         "fill-extrusion-color": "#aaa",
          //         "fill-extrusion-height": ["get", "height"],
          //         "fill-extrusion-base": ["get", "min_height"],
          //         "fill-extrusion-opacity": 1,
          //       },
          //     });
          //   }
          // });
        };

        this.container.appendChild(styleControl);

        // houseControl
        const houseControl = document.createElement("button");
        houseControl.className =
          "basemap_icon mapboxgl-ctrl-icon mapboxgl-ctrl-wms3";
        houseControl.textContent = "淹水房屋";

        const houseButtonClick = () => {
          if (afterMap.getLayer("room-extrusion")) {
            let currentColor = afterMap.getPaintProperty(
              "room-extrusion",
              "fill-extrusion-color"
            );

            if (currentColor === "black") {
              afterMap.setPaintProperty(
                "room-extrusion",
                "fill-extrusion-color",
                ["get", "color_demo"]
              );
            } else {
              afterMap.setPaintProperty(
                "room-extrusion",
                "fill-extrusion-color",
                "black"
              );
            }
          }
        };

        houseControl.onclick = houseButtonClick;

        this.container.appendChild(houseControl);

        // WMS3 Control
        const wms3Control = document.createElement("button");
        wms3Control.className =
          "basemap_icon mapboxgl-ctrl-icon mapboxgl-ctrl-wms3";
        wms3Control.textContent = "淹水道路";
        // let isClicked = false; // Tracks whether the button has been clicked

        const handleButtonClick = () => {
          // Toggle between the first and second WMS layer URLs
          const newWMSLayerURL = this.isFirstWMSLayer
            ? geoserver_url +
              `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:demo_cricle_road_merge_v2&FORMAT=image/png&TILED=true`
            : geoserver_url +
              `geoserver/staging/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&transparent=true&width=256&height=256&LAYERS=staging:demo_merge_350mm6hr&FORMAT=image/png&TILED=true`;

          // Remove the existing WMS layer
          afterMap.removeLayer("wms-test-layer");
          afterMap.removeSource("wms-test-source");

          // Add a new WMS layer with the updated URL
          afterMap.addSource("wms-test-source", {
            type: "raster",
            tiles: [newWMSLayerURL],
            tileSize: 256,
          });
          afterMap.addLayer(
            {
              id: "wms-test-layer",
              type: "raster",
              source: "wms-test-source",
              paint: {},
            },
            "room-extrusion"
          );
          // Toggle the state variable
          this.isFirstWMSLayer = !this.isFirstWMSLayer;
        };

        wms3Control.onclick = handleButtonClick;

        //

        this.container.appendChild(wms3Control);

        // Opacity Control
        const opacityControl = document.createElement("label");
        opacityControl.className = "opacity-control";

        const opacityLabel = document.createElement("span");
        opacityLabel.textContent = "房屋透明度: ";
        opacityLabel.className = "opacity-label";

        const opacityRange = document.createElement("input");
        opacityRange.type = "range";
        opacityRange.min = "0";
        opacityRange.max = "1";
        opacityRange.step = "0.1";
        opacityRange.value = this.currentOpacity || "0.8"; // Set initial opacity value
        opacityRange.oninput = (event) => {
          const newOpacity = parseFloat(event.target.value);

          this.currentOpacity = newOpacity;
          // Update the opacity of the layers
          afterMap.setPaintProperty(
            "room-extrusion",
            "fill-extrusion-opacity",
            newOpacity
          );
          // beforeMap.setPaintProperty(
          //   "room-extrusion",
          //   "fill-extrusion-opacity",
          //   newOpacity
          // );
        };

        opacityControl.appendChild(opacityLabel);
        opacityControl.appendChild(opacityRange);
        this.container.appendChild(opacityControl);

        return this.container;
      }

      onRemove() {
        this.removeCurrentWmsLayer();
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
      }
    }

    // Then you would add the control like this:
    const control = new CustomControl();
    afterMap.addControl(control, "top-right");

    // define layer names
    const layers = [
      "0.0-0.1",
      "0.1-0.2",
      "0.2-0.3",
      "0.3-0.4",
      "0.4-0.5",
      "> 0.5",
    ];
    const colors = [
      "rgba(255, 255, 255, 0.5)",
      "rgba(240, 255, 255, 1)",
      "rgba(30, 144, 255, 1)",
      "rgba(255, 255, 0, 1)",
      "rgba(255, 140, 0, 1)",
      "rgba(255, 0, 0, 1)",
    ];

    // create legend
    const legend = document.getElementById("legend");
    // Clear the legend before appending new items
    legend.innerHTML = "水深(m)";

    layers.forEach((layer, i) => {
      const color = colors[i];
      const item = document.createElement("div");
      const key = document.createElement("span");
      key.className = "legend-key";
      key.style.backgroundColor = color;

      const value = document.createElement("span");
      value.innerHTML = `${layer}`;
      item.appendChild(key);
      item.appendChild(value);
      legend.appendChild(item);
    });

    // A selector or reference to HTML element
    const container = "#comparison-container";

    // const map = new mapboxgl.Compare(beforeMap, afterMap, container, {
    const map = new MapboxCompare(beforeMap, afterMap, container, {
      // Set this to enable comparing two maps by mouse movement:
      // mousemove: true,
      orientation: "vertical",
    });

    map.on("slideend", (e) => {});

    setMap(map);
    setMap(beforeMap);
    setMap(afterMap);

    // map.setSlider(x);

    // Clean up on unmount
    return () => {
      map.remove();
      afterMap.remove();
      beforeMap.remove();
    };
  }, []);
  return (
    <div className="mapmove-compare">
      <div className="test-legend">
        <div className="map-overlay" id="legend">
          水深
        </div>
        <div id="comparison-container" style={{ height: "70vh" }}>
          <div id="before" className="map_compare">
            {/* <div className="test-legend2 map-overlay" id="legend-before">
            水深(m)
          </div> */}
          </div>
          <div id="after" className="map_compare">
            {/* <div className="test-legend2 map-overlay" id="legend-after">
            水深(m)
          </div> */}
          </div>
        </div>
      </div>

      <div className="buttons-container">
        <button
          className="map-button"
          onClick={() =>
            handleFlyTo({
              center: [121.29943054854983586, 24.99362409757704384],
              zoom: 17,
              bearing: 130,
              pitch: 75,
              demo_geo: {
                type: "Polygon",
                coordinates: [
                  [
                    [121.560414, 25.041399],
                    [121.560282, 25.040644],
                    [121.5599, 25.039963],
                    [121.559303, 25.039423],
                    [121.558552, 25.039076],
                    [121.557719, 25.038957],
                    [121.556887, 25.039076],
                    [121.556135, 25.039423],
                    [121.555539, 25.039963],
                    [121.555156, 25.040644],
                    [121.555024, 25.041399],
                    [121.555156, 25.042153],
                    [121.555539, 25.042834],
                    [121.556135, 25.043374],
                    [121.556887, 25.043721],
                    [121.557719, 25.04384],
                    [121.558552, 25.043721],
                    [121.559303, 25.043374],
                    [121.5599, 25.042834],
                    [121.560282, 25.042153],
                    [121.560414, 25.041399],
                  ],
                ],
              },
            })
          }
        >
          桃園市政府
        </button>
        <button
          className="map-button"
          onClick={() =>
            handleFlyTo({
              center: [121.31332588483557799, 24.98988097357881344],
              zoom: 16,
              bearing: 30,
              pitch: 45,
              demo_geo: {
                type: "Polygon",
                coordinates: [
                  [
                    [121.560414, 25.041399],
                    [121.560282, 25.040644],
                    [121.5599, 25.039963],
                    [121.559303, 25.039423],
                    [121.558552, 25.039076],
                    [121.557719, 25.038957],
                    [121.556887, 25.039076],
                    [121.556135, 25.039423],
                    [121.555539, 25.039963],
                    [121.555156, 25.040644],
                    [121.555024, 25.041399],
                    [121.555156, 25.042153],
                    [121.555539, 25.042834],
                    [121.556135, 25.043374],
                    [121.556887, 25.043721],
                    [121.557719, 25.04384],
                    [121.558552, 25.043721],
                    [121.559303, 25.043374],
                    [121.5599, 25.042834],
                    [121.560282, 25.042153],
                    [121.560414, 25.041399],
                  ],
                ],
              },
            })
          }
        >
          桃園火車站
        </button>
        <button
          className="map-button"
          onClick={() =>
            handleFlyTo({
              center: [121.56342031361216982, 25.03473394107771455], //101 121.56365, 25.03320
              zoom: 16,
              bearing: 100,
              pitch: 45,
              demo_geo: {
                type: "Polygon",
                coordinates: [
                  [
                    [121.560414, 25.041399],
                    [121.560282, 25.040644],
                    [121.5599, 25.039963],
                    [121.559303, 25.039423],
                    [121.558552, 25.039076],
                    [121.557719, 25.038957],
                    [121.556887, 25.039076],
                    [121.556135, 25.039423],
                    [121.555539, 25.039963],
                    [121.555156, 25.040644],
                    [121.555024, 25.041399],
                    [121.555156, 25.042153],
                    [121.555539, 25.042834],
                    [121.556135, 25.043374],
                    [121.556887, 25.043721],
                    [121.557719, 25.04384],
                    [121.558552, 25.043721],
                    [121.559303, 25.043374],
                    [121.5599, 25.042834],
                    [121.560282, 25.042153],
                    [121.560414, 25.041399],
                  ],
                ],
              },
            })
          }
        >
          台北101
        </button>
        <button
          className="map-button"
          onClick={() =>
            handleFlyTo({
              center: [121.55771936172224912, 25.04139862804840178], //101 121.56365, 25.03320
              zoom: 16,
              bearing: 100,
              pitch: 45,
              demo_geo: {
                type: "Polygon",
                coordinates: [
                  [
                    [121.560414, 25.041399],
                    [121.560282, 25.040644],
                    [121.5599, 25.039963],
                    [121.559303, 25.039423],
                    [121.558552, 25.039076],
                    [121.557719, 25.038957],
                    [121.556887, 25.039076],
                    [121.556135, 25.039423],
                    [121.555539, 25.039963],
                    [121.555156, 25.040644],
                    [121.555024, 25.041399],
                    [121.555156, 25.042153],
                    [121.555539, 25.042834],
                    [121.556135, 25.043374],
                    [121.556887, 25.043721],
                    [121.557719, 25.04384],
                    [121.558552, 25.043721],
                    [121.559303, 25.043374],
                    [121.5599, 25.042834],
                    [121.560282, 25.042153],
                    [121.560414, 25.041399],
                  ],
                ],
              },
            })
          }
        >
          台北大巨蛋
        </button>
      </div>
    </div>
  );
};

export default MapMoveCompare;
