import React from "react";
import Price from "../../components/Price/Price3";
import "./BuyPoint.scss"; // Import the custom styles

const BuyPoint = () => {
  return (
    <div className="tech">
      <div className="title-container">
        <div className="report-h0">SAFEHOUSING</div>
        <h3 className="title-text">會員點數</h3>
        <hr className="title-divider" />
      </div>
      <div className="content-container">
        <div className="content-row">
          <p className="content-paragraph">
            非常歡迎您光臨安家百科（以下簡稱本網站），為了讓您能夠安心的使用本網站的各項服務與資訊，我們為您提供了一個便捷、快速的購物體驗，並推出了會員點數系統，並以點數購買本網站的安家圖資與報告。
            以下是會員點數的詳細說明：
          </p>
          <h4 className="content-heading">一、點數的獲取方式</h4>
          <p className="content-paragraph">
            請聯繫本公司信箱:service@safehouing.info
          </p>
          <Price/>
          <h4 className="content-heading">二、點數的使用方式</h4>
          <p className="content-paragraph">
            1.兌換商品：會員可以在網站上直接用點數兌換商品，兌換的商品與其他商品一樣，都是正品商品，兌換所需的點數會根據商品的價值不同而有所不同。
          </p>
        </div>
      </div>
    </div>
  );
};

export default BuyPoint;
