import React from "react";
import { motion, useInView } from "framer-motion";
import CountUp from "react-countup";
import "./SummaryChart.css";

const SummaryChartEarthquake = ({ report }) => {
  const minHeight = 40; // Minimum height for bars
  const minHeight2 = 80; // Minimum height for bars

  const earlth_loss_yearly = report.risk_results["earthquake"][0].toFixed(0); //本建物的年期望損失為
  const earlth_loss_rate_yearly = (
    (report.risk_results["earthquake"][6] * 100) /
    50
  ).toFixed(2);

  // For animating numbers when they come into view
  const ref = React.useRef(null);
  const isInView = useInView(ref, { triggerOnce: true, threshold: 0.2 });

  // Helper function to handle small numbers
  const renderCountUpOrStatic = (value, suffix = "") => {
    // If value is less than 1, display it without CountUp animation
    if (value < 1) {
      return `${value}${suffix}`;
    }
    // Otherwise, use CountUp for animation
    return (
      <CountUp
        start={0}
        end={value}
        duration={2.5}
        suffix={suffix}
        decimals={2}
      />
    );
  };

  return (
    <div className="flood-loss-container" ref={ref}>
      <div className="f-title">發生地震損失機率</div>
      <div>
        您的房屋在50年內的損壞比率約為
        <span style={{ color: "red" }}>
          {renderCountUpOrStatic(
            (report.risk_results["earthquake"][6] * 100).toFixed(2),
            "%"
          )}
          ，每年平均損壞為
          {renderCountUpOrStatic(
            ((report.risk_results["earthquake"][6] * 100) / 50).toFixed(2),
            "%"
          )}
        </span>
      </div>
      <div className="bar-container">
        <div className="bar" style={{ width: "100px" }}>
          <div
            className="bar-value-prob"
            style={{
              height: `${Math.max(earlth_loss_rate_yearly / 100, minHeight)}px`,
              width: "100px",
            }}
          >
            {renderCountUpOrStatic(parseFloat(earlth_loss_rate_yearly), "%")}
          </div>
        </div>
      </div>

      <br />
      <div className="f-title">每年平均地震損失</div>
      <div>
        每年平均損壞比率與建物成本，本建物的每年平均損失(年期望損失)為
        <span style={{ color: "red" }}>
          {renderCountUpOrStatic(earlth_loss_yearly, "元/年")}
        </span>
      </div>
      <div className="bar-container">
        <div className="bar" style={{ width: "100px" }}>
          <div
            className="bar-value"
            style={{
              height: `${Math.max(earlth_loss_yearly / 300, minHeight)}px`,
              width: "100px",
            }}
          >
            {renderCountUpOrStatic(earlth_loss_yearly, "元/年")}
          </div>
        </div>
      </div>

      <br />
      <div className="f-title">建議</div>
      <div>
        如果您只購買基本地震險(保費1350元，購屋時銀行的要求火險與地震險)，其保額僅150萬，且僅在半倒與全倒時才會有理賠。
        若未購買基本地震險，目前您的房屋仍有
        <span style={{ color: "red" }}>
          {renderCountUpOrStatic(
            (report.risk_results["earthquake"][8] / 10000).toFixed(2),
            "萬元"
          )}
        </span>
        的保險缺額。 若已購買基本地震險，則有
        <span style={{ color: "red" }}>
          {renderCountUpOrStatic(
            (report.risk_results["earthquake"][8] / 10000 - 150).toFixed(2),
            "萬元"
          )}
        </span>
        的保險缺額。詳細請參閱地震分析專章。
      </div>
      <br />

      <div className="f-title">詳細內容</div>
      <div>請見地震分析專章</div>
    </div>
  );
};

export default SummaryChartEarthquake;
