import React, { useEffect, useState } from "react";
import "./Prob2.scss";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Prob3 = ({ report, newProb }) => {
  const [prob, setProb] = useState({
    p10: newProb?.p10,
    p20: newProb?.p20,
    p30: newProb?.p30,
    p40: newProb?.p40,
    p50: newProb?.p50,
  });
  // Update state when newProb changes
  useEffect(() => {
    setProb({
      p10: newProb?.p10,
      p20: newProb?.p20,
      p30: newProb?.p30,
      p40: newProb?.p40,
      p50: newProb?.p50,
    });
  }, [newProb]);
  // console.log(prob);
  const data = [
    {
      name: "10cm",
      機率: parseFloat(prob.p10),
    },
    {
      name: "20cm",
      機率: parseFloat(prob.p20),
    },
    {
      name: "30cm",
      機率: parseFloat(prob.p30),
    },
    {
      name: "40cm",
      機率: parseFloat(prob.p40),
    },
    {
      name: "50cm",
      機率: parseFloat(prob.p50),
    },
  ];
  return (
    <div className="prob2">
      <div className="chart-container">
        <ResponsiveContainer>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 10,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#000" />
            <XAxis
              dataKey="name"
              label={{
                value: "淹水深度(cm)",
                position: "insideBottom",
                offset: -10,
                fill: "#000",
              }}
              stroke="#000"
            />
            <YAxis
              stroke="#000"
              label={{
                value: "機率(%)",
                angle: -90,
                position: "insideLeft",
                fill: "#000",
              }}
            />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="機率"
              stroke="#8884d8"
              fill="#8884d8"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className="prob-table-all">
        <div className="prob-table">
          <table cellSpacing="5">
            <thead>
              <tr>
                <th>淹水深度 (m)</th>
                <th>0.1m以上</th>
                <th>0.2m以上</th>
                <th>0.3m以上</th>
                <th>0.4m以上</th>
                <th>0.5m以上</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>發生機率 (%)</td>
                <td>{parseFloat(prob.p10).toFixed(3)}%</td>
                <td>{parseFloat(prob.p20).toFixed(3)}%</td>
                <td>{parseFloat(prob.p30).toFixed(3)}%</td>
                <td>{parseFloat(prob.p40).toFixed(3)}%</td>
                <td>{parseFloat(prob.p50).toFixed(3)}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Prob3;
