import React, { useState } from "react";
import "./Questionnaire.scss";

const Questionnaire = () => {
  const [selectedOptions, setSelectedOptions] = useState({
    option1: false,
    option2: false,
    option3: false,
    option4: false,
  });

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setSelectedOptions((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleSubmit = () => {
    // Count the number of selected options
    const selectedCount = Object.values(selectedOptions).filter(
      (option) => option
    ).length;

    // Generate the result message
    let resultMessage;
    if (selectedCount > 0) {
      resultMessage = `符合下列 ${selectedCount} 項，通常不需要太擔心土壤液化的問題。`;
    } else {
      resultMessage =
        "您沒有勾選任何項目，建議進一步檢查建築物的土壤液化風險。";
    }

    // Display the result message
    alert(resultMessage);
  };

  return (
    <div className="questionnaire">
      <h1>土壤液化潛勢區建築物簡易自主檢查表</h1>
      {/* <p>
        本表僅適用於中度、高度土壤液化潛勢區之建築物自主檢查、因位於低度潛勢區者，發生土壤液化的機率極低，請您無須擔心!
      </p> */}
      <p>請依照分析建物(一幢或一棟)之狀況勾選下表</p>
      <p>資料來源: 內政部國土數</p>
      <br />
      <br />

      <div className="question">
        <div className="check_area">
          <input
            type="checkbox"
            name="option1"
            checked={selectedOptions.option1}
            onChange={handleChange}
          />
          <label>
            依據921地震後，88年12月29日公告修正之建築物耐震設計規範與解說所設計之建築物
          </label>
        </div>
        <div className="desc_area">
          說明：88年12月29日修正之建物物耐震設計規範，已要求建築物設計時需進行液化潛能評估，並納入耐震設計中
        </div>
      </div>
      <hr />

      <div className="question">
        <div className="check_area">
          <input
            type="checkbox"
            name="option2"
            checked={selectedOptions.option2}
            onChange={handleChange}
          />
          <label>
            地下室三層（含）以上之建築物
            {/* <br /> */}
          </label>
        </div>
        <div className="desc_area">
          <div className="desc">
            說明：地下室三層（含）以上之建築物，可能發生液化的地層再施作地下事實已大部分挖除，且其基礎通常採用筏式基礎或樁基礎，因而發生液化時對建築物之危害較小
          </div>

          <div className="images">
            <div>
              <img className="img" src="/img/筏式基礎.png" alt="筏式基礎" />
              <p>筏式基礎</p>
            </div>
            <div>
              <img className="img" src="/img/樁基礎.png" alt="樁基礎" />
              <p>樁基礎</p>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div className="question">
        <div className="check_area">
          <input
            type="checkbox"
            name="option3"
            checked={selectedOptions.option3}
            onChange={handleChange}
          />
          <label>
            採用樁基礎之建築物
            <br />
          </label>
        </div>
        <div className="desc_area">
          <div>
            說明：建築物若採用樁基礎且穿過液化地層，當地層產生液化時仍可以支撐
            建築物，對建築物之危害較小。
          </div>
        </div>
      </div>
      <hr />

      <div className="question">
        <div className="check_area">
          <input
            type="checkbox"
            name="option4"
            checked={selectedOptions.option4}
            onChange={handleChange}
          />
          <label>地上三層(含)以下，採用筏式或版式基礎。</label>
        </div>
        <div className="desc_area">
          <div className="desc">
            說明：由 921 地震之經驗，在液化區三層樓以下且採用筏式或版式基礎的
            建築物，因液化而受損的情況都不嚴重。
          </div>
          <div className="images">
            <div>
              <img className="img" src="/img/筏式基礎矮.png" alt="筏式基礎矮" />
              <p>筏式基礎矮</p>
            </div>
            <div>
              <img className="img" src="/img/板式基礎.png" alt="板式基礎" />
              <p>板式基礎</p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="question">
        <div className="check_area">
          <input
            type="checkbox"
            name="option4"
            checked={selectedOptions.option4}
            onChange={handleChange}
          />
          <label>基礎下方已無會產生液化地層之建築物。</label>
        </div>
        <div className="desc_area">
          <div>
            說明：建築物因開挖或基礎加深，基礎已座落於不會產生液化的地層，如岩
            盤、卵礫石層、承載力足夠之黏土層，或地下水位很深的地層，建築
            物雖位於土壤液化潛勢區，也不會因液化而發生損壞。
          </div>
        </div>
      </div>
      <hr />

      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
};

export default Questionnaire;
